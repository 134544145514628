import { Button } from '@/components/common/Button';
import { Checkbox } from '@/components/common/Checkbox';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/common/Form';
import { SheetHeader, SheetTitle, SheetDescription, SheetFooter } from '@/components/common/Sheet';
import { SHOP_CATEGORY, PICK_UP_STATUS } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { FilterShopParams } from '@/types/shop';
import { sortFilterProvinces } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { ChevronRight, Star } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

interface FormFilterProps {
    handleCloseSheet: Function,
    handelRedirectForm: Function
}

export default observer(function FormFilter({ handleCloseSheet, handelRedirectForm }: FormFilterProps) {
    // hook
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    // store
    const {
        generalStore: { administrativeUnit },
        uiStore: { filterData, setObservable }
    } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        city: yup.array().of(yup.string().required()).optional(),
        category: yup.array().of(yup.number()).optional().nullable(),
        pickup: yup.string().nullable().optional(),
        rating: yup.string().nullable().optional()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            city: [],
            category: [],
            pickup: null,
            rating: ''
        }
    });

    // licecycle
    useEffect(() => {
        form.reset({
            city: filterData.city,
            category: filterData.category,
            pickup: filterData.pickUp,
            rating: filterData.rating?.toString()
        });
    }, []);

    // function
    const provinces = useMemo(() => {
        const currentProvinces = administrativeUnit?.provinces?.length ? [...administrativeUnit?.provinces] : [];
        const listCitySelectedValue = filterData.city || [];
        const filterCities = sortFilterProvinces(currentProvinces, listCitySelectedValue);
        if (listCitySelectedValue.length > 5) {
            return filterCities.filter(item => listCitySelectedValue.includes(item.code));
        }
        return filterCities.slice(0, 5);
    }, [administrativeUnit?.provinces]);
    const shopCategoryList = Object.keys(SHOP_CATEGORY).map((key) => {
        return { value: Number(key), label: SHOP_CATEGORY[key].key };
    });
    const pickUpServiceList = Object.keys(PICK_UP_STATUS).map((key) => {
        return {
            value: Number(key),
            label: PICK_UP_STATUS[key].key
        };
    }).reverse();
    const ratingList = Array.from({ length: 5 }, (_, i) => i + 1).reverse();

    const handleSetFilterData = (params: URLSearchParams) => {
        const cityParams = params.get('city');
        const categoryParams = params.get('category');
        const pickupParams = params.get('pickup');
        const ratingParams = params.get('rating');

        let initFilterData: FilterShopParams = {};

        initFilterData.city = cityParams ? cityParams.split(',') || [] : [];

        initFilterData.category = categoryParams ? categoryParams.split(',').map(Number) : [];
        initFilterData.pickUp = pickupParams ? pickupParams : undefined;
        initFilterData.rating = ratingParams ? ratingParams : undefined;

        setObservable('filterData', initFilterData, { isMergeObject: true });
    };

    const onSubmit = (data: FormData) => {
        const newParams = new URLSearchParams();

        // Thêm từng field vào query params, với mảng cần join lại bằng dấu phẩy
        if (data?.city && data.city.length > 0) {
            newParams.append('city', data.city.join(','));
        }

        if (data.category && data.category.length > 0) {
            newParams.append('category', data.category.join(','));
        }

        if (data.pickup) {
            newParams.append('pickup', data.pickup.toString());
        }

        if (data.rating) {
            newParams.append('rating', data.rating.toString());
        }

        handleSetFilterData(newParams);

        const sortParams = searchParams.get('sort');
        if (sortParams) {
            newParams.set('sort', sortParams);
        }
        const keywordParams = searchParams.get('keyword');
        if (keywordParams) {
            newParams.set('keyword', keywordParams);
        }

        setSearchParams(newParams);
        handleCloseSheet && handleCloseSheet(false);
    };

    const handleViewAll = () => {
        let keepFilterData: FilterShopParams = {
            city: form.getValues('city') || [],
            category: form.getValues('category') || [],
            pickUp: form.getValues('pickup'),
            rating: form.getValues('rating')
        };
        setObservable('filterData', keepFilterData, { isMergeObject: false });
        handelRedirectForm(false);
    };

    const handleClearAll = () => {
        form.reset({
            city: [],
            category: [],
            pickup: null,
            rating: undefined
        });
    };
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='h-full flex flex-col gap-0 overflow-hidden'>
                <SheetHeader className='py-6 px-4 h-[4.5rem] flex items-center justify-center'>
                    <SheetTitle className='text-[0.9375rem]'>{t('button.filter')}</SheetTitle>
                    <SheetDescription></SheetDescription>
                </SheetHeader>
                <div className='bg-white flex-1 overflow-x-hidden overflow-y-scroll scrollbar scrollbar-width-2'>
                    <FormField
                        control={form.control}
                        name='city'
                        render={() => (
                            <FormItem>
                                <div className='p-4 h-[3.25rem] bg-border-6 text-[0.9375rem] font-semibold'>
                                    <FormLabel className='text-[0.9375rem] font-semibold'>{t('words_title.by_city')}</FormLabel>
                                </div>
                                {provinces.map(item => (
                                    <FormField
                                        key={item.code}
                                        control={form.control}
                                        name='city'
                                        render={({ field }) => {
                                            return (
                                                <FormItem
                                                    key={item.code}
                                                    className='px-4 hover:bg-accent'
                                                >
                                                    <div className='h-10 grid grid-cols-[1fr,1rem] items-center border-b border-b-border-6'>
                                                        <FormLabel className='h-full flex items-center font-medium cursor-pointer'>
                                                            <div>{i18n.language === 'vi' ? item.name : i18n.language === 'en' ? item.nameEn : item.name}</div>
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={field.value?.includes(item.code)}
                                                                onCheckedChange={(checked) => {
                                                                    return checked ?
                                                                        field.onChange(
                                                                            field.value?.length ? [...field.value, item.code] : [item.code]
                                                                        ) :
                                                                        field.onChange(
                                                                            field.value?.filter(
                                                                                value => value !== item.code
                                                                            )
                                                                        );
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </FormItem>
                                            );
                                        }}
                                    />
                                ))}
                                <div className='h-[3.25rem] p-4 flex justify-center items-center'>
                                    <Button variant='ghost' className='hover:bg-white' asChild>
                                        <div onClick={() => handleViewAll()} className='flex gap-[2px] justify-center items-center cursor-pointer'>
                                            <span className='font-medium'>{t('button.view_all')}</span>
                                            <ChevronRight className='text-black' />
                                        </div>
                                    </Button>
                                </div>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='category'
                        render={() => (
                            <FormItem>
                                <div className='p-4 h-[3.25rem] bg-border-6 text-[0.9375rem] font-semibold'>
                                    <FormLabel className='text-[0.9375rem] font-semibold'>{t('words_title.by_category')}</FormLabel>
                                </div>
                                {shopCategoryList.map((item, index) => (
                                    <FormField
                                        key={item.value}
                                        control={form.control}
                                        name='category'
                                        render={({ field }) => {
                                            return (
                                                <FormItem
                                                    className='px-4 hover:bg-accent'
                                                >
                                                    <div className={classNames('h-10 grid grid-cols-[1fr,1rem] items-center', index < shopCategoryList.length - 1 && 'border-b border-b-border-6')}>
                                                        <FormLabel className='h-full flex items-center font-medium cursor-pointer'>
                                                            <div>{t(`select_options.${item.label}`)}</div>
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={field.value?.includes(item.value)}
                                                                onCheckedChange={(checked) => {
                                                                    return checked ?
                                                                        field.onChange(
                                                                            field.value?.length ? [...field.value, item.value] : [item.value]
                                                                        ) :
                                                                        field.onChange(
                                                                            field.value?.filter(
                                                                                value => value !== item.value
                                                                            )
                                                                        );
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </FormItem>
                                            );
                                        }}
                                    />
                                ))}
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='pickup'
                        render={() => (
                            <FormItem>
                                <div className='p-4 h-[3.25rem] bg-border-6 text-[0.9375rem] font-semibold'>
                                    <FormLabel className='text-[0.9375rem] font-semibold'>{t('words_title.by_pick_up')}</FormLabel>
                                </div>
                                {pickUpServiceList.map((item, index) => (
                                    <FormField
                                        key={item.value}
                                        control={form.control}
                                        name='pickup'
                                        render={({ field }) => {
                                            return (
                                                <FormItem
                                                    className='px-4 hover:bg-accent'
                                                >
                                                    <div className={classNames('h-10 grid grid-cols-[1fr,1rem] items-center', index < pickUpServiceList.length - 1 && 'border-b border-b-border-6')}>
                                                        <FormLabel className='h-full flex items-center font-medium cursor-pointer'>
                                                            {t(`select_options.${item.label}`)}
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={field?.value === item.value.toString()}
                                                                onCheckedChange={(checked) => {
                                                                    return checked ?
                                                                        field.onChange(
                                                                            item.value.toString()
                                                                        ) :
                                                                        field.onChange(
                                                                            null
                                                                        );
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </FormItem>
                                            );
                                        }}
                                    />
                                ))}
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='rating'
                        render={({ field }) => (
                            <FormItem className='mb-3'>
                                <div className='p-4 h-[3.25rem] bg-border-6 text-[0.9375rem] font-semibold'>
                                    <FormLabel className='text-[0.9375rem] font-semibold'>{t('words_title.by_rating')}</FormLabel>
                                </div>
                                {ratingList.map((rate, index) => {
                                    return (
                                        <div key={index} className='px-4 hover:bg-accent'>
                                            <FormItem className='h-10 grid grid-cols-[1fr,1rem] items-center border-b border-b-border-6'>
                                                <FormLabel className='font-medium h-full flex items-center gap-2 cursor-pointer'>
                                                    {[...Array(5)].map((_, starIndex) => (
                                                        <div key={starIndex}>
                                                            <Star
                                                                fill={starIndex < rate ? '#FFB72C' : 'white'}
                                                                className={classNames('h-4 w-4', starIndex < rate && 'text-icon-3')}
                                                            />
                                                        </div>
                                                    ))}
                                                    {rate < ratingList.length && (
                                                        <div className='font-medium'>
                                                            &
                                                            <span>
                                                                {' '}
                                                                {t('words_title.up')}
                                                            </span>
                                                        </div>
                                                    )}
                                                </FormLabel>
                                                <FormControl>
                                                    <Checkbox
                                                        checked={field?.value === rate.toString()}
                                                        onCheckedChange={(checked) => {
                                                            return checked ?
                                                                field.onChange(
                                                                    rate.toString()
                                                                ) :
                                                                field.onChange(
                                                                    ''
                                                                );
                                                        }}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        </div>
                                    );
                                })}
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <SheetFooter className='w-full block'>
                    <div className='bg-white h-16 px-4 flex gap-4 items-center shadow-sm'>
                        <Button type='button' onClick={() => handleClearAll()} variant='clear' className='basis-1/2'>{t('button.clear_all')}</Button>
                        <Button type='submit' variant='submit' className='basis-1/2'>{t('button.apply')}</Button>
                    </div>
                </SheetFooter>
            </form>
        </Form>
    );
});

import Chat from '@/assets/images/chat.svg';
import { MAX_LENGTH_TEXT_DESKTOP, MAX_LENGTH_TEXT_MOBILE, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { GENDER, LANGUAGES_SUPPORTED, PET_TYPE } from '@/types/enums';
import { formatAge } from '@/utils/datetime';
import { shortenParagraph } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default observer(function PetInformation() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const {
        appointmentStore: { appointment },
        uiStore: { devicesScreen: { mobile } }
    } = useStore();

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='flex flex-col gap-4 bg-white p-4 md:rounded-sm'>
            <div className='flex justify-between md:px-4 items-center'>
                <h4 className='font-semibold text-[0.9375rem]'>{t('words_title.pet_information')}</h4>
                <Link to={`${ROUTES.chatForShop.href}/${appointment?.petOwner?.code}`} className='flex gap-1 items-center justify-center'>
                    <div className='bg-active rounded-full size-6 mx-1 flex items-center justify-center'>
                        <img src={Chat} alt='' />
                    </div>
                    <p className='font-medium hidden md:block'>{t('button.chat_now')}</p>
                </Link>
            </div>
            <hr className='text-border-8' />
            <div className='grid grid-cols-2 md:gap-4 gap-y-1.5'>
                <div className='md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5'>
                    <p className='text-text-4'>{t('words_title.pet_owner_name')}</p>
                    <p className=''>{appointment?.petOwner?.fullName || '--'}</p>
                </div>
                <div className='md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5'>
                    <p className='text-text-4'>{t('words_title.phone_number')}</p>
                    <p className=''>{appointment?.petOwner?.phoneNumber || '--'}</p>
                </div>
                <div className='md:px-4 py-3 col-span-2 border-b border-border-5'>
                    <p className='text-text-4'>{t('words_title.address')}</p>
                    <p className=''>
                        {language === LANGUAGES_SUPPORTED.Vietnamese ?
                            (
                                <>
                                    {
                                        (appointment?.petOwner.address ? appointment?.petOwner.address + ', ' : '') +
                                        (appointment?.petOwner.ward ? appointment?.petOwner.ward?.fullName + ', ' : '') +
                                        (appointment?.petOwner.district ? appointment?.petOwner.district?.fullName + ', ' : '') +
                                        (appointment?.petOwner.province ? appointment?.petOwner.province?.fullName : '') || '--'
                                    }
                                </>
                            ) :
                            (
                                <>
                                    {
                                        (appointment?.petOwner.address ? appointment?.petOwner.address + ', ' : '') +
                                        (appointment?.petOwner.ward ? appointment?.petOwner.ward?.fullNameEn + ', ' : '') +
                                        (appointment?.petOwner.district ? appointment?.petOwner.district?.fullNameEn + ', ' : '') +
                                        (appointment?.petOwner.province ? appointment?.petOwner.province?.fullNameEn : '') || '--'
                                    }
                                </>
                            )}
                    </p>
                </div>
                <div className='md:px-4 py-3 col-span-2 border-b border-border-5 md:border-none flex gap-3 items-start'>
                    <img className='size-14 md:size-16 rounded-sm' src={appointment?.pet?.image} alt='image-pet' />
                    <div className='md:space-y-1'>
                        <p className='font-medium line-clamp-2'>{appointment?.pet?.name}</p>
                        <div className='text-tab-1 text-small'>
                            <span className=''>{appointment?.pet?.typeAnimal === PET_TYPE.Dog ? t('select_options.my_pet_dog') : (appointment?.pet?.typeAnimal === PET_TYPE.Cat ? t('select_options.my_pet_cat') : t('select_options.my_pet_other'))}</span>
                            {appointment?.pet?.species && (
                                <>
                                    <span className=' text-border-4 mx-2 lg:mx-1 h-[1.5625rem]'>|</span>
                                    <span className=''>{typeof appointment?.pet?.species === 'string' ? appointment?.pet?.species : (language === LANGUAGES_SUPPORTED.Vietnamese ? appointment?.pet?.species?.name : appointment?.pet?.species?.nameEn)}</span>
                                </>
                            )}
                        </div>

                        <div className='text-tab-1 text-small'>
                            {appointment?.pet?.gender && (
                                <>
                                    <span className=''>{appointment?.pet?.gender === GENDER.Male ? t('words_title.male') : t('words_title.female')}</span>
                                    <span className=' text-border-4 mx-2 lg:mx-1 h-[1.5625rem]'>|</span>
                                </>
                            )}
                            <span className=''>
                                {formatAge(appointment?.pet?.birthday ?? '', t)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='md:px-4 py-3 col-span-2'>
                    <p className='text-text-4'>{t('words_title.note')}</p>
                    <p className='whitespace-pre-wrap'>
                        {shortenParagraph(appointment?.note || '--', mobile ? MAX_LENGTH_TEXT_MOBILE : MAX_LENGTH_TEXT_DESKTOP, isExpanded).value}
                        {
                            shortenParagraph(appointment?.note || '--', mobile ? MAX_LENGTH_TEXT_MOBILE : MAX_LENGTH_TEXT_DESKTOP, isExpanded).isEllipsis && (
                                <button
                                    onClick={toggleExpand}
                                    className='text-active text-[0.8125rem] text-blue-500 no-underline'
                                >
                                    {isExpanded ? t('button.view_less') : t('button.view_more')}
                                </button>
                            )
                        }
                    </p>
                </div>
            </div>
        </div>
    );
});

import { observer } from 'mobx-react-lite';
import ShopImg from '@/assets/images/defaultShopImg.png';
import { Phone, TriangleAlert, User } from 'lucide-react';
import { LocationIcon } from '@/components/icons';
import { convertServicePrice, convertPetWeight, scrollToTop, shortenParagraph } from '@/utils/utils';
import { Button } from '@/components/common/Button';
import { useStore } from '@/hooks/useStore';
import { formatAge, formatDateTime } from '@/utils/datetime';
import { DISPLAY_MOMENT_DATE_YMD, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, PICK_UP_PRICE, ROUTES, SHOP_SERVICE_NAME, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { BOOK_APPOINTMENT_ERROR_TYPE, GENDER, LANGUAGES_SUPPORTED, PET_TYPE, SHOP_SERVICE_TYPE } from '@/types/enums';
import { useNavigate, useParams } from 'react-router-dom';
import { ShopServiceAppointment } from '@/types/shop';
import { TFunction } from 'i18next';
import { flowResult } from 'mobx';
import { useEffect, useState } from 'react';
import { BooKAppointmentData } from '@/types/http-payload/appointment';
import classNames from 'classnames';

interface ConfirmAppoinmentProps {
    onComplete?: Function,
    onBackStep?: Function
}

export default observer(function ConfirmAppoinment({ onBackStep, onComplete }: ConfirmAppoinmentProps) {
    // hook
    const { t, i18n: { language } } = useTranslation();
    const { shopSlug } = useParams();
    const navigate = useNavigate();
    // store
    const { authStore: { profile }, appointmentStore: { appointmentFormData, confirmOrCompleteAppointment }, shopStore: { getShopDetail, shopDetail, shopAppointmentInfor },
        petStore: { petDetail, getDetailPet }, modalStore: { showAlertModal, hideAllModals } } = useStore();
    const { appointmentDate, appointmentTime, petCode, note, services, total, pickUpStatus } = appointmentFormData;

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    // lifecycle
    useEffect(() => {
        if (shopSlug) getShopDetail(shopSlug);
    }, [shopSlug]);

    useEffect(() => {
        scrollToTop();
        petCode && getDetailPet(petCode);
    }, []);

    // function

    const handleErrorResponseComplete = (error: any) => {
        const res = error?.data;
        if (res?.data?.errorType) {
            hideAllModals();
            const message = language === LANGUAGES_SUPPORTED.Vietnamese ? res.data.message.vi : res.data.message.en;
            switch (res.data.errorType) {
                case BOOK_APPOINTMENT_ERROR_TYPE.GENERAL:
                    showAlertModal({
                        id: 'not-found-shop',
                        type: 'error',
                        content: t('sentences.appointment_shop_not_exist'),
                        onSave: () => {
                            hideAllModals();
                            navigate(ROUTES.home.href);
                        },
                        saveButton: t('button.back_to_home')
                    });
                    break;
                case BOOK_APPOINTMENT_ERROR_TYPE.DATE_TIME_INVALID:
                case BOOK_APPOINTMENT_ERROR_TYPE.PET_INVALID:
                case BOOK_APPOINTMENT_ERROR_TYPE.SERVICE_INVALID:
                    showAlertModal({
                        id: 'error-modal',
                        type: 'error',
                        content: message,
                        onSave: () => {
                            hideAllModals();
                        }
                    });
                    break;
            }
        }
    };

    const onSubmitCompleteAppointment = async () => {
        let data: BooKAppointmentData = {
            appointmentDate: formatDateTime(appointmentFormData.appointmentDate, SUBMIT_MOMENT_DATE_YMD) || '',
            appointmentTime: appointmentFormData.appointmentTime.slice(0, 5),
            services: appointmentFormData.services,
            petCode: appointmentFormData.petCode,
            note: appointmentFormData.note,
            typeAnimal: appointmentFormData.typeAnimal,
            pickUpStatus: appointmentFormData.pickUpStatus,
            pickUpAddressSameOwner: appointmentFormData.pickUpAddressSameOwner,
            isConfirm: false
        };
        const res = await flowResult(confirmOrCompleteAppointment({ data, slug: shopSlug || '' }, handleErrorResponseComplete));
        if (res) {
            if (res?.ok) {
                onComplete && onComplete();
            }
        }
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='md:mt-4 grid lg:grid-cols-2 grid-rows-1 md:gap-4 gap-1 md:mb-0 mb-2'>
            <div className='bg-white p-4 md:rounded-[0.25rem] flex flex-col gap-3'>
                <div className='pb-3 border-b border-b-border-8'>
                    <h3 className='font-semibold text-[0.9375rem]'>{t('words_title.general_information')}</h3>
                </div>
                <div className='pb-3 border-b border-dashed border-b-border-8 flex justify-between'>
                    <div>
                        <p className='text-label-4'>{t('words_title.booking_date')}</p>
                        <p className='font-medium'>{formatDateTime(appointmentDate, DISPLAY_MOMENT_DATE_YMD)}</p>
                    </div>
                    <div>
                        <p className='text-label-4'>{t('words_title.booking_time')}</p>
                        <p className='font-medium text-end'>
                            {formatDateTime(appointmentTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            })}
                        </p>
                    </div>
                </div>
                <div className='pb-3 border-b border-dashed border-b-border-8 flex flex-col gap-3'>
                    <h3 className='font-semibold text-[0.9375rem]'>{t('words_title.pet_information')}</h3>
                    <div className='flex gap-3'>
                        <div className='min-h-20 min-w-20'>
                            <img src={petDetail?.image} className='h-20 w-20 rounded-[0.25rem]' alt='pet img' />
                        </div>
                        <div className='flex flex-col justify-around gap-2'>
                            <p className='font-medium'>{petDetail?.name}</p>
                            <div className='flex gap-2 overflow-hidden'>
                                <p className={classNames('text-[0.8125rem] text-tab-1', language === LANGUAGES_SUPPORTED.Vietnamese && petDetail?.typeAnimal === PET_TYPE.Other && 'min-w-[5.5rem]')}>{petDetail?.typeAnimal === PET_TYPE.Dog ? t('select_options.my_pet_dog') : (petDetail?.typeAnimal === PET_TYPE.Cat ? t('select_options.my_pet_cat') : t('select_options.my_pet_other'))}</p>
                                {
                                    (petDetail?.species !== '') && (
                                        <>
                                            <div className='h-full w-[0.125rem] flex justify-center items-center'>
                                                <div className='w-[0.125rem] h-[80%] bg-border-8 rounded-md'></div>
                                            </div>
                                            <p className='text-[0.8125rem] text-tab-1 break-all'>{typeof petDetail?.species === 'string' ? petDetail?.species : (language === LANGUAGES_SUPPORTED.Vietnamese ? petDetail?.species?.name : petDetail?.species?.nameEn)}</p>
                                        </>
                                    )
                                }
                            </div>
                            <div className='flex gap-2 items-center'>
                                {
                                    petDetail?.gender !== null && (
                                        <>
                                            <p className='text-[0.8125rem] text-tab-1'>{petDetail?.gender === GENDER.Male ? t('words_title.male') : t('words_title.female')}</p>
                                            <div className='w-[0.125rem] h-[70%] bg-border-8 rounded-md'></div>
                                        </>
                                    )
                                }
                                <p className='text-[0.8125rem] text-tab-1'>
                                    {formatAge(petDetail?.birthday ?? '', t)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='mt-2 text-label-4'>{t('words_title.note')}</label>
                        <p className='font-medium whitespace-pre-line'>{note || '--'}</p>
                    </div>
                </div>
                <div className='pb-3 border-b border-dashed border-b-border-8 flex flex-col gap-3'>
                    <h3 className='font-semibold text-[0.9375rem]'>{t('words_title.shop_info')}</h3>
                    <div className='grid grid-cols-[7rem,1fr] gap-3'>
                        <div className='min-h-28 min-w-28'>
                            <img src={shopDetail?.images?.[0] || ShopImg} className='h-28 w-28 rounded-[0.25rem]' alt='pet img' />
                        </div>
                        <div className='flex flex-col gap-2 overflow-hidden'>
                            <p className='font-medium leading-6'>{shopDetail.name}</p>
                            <div className='flex gap-2 items-center'>
                                <Phone strokeWidth={0.25} className='size-4 fill-icon-2' />
                                <p className='text-[0.8125rem] text-tab-1'>{shopDetail.phoneNumber}</p>
                            </div>
                            <div className='flex gap-2'>
                                <div className='pt-1'><LocationIcon className='size-4 fill-icon-2' /></div>
                                <p className='text-[0.8125rem] text-tab-1'>{shopDetail.address}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-3'>
                    <h3 className='font-semibold text-[0.9375rem]'>{t('words_title.user_infor')}</h3>
                    <div className='flex gap-2 mt-2'>
                        <div className='h-12 w-12 flex justify-center pt-1'>
                            <div className='flex justify-center items-center h-8 w-8 bg-active rounded-full'>
                                <User className='text-white h-4' fill='white' />
                            </div>
                        </div>
                        <div>
                            <p className='font-medium'>{profile?.fullName}</p>
                            <p className='text-[0.8125rem] text-tab-1'>
                                {profile?.email ?
                                    (
                                        <>
                                            {profile.email}
                                            {' '}
                                            <span className='text-[0.9375rem]'>&middot;</span>
                                            {' '}
                                            {profile.phoneNumber}
                                        </>
                                    ) :
                                    profile?.phoneNumber}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col md:gap-4 gap-1'>
                <div className='p-4 bg-white rounded-[0.25rem]'>
                    <div className='pb-3 border-b border-b-border-8 flex justify-between'>
                        <h3 className='font-semibold text-[0.9375rem]'>{t('words_title.service_list')}</h3>
                        <p className='text-tab-1'>
                            (
                            {t('words_title.currency')}
                            : VND)
                        </p>
                    </div>
                    <div className='mt-3 flex flex-col gap-3'>
                        {pickUpStatus && (
                            <div className='pb-3 border-b border-dashed border-b-border-8 flex justify-between'>
                                <p className=''>{t('words_title.pick_up_service')}</p>
                                <p className=''>
                                    {shopAppointmentInfor?.pickUpPriceType && t(`select_options.${PICK_UP_PRICE[shopAppointmentInfor?.pickUpPriceType].key}`)}
                                </p>
                            </div>
                        )}
                        {convertServiceList(services, t).map((service, index, array) => {
                            return (
                                <div key={index} className={classNames('pb-3 flex justify-between gap-1', (index < array.length - 1) && 'border-b border-dashed border-b-border-8 ')}>
                                    <p className='text-tab-1 w-[65%]'>{service.serviceName}</p>
                                    <p className='text-tab-1 text-right'>
                                        {service.servicePrice}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='p-4 bg-white rounded-[0.25rem] md:mb-0 mb-16 md:static relative md:z-auto z-[48]'>
                    <h3 className='font-semibold text-[0.9375rem] border-b border-b-border-8 pb-3'>{t('words_title.payment_infor')}</h3>
                    <div className='mt-3 md:border-b border-dashed border-b-border-8 pb-2 flex flex-col gap-3'>
                        <div className='flex justify-between'>
                            <label className='text-label-4'>{t('words_title.total_selected_service')}</label>
                            <p className='text-black'>{total.totalService}</p>
                        </div>
                        <div className='flex justify-between items-center font-semibold'>
                            <label>{t('words_title.expected_total_amount')}</label>
                            <p className='text-[1.3125rem]'>
                                {convertServicePrice(total.totalAmount, t)}
                            </p>
                        </div>
                        <div className='flex gap-2 rounded-sm bg-border-6 p-2'>
                            <div className='size-5'>
                                <TriangleAlert />
                            </div>
                            <span className='text-small'>
                                {shortenParagraph(t('sentences.expected_total_amount_note'), 120, isExpanded).value}
                                {
                                    shortenParagraph(t('sentences.expected_total_amount_note'), 120, isExpanded).isEllipsis && (
                                        <button
                                            type='button'
                                            onClick={toggleExpand}
                                            className='text-active text-[0.8125rem] text-blue-500 no-underline'
                                        >
                                            {isExpanded ? t('button.view_less') : t('button.view_more')}
                                        </button>
                                    )
                                }
                            </span>
                        </div>
                    </div>
                    <div className='md:mt-4 flex justify-between xsm:gap-2 gap-1 md:static md:w-full md:h-auto md:py-0 md:px-0 fixed bottom-0 left-0 z-50 w-screen bg-white h-17 xsm:p-4 p-2'>
                        <Button type='button' onClick={() => onBackStep && onBackStep()} variant='clear' className='w-full'>{t('button.back')}</Button>
                        <Button type='button' onClick={() => onSubmitCompleteAppointment()} variant='submit' className='w-full'>{t('button.complete_appointment')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
});

const convertServiceList = (serviceList: ShopServiceAppointment[], t: TFunction) => {
    let result: any = [];
    if (serviceList.length > 0) {
        result = serviceList.map((service) => {
            let serviceName = '';
            let servicePrice: number | string = convertServicePrice(service.price, t, false);
            if (service.type === SHOP_SERVICE_TYPE.Dog || service.type === SHOP_SERVICE_TYPE.Cat) {
                serviceName = t(`select_options.${SHOP_SERVICE_NAME[service.serviceName].key}`);
                serviceName += ` / ${convertPetWeight(service.petWeightFrom, service.petWeightTo, t)}`;
            } else if (service.type === SHOP_SERVICE_TYPE.Medical || (service.type === SHOP_SERVICE_TYPE.Other && service.isMedicalService)) {
                serviceName = `(${t('words_title.label_medical')}) ` + service.serviceName;
            } else {
                serviceName = service.serviceName;
            }
            return {
                serviceName,
                servicePrice
            };
        });
    }
    return result;
};

import { Button } from '@/components/common/Button';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import MyPetItem from './components/MyPetItem';
import { useStore } from '@/hooks/useStore';
import Notfound from '@/assets/images/myPetNotfound.png';
import MyPetForm from './components/SettingPetForm';
import { useEffect } from 'react';
import { ROUTES } from '@/configs/constants';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { AddPetIcon } from '@/components/icons';

export default observer(function MyPetPage() {
    // hook
    const { t } = useTranslation();

    // store
    const {
        modalStore: { showModal },
        petStore: { pets, getAllPet },
        apiStore: { isLoading },
        uiStore: { devicesScreen: { desktop } }
    } = useStore();

    // life cycle
    useEffect(() => {
        getAllPet();
    }, []);

    // function
    const onHandleAddPet = () => {
        showModal({
            id: 'add-my-pet',
            size: '2xl',
            title: t('words_title.add_my_pet'),
            titleClassName: 'title-3 text-center',
            footerClassName: 'p-0',
            content: <MyPetForm />
        });
    };

    return (
        <>
            <Header
                onlyShowMobile
                headerMobileComponent={(
                    <HeaderMobileFormat3
                        title={t('words_title.my_pet')}
                        defaultNavigateUrl={ROUTES.myAccount.href}
                        rightContent={pets.length > 0 ? <div onClick={onHandleAddPet}><AddPetIcon fill='#9A6051' /></div> : <></>}
                    />
                )}
            />
            <div className='px-4 title-3 hidden md:block md:px-4 md:py-6'>{t('words_title.my_pet')}</div>
            {
                pets.length > 0 && (
                    <>
                        <div className='pb-[1.5rem] md:bg-white'>
                            <div className='px-4 pt-4 grid grid-cols-2 gap-4 xsm:grid-cols-3 xsm:gap-2.5 xl:gap-4 xl:grid-cols-4 md:bg-none md:pt-0'>
                                {
                                    pets.map((pet, index) => <MyPetItem key={index} pet={pet} />)
                                }
                            </div>
                            {
                                desktop && (
                                    <div className='w-full md:flex md:justify-center'>
                                        <Button
                                            onClick={onHandleAddPet}
                                            className='flex items-center justify-center mt-6 h-[38px] w-60 md:w-80'
                                        >
                                            <AddPetIcon fill='white' />
                                            {t('button.add_my_pet')}
                                        </Button>
                                    </div>
                                )
                            }

                        </div>
                    </>
                )
            }
            {
                pets.length === 0 && !isLoading && (
                    <>
                        <div className='text-center pt-[3.75rem] pb-6 md:pt-0 bg-white h-screen md:h-auto'>
                            <img className='max-w-[19.625rem] h-[11.375rem] mx-auto' src={Notfound} alt='My Pet NotFound' />
                            <div className='mt-5'>
                                <h3 className='font-semibold text-[0.9375rem] leading-[1.3125rem]'>{t('sentences.you_dont_have_any_pet')}</h3>
                            </div>
                            <Button
                                onClick={onHandleAddPet}
                                className='h-[38px] w-60 md:w-80 mx-auto mt-5 md:flex items-center justify-center'
                            >
                                <AddPetIcon fill='white' />
                                {t('button.add_my_pet')}
                            </Button>
                        </div>
                    </>
                )
            }
        </>
    );
});

import Paginate from '@/components/pagination/Paginate';
import { useStore } from '@/hooks/useStore';
import { scrollToTop } from '@/utils/utils';
import { Star } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReviewList from './components/ReviewList';
import ShopRate from './components/ShopRate';

export default observer(function ReviewManagement() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        reviewStore: { getAllReviewForReviewManagement, setObservable, totalPage, shopInfo, myReviewPaging, allReviewForReviewManagement, params }
    } = useStore();

    // lifecycle
    useEffect(() => {
        flowResult(getAllReviewForReviewManagement(params));
        return () => {
            setObservable('myReviewPaging', { ...myReviewPaging, pagination: { ...myReviewPaging.pagination, pageIndex: 0 } });
        };
    }, [params]);

    return (
        <div className='flex flex-col bg-background-2'>
            <div className='text-start px-4 hidden md:flex md:justify-between bg-white py-6 rounded-t-sm'>
                <h3 className='text-[1.3125rem] text-active font-semibold'>{t('words_title.review_management')}</h3>
                <div className='flex items-center gap-2 md:flex point-920:hidden'>
                    <p className='text-[1.0625rem] font-semibold text-title-1'>{t('words_title.shop_rate')}</p>
                    <div className='flex items-center gap-1'>
                        <p className='text-[1.0625rem] font-medium text-icon-3'>{shopInfo?.rating?.toFixed(1)}</p>
                        <Star className='size-5 fill-current text-icon-3' />
                    </div>
                </div>
            </div>
            <ShopRate />
            <ReviewList />
            {
                allReviewForReviewManagement.length > 0 && (
                    <div className='flex justify-center bg-white pb-6'>
                        <Paginate
                            forcePage={myReviewPaging.pagination.pageIndex}
                            pageCount={totalPage}
                            onPageChange={({ selected }) => {
                                myReviewPaging.pagination.pageIndex = selected;
                                getAllReviewForReviewManagement(params, { ...myReviewPaging, pagination: { ...myReviewPaging.pagination, pageIndex: selected } });
                                scrollToTop();
                            }}
                        />
                    </div>
                )
            }
        </div>
    );
});

import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import classNames from 'classnames';
import { Heart } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ShopDetailInfo from './components/ShopDetailInfo';
import ShopDetailOverview from './components/ShopDetailOverview';
import ShopDetailSlider from './components/ShopDetailSlider';
import ShopDetailUtils from './components/ShopDetailUtils';

export default observer(function ShopDetailPage() {
    // hooks
    const { slug } = useParams();

    // store
    const {
        uiStore: { devicesScreen: { desktop, mobile } },
        shopStore: { updateFavoriteShops, shopDetail, getShopDetail },
        authStore: { token }
    } = useStore();

    // state
    const [showOnScroll, setShowOnScroll] = useState(false);

    // lifecycle
    useEffect(() => {
        fetchData();
    }, [slug]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setShowOnScroll(true);
            } else {
                setShowOnScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // function
    const fetchData = async () => {
        if (slug) {
            await flowResult(getShopDetail(slug, true, true));
        }
    };

    const handleAddToFavoriteShops = async (slugShop: string, value: boolean) => {
        const res = await flowResult(updateFavoriteShops({ slug: slugShop, favorite: value }));
        if (res) {
            getShopDetail(slugShop, true);
        }
    };

    return (
        <div className='min-h-screen flex md:gap-4 md:py-8'>
            <div className='w-full md:w-2/3 md:flex md:flex-col md:gap-4'>
                {
                    showOnScroll && (
                        <Header
                            onlyShowMobile
                            headerMobileComponent={(
                                <HeaderMobileFormat3
                                    title={shopDetail?.name}
                                    defaultNavigateUrl={ROUTES.home.href}
                                    rightContent={(
                                        <>
                                            {
                                                token && (
                                                    <Heart onClick={() => handleAddToFavoriteShops(slug!, !shopDetail?.isFavorite)} className={classNames('w-5 h-5 cursor-pointer text-active', shopDetail?.isFavorite ? 'fill-current' : '')} strokeWidth={1} />
                                                )
                                            }
                                        </>
                                    )}
                                />
                            )}
                        />
                    )
                }
                <ShopDetailSlider />
                <ShopDetailInfo />
                {
                    mobile && showOnScroll && (
                        <ShopDetailUtils />
                    )
                }
            </div>
            {
                desktop && (
                    <div className='w-1/3 md:flex hidden flex-col gap-4 '>
                        {/* <div className='sticky top-[9.125rem]'> */}
                        <ShopDetailOverview />
                        <div className='w-full h-1 md:h-4 bg-background-2'></div>
                        <ShopDetailUtils />
                        {/* </div> */}
                    </div>
                )
            }
        </div>
    );
});

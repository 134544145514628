import FilledStar from '@/assets/images/filledStar.svg';
import ShopDetailImg from '@/assets/images/shop_detail_img.png';
import { Carousel, CarouselApi, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/common/Carousel';
import { PickupIcon } from '@/components/icons';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import classNames from 'classnames';
import { ArrowLeft, Heart, TriangleAlert } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ShopDetailReview from './ShopDetailReview';

export default observer(function ShopDetailSlider() {
    // hooks
    const { t } = useTranslation();
    const divRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { slug } = useParams();

    // store
    const {
        uiStore: { devicesScreen: { mobile, desktop } },
        shopStore: { updateFavoriteShops, shopDetail, getShopDetail },
        authStore: { token }
    } = useStore();

    // state
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState(0);
    const [imageHeight, setImageHeight] = useState<number>(0);
    const [count, setCount] = useState(0);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // lifecycle
    useEffect(() => {
        const img = new Image();
        img.src = ShopDetailImg;

        img.onload = () => {
            if (divRef.current) {
                const aspectRatio = img.height / img.width;
                const divWidth = divRef.current.clientWidth;

                setImageHeight(divWidth * aspectRatio);
            }
        };
    }, []);

    useEffect(() => {
        if (!api) {
            return;
        }

        setCount(api.scrollSnapList().length);
        setCurrent(api.selectedScrollSnap() + 1);

        api.on('select', () => {
            setCurrent(api.selectedScrollSnap() + 1);
        });
    }, [api]);

    // function
    const handleAddToFavoriteShops = async (slugShop: string, value: boolean) => {
        const res = await flowResult(updateFavoriteShops({ slug: slugShop, favorite: value }));
        if (res) {
            getShopDetail(slugShop, true);
        }
    };

    return (
        <div className='bg-white rounded flex flex-col md:gap-3 md:p-4'>
            <div
                ref={divRef}
                style={{
                    backgroundImage: `url(${shopDetail?.images?.[selectedImageIndex]})`,
                    backgroundRepeat: 'no-repeat',
                    height: `${imageHeight}px`
                }}
                className='relative bg-cover bg-center hidden md:block'
            >
                {
                    token && (
                        <div
                            className='absolute top-4 right-4 rounded-full bg-white h-7 w-7 flex justify-center items-center'
                        >
                            <Heart onClick={() => handleAddToFavoriteShops(slug!, !shopDetail?.isFavorite)} className={classNames('w-5 h-5 cursor-pointer text-active', shopDetail?.isFavorite ? 'fill-current' : '')} strokeWidth={1} />
                        </div>
                    )
                }
                <div className='absolute bottom-2 left-4 rounded bg-white px-1.5'>
                    <div className='w-fit h-[1.625rem] flex items-center justify-center space-x-1 border-none cursor-default'>
                        <span className='text-text-9 text-small'>{shopDetail?.rating?.toFixed(1)}</span>
                        <img className='size-4' src={FilledStar} alt='' />
                    </div>
                </div>
                {
                    shopDetail?.pickUpStatus && (
                        <div className='absolute bottom-2 right-4 rounded bg-white px-1.5'>
                            <div className='w-fit h-[1.625rem] flex items-center justify-center space-x-1 border-none cursor-default'>
                                <PickupIcon className='fill-icon-2 w-4 h-3' />
                                <span className='text-text-9 text-small'>{t('words_title.mark_pick_up')}</span>
                            </div>
                        </div>
                    )
                }
                {!shopDetail?.operatingStatus && (
                    <div className='absolute flex flex-col gap-1 justify-center items-center top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-[12.5rem] h-[6.25rem] rounded-[0.25rem] bg-black/60'>
                        <TriangleAlert className='text-white size-[1.1875rem]' />
                        <p className='text-white font-medium'>{t('sentences.temporarily_out_of_service_2')}</p>
                    </div>
                )}
            </div>
            <div>
                <div className='w-full relative md:static'>
                    <Carousel setApi={setApi} className='w-full relative'>
                        {
                            desktop && <CarouselPrevious className='border-none group absolute top-1/2 left-[40px] transform -translate-y-1/2 -translate-x-full shadow-xl z-10' />
                        }
                        {
                            mobile && (
                                <>
                                    <div className='absolute bottom-2 left-4 rounded bg-white px-1.5 z-10'>
                                        <div className='w-fit h-[1.625rem] flex items-center justify-center space-x-1 border-none cursor-default'>
                                            <span className='text-text-9 text-small font-medium'>{shopDetail?.rating?.toFixed(1)}</span>
                                            <img className='size-4' src={FilledStar} alt='' />
                                        </div>
                                    </div>
                                    <div
                                        className='absolute top-4 left-4 rounded-full bg-transparent h-7 w-7 flex justify-center items-center'
                                        onClick={() => {
                                            navigate(ROUTES.home.href);
                                        }}
                                    >
                                        <ArrowLeft className='text-white z-10 w-full h-full' />
                                    </div>
                                </>
                            )
                        }
                        <CarouselContent>
                            {shopDetail?.images?.map((image, index) => (
                                <CarouselItem
                                    key={index}
                                    className={`${desktop ? `basis-1/5 w-1/5 ${selectedImageIndex === index ? 'opacity-100' : 'opacity-50'}` : ''} flex justify-center cursor-pointer`}
                                    onClick={() => setSelectedImageIndex(index)}
                                >
                                    <img
                                        className='w-full object-cover aspect-square md:rounded-sm'
                                        src={image}
                                        alt={`thumbnail-${index}`}
                                    />
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                        {
                            token && mobile && (
                                <div
                                    className='absolute top-4 right-4 rounded-full bg-white h-7 w-7 flex justify-center items-center'
                                >
                                    <Heart onClick={() => handleAddToFavoriteShops(slug!, !shopDetail?.isFavorite)} className={classNames('w-5 h-5 cursor-pointer text-active', shopDetail?.isFavorite ? 'fill-current' : '')} strokeWidth={1} />
                                </div>
                            )
                        }
                        {
                            shopDetail?.pickUpStatus && mobile && (
                                <div className='absolute bottom-2 right-4 rounded bg-white px-1.5'>
                                    <div className='w-fit h-[1.625rem] flex items-center justify-center space-x-1 border-none'>
                                        <PickupIcon className='fill-icon-2 w-4 h-3' />
                                        <span className='text-text-9 text-small'>{t('words_title.mark_pick_up')}</span>
                                    </div>
                                </div>
                            )
                        }
                        {!shopDetail?.operatingStatus && mobile && (
                            <div className='absolute flex flex-col gap-1 justify-center items-center top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-[12.5rem] h-[6.25rem] rounded-[0.25rem] bg-black opacity-60'>
                                <TriangleAlert className='text-white size-[1.1875rem]' />
                                <p className='text-white font-medium'>{t('sentences.temporarily_out_of_service_2')}</p>
                            </div>
                        )}
                        {
                            desktop && <CarouselNext className='border-none group absolute top-1/2 right-[40px] transform -translate-y-1/2 translate-x-full shadow-xl z-10' />
                        }
                    </Carousel>
                    <div className='absolute md:static md:hidden bottom-2 left-0 right-0 flex justify-center'>
                        <div className='p-1 bg-tab-1 rounded-sm grid place-items-center'>
                            <p className='text-white text-small'>
                                <span>{current}</span>
                                <span>/</span>
                                <span>{shopDetail?.images?.length}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {
                mobile && (
                    <>
                        <ShopDetailReview />
                    </>
                )
            }
        </div>
    );
});

import { observer } from 'mobx-react-lite';
import GeneralInformation from '../components/GeneralInformation';
import PetInformation from '../components/PetInformation';
import PickupService from '../components/PickupService';
import ServicesList from '../components/ServicesList';
import { Button } from '@/components/common/Button';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { ROUTES } from '@/configs/constants';
import Header from '@/components/layout/Header';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { APPOINTMENT_STATUS_TYPE } from '@/types/enums';
import { flowResult } from 'mobx';
import { toastify } from '@/utils/toastify';
import NotFoundPage from '@/pages/not-found';

export default observer(function AppointmentDetail() {
    // hooks
    const { t } = useTranslation();
    const { slug } = useParams();
    const navigate = useNavigate();

    // store
    const {
        appointmentStore: { appointment, getAppointmentDetail, updateAppointmentStatus },
        modalStore: { hideAllModals, showAlertModal, modalWaringAlertOptions },
        uiStore: { devicesScreen: { mobile, desktop } }
    } = useStore();

    // lifecycle
    useEffect(() => {
        if (slug) {
            getAppointmentDetail(slug);
        }
    }, []);

    // functions
    const handleUpdateAppointmentStatus = (statusType, title, successMessage) => {
        showAlertModal(modalWaringAlertOptions({
            content: title,
            onSave: async () => {
                hideAllModals();
                if (appointment?.code) {
                    const res = await flowResult(updateAppointmentStatus({ code: appointment.code, status: statusType }));
                    if (res) {
                        toastify('alert-success', successMessage);
                        getAppointmentDetail(appointment?.code);
                    }
                }
            }
        }));
    };

    const handleCancelAppointment = () => {
        handleUpdateAppointmentStatus(
            APPOINTMENT_STATUS_TYPE.Cancelled,
            t('sentences.cancel_appointment'),
            t('sentences.update_success')
        );
    };

    const handleConfirmAppointment = () => {
        handleUpdateAppointmentStatus(
            APPOINTMENT_STATUS_TYPE.Confirmed,
            t('sentences.confirm_appointment'),
            t('sentences.update_success')
        );
    };

    const handleCompleteAppointment = () => {
        handleUpdateAppointmentStatus(
            APPOINTMENT_STATUS_TYPE.Completed,
            t('sentences.confirm_complete_status'),
            t('sentences.update_success')
        );
    };

    return (
        <>
            {appointment ?
                (
                    <>
                        <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.appointment_details')} defaultNavigateUrl={ROUTES.appointmentManagement.href} />} />
                        <div className='bg-background-2 h-full w-full flex flex-col gap-1.5 md:gap-4 pb-12 md:pb-0'>
                            <div className='text-start px-4 hidden md:block bg-white py-6 rounded-sm'>
                                <h3 className='text-[1.3125rem] text-active font-semibold'>{t('words_title.appointment_details')}</h3>
                            </div>
                            <GeneralInformation />
                            <PetInformation />
                            <PickupService />
                            <ServicesList />
                            {desktop && (
                                <div className='px-4 md:px-0 flex justify-between items-center bg-white md:bg-background-2 py-3 md:py-0'>
                                    <div className='hidden md:block'>
                                        <Button
                                            onClick={() => {
                                                navigate(ROUTES.appointmentManagement.href);
                                            }}
                                            variant='clear'
                                            className='w-20 lg:w-40'
                                        >
                                            {t('button.back')}
                                        </Button>
                                    </div>
                                    {appointment?.status === APPOINTMENT_STATUS_TYPE.NotConfirmed && (
                                        <div className='flex justify-between gap-2 xsm:gap-4 w-full md:w-auto'>
                                            <Button onClick={handleCancelAppointment} variant='outline' className='w-full md:w-44 !text-small'>{t('button.cancel_appointment')}</Button>
                                            <Button onClick={handleConfirmAppointment} variant='default' className='w-full md:w-44 !text-small'>{t('button.confirm_appointment')}</Button>
                                        </div>
                                    )}
                                    {appointment?.status === APPOINTMENT_STATUS_TYPE.Confirmed && (
                                        <div className='flex justify-between gap-2 xsm:gap-4 w-full md:w-auto'>
                                            <Button onClick={handleCancelAppointment} variant='outline' className='w-full md:w-44 !text-small'>{t('button.cancel_appointment')}</Button>
                                            <Button onClick={handleCompleteAppointment} variant='default' className='w-full md:w-44 !text-small'>{t('button.complete_appointment')}</Button>
                                        </div>
                                    )}
                                </div>
                            )}
                            {(mobile && (appointment?.status !== APPOINTMENT_STATUS_TYPE.Cancelled && appointment?.status !== APPOINTMENT_STATUS_TYPE.Completed)) &&
                                (
                                    <div className='py-4 px-1 point-375:px-4 flex justify-between items-center fixed bottom-0 w-full bg-white'>
                                        {appointment?.status === APPOINTMENT_STATUS_TYPE.NotConfirmed && (
                                            <div className='flex justify-between gap-2 xsm:gap-4 w-full md:w-auto'>
                                                <Button onClick={handleCancelAppointment} variant='outline' className='w-full md:w-44 !text-small point-375:px-2'>{t('button.cancel_appointment')}</Button>
                                                <Button onClick={handleConfirmAppointment} variant='default' className='w-full md:w-44 !text-small point-375:px-2'>{t('button.confirm_appointment')}</Button>
                                            </div>
                                        )}
                                        {appointment?.status === APPOINTMENT_STATUS_TYPE.Confirmed && (
                                            <div className='flex justify-between gap-2 xsm:gap-4 w-full md:w-auto'>
                                                <Button onClick={handleCancelAppointment} variant='outline' className='w-full md:w-44 !text-small point-375:px-2'>{t('button.cancel_appointment')}</Button>
                                                <Button onClick={handleCompleteAppointment} variant='default' className='w-full md:w-44 !text-small point-375:px-2'>{t('button.complete_appointment')}</Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                        </div>
                    </>
                ) :
                <NotFoundPage />}

        </>
    );
});

import { DropdownMenu, DropdownMenuContent, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/common/DropdownMenu';
import { DISPLAY_MOMENT_DATE_DMYHMS_AM_PM, ROUTES } from '@/configs/constants';
import { MarkReadNotificationRequest } from '@/types/http-payload/notification';
import { Notification } from '@/types/notification';
import { formatDateTime } from '@/utils/datetime';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { CheckIcon, Ellipsis, Trash2Icon } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface NotificationItemProps extends Notification {
    onMarkReadNotification: (data: MarkReadNotificationRequest) => void,
    onDeleteNotification: (code: string) => void
}

const NotificationItem = observer((props: NotificationItemProps) => {
    // props
    const { content, createdAt, isRead, code, onMarkReadNotification, onDeleteNotification } = props;

    // hooks
    const { t, i18n: { language } } = useTranslation();

    return (
        <div className='relative'>
            <Link to={`${ROUTES.notification.href}/${code}`} className={` flex p-4 ${isRead ? '' : 'bg-background-5'} border-b border-dashed border-border-12 cursor-pointer w-full h-full`}>
                <div className='flex flex-col gap-y-1'>
                    <p
                        className=' pr-8'
                        dangerouslySetInnerHTML={{ __html: content?.[language] }}
                    >
                    </p>
                    <p className='text-small text-text-4'>{formatDateTime(createdAt, DISPLAY_MOMENT_DATE_DMYHMS_AM_PM)}</p>
                </div>
            </Link>

            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <button
                        className='absolute top-4 right-4'
                        type='button'
                        title={t('words_title.more')}
                    >
                        <Ellipsis className='text-black' />
                    </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align='end' className='w-[14.5rem]'>
                    {
                        !isRead && (
                            <>
                                <DropdownMenuItem
                                    className='flex items-center gap-2 px-4 py-2 hover:cursor-pointer hover:bg-background-5 focus-visible:outline-none'
                                    onClick={() => onMarkReadNotification({
                                        isReadAll: false,
                                        notificationCodes: [code]
                                    })}
                                >
                                    <CheckIcon />
                                    <span>{t('button.mark_as_read')}</span>
                                </DropdownMenuItem>
                                <DropdownMenuSeparator />
                            </>
                        )
                    }
                    <DropdownMenuItem
                        className='flex items-center gap-2 px-4 py-2 hover:cursor-pointer hover:bg-background-5 focus-visible:outline-none'
                        onClick={() => onDeleteNotification(code)}
                    >
                        <Trash2Icon />
                        <span>{t('button.delete')}</span>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
});

export default NotificationItem;

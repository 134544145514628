import { useStore } from '@/hooks/useStore';
import { cn } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { ReactNode, useMemo } from 'react';
import Footer from './Footer';
import FooterMenuMobile from './FooterMenuMobile';
import Header, { HeaderProps } from './Header';
import { HEADER_FORMAT } from '@/types/enums';

interface ContainerBodyProps extends HeaderProps {
    children: ReactNode,
    showHeader?: boolean,
    showFooter?: boolean,
    showFooterMenu?: boolean,
    bodyClassname?: string
}

export default observer(function ContainerBody({
    children,
    showHeader,
    showFooter,
    showFooterMenu,
    headerMobileComponent,
    headerMobileComponentForGeneral,
    headerMobileComponentForShopOwner,
    bodyClassname
}: ContainerBodyProps) {
    // store
    const {
        authStore: { isChannelShopOwner },
        uiStore: { showHeaderFormat, windowSize, devicesScreen }
    } = useStore();

    const { mobile, desktop } = devicesScreen;
    const { width, height } = windowSize;

    // state
    const minHeightContent = useMemo(() => {
        let heightHeader = 0, heightMenuFooter = 0, heightFooter = 0;

        if (showHeader) {
            if (showHeaderFormat === HEADER_FORMAT.desktopGeneral) {
                heightHeader = 7.125;
            }

            if (showHeaderFormat === HEADER_FORMAT.desktopShop) {
                if (width >= 920) {
                    heightHeader = 4.25;
                } else {
                    heightHeader = 7.25;
                }
            }

            if (showHeaderFormat === HEADER_FORMAT.mobile1) {
                heightHeader = 6.75;
            }

            if (showHeaderFormat === HEADER_FORMAT.mobile2) {
                heightHeader = 3.25;
            }
        }

        if (showFooterMenu) {
            heightMenuFooter = 3.875;
        }

        if (showFooter) {
            if (desktop) {
                heightFooter = 16.25;
            } else {
                heightFooter = 19.125;
            }
        }

        return heightHeader + heightMenuFooter + heightFooter;
    }, [showHeaderFormat, showFooterMenu, devicesScreen, windowSize, isChannelShopOwner]);

    return (
        <>
            {/* Header */}
            {showHeader && (
                <Header
                    headerMobileComponent={headerMobileComponent}
                    headerMobileComponentForGeneral={headerMobileComponentForGeneral}
                    headerMobileComponentForShopOwner={headerMobileComponentForShopOwner}
                />
            )}

            {/* Body */}
            <section className={cn(
                'bg-background-2',
                desktop && 'px-4',
                showFooterMenu && mobile && !isChannelShopOwner && !showFooter && 'pb-15.5',
                bodyClassname
            )}
            >
                <div className={cn('container')} style={{ minHeight: `calc(${height}px - ${minHeightContent}rem)` }}>
                    {children}
                </div>

            </section>

            {/* Footer */}
            {
                showFooter && (
                    <section className={cn(
                        'bg-background-2 pt-12.5',
                        showFooterMenu && mobile && !isChannelShopOwner && 'pb-15.5'
                    )}
                    >
                        <Footer />
                    </section>
                )
            }

            {/* Footer Mobile */}
            {showFooterMenu && mobile && !isChannelShopOwner && <FooterMenuMobile />}
        </>
    );
});

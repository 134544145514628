import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import InputAppointment from './input-appointment';
import { useEffect, useState } from 'react';
import ConfirmAppoinment from './confirm-appoinment';
import BookSuccess from './success';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';

export default observer(function BookAppointment() {
    // hook
    const { t } = useTranslation();
    // store
    const { appointmentStore: { cleanForAppointment }, petStore: { clean }, shopStore: { setObservable: setShopVarObservable } } = useStore();
    // state
    const [completedSteps, setCompletedSteps] = useState({
        select: true, // Tab 1 luôn mở
        confirm: false,
        success: false
    });
    const [currentValue, setCurrentValue] = useState<'select' | 'confirm' | 'success'>('select');

    // lifecycle
    useEffect(() => {
        return () => {
            cleanForAppointment();
            setShopVarObservable('shopAppointmentInfor', {}, { isMergeObject: false });
            clean();
        };
    }, []);
    // function
    const handleCompleteStep = (step: 'select' | 'confirm' | 'success') => {
        setCompletedSteps(prev => ({
            ...prev,
            [step]: true
        }));
        setCurrentValue(step);
    };
    const handleBackStep = (step: 'select' | 'confirm') => {
        setCurrentValue(step);
    };
    return (
        <div className='md:pt-8'>
            <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.book_an_appointment')} defaultNavigateUrl={ROUTES.home.href} />} />
            <div className='md:block hidden text-center'>
                <h3 className={classNames('title-1 my-auto text-center text-active md:text-2xl')}>{t('words_title.book_an_appointment')}</h3>
            </div>
            <div className={classNames('bg-background-2 rounded-[0.25rem] md:mx-auto md:min-h-[calc(100vh-25rem)] h-full w-full md:mt-2 md:pt-3')}>
                <Tabs value={currentValue} className='w-full h-full flex flex-col'>
                    <TabsList variant='appointment' data-orientation='horizontal' className={classNames('flex w-full overflow-x-auto bg-white sticky top-13 left-0 z-20')}>
                        <TabsTrigger variant='appointment' className='w-full' value='select'>{t('words_title.tab_select_date_time')}</TabsTrigger>
                        <TabsTrigger disabled={!completedSteps.confirm} variant='appointment' className='w-full' value='confirm'>{t('words_title.tab_confirm_appointment')}</TabsTrigger>
                        <TabsTrigger disabled={!completedSteps.success} variant='appointment' className='w-full' value='success'>{t('words_title.tab_success')}</TabsTrigger>
                    </TabsList>

                    <TabsContent value='select'>
                        <InputAppointment onComplete={() => handleCompleteStep('confirm')} />
                    </TabsContent>
                    <TabsContent value='confirm'>
                        <ConfirmAppoinment onBackStep={() => handleBackStep('select')} onComplete={() => handleCompleteStep('success')} />
                    </TabsContent>
                    <TabsContent value='success'>
                        <BookSuccess />
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    );
});

import { AppointmentApi } from '@/apis';
import BaseStore from './BaseStore';
import RootStore from '.';
import { action, flow, makeObservable, observable } from 'mobx';
import { ResponseData } from '@/types/http';
import { BookAppointmentRequest, UpdateAppointmentInforRequest } from '@/types/http-payload/appointment';
import { AppointmentDate, AppointmentInfo, ShopServiceAppointment } from '@/types/shop';
import { SearchAppointmentsParam, GetAllAppointmentsResponse, UpdateAppointmentStatusRequest } from '@/types/http-payload/shop';
import { TableState } from '@tanstack/react-table';
import { DEFAULT_PAGINATION } from '@/configs/constants';

export default class AppointmentStore extends BaseStore {
    api: AppointmentApi;

    // book appointment
    appointmentFormData: any = {};
    serviceList: ShopServiceAppointment[] = [];
    appointmentTimesOfDay: AppointmentDate[] = [];
    isConfirmTab: boolean = false;

    // appointment for shop
    serviceAppointmentList: ShopServiceAppointment[] = [];
    appointment: AppointmentInfo | null = null;
    appointments: AppointmentInfo[] = [];
    totalPageAppointments: number = 0;

    // my appointment
    userAppointment: AppointmentInfo | null = null;
    userAppointments: AppointmentInfo[] = [];
    totalMyAppointmentsPage: number = 0;
    totalMyAppointmentsRecord: number = 0;
    defaultMyAppointmentPaging: TableState = {
        pagination: {
            pageIndex: 0,
            pageSize: 20
        },
        sorting: [{}]
    } as TableState;

    myAppointmentPaging: TableState = this.defaultMyAppointmentPaging;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            isConfirmTab: observable,
            appointmentFormData: observable,
            serviceAppointmentList: observable,
            serviceList: observable,
            appointmentTimesOfDay: observable,
            appointment: observable,
            appointments: observable,
            totalPageAppointments: observable,
            userAppointment: observable,
            userAppointments: observable,
            totalMyAppointmentsPage: observable,
            myAppointmentPaging: observable,
            confirmOrCompleteAppointment: flow.bound,
            getAllAppointments: flow.bound,
            getAppointmentDetail: flow.bound,
            updateAppointmentStatus: flow.bound,
            updateAppointmentInfor: flow.bound,
            getAllUserAppointments: flow.bound,
            getUserAppointmentDetail: flow.bound,
            updateUserAppointmentStatus: flow.bound,
            cleanForAppointment: action.bound
        });
        this.api = new AppointmentApi();
    }

    *confirmOrCompleteAppointment(payload: BookAppointmentRequest, callBackErrorFn?: Function) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(
                this.api,
                this.api.createAppointment,
                payload,
                { disableAlertError: true }
            );
            return res;
        } catch (error) {
            callBackErrorFn && callBackErrorFn(error);
        }
    }

    // #region shop
    *getAllAppointments(payload?: SearchAppointmentsParam, paging?: TableState) {
        try {
            payload = {
                ...this.convertPagingFromTableToRequest(paging ?? {
                    ...this.paging,
                    pagination: {
                        ...this.paging.pagination,
                        pageSize: 20
                    }
                }), ...payload
            };
            const res: ResponseData<GetAllAppointmentsResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllAppointments, payload);
            if (res?.ok) {
                this.appointments = res?.data?.elements || [];
                this.paging = {
                    ...this.paging,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalRecord = res?.data?.pagination?.totalRecord;
                this.totalPageAppointments = res?.data?.pagination?.totalPage;
                return res;
            }
        } catch (error) {
        }
    }

    *getAppointmentDetail(code: string) {
        try {
            const res: ResponseData<AppointmentInfo> = yield this.rootStore.apiStore.call(this.api, this.api.getAppointmentDetail, code);
            if (res?.ok) {
                this.appointment = res?.data;
                return res?.data;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *updateAppointmentStatus(payload: UpdateAppointmentStatusRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updateAppointmentStatus, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *updateAppointmentInfor(payload: UpdateAppointmentInforRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updateUserAppointmentInfor, payload);
            if (res) {
                return res;
            }
        } catch (error) {
        }
    }
    // #endregion

    // #region user
    *getAllUserAppointments(payload?: any, paging?: TableState) {
        try {
            payload = { ...this.convertPagingFromTableToRequest(paging ?? this.myAppointmentPaging), ...payload };
            const res: ResponseData<GetAllAppointmentsResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllUserAppointments, payload);
            if (res?.ok) {
                this.userAppointments = res?.data?.elements || [];
                this.myAppointmentPaging = {
                    ...this.myAppointmentPaging,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalMyAppointmentsRecord = res?.data?.pagination?.totalRecord;
                this.totalMyAppointmentsPage = res?.data?.pagination?.totalPage;
            }
        } catch (error) {
        }
    }

    *getUserAppointmentDetail(code: string) {
        try {
            const res: ResponseData<AppointmentInfo> = yield this.rootStore.apiStore.call(this.api, this.api.getUserAppointmentDetail, code);
            if (res?.ok) {
                this.userAppointment = res?.data;
                return res?.data;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *updateUserAppointmentStatus(payload: UpdateAppointmentStatusRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updateUserAppointmentStatus, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    // #endregion
    clean() {
        super.clean();

        this.myAppointmentPaging = this.defaultMyAppointmentPaging;
        this.userAppointment = null;
        this.userAppointments = [];
        this.appointment = null;
        this.appointments = [];
    }

    cleanForAppointment() {
        this.appointment = null;
        this.appointmentFormData = {};
        this.serviceList = [];
        this.appointmentTimesOfDay = [];
        this.isConfirmTab = false;
    }
}

import { PetInfoRequest, PetInfoResponse, SpeciesResponse } from '@/types/http-payload/pet';
import BaseStore from './BaseStore';
import { flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import { ResponseData } from '@/types/http';
import { PetApi } from '@/apis';

export default class PetStore extends BaseStore {
    pets: PetInfoResponse[] = [];
    petDetail?: PetInfoResponse | null = null;
    petSpecies: SpeciesResponse[] = [];
    api: PetApi;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            pets: observable,
            petDetail: observable,
            addPet: flow.bound,
            getAllPet: flow.bound,
            getAllSpecies: flow.bound,
            getDetailPet: flow.bound,
            deletePet: flow.bound,
            editPet: flow.bound
        });
        this.api = new PetApi();
    }

    *addPet(payload: PetInfoRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.addPet, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *getAllPet() {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getAllPet);
            if (res?.ok) {
                this.pets = res?.data;
                return res?.data;
            }
            return false;
        } catch (error) {
        }
    }

    *getDetailPet(code: string) {
        try {
            const res: ResponseData<PetInfoResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getDetailPet, code);
            if (res?.ok) {
                this.petDetail = res.data;
                return res?.data;
            }
            return false;
        } catch (error) {
        }
    }

    *getAllSpecies() {
        try {
            const res: ResponseData<SpeciesResponse[]> = yield this.rootStore.apiStore.call(this.api, this.api.getAllSpecies);
            if (res?.ok) {
                this.petSpecies = res?.data;
            }
        } catch (error) {
        }
    }

    *deletePet(code: string) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.deletePet, code);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    };

    *editPet(data) {
        try {
            const res: ResponseData<PetInfoResponse> = yield this.rootStore.apiStore.call(this.api, this.api.editPet, data);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    };

    clean(): void {
        super.clean();
        this.pets = [];
        this.petDetail = null;
    }
}

import { Button } from '@/components/common/Button';
import { Checkbox } from '@/components/common/Checkbox';
import Combobox from '@/components/common/Combobox';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/common/Popover';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { PHONE_VALIDATE_REGEX, SHOP_CATEGORY, REPRESENTATIVE_ROLE, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import DisabledButton from '@/pages/my-shop/components/DisabledButton';
import yup from '@/services/yup';
import { INFO_TYPE, LANGUAGES_SUPPORTED, REPRESENTATIVE_ROLE_TYPE, SHOP_STATUS_TYPE } from '@/types/enums';
import { GetGeneralInfoResponse } from '@/types/http-payload/shop';
import { toastify } from '@/utils/toastify';
import { cn, removeVietnameseAccents, scrollToTop } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { MapPinIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

interface GeneralInformationTabProps {
    callbackSuccess?: () => void,
    setShopCategory?: Function
}

export default observer(function GeneralInformationTab({ callbackSuccess, setShopCategory }: GeneralInformationTabProps) {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const navigate = useNavigate();
    const sessionToken = uuidv4();

    // store
    const {
        generalStore: { administrativeUnit, getPlaceAutoComplete },
        shopStore: { shopInfo, getShopInfo, updateGeneralInfo, requestApprove, setObservable, createShop, isEditMode },
        authStore: { isChannelShopOwner, profile, getProfile },
        modalStore: { showAlertModal, hideModal }
    } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        name: yup
            .string()
            .required('form_error_validate.required'),
        address: yup
            .string()
            .required('form_error_validate.required'),
        provinceName: yup
            .string()
            .required('form_error_validate.required'),
        provinceCode: yup
            .string()
            .required('form_error_validate.required'),
        category: yup
            .string()
            .required('form_error_validate.required'),
        taxCode: yup
            .string()
            .required('form_error_validate.required')
            .max(13, 'form_error_validate.tax_code_maxlength'),
        phoneNumber: yup
            .string()
            .required('form_error_validate.required')
            .matches(PHONE_VALIDATE_REGEX, 'form_error_validate.format_phone'),
        zaloId: yup
            .string(),
        // owner
        ownerName: yup
            .string()
            .required('form_error_validate.required'),
        ownerPhoneNumber: yup
            .string()
            .required('form_error_validate.required')
            .matches(PHONE_VALIDATE_REGEX, 'form_error_validate.format_phone'),
        // representative
        representativeName: yup
            .string()
            .required('form_error_validate.required'),
        representativePhoneNumber: yup
            .string()
            .required('form_error_validate.required')
            .matches(PHONE_VALIDATE_REGEX, 'form_error_validate.format_phone'),
        representativeRole: yup
            .string()
            .required('form_error_validate.required'),
        representativeEmail: yup
            .string()
            .email('form_error_validate.format_email')
    });

    type FormData = yup.InferType<typeof validateSchema>;

    const initialValues = {
        name: '',
        address: '',
        provinceName: '',
        provinceCode: '',
        category: '',
        taxCode: '',
        phoneNumber: '',
        zaloId: '',
        ownerName: '',
        ownerPhoneNumber: '',
        representativeName: '',
        representativePhoneNumber: '',
        representativeRole: '',
        representativeEmail: ''
    };

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: initialValues
    });

    // state
    const [isOwnerAlsoPresentative, setIsOwnerAlsoPresentative] = useState<boolean>(false);
    const [placesSearch, setPlacesSearch] = useState<any[]>([]);
    const [openPopoverSearch, setOpenPopoverSearch] = useState<boolean>(false);
    const [placeId, setPlaceId] = useState<string>('');

    // lifecycle
    useEffect(() => {
        if (isChannelShopOwner) {
            fetchData();
        }
    }, [isChannelShopOwner]);

    useEffect(() => {
        getProfile();
    }, []);

    useEffect(() => {
        if (!isChannelShopOwner && profile?.alreadyOwnShop) {
            showAlertModal({
                id: 'alert-already-shop',
                type: 'error',
                size: 'lg',
                content: t('sentences.pet_duplicate_create_shop'),
                variantSaveBtn: 'default',
                saveButtonClassName: 'w-64',
                saveButton: t('button.back_to_home'),
                footerClassName: 'flex items-center sm:justify-around w-full mt-5',
                onSave: () => {
                    hideModal('alert-already-shop');
                    navigate(ROUTES.home.href);
                }
            });
        }
    }, [profile]);

    useEffect(() => {
        if (shopInfo) {
            const { name, address, province, category, taxCode, phoneNumber, zaloId, ownerName, ownerPhoneNumber, representativeName, representativePhoneNumber, representativeRole, representativeEmail } = shopInfo as GetGeneralInfoResponse;
            form.reset({
                name: name ?? '',
                address: address ?? '',
                provinceCode: province?.code ?? '',
                provinceName: province?.nameEn,
                category: category ? category.toString() : '',
                taxCode: taxCode ?? '',
                phoneNumber: phoneNumber ?? '',
                zaloId: zaloId ?? '',
                ownerName: ownerName ?? '',
                ownerPhoneNumber: ownerPhoneNumber ? ownerPhoneNumber : phoneNumber ?? '',
                representativeName: representativeName ?? '',
                representativePhoneNumber: representativePhoneNumber ?? '',
                representativeRole: representativeRole ? representativeRole.toString() : '',
                representativeEmail: representativeEmail ?? ''
            });
        }
    }, [shopInfo]);

    useEffect(() => {
        if (placesSearch?.length > 0) {
            setOpenPopoverSearch(true);
        }
    }, [placesSearch]);

    // function
    const fetchData = async () => {
        await flowResult(getShopInfo({ typeInfo: INFO_TYPE.GeneralInfo }));
    };

    const onSubmit = async (data) => {
        const { name, address, provinceCode, category, taxCode, phoneNumber, zaloId, ownerName, ownerPhoneNumber, representativeName, representativePhoneNumber, representativeRole, representativeEmail } = data;
        if (isChannelShopOwner) {
            const res = await flowResult(updateGeneralInfo({
                name, address, provinceCode,
                taxCode, phoneNumber, zaloId, ownerName, ownerPhoneNumber,
                representativeName, representativePhoneNumber,
                category: Number(category),
                representativeRole: Number(representativeRole),
                representativeEmail,
                placeId,
                sessionToken
            }));
            if (res) {
                if (shopInfo?.status === SHOP_STATUS_TYPE.NotCompletedInfo) {
                    toastify('alert-success', t('messages.update_success'));
                    callbackSuccess?.();
                    scrollToTop();
                } else {
                    toastify('alert-success', t('messages.update_success'));
                    fetchData();
                    setObservable('isEditMode', false);
                    scrollToTop();
                }
            }
        } else {
            const res = await flowResult(createShop({
                name, address, provinceCode,
                taxCode, phoneNumber, zaloId, ownerName, ownerPhoneNumber,
                representativeName, representativePhoneNumber,
                category: Number(category),
                representativeRole: Number(representativeRole),
                representativeEmail,
                placeId,
                sessionToken
            }));
            if (res) {
                setShopCategory && setShopCategory(Number(category));
                getProfile();
                callbackSuccess?.();
            }
        }
    };

    const onHandleClickCheckbox = () => {
        const provinceCodeCurrent = form.getValues('provinceCode');
        if (!isOwnerAlsoPresentative) {
            const ownerNameCurrent = form.getValues('ownerName');
            const phoneNumberOwnerCurrent = form.getValues('ownerPhoneNumber');
            form.reset({
                ...form.getValues(),
                representativeName: ownerNameCurrent,
                representativePhoneNumber: phoneNumberOwnerCurrent,
                representativeRole: String(REPRESENTATIVE_ROLE_TYPE.ShopOwner),
                provinceCode: provinceCodeCurrent
            });
        }
        form.trigger(['ownerName', 'ownerPhoneNumber', 'representativeName', 'representativePhoneNumber']);
        setIsOwnerAlsoPresentative(!isOwnerAlsoPresentative);
    };

    const onGetPlaceAutocomplete = async (addr: string) => {
        let data = [];
        try {
            if (addr.trim()) {
                const response = await flowResult(getPlaceAutoComplete({ input: addr.trim(), sessionToken }));
                data = response;
            }
        } catch (error) {
        } finally {
            setPlacesSearch(data);
        }
    };

    const onDebounceGetPlaceAutocomplete = useCallback(
        debounce((addr: string) => {
            onGetPlaceAutocomplete(addr);
        }, 500),
        []
    );

    const handleClickReAppoval = debounce(async () => {
        const resApprove = await flowResult(requestApprove({}, true));
        if (resApprove) {
            setObservable('shopInfo', null);
            navigate(ROUTES.home.href);
        };
    }, 1000, {
        leading: true,
        trailing: false
    });

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={classNames(isEditMode ? 'mt-4' : 'mt-6')}>
                <div className={classNames('bg-white rounded p-4', !isEditMode && 'mt-4')}>
                    <FormField
                        control={form.control}
                        name='name'
                        render={({ field }) => (
                            <FormItem>
                                <Label className='label-1' required>{t('words_title.shop_name')}</Label>
                                <FormControl className='mt-1.5'>
                                    <Input type='text' {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='address'
                        render={({ field }) => (
                            <FormItem className='mt-4'>
                                <Label className='label-1' required>{t('words_title.shop_address')}</Label>
                                <Popover open={openPopoverSearch}>
                                    <PopoverTrigger className='w-full'>
                                        <FormControl className='mt-1.5'>
                                            <Input
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e.target.value);
                                                    onDebounceGetPlaceAutocomplete(field.value);
                                                }}
                                            />
                                        </FormControl>
                                    </PopoverTrigger>
                                    <PopoverContent
                                        className='w-full p-1 !max-w-[900px]'
                                        align='start'
                                        autoFocus={false}
                                        widthFull={true}
                                        onOpenAutoFocus={e => e.preventDefault()}
                                        onPointerDownOutside={() => setOpenPopoverSearch(false)}
                                    >
                                        {
                                            placesSearch?.length > 0 && (
                                                <div className='divide-y divide-gray-200'>
                                                    {
                                                        placesSearch.map((place, index) => (
                                                            <div
                                                                key={index}
                                                                className='py-2 px-1 text-sm flex items-center gap-2 hover:cursor-pointer hover:bg-slate-200'
                                                                onClick={() => {
                                                                    if (place?.address) {
                                                                        const desctructuringAddress = place.address.split(', ');
                                                                        const city = desctructuringAddress[desctructuringAddress.length - 1];
                                                                        const cityAccent = removeVietnameseAccents(city)?.trim();
                                                                        const cityCode = administrativeUnit?.provinces.find(item => item.nameEn === cityAccent ||
                                                                            removeVietnameseAccents(item.fullName) === cityAccent)?.code;

                                                                        if (cityCode) {
                                                                            form.setValue('provinceName', cityAccent);
                                                                            form.setValue('provinceCode', cityCode);
                                                                            form.clearErrors('provinceName');
                                                                            form.clearErrors('provinceCode');
                                                                        }
                                                                        form.setValue('address', place.address);
                                                                    }
                                                                    setPlaceId(place?.placeId);
                                                                    setOpenPopoverSearch(false);
                                                                }}
                                                            >
                                                                <MapPinIcon className='w-4 h-4' />
                                                                <span>{place?.address}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    </PopoverContent>
                                </Popover>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className='flex md:flex-row flex-col md:gap-4 gap-0'>
                        <FormField
                            control={form.control}
                            name='provinceName'
                            render={({ field }) => (
                                <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.city')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Combobox
                                            placeholder={t('placeholder.choose')}
                                            value={field.value}
                                            onChange={(item) => {
                                                field.onChange(item?.value);
                                                form.setValue('provinceCode', item?.code);
                                            }}
                                            items={administrativeUnit?.provinces?.map((item, index) => (
                                                {
                                                    label: language === LANGUAGES_SUPPORTED.English ? item.fullNameEn : item.fullName,
                                                    value: item?.nameEn,
                                                    code: item?.code
                                                }
                                            ))}
                                            required
                                            side='top'
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className='md:w-1/2 w-full'></div>
                    </div>
                    <div className='flex md:flex-row flex-col md:gap-4 gap-0'>
                        <FormField
                            control={form.control}
                            name='category'
                            render={({ field }) => (
                                <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.category')}</Label>
                                    <FormControl>
                                        <Select
                                            key={field.value}
                                            onValueChange={field.onChange}
                                            {...field}
                                            disabled={(shopInfo as GetGeneralInfoResponse)?.isHasMenuPrice ?? false}
                                        >
                                            <SelectTrigger className='mt-1.5'>
                                                <SelectValue placeholder={t('placeholder.choose')}>
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.keys(SHOP_CATEGORY).map(item => (
                                                    <SelectItem
                                                        className='hover:cursor-pointer'
                                                        key={SHOP_CATEGORY[item].key}
                                                        value={item}
                                                    >
                                                        <span>{t(`select_options.${SHOP_CATEGORY[item].key}`)}</span>
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='taxCode'
                            render={({ field }) => (
                                <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.tax_code')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Input
                                            maxLength={13}
                                            type='number'
                                            isTypingOnlyNumber={true}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='flex md:flex-row flex-col md:gap-4 gap-0'>
                        <FormField
                            control={form.control}
                            name='phoneNumber'
                            render={({ field }) => (
                                <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.phone_number')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Input
                                            type='number'
                                            isTypingOnlyNumber={true}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='zaloId'
                            render={({ field }) => (
                                <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                    <Label className='label-1'>{t('words_title.zalo_id')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Input
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <div className='bg-white mt-4 rounded p-4'>
                    <div className='flex md:flex-row flex-col gap-4'>
                        <FormField
                            control={form.control}
                            name='ownerName'
                            render={({ field }) => (
                                <FormItem className='flex flex-col md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.owner_name')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Input
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='ownerPhoneNumber'
                            render={({ field }) => (
                                <FormItem className='flex flex-col md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.phone_number')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Input
                                            type='number'
                                            isTypingOnlyNumber={true}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='flex items-center mt-4'>
                        <Checkbox
                            checked={isOwnerAlsoPresentative}
                            onCheckedChange={onHandleClickCheckbox}
                        />
                        <Label className='label-1 ml-2 mt-0.5'>{t('sentences.general_tab_check_box')}</Label>
                    </div>
                </div>
                <div className='bg-white mt-4 rounded p-4'>
                    <div className='flex md:flex-row flex-col gap-4'>
                        <FormField
                            control={form.control}
                            name='representativeName'
                            render={({ field }) => (
                                <FormItem className='flex flex-col md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.representative_name')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Input
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='representativePhoneNumber'
                            render={({ field }) => (
                                <FormItem className='flex flex-col md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.phone_number')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Input
                                            type='number'
                                            isTypingOnlyNumber={true}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='flex md:flex-row flex-col md:gap-4 gap-0'>
                        <FormField
                            control={form.control}
                            name='representativeRole'
                            render={({ field }) => (
                                <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                    <Label className='label-1' required>{t('words_title.role')}</Label>
                                    <FormControl>
                                        <Select
                                            key={field.value}
                                            onValueChange={field.onChange}
                                            {...field}
                                        >
                                            <SelectTrigger className='mt-1.5'>
                                                <SelectValue placeholder={t('placeholder.choose')} />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.keys(REPRESENTATIVE_ROLE).map(item => (
                                                    <SelectItem
                                                        className='hover:cursor-pointer'
                                                        key={REPRESENTATIVE_ROLE[item].key}
                                                        value={item}
                                                    >
                                                        <span>{t(`select_options.${REPRESENTATIVE_ROLE[item].key}`)}</span>
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='representativeEmail'
                            render={({ field }) => (
                                <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                    <Label className='label-1'>{t('words_title.email')}</Label>
                                    <FormControl className='mt-1.5'>
                                        <Input
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <div className={classNames('flex md:flex-row flex-col-reverse items-center md:px-0 px-4 mt-4 md:mb-0 mb-4', shopInfo?.status === SHOP_STATUS_TYPE.Active ? 'justify-center md:gap-6 gap-4' : 'justify-between md:gap-0 gap-4')}>
                    <Button
                        variant='clear'
                        type='button'
                        className='px-4 py-2 md:min-w-44 md:w-fit w-full'
                        onClick={() => {
                            if (shopInfo?.status === SHOP_STATUS_TYPE.Active) {
                                setObservable('isEditMode', false);
                                scrollToTop();
                            } else {
                                navigate(ROUTES.home.href);
                            }
                        }}
                    >
                        {shopInfo?.status === SHOP_STATUS_TYPE.Active ? t('button.cancel') : t('button.back_to_home')}
                    </Button>
                    <div className='flex flex-row md:justify-center items-center gap-6 md:w-fit w-full'>
                        {
                            shopInfo?.status === SHOP_STATUS_TYPE.DeniedApprove && (
                                <DisabledButton
                                    text={t('button.re_apporval')}
                                    disabled={form.formState.isDirty}
                                    onClick={handleClickReAppoval}
                                    buttonClassname='px-4 py-2'
                                    tooltipTriggerClassname='md:w-fit w-1/2'
                                />
                            )
                        }
                        <Button type='submit' variant='submit' size='submit' disabled={form.formState.isSubmitting} className={cn('px-4 py-2 md:min-w-44', shopInfo?.status === SHOP_STATUS_TYPE.DeniedApprove ? 'w-1/2' : 'w-full')}>
                            {shopInfo?.status === SHOP_STATUS_TYPE.NotCompletedInfo || !isChannelShopOwner ? t('button.save_and_next') : t('button.save')}
                        </Button>
                    </div>
                </div>
            </form>
        </Form>
    );
});

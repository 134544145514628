import ApiService from './ApiService';

export default class ReviewApi extends ApiService {
    // #region Pet Owner
    getAllMyReview(payload) {
        return this.get('my-reviews', payload);
    }

    getReviewDetail(id) {
        return this.get(`my-reviews/${id}`);
    }

    editReviewDetail(payload) {
        const { id, ...data } = payload;
        return this.put(`my-reviews/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    deleteReview(id) {
        return this.delete(`my-reviews/${id}`);
    }

    // #endregion

    // #region Shop Owner

    getAllReviewForReviewManagement(payload) {
        return this.get('my-shop/reviews', payload);
    }

    markTopReview(id) {
        return this.put(`my-shop/reviews/mark-top/${id}`);
    }

    reportReview(payload) {
        return this.post('my-shop/reviews/report', payload);
    }

    // #endregion
}

import { observer } from 'mobx-react-lite';
import MyAccountItem from './MyAccountItem';
import { Button } from '@/components/common/Button';
import { CalendarIcon, MailIcon, MapPinHouseIcon, MapPinIcon, PhoneIcon, UserIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DISPLAY_MOMENT_DATE_YMD, ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { Profile } from '@/types/auth';
import { formatDateTime } from '@/utils/datetime';
import { LANGUAGES_SUPPORTED } from '@/types/enums';
type Props = {
    profile?: Profile
};
export default observer(function AccountInfoDesktop({ profile }: Props) {
    // hooks
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t, i18n: { language } } = useTranslation();

    return (
        <div className='pb-[1.5rem]'>
            <div className='px-4 py-6 title-3'>{t('words_title.account_infomation')}</div>
            <div className='lg:flex block gap-[0.625rem]'>
                <MyAccountItem borderBottom={true} className='lg:w-1/2 w-full' icon={UserIcon} title={t('words_title.full_name_2')} desc={profile?.fullName} />
                <MyAccountItem borderBottom={true} className='lg:w-1/2 w-full' icon={CalendarIcon} title={t('words_title.date_of_birth')} desc={formatDateTime((profile?.birthday ?? null), DISPLAY_MOMENT_DATE_YMD)} />
            </div>
            <div className='lg:flex block gap-[0.625rem]'>
                <MyAccountItem borderBottom={true} className='lg:w-1/2 w-full' icon={PhoneIcon} title={t('words_title.phone_number')} desc={profile?.phoneNumber} buttonRight={t('button.change_2')} urlButtonRight={ROUTES.myAccountEditPhone.href} />
                <MyAccountItem borderBottom={true} className='lg:w-1/2 w-full' icon={MailIcon} title={t('words_title.email')} desc={profile?.email} />
            </div>
            <div className='lg:flex block gap-[0.625rem]'>
                <MyAccountItem borderBottom={true} className='lg:w-1/2 w-full' icon={MapPinIcon} title={t('words_title.city')} desc={language === LANGUAGES_SUPPORTED.English ? profile?.province?.fullNameEn : profile?.province?.fullName} />
                <MyAccountItem borderBottom={true} className='lg:w-1/2 w-full' icon={MapPinHouseIcon} title={t('words_title.district')} desc={language === LANGUAGES_SUPPORTED.English ? profile?.district?.fullNameEn : profile?.district?.fullName} />
            </div>
            <div className='lg:flex block gap-[0.625rem]'>
                <MyAccountItem borderBottom={true} className='lg:w-1/2 w-full' icon={MapPinHouseIcon} title={t('words_title.ward')} desc={language === LANGUAGES_SUPPORTED.English ? profile?.ward?.fullNameEn : profile?.ward?.fullName} />
                <MyAccountItem borderBottom={true} className='lg:w-1/2 w-full' icon={MapPinHouseIcon} title={t('words_title.address')} desc={profile?.address} />
            </div>
            <div className='mx-4 mt-6 flex justify-center'>
                <Button className='h-9.5 w-1/2' variant='outline' onClick={() => navigate(ROUTES.myAccountEdit.href, { state: { previousUrl: pathname } })}>
                    <span className='font-medium'>{t('button.edit_account_info')}</span>
                </Button>
            </div>
        </div>
    );
});

import { action, flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { ResponseData } from '@/types/http';
import { ShopApi } from '@/apis';
import { GetAllShopResponse, GetShopInfoRequest, GetShopResponse, GetStatisticsAppointmentByServicesRequest, GetStatisticsAppointmentByStatusRequest, GetStatisticsAppointmentByViewRequest, RequestApproveRequest, SearchShopParams, ShopDetailResponse, UpdateAppointmentInfoRequest, UpdateFavoriteShopsRequest, UpdateGeneralInfoRequest, UpdateOperationInfoRequest, UpdateShopInfoRequest } from '@/types/http-payload/shop';
import { DEFAULT_PAGINATION } from '@/configs/constants';
import { ShopCardInfo, AllReviewShopResponse } from '@/types/shop';
import { TableState } from '@tanstack/react-table';

export default class ShopStore extends BaseStore {
    api: ShopApi;
    // for my-shop
    shopInfo?: GetShopResponse;
    shopCode?: string;
    isEditMode?: boolean;
    // for all shop
    allShop: ShopCardInfo[] = [];
    nearByShops: ShopCardInfo[] = [];
    topService: ShopCardInfo[] = [];
    shopAppointmentInfor: any = {};
    shopDetail: ShopDetailResponse = {} as ShopDetailResponse;
    allReviewShop: AllReviewShopResponse[] = [];

    allFavoriteShop: ShopCardInfo[] = [];

    // Top service paging
    defaultTopServicePaging: TableState = {
        pagination: DEFAULT_PAGINATION,
        sorting: [{}]
    } as TableState;

    totalTopServiceRecord: number = 0;
    pagingTopService: TableState = this.defaultTopServicePaging;

    // nearby paging
    defaultShopPaging: TableState = {
        pagination: {
            pageIndex: 0,
            pageSize: 20
        },
        sorting: [{}]
    } as TableState;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            shopInfo: observable,
            shopCode: observable,
            isEditMode: observable,
            allShop: observable,
            allReviewShop: observable,
            shopDetail: observable,
            topService: observable,
            totalPage: observable,
            shopAppointmentInfor: observable,
            allFavoriteShop: observable,
            totalTopServiceRecord: observable,
            nearByShops: observable,
            getShopInfo: flow.bound,
            updateGeneralInfo: flow.bound,
            updateShopInfo: flow.bound,
            updateOperationInfo: flow.bound,
            updateAppointmentInfo: flow.bound,
            requestApprove: flow.bound,
            createShop: flow.bound,
            setAllShop: action.bound,
            getAllShop: flow.bound,
            getTopServiceShop: flow.bound,
            updateFavoriteShops: flow.bound,
            getShopDetailForAppointment: flow.bound,
            getShopDetail: flow.bound,
            postReview: flow.bound,
            getAllReviewForShopDetail: flow.bound,
            getStatisticsAppointmentByStatus: flow.bound,
            getStatisticsAppointmentByService: flow.bound,
            getStatisticsAppointmentByView: flow.bound,
            cleanAllShop: action.bound

        });
        this.api = new ShopApi();
        this.paging = this.defaultShopPaging;
    }

    // #region my-shop
    *getShopInfo(params?: GetShopInfoRequest) {
        try {
            const res: ResponseData<GetShopResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getShopInfo, params);
            if (res?.ok) {
                this.shopInfo = {
                    ...this.shopInfo,
                    ...res?.data
                };
                return res;
            }
        } catch (error) {
        }
    }

    *updateGeneralInfo(payload: UpdateGeneralInfoRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updateGeneralInfo, payload);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    }

    *updateShopInfo(payload: UpdateShopInfoRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updateShopInfo, payload);
            if (res?.ok) {
                return res;
            }
        } catch (error) {
        }
    }

    *updateOperationInfo(payload: UpdateOperationInfoRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updateOperationInfo, payload);
            if (res?.ok) {
                return res;
            }
        } catch (error) {
        }
    }

    *updateAppointmentInfo(payload: UpdateAppointmentInfoRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updateAppointmentInfo, payload);
            if (res?.ok) {
                return res;
            }
        } catch (error) {
        }
    }

    *requestApprove(payload: RequestApproveRequest, isReRequestApprove: boolean = false) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.requestApprove, {
                ...payload,
                isReRequestApprove
            });
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    }

    *createShop(payload: UpdateGeneralInfoRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.createShop, payload);
            if (res?.ok) {
                this.shopCode = res.data.shopCode;
                return true;
            }
        } catch (error) {
        }
    }

    // #endregion

    // #region all-shop

    *getAllShop(searchParams: SearchShopParams, paging: TableState, isHideLoading: boolean = true) {
        try {
            const payload = { ...this.convertPagingForSearchShopToRequest(paging ?? this.paging), ...searchParams };
            const res: ResponseData<GetAllShopResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllShop, payload, { hideLoading: isHideLoading });
            if (res?.ok) {
                this.paging = {
                    ...this.paging,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalRecord = res?.data?.pagination?.totalRecord;
                this.totalPage = res?.data?.pagination?.totalPage;
                if (searchParams.isFavorite) {
                    this.allFavoriteShop = res?.data?.elements || [];
                }
                return res?.data;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *getTopServiceShop(paging: TableState) {
        try {
            const payload = { ...this.convertPagingForSearchShopToRequest(paging ?? this.pagingTopService) };
            const res: ResponseData<GetAllShopResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllShop, {
                ...payload,
                isHomePage: true
            });
            if (res?.ok) {
                this.topService = res?.data?.elements || [];
                this.pagingTopService = {
                    ...this.pagingTopService,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalTopServiceRecord = res?.data?.pagination?.totalRecord;
            }
        } catch (error) {

        }
    }

    *getShopDetail(slug: string, isHideLoading: boolean = false, isGetPageDetail: boolean = false) {
        try {
            const res: ResponseData<ShopDetailResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getShopDetail, { slug, isGetPageDetail }, { hideLoading: isHideLoading });
            if (res?.ok) {
                this.shopDetail = res.data;
                return res.data;
            }
            return false;
        } catch (error) {
        }
    }

    *updateFavoriteShops(payload: UpdateFavoriteShopsRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updateFavoriteShops, payload, { hideLoading: true });
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    }

    *getShopDetailForAppointment(payload, callBackError?: Function) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(
                this.api,
                this.api.getShopDetail,
                payload
            );
            if (res?.ok) {
                this.shopAppointmentInfor = res.data;
                return res.data;
            }
        } catch (error) {
            callBackError && callBackError(error);
        }
    }

    setAllShop(data: ShopCardInfo[]) {
        this.allShop = data;
    }

    *postReview(payload) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.postReview, payload);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    }

    *getAllReviewForShopDetail(slug: string, paging?: TableState) {
        try {
            const payload = { ...this.convertPagingFromTableToRequest(paging ?? this.paging), slug };
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getAllReviewForShopDetail, payload, { hideLoading: true });
            if (res?.ok) {
                this.paging = {
                    ...this.paging,
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalRecord = res?.data?.pagination?.totalRecord;
                this.totalPage = res?.data?.pagination?.totalPage;
                this.allReviewShop = res?.data?.elements || [];
                return res;
            }
        } catch (error) {
        }
    }

    // #endregion

    // #region statistics
    *getStatisticsAppointmentByStatus(params?: GetStatisticsAppointmentByStatusRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getStatisticsAppointmentByStatus, params);
            if (res?.ok) {
                return res;
            }
        } catch (error) {
        }
    }

    *getStatisticsAppointmentByService(params?: GetStatisticsAppointmentByServicesRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getStatisticsAppointmentByService, params);
            if (res?.ok) {
                return res;
            }
        } catch (error) {
        }
    }

    *getStatisticsAppointmentByView(params?: GetStatisticsAppointmentByViewRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getStatisticsAppointmentByView, params);
            if (res?.ok) {
                return res;
            }
        } catch (error) {
        }
    }

    // #endregion

    convertPagingForSearchShopToRequest(paging: TableState) {
        let request = {
            page: paging.pagination.pageIndex + 1,
            size: paging.pagination.pageSize
        } as any;
        if (paging.sorting.filter(sort => sort.id)?.[0]?.id) {
            request.sorting = paging.sorting.filter(sort => sort.id)?.[0].id;
        }
        return request;
    }

    cleanAllShop(): void {
        this.paging = this.defaultShopPaging;
        this.allShop = [];
        this.nearByShops = [];
    }

    clean(): void {
        super.clean();

        this.shopAppointmentInfor = {};
        this.allShop = [];
        this.allReviewShop = [];
        this.topService = [];
        this.pagingTopService = this.defaultTopServicePaging;
    }
}

import ChatButton from '@/components/general/ChatButton';
import ContainerBody from '@/components/layout/ContainerBody';
import { useStore } from '@/hooks/useStore';
import ShopDetailUtils from '@/pages/shop-detail/components/ShopDetailUtils';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

export default observer(function ShopDetailPanelLayout() {
    // store
    const { uiStore: { devicesScreen: { desktop } }, authStore: { token, isChannelShopOwner } } = useStore();

    return (
        <>
            <ContainerBody
                showHeader={desktop}
                showFooter={desktop}
            >
                <Outlet />
            </ContainerBody>
            {token && !isChannelShopOwner && <ChatButton />}
        </>
    );
});

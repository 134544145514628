import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './routers/router';
import { StoreProvider } from './hooks/useStore';
import RootStore from './stores';
import momentTz from 'moment-timezone';

import 'react-datetime/css/react-datetime.css';
import './styles/index.scss';

// set default timezone
momentTz.tz.setDefault('Asia/Ho_Chi_Minh');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(

    <StoreProvider store={new RootStore()}>
        <RouterProvider router={router} />
    </StoreProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import CommingSoonImg from '@/assets/images/commingSoon.png';
import { useTranslation } from 'react-i18next';

export default function CommingSoonPage() {
    const { t } = useTranslation();

    return (
        <div className='flex flex-col gap-4 items-center py-8'>
            <img src={CommingSoonImg} alt='' className='w-[314px] h-auto object-contain' />
            <div className='md:px-0 px-2 max-w-[405px] leading-[1.0625rem] text-[#646464] font-normal text-center'>{t('sentences.comming_soon_page')}</div>
        </div>
    );
}

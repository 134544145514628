import { observer } from 'mobx-react-lite';
import UserAvatarMobile from './UserAvatarMobile';
import MyAccountItem from './MyAccountItem';
import { CheckIcon, ChevronRightIcon, GlobeIcon, HousePlusIcon, StoreIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { LANGUAGES_MAPPER, ROUTES } from '@/configs/constants';
import classNames from 'classnames';
import { useStore } from '@/hooks/useStore';
import { ENDUSER_TYPE } from '@/types/enums';

interface MenuSibarMobileBeforeLoginProps {
    outsideRef: RefObject<any>,
    showLangOptions: boolean,
    setShowLangOptions: Dispatch<SetStateAction<boolean>>,
    handleClickChangeLanguage: Function
}

export default observer(function MenuSibarMobileBeforeLogin({
    outsideRef,
    showLangOptions,
    setShowLangOptions,
    handleClickChangeLanguage
}: MenuSibarMobileBeforeLoginProps) {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const { authStore: { onSwitchToShopChannel } } = useStore();

    return (
        <div>
            <UserAvatarMobile />
            <MyAccountItem
                className='mt-1.5'
                icon={StoreIcon}
                title={t('words_title.shop_centre')}
                iconRight={ChevronRightIcon}
                onClick={onSwitchToShopChannel}
            />
            <MyAccountItem
                className='mt-0.25'
                icon={HousePlusIcon}
                title={t('words_title.register_shop')}
                iconRight={ChevronRightIcon}
                url={ROUTES.signUp.href}
                otherStateUrl={{
                    userType: String(ENDUSER_TYPE.ShopOwner),
                    previousUrl: null
                }}
            />
            <div ref={outsideRef}>
                <MyAccountItem
                    className='mt-1.5'
                    icon={GlobeIcon}
                    title={t('words_title.language')}
                    desc={t(`words_title.${Object.keys(LANGUAGES_MAPPER).find(item => LANGUAGES_MAPPER[item].code === language)}`)}
                    iconRight={ChevronRightIcon}
                    showDropDownOption={showLangOptions}
                    onShowDropDownOption={() => setShowLangOptions(!showLangOptions)}
                />
                {
                    showLangOptions && (
                        <div className='w-full bg-white rounded mt-0.5 px-4 py-2'>
                            {
                                Object.keys(LANGUAGES_MAPPER).map((item, index) => (
                                    <div key={index} className={classNames('relative px-11.5 text-[0.8125rem] leading-[1.125rem] text-text-4 font-normal py-2', language === LANGUAGES_MAPPER[item].code && 'bg-accent text-accent-foreground rounded')} onClick={() => handleClickChangeLanguage(item)}>
                                        {t(LANGUAGES_MAPPER[item].fullname)}
                                        <CheckIcon className={classNames('w-4.5 h-4.5 absolute top-2 left-2', language !== LANGUAGES_MAPPER[item].code && 'hidden')} />
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
});

import Paginate from '@/components/pagination/Paginate';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ReviewItem from './components/ReviewItem';
import Notfound from '@/assets/images/myReviewNotfound.png';
import { ROUTES } from '@/configs/constants';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { useStore } from '@/hooks/useStore';
import { useEffect } from 'react';
import { scrollToTop } from '@/utils/utils';

export default observer(function MyReviewPage() {
    // hook
    const { t } = useTranslation();

    // store
    const {
        authStore: { token },
        reviewStore: { getAllMyReview, allMyReview, paging, totalPage, myReviewPaging, setObservable }
    } = useStore();

    // life cycle
    useEffect(() => {
        getAllMyReview();
        return () => {
            setObservable('myReviewPaging', { ...myReviewPaging, pagination: { ...myReviewPaging.pagination, pageIndex: 0 } }, { isMergeObject: true });
        };
    }, [token]);

    return (
        <>
            <div className='pb-[1.5rem] bg-white'>
                <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.my_review')} defaultNavigateUrl={ROUTES.myAccount.href} />} />
                <div className='px-4 py-6 title-3 hidden md:block md:px-4 md:py-6'>{t('words_title.my_review')}</div>
                {
                    allMyReview.length > 0 && (
                        <>
                            <div className='flex flex-col gap-1 md:gap-0 bg-border-1 md:bg-white'>
                                {
                                    allMyReview.map((item, index) => (
                                        <ReviewItem
                                            key={index}
                                            data={item}
                                        />
                                    ))
                                }
                            </div>
                            <div className='px-4 pt-4 md:pt-0 mx-auto w-full text-center flex justify-center'>
                                <Paginate
                                    forcePage={paging.pagination.pageIndex}
                                    pageCount={totalPage}
                                    onPageChange={({ selected }) => {
                                        myReviewPaging.pagination.pageIndex = selected;
                                        getAllMyReview(myReviewPaging);
                                        scrollToTop();
                                    }}
                                />
                            </div>
                        </>
                    )
                }
                {
                    allMyReview.length === 0 && (
                        <div className='w-full h-[100vh] pt-20 md:pt-0 md:h-auto'>
                            <img className='h-[9.4375rem] w-[16.3125rem] mx-auto' src={Notfound} alt='' />
                            <p className='mt-5 font-medium leading-[21px] text-text-8 text-center'>{t('words_title.no_reviews_2')}</p>
                        </div>
                    )
                }
            </div>
        </>
    );
});

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { Tabs, TabsList, TabsTrigger } from '@/components/common/Tabs';
import FormatNumber from '@/components/react-number-format';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { DAYS_OF_WEEK, MONTH_OF_YEAR, MONTH_OF_YEAR_CHART, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { DAYS_OF_WEEK_TYPE, LANGUAGES_SUPPORTED, MONTH_OF_YEAR_TYPE, STATISTIC_TYPE, StatisticType } from '@/types/enums';
import { formatDateTime } from '@/utils/datetime';
import { convertNumber } from '@/utils/utils';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Line, LineChart, Text, XAxis, YAxis } from 'recharts';

const STATISTIC_APPOINTMENT_BY_STATUS = [
    STATISTIC_TYPE.Weekly,
    STATISTIC_TYPE.Monthly,
    STATISTIC_TYPE.Yearly
];

export default observer(function ShopAppointmentStatisticByView() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const { shopStore: { getStatisticsAppointmentByView } } = useStore();

    // state
    const [data, setData] = useState<any>();
    const [statisticType, setStatisticType] = useState<StatisticType>(STATISTIC_TYPE.Weekly);
    const [currentYear, setCurrentYear] = useState<string>(formatDateTime(new Date(), 'YYYY') ?? '');
    const [yearListStatisticForMonthly, setYearListStatisticForMonthly] = useState<string[]>([]);

    // lifecycle
    useEffect(() => {
        fetchData();
    }, [statisticType, currentYear]);

    // chart
    const chartConfig = {
        totalViews: {
            label: t('words_title.total_view'),
            color: '#C52B20'
        }
    } satisfies ChartConfig;

    const chartData = useMemo(() => {
        if (data) {
            if (statisticType === STATISTIC_TYPE.Weekly) {
                return Object.values(DAYS_OF_WEEK_TYPE).map((day, index) => {
                    return {
                        key: day,
                        day: language === LANGUAGES_SUPPORTED.English ? DAYS_OF_WEEK[day].shortCutEn : DAYS_OF_WEEK[day].shortCutVi,
                        totalViews: data[index]?.totalViews
                    };
                });
            } else if (statisticType === STATISTIC_TYPE.Monthly) {
                return Object.values(MONTH_OF_YEAR_TYPE).map((month, index) => {
                    return {
                        key: index,
                        month: language === LANGUAGES_SUPPORTED.English ? MONTH_OF_YEAR_CHART[month].shortCut : MONTH_OF_YEAR_CHART[month].shortCut,
                        totalViews: data[month - 1]?.totalViews
                    };
                });
            } else if (statisticType === STATISTIC_TYPE.Yearly) {
                if (data.length > 0) {
                    return data.map((item, index) => {
                        return {
                            key: index,
                            year: item.year,
                            totalViews: item?.totalViews
                        };
                    });
                } else {
                    return [
                        {
                            year: formatDateTime(new Date(), 'YYYY'),
                            totalViews: 0
                        }
                    ];
                }
            }
        }
    }, [data, language]);

    // function
    const fetchData = async () => {
        const res = await flowResult(getStatisticsAppointmentByView({
            statisticType,
            ...(statisticType === STATISTIC_TYPE.Weekly ? { date: formatDateTime(new Date(), SUBMIT_MOMENT_DATE_YMD) ?? '' } : {}),
            ...(statisticType === STATISTIC_TYPE.Monthly ? { year: currentYear ?? '' } : {})
        }));
        if (res?.ok) {
            setData(statisticType === STATISTIC_TYPE.Monthly ? res.data?.data : res.data);
            setYearListStatisticForMonthly(res.data?.years);
        }
    };

    const handleChangeSelect = (value) => {
        setCurrentYear(value.toString());
    };

    const handleClickTab = (value) => {
        setStatisticType(value);
        setCurrentYear(formatDateTime(new Date(), 'YYYY') ?? '');
    };

    const CustomYAxisTick = ({ x, y, payload }: any) => {
        if (payload && payload.value) {
            return (
                <Text
                    x={x}
                    y={y}
                    textAnchor='middle'
                    verticalAnchor='start'
                >
                    {convertNumber(payload.value)}
                </Text>
            );
        }
        return null;
    };

    return (
        <div className='p-4 bg-white rounded mt-4'>
            <div className='text-[0.9375rem] font-semibold pb-4 border-b border-border-12'>{t('words_title.statistic_by_view')}</div>
            <div className='mt-4 flex flex-wrap items-center justify-between gap-4'>
                <Tabs value={statisticType} onValueChange={handleClickTab}>
                    <TabsList variant='statistic' data-orientation='horizontal'>
                        {STATISTIC_APPOINTMENT_BY_STATUS.map((tab, index) =>
                            (<TabsTrigger key={index} value={tab} variant='statistic'>{t(`words_title.${tab}`)}</TabsTrigger>))}
                    </TabsList>
                </Tabs>
                {
                    statisticType === STATISTIC_TYPE.Monthly && (
                        <Select defaultValue={currentYear.toString()} value={currentYear} onValueChange={handleChangeSelect}>
                            <SelectTrigger className='h-10 min-w-32'>
                                <SelectValue>
                                </SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                                {yearListStatisticForMonthly?.length > 0 && yearListStatisticForMonthly.map((item, index) => (
                                    <SelectItem
                                        className='hover:cursor-pointer'
                                        key={index}
                                        value={item.toString()}
                                    >
                                        {item}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    )
                }
            </div>
            <ChartContainer config={chartConfig} className='mt-8 w-full point-920:h-[500px] h-[300px] mx-auto aspect-none'>
                <LineChart
                    accessibilityLayer
                    data={chartData}
                    margin={{
                        left: 4,
                        right: 4
                    }}
                >
                    <CartesianGrid vertical={false} strokeDasharray='4' />
                    <XAxis
                        dataKey={statisticType === STATISTIC_TYPE.Monthly ? 'month' : (statisticType === STATISTIC_TYPE.Weekly ? 'day' : 'year')}
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                        interval='preserveStartEnd'
                    />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        tickMargin={16}
                        width={32}
                        domain={([dataMin, dataMax]) => {
                            return [0, dataMax + (dataMax / 10)];
                        }}
                        tick={<CustomYAxisTick />}
                    />
                    <ChartTooltip
                        cursor={false}
                        content={(
                            <ChartTooltipContent
                                hideLabel
                                formatter={(value, name, item) => {
                                    return (
                                        <div className='flex items-center gap-1'>
                                            <div>
                                                {`${
                                                    statisticType === STATISTIC_TYPE.Weekly ?
                                                        (
                                                            language === LANGUAGES_SUPPORTED.English ?
                                                                DAYS_OF_WEEK[item.payload.key]?.fullNameEn :
                                                                DAYS_OF_WEEK[item.payload.key]?.fullNameVi
                                                        ) :
                                                        (
                                                            statisticType === STATISTIC_TYPE.Monthly ?
                                                                (
                                                                    language === LANGUAGES_SUPPORTED.English ?
                                                                        MONTH_OF_YEAR[item.payload.key + 1]?.fullNameEn :
                                                                        MONTH_OF_YEAR[item.payload.key + 1]?.fullNameVi
                                                                ) :
                                                                item.payload.year
                                                        )
                                                }: `}
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <FormatNumber
                                                    type='text'
                                                    decimalScale={0}
                                                    statusDisplay='text'
                                                    thousandSeparator={true}
                                                    value={value as number}
                                                    className='h-auto font-bold border-none bg-transparent items-center m-0 p-0 max-w-fit'
                                                />
                                                <div>{t('words_title.visitor')}</div>
                                            </div>
                                        </div>
                                    );
                                }}
                                className='min-w-40'
                            />
                        )}
                    />
                    <Line
                        dataKey='totalViews'
                        type='linear'
                        stroke='#C52B20'
                        strokeWidth={2}
                        dot={true}
                    />
                </LineChart>
            </ChartContainer>
        </div>
    );
});

import { PetPrintIcon } from '@/components/icons';
import { cn } from '@/utils/utils';

interface ServiceLabelProps {
    title: string,
    classTitleName?: string,
    className?: string,
    classIconName?: string
}

export default function ServiceLabel({ title, className, classTitleName, classIconName }: ServiceLabelProps) {
    return (
        <div className={cn('flex gap-[6px] items-center', className)}>
            <div>
                <PetPrintIcon className={cn('size-[1.125rem] fill-black text-black', classIconName)}></PetPrintIcon>
            </div>
            <div>
                <label className={cn('text-black font-medium', classTitleName)}>{title}</label>
            </div>
        </div>
    );
}

import { Button } from '@/components/common/Button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import FormatNumber from '@/components/react-number-format';
import { APPOINTMENT_STATUS, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, ROUTES, SHOP_SERVICE_NAME, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { APPOINTMENT_STATUS_TYPE, INFO_TYPE, LANGUAGES_SUPPORTED, SHOP_SERVICE_TYPE } from '@/types/enums';
import { GetAllAppointmentsResponse, GetDashboardResponse } from '@/types/http-payload/shop';
import { AppointmentInfo } from '@/types/shop';
import { formatDateTime } from '@/utils/datetime';
import { TableState } from '@tanstack/react-table';
import classNames from 'classnames';
import { ClockIcon, StarIcon, UserIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '@/assets/images/logo.svg';
import { cn } from '@/utils/utils';
import Phone from '@/assets/images/phone.svg';

export default observer(function ShopTodayAppointmentDashboard() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        shopStore: { shopInfo, getShopInfo },
        appointmentStore: { getAllAppointments }
    } = useStore();

    // state
    const [filterStatus, setFilterStatus] = useState('all');
    const [appointments, setAppointments] = useState<GetAllAppointmentsResponse>();
    const [imageShopUrl, setImageShopUrl] = useState<string>();
    const appointmentList = useMemo(() => {
        if (appointments && appointments?.elements?.length > 0) {
            if (filterStatus !== 'all') {
                return appointments.elements.filter(appointment => appointment.status.toString() === filterStatus);
            } else {
                return appointments.elements;
            }
        }
    }, [filterStatus, appointments]);

    // lifecycle
    useEffect(() => {
        fetchData();
    }, []);

    // function
    const fetchData = async () => {
        const payloadForTotalAppointments = {
            appointmentDate: formatDateTime(new Date(), SUBMIT_MOMENT_DATE_YMD) ?? '',
            statusList: [APPOINTMENT_STATUS_TYPE.NotConfirmed, APPOINTMENT_STATUS_TYPE.Confirmed, APPOINTMENT_STATUS_TYPE.Completed]
        };
        const res = await flowResult(getAllAppointments(payloadForTotalAppointments, {
            pagination: {
                pageIndex: 0,
                pageSize: -1
            },
            sorting: [
                {
                    id: 'appointment_date',
                    desc: false
                },
                {
                    id: 'appointment_time',
                    desc: false
                },
                {
                    id: 'created_at',
                    desc: false
                }
            ]
        } as TableState));
        if (res?.ok) {
            setAppointments(res.data);
        }
        const shopInformation = await flowResult(getShopInfo({
            typeInfo: INFO_TYPE.DashboardShopOwner,
            isHomePage: true
        }));
        if (shopInformation?.ok) {
            setImageShopUrl((shopInformation.data as GetDashboardResponse)?.images?.[0] || Logo);
        }
    };

    const onChangeFilterStatus = (value) => {
        setFilterStatus(value);
    };

    return (
        <>
            <div className='rounded p-4 bg-white'>
                <div className='flex gap-4'>
                    <img className={cn('md:size-28 size-24 rounded-sm object-cover', imageShopUrl === Logo && 'object-contain')} src={imageShopUrl} alt='' />
                    <div className='flex flex-col md:py-[0.4375rem] py-0 flex-1'>
                        <div className='text-active font-semibold md:text-[1.25rem] text-[1rem]'>{(shopInfo as GetDashboardResponse)?.name ?? ''}</div>
                        <div className='mt-[0.5625rem] flex items-center gap-2'>
                            <div className='text-icon-3 md:text-[1.0625rem] text-[0.875rem]'>{(shopInfo as GetDashboardResponse)?.rating?.toFixed(1) ?? ''}</div>
                            <StarIcon className='h-4 w-4 fill-icon-3 text-icon-3' />
                            <div className='text-tab-1 md:text-[1.0625rem] text-[0.875rem] border-l border-tab-1 pl-2'>{`${(shopInfo as GetDashboardResponse)?.totalReviews ?? ''} ${(shopInfo as GetDashboardResponse)?.totalReviews > 1 ? t('words_title.review') : t('words_title.little_review')}`}</div>
                        </div>
                        <Link to={ROUTES.myShop.href} className='text-link-1 underline cursor-pointer mt-auto w-fit text-[0.8125rem]'>{t('button.view_shop_info')}</Link>
                    </div>
                </div>
                <div className='mt-6 bg-background-2 rounded-[0.375rem] px-4 py-2 flex items-center gap-2'>
                    <div className='text-[0.875rem] text-nav-1'>{t('words_title.today_appointment')}</div>
                    <div className='text-icon-16 font-medium md:text-2xl text-[1.125rem]'>{appointments?.elements?.length}</div>
                </div>
                <div className='py-4 border border-border-12 rounded-[0.375rem] flex flex-wrap text-nav-1 mt-4'>
                    <div className='md:w-1/3 w-full px-4 md:text-left text-center'>
                        <div className='text-[0.875rem]'>{t('words_title.upcomming_appoints')}</div>
                        <FormatNumber
                            type='text'
                            decimalScale={0}
                            statusDisplay='text'
                            thousandSeparator={true}
                            value={(shopInfo as GetDashboardResponse)?.totalUpcomingAppointment ?? ''}
                            className='h-10 mt-4 font-medium md:text-2xl text-[1.125rem] border-none bg-transparent items-center m-0 p-0 md:justify-normal justify-center'
                        />
                    </div>
                    <div className='md:w-1/3 w-1/2 border-l border-border-12 px-4'>
                        <div className='text-[0.875rem] md:text-left text-center'>{t('words_title.not_confirmed_appoints')}</div>
                        <FormatNumber
                            type='text'
                            decimalScale={0}
                            statusDisplay='text'
                            thousandSeparator={true}
                            value={(shopInfo as GetDashboardResponse)?.totalNotConfirmAppointment ?? ''}
                            className='h-10 mt-4 font-medium md:text-2xl text-[1.125rem] border-none bg-transparent items-center m-0 p-0 md:justify-normal justify-center'
                        />
                    </div>
                    <div className='md:w-1/3 w-1/2 border-l border-border-12 px-4'>
                        <div className='text-[0.875rem] md:text-left text-center'>{t('words_title.confirmed_appoints')}</div>
                        <FormatNumber
                            type='text'
                            decimalScale={0}
                            statusDisplay='text'
                            thousandSeparator={true}
                            value={(shopInfo as GetDashboardResponse)?.totalConfirmedAppointment ?? ''}
                            className='h-10 mt-4 font-medium md:text-2xl text-[1.125rem] border-none bg-transparent items-center m-0 p-0 md:justify-normal justify-center'
                        />
                    </div>
                </div>
                <div className='py-4 border border-border-12 rounded-[0.375rem] flex text-nav-1 mt-4'>
                    <div className='w-1/2 px-4'>
                        <div className='text-[0.875rem] md:text-left text-center'>{t('words_title.total_review')}</div>
                        <FormatNumber
                            type='text'
                            decimalScale={0}
                            statusDisplay='text'
                            thousandSeparator={true}
                            value={(shopInfo as GetDashboardResponse)?.totalReviews ?? ''}
                            className='h-10 mt-4 font-medium md:text-2xl text-[1.125rem] border-none bg-transparent items-center m-0 p-0 md:justify-normal justify-center'
                        />
                    </div>
                    <div className='w-1/2 border-l border-border-12 px-4'>
                        <div className='text-[0.875rem] md:text-left text-center'>{t('words_title.total_visitor')}</div>
                        <FormatNumber
                            type='text'
                            decimalScale={0}
                            statusDisplay='text'
                            thousandSeparator={true}
                            value={(shopInfo as GetDashboardResponse)?.totalViews ?? ''}
                            className='h-10 mt-4 font-medium md:text-2xl text-[1.125rem] border-none bg-transparent items-center m-0 p-0 md:justify-normal justify-center'
                        />
                    </div>
                </div>
            </div>
            <div className='p-4 bg-white rounded mt-4 min-h-60'>
                <div className='flex xsm:flex-row flex-col xsm:items-center xsm:justify-between'>
                    <div className='text-text-9 font-semibold text-[0.9375rem] leading-[1.3125rem]'>{t('words_title.today_appointment')}</div>
                    <Select
                        value={filterStatus}
                        onValueChange={onChangeFilterStatus}
                        className='xsm:mt-0 mt-4'
                        disabled={appointments && appointments?.elements?.length <= 0}
                    >
                        <SelectTrigger className='font-normal text-[0.8125rem] min-w-44'>
                            <SelectValue>
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent align='end'>
                            <SelectItem
                                className='hover:cursor-pointer'
                                value='all'
                            >
                                <span>{`${t('select_options.all_appointment')} (${appointments?.elements?.length})`}</span>
                            </SelectItem>
                            {Object.values(APPOINTMENT_STATUS_TYPE).filter(status => status !== APPOINTMENT_STATUS_TYPE.Cancelled).map(item => (
                                <SelectItem
                                    className='hover:cursor-pointer'
                                    key={APPOINTMENT_STATUS[item].key}
                                    value={item.toString()}
                                >
                                    <span>{`${t(`select_options.${APPOINTMENT_STATUS[item].key}`)} (${appointments?.elements?.filter(appointment => appointment.status === item).length})`}</span>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                {
                    appointmentList && appointmentList.length > 0 ?
                        (
                            <>
                                <div className='border-t border-border-12 mt-4'></div>
                                {
                                    appointmentList.map((appointment, index) => (
                                        <TodayAppointmentItem
                                            key={index}
                                            appointment={appointment}
                                        />
                                    ))
                                }
                            </>
                        ) :
                        (
                            <div className='text-icon-2 text-center mt-10 text-small'>{t('sentences.today_no_data')}</div>
                        )
                }
            </div>
        </>
    );
});

interface TodayAppointmentItemProps {
    appointment: AppointmentInfo
}

export const TodayAppointmentItem = observer(({ appointment }: TodayAppointmentItemProps) => {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const navigate = useNavigate();

    // store
    const { uiStore: { windowSize: { width } } } = useStore();

    // ref
    const serviceContainerRef = useRef<HTMLDivElement | null>(null);

    // state
    const [secondLineIndex, setSecondLineIndex] = useState<number | null>(null);

    // lifecycle
    useEffect(() => {
        if (serviceContainerRef.current) {
            const spans = serviceContainerRef.current.querySelectorAll('span');
            if (spans.length > 0) {
                let initialOffsetTop = spans[0].offsetTop;
                let lineCount = 1;

                for (let i = 1; i < spans.length; i++) {
                    if (spans[i].offsetTop > initialOffsetTop) {
                        lineCount++;
                        initialOffsetTop = spans[i].offsetTop;
                    }

                    if (lineCount === 2) {
                        setSecondLineIndex(i);
                        break;
                    }
                }
            }
        }
    }, [width]);

    return (
        <div className='p-4 rounded border border-border-8 mt-4'>
            <div className='flex items-center border-b border-border-8 pb-3'>
                <div className='flex gap-1 lg:pr-9 pr-2 lg:w-1/4 w-1/3'>
                    <ClockIcon className={classNames('h-4.5 w-4.5 text-white', APPOINTMENT_STATUS[appointment.status].iconColorClassname)} />
                    <div className={classNames(APPOINTMENT_STATUS[appointment?.status].textColorClassname)}>
                        {formatDateTime(appointment?.appointmentTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                            onlyTime: true,
                            currentformat: DISPLAY_MOMENT_TIME_HMS
                        })}
                    </div>
                </div>
                <div className='flex items-center gap-1 lg:px-9 px-2 border-x-[0.125rem] border-border-8 lg:w-1/2 w-1/3'>
                    <UserIcon className='h-4.5 w-4.5 fill-icon-2 text-icon-2 flex-none' />
                    <div className='text-icon-2 line-clamp-1'>{appointment?.petOwner?.fullName ?? ''}</div>
                </div>
                <div className='flex items-center gap-1 lg:pl-9 pl-2 lg:w-1/4 w-1/3'>
                    <img className='flex-none' src={Phone} alt='' />
                    <div className='text-icon-2 line-clamp-1'>{appointment?.petOwner?.phoneNumber ?? ''}</div>
                </div>
            </div>
            <div className='py-3 flex items-center gap-4 border-b border-dotted border-border-8'>
                <img className='size-[4.5625rem] rounded-sm object-cover' src={appointment?.pet?.image} alt='' />
                <div className='flex flex-col gap-2'>
                    <div className='font-medium text-title-1 line-clamp-1'>{appointment?.pet?.name}</div>
                    <div className='text-small text-tab-1 line-clamp-1'>
                        {appointment?.pet?.species?.id ?
                            (
                                language === LANGUAGES_SUPPORTED.Vietnamese ? appointment.pet.species?.name : appointment.pet.species?.nameEn
                            ) :
                            (
                                appointment?.pet?.species ? appointment.pet.species : t('words_title.other')
                            )}
                    </div>
                    <div ref={serviceContainerRef} className='flex gap-1 flex-wrap items-center'>
                        {
                            appointment.pickUpService && (
                                <PetServiceItem serviceName={t('words_title.pick_up_service')} />
                            )
                        }
                        {
                            appointment?.selectedServices?.length > 0 &&
                            appointment.selectedServices.slice(0, secondLineIndex ?? appointment.selectedServices.length).map((service, index) => (
                                <PetServiceItem
                                    key={index}
                                    serviceName={(service.type === SHOP_SERVICE_TYPE.Cat || service.type === SHOP_SERVICE_TYPE.Dog) ?
                                        t(`select_options.${SHOP_SERVICE_NAME[service.serviceName]?.key}`) :
                                        service?.serviceName}
                                />
                            ))
                        }
                        {secondLineIndex && secondLineIndex < appointment?.selectedServices?.length && (
                            <div>
                                <span
                                    className='font-normal max-w-[20ch] truncate text-text-4'
                                >
                                    {`+${appointment.selectedServices.length - secondLineIndex} ${t('words_title.others')}`}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='flex items-center gap-2 mt-3'>
                <Button
                    variant='outline'
                    className='w-1/3 h-6.5 rounded point-920:hidden block'
                    onClick={() => window.location.href = `tel:${appointment.petOwner.phoneNumber}`}
                >
                    {t('button.make_a_call')}
                </Button>
                <Button
                    variant='outline'
                    className='w-1/3 h-6.5 rounded point-920:hidden block'
                    onClick={() => window.location.href = `sms:${appointment.petOwner.phoneNumber}`}
                >
                    {t('button.send_sms')}
                </Button>
                <Button
                    variant='outline'
                    className='w-1/2 h-6.5 rounded point-920:block hidden'
                    onClick={() => navigate(`${ROUTES.chatForShop.href}/${appointment.petOwner.code}`)}
                >
                    {t('button.chat_now')}
                </Button>
                <Button
                    variant='outline'
                    className='point-920:w-1/2 w-1/3 h-6.5 rounded'
                    onClick={() => window.open(`${ROUTES.appointmentManagement.href}/${appointment.code}`, '_blank')}
                >
                    {t('button.view_detail')}
                </Button>
            </div>
        </div>
    );
});

interface PetServiceItemProps {
    serviceName: string
}

const PetServiceItem = ({ serviceName }: PetServiceItemProps) => {
    return (
        <span className='font-normal rounded-xl bg-background-4 py-1 px-2.5 max-w-[20ch] truncate'>{serviceName}</span>
    );
};

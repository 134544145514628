import { MultiPetPrintIcon } from '@/components/icons';
import FormatNumber from '@/components/react-number-format';
import { DEFAULT_NULL_VALUE, SHOP_SERVICE_2, SHOP_SERVICE_NAME } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { SHOP_SERVICE_NAME_TYPE, SHOP_SERVICE_TYPE } from '@/types/enums';
import { ShopInfoService } from '@/types/shop';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export default observer(function MenuPriceComponent() {
    // hooks
    const { t } = useTranslation();

    // store
    const { shopStore: { shopDetail } } = useStore();

    return (
        <>
            {
                Object.values(SHOP_SERVICE_TYPE).filter(item => item !== SHOP_SERVICE_TYPE.Additional).map((shopServiceType, index) => (
                    <ShopServiceItem
                        key={index}
                        title={t(`select_options.${SHOP_SERVICE_2[shopServiceType]?.key}`)}
                        type={shopServiceType}
                        data={shopDetail?.services?.filter(service => service.type === shopServiceType)}
                    />
                ))
            }
        </>
    );
});

interface ShopServiceItemProps {
    title: string,
    type: number,
    data: ShopInfoService[] | undefined
}

const ShopServiceItem = ({ title, type, data }: ShopServiceItemProps) => {
    // hooks
    const { t } = useTranslation();

    function processPetServices(data) {
        // Khởi tạo một đối tượng để chứa dữ liệu kết quả
        const result: any = [];

        data && data.length > 0 && data.forEach((item) => {
            const weightRange = `${item.petWeightFrom}kg - ${item.petWeightTo === '50+' ? 'up' : item.petWeightTo + 'kg'}`;
            const serviceName = item.serviceName;

            let existingEntry: any = result.find(entry => Object.keys(entry)[0] === weightRange);

            if (!existingEntry) {
                existingEntry = {
                    [weightRange]: {
                        [SHOP_SERVICE_NAME_TYPE.Cut]: null,
                        [SHOP_SERVICE_NAME_TYPE.Shower]: null,
                        [SHOP_SERVICE_NAME_TYPE.Combo]: null,
                        [SHOP_SERVICE_NAME_TYPE.Hotel]: null
                    }
                };
                result.push(existingEntry);
            }
            if (!existingEntry[weightRange][serviceName]) {
                existingEntry[weightRange][serviceName] = item.price;
            } else {
                const duplicateService = {
                    [weightRange]: {
                        [SHOP_SERVICE_NAME_TYPE.Cut]: null,
                        [SHOP_SERVICE_NAME_TYPE.Shower]: null,
                        [SHOP_SERVICE_NAME_TYPE.Combo]: null,
                        [SHOP_SERVICE_NAME_TYPE.Hotel]: null
                    }
                };
                result.push(duplicateService);
                duplicateService[weightRange][serviceName] = item.price;
            }
        });

        return result;
    }

    return (
        <div className=''>
            {
                data && data.length > 0 && (
                    <div className='bg-white px-4 pt-6 pb-4 rounded'>
                        <div className='title flex justify-between items-center bg-white h-auto py-1 border-b border-b-black'>
                            <div className='relative w-fit'>
                                <h3 className='text-[0.9375rem] font-bold'>{title}</h3>
                                <div className='absolute -top-3 -right-4'>
                                    <MultiPetPrintIcon className='w-6 h-6 fill-black' />
                                </div>
                            </div>
                            <p className='text-title-1'>
                                (
                                {t('words_title.currency')}
                                : VND)
                            </p>
                        </div>
                        <div className='pt-3 overflow-x-auto scrollbar-height-4'>
                            <div className='lg:min-w-0 md:min-w-[800px] min-w-[700px]'>
                                {
                                    type === SHOP_SERVICE_TYPE.Cat || type === SHOP_SERVICE_TYPE.Dog ?
                                        (
                                            <div className='grid grid-cols-5 items-center bg-background-3 h-auto py-2 px-1.5 font-medium '>
                                                <div>
                                                    {t('words_title.weight')}
                                                </div>
                                                {
                                                    Object.keys(SHOP_SERVICE_NAME).map((item, index) => (
                                                        <div className='text-right' key={index}>{t(`select_options.${SHOP_SERVICE_NAME[item]?.key}`)}</div>
                                                    ))
                                                }
                                            </div>
                                        ) :
                                        (
                                            <div className='flex items-center justify-end bg-background-3 h-auto py-2 px-1.5 font-medium'>
                                                <div className='w-4/5'>{t('words_title.service_name_2')}</div>
                                                <div className='w-1/5 text-right'>{t('words_title.price2')}</div>
                                            </div>
                                        )
                                }
                            </div>
                            <div className='lg:min-w-0 md:min-w-[800px] min-w-[700px]'>
                                {
                                    type === SHOP_SERVICE_TYPE.Cat || type === SHOP_SERVICE_TYPE.Dog ?
                                        (
                                            processPetServices(data).map((item, index) => (
                                                <ShopServiceRow
                                                    key={index}
                                                    petWeight={Object.keys(item)[0]}
                                                    data={item[Object.keys(item)[0]]}
                                                    type={type}
                                                />
                                            ))
                                        ) :
                                        (
                                            data?.map((item, index) => (
                                                <ShopServiceRow key={index} data={item} type={type} />
                                            ))
                                        )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

interface ShopServiceRowProps {
    data: any,
    petWeight?: string,
    type: number
}

const ShopServiceRow = ({ petWeight, data, type }: ShopServiceRowProps) => {
    // hooks
    const { t } = useTranslation();

    return (
        <>
            {
                (type === SHOP_SERVICE_TYPE.Cat || type === SHOP_SERVICE_TYPE.Dog) ?
                    (
                        <div className='min-h-8.5 border-b border-background-2 px-1.5 grid grid-cols-5 items-center'>
                            <div>{petWeight}</div>
                            {
                                Object.keys(data).map((item, index) => {
                                    if (!data[item]) {
                                        return <div className='text-right' key={index}>{DEFAULT_NULL_VALUE}</div>;
                                    } else {
                                        return (
                                            <FormatNumber
                                                key={index}
                                                type='text'
                                                decimalScale={0}
                                                statusDisplay='text'
                                                thousandSeparator={true}
                                                value={data[item]}
                                                // suffix=' VND'
                                                className='p-0 min-h-8.5 border-none bg-transparent items-center justify-end'
                                            />
                                        );
                                    }
                                })
                            }
                        </div>
                    ) :
                    (
                        <div className='border-b border-background-2 px-1.5 flex items-center'>
                            <div className='w-4/5 py-2'>{`${data.isMedicalService ? `(${t('words_title.medical')}) ` : ''}${data.serviceName}`}</div>
                            {
                                data.price > 0 ?
                                    (
                                        <FormatNumber
                                            type='text'
                                            decimalScale={0}
                                            statusDisplay='text'
                                            thousandSeparator={true}
                                            value={data.price}
                                            // suffix=' VND'
                                            className='w-1/5 p-0 min-h-8.5 border-none bg-transparent items-center justify-end'
                                        />
                                    ) :
                                    (
                                        <div className='w-1/5 py-2 min-h-8.5 text-right'>{t('words_title.by_consul')}</div>
                                    )
                            }
                        </div>
                    )
            }
        </>
    );
};

import ApiService from './ApiService';

export default class PetApi extends ApiService {
    constructor() {
        super('pets');
    }

    addPet(payload) {
        return this.post('', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getAllPet() {
        return this.get('');
    }

    getDetailPet(code) {
        return this.get(code);
    }

    deletePet(code) {
        return this.delete(code);
    }

    editPet(data) {
        const { code, payload } = data;
        return this.put(`${code}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    getAllSpecies(payload) {
        return this.get('species', payload);
    }
}

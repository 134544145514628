import { Button } from '@/components/common/Button';
import { Checkbox } from '@/components/common/Checkbox';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import serviceAppointmentButton from '@/components/general/ServiceAppointmentButton';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import FormatNumber from '@/components/react-number-format';
import { MAX_PRICE_SERVICE, ROUTES, SHOP_SERVICE_NAME } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import useWindowSize from '@/hooks/useWindowSize';
import ServiceLabel from '@/pages/book-appointment/components/ServiceLabel';
import NotFoundPage from '@/pages/not-found';
import yup from '@/services/yup';
import { APPOINTMENT_INFOR_TYPE, INFO_TYPE, SHOP_SERVICE_NAME_TYPE, SHOP_SERVICE_TYPE } from '@/types/enums';
import { UpdateAppointmentInforRequest } from '@/types/http-payload/appointment';
import { GetShopInfoResponse } from '@/types/http-payload/shop';
import { ShopServiceAppointment } from '@/types/shop';
import { toastify } from '@/utils/toastify';
import { convertServicePrice } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { CirclePlus, CircleX } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default observer(function EditServiceList() {
    // hook
    const { t } = useTranslation();
    const [devicesScreen] = useWindowSize({});
    const { mobile, desktop } = devicesScreen;
    const { slug } = useParams();
    const navigate = useNavigate();

    // store
    const { shopStore: { getShopInfo },
        appointmentStore: { updateAppointmentInfor, getAppointmentDetail, appointment, serviceList, serviceAppointmentList, setObservable: setAppointVarObservable }
    } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        selectedServices: yup.array<ShopServiceAppointment>().nullable(),
        additionalService: yup.array().of(
            yup.object().shape({
                type: yup.number(),
                isMedicalService: yup
                    .boolean(),
                serviceName: yup
                    .string()
                    .required('form_error_validate.required'),
                price: yup.lazy((value) => {
                    if (value === '') {
                        return yup
                            .string().nullable().optional();
                    }
                    return yup
                        .number()
                        .positive()
                        .max(MAX_PRICE_SERVICE).nullable().optional();
                })
            })
        ).nullable().optional()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const [deletedSelectedServices, setDeletedSelectedServices] = useState<ShopServiceAppointment[]>([]);
    const [currentServiceList, setCurrentServiceList] = useState<ShopServiceAppointment[]>([]);
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            selectedServices: [],
            additionalService: []
        }
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'additionalService'
    });

    const watchSelectedServices = form.watch('selectedServices');
    const watchAddtionalServices = form.watch('additionalService');

    const watchSelectedServicesJson = useMemo(() => {
        if (watchSelectedServices && watchSelectedServices.length > 0) {
            return watchSelectedServices.map(e => JSON.stringify(e));
        }
        return [];
    }, [JSON.stringify(watchSelectedServices)]);

    const deletedServicesJson = useMemo(() => {
        if (deletedSelectedServices && deletedSelectedServices.length > 0) {
            return deletedSelectedServices.map(e => JSON.stringify(e));
        }
        return [];
    }, [JSON.stringify(deletedSelectedServices)]);

    const showBtnSave = useMemo(() => {
        return watchSelectedServices && watchSelectedServices.length > 0;
    }, [watchSelectedServices?.length]);

    const calculateTotal = useMemo(() => {
        return [...(watchSelectedServices ?? []), ...(watchAddtionalServices ?? [])].reduce((prev, cur) => {
            return cur.price ? prev + Number(cur.price) : prev;
        }, 0);
    }, [JSON.stringify(watchSelectedServices), JSON.stringify(watchAddtionalServices)]);

    const [dogService, catService, medicalService, otherService] = useMemo(() => {
        const dogService: ShopServiceAppointment[] = [],
            catService: ShopServiceAppointment[] = [],
            medicalService: ShopServiceAppointment[] = [],
            otherService: ShopServiceAppointment[] = [];
        if (currentServiceList.length > 0) {
            currentServiceList.forEach((service) => {
                switch (service.type) {
                    case SHOP_SERVICE_TYPE.Dog:
                        dogService.push(service);
                        break;

                    case SHOP_SERVICE_TYPE.Cat:
                        catService.push(service);
                        break;

                    case SHOP_SERVICE_TYPE.Medical:
                        medicalService.push(service);
                        break;

                    case SHOP_SERVICE_TYPE.Other:
                        otherService.push(service);
                        break;

                    default:
                        break;
                }
            });
        }

        return [dogService, catService, medicalService, otherService];
    }, [JSON.stringify(currentServiceList)]);

    // lifecycle
    useEffect(() => {
        if (slug) {
            getShopService();
            getAppointment(slug);
        }
        return () => {
            setAppointVarObservable('serviceAppointmentList', []);
            setAppointVarObservable('serviceList', []);
        };
    }, [slug]);

    useEffect(() => {
        if (serviceAppointmentList.length > 0 || serviceList.length > 0) {
            const additionalServiceAppointmentList: any[] = [];
            const differenceAdditionalServiceAppointmentList: any[] = [];

            serviceAppointmentList.forEach((appointmentService) => {
                if (appointmentService.type === SHOP_SERVICE_TYPE.Additional) {
                    additionalServiceAppointmentList.push(appointmentService);
                } else {
                    differenceAdditionalServiceAppointmentList.push(appointmentService);
                }
            });

            // process additional service
            form.setValue('additionalService', additionalServiceAppointmentList);

            // process difference additional service
            const serviceListJson = serviceList.map(e => JSON.stringify(e));
            const differenceAdditionalServiceAppointmentListJson = differenceAdditionalServiceAppointmentList.map(e => JSON.stringify(e));
            const deletedSelectedServices = differenceAdditionalServiceAppointmentListJson.filter(serviceJson => !serviceListJson.includes(serviceJson)).map(e => JSON.parse(e));

            form.setValue('selectedServices', differenceAdditionalServiceAppointmentList);
            setDeletedSelectedServices(deletedSelectedServices);
            setCurrentServiceList([...deletedSelectedServices, ...serviceList]);
        }
    }, [serviceAppointmentList, serviceList]);

    // function
    const getShopService = async () => {
        const res = await flowResult(getShopInfo({ typeInfo: INFO_TYPE.ShopInfo }));
        if (res && res?.ok) {
            const { services } = res.data as GetShopInfoResponse;
            setAppointVarObservable('serviceList', services || [], { isMergeObject: false });
        }
    };

    const getAppointment = async (slug: string) => {
        const res = await flowResult(getAppointmentDetail(slug));
        if (res) {
            const { selectedServices } = res;
            setAppointVarObservable('serviceAppointmentList', selectedServices || [], { isMergeObject: false });
        }
    };

    const handleAddNewService = () => {
        append({ isMedicalService: false, serviceName: '', price: null, type: SHOP_SERVICE_TYPE.Additional });
    };

    const handleRemoveService = (index: number) => {
        remove(index);
    };

    const onSubmit = async (data: FormData) => {
        const { selectedServices, additionalService } = data;

        if ((selectedServices && selectedServices.length > 0) || (additionalService && additionalService.length > 0)) {
            let data = {
                services: [...(selectedServices ?? []), ...(additionalService ?? [])]
            };

            const payload = {
                type: APPOINTMENT_INFOR_TYPE.service,
                code: slug,
                data
            } as UpdateAppointmentInforRequest;
            const res = await flowResult(updateAppointmentInfor(payload));
            if (res) {
                navigate(`${ROUTES.appointmentManagement.href}/${slug}`);
                toastify('alert-success', t('sentences.update_success'));
            } else {
                getShopService();
            }
        }
    };

    const handleGroomingService = (itemService: ShopServiceAppointment) => {
        const itemServiceJson = JSON.stringify(itemService);
        if (watchSelectedServicesJson.includes(itemServiceJson)) {
            form.setValue('selectedServices', watchSelectedServicesJson.filter(e => e !== itemServiceJson).map(e => JSON.parse(e)));
        } else {
            if (itemService.type === SHOP_SERVICE_TYPE.Cat || itemService.type === SHOP_SERVICE_TYPE.Dog) {
                const updatedServices = (watchSelectedServices ?? []).filter(service => service.serviceName !== itemService.serviceName);
                form.setValue('selectedServices', [...(updatedServices ?? []), itemService]);
            } else {
                form.setValue('selectedServices', [...(watchSelectedServices ?? []), itemService]);
            }
        }
    };

    return (
        <>
            {appointment ?
                (
                    <>
                        <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.edit_service_list')} defaultNavigateUrl={`${ROUTES.appointmentManagement.href}/${slug}`} />} />
                        <div className='h-full w-full mb-4 md:mb-0 md:mt-6 flex flex-col gap-4'>
                            <div className='hidden md:block rounded-sm'>
                                <h3 className='px-4 text-[1.3125rem] text-active font-semibold'>{t('words_title.edit_service_list')}</h3>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <Form {...form}>
                                    <form onSubmit={form.handleSubmit(onSubmit)} className='flex-col flex md:gap-4 bg-background-2'>
                                        <div className='px-4 -mt-[1px] md:pt-0 flex flex-col gap-4 md:pb-6 bg-white md:rounded-sm'>
                                            {dogService.length > 0 && (
                                                <>
                                                    <hr className='border-border-12' />
                                                    <div className='flex flex-col gap-4'>
                                                        <div><ServiceLabel classTitleName='!font-bold' title={t('select_options.shop_service_dog')} /></div>
                                                        <FormField
                                                            control={form.control}
                                                            name='selectedServices'
                                                            render={() => (
                                                                <FormItem className='flex flex-col gap-4'>
                                                                    {[
                                                                        SHOP_SERVICE_NAME_TYPE.Cut,
                                                                        SHOP_SERVICE_NAME_TYPE.Shower,
                                                                        SHOP_SERVICE_NAME_TYPE.Combo,
                                                                        SHOP_SERVICE_NAME_TYPE.Hotel
                                                                    ].map((serviceType) => {
                                                                        const filteredServices = dogService.filter(item => item.serviceName === serviceType.toString());

                                                                        return (
                                                                            <div key={serviceType}>
                                                                                <p className='font-medium'>{t(`select_options.${SHOP_SERVICE_NAME[serviceType].key}`)}</p>
                                                                                <div className='flex gap-3 overflow-x-scroll scrollbar scrollbar-height-2 md:scrollbar-height-4 pb-2'>
                                                                                    {filteredServices.length > 0 ?
                                                                                        (
                                                                                            filteredServices.map((itemService, index) => (
                                                                                                <FormField
                                                                                                    key={index}
                                                                                                    control={form.control}
                                                                                                    name='selectedServices'
                                                                                                    render={() => {
                                                                                                        const isActive = watchSelectedServicesJson.length > 0 && watchSelectedServicesJson.includes(JSON.stringify(itemService));
                                                                                                        const isDelete = deletedServicesJson.length > 0 && deletedServicesJson.includes(JSON.stringify(itemService));
                                                                                                        return (
                                                                                                            <FormItem className='mt-3'>
                                                                                                                <FormControl>
                                                                                                                    <Button
                                                                                                                        variant={isActive ? 'active' : 'option'}
                                                                                                                        onClick={() => handleGroomingService(itemService)}
                                                                                                                        type='button'
                                                                                                                        className='flex flex-col gap-2 px-3 py-2 items-start lg:w-[11.875rem] md:w-[10.625rem] w-[11.25rem] h-[3.75rem] group'
                                                                                                                        title={isDelete ? t('words_title.deleted') : ''}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            serviceAppointmentButton({
                                                                                                                                petWeightFrom: itemService.petWeightFrom,
                                                                                                                                petWeightTo: itemService.petWeightTo,
                                                                                                                                price: itemService.price || 0,
                                                                                                                                active: isActive,
                                                                                                                                isDelete,
                                                                                                                                t
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </Button>
                                                                                                                </FormControl>
                                                                                                            </FormItem>
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            ))
                                                                                        ) :
                                                                                        (
                                                                                            <p className='mt-2 text-small italic'>{t('sentences.service_no_data')}</p>
                                                                                        )}

                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>

                                                </>
                                            )}

                                            {catService.length > 0 && (
                                                <>
                                                    <hr className='border-border-12' />
                                                    <div className='flex flex-col gap-4'>
                                                        <div><ServiceLabel classTitleName='!font-bold' title={t('select_options.shop_service_cat')} /></div>
                                                        <FormField
                                                            control={form.control}
                                                            name='selectedServices'
                                                            render={() => (
                                                                <FormItem className='flex flex-col gap-4'>
                                                                    {[
                                                                        SHOP_SERVICE_NAME_TYPE.Cut,
                                                                        SHOP_SERVICE_NAME_TYPE.Shower,
                                                                        SHOP_SERVICE_NAME_TYPE.Combo,
                                                                        SHOP_SERVICE_NAME_TYPE.Hotel
                                                                    ].map((serviceType) => {
                                                                        const filteredServices = catService.filter(item => item.serviceName === serviceType.toString());

                                                                        return (
                                                                            <div key={serviceType}>
                                                                                <p className='font-medium'>{t(`select_options.${SHOP_SERVICE_NAME[serviceType].key}`)}</p>
                                                                                <div className='flex gap-3 overflow-x-scroll scrollbar scrollbar-height-2 md:scrollbar-height-4 pb-2'>
                                                                                    {filteredServices.length > 0 ?
                                                                                        (
                                                                                            filteredServices.map((itemService, index) => (
                                                                                                <FormField
                                                                                                    key={index}
                                                                                                    name='selectedServices'
                                                                                                    control={form.control}
                                                                                                    render={() => {
                                                                                                        const isActive = watchSelectedServicesJson.length > 0 && watchSelectedServicesJson.includes(JSON.stringify(itemService));
                                                                                                        const isDelete = deletedServicesJson.length > 0 && deletedServicesJson.includes(JSON.stringify(itemService));
                                                                                                        return (
                                                                                                            <FormItem className='mt-3'>
                                                                                                                <FormControl>
                                                                                                                    <Button
                                                                                                                        variant={isActive ? 'active' : 'option'}
                                                                                                                        onClick={() => handleGroomingService(itemService)}
                                                                                                                        type='button'
                                                                                                                        className='flex flex-col gap-2 px-3 py-2 items-start lg:w-[11.875rem] md:w-[10.625rem] w-[11.25rem] h-[3.75rem] group'
                                                                                                                    >
                                                                                                                        {
                                                                                                                            serviceAppointmentButton({
                                                                                                                                petWeightFrom: itemService.petWeightFrom,
                                                                                                                                petWeightTo: itemService.petWeightTo,
                                                                                                                                price: itemService.price || 0,
                                                                                                                                active: isActive,
                                                                                                                                isDelete,
                                                                                                                                t
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </Button>
                                                                                                                </FormControl>
                                                                                                            </FormItem>
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            ))
                                                                                        ) :
                                                                                        (
                                                                                            <p className='mt-2 text-small italic'>{t('sentences.service_no_data')}</p>
                                                                                        )}

                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>

                                                </>
                                            )}

                                            {medicalService.length > 0 && (
                                                <>
                                                    <hr className='border-border-12' />
                                                    <div className='flex flex-col gap-4'>
                                                        <div><ServiceLabel classTitleName='!font-bold' title={t('words_title.medical_services').toUpperCase()} /></div>
                                                        <FormField
                                                            control={form.control}
                                                            name='selectedServices'
                                                            render={() => (
                                                                <FormItem className='w-full'>
                                                                    {medicalService.length > 0 && (
                                                                        <div className='flex flex-col gap-3'>
                                                                            {medicalService.map((itemService, index) => {
                                                                                return (
                                                                                    <FormField
                                                                                        key={index}
                                                                                        control={form.control}
                                                                                        name='selectedServices'
                                                                                        render={({ field }) => {
                                                                                            const isDelete = deletedServicesJson.length > 0 && deletedServicesJson.includes(JSON.stringify(itemService));
                                                                                            const isActive = watchSelectedServicesJson.length > 0 && watchSelectedServicesJson.includes(JSON.stringify(itemService));
                                                                                            return (
                                                                                                <>
                                                                                                    <FormItem>
                                                                                                        <FormLabel
                                                                                                            htmlFor={`checkbox-medical-${itemService.code}-${index}`}
                                                                                                            className='w-full cursor-pointer'
                                                                                                        >
                                                                                                            <div title={isDelete ? t('words_title.deleted') : ''}>
                                                                                                                <div className='flex items-start gap-2'>
                                                                                                                    <FormControl>
                                                                                                                        <Checkbox
                                                                                                                            className=''
                                                                                                                            id={`checkbox-medical-${itemService.code}-${index}`}
                                                                                                                            checked={isActive}
                                                                                                                            onCheckedChange={() => handleGroomingService(itemService)}
                                                                                                                        />
                                                                                                                    </FormControl>
                                                                                                                    <div className='flex flex-col gap-2'>
                                                                                                                        <p
                                                                                                                            className={classNames('leading-4', isDelete && 'line-through')}
                                                                                                                        >
                                                                                                                            {itemService.serviceName}
                                                                                                                        </p>
                                                                                                                        <p className={classNames('text-label-4', isDelete && 'line-through')}>
                                                                                                                            <span className='text-label-4'>
                                                                                                                                {t('words_title.appointment_price')}
                                                                                                                                :
                                                                                                                            </span>
                                                                                                                            <span className='ml-1 font-semibold text-black'>
                                                                                                                                {convertServicePrice(itemService.price, t)}
                                                                                                                            </span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </FormLabel>
                                                                                                    </FormItem>
                                                                                                    {index < medicalService.length - 1 && <hr className='border-dashed border-r-border-8' />}
                                                                                                </>
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>

                                                </>
                                            )}

                                            {otherService.length > 0 && (
                                                <>
                                                    {' '}
                                                    <hr className='border-border-12' />
                                                    <div className='flex flex-col gap-4'>
                                                        <div><ServiceLabel classTitleName='!font-bold' title={t('words_title.other').toUpperCase()} /></div>
                                                        <FormField
                                                            control={form.control}
                                                            name='selectedServices'
                                                            render={() => (
                                                                <FormItem className='w-full'>
                                                                    {otherService.length > 0 && (
                                                                        <div className='flex flex-col gap-3'>
                                                                            {otherService.map((itemService, index) => {
                                                                                return (
                                                                                    <FormField
                                                                                        key={index}
                                                                                        control={form.control}
                                                                                        name='selectedServices'
                                                                                        render={({ field }) => {
                                                                                            const isDelete = deletedServicesJson.length > 0 && deletedServicesJson.includes(JSON.stringify(itemService));
                                                                                            const isActive = watchSelectedServicesJson.length > 0 && watchSelectedServicesJson.includes(JSON.stringify(itemService));
                                                                                            return (
                                                                                                <>
                                                                                                    <FormItem>
                                                                                                        <FormLabel
                                                                                                            htmlFor={`checkbox-other-${itemService.code}-${index}`}
                                                                                                            className='w-full cursor-pointer'
                                                                                                        >
                                                                                                            <div title={isDelete ? t('words_title.deleted') : ''}>
                                                                                                                <div className='flex items-start gap-2'>
                                                                                                                    <FormControl>
                                                                                                                        <Checkbox
                                                                                                                            className=''
                                                                                                                            id={`checkbox-other-${itemService.code}-${index}`}
                                                                                                                            checked={isActive}
                                                                                                                            onCheckedChange={() => handleGroomingService(itemService)}
                                                                                                                        />
                                                                                                                    </FormControl>
                                                                                                                    <div className='flex flex-col gap-2'>
                                                                                                                        <p
                                                                                                                            className={classNames('leading-4', isDelete && 'line-through')}
                                                                                                                        >
                                                                                                                            {`${itemService.isMedicalService ? `(${t('words_title.medical')}) ` : ''}${itemService.serviceName}`}
                                                                                                                        </p>
                                                                                                                        <p className={classNames('text-label-4', isDelete && 'line-through')}>
                                                                                                                            <span className='text-label-4'>
                                                                                                                                {t('words_title.appointment_price')}
                                                                                                                                :
                                                                                                                            </span>
                                                                                                                            <span className='ml-1 font-semibold text-black'>
                                                                                                                                {convertServicePrice(itemService.price, t)}
                                                                                                                            </span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </FormLabel>
                                                                                                    </FormItem>
                                                                                                    {index < otherService.length - 1 && <hr className='border-dashed border-r-border-8' />}
                                                                                                </>
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                        </div>

                                        {desktop && (
                                            <div className='py-6 px-4 flex flex-col gap-6 bg-white rounded-sm'>
                                                <div>
                                                    <h3 className='text-[0.9375rem] md:text-[1.3125rem] text-active font-semibold'>{t('words_title.additional_services')}</h3>
                                                </div>
                                                <FormField
                                                    control={form.control}
                                                    name='additionalService'
                                                    render={() => (
                                                        <FormItem className='border-t pt-4 flex flex-col gap-3'>
                                                            {fields.length > 0 && (
                                                                <div className='grid grid-cols-11 items-center'>
                                                                    <div className='col-span-2'>{t('words_title.medical_services')}</div>
                                                                    <Label required className='col-span-4 font-normal'>{t('words_title.service_name')}</Label>
                                                                    <div className='col-span-4'>
                                                                        {t('words_title.price')}
                                                                        {' '}
                                                                        (VND)
                                                                    </div>
                                                                    <div className='col-span-1'></div>
                                                                </div>
                                                            )}
                                                            {fields.map((field, index) => (
                                                                <FormField
                                                                    key={field.id}
                                                                    control={form.control}
                                                                    name={`additionalService.${index}`}
                                                                    render={() => (
                                                                        <FormItem>
                                                                            <>
                                                                                <hr className='border-dashed border-border-8 py-1.5' />
                                                                                <div className='grid grid-cols-11'>
                                                                                    {/* Medical Service Checkbox */}
                                                                                    <div className='flex items-center col-span-2 pl-8 h-12'>
                                                                                        <FormField
                                                                                            control={form.control}
                                                                                            name={`additionalService.${index}.isMedicalService`}
                                                                                            render={({ field }) => (
                                                                                                <FormItem>
                                                                                                    <FormControl>
                                                                                                        <Checkbox
                                                                                                            className='mt-1'
                                                                                                            checked={field.value}
                                                                                                            onCheckedChange={(checked) => {
                                                                                                                field.onChange(checked);
                                                                                                            }}
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                    <FormMessage />
                                                                                                </FormItem>
                                                                                            )}
                                                                                        />
                                                                                    </div>

                                                                                    {/* Service Input */}
                                                                                    <div className='col-span-4 h-full'>
                                                                                        <FormField
                                                                                            control={form.control}
                                                                                            name={`additionalService.${index}.serviceName`}
                                                                                            render={({ field }) => (
                                                                                                <FormItem>
                                                                                                    <FormControl>
                                                                                                        <Input
                                                                                                            className='w-11/12'
                                                                                                            {...field}
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                    <FormMessage />
                                                                                                </FormItem>
                                                                                            )}
                                                                                        />
                                                                                    </div>

                                                                                    {/* Price Input */}
                                                                                    <div className='col-span-4 h-full'>
                                                                                        <FormField
                                                                                            control={form.control}
                                                                                            name={`additionalService.${index}.price`}
                                                                                            render={({ field }) => (
                                                                                                <FormItem>
                                                                                                    <FormControl>
                                                                                                        <FormatNumber
                                                                                                            minValue={1}
                                                                                                            maxValue={MAX_PRICE_SERVICE}
                                                                                                            className=''
                                                                                                            decimalScale={0}
                                                                                                            statusDisplay='input'
                                                                                                            thousandSeparator={true}
                                                                                                            allowNegative={false}
                                                                                                            value={field.value}
                                                                                                            setvalueOfNumericFormat={(values) => {
                                                                                                                form.setValue(`additionalService.${index}.price`, values === '' ? values : Number(values), { shouldValidate: false });
                                                                                                            }}
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                    <FormMessage />
                                                                                                </FormItem>
                                                                                            )}
                                                                                        />
                                                                                    </div>

                                                                                    {/* Remove Service Button */}
                                                                                    <div className='flex items-center col-span-1 pl-8 h-12 '>
                                                                                        <button className='mt-1' type='button' onClick={() => handleRemoveService(index)}>
                                                                                            <CircleX className='fill-placeholder-1 text-white' />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            ))}
                                                        </FormItem>
                                                    )}
                                                />

                                                <div className='flex justify-center'>
                                                    <button type='button' onClick={handleAddNewService} className='flex gap-1 items-center px-4 py-2 border border-black rounded-[35px]'>
                                                        <CirclePlus className='fill-black text-white' />
                                                        <p>{t('button.add_service')}</p>
                                                    </button>
                                                </div>
                                            </div>
                                        )}

                                        {mobile && (
                                            <div className='py-6 px-4 flex flex-col gap-4 bg-white rounded-sm pb-32'>
                                                <hr className='border-border-12' />
                                                <div>
                                                    <h3 className='text-[0.9375rem] text-active font-semibold'>{t('words_title.additional_services')}</h3>
                                                </div>
                                                <FormField
                                                    control={form.control}
                                                    name='additionalService'
                                                    render={() => (
                                                        <FormItem className='flex flex-col gap-3'>
                                                            {fields.map((field, index) => (
                                                                <FormField
                                                                    key={field.id}
                                                                    control={form.control}
                                                                    name={`additionalService.${index}`}
                                                                    render={() => (
                                                                        <FormItem className='flex flex-col gap-4 border-t pt-4 border-dashed border-border-8'>
                                                                            {/* Service Input */}
                                                                            <div className=''>
                                                                                <FormField
                                                                                    control={form.control}
                                                                                    name={`additionalService.${index}.serviceName`}
                                                                                    render={({ field }) => (
                                                                                        <FormItem className='flex flex-col gap-1.5'>
                                                                                            <div className='flex justify-between'>
                                                                                                <Label required className='text-[0.9375rem]'>{t('words_title.service_name')}</Label>
                                                                                                {/* Remove Service Button */}
                                                                                                <div className='flex items-center'>
                                                                                                    <button title='clear' type='button' onClick={() => handleRemoveService(index)}>
                                                                                                        <CircleX className='fill-placeholder-1 text-white size-5' />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            <FormControl>
                                                                                                <Input
                                                                                                    className='w-full'
                                                                                                    {...field}
                                                                                                />
                                                                                            </FormControl>
                                                                                            <FormMessage />
                                                                                        </FormItem>
                                                                                    )}
                                                                                />
                                                                            </div>

                                                                            {/* Price Input */}
                                                                            <div className=''>
                                                                                <FormField
                                                                                    control={form.control}
                                                                                    name={`additionalService.${index}.price`}
                                                                                    render={({ field }) => (
                                                                                        <FormItem className='flex flex-col gap-1.5'>
                                                                                            <FormLabel className='text-[0.9375rem]'>
                                                                                                {t('words_title.price')}
                                                                                                {' '}
                                                                                                (VND)
                                                                                            </FormLabel>
                                                                                            <FormatNumber
                                                                                                minValue={1}
                                                                                                maxValue={MAX_PRICE_SERVICE}
                                                                                                className=''
                                                                                                decimalScale={0}
                                                                                                statusDisplay='input'
                                                                                                thousandSeparator={true}
                                                                                                allowNegative={false}
                                                                                                value={field.value}
                                                                                                setvalueOfNumericFormat={(values) => {
                                                                                                    form.setValue(`additionalService.${index}.price`, values === '' ? values : Number(values), { shouldValidate: false });
                                                                                                }}
                                                                                            />
                                                                                            <FormMessage />
                                                                                        </FormItem>
                                                                                    )}
                                                                                />
                                                                            </div>

                                                                            {/* Medical Service Checkbox */}
                                                                            <div className=''>
                                                                                <FormField
                                                                                    control={form.control}
                                                                                    name={`additionalService.${index}.isMedicalService`}
                                                                                    render={({ field }) => (
                                                                                        <FormItem className='flex gap-1 items-center'>
                                                                                            <FormControl>
                                                                                                <Checkbox
                                                                                                    checked={field.value}
                                                                                                    onCheckedChange={(checked) => {
                                                                                                        field.onChange(checked);
                                                                                                    }}
                                                                                                />
                                                                                            </FormControl>
                                                                                            <FormLabel className='text-[0.9375rem]'>{t('words_title.medical_services')}</FormLabel>
                                                                                            <FormMessage />
                                                                                        </FormItem>
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            ))}
                                                        </FormItem>
                                                    )}
                                                />

                                                <div className='flex justify-center'>
                                                    <button type='button' onClick={handleAddNewService} className='flex gap-1 items-center px-4 py-2 border border-black rounded-[35px]'>
                                                        <CirclePlus className='fill-black text-white' />
                                                        <p>{t('button.add_service')}</p>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            style={mobile ? { zIndex: 100 } : {}}
                                            className={classNames({ 'fixed bottom-0 md:static w-full pb-4 md:py-0 bg-white shadow-[0_-2px_6px_rgba(0,0,0,0.1)]': mobile })}
                                        >
                                            <div className='flex justify-between items-center bg-white px-4 py-3 md:y-4 md:rounded-sm mt-[0.3125rem] md:mt-0'>
                                                <div className='hidden md:block'></div>
                                                <p className='font-semibold'>{t('words_title.expected_total_amount')}</p>
                                                <p className='font-semibold text-[1.3125rem]'>
                                                    {convertServicePrice(calculateTotal)}
                                                </p>
                                            </div>
                                            <div
                                                className='flex justify-between gap-6 px-4 md:mt-3 md:px-0 pt-3 md:pt-0 bg-white md:bg-background-2 border-t border-border-8 md:border-none'
                                            >
                                                <Button
                                                    onClick={() => {
                                                        navigate(`${ROUTES.appointmentManagement.href}/${slug}`);
                                                    }}
                                                    variant='clear'
                                                    type='button'
                                                    className='w-full hidden md:block'
                                                >
                                                    {t('button.cancel')}
                                                </Button>
                                                <Button disabled={!showBtnSave || form.formState.isSubmitting} variant='default' type='submit' className='w-full'>{t('button.save')}</Button>
                                            </div>
                                        </div>
                                    </form>
                                </Form>
                            </div>
                        </div>
                    </>
                ) :
                <NotFoundPage />}
        </>
    );
});

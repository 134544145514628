import { Carousel, CarouselApi, CarouselContent, CarouselItem } from '@/components/common/Carousel';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FORUM_PATH, FORUM_TABS, ROUTES } from '@/configs/constants';
import { useEffect, useMemo, useState } from 'react';
import GeneralSetting from './general-setting';
import { FORUM_TYPE, GENERAL_SETTING_TYPE } from '@/types/enums';
import Topic from './topic';
import NotFoundPage from '../not-found';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import CommingSoonPage from '../comming-soon';

export default observer(function Forum() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { tabPath, slug } = useParams();

    // store
    const { uiStore: { devicesScreen: { mobile, desktop }, windowSize: { width } },
        authStore: { isChannelShopOwner } } = useStore();

    // const
    const pathForum = isChannelShopOwner ? ROUTES.shopForum.href : ROUTES.forum.href;

    // state
    const [api, setApi] = useState<CarouselApi>();

    // lifecycle
    useEffect(() => {
        const findTab = FORUM_TABS.findIndex(item => item.path === tabPath);

        if (findTab > -1) {
            api?.scrollTo(findTab, true);
        }
    }, [tabPath, api]);

    // function
    const currentTabValue = useMemo(() => {
        if (!tabPath) return 'all';
        const findTab = FORUM_TABS.findIndex(item => item.path === tabPath);

        if (findTab > -1) {
            if ((tabPath !== FORUM_PATH.career && tabPath !== FORUM_PATH.freeTopic) && slug) return null;
            return tabPath;
        }
        return null;
    }, [tabPath]);

    const handleNavigate = (path: string) => {
        let redirectPath = pathForum + (path ? '/' + path : '');
        navigate(redirectPath);
    };

    const showRedirectHeader = slug && (pathname.includes(`${pathForum}/${FORUM_PATH.career}`) || pathname.includes(`${pathForum}/${FORUM_PATH.freeTopic}`));

    return (
        currentTabValue === null ?
            <NotFoundPage /> :
            (
                <div>
                    {showRedirectHeader && <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.forum')} defaultNavigateUrl={`${pathForum}/${tabPath}`} />} />}

                    <div className={classNames('w-full md:pt-2')}>
                        {desktop && (
                            <h3
                                className={classNames(
                                    'title-1 text-active',
                                    isChannelShopOwner ? 'text-[1.3125rem] text-active font-semibold text-start px-4 mt-4 pb-6' : ' my-auto text-center md:text-2xl'
                                )}
                            >
                                {t('words_title.forum')}
                            </h3>
                        )}
                        <div className={classNames('bg-white rounded-[0.25rem] md:mx-auto w-full md:mt-2 md:pt-4 pt-3')}>
                            <Tabs value={currentTabValue} className='w-full h-full flex flex-col'>
                                {/* Pet */}
                                {(desktop && !isChannelShopOwner) && (
                                    <TabsList variant='history' data-orientation='horizontal' className={classNames('flex w-full overflow-x-auto')}>
                                        {FORUM_TABS.map((tab, index) =>
                                            (<TabsTrigger onClick={() => handleNavigate(tab.path)} key={index} variant='history' className={classNames('w-full px-0.5', isChannelShopOwner && ' h-11 lg:h-7')} value={tab.path || 'all'}>{t(`${tab.key}`)}</TabsTrigger>))}
                                    </TabsList>
                                )}
                                {(mobile && !isChannelShopOwner) && (
                                    <TabsList variant='history' data-orientation='horizontal' className={classNames('w-full')}>
                                        <Carousel
                                            opts={{
                                                align: 'start'
                                            }}
                                            setApi={setApi}
                                            className='w-full'
                                        >
                                            <CarouselContent className='flex snap-x snap-mandatory ml-0'>
                                                {FORUM_TABS.map((tab, index) =>
                                                    (
                                                        <CarouselItem key={index} className='pl-0 point-600:basis-[calc(33.333333%-35px)] point-450:basis-[calc(33.333333%-25px)] point-375:basis-[calc(33.333333%-15px)] basis-[calc(33.333333%-12px)] snap-center flex-shrink-0'>
                                                            <TabsTrigger onClick={() => handleNavigate(tab.path)} key={index} variant='history' className='w-full' value={tab.path || 'all'}>{t(`${tab.key}`)}</TabsTrigger>
                                                        </CarouselItem>
                                                    ))}
                                            </CarouselContent>
                                        </Carousel>
                                    </TabsList>
                                )}

                                {/* Shop */}
                                {(width >= 1024 && isChannelShopOwner) && (
                                    <TabsList variant='history' data-orientation='horizontal' className={classNames('flex w-full overflow-x-auto')}>
                                        {FORUM_TABS.map((tab, index) =>
                                            (<TabsTrigger onClick={() => handleNavigate(tab.path)} key={index} variant='history' className={classNames('w-full px-0.5', isChannelShopOwner && ' h-11 lg:h-7')} value={tab.path || 'all'}>{t(`${tab.key}`)}</TabsTrigger>))}
                                    </TabsList>
                                )}
                                {(width < 1024 && isChannelShopOwner) && (
                                    <TabsList variant='history' data-orientation='horizontal' className={classNames('w-full')}>
                                        <Carousel
                                            opts={{
                                                align: 'start'
                                            }}
                                            setApi={setApi}
                                            className='w-full'
                                        >
                                            <CarouselContent className='flex snap-x snap-mandatory ml-0'>
                                                {FORUM_TABS.map((tab, index) =>
                                                    (
                                                        <CarouselItem key={index} className='pl-0 point-600:basis-[calc(33.333333%-35px)] point-450:basis-[calc(33.333333%-25px)] point-375:basis-[calc(33.333333%-15px)] basis-[calc(33.333333%-12px)] md:basis-32 snap-center flex-shrink-0'>
                                                            <TabsTrigger onClick={() => handleNavigate(tab.path)} key={index} variant='history' className='w-full' value={tab.path || 'all'}>{t(`${tab.key}`)}</TabsTrigger>
                                                        </CarouselItem>
                                                    ))}
                                            </CarouselContent>
                                        </Carousel>
                                    </TabsList>
                                )}
                                <>
                                    {FORUM_TABS.map((tab, index) =>
                                        (
                                            <TabsContent key={index} value={tab.path || 'all'}>
                                                <TabContent path={tab.path} />
                                            </TabsContent>
                                        ))}
                                </>
                            </Tabs>
                        </div>
                    </div>
                </div>
            )
    );
});

const TabContent = ({ path }: { path: string }) => {
    switch (path) {
        case FORUM_PATH.all:
            return <CommingSoonPage />;
        case FORUM_PATH.freeTopic:
            return <Topic forumType={FORUM_TYPE.FreeTopic} />;
        case FORUM_PATH.aboutUs:
            return <GeneralSetting type={GENERAL_SETTING_TYPE.AboutUs} />;
        case FORUM_PATH.career:
            // return <CommingSoonPage />;
            return <Topic forumType={FORUM_TYPE.Career} />;
        case FORUM_PATH.miaPolicy:
            return <GeneralSetting type={GENERAL_SETTING_TYPE.MiaPolicy} />;
        case FORUM_PATH.privacyPolicy:
            return <GeneralSetting type={GENERAL_SETTING_TYPE.PrivacyPolicy} />;
        default:
            return <></>;
    }
};

import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import { Textarea } from '@/components/common/Textarea';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { toastify } from '@/utils/toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
    arrayId: number[]
};

export default observer(function ReportForm({ arrayId }: Props) {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        reviewStore: { reportReview, getAllReviewForReviewManagement, params, setObservable },
        modalStore: { hideModal }
    } = useStore();

    // validate Schema
    const validateSchema = yup.object().shape({
        reportReason: yup.string().required('form_error_validate.required'),
        reportComment: yup.string()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            reportReason: '',
            reportComment: ''
        }
    });

    // functions
    const onSubmit = async (data: FormData) => {
        const { reportReason, reportComment } = data;
        if (arrayId) {
            const res = await flowResult(reportReview({
                reportReason,
                reportComment,
                reviewIds: arrayId
            }));
            if (res) {
                getAllReviewForReviewManagement(params);
                toastify('alert-success', t('messages.update_success'));
                setObservable('arrayIdReport', [], { isMergeObject: false });
                hideModal('report-review');
            }
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-4'>
                    <FormField
                        control={form.control}
                        name='reportReason'
                        render={({ field }) => (
                            <FormItem className='flex flex-col gap-1.5'>
                                <Label required className='text-[0.9375rem] font-medium'>{t('words_title.reason')}</Label>
                                <FormControl>
                                    <Input type='text' placeholder={t('placeholder.enter')} className='placeholder:text-placeholder-1' {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='reportComment'
                        render={({ field }) => (
                            <FormItem className='flex flex-col gap-1.5'>
                                <Label className='text-[0.9375rem] font-medium'>{t('words_title.comment')}</Label>
                                <FormControl>
                                    <Textarea
                                        className='resize-none h-32'
                                        value={field.value ?? ''}
                                        onChange={e => field.onChange(e.target.value)}
                                        placeholder={t('placeholder.enter_comment')}
                                        maxLength={2000}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <Button disabled={form.formState.isSubmitting} type='submit' variant='submit' size='submit' className='mt-6'>
                    {t('button.send')}
                </Button>
            </form>
        </Form>
    );
});

import { action, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { HeaderFormat } from '@/types/enums';
import { DevicesScreen, WindowSize } from '@/hooks/useWindowSize';
import { Location } from '@/types/general';
import { FilterShopParams } from '@/types/shop';

export default class UIStore extends BaseStore {
    isLogoVisible: boolean = true;
    listCitySelectedValue: string[] = [];
    filterData: FilterShopParams = {};
    countFilter: number = 0;
    currentLocation: Location | null = null;
    isShowScrollToTop: boolean = false;
    showHeaderFormat?: HeaderFormat | false | null;
    devicesScreen: DevicesScreen = {
        mobile: false,
        desktop: false
    };

    windowSize: WindowSize = {
        width: window.innerWidth,
        height: window.innerHeight
    };

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            isLogoVisible: observable,
            listCitySelectedValue: observable,
            filterData: observable,
            isShowScrollToTop: observable,
            showHeaderFormat: observable,
            devicesScreen: observable,
            windowSize: observable,
            countFilter: observable,
            currentLocation: observable,
            setShowHeaderFormat: action.bound,
            setDevicesScreen: action.bound,
            setWindowSize: action.bound
        });
    }

    setShowHeaderFormat(value: HeaderFormat | null) {
        this.showHeaderFormat = value;
    }

    setDevicesScreen(value: DevicesScreen) {
        this.devicesScreen = value;
    }

    setWindowSize(value: WindowSize) {
        this.windowSize = value;
    }
}

import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { FloatingLabelInput } from '@/components/common/Input';
import BackToSigninBtn from '@/components/general/BackToSigninBtn';
import { PHONE_VALIDATE_REGEX, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default observer(function ForgotPasswordPage() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const { authStore: { forgotPassword } } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        phoneNumber: yup
            .string()
            .required('form_error_validate.required')
            .matches(PHONE_VALIDATE_REGEX, 'form_error_validate.format_phone')
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            phoneNumber: ''
        }
    });

    // function
    const onSubmit = async (data: FormData) => {
        const res = await flowResult(forgotPassword(data));

        if (res && res.ok) {
            navigate(`${ROUTES.verifyOtpForgotPassword.href}?uuid=${res.data.uuid}`);
        }
    };

    return (
        <div>
            <h3 className='title-1 text-center mt-6'>{t('words_title.forgot_password')}</h3>
            <div className='desc-1 mt-4 text-center'>{t('sentences.forgot_password_desc')}</div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className='mt-6'>
                    <div>
                        <FormField
                            control={form.control}
                            name='phoneNumber'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <FloatingLabelInput
                                            type='number'
                                            isTypingOnlyNumber={true}
                                            label={t(
                                                'words_title.phone_number'
                                            )}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button variant='submit' size='submit' className='mt-6' disabled={!form.getFieldState('phoneNumber').isDirty}>
                        {t('button.verify_code')}
                    </Button>
                    <BackToSigninBtn />
                </form>
            </Form>
        </div>
    );
});

import { ArrowRight, Star, TriangleAlert } from 'lucide-react';
import defaultShopImg from '@/assets/images/defaultShopImg.png';
import { observer } from 'mobx-react-lite';
import { Button } from '@/components/common/Button';
import { ShopCardInfo } from '@/types/shop';
import { useStore } from '@/hooks/useStore';
import { useTranslation } from 'react-i18next';
import { convertDistance, formatNumber } from '@/utils/utils';
import { ROUTES } from '@/configs/constants';
import classNames from 'classnames';

interface ShopItemProps {
    shopInfor: ShopCardInfo,
    onSelectedShop?: Function,
    isViewing?: boolean
}

export default observer(function ShopItem({ shopInfor, onSelectedShop, isViewing = false }: ShopItemProps) {
    const { name, totalReviews, rating, distance, image, slug, operatingStatus } = shopInfor;
    // hook
    const { t } = useTranslation();

    // store
    const { uiStore: { currentLocation } } = useStore();
    return (
        <div className={classNames('flex gap-4 p-4 pl-[0.875rem] border-l-[0.3125rem] border-b border-b-border-8', isViewing ? 'border-l-icon-4' : 'border-l-white')}>
            <a title='link shop' href={`${ROUTES.shopDetail.href}/${slug}`} target='_blank' rel='noreferrer'>
                <div className='size-24 relative'>
                    {!operatingStatus && (
                        <div className='absolute flex flex-col gap-1 justify-center items-center top-0 left-0 w-full h-full rounded bg-black/60'>
                            <TriangleAlert className='text-white size-[1.1875rem]' />
                            <p className='text-white font-medium text-center text-small'>{t('sentences.temporarily_out_of_service')}</p>
                        </div>
                    )}
                    <img src={image || defaultShopImg} className='size-full rounded' alt='shop avartar' />
                </div>
            </a>
            <div className='flex flex-col justify-between gap-2 flex-1 w-full overflow-hidden'>
                <a href={`${ROUTES.shopDetail.href}/${slug}`} target='_blank' rel='noreferrer' className='line-clamp-2 leading-6 font-semibold hover:underline'>{name}</a>
                <div className='flex gap-2 items-center'>
                    <div className='flex gap-1 items-center'>
                        <p className='text-icon-3 text-small'>{rating?.toFixed(1) || '0.0'}</p>
                        <Star className='text-icon-3 w-4 h-4' fill='#FFB72C' />
                        <p className='text-tab-1 text-small'>
                            (
                            {totalReviews ? formatNumber(totalReviews) : 0}
                            )
                        </p>
                    </div>
                    {distance ?
                        (
                            <>
                                <div className='w-[0.125rem] h-[75%] bg-border-8 rounded-md'></div>
                                <div className='text-tab-1 text-small'>
                                    {convertDistance(distance)}
                                </div>
                            </>
                        ) :
                        <div></div>}
                </div>
                <div className='flex justify-end gap-1 min-h-6'>
                    <div>
                        <Button
                            onClick={() => {
                                onSelectedShop && onSelectedShop();
                            }}
                            variant='default'
                            className='px-2 py-1 h-full rounded-[0.25rem] flex gap-[0.125rem]'
                        >
                            <span className='text-small'>{t('button.view_on_map')}</span>
                            <ArrowRight className='text-white size-[14px]' />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
});

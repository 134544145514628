import Paginate from '@/components/pagination/Paginate';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppointmentCard from '../components/AppointmentCard';
import { scrollToTop } from '@/utils/utils';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import DualDatePicker from '@/components/calendar/DualDatePicker';
import { SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { formatDateTime } from '@/utils/datetime';

export default observer(function AppointmentList() {
    // hooks
    const { t } = useTranslation();

    // store
    const { appointmentStore: { userAppointments, getAllUserAppointments, totalMyAppointmentsPage, myAppointmentPaging, clean, defaultMyAppointmentPaging },
        petStore: { pets, getAllPet }
    } = useStore();

    // states
    const [params, setParams] = useState<{ [key: string]: any }>({});
    const [fromAppointmentDate, setFromAppointmentDate] = useState<Date | undefined>(undefined);
    const [toAppointmentDate, setToAppointmentDate] = useState<Date | undefined>(undefined);

    // lifecycle
    useEffect(() => {
        getAllPet();
    }, []);

    useEffect(() => {
        getAllUserAppointments(params, defaultMyAppointmentPaging);

        return () => {
            clean();
        };
    }, [params]);

    useEffect(() => {
        setParams((prevParams) => {
            let updatedParams = { ...prevParams };

            // Xử lý fromAppointmentDate
            if (fromAppointmentDate) {
                updatedParams.fromAppointmentDate = formatDateTime(fromAppointmentDate, SUBMIT_MOMENT_DATE_YMD);
            } else {
                const { fromAppointmentDate, ...rest } = updatedParams;
                updatedParams = rest; // Loại bỏ fromAppointmentDate nếu không có giá trị
            }

            // Xử lý toAppointmentDate
            if (toAppointmentDate) {
                updatedParams.toAppointmentDate = formatDateTime(toAppointmentDate, SUBMIT_MOMENT_DATE_YMD);
            } else {
                const { toAppointmentDate, ...rest } = updatedParams;
                updatedParams = rest; // Loại bỏ toAppointmentDate nếu không có giá trị
            }

            return updatedParams;
        });
    }, [toAppointmentDate, fromAppointmentDate]);

    // functions
    const handleSelectPet = (value: string) => {
        if (value === 'all') {
            setParams({ ...params, petCode: undefined });
        } else {
            setParams({ ...params, petCode: value });
        }
    };

    return (
        <>
            <div className='flex justify-between items-center flex-col point-600:flex-row gap-x-8 gap-y-4  bg-white p-4 border-b-4 border-gray-100 md:border-none'>
                <div className='flex-auto w-full point-600:max-w-[16.5rem]'>
                    <Select defaultValue='all' onValueChange={handleSelectPet}>
                        <SelectTrigger className='h-9.5'>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent className='max-w-[95vw] point-600:max-w-[16.5rem]'>
                            <SelectItem value='all'>
                                <span>{t('words_title.all_pet')}</span>
                            </SelectItem>
                            {pets.map((value, index) => {
                                return (
                                    <SelectItem value={value.code} key={index}>
                                        <span className='line-clamp-1'>{value.name}</span>
                                    </SelectItem>
                                );
                            })}
                        </SelectContent>
                    </Select>
                </div>
                <div className='w-full min-w-64 point-600:w-auto'>
                    <DualDatePicker
                        selectedFrom={fromAppointmentDate}
                        selectedTo={toAppointmentDate}
                        onSelectFrom={setFromAppointmentDate}
                        onSelectTo={setToAppointmentDate}
                        className='h-9.5'
                    />
                </div>
            </div>
            {(userAppointments && userAppointments.length > 0) ?
                (
                    <div className='bg-white'>
                        <div className=' md:px-4 grid grid-cols-1 md:grid-cols-2 md:gap-2 lg:gap-4 gap-1 md:pt-2 md:mt-2'>
                            {userAppointments.map((appointment, index) => (
                                <AppointmentCard key={index} appointment={appointment} />
                            ))}
                        </div>
                        {totalMyAppointmentsPage > 0 && (
                            <div className='w-full flex justify-center pt-4 pb-6 md:py-4'>
                                <Paginate
                                    forcePage={myAppointmentPaging.pagination.pageIndex}
                                    pageCount={totalMyAppointmentsPage}
                                    onPageChange={({ selected }) => {
                                        myAppointmentPaging.pagination.pageIndex = selected;
                                        getAllUserAppointments(params, myAppointmentPaging);
                                        scrollToTop();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                ) :
                (
                    <div className='text-tab-1 flex w-full h-40 items-center justify-center'>
                        <p>{t('sentences.no_appointment')}</p>
                    </div>
                )}

        </>
    );
});

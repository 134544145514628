import ChatButton from '@/components/general/ChatButton';
import SidebarShopManagement from '@/components/general/SidebarShopManagement';
import ContainerBody from '@/components/layout/ContainerBody';
import HeaderMobileFormat2 from '@/components/layout/header-component/HeaderMobileFormat2';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { NotFoundPage } from '@/pages';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

interface ShopManagementPanelLayoutProps {
    children?: ReactNode
}

export default observer(function ShopManagementPanelLayout({ children }: ShopManagementPanelLayoutProps) {
    // hooks
    const { pathname } = useLocation();
    const { slug } = useParams();

    // store
    const { authStore: { isChannelShopOwner, token }, uiStore: { devicesScreen: { mobile, desktop } } } = useStore();
    return (
        isChannelShopOwner ?
            (
                <>
                    {/* Container Body */}
                    <ContainerBody
                        showHeader={(pathname === ROUTES.appointmentManagement.href) || (pathname === ROUTES.reviewManagement.href) || (pathname.startsWith(ROUTES.shopForum.href) && !slug) || desktop}
                        showFooter={desktop}
                        showFooterMenu={desktop}
                        headerMobileComponent={<HeaderMobileFormat2 useMenuDropDown />}
                    >
                        {
                            mobile && (
                                <div className='bg-white min-h-screen'>
                                    {children ?? <Outlet />}
                                </div>
                            )
                        }
                        {
                            desktop && (
                                <div className='grid md:grid-cols-[250px,1fr] lg:grid-cols-[300px,1fr] grid-cols-1 md:gap-4 md:pt-8'>
                                    <div className='bg-white rounded-[0.25rem]'>
                                        <SidebarShopManagement />
                                    </div>
                                    <div className='bg-white md:rounded-[0.25rem] overflow-hidden'>
                                        {
                                            children ?? <Outlet />
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </ContainerBody>
                    {token && <ChatButton />}
                </>
            ) :
            <><NotFoundPage /></>
    );
});

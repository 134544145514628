export const getStorage = (key: string, isParse: boolean = false) => {
    const value = localStorage.getItem(key) ?? sessionStorage.getItem(key);
    if (!isParse || !value) {
        return value;
    }
    return JSON.parse(value);
};

export const saveLocalStorage = (key: string, value: any, isStringify: boolean = false) => {
    const saveValue = isStringify ? JSON.stringify(value) : value;
    localStorage.setItem(key, saveValue);
};

export const removeStorage = (keys: string[]) => {
    if (keys.length > 0) {
        keys.forEach((key) => {
            localStorage.removeItem(key);
            sessionStorage.removeItem(key);
        });
    }
};

interface loadFilePreviewOptions {
    callbackLoadMedia?: (duration: number) => void,
    callbackLoadImage?: (size: { width: number, height: number, src: string }) => void,
    nonFreeMemory?: boolean
}

export const loadFilePreview = (
    file: File | null,
    output: string,
    options?: loadFilePreviewOptions
): void => {
    if (!file || !(file instanceof File)) return;

    const outElement = document.getElementById(output) as HTMLImageElement | HTMLVideoElement | null;
    if (!outElement) return;

    outElement.src = URL.createObjectURL(file);

    outElement.onloadedmetadata = () => {
        if (options?.callbackLoadMedia && outElement instanceof HTMLVideoElement) {
            options?.callbackLoadMedia(outElement.duration);
        }
    };

    outElement.onload = () => {
        if (options?.callbackLoadImage && 'naturalWidth' in outElement && 'naturalHeight' in outElement) {
            options?.callbackLoadImage({ width: outElement.naturalWidth, height: outElement.naturalHeight, src: outElement.src });
        }
        !options?.nonFreeMemory && URL.revokeObjectURL(outElement.src); // free memory
    };
};

export const loadFilesPreviewOfArray = (
    files: FileList | null,
    outputs: string[]
): void => {
    if (!files || !(files instanceof FileList) || !Array.isArray(outputs) || files.length !== outputs.length) return;

    Array.from(files).forEach((file, index) => {
        loadFilePreview(file, outputs[index]);
    });
};

export const loadURLPreview = (
    url: string,
    output: string,
    cb?: () => void
): void => {
    const outElement = document.getElementById(output) as HTMLImageElement | HTMLVideoElement | null;
    if (outElement) {
        outElement.setAttribute('src', url);
        if (cb) {
            cb();
        }
    }
};

export const loadURLPreviewOfArray = (
    urls: string[],
    outputs: string[],
    cb?: () => void
): void => {
    if (!Array.isArray(urls) || !Array.isArray(outputs) || urls.length !== outputs.length) return;

    urls.forEach((url, index) => {
        const outputId = outputs[index];
        const outElement = document.getElementById(outputId) as HTMLImageElement | HTMLVideoElement | null;

        if (outElement) {
            outElement.setAttribute('src', url);
        }
    });

    if (cb) {
        cb();
    }
};

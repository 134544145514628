import { observer } from 'mobx-react-lite';
import userAvatar from '@/assets/images/userAvatar.svg';
import { Button } from '@/components/common/Button';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '@/hooks/useStore';
import { ROUTES } from '@/configs/constants';
import { cn } from '@/utils/utils';
import { LANGUAGES_SUPPORTED } from '@/types/enums';

export default observer(function UserAvatarMobile() {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const navigate = useNavigate();

    // store
    const { authStore: { token, profile } } = useStore();

    // function
    const onClickAccountInfomation = () => {
        token && navigate('#account-infomation', { state: { previousUrl: ROUTES.myAccount.href } });
    };

    const onNavigateSignIn = () => {
        !token && navigate(ROUTES.signIn.href, { state: { previousUrl: ROUTES.myAccount.href } });
    };

    const onNavigateSignUp = () => {
        !token && navigate(ROUTES.signUp.href, { state: { previousUrl: ROUTES.myAccount.href } });
    };

    return (
        <div className={cn('px-4 bg-white flex items-center', token ? 'gap-3 cursor-pointer h-17' : 'justify-between h-13')} onClick={onClickAccountInfomation}>
            {
                token ?
                    (
                        <>
                            <div className='flex-none w-11 h-11 px-4 xsm:px-5 xsm:w-12.5 xsm:h-12.5 rounded-full bg-background-avatar flex justify-center items-center'>
                                <div className='text-white text-2xl font-bold'>{profile?.fullName[0]}</div>
                            </div>
                            <div className='flex-auto truncate'>
                                <div className='text-sm truncate'>{profile?.fullName}</div>
                                <div className='text-small leading-4 text-text-4'>{profile?.phoneNumber}</div>
                                <div className='text-small leading-4 text-text-4 truncate'>
                                    {
                                        `${profile?.address ? `${profile.address}, ` : ''}${profile?.ward ? (language === LANGUAGES_SUPPORTED.English ? profile?.ward?.fullNameEn : profile?.ward?.fullName) + ', ' : ''
                                        }${profile?.district ? (language === LANGUAGES_SUPPORTED.English ? profile?.district?.fullNameEn : profile?.district?.fullName) + ', ' : ''
                                        }${profile?.province ? (language === LANGUAGES_SUPPORTED.English ? profile?.province?.fullNameEn : profile?.province?.fullName) : ''
                                        }`
                                    }
                                </div>
                            </div>
                            <ChevronRightIcon className='flex-none w-4 h-4' />
                        </>
                    ) :
                    (
                        <>
                            <img className='w-7.5 h-7.5' src={userAvatar} alt='logo-img' />
                            <div className='flex gap-3'>
                                <Button className='px-4 py-1.5 h-7.5 rounded-sm' onClick={onNavigateSignIn}>
                                    <span className='button-1'>{t('words_title.sign_in')}</span>
                                </Button>
                                <Button className='px-4 py-1.5 h-7.5 rounded-sm' variant='outline' onClick={onNavigateSignUp}>
                                    <span className='button-1'>{t('words_title.sign_up')}</span>
                                </Button>
                            </div>
                        </>
                    )
            }

        </div>
    );
});

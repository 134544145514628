import { DISPLAY_MOMENT_DATE_DMYHM_AM_PM, FORUM_PATH, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { Eye, SquarePen, ThumbsUp, Trash2 } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Comment } from '../component/Comment';
import { PostForm } from '../component/PostForm';
import { formatDateTime } from '@/utils/datetime';
import { useTranslation } from 'react-i18next';
import { flowResult } from 'mobx';
import { toastify } from '@/utils/toastify';
import { FORUM_TYPE, ForumType } from '@/types/enums';
import 'react-quill/dist/quill.snow.css';
import '../../index.scss';

interface TopicDetailProps {
    forumType: ForumType
}

export default observer(function TopicDetail({ forumType }: TopicDetailProps) {
    // hooks
    const { t } = useTranslation();
    const { slug } = useParams();
    const navigate = useNavigate();

    // store
    const { topicStore: { topic, likeTopic, getTopicDetail, deleteTopic, clean },
        modalStore: { showModal, showAlertModal, modalWaringAlertOptions },
        authStore: { isChannelShopOwner, token } } = useStore();

    // const
    const pathForum = isChannelShopOwner ? ROUTES.shopForum.href : ROUTES.forum.href;

    // lifecycle
    useEffect(() => {
        if (slug) {
            getTopicDetail({
                slug,
                isGetPageDetail: true
            });
        }
        return () => {
            clean();
        };
    }, []);

    // functions
    async function showModalEditPost() {
        if (slug) {
            const res = await flowResult(getTopicDetail({
                slug
            }));
            if (res) {
                showModal({
                    id: 'topic-edit-post',
                    title: t('words_title.edit_post'),
                    titleClassName: 'title-3 text-center',
                    content: <PostForm slug={topic?.code} forumType={forumType} />,
                    footerClassName: 'p-0'
                });
            }
        }
    }

    async function handleDeleteTopic() {
        showAlertModal(modalWaringAlertOptions({
            content: t('sentences.delete_post'),
            onSave: async () => {
                if (!topic?.code) return;
                const res = await flowResult(deleteTopic(topic?.code));
                if (res) {
                    navigate(`${pathForum}/${forumType === FORUM_TYPE.Career ? FORUM_PATH.career : FORUM_PATH.freeTopic}`);
                    toastify('alert-success', t('sentences.delete_success'));
                }
            }
        }));
    }

    async function handleLikeTopic() {
        if (topic && topic.code) {
            const res = await flowResult(likeTopic(topic.code));
            if (res) {
                getTopicDetail({
                    slug: topic?.slug
                }, true);
            }
        }
    }

    return (
        <div>
            <div className='md:bg-background-2'>
                <div className='flex flex-col gap-4 py-4 px-2 md:px-4 bg-white'>
                    <div className='flex flex-col gap-2 -mb-2'>
                        <div className='flex items-center justify-between'>
                            <div
                                className='flex items-center justify-center gap-1 md:gap-2 overflow-hidden'
                            >
                                <span className='text-small text-icon-2 flex-auto line-clamp-1'>{topic?.user?.fullName}</span>
                                <div className='h-4.5 w-[0.0625rem] bg-background-8 flex-none'></div>
                                <span className='text-small text-icon-2 flex-none'>{formatDateTime(topic?.createdAt ?? '', DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}</span>
                            </div>
                        </div>
                        <h3 className='text-[1.3125rem] font-semibold text-title-1'>{topic?.title}</h3>
                    </div>
                    <hr className='text-border-8' />
                    <div>
                        {topic?.image && (<img className='mb-4 md:float-left md:mr-8 h-[13.125rem] mx-auto md:w-[15.375rem] object-cover rounded-sm' src={topic?.image} alt='job-image' />)}
                        <div className='ql-editor p-0' style={{ overflowY: 'unset' }} dangerouslySetInnerHTML={{ __html: topic?.description || '' }} />
                    </div>
                    {/* Button */}
                    <div className='flex justify-between items-center'>
                        {topic?.isMyPost ?
                            (
                                <div className='flex gap-4'>
                                    <button
                                        onClick={showModalEditPost}
                                        className='flex items-center justify-center gap-1 group'
                                    >
                                        <SquarePen className='size-3.5 text-icon-8 group-hover:text-active' />
                                        <span className='text-small text-icon-8 group-hover:text-active'>{t('button.edit_post')}</span>
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            handleDeleteTopic();
                                        }}
                                        className='flex items-center justify-center gap-1 group'
                                    >
                                        <Trash2 className='size-3.5 text-icon-8 group-hover:text-active' />
                                        <span className='text-small text-icon-8 group-hover:text-active'>{t('button.delete_post')}</span>
                                    </button>
                                </div>
                            ) :
                            <div></div>}
                        <div className='flex gap-2'>
                            <div className='flex gap-2 items-center px-2.5 py-1 border rounded-[0.3125rem]'>
                                <Eye className='size-[1.0625rem]' />
                                <span className='text-small text-icon-2'>{topic?.totalViews}</span>
                            </div>
                            <button
                                onClick={handleLikeTopic}
                                className={`flex gap-2 items-center px-2.5 py-1 border rounded-[0.3125rem] cursor-default ${!token ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                disabled={!token}
                            >
                                <ThumbsUp className={`size-3.5 text-active ${topic?.liked ? 'fill-active' : ''}`} />
                                <span className='text-small text-text-11'>{topic?.totalLikes}</span>
                            </button>
                        </div>
                    </div>

                    {topic?.code && <Comment slug={topic?.code} />}
                </div>
                <div className='pt-4 hidden md:flex w-full justify-center '><Link to={`${pathForum}/${forumType === FORUM_TYPE.Career ? FORUM_PATH.career : FORUM_PATH.freeTopic}`} className='hidden md:block text-active py-2.5 px-16 font-medium bg-white rounded-md'>{t('button.back')}</Link></div>
            </div>
        </div>
    );
});

import { Progress } from '@/components/common/Progress';
import Paginate from '@/components/pagination/Paginate';
import { useStore } from '@/hooks/useStore';
import { convertNumber, shortenParagraph, sumTotalByServiceQuality } from '@/utils/utils';
import { Star, TriangleAlert } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReviewItem from './ReviewItem';

export default observer(function ReviewComponent() {
    // hooks
    const { t } = useTranslation();
    const { slug } = useParams();
    const componentRef = useRef<HTMLDivElement>(null);

    // store
    const {
        uiStore: { devicesScreen: { mobile, desktop } },
        shopStore: { shopDetail, getAllReviewForShopDetail, allReviewShop, paging, totalPage, setObservable }
    } = useStore();

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    // lifecycle
    useEffect(() => {
        if (slug) {
            fetchData(slug);
        }
        return () => {
            setObservable('paging', { ...paging, pagination: { ...paging.pagination, pageIndex: 0 } }, { isMergeObject: true });
        };
    }, [slug]);

    const totalReviewsByRating = shopDetail?.totalReviewsByRating ?? 0;
    const totalReviews = sumTotalByServiceQuality(totalReviewsByRating).totalReviews;

    // function
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const fetchData = async (slug: string) => {
        await flowResult(getAllReviewForShopDetail(slug));
    };

    return (
        <div ref={componentRef}>
            <div className='pt-5 pb-4 px-10 w-full flex items-center gap-6 md:gap-0 lg:px-[104px] border-b border-b-border-1'>
                <div className='flex flex-col justify-center items-center space-y-2 w-1/3'>
                    <h2 className='text-[30px] leading-[30px]'>{shopDetail?.rating?.toFixed(1) || '0.0'}</h2>
                    <div className='text-icon-3 flex space-x-1 mt-1'>
                        {
                            Array.from({ length: 5 }, (_, i) => {
                                const ratingValue = Number(shopDetail?.rating);
                                if (i < Math.floor(ratingValue)) {
                                    return <Star key={i} className='fill-current text-icon-3 w-5 h-5' />;
                                } else if (i === Math.floor(ratingValue) && ratingValue % 1 !== 0) {
                                    return (
                                        <div key={i} className='relative inline-block w-5 h-5'>
                                            <Star className='fill-current text-gray-300 w-5 h-5' />
                                            <div
                                                className='absolute top-0 left-0 h-full overflow-hidden'
                                                style={{
                                                    width: `${(ratingValue % 1) * 100}%`
                                                }}
                                            >
                                                <Star className='fill-current text-icon-3 w-5 h-5' />
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return <Star key={i} className='fill-current text-gray-300 w-5 h-5' />;
                                }
                            })
                        }
                    </div>
                    <p className='text-[12px] text-tab-1'>
                        {convertNumber(totalReviews)}
                        {' '}
                        {totalReviews > 1 ? t('words_title.tab_reviews') : t('words_title.tab_review')}
                    </p>
                </div>
                <div className='w-2/3'>
                    {
                        Object.entries(totalReviewsByRating).reverse().map(([star, count], index) => {
                            const reviewCount = Number(count);
                            const percentage = totalReviews > 0 ? (reviewCount / totalReviews) * 100 : 0;
                            return (
                                <div key={index} className='flex items-center gap-x-1 w-full my-1'>
                                    <p className='text-[14px]'>{star}</p>
                                    <Star className='fill-current text-icon-3 size-4' />
                                    <Progress className='h-2 bg-progress bg-opacity-60' value={percentage} />
                                    <p className='text-[14px] w-10 text-tab-1'>{convertNumber(reviewCount)}</p>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className='pt-4 px-4'>
                <div className='flex gap-2 rounded-sm bg-border-6 p-2'>
                    <div className='size-5'>
                        <TriangleAlert />
                    </div>
                    {
                        mobile && (
                            <span className='text-small'>
                                {shortenParagraph(t('sentences.warning_all_reviews'), 130, isExpanded).value}
                                {
                                    shortenParagraph(t('sentences.warning_all_reviews'), 130, isExpanded).isEllipsis && (
                                        <button
                                            onClick={toggleExpand}
                                            className='text-active text-[0.8125rem] text-blue-500 no-underline'
                                        >
                                            {isExpanded ? t('button.view_less') : t('button.view_more')}
                                        </button>
                                    )
                                }
                            </span>
                        )
                    }
                    {
                        desktop && (
                            <span className='text-small'>
                                {t('sentences.warning_all_reviews')}
                            </span>
                        )
                    }
                </div>
            </div>
            <div className='w-full'>
                <div className='flex flex-col gap-[2px] bg-border-1'>
                    {
                        allReviewShop?.map((review, index) => (
                            <ReviewItem key={index} review={review} />
                        ))
                    }
                </div>

                {
                    totalPage > 0 ?
                        (
                            <div className='w-full flex justify-center pt-4 pb-16 md:pb-0'>
                                <Paginate
                                    forcePage={paging.pagination.pageIndex}
                                    pageCount={totalPage}
                                    onPageChange={({ selected }) => {
                                        getAllReviewForShopDetail(slug!, { ...paging, pagination: { ...paging.pagination, pageIndex: selected } });
                                        componentRef.current?.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'start'
                                        });
                                    }}
                                />
                            </div>
                        ) :
                        (
                            <div className='leading-[21px] text-text-8 text-center mt-5 mb-16'>{t('sentences.no_comment')}</div>
                        )
                }

            </div>
        </div>
    );
});

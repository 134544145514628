import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Label } from '@/components/common/Label';
import yup from '@/services/yup';
import { cn, convertToHourMinuteFormat, generateTimeSlots, scrollToTop } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { useTranslation } from 'react-i18next';
import { AVERAGE_DURATIONS, DAYS_OF_WEEK, DISPLAY_MOMENT_TIME_HM, DISPLAY_MOMENT_TIME_HM_AM_PM_2, DISPLAY_MOMENT_TIME_HMS } from '@/configs/constants';
import StepTimeSelect from '@/components/general/StepTimeSelect';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { DAYS_OF_WEEK_TYPE, INFO_TYPE } from '@/types/enums';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/common/DropdownMenu';
import { BanIcon, CheckIcon, CopyPlusIcon, EllipsisIcon, LucideIcon, Trash2Icon } from 'lucide-react';
import { MouseEventHandler, useEffect, useMemo } from 'react';
import { Schedule } from '@/types/shop';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/hooks/useStore';
import { GetAppointmentInfoResponse } from '@/types/http-payload/shop';
import { flowResult } from 'mobx';
import { toastify } from '@/utils/toastify';
import { formatDateTime } from '@/utils/datetime';

const DEFAULT_AVERAGE_DURATION = '';
const DEFAULT_TIME_STEP = '00:00';

// validate scheme
const validateSchema = yup.object().shape({
    averageDuration: yup
        .string()
        .required('form_error_validate.required'),
    serviceOpenTime: yup
        .string()
        .required('form_error_validate.required'),
    serviceCloseTime: yup
        .string()
        .required('form_error_validate.required'),
    appointmentTimesOfDays: yup
        .array()
        .of(
            yup.object().shape({
                day: yup
                    .number()
                    .oneOf(Object.values(DAYS_OF_WEEK_TYPE))
                    .required('form_error_validate.required'),
                appointments: yup
                    .array()
                    .of(
                        yup.object().shape({
                            startTime: yup
                                .string()
                                .required('form_error_validate.required'),
                            status: yup
                                .boolean()
                                .required('form_error_validate.required')
                        })
                    )
            })
        ).max(7).required('form_error_validate.required')
});

type FormData = yup.InferType<typeof validateSchema>;

export default observer(function AppointmentInformationTab() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // validate form
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            averageDuration: DEFAULT_AVERAGE_DURATION,
            serviceOpenTime: DEFAULT_TIME_STEP,
            serviceCloseTime: DEFAULT_TIME_STEP,
            appointmentTimesOfDays: Object.values(DAYS_OF_WEEK_TYPE).map((item) => {
                return {
                    day: Number(item),
                    appointmentTimes: generateTimeSlots(Number(DEFAULT_AVERAGE_DURATION), DEFAULT_TIME_STEP, DEFAULT_TIME_STEP)
                };
            })
        },
        shouldUnregister: false
    });

    const watchAverageDuration = form.watch('averageDuration');
    const watchServiceOpenTime = form.watch('serviceOpenTime');
    const watchServiceCloseTime = form.watch('serviceCloseTime');

    // store
    const {
        authStore: { isChannelShopOwner },
        shopStore: { shopInfo, getShopInfo, updateAppointmentInfo, setObservable }
    } = useStore();

    // lifecycle
    useEffect(() => {
        if (isChannelShopOwner) {
            fetchData();
        }
    }, [isChannelShopOwner]);

    useEffect(() => {
        if (watchAverageDuration && watchServiceOpenTime && watchServiceCloseTime) {
            const slots = Object.values(DAYS_OF_WEEK_TYPE).map((item) => {
                return {
                    day: Number(item),
                    appointments: generateTimeSlots(Number(watchAverageDuration), watchServiceOpenTime, watchServiceCloseTime)
                };
            });
            form.setValue('appointmentTimesOfDays', slots, { shouldValidate: true });
        }
    }, [watchAverageDuration, watchServiceOpenTime, watchServiceCloseTime]);

    useEffect(() => {
        if (shopInfo && (shopInfo as GetAppointmentInfoResponse)) {
            const { averageDuration, serviceOpenTime, serviceCloseTime, appointmentTimesOfDays, generalOpenTime, generalCloseTime } = shopInfo as GetAppointmentInfoResponse;

            form.reset({
                averageDuration: averageDuration ? averageDuration.toString() : DEFAULT_AVERAGE_DURATION,
                serviceOpenTime: serviceOpenTime ?
                    formatDateTime(serviceOpenTime, DISPLAY_MOMENT_TIME_HM, {
                        onlyTime: true,
                        currentformat: DISPLAY_MOMENT_TIME_HMS
                    }) ?? undefined :
                    (generalOpenTime ?
                        formatDateTime(generalOpenTime, DISPLAY_MOMENT_TIME_HM, {
                            onlyTime: true,
                            currentformat: DISPLAY_MOMENT_TIME_HMS
                        }) ?? undefined :
                        DEFAULT_TIME_STEP),
                serviceCloseTime: serviceCloseTime ?
                    formatDateTime(serviceCloseTime, DISPLAY_MOMENT_TIME_HM, {
                        onlyTime: true,
                        currentformat: DISPLAY_MOMENT_TIME_HMS
                    }) ?? undefined :
                    (generalCloseTime ?
                        formatDateTime(generalCloseTime, DISPLAY_MOMENT_TIME_HM, {
                            onlyTime: true,
                            currentformat: DISPLAY_MOMENT_TIME_HMS
                        }) ?? undefined :
                        DEFAULT_TIME_STEP),
                appointmentTimesOfDays: appointmentTimesOfDays?.map((item) => {
                    return {
                        day: Number(item.day),
                        appointments: item?.appointments?.length > 0 ?
                            item.appointments.map((appointment) => {
                                return {
                                    startTime: formatDateTime(appointment?.startTime, DISPLAY_MOMENT_TIME_HM_AM_PM_2, {
                                        onlyTime: true,
                                        currentformat: DISPLAY_MOMENT_TIME_HMS
                                    }) ?? undefined,
                                    status: appointment.status
                                };
                            }) :
                            undefined
                    };
                }) ?? []
            });
        }
    }, [shopInfo]);

    // function
    const fetchData = async () => {
        await getShopInfo({ typeInfo: INFO_TYPE.AppointmentInfo });
    };

    // function
    const onSubmit = async (data: FormData) => {
        const { averageDuration, serviceOpenTime, serviceCloseTime, appointmentTimesOfDays } = data;
        const res = await flowResult(updateAppointmentInfo({
            averageDuration: Number(averageDuration),
            serviceOpenTime: serviceOpenTime,
            serviceCloseTime: serviceCloseTime,
            appointmentTimesOfDays: appointmentTimesOfDays.map((item) => {
                return {
                    day: item.day,
                    appointmentTimes: item?.appointments && item.appointments.map((appointmentTime) => {
                        return {
                            startTime: formatDateTime(appointmentTime.startTime, DISPLAY_MOMENT_TIME_HM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HM_AM_PM_2
                            }),
                            status: appointmentTime.status
                        };
                    })
                };
            })
        }));
        if (res && res.ok) {
            toastify('alert-success', t('messages.update_success'));
            setObservable('isEditMode', false);
            scrollToTop();
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='appointment-tab-screen'>
                <div className='flex flex-col md:gap-4 gap-2 items-start'>
                    <div className='bg-white mt-4 rounded p-4 w-full flex flex-col gap-4'>
                        <FormField
                            control={form.control}
                            name='averageDuration'
                            render={({ field }) => (
                                <FormItem className='flex flex-col'>
                                    <Label className='label-1' required>{t('words_title.average_duration')}</Label>
                                    <Select onValueChange={field.onChange} value={field.value?.toString()}>
                                        <FormControl>
                                            <SelectTrigger className='mt-1.5'>
                                                <SelectValue placeholder={t('placeholder.choose')}>
                                                </SelectValue>
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {AVERAGE_DURATIONS.map(item => (
                                                <SelectItem
                                                    className='hover:cursor-pointer'
                                                    key={item}
                                                    value={item.toString()}
                                                >
                                                    <span>{convertToHourMinuteFormat(item, language)}</span>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <StepTimeSelect title={t('words_title.service_open_time')} name='serviceOpenTime' control={form.control} min='05:30' max='16:30' />
                        <StepTimeSelect title={t('words_title.service_close_time')} name='serviceCloseTime' control={form.control} />
                    </div>
                    {
                        form.getValues('appointmentTimesOfDays').every(item => item?.appointments && item.appointments.length > 0) && form.getValues('averageDuration') && (
                            <div className='bg-white md:mt-4 mt-0 rounded p-4 w-full'>
                                <Tabs defaultValue={String(DAYS_OF_WEEK_TYPE.Monday)} className='w-full h-full flex flex-col'>
                                    <TabsList data-orientation='horizontal'>
                                        {
                                            Object.values(DAYS_OF_WEEK_TYPE).map((tab, index) => (
                                                <TabsTrigger
                                                    key={index}
                                                    variant='scheduleList'
                                                    className='md:text-[0.875rem] text-3 w-full'
                                                    value={tab.toString()}
                                                >
                                                    {language === 'vi' ? DAYS_OF_WEEK[tab].shortCutVi : DAYS_OF_WEEK[tab].shortCutEn}
                                                </TabsTrigger>
                                            ))
                                        }
                                    </TabsList>
                                    {
                                        form.getValues('appointmentTimesOfDays').length > 0 && form.getValues('appointmentTimesOfDays').map((item, index) => {
                                            const isShowAlert = !(shopInfo as GetAppointmentInfoResponse)?.operationTimes?.find(operationTime => operationTime.day === item.day)?.openStatus;

                                            return (
                                                <TabsContent key={index} value={item.day.toString()} className='w-full'>
                                                    {
                                                        isShowAlert && (
                                                            <div className='text-[0.8125rem] font-medium text-destructive whitespace-pre-line mt-4'>
                                                                {t('sentences.alert_disable_appointment')}
                                                            </div>
                                                        )
                                                    }
                                                    <div className='mt-4 grid grid-cols-2 gap-x-1.5 gap-y-2 w-full'>
                                                        {
                                                            item?.appointments && item.appointments.map((appointment, appointmentIndex, appointments) => (
                                                                <ScheduleListItem
                                                                    key={appointmentIndex}
                                                                    form={form}
                                                                    data={appointment}
                                                                    index={index}
                                                                    appointmentIndex={appointmentIndex}
                                                                    isDisableAll={
                                                                        !(shopInfo as GetAppointmentInfoResponse)?.operationTimes?.find(operationTime => operationTime.day === item.day)?.openStatus
                                                                    }
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </TabsContent>
                                            );
                                        })
                                    }
                                </Tabs>
                            </div>
                        )
                    }
                </div>
                <div className='flex md:flex-row flex-col-reverse justify-center items-center gap-4 px-4 pt-4 md:pb-0 pb-4'>
                    <Button
                        type='button'
                        variant='clear'
                        className='px-4 py-2 md:min-w-44 md:w-fit w-full'
                        onClick={() => {
                            setObservable('isEditMode', false);
                            scrollToTop();
                        }}
                    >
                        {t('button.cancel')}
                    </Button>
                    <Button type='submit' variant='submit' size='submit' disabled={form.formState.isSubmitting} className='px-4 py-2 md:min-w-44 md:w-fit w-full'>
                        {t('button.save')}
                    </Button>
                </div>
            </form>
        </Form>
    );
});

interface ScheduleListItemProps {
    data: Schedule,
    form: UseFormReturn<FormData>,
    index: number,
    appointmentIndex: number,
    isDisableAll?: boolean
}

const ScheduleListItem = ({ data, form, index, appointmentIndex, isDisableAll = false }: ScheduleListItemProps) => {
    // hooks
    const { t } = useTranslation();

    const [hour, minute, period] = data?.startTime.split(':');

    const isDuplicate = useMemo(() => {
        if (appointmentIndex > 0) {
            return form.getValues(`appointmentTimesOfDays.${index}.appointments.${appointmentIndex}.startTime`) === form.getValues(`appointmentTimesOfDays.${index}.appointments.${appointmentIndex - 1}.startTime`) || false;
        }
    }, [data]);

    // function
    const handleClickActive = () => {
        form.setValue(`appointmentTimesOfDays.${index}.appointments.${appointmentIndex}.status`, true, { shouldValidate: true });
    };

    const handleClickInactive = () => {
        form.setValue(`appointmentTimesOfDays.${index}.appointments.${appointmentIndex}.status`, false, { shouldValidate: true });
    };

    const handleClickDuplicate = () => {
        const appointmentOfDay = form.getValues(`appointmentTimesOfDays.${index}.appointments`) ?? [];
        form.setValue(`appointmentTimesOfDays.${index}.appointments`, [
            ...appointmentOfDay.slice(0, appointmentIndex),
            {
                startTime: data.startTime,
                status: true
            },
            ...appointmentOfDay.slice(appointmentIndex)
        ], { shouldValidate: true });
    };

    const handleClickRemove = () => {
        const appointmentOfDay = form.getValues(`appointmentTimesOfDays.${index}.appointments`) ?? [];
        form.setValue(`appointmentTimesOfDays.${index}.appointments`, [
            ...appointmentOfDay.slice(0, appointmentIndex),
            ...appointmentOfDay.slice(appointmentIndex + 1)
        ], { shouldValidate: true });
    };

    return (
        <div className={cn(
            'appointment-item flex px-3 py-4 rounded-[0.75rem] border border-input',
            (!form.getValues(`appointmentTimesOfDays.${index}.appointments.${appointmentIndex}.status`) || isDisableAll) && 'bg-background-3 opacity-50',
            isDuplicate && 'border-l-4 border-l-active'
        )}
        >
            <div className='flex justify-between items-center w-full'>
                <span className='text-[0.9375rem] leading-4 font-medium'>
                    <span className='text-input-1 mr-1'>{`${hour}:${minute}`}</span>
                    <span className='text-placeholder-1'>{period}</span>
                </span>
                {
                    !isDisableAll && (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild disabled={isDisableAll} className='cursor-pointer'>
                                <EllipsisIcon />
                            </DropdownMenuTrigger>

                            <DropdownMenuContent align='end' forceMount>
                                {
                                    isDuplicate ?
                                        (
                                            <DropdownOptionItem icon={Trash2Icon} text={t('words_title.remove')} onClick={handleClickRemove} />
                                        ) :
                                        (
                                            !form.getValues(`appointmentTimesOfDays.${index}.appointments.${appointmentIndex}.status`) ?
                                                (
                                                    <DropdownOptionItem icon={CheckIcon} text={t('words_title.active')} onClick={handleClickActive} />
                                                ) :
                                                (
                                                    <>
                                                        <DropdownOptionItem icon={BanIcon} text={t('words_title.inactive')} onClick={handleClickInactive} />
                                                        <DropdownMenuSeparator />
                                                        <DropdownOptionItem icon={CopyPlusIcon} text={t('words_title.duplicate')} onClick={handleClickDuplicate} />
                                                    </>
                                                )
                                        )
                                }
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )
                }
            </div>

        </div>
    );
};

interface DropdownOptionItemProps {
    icon: LucideIcon,
    text: string,
    onClick: MouseEventHandler<HTMLDivElement>
}

const DropdownOptionItem = ({ icon: Icon, text, onClick }: DropdownOptionItemProps) => {
    return (
        <DropdownMenuItem className='px-0 py-0 max-w-fit'>
            <div className='px-4 py-3 flex items-center gap-3 cursor-pointer h-10.5' onClick={onClick}>
                <Icon className='text-text-4 w-4.5 h-4.5' />
                <span className='text-text-4 text-[0.8125rem] leading-[1.125rem] font-medium'>{text}</span>
            </div>
        </DropdownMenuItem>
    );
};

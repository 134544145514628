export const LANGUAGES_SUPPORTED = {
    English: 'en',
    Vietnamese: 'vi'
} as const;

export type LanguagesSupported = (typeof LANGUAGES_SUPPORTED)[keyof typeof LANGUAGES_SUPPORTED];

export const STATUS = {
    Active: 1,
    Inactive: 2,
    WaitConfirm: 3
} as const;

export type Status = (typeof STATUS)[keyof typeof STATUS];

export const GENDER = {
    Male: 1,
    Female: 2
} as const;

export type Gender = (typeof GENDER)[keyof typeof GENDER];

export const ROLE = {
    Admin: 1,
    Enduser: 2
} as const;

export type Role = (typeof ROLE)[keyof typeof ROLE];

export const ENDUSER_TYPE = {
    PetOwner: 1,
    ShopOwner: 2
} as const;

export type EnduserType = (typeof ENDUSER_TYPE)[keyof typeof ENDUSER_TYPE];

export const VERIFY_OTP_TYPE = {
    Signup: 1,
    ForgotPassword: 2
} as const;

export type VerifyOtpType = (typeof VERIFY_OTP_TYPE)[keyof typeof VERIFY_OTP_TYPE];

export const SHOP_STATUS = {
    Active: 1,
    Inactive: 2,
    NotCompletedInfo: 3,
    WaitApprove: 4,
    DeniedApprove: 5
} as const;

export type ShopStatus = (typeof SHOP_STATUS)[keyof typeof SHOP_STATUS];

export const SHOP_CATEGORY_TYPE = {
    PetShop: 1,
    PetHospital: 2,
    Mixed: 3
} as const;

export type ShopCategoryType = (typeof SHOP_CATEGORY_TYPE)[keyof typeof SHOP_CATEGORY_TYPE];

export const REPRESENTATIVE_ROLE_TYPE = {
    ShopOwner: 1,
    Doctor: 2,
    Manager: 3,
    Staff: 4
} as const;

export type ShopRoleType = (typeof REPRESENTATIVE_ROLE_TYPE)[keyof typeof REPRESENTATIVE_ROLE_TYPE];

export const SHOP_SERVICE_TYPE = {
    Dog: 1,
    Cat: 2,
    Medical: 3,
    Other: 4,
    Additional: 5
} as const;

export type ShopServiceType = (typeof SHOP_SERVICE_TYPE)[keyof typeof SHOP_SERVICE_TYPE];

export const PET_TYPE = {
    Dog: 1,
    Cat: 2,
    Other: 3
} as const;

export type PetType = (typeof PET_TYPE)[keyof typeof PET_TYPE];

export const SHOP_SERVICE_NAME_TYPE = {
    Cut: 1,
    Shower: 2,
    Combo: 3,
    Hotel: 4
} as const;

export type ShopServiceNameType = (typeof SHOP_SERVICE_NAME_TYPE)[keyof typeof SHOP_SERVICE_NAME_TYPE];

export const SHOP_STATUS_TYPE = {
    Active: 1,
    Inactive: 2,
    NotCompletedInfo: 3,
    WaitApprove: 4,
    DeniedApprove: 5
} as const;

export type ShopStatusType = (typeof SHOP_STATUS_TYPE)[keyof typeof SHOP_STATUS_TYPE];

export const PICK_UP_PRICE_TYPE = {
    Free: 1,
    ChargeByDistance: 2
} as const;

export type PickUpPriceType = (typeof PICK_UP_PRICE_TYPE)[keyof typeof PICK_UP_PRICE_TYPE];

export const PICK_UP_SERVICE_STATUS = {
    Offer: 1,
    NotOffer: 0
} as const;

export type PickUpServiceStatus = (typeof PICK_UP_SERVICE_STATUS)[keyof typeof PICK_UP_SERVICE_STATUS];

export const DAYS_OF_WEEK_TYPE = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0
} as const;

export type DaysOfWeekType = (typeof DAYS_OF_WEEK_TYPE)[keyof typeof DAYS_OF_WEEK_TYPE];

export const GENERAL_SETTING_TYPE = {
    AboutUs: 'about_us',
    MiaPolicy: 'company_policy',
    PrivacyPolicy: 'privacy_policy'
} as const;

export type GeneralSettingType = (typeof GENERAL_SETTING_TYPE)[keyof typeof GENERAL_SETTING_TYPE];

export const INFO_TYPE = {
    GeneralInfo: 'generalInfo',
    ShopInfo: 'shopInfo',
    OperationInfo: 'operationInfo',
    AppointmentInfo: 'appointmentInfo',
    DashboardShopOwner: 'dashboardShopOwner'
} as const;

export type InfoType = (typeof INFO_TYPE)[keyof typeof INFO_TYPE];

export const SCREEN_MODE = {
    Add: 1,
    Edit: 2
} as const;

export type ScreenMode = (typeof SCREEN_MODE)[keyof typeof SCREEN_MODE];

export const HEADER_FORMAT = {
    desktopGeneral: 'desktop-general',
    desktopShop: 'desktop-shop',
    mobile1: 'mobile-1',
    mobile2: 'mobile-2'
};

export type HeaderFormat = (typeof HEADER_FORMAT)[keyof typeof HEADER_FORMAT];

export const APPOINTMENT_STATUS_TYPE = {
    NotConfirmed: 1,
    Confirmed: 2,
    Completed: 3,
    Cancelled: 4
};

export type AppointmentStatusType = (typeof APPOINTMENT_STATUS_TYPE)[keyof typeof APPOINTMENT_STATUS_TYPE];
export const BOOK_APPOINTMENT_ERROR_TYPE = {
    GENERAL: 1,
    DATE_TIME_INVALID: 2,
    PET_INVALID: 3,
    SERVICE_INVALID: 4
} as const;

export type BookAppointmentErrorType = (typeof BOOK_APPOINTMENT_ERROR_TYPE)[keyof typeof BOOK_APPOINTMENT_ERROR_TYPE];

export const APPOINTMENT_ROLE_TYPE = {
    PetOwner: 2,
    ShopOwner: 3
} as const;
export type AppointmentRoleType = (typeof APPOINTMENT_ROLE_TYPE)[keyof typeof APPOINTMENT_ROLE_TYPE];

export const APPOINTMENT_INFOR_TYPE = {
    general: 'general',
    pickUp: 'pick-up-service',
    service: 'services'
};
export type AppointmentInforType = (typeof APPOINTMENT_INFOR_TYPE)[keyof typeof APPOINTMENT_INFOR_TYPE];

export const FORUM_TYPE = {
    FreeTopic: 1,
    Career: 2
} as const;
export type ForumType = (typeof FORUM_TYPE)[keyof typeof FORUM_TYPE];

export const SOCKET_EVENT_TYPE = {
    Connect: 'connect',
    Connected: 'connected',
    NewNotification: 'new-notification',
    NewMessage: 'new-message',
    SeenMessage: 'seen-message'
} as const;
export type SocketEventType = (typeof SOCKET_EVENT_TYPE)[keyof typeof SOCKET_EVENT_TYPE];

export const LOAD_MESSAGE_TYPE = {
    Top: 0,
    End: 1
} as const;
export type LoadMessageType = (typeof LOAD_MESSAGE_TYPE)[keyof typeof LOAD_MESSAGE_TYPE];

export const TOPIC_TYPE = {
    Notice: 1,
    Advertisement: 2,
    Best: 3
} as const;

export type TopicType = (typeof TOPIC_TYPE)[keyof typeof TOPIC_TYPE];

export const STATISTIC_TYPE = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;

export type StatisticType = (typeof STATISTIC_TYPE)[keyof typeof STATISTIC_TYPE];

export const MONTH_OF_YEAR_TYPE = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12
} as const;

export type MonthOfYearType = (typeof MONTH_OF_YEAR_TYPE)[keyof typeof MONTH_OF_YEAR_TYPE];

export const CHAT_PARTICIPANT_STATUS = {
    UNAVAILABLE: 0,
    DELETED: 1,
    AVAILABLE: 2
} as const;
export type ChatParticipantStatus = (typeof CHAT_PARTICIPANT_STATUS)[keyof typeof CHAT_PARTICIPANT_STATUS];

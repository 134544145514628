import FaceBook from '@/assets/images/facebook.svg';
import Instagram from '@/assets/images/instagram.svg';
import Phone from '@/assets/images/phone.svg';
import Tiktok from '@/assets/images/tiktok.svg';
import Youtube from '@/assets/images/youtube.svg';
import { ClockIcon, LocationIcon, PetHospitalIcon, PetPrintIcon } from '@/components/icons';
import { DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, MAX_LENGTH_TEXT_DESKTOP, SHOP_CATEGORY } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { formatDateTime } from '@/utils/datetime';
import { shortenParagraph } from '@/utils/utils';
import { Download } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InformationShopItem from './components/InformationShopItem';
import { SHOP_CATEGORY_TYPE } from '@/types/enums';

export default observer(function ShopDetailOverview() {
    // hooks
    const { t } = useTranslation();

    // store
    const { shopStore: { shopDetail } } = useStore();

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    // function
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='bg-white rounded h-auto px-4 py-3'>
            <div className='min-h-7 flex justify-between items-center gap-2'>
                <div className='h-full min-h-8 bg-background-3 border-l border-l-icon-4 rounded-[0.125rem] flex items-center justify-start px-2 gap-1'>
                    {shopDetail?.category === SHOP_CATEGORY_TYPE.PetHospital ? <PetHospitalIcon className='h-5 w-5 fill-active' /> : <PetPrintIcon className='h-4 w-4 fill-icon-4' />}
                    <p className='text-tab-1 text-small'>{t(`select_options.${SHOP_CATEGORY[shopDetail?.category]?.key}`)}</p>
                </div>
            </div>
            <div className='mt-[0.375rem]'>
                <div className='line-clamp-2'>
                    <h3 className='text-text-3 text-[1.0625rem] font-semibold my-3'>{shopDetail?.name}</h3>
                </div>
                <div className='text-tab-1'>
                    <div className='mt-1.5 flex space-x-2 text-small justify-start'>
                        <div>
                            <LocationIcon className='fill-icon-2 size-[0.8125rem] mt-1' />
                        </div>
                        <p>
                            {shopDetail?.address}
                        </p>
                    </div>
                    <div className='mt-1.5 flex space-x-2 text-small items-center'>
                        <div>
                            <img src={Phone} alt='' />
                        </div>
                        <p>{shopDetail?.phoneNumber}</p>
                    </div>
                    <div className='mt-1.5 flex space-x-2 text-small items-center'>
                        <div>
                            <ClockIcon className='size-[0.8125rem] fill-icon-2' />
                        </div>
                        <p>
                            {formatDateTime(shopDetail?.generalOpenTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            })}
                            {' '}
                            -
                            {' '}
                            {formatDateTime(shopDetail?.generalCloseTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            })}
                        </p>
                    </div>
                    <InformationShopItem url={shopDetail?.facebook} image={FaceBook} />
                    <InformationShopItem url={shopDetail?.youtube} image={Youtube} />
                    <InformationShopItem url={shopDetail?.instagram} image={Instagram} />
                    <InformationShopItem url={shopDetail?.tiktok} image={Tiktok} />
                    {
                        shopDetail?.menuFile && (
                            <p className='mt-1.5 flex space-x-2 text-small items-center justify-start'>
                                <Download className='size-[0.8125rem]' />
                                <a href={shopDetail?.menuFile} className='underline'>{t('words_title.download_menu')}</a>
                            </p>
                        )
                    }
                </div>
            </div>
            <p
                className='mt-3 text-text-9 text-ellipsis text-small whitespace-pre-line'
            >
                {shortenParagraph(shopDetail?.introduction, MAX_LENGTH_TEXT_DESKTOP, isExpanded).value}
                {
                    shortenParagraph(shopDetail?.introduction, MAX_LENGTH_TEXT_DESKTOP, isExpanded).isEllipsis && (
                        <button
                            onClick={toggleExpand}
                            className='text-active text-[0.8125rem] text-blue-500 no-underline'
                        >
                            {isExpanded ? t('button.view_less') : t('button.view_more')}
                        </button>
                    )
                }
            </p>
        </div>
    );
});

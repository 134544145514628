import { APPOINTMENT_STATUS, DISPLAY_MOMENT_DATE_DMYHM_AM_PM, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { APPOINTMENT_ROLE_TYPE, APPOINTMENT_STATUS_TYPE } from '@/types/enums';
import { formatDateTime } from '@/utils/datetime';
import { SquarePen } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default observer(function GeneralInformation() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        appointmentStore: { appointment }
    } = useStore();

    const type = appointment?.status ? APPOINTMENT_STATUS[appointment.status]?.key : undefined;

    const bgColorStatus = {
        not_confirmed: 'bg-icon-18',
        confirmed: 'bg-icon-19',
        completed: 'bg-icon-20',
        cancelled: 'bg-icon-21'
    };

    return (
        <div className='flex flex-col gap-4 bg-white p-4 md:rounded-sm'>
            <div className='flex justify-between md:px-4 items-center'>
                <h4 className='font-semibold text-[0.9375rem]'>{t('words_title.general_information')}</h4>
                {(appointment?.status === APPOINTMENT_STATUS_TYPE.NotConfirmed ||
                    appointment?.status === APPOINTMENT_STATUS_TYPE.Confirmed
                ) && (
                    <Link to={`${ROUTES.editGeneralInformation.href}/${appointment.code}`} className='flex gap-1 items-center justify-center'>
                        <SquarePen className='text-black size-6 md:size-4' />
                        <p className='font-medium hidden md:block'>{t('button.edit')}</p>
                    </Link>
                )}
            </div>
            <hr className='text-border-8' />
            <div className='grid grid-cols-2 md:gap-4 gap-y-1.5'>
                <div className='md:px-4 py-3 col-span-2 border-b border-border-5'>
                    <p className='text-text-4'>{t('words_title.appointment_id')}</p>
                    <p className=''>{appointment?.code || '--'}</p>
                </div>
                <div className='md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5'>
                    <p className='text-text-4'>{t('words_title.appointment_time')}</p>
                    <p className=''>
                        { formatDateTime(`${appointment?.appointmentDate} ${appointment?.appointmentTime}`, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}
                    </p>
                </div>
                <div className='md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5'>
                    <p className='text-text-4'>{t('words_title.created_date')}</p>
                    <p className=''>
                        { formatDateTime(appointment?.createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM) || '--'}
                    </p>
                </div>
                <div className='md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5 md:border-none'>
                    <p className='text-text-4'>{t('words_title.last_update')}</p>
                    <p className=''>{formatDateTime(appointment?.updatedAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM) || '--'}</p>
                </div>
                <div className='md:px-4 py-3 col-span-2 md:col-span-1'>
                    <p className='text-text-4 pb-1'>{t('words_title.appointment_status')}</p>
                    <div className='inline-flex items-center justify-center px-2.5 py-0.5 gap-1 rounded-xl bg-background-4'>
                        <span className={`size-2 rounded-full ${type ? bgColorStatus[type] : ''}`}></span>
                        <p className='text-text-14'>{t(`words_title.${type}`)}</p>
                    </div>
                </div>
                {(appointment?.cancelledBy === APPOINTMENT_ROLE_TYPE.PetOwner) && (
                    <p className='text-text-21 font-medium md:px-4 col-span-2'>{t('sentences.cancelled_by_pet_owner')}</p>
                )}
                {(appointment?.cancelledBy === APPOINTMENT_ROLE_TYPE.ShopOwner) && (
                    <p className='text-text-21 font-medium md:px-4 col-span-2'>{t('sentences.cancelled_by_you')}</p>
                )}
            </div>
        </div>
    );
});

import moment from 'moment';

export const calculateAge = (birthday: string) => {
    const today = new Date();
    const [birthYear, birthMonth] = birthday.split('-').map(Number);

    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;

    let years = currentYear - birthYear;
    let months = currentMonth - birthMonth;

    if (months < 0) {
        years--;
        months += 12;
    }
    return {
        years: years > 0 ? years : 0,
        months: months > 0 ? months : 0
    };
};

export const formatAge = (birthday: string, t: Function) => {
    const age = calculateAge(birthday);
    const yearLabel = age.years > 0 ? `${age.years} ${age.years === 1 ? t('words_title.year') : t('words_title.years')}` : '';
    const monthLabel = age.months > 0 ? `${age.months} ${age.months === 1 ? t('words_title.month') : t('words_title.months')}` : '';

    // Điều kiện để trả về chuỗi tuổi
    if (age.years > 0 && age.months > 0) {
        return `${yearLabel} ${monthLabel}`;
    } else if (age.years > 0) {
        return yearLabel;
    } else if (age.months > 0) {
        return monthLabel;
    } else {
        return `0 ${t('words_title.month')}`;
    }
};

interface formatDateTimeOptions {
    returnDefault?: null | '',
    onlyTime?: boolean,
    currentformat?: string
}

export const formatDateTime = (datetime?: string | Date | null, format?: string, options?: formatDateTimeOptions) => {
    if (!datetime) return options?.returnDefault ?? null;
    if (options?.onlyTime && options?.currentformat) {
        return moment(datetime, [moment.ISO_8601, options.currentformat]).format(format);
    }
    if (datetime instanceof Date) return moment(datetime).format(format);
    return moment(datetime).format(format);
};

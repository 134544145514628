import { DAYS_OF_WEEK, DISPLAY_FNS_DATE_YMD_FOR_LABEL_CALENDAR, DISPLAY_MOMENT_DATE_YMD, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, ROUTES, SHOP_SERVICE_NAME, SUBMIT_MOMENT_DATE_YM, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { formatDateTime } from '@/utils/datetime';
import { TableState } from '@tanstack/react-table';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventObject } from '@toast-ui/calendar/types/types/events';
import Calendar from '@toast-ui/react-calendar';
import { APPOINTMENT_STATUS_TYPE, LANGUAGES_SUPPORTED, SHOP_SERVICE_TYPE } from '@/types/enums';
import { flowResult } from 'mobx';
import { GetAllAppointmentsResponse } from '@/types/http-payload/shop';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import classNames from 'classnames';

import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import './style.scss';
import CalendarDatetime from '@/components/calendar/CalendarDatetime';
import moment, { Moment } from 'moment';

const APPOINTMENT_STATUS_TO_CALENDER_MAPPER = {
    [APPOINTMENT_STATUS_TYPE.NotConfirmed]: {
        colorClassname: 'appointment-not-confirmed'
    },
    [APPOINTMENT_STATUS_TYPE.Confirmed]: {
        colorClassname: 'appointment-confirmed'
    },
    [APPOINTMENT_STATUS_TYPE.Completed]: {
        colorClassname: 'appointment-completed'
    }
};

export default observer(function ShopMonthlyAppointmentDashboard() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const {
        appointmentStore: { getAllAppointments, setObservable, defaultPaging },
        modalStore: { showModal, hideModal },
        uiStore: { windowSize: { width } }
    } = useStore();

    // ref
    const calenderRef = useRef<Calendar>(null);

    // state
    const [events, setEvents] = useState<Partial<EventObject>[]>([]);
    const [appointments, setAppointments] = useState<GetAllAppointmentsResponse>();
    const [month, setMonth] = useState(new Date());

    // lifecycle
    useEffect(() => {
        fetchData();
        return () => {
            setObservable('paging', defaultPaging);
        };
    }, [month]);

    useEffect(() => {
        if (appointments && appointments?.elements?.length > 0) {
            convertEventToAppointment(appointments.elements);
        }
    }, [appointments]);

    useEffect(() => {
        if (calenderRef?.current?.calendarInstance) {
            calenderRef.current.calendarInstance.setOptions({ template });
        }
    }, [language]);

    // function
    const fetchData = async () => {
        const payloadForTotalAppointments = {
            appointmentMonthDate: formatDateTime(month, SUBMIT_MOMENT_DATE_YM) ?? '',
            statusList: [APPOINTMENT_STATUS_TYPE.NotConfirmed, APPOINTMENT_STATUS_TYPE.Confirmed, APPOINTMENT_STATUS_TYPE.Completed]
        };
        const res = await flowResult(getAllAppointments(payloadForTotalAppointments, {
            pagination: {
                pageIndex: 0,
                pageSize: -1
            },
            sorting: [
                {
                    id: 'appointment_date',
                    desc: false
                },
                {
                    id: 'appointment_time',
                    desc: false
                },
                {
                    id: 'created_at',
                    desc: false
                }
            ]
        } as TableState));
        if (res?.ok) {
            setAppointments(res?.data);
        }
    };

    const template = {
        time(event) {
            return `<span class="${event?.raw?.classname}">${event.title}</span>`;
        },
        monthDayName(model) {
            return language === LANGUAGES_SUPPORTED.English ? DAYS_OF_WEEK[model.day].shortCutEn : DAYS_OF_WEEK[model.day].shortCutVi;
        }
    };

    const convertEventToAppointment = (appointmentList) => {
        let eventsFormat: Partial<EventObject>[] = [];

        if (appointmentList.length > 0) {
            eventsFormat = appointmentList.map((appointment) => {
                if (appointment) {
                    return {
                        id: appointment.code,
                        title: `${formatDateTime(appointment.appointmentTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                            onlyTime: true,
                            currentformat: DISPLAY_MOMENT_TIME_HMS
                        })} ${width > 1024 ? `- ${appointment.pet.name}` : ''}`,
                        start: new Date(`${appointment.appointmentDate} ${appointment.appointmentTime}`),
                        end: new Date(`${appointment.appointmentDate} ${appointment.appointmentTime}`),
                        category: 'time',
                        raw: {
                            classname: APPOINTMENT_STATUS_TO_CALENDER_MAPPER[appointment.status]?.colorClassname,
                            appointmentTime: formatDateTime(appointment.appointmentTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            }),
                            petName: appointment.pet.name,
                            species: appointment?.pet?.species ?
                                (
                                    language === LANGUAGES_SUPPORTED.Vietnamese ? appointment.pet.species?.name : appointment.pet.species?.nameEn
                                ) :
                                t('words_title.other'),
                            selectedServices: appointment.selectedServices
                        }
                    };
                }
                return null;
            }).filter(Boolean);
        }

        setEvents(eventsFormat);
    };

    const handlePrevMonth = () => {
        if (calenderRef.current?.calendarInstance) {
            calenderRef.current.calendarInstance.prev();
            setMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
        }
    };

    const handleNextMonth = () => {
        if (calenderRef.current?.calendarInstance) {
            calenderRef.current.calendarInstance.next();
            setMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
        }
    };

    const handleClickMoreBtn = (event) => {
        const toastuiCalendarPopupOverlay = document.getElementsByClassName('toastui-calendar-popup-overlay');
        if (toastuiCalendarPopupOverlay?.length > 0) {
            for (let index = 0; index < toastuiCalendarPopupOverlay.length; index++) {
                const element = toastuiCalendarPopupOverlay[index] as HTMLDivElement;
                element.click();
            }
        }

        showModalDetail(event.date);
    };

    const handleClickEvent = (eventInfo) => {
        window.open(`${ROUTES.appointmentManagement.href}/${eventInfo.id}`, '_blank');
    };

    const AppointmentDetailModal = ({ appointments }) => {
        return (
            <div>
                {
                    appointments?.length > 0 ?
                        (
                            <>
                                {
                                    appointments.map((appointment, index) => (
                                        <AppointmentDetailItem key={index} appointment={appointment} index={index} />
                                    ))
                                }
                            </>
                        ) :
                        <div className='text-icon-2 text-center mt-10 text-small'>{t('sentences.this_day_no_data')}</div>
                }
            </div>
        );
    };

    const showModalDetail = (date) => {
        if (appointments?.elements && appointments?.elements.length > 0) {
            const filterAppointments = appointments?.elements.filter(appointment => appointment.appointmentDate === formatDateTime(date, SUBMIT_MOMENT_DATE_YMD)) ?? [];
            showModal({
                size: 'xl',
                content: <AppointmentDetailModal appointments={filterAppointments} />,
                cancelButton: t('button.close'),
                variantCancelBtn: 'outline',
                footerClassName: 'sm:justify-center',
                onCancel: () => {
                    hideModal();
                },
                showCloseIcon: false,
                contentContainerClassName: 'max-h-96 overflow-y-auto',
                headerClassName: 'px-4 py-6',
                headerRender: (
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-1'>
                            <CalendarIcon className='text-text-9 w-5 h-5' />
                            <div className='text-[1.0625rem] font-semibold'>{formatDateTime(date, DISPLAY_MOMENT_DATE_YMD)}</div>
                        </div>
                        <div className='text-[0.9375rem]'>
                            <span className='text-tab-1'>{`${t('words_title.total')}: `}</span>
                            <span className='text-text-9 font-medium'>{`${filterAppointments?.length} ${t('words_title.appointment')}${appointments?.elements.length > 1 && language === LANGUAGES_SUPPORTED.English ? '(s)' : ''}`}</span>
                        </div>
                    </div>
                )
            });
        }
    };

    return (
        <div className='calendar-dashboard bg-white rounded md:p-4 px-0 py-4 mt-4'>
            <div className='text-[0.9375rem] text-text-9 font-semibold pb-4 border-b border-border-12 md:mb-4 mb-0 md:px-0 px-4'>{t('words_title.monthly_dashboard')}</div>
            <div className='flex items-center justify-center text-2xl md:mb-5 mb-0'>
                <ChevronLeftIcon className='cursor-pointer h-6 w-6' onClick={handlePrevMonth} />
                <CalendarDatetime
                    className='mt-[0.375rem] px-6'
                    value={month}
                    dateFormat={DISPLAY_FNS_DATE_YMD_FOR_LABEL_CALENDAR}
                    onChange={(value) => {
                        if (calenderRef.current?.calendarInstance) {
                            calenderRef.current.calendarInstance.move((value as Moment).diff(month, 'month'));
                        }
                        return setMonth(moment(value).toDate());
                    }}
                    timeFormat={false}
                    initialViewMode='months'
                    updateOnView='months'
                    inputProps={{
                        placeholder: t('placeholder.choose')
                    }}
                    closeOnSelect={true}
                    useIconRemove={false}
                    renderInput={({ value, onClick, onFocus }) => (
                        <button
                            className='mb-[0.375rem] text-[0.9375rem] text-[#303030]'
                            type='button'
                            onClick={onClick}
                            onFocus={onFocus}
                        >
                            {value}
                        </button>
                    )}
                />
                <ChevronRightIcon className='cursor-pointer h-6 w-6' onClick={handleNextMonth} />
            </div>
            <Calendar
                ref={calenderRef}
                usageStatistics={false}
                view='month'
                height='900px'
                events={events}
                month={{
                    startDayOfWeek: 1
                }}
                template={template}
                onClickMoreEventsBtn={moreEventsBtnInfo => handleClickMoreBtn(moreEventsBtnInfo)}
                onAfterRenderEvent={(eventInfo) => {
                    const element = document.querySelector(`[data-event-id="${eventInfo.id}"]`);
                    if (element) {
                        element.addEventListener('click', () => {
                            if (width > 920) {
                                return handleClickEvent(eventInfo);
                            } else {
                                return showModalDetail(eventInfo.start.d.d);
                            }
                        });
                    }
                }}
            />
        </div>
    );
});

const AppointmentDetailItem = observer(({ appointment, index }: any) => {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const {
        uiStore: { windowSize: { width } }
    } = useStore();

    // ref
    const serviceContainerRef = useRef<HTMLDivElement | null>(null);

    // state
    const [secondLineIndex, setSecondLineIndex] = useState<number | null>(null);

    // lifecycle
    useEffect(() => {
        if (serviceContainerRef.current) {
            const spans = serviceContainerRef.current.querySelectorAll('span');
            if (spans.length > 0) {
                let initialOffsetTop = spans[0].offsetTop;
                let lineCount = 1;

                for (let i = 1; i < spans.length; i++) {
                    if (spans[i].offsetTop > initialOffsetTop) {
                        lineCount++;
                        initialOffsetTop = spans[i].offsetTop;
                    }

                    if (lineCount === 2) {
                        setSecondLineIndex(i);
                        break;
                    }
                }
            }
        }
    }, [width]);

    return (
        <div
            className={classNames('flex gap-4 py-4 items-start cursor-pointer', index !== 0 && 'border-t border-dotted border-border-8')}
            onClick={() => {
                window.open(`${ROUTES.appointmentManagement.href}/${appointment.code}`, '_blank');
            }}
        >
            <div className={classNames('min-w-18 text-[0.9375rem] flex-none cursor-pointer', APPOINTMENT_STATUS_TO_CALENDER_MAPPER[appointment.status]?.colorClassname)}>
                {formatDateTime(appointment.appointmentTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                    onlyTime: true,
                    currentformat: DISPLAY_MOMENT_TIME_HMS
                })}
            </div>
            <div className='flex flex-col gap-2'>
                <div className='font-medium text-title-1 line-clamp-1'>{appointment?.pet?.name}</div>
                <div className='text-small text-tab-1 line-clamp-1'>
                    {appointment?.pet?.species?.id ?
                        (
                            language === LANGUAGES_SUPPORTED.Vietnamese ? appointment.pet.species?.name : appointment.pet.species?.nameEn
                        ) :
                        (
                            appointment?.pet?.species ? appointment.pet.species : t('words_title.other')
                        )}
                </div>
                <div ref={serviceContainerRef} className='flex gap-1 flex-wrap items-center'>
                    {
                        appointment.pickUpService && (
                            <PetServiceItem serviceName={t('words_title.pick_up_service')} />
                        )
                    }
                    {
                        appointment?.selectedServices?.length > 0 &&
                        appointment.selectedServices.slice(0, secondLineIndex ?? appointment.selectedServices.length).map((service, index) => (
                            <PetServiceItem
                                key={index}
                                serviceName={(service.type === SHOP_SERVICE_TYPE.Cat || service.type === SHOP_SERVICE_TYPE.Dog) ?
                                    t(`select_options.${SHOP_SERVICE_NAME[service.serviceName]?.key}`) :
                                    service?.serviceName}
                            />
                        ))
                    }
                    {secondLineIndex && secondLineIndex < appointment?.selectedServices?.length && (
                        <div>
                            <span
                                className='font-normal max-w-[20ch] truncate text-text-4'
                            >
                                {`+${appointment?.selectedServices?.length - secondLineIndex} ${t('words_title.others')}`}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

const PetServiceItem = ({ serviceName }) => {
    return (
        <span className='font-normal rounded-xl bg-background-4 py-1 px-2.5 max-w-[20ch] truncate'>{serviceName}</span>
    );
};

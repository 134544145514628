import { observer } from 'mobx-react-lite';
import ShopItem from './components/ShopItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TailSpin } from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import { useStore } from '@/hooks/useStore';
import { TableState } from '@tanstack/react-table';
import { SORT_OPTION } from '@/configs/constants';
import { SearchShopParams } from '@/types/http-payload/shop';
import { ShopCardInfo } from '@/types/shop';
import { flowResult } from 'mobx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { Location } from '@/types/general';
import LazyLoad from 'react-lazy-load';

export default observer(function NearBy() {
    // hook
    const { t, i18n: { language } } = useTranslation('translation');
    const { pathname } = useLocation();
    // store
    const { shopStore: { nearByShops, getAllShop, paging, setAllShop, setObservable: setShopVarObservable, cleanAllShop }, uiStore: { currentLocation, windowSize, devicesScreen: { mobile, desktop }, setObservable } } = useStore();
    const { height } = windowSize;
    // state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedShop, setSelectedShop] = useState<ShopCardInfo>();
    const [mapUrl, setMapUrl] = useState<string>('');
    const [open, setOpen] = useState(false);

    // lifecycle
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setObservable('currentLocation', { latitude: position.coords.latitude,
                        longitude: position.coords.longitude }, { isMergeObject: true });

                    getDataNearByShop(paging, { latitude: position.coords.latitude,
                        longitude: position.coords.longitude });
                },
                (error) => {
                    getDataNearByShop(paging);
                }
            );
        } else {
            getDataNearByShop(paging);
        }

        return () => {
            cleanAllShop();
        };
    }, []);
    // useEffect(() => {
    //     getDataNearByShop(paging);
    // }, [currentLocation?.latitude, currentLocation?.longitude]);

    useEffect(() => {
        handleOnChangeMap(currentLocation, null);
    }, [currentLocation]);

    useEffect(() => {
        if (windowSize.width >= 1024 && open === true) {
            setOpen(false);
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (open === false && windowSize.width < 1024) {
            setSelectedShop(undefined);
        }
    }, [open]);
    // function
    const getDataNearByShop = async (paging: TableState, location?: Location | null) => {
        let pagingReq: TableState = { ...paging };
        let params: SearchShopParams = {};
        if (location) {
            params.location = location.latitude + ',' + location.longitude;
        }
        pagingReq = {
            ...pagingReq,
            sorting: [
                {
                    id: SORT_OPTION.Nearby.value,
                    desc: true
                }
            ]
        };

        // pagingReq.pagination.pageSize = 4;

        params.isGetNearBy = true;
        params.shopIds = nearByShops.map(item => item.id).join(',');
        let currentTotal = nearByShops?.length || 0;

        const res = await flowResult(getAllShop(params, pagingReq));
        if (res) {
            let shopData: ShopCardInfo[] = [];
            shopData = nearByShops.length > 0 ? [...nearByShops, ...res.elements] : res.elements;
            setIsLoading(shopData.length < (res.pagination.totalRecord + currentTotal));
            setShopVarObservable('nearByShops', shopData, { isMergeObject: false });
        }
    };

    const onSelectedShop = (selectedShop: ShopCardInfo) => {
        setSelectedShop({ ...selectedShop });
        windowSize.width < 1024 && setOpen(true);
    };

    const handleOnChangeMap = (location: Location | null | undefined, placeId?: string | null) => {
        let initGoogleMap = 'https://www.google.com/maps/embed/v1/';
        if (location && placeId) {
            initGoogleMap += `directions?origin=${location?.latitude},${location?.longitude}&destination=place_id:${placeId}`;
        } else if (location && !placeId) {
            initGoogleMap += `place?q=${currentLocation?.latitude},${currentLocation?.longitude}`;
        } else if (!location && placeId) {
            initGoogleMap += `place?q=place_id:${placeId}`;
        } else {
            initGoogleMap += 'view?center=21.0285,105.8542&zoom=12&maptype=roadmap';
        }
        initGoogleMap += `&language=${language}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
        setMapUrl(initGoogleMap);
    };

    return (
        <div className='grid lg:grid-cols-[420px,1fr] h-full md:gap-4 md:pt-8'>
            <div
                className='bg-white overflow-y-auto overflow-x-hidden scrollbar scrollbar-width-2'
                style={{
                    height: mobile ? `calc(${height}px - 10.625rem)` : 'calc(100vh - 6.75rem)'
                }}
                id='scrollableDiv'
            >
                <InfiniteScroll
                    dataLength={nearByShops.length}
                    next={() => getDataNearByShop(paging, currentLocation)}
                    hasMore={isLoading}
                    loader={(
                        <div className='w-full flex justify-center items-center h-8'>
                            <TailSpin
                                visible={true}
                                height='20'
                                width='20'
                                color='#64748b'
                                ariaLabel='tail-spin-loading'
                                radius='1'
                                wrapperStyle={{}}
                                wrapperClass=''
                            />
                        </div>

                    )}
                    scrollableTarget='scrollableDiv'
                >
                    {nearByShops.length > 0 ?
                        nearByShops.map((shop, index) => {
                            return (
                                <LazyLoad className='' key={index}>
                                    <ShopItem
                                        isViewing={selectedShop && selectedShop.code === shop.code}
                                        shopInfor={shop}
                                        onSelectedShop={() => {
                                            onSelectedShop(shop);
                                            handleOnChangeMap(currentLocation, shop.placeId);
                                        }}
                                    />
                                </LazyLoad>
                            );
                        }) :
                        <p className='mt-10 text-center'>{t('sentences.no_shop_available')}</p>}
                </InfiniteScroll>
            </div>
            <div className='bg-white lg:block hidden'>
                <iframe
                    className='h-full w-full'
                    title='embed-maps'
                    loading='lazy'
                    referrerPolicy='no-referrer-when-downgrade'
                    src={mapUrl}
                />
            </div>
            {windowSize.width < 1024 && (
                <div>
                    <Sheet open={open} onOpenChange={setOpen}>
                        <SheetContent
                            side='map'
                            className='p-0 flex flex-col gap-0'
                            closeButtonClassName='bg-border-1 h-6 w-6 flex items-center justify-center rounded-full text-black opacity-100 hover:opacity-70'
                            onPointerDownOutside={e => e.preventDefault()}
                        >
                            <SheetHeader className='p-4  flex items-center justify-center'>
                                <SheetTitle className='text-[0.9375rem]'>{t('words_title.map')}</SheetTitle>
                                <SheetDescription></SheetDescription>
                            </SheetHeader>
                            <div className='flex-1'>
                                <iframe
                                    className='h-full w-full'
                                    title='embed-maps'
                                    loading='lazy'
                                    referrerPolicy='no-referrer-when-downgrade'
                                    src={mapUrl}
                                    allowFullScreen
                                />
                            </div>
                        </SheetContent>
                    </Sheet>
                </div>
            )}
        </div>
    );
});

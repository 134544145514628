import { Button } from '@/components/common/Button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from '@/components/common/Form';
import { FloatingLabelInput } from '@/components/common/Input';
import { Tabs, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { ENDUSER_TYPE } from '@/types/enums';
import { getStorage, removeStorage } from '@/utils/browsers';
import { yupResolver } from '@hookform/resolvers/yup';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default observer(function SignInPage() {
    // hooks
    const { t } = useTranslation();
    const location = useLocation();

    // state
    const signInUserType = getStorage('signInUserType');
    const hashShopOwner = location.hash === '#shop-owner';
    const isSwitchSignInAsShop = hashShopOwner && signInUserType === ENDUSER_TYPE.ShopOwner.toString();
    const [userType, setUserType] = useState<string>(location?.state?.userType ? location.state.userType : signInUserType ?? ENDUSER_TYPE.PetOwner.toString());

    // lifecycle
    useEffect(() => {
        if (!hashShopOwner) {
            removeStorage(['signInUserType']);
        }
    }, []);

    // function
    const onClickChangeTab = (value) => {
        setUserType(value);
    };

    return (
        <div>
            <h3 className='title-1 text-center mt-6'>{t('words_title.sign_in')}</h3>
            <div className='mx-auto flex flex-col justify-center'>
                <Tabs defaultValue={userType} className='w-full mt-6' onValueChange={onClickChangeTab}>
                    <TabsList variant='form' className='grid w-full grid-cols-2'>
                        <TabsTrigger variant='form' disabled={isSwitchSignInAsShop} value={String(ENDUSER_TYPE.PetOwner)}>{t('words_title.pet_owner')}</TabsTrigger>
                        <TabsTrigger variant='form' value={String(ENDUSER_TYPE.ShopOwner)}>{t('words_title.shop_owner')}</TabsTrigger>
                    </TabsList>
                    <SignInForm userType={userType} isSwitchSignInAsShop={isSwitchSignInAsShop} />
                </Tabs>
            </div>
        </div>
    );
});

interface SignInFormProps {
    userType: string,
    isSwitchSignInAsShop?: boolean
}

const SignInForm = observer(({ userType, isSwitchSignInAsShop }: SignInFormProps) => {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const { authStore: { signIn } } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        phoneNumber: yup
            .string()
            .required('form_error_validate.required'),
        password: yup
            .string()
            .required('form_error_validate.required')
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            phoneNumber: '',
            password: ''
        }
    });

    const [showPassword, setShowPassword] = useState<boolean>(false);

    // function
    const onSubmit = async (data: FormData) => {
        const { phoneNumber, password } = data;
        const res = await flowResult(signIn({ phoneNumber, password, type: Number(userType) }));
        if (res) {
            navigate(ROUTES.home.href);
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='mt-6'>
                <div>
                    <FormField
                        control={form.control}
                        name='phoneNumber'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <FloatingLabelInput
                                        type='number'
                                        label={t(
                                            'words_title.phone_number'
                                        )}
                                        isTypingOnlyNumber={true}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='password'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <FloatingLabelInput
                                        type={!showPassword ? 'password' : 'text'}
                                        label={t(
                                            'words_title.password'
                                        )}
                                        className='mt-4'
                                        icon={
                                            !showPassword ?
                                                <EyeOffIcon className='icon-right-input' onClick={() => setShowPassword(!showPassword)} /> :
                                                <EyeIcon className='icon-right-input' onClick={() => setShowPassword(!showPassword)} />
                                        }
                                        isPreventCopy={true}
                                        isPreventCut={true}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                {
                    !isSwitchSignInAsShop &&
                    <Link to='/forgot-password' state={{ previousUrl: ROUTES.signIn.href }} className='link-1 mt-4'>{t('sentences.forgot_your_password')}</Link>
                }
                <Button type='submit' variant='submit' size='submit' className='mt-6'>
                    {t('words_title.sign_in')}
                </Button>
                {
                    !isSwitchSignInAsShop && (
                        <div className='text-center text-[0.8125rem] mt-6'>
                            <span className='text-text-1'>{t('sentences.you_dont_have_an_account')}</span>
                            {' '}
                            <Link to='/sign-up' state={{ previousUrl: ROUTES.signIn.href, userType }} className='link-1'>{t('sentences.register_account')}</Link>
                        </div>
                    )
                }
            </form>
        </Form>
    );
});

import Notfound from '@/assets/images/myPetNotfound.png';
import { Button } from '@/components/common/Button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { PET_CARE_HISTORY_TYPE, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PetNoteList from './pet-note-list';

export default observer(function PetNote() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        uiStore: { devicesScreen: { desktop } },
        petStore: { getAllPet, pets },
        apiStore: { isLoading }
    } = useStore();

    // lifecycle
    useEffect(() => {
        getAllPet();
    }, []);

    return (
        <>
            <div className={classNames('w-full md:pt-2')}>
                {desktop && (
                    <h3 className={classNames('title-1 my-auto text-center text-active md:text-2xl')}>{t('words_title.pet_note')}</h3>
                )}
                {
                    pets.length > 0 && (
                        <div className={classNames('rounded-[0.25rem] md:mx-auto md:min-h-[calc(100vh-400px)] h-full w-full md:mt-2')}>
                            <Tabs
                                defaultValue='grooming'
                                className='w-full h-full flex flex-col'
                            >
                                <TabsList variant='createShop' data-orientation='horizontal' className={classNames('bg-white flex w-full overflow-x-auto')}>
                                    <TabsTrigger variant='createShop' className='w-full' value='grooming'>{t('words_title.grooming_history')}</TabsTrigger>
                                    <TabsTrigger variant='createShop' className='w-full' value='medical'>{t('words_title.medical_history')}</TabsTrigger>
                                </TabsList>
                                <TabsContent value='grooming'>
                                    {/* <CommingSoonPage /> */}
                                    <PetNoteList type={PET_CARE_HISTORY_TYPE.GROOMING_HISTORY} />
                                </TabsContent>
                                <TabsContent value='medical'>
                                    {/* <CommingSoonPage /> */}
                                    <PetNoteList type={PET_CARE_HISTORY_TYPE.MEDICAL_HISTORY} />
                                </TabsContent>
                            </Tabs>
                        </div>

                    )
                }
                {
                    pets.length === 0 && !isLoading && (
                        <>
                            <div className='text-center pt-[3.75rem] py-6 bg-white h-screen md:h-auto'>
                                <img className='max-w-[19.625rem] h-[11.375rem] mx-auto' src={Notfound} alt='My Pet NotFound' />
                                <div className='mt-5'>
                                    <h3 className='font-semibold text-[0.9375rem] leading-[1.3125rem]'>{t('sentences.you_dont_have_any_pet_2')}</h3>
                                </div>
                                <Button
                                    onClick={() => navigate(ROUTES.myPet.href)}
                                    className='h-[38px] w-60 md:w-80 mx-auto mt-5 md:flex items-center justify-center'
                                >
                                    {t('button.go_to_my_pet')}
                                </Button>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
});

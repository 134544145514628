import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem } from '@/components/common/Form';
import { InputOTP, InputOTPSlot } from '@/components/common/Input';
import BackToSigninBtn from '@/components/general/BackToSigninBtn';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CountDownClock from '../../components/common/CountDownClock';
import { useStore } from '@/hooks/useStore';
import { flowResult } from 'mobx';
import { DURATION_RESEND_OTP, ROUTES } from '@/configs/constants';
import { VERIFY_OTP_TYPE, VerifyOtpType } from '@/types/enums';
import { toastify } from '@/utils/toastify';

interface VerifyOtpPageProps {
    type: VerifyOtpType
}

export default observer(function VerifyOtpPage({ type }: VerifyOtpPageProps) {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const uuid = searchParams.get('uuid') ?? '';

    // store
    const { authStore: { signUp, resendOtp, verifyOtp }, modalStore: { showAlertModal, hideModal } } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        verifyOtp: yup
            .string()
            .min(6)
            .required()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            verifyOtp: ''
        }
    });

    const [runTimmer, setRunTimer] = useState<boolean>(true);

    // life cycle
    // function
    const onSubmit = async (data: FormData) => {
        if (type === VERIFY_OTP_TYPE.Signup) {
            const res = await flowResult(signUp({ uuid, ...data }));

            if (res && res.ok) {
                showAlertModal({
                    type: 'success',
                    content: t('messages.verify_otp_signup_success'),
                    saveButton: t('button.back_to_signin'),
                    onSave: () => navigate(ROUTES.signIn.href),
                    onCancel: () => navigate(ROUTES.signIn.href)
                });
            } else if (res && !res.ok) {
                showAlertModal({
                    type: 'error',
                    content: res.message,
                    saveButton: t('button.close'),
                    onSave: () => hideModal()
                });
            }
        } else {
            const res = await flowResult(verifyOtp({ uuid, ...data }));
            if (res && res.ok) {
                // toastify('success', t('messages.verify_otp_success'));
                navigate(`${ROUTES.resetPassword.href}?uuid=${uuid}`);
            } else if (res && !res.ok) {
                showAlertModal({
                    type: 'error',
                    content: res.message,
                    cancelButton: false,
                    showHeader: false,
                    saveButton: t('button.close'),
                    onSave: () => hideModal()
                });
            }
        }
    };

    const onClickResendOtp = async () => {
        setRunTimer(true);
        const res = await flowResult(resendOtp({ uuid }));
        if (res && res.ok) {
            toastify('success', t('messages.resend_otp_succes'));
            navigate(`${ROUTES.verifyOtpSignUp.href}?uuid=${res.data.uuid}`);
        }
    };

    return (
        <div>
            <h3 className='title-1 text-center mt-6'>{t('words_title.verify')}</h3>
            {
                type === VERIFY_OTP_TYPE.Signup ?
                    (
                        <div className='desc-1 mt-4 flex flex-col items-center gap-2 text-center'>
                            <div>{t('sentences.verify_otp_signup_title')}</div>
                            <div>{t('sentences.verify_otp_signup_desc')}</div>
                        </div>
                    ) :
                    (
                        <div className='desc-1 mt-4 flex flex-col items-center gap-2 text-center'>
                            <div>{t('sentences.verify_otp_fpw_title')}</div>
                            <div>{t('sentences.verify_otp_fpw_desc')}</div>
                        </div>
                    )
            }
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className='mt-6'>
                    <div className='flex justify-center'>
                        <FormField
                            control={form.control}
                            name='verifyOtp'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <InputOTP maxLength={6} {...field}>
                                            <InputOTPSlot index={0} />
                                            <InputOTPSlot index={1} />
                                            <InputOTPSlot index={2} />
                                            <InputOTPSlot index={3} />
                                            <InputOTPSlot index={4} />
                                            <InputOTPSlot index={5} />
                                        </InputOTP>
                                    </FormControl>
                                    {/* <FormMessage /> */}
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='flex xsm:flex-row flex-col items-center gap-1 text-[0.8125rem] font-medium leading-6 mt-4'>
                        <span className='text-text-1'>{t('sentences.verify_otp_resend_desc')}</span>
                        <div className='flex gap-1'>
                            <button type='button' className={classNames('text-link-1', runTimmer && 'opacity-50')} disabled={runTimmer} onClick={onClickResendOtp}>{t('sentences.verify_otp_resend_text')}</button>
                            {
                                runTimmer && (
                                    <CountDownClock
                                        duration={DURATION_RESEND_OTP}
                                        callbackWhenZero={() => {
                                            setRunTimer(false);
                                        }}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <Button type='submit' variant='submit' size='submit' className='mt-6' disabled={!form.getFieldState('verifyOtp').isDirty || form.getFieldState('verifyOtp').invalid}>
                        {t('button.verify_otp_code')}
                    </Button>
                    <BackToSigninBtn />
                </form>
            </Form>
        </div>
    );
});

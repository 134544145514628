import { observer } from 'mobx-react-lite';
import HeaderDesktop from './header-component/HeaderDesktop';
import { useStore } from '@/hooks/useStore';
import { ReactElement } from 'react';
import HeaderMobileFormat1 from './header-component/HeaderMobileFormat1';

export interface HeaderProps {
    headerMobileComponent?: ReactElement,
    headerMobileComponentForGeneral?: ReactElement,
    headerMobileComponentForShopOwner?: ReactElement,
    onlyShowMobile?: boolean
}

export default observer(function Header({
    headerMobileComponent = <HeaderMobileFormat1 />,
    headerMobileComponentForGeneral,
    headerMobileComponentForShopOwner,
    onlyShowMobile
}: HeaderProps) {
    // store
    const {
        authStore: { isChannelShopOwner },
        uiStore: { devicesScreen: { mobile, desktop } }
    } = useStore();

    if (onlyShowMobile && desktop) return <></>;

    return (
        <header className='sticky w-full top-0 left-0 z-50 bg-white overflow-hidden px-4 shadow-sm'>
            <div className='container'>
                {
                    isChannelShopOwner && (
                        mobile ?
                            (headerMobileComponentForShopOwner ?? headerMobileComponent) :
                            <HeaderDesktop />
                    )
                }
                {
                    !isChannelShopOwner && (
                        mobile ?
                            (headerMobileComponentForGeneral ?? headerMobileComponent) :
                            <HeaderDesktop />
                    )
                }
            </div>
        </header>
    );
});

import GeneralStore from './GeneralStore';
import ApiStore from './ApiStore';
import AuthStore from './AuthStore';
import ModalStore from './ModalStore';
import UIStore from './UIStore';
import UserStore from './UserStore';
import ShopStore from './ShopStore';
import PetStore from './PetStore';
import FileStore from './FileStore';
import AppointmentStore from './AppointmentStore';
import ReviewStore from './ReviewStore';
import TopicStore from './TopicStore';
import NotificationStore from './NotificationStore';
import ChatStore from './ChatStore';
import PetCareStore from './PetCareStore';

export default class RootStore {
    modalStore: ModalStore;
    apiStore: ApiStore;
    uiStore: UIStore;
    authStore: AuthStore;
    userStore: UserStore;
    generalStore: GeneralStore;
    shopStore: ShopStore;
    petStore: PetStore;
    topicStore: TopicStore;
    fileStore: FileStore;
    appointmentStore: AppointmentStore;
    reviewStore: ReviewStore;
    notificationStore: NotificationStore;
    chatStore: ChatStore;
    petCareStore: PetCareStore;

    constructor() {
        this.modalStore = new ModalStore();
        this.apiStore = new ApiStore(this);
        this.uiStore = new UIStore(this);
        this.authStore = new AuthStore(this);
        this.userStore = new UserStore(this);
        this.generalStore = new GeneralStore(this);
        this.shopStore = new ShopStore(this);
        this.petStore = new PetStore(this);
        this.topicStore = new TopicStore(this);
        this.fileStore = new FileStore(this);
        this.appointmentStore = new AppointmentStore(this);
        this.reviewStore = new ReviewStore(this);
        this.notificationStore = new NotificationStore(this);
        this.chatStore = new ChatStore(this);
        this.petCareStore = new PetCareStore(this);
    }
}

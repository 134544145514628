import { DAYS_OF_WEEK_TYPE, PICK_UP_PRICE_TYPE, PET_TYPE, PICK_UP_SERVICE_STATUS, APPOINTMENT_STATUS_TYPE, MONTH_OF_YEAR_TYPE } from './../types/enums';
import { LANGUAGES_SUPPORTED, LanguagesSupported, REPRESENTATIVE_ROLE_TYPE, SHOP_CATEGORY_TYPE, SHOP_SERVICE_NAME_TYPE, SHOP_SERVICE_TYPE } from '@/types/enums';
import { LanguageInfo } from '@/types/language';
import { Route } from '@/types/routes';
import { TabItemInfo } from '@/types/tabs';
import { PaginationState } from '@tanstack/react-table';
import { enUS, vi } from 'date-fns/locale';

// #region Routes
enum RoutesKey {
    home,
    shopDetail,
    signIn,
    signUp,
    forgotPassword,
    verifyOtpSignUp,
    verifyOtpForgotPassword,
    resetPassword,
    myAccount,
    notification,
    activity,
    forum,
    myAccountEdit,
    myAccountEditPhone,
    changePassword,
    myPet,
    myAppointment,
    myReview,
    createShop,
    petNote,
    nearBy,
    chatForShop,
    search,
    bookAppointment,
    appointmentManagement,
    editGeneralInformation,
    editPickupService,
    editServiceList,
    reviewManagement,
    myShop,
    shopForum
};

export const ROUTES: Record<keyof typeof RoutesKey, Route> = {
    home: {
        href: '/',
        title: 'words_title.home',
        menuLabel: ''
    },
    shopDetail: {
        href: '/shops',
        title: '',
        menuLabel: ''
    },
    signIn: {
        href: '/sign-in',
        title: 'words_title.sign_in',
        menuLabel: ''
    },
    signUp: {
        href: '/sign-up',
        title: 'words_title.sign_up',
        menuLabel: ''
    },
    forgotPassword: {
        href: '/forgot-password',
        title: '',
        menuLabel: ''
    },
    verifyOtpSignUp: {
        href: '/verify-signup',
        title: '',
        menuLabel: ''
    },
    verifyOtpForgotPassword: {
        href: '/verify-forgot-password',
        title: '',
        menuLabel: ''
    },
    resetPassword: {
        href: '/reset-password',
        title: '',
        menuLabel: ''
    },
    myAccount: {
        href: '/my-account',
        title: '',
        menuLabel: ''
    },
    myAccountEdit: {
        href: '/my-account/edit',
        title: '',
        menuLabel: ''
    },
    myAccountEditPhone: {
        href: '/my-account/edit-phone',
        title: '',
        menuLabel: ''
    },
    changePassword: {
        href: '/change-password',
        title: '',
        menuLabel: ''
    },
    myPet: {
        href: '/my-pet',
        title: '',
        menuLabel: ''
    },
    myAppointment: {
        href: '/my-appointment',
        title: '',
        menuLabel: ''
    },
    myReview: {
        href: '/my-review',
        title: '',
        menuLabel: ''
    },
    createShop: {
        href: '/create-shop',
        title: '',
        menuLabel: ''
    },
    notification: {
        href: '/notification',
        title: '',
        menuLabel: ''
    },
    activity: {
        href: '/activity',
        title: '',
        menuLabel: ''
    },
    forum: {
        href: '/forum',
        title: '',
        menuLabel: ''
    },
    petNote: {
        href: '/pet-note',
        title: '',
        menuLabel: ''
    },
    nearBy: {
        href: '/near-by',
        title: '',
        menuLabel: ''
    },
    chatForShop: {
        href: '/shop/chat',
        title: '',
        menuLabel: ''
    },
    search: {
        href: '/search-shop',
        title: '',
        menuLabel: ''
    },
    bookAppointment: {
        href: '/book-appointment',
        title: '',
        menuLabel: ''
    },
    appointmentManagement: {
        href: '/appointment-management',
        title: '',
        menuLabel: ''
    },
    editGeneralInformation: {
        href: '/edit-general-information',
        title: '',
        menuLabel: ''
    },
    editPickupService: {
        href: '/edit-pickup-service',
        title: '',
        menuLabel: ''
    },
    editServiceList: {
        href: '/edit-service-list',
        title: '',
        menuLabel: ''
    },
    reviewManagement: {
        href: '/review-management',
        title: '',
        menuLabel: ''
    },
    myShop: {
        href: '/shop-information',
        title: '',
        menuLabel: ''
    },
    shopForum: {
        href: '/shop-forum',
        title: '',
        menuLabel: ''
    }
};

export const MY_ACCOUNT_HASH_MAPPER_ROUTES = {
    accountInfomation: {
        href: '#account-infomation'
    },
    editAccountInfomation: {
        href: '#edit-account-infomation'
    },
    editPhoneNumber: {
        href: '#edit-phone-number'
    },
    changePassword: {
        href: '#change-password'
    }
};

// #endregion Routes

// #region Languages
export const DEFAULT_LANGUAGE: LanguagesSupported = LANGUAGES_SUPPORTED.English;

export const LANGUAGES_MAPPER: {
    english: LanguageInfo,
    vietnamese: LanguageInfo
} = {
    english: {
        code: LANGUAGES_SUPPORTED.English,
        fullname: 'words_title.english',
        shortName: 'EN',
        flag: 'gb',
        dateFns: enUS
    },
    vietnamese: {
        code: LANGUAGES_SUPPORTED.Vietnamese,
        fullname: 'words_title.vietnamese',
        shortName: 'VI',
        flag: 'vn',
        dateFns: vi
    }
};

// #endredion

// #region Dates

// use for display UI
export const DISPLAY_FNS_DATE_YMD = 'dd/MM/yyyy';
export const DISPLAY_FNS_DATE_YMD_FOR_LABEL_CALENDAR = 'MM/yyyy';
export const DISPLAY_MOMENT_DATE_YMD = 'DD/MM/YYYY';
export const DISPLAY_MOMENT_DATE_DMYHMS = 'DD/MM/YYYY HH:mm:ss';
export const DISPLAY_MOMENT_DATE_DMYHM = 'DD/MM/YYYY HH:mm';
export const DISPLAY_MOMENT_DATE_DMYHM_AM_PM = 'DD/MM/YYYY hh:mm A';
export const DISPLAY_MOMENT_DATE_DMYHMS_AM_PM = 'DD/MM/YYYY hh:mm:ss A';
export const DISPLAY_MOMENT_TIME_HMS = 'HH:mm:ss';
export const DISPLAY_MOMENT_TIME_HM_AM_PM = 'hh:mm A';
export const DISPLAY_MOMENT_TIME_HM_AM_PM_2 = 'hh:mm:A';
export const DISPLAY_MOMENT_TIME_HM = 'HH:mm';
export const DISPLAY_MOMENT_TIME_DMY = 'DD/MM/YYYY';
export const DISPLAY_MOMENT_TIME_MONTHLY = 'MMMM yyyy';
export const DISPLAY_MOMENT_TIME_DM = 'DD/MM';

// use for submit api
export const SUBMIT_MOMENT_DATE_YMD = 'YYYY-MM-DD';
export const SUBMIT_MOMENT_DATE_YM = 'YYYY-MM';

// #endregion

// #region Table

export const DEFAULT_SELECT_PAGE_SIZES: number[] = [10, 20, 50, 100, 200, 500, 1000];

export const DEFAULT_PAGINATION: PaginationState = {
    pageIndex: 0,
    pageSize: 5
};

// #endregion

// #region Regex
export const PHONE_VALIDATE_REGEX = /^0\d{9}$/;
export const PASSWORD_VALIDATE_REGEX = /^(?=.*[A-Z])(?=.*[@#$%^&+=!])[A-Za-z\d@#$%^&+=!]{8,}$/;
export const TIME_FORMAT_24h_STEP_5M = /^(?:[01]\d|2[0-3]):[0-5](0|5)$/;

// #endregion

// #region number
export const MAX_LENGTH_DEFAULT_INPUT = 255;

export const DURATION_RESEND_OTP = 60;

export const FILE_SIZE_IMAGE = 5 * 1024 * 1024; // 5 MB

export const FILE_SIZE_MENU = 50 * 1024 * 1024; // 50 MB

export const MAX_PRICE_SERVICE = 999999999;

export const TIME_STEP_SELECT = 5;

export const MAX_LENGTH_TEXT_DESKTOP = 500;

export const MAX_LENGTH_TEXT_MOBILE = 210;

export const AVERAGE_DURATIONS = Array.from({
    length: (150 - 15) / 15 + 1
}).map((_, index) => 15 * (index + 1));

export const PET_WEIGHT_OPTIONS = Array.from({
    length: 51
}).map((_, index) => index);

export const LIMIT_IMAGES_UPLOAD = 100;

// #endregion

// #region shop owner
export const SHOP_CATEGORY = {
    [SHOP_CATEGORY_TYPE.PetShop]: {
        key: 'category_pet_shop'
    },
    [SHOP_CATEGORY_TYPE.PetHospital]: {
        key: 'category_pet_hospital'
    },
    [SHOP_CATEGORY_TYPE.Mixed]: {
        key: 'category_mixed'
    }
};

export const REPRESENTATIVE_ROLE = {
    [REPRESENTATIVE_ROLE_TYPE.ShopOwner]: {
        key: 'representative_role_shop_owner'
    },
    [REPRESENTATIVE_ROLE_TYPE.Doctor]: {
        key: 'representative_role_doctor'

    },
    [REPRESENTATIVE_ROLE_TYPE.Manager]: {
        key: 'representative_role_manager'
    },
    [REPRESENTATIVE_ROLE_TYPE.Staff]: {
        key: 'representative_role_staff'
    }
};

export const SHOP_SERVICE_NAME = {
    [SHOP_SERVICE_NAME_TYPE.Cut]: {
        key: 'shop_service_cut'
    },
    [SHOP_SERVICE_NAME_TYPE.Shower]: {
        key: 'shop_service_shower'

    },
    [SHOP_SERVICE_NAME_TYPE.Combo]: {
        key: 'shop_service_combo'
    },
    [SHOP_SERVICE_NAME_TYPE.Hotel]: {
        key: 'shop_service_hotel'
    }
};

export const SHOP_SERVICE = {
    [SHOP_SERVICE_TYPE.Cat]: {
        key: 'shop_service_cat'
    },
    [SHOP_SERVICE_TYPE.Dog]: {
        key: 'shop_service_dog'
    },
    [SHOP_SERVICE_TYPE.Medical]: {
        key: 'shop_service_medical'
    },
    [SHOP_SERVICE_TYPE.Other]: {
        key: 'shop_service_other'
    }
};

export const SHOP_SERVICE_2 = {
    [SHOP_SERVICE_TYPE.Cat]: {
        key: 'shop_service_cat_2'
    },
    [SHOP_SERVICE_TYPE.Dog]: {
        key: 'shop_service_dog_2'
    },
    [SHOP_SERVICE_TYPE.Medical]: {
        key: 'shop_service_medical_2'
    },
    [SHOP_SERVICE_TYPE.Other]: {
        key: 'shop_service_other_2'
    }
};

export const SHOP_SERVICE_APPOINTMENT = {
    [SHOP_SERVICE_TYPE.Cat]: {
        key: 'shop_service_cat_appointment'
    },
    [SHOP_SERVICE_TYPE.Dog]: {
        key: 'shop_service_dog_appointment'
    },
    [SHOP_SERVICE_TYPE.Medical]: {
        key: 'shop_service_medical'
    },
    [SHOP_SERVICE_TYPE.Other]: {
        key: 'shop_service_other'
    }
};

export const PICK_UP_PRICE = {
    [PICK_UP_PRICE_TYPE.Free]: {
        key: 'pick_up_free'
    },
    [PICK_UP_PRICE_TYPE.ChargeByDistance]: {
        key: 'pick_up_charge'
    }
};

export const DAYS_OF_WEEK = {
    [DAYS_OF_WEEK_TYPE.Monday]: {
        key: 'days.monday',
        shortCutEn: 'Mon',
        shortCutVi: 'Hai',
        fullNameEn: 'Monday',
        fullNameVi: 'Thứ hai'
    },
    [DAYS_OF_WEEK_TYPE.Tuesday]: {
        key: 'days.tuesday',
        shortCutEn: 'Tue',
        shortCutVi: 'Ba',
        fullNameEn: 'Tuesday',
        fullNameVi: 'Thứ ba'
    },
    [DAYS_OF_WEEK_TYPE.Wednesday]: {
        key: 'days.wednesday',
        shortCutEn: 'Wed',
        shortCutVi: 'Tư',
        fullNameEn: 'Wednesday',
        fullNameVi: 'Thứ tư'
    },
    [DAYS_OF_WEEK_TYPE.Thursday]: {
        key: 'days.thursday',
        shortCutEn: 'Thu',
        shortCutVi: 'Năm',
        fullNameEn: 'Thursday',
        fullNameVi: 'Thứ năm'
    },
    [DAYS_OF_WEEK_TYPE.Friday]: {
        key: 'days.friday',
        shortCutEn: 'Fri',
        shortCutVi: 'Sáu',
        fullNameEn: 'Friday',
        fullNameVi: 'Thứ sáu'
    },
    [DAYS_OF_WEEK_TYPE.Saturday]: {
        key: 'days.saturday',
        shortCutEn: 'Sat',
        shortCutVi: 'Bảy',
        fullNameEn: 'Saturday',
        fullNameVi: 'Thứ bảy'
    },
    [DAYS_OF_WEEK_TYPE.Sunday]: {
        key: 'days.sunday',
        shortCutEn: 'Sun',
        shortCutVi: 'CN',
        fullNameEn: 'Sunday',
        fullNameVi: 'Chủ nhật'
    }
};

export const MONTH_OF_YEAR = {
    [MONTH_OF_YEAR_TYPE.January]: {
        shortCutEn: 'Jan',
        fullNameEn: 'January',
        shortCutVi: 'T1',
        fullNameVi: 'Tháng 1'
    },
    [MONTH_OF_YEAR_TYPE.February]: {
        shortCutEn: 'Feb',
        fullNameEn: 'February',
        shortCutVi: 'T2',
        fullNameVi: 'Tháng 2'
    },
    [MONTH_OF_YEAR_TYPE.March]: {
        shortCutEn: 'Mar',
        fullNameEn: 'March',
        shortCutVi: 'T3',
        fullNameVi: 'Tháng 3'
    },
    [MONTH_OF_YEAR_TYPE.April]: {
        shortCutEn: 'Apr',
        fullNameEn: 'April',
        shortCutVi: 'T4',
        fullNameVi: 'Tháng 4'
    },
    [MONTH_OF_YEAR_TYPE.May]: {
        shortCutEn: 'May',
        fullNameEn: 'May',
        shortCutVi: 'T5',
        fullNameVi: 'Tháng 5'
    },
    [MONTH_OF_YEAR_TYPE.June]: {
        shortCutEn: 'Jun',
        fullNameEn: 'June',
        shortCutVi: 'T6',
        fullNameVi: 'Tháng 6'
    },
    [MONTH_OF_YEAR_TYPE.July]: {
        shortCutEn: 'Jul',
        fullNameEn: 'July',
        shortCutVi: 'T7',
        fullNameVi: 'Tháng 7'
    },
    [MONTH_OF_YEAR_TYPE.August]: {
        shortCutEn: 'Aug',
        fullNameEn: 'August',
        shortCutVi: 'T8',
        fullNameVi: 'Tháng 8'
    },
    [MONTH_OF_YEAR_TYPE.September]: {
        shortCutEn: 'Sep',
        fullNameEn: 'September',
        shortCutVi: 'T9',
        fullNameVi: 'Tháng 9'
    },
    [MONTH_OF_YEAR_TYPE.October]: {
        shortCutEn: 'Oct',
        fullNameEn: 'October',
        shortCutVi: 'T10',
        fullNameVi: 'Tháng 10'
    },
    [MONTH_OF_YEAR_TYPE.November]: {
        shortCutEn: 'Nov',
        fullNameEn: 'November',
        shortCutVi: 'T11',
        fullNameVi: 'Tháng 11'
    },
    [MONTH_OF_YEAR_TYPE.December]: {
        shortCutEn: 'Dec',
        fullNameEn: 'December',
        shortCutVi: 'T12',
        fullNameVi: 'Tháng 12'
    }
};

export const MONTH_OF_YEAR_CHART = {
    [MONTH_OF_YEAR_TYPE.January]: {
        shortCut: '1'
    },
    [MONTH_OF_YEAR_TYPE.February]: {
        shortCut: '2'
    },
    [MONTH_OF_YEAR_TYPE.March]: {
        shortCut: '3'
    },
    [MONTH_OF_YEAR_TYPE.April]: {
        shortCut: '4'
    },
    [MONTH_OF_YEAR_TYPE.May]: {
        shortCut: '5'
    },
    [MONTH_OF_YEAR_TYPE.June]: {
        shortCut: '6'
    },
    [MONTH_OF_YEAR_TYPE.July]: {
        shortCut: '7'
    },
    [MONTH_OF_YEAR_TYPE.August]: {
        shortCut: '8'
    },
    [MONTH_OF_YEAR_TYPE.September]: {
        shortCut: '9'
    },
    [MONTH_OF_YEAR_TYPE.October]: {
        shortCut: '10'
    },
    [MONTH_OF_YEAR_TYPE.November]: {
        shortCut: '11'
    },
    [MONTH_OF_YEAR_TYPE.December]: {
        shortCut: '12'
    }
};

// #endregion

// #region support file type
export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const SUPPORTED_FORMATS_MENU_UPLOAD = ['application/pdf'];

// #endregion

// #region Tabs
export const FORUM_PATH = {
    all: '',
    freeTopic: 'free-topic',
    aboutUs: 'about-us',
    career: 'miapet-career',
    miaPolicy: 'miapet-policy',
    privacyPolicy: 'privacy-policy'
};
export const FORUM_TABS: TabItemInfo[] = [
    {
        key: 'words_title.all',
        path: FORUM_PATH.all
    },
    {
        key: 'words_title.free_topic',
        path: FORUM_PATH.freeTopic
    },
    {
        key: 'footer.about',
        path: FORUM_PATH.aboutUs
    },
    {
        key: 'footer.career',
        path: FORUM_PATH.career
    },
    {
        key: 'footer.policy',
        path: FORUM_PATH.miaPolicy
    },
    {
        key: 'footer.privacy',
        path: FORUM_PATH.privacyPolicy
    }
];

export const ACTIVITY_PATH = {
    appointment: 'appointment',
    favoriteShop: 'favorite-shop',
    chat: 'chat'
};
export const ACTIVITY_TABS: TabItemInfo[] = [
    {
        key: 'words_title.my_appointments',
        path: ACTIVITY_PATH.appointment
    },
    {
        key: 'words_title.my_favorite_shop',
        path: ACTIVITY_PATH.favoriteShop
    },
    {
        key: 'words_title.chat',
        path: ACTIVITY_PATH.chat
    }

];

export const APPOINTMENT_MANAGEMENT_TABS = [
    {
        title: 'ALL',
        key: 'all',
        value: 0
    },
    {
        title: 'Not Confirmed',
        key: 'not_confirmed',
        value: 1
    },
    {
        title: 'Confirmed',
        key: 'confirmed',
        value: 2
    },
    {
        title: 'Complete',
        key: 'completed',
        value: 3
    },
    {
        title: 'Cancelled',
        key: 'cancelled',
        value: 4
    }
];

// #endregion

// #region Shop Category
export const SHOP_CATEGORY_FILTER = {
    petShop: {
        value: 1,
        label: 'Pet Shop'
    },
    petHospital: {
        value: 2,
        label: 'Pet Hospital'

    },
    mixed: {
        value: 3,
        label: 'Pet Hospital + Shop'
    }
};
// #endregion

// #region Filter
export const FILTER_KEY = {
    city: {
        key: 'city'
    },
    category: {
        key: 'category'
    },
    pickup: {
        key: 'pickup'
    },
    rating: {
        key: 'rating'
    }
};

export const FILTER_KEY_LIST = Object.keys(FILTER_KEY).map(key => FILTER_KEY[key].key);

export const PICK_UP_STATUS = {
    [PICK_UP_SERVICE_STATUS.Offer]: {
        key: 'offer'
    },
    [PICK_UP_SERVICE_STATUS.NotOffer]: {
        key: 'not_offer'
    }
};

export const SORT_OPTION = {
    Nearby: {
        value: 'nearby',
        label: 'sort_near_by'
    },
    MostBooked: {
        value: 'appointment',
        label: 'sort_most_booked_shop'
    },
    StarRate: {
        value: 'rating',
        label: 'sort_highest_rate'
    }
};
// #endregion

// #region my pet
export const MY_PET_TYPE = {
    [PET_TYPE.Dog]: {
        key: 'my_pet_dog'
    },
    [PET_TYPE.Cat]: {
        key: 'my_pet_cat'
    },
    [PET_TYPE.Other]: {
        key: 'my_pet_other'
    }
};

// #region Province Code
export const IMPORTANT_CODE = {
    HN: '01',
    HCM: '79'
};
// #endregion

// #region Cookie
export const COOKIE_TOPIC = 'read_topics';
// #endregion

// #region default render null value
export const DEFAULT_NULL_VALUE = '--';
// #endregion

// #region Appointment
export const APPOINTMENT_STATUS = {
    [APPOINTMENT_STATUS_TYPE.NotConfirmed]: {
        key: 'not_confirmed',
        bgColorClassname: 'bg-icon-18',
        iconColorClassname: 'fill-icon-18',
        textColorClassname: 'text-icon-18'
    },
    [APPOINTMENT_STATUS_TYPE.Confirmed]: {
        key: 'confirmed',
        bgColorClassname: 'bg-icon-19',
        iconColorClassname: 'fill-icon-19',
        textColorClassname: 'text-icon-19'
    },
    [APPOINTMENT_STATUS_TYPE.Completed]: {
        key: 'completed',
        bgColorClassname: 'bg-icon-20',
        iconColorClassname: 'fill-icon-20',
        textColorClassname: 'text-icon-20'
    },
    [APPOINTMENT_STATUS_TYPE.Cancelled]: {
        key: 'cancelled',
        bgColorClassname: 'bg-icon-21',
        iconColorClassname: 'fill-icon-21',
        textColorClassname: 'text-icon-21'
    }
};
// #endregion

// #region Review Management
export const SHOP_REVIEW_STATUS = {
    PUBLISHED: 1,
    REQUEST_APPROVAL: 2
};
// #endregion

// #region Pet Care History
export const PET_CARE_HISTORY_TYPE = {
    GROOMING_HISTORY: 1,
    MEDICAL_HISTORY: 2
};
// #endregion

// #region hide scroll top list
export const HIDE_SCROLL_TOP_LST_FOR_SO = [
    ROUTES.home.href,
    ROUTES.reviewManagement.href,
    ...Object.values(FORUM_PATH).map(item => `${ROUTES.shopForum.href}/${item}`)
];

export const HIDE_SCROLL_TOP_LST_FOR_PO = [
    ROUTES.shopDetail.href,
    ROUTES.myReview.href,
    ...Object.values(FORUM_PATH).map(item => `${ROUTES.forum.href}/${item}`)
];

export const HIDE_SCROLL_TOP_SCREEN = [
    ROUTES.chatForShop.href,
    `${ROUTES.activity.href}/${ACTIVITY_PATH.chat}`
];
// #endregion

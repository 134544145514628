import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Label } from '@/components/common/Label';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import CircleAddIcon from '@/assets/images/circleAddIcon.svg';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { useTranslation } from 'react-i18next';
import { DAYS_OF_WEEK, DISPLAY_MOMENT_TIME_HM, DISPLAY_MOMENT_TIME_HMS, PICK_UP_PRICE } from '@/configs/constants';
import { DAYS_OF_WEEK_TYPE, INFO_TYPE, PICK_UP_PRICE_TYPE } from '@/types/enums';
import StepTimeSelect from '@/components/general/StepTimeSelect';
import { Switch } from '@/components/common/Switch';
import { OperationTime } from '@/types/shop';
import { XIcon } from 'lucide-react';
import { useStore } from '@/hooks/useStore';
import { flowResult, toJS } from 'mobx';
import { GetOperationInfoResponse } from '@/types/http-payload/shop';
import { toastify } from '@/utils/toastify';
import { observer } from 'mobx-react-lite';
import { scrollToTop } from '@/utils/utils';
import { formatDateTime } from '@/utils/datetime';

// validate scheme
const validateSchema = yup.object().shape({
    displayStatus: yup.boolean(),
    operatingStatus: yup.boolean(),
    pickUpStatus: yup.boolean(),
    pickUpPriceType: yup.string().when('pickUpStatus', {
        is: true,
        then: _ => yup.string().oneOf(Object.values(PICK_UP_PRICE_TYPE).map(item => item.toString())).required('form_error_validate.required'),
        otherwise: _ => yup.mixed().nullable()
    }),
    generalOpenTime: yup.string().required(),
    generalCloseTime: yup.string().required(),
    operationTimes: yup.array().of(
        yup.object().shape({
            day: yup.number().oneOf(Object.values(DAYS_OF_WEEK_TYPE)).required(),
            openStatus: yup.boolean().required(),
            openTime: yup.string().when('openStatus', {
                is: false,
                then: _ => yup.string().nullable(),
                otherwise: _ => yup.string().required()
            }),
            closeTime: yup.string().when('openStatus', {
                is: false,
                then: _ => yup.string().nullable(),
                otherwise: _ => yup.string().required()
            })
        }).required()
    ).min(0).max(7)
});

type FormData = yup.InferType<typeof validateSchema>;

const DEFAULT_TIME_STEP = '00:00';

export default observer(function OperationInformationTab() {
    // hooks
    const { t } = useTranslation();

    // validate form
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            displayStatus: false,
            operatingStatus: false,
            pickUpStatus: false,
            pickUpPriceType: PICK_UP_PRICE_TYPE.Free.toString(),
            generalOpenTime: '00:00',
            generalCloseTime: '00:00',
            operationTimes: []
        }
    });

    const watchOperationTimes = form.watch('operationTimes');

    const disableOptions = useMemo(() => {
        return watchOperationTimes && watchOperationTimes?.length > 0 ? watchOperationTimes.map(item => Number(item.day)) : [];
    }, [JSON.stringify(watchOperationTimes)]);

    // store
    const {
        authStore: { isChannelShopOwner },
        shopStore: { shopInfo, getShopInfo, updateOperationInfo, setObservable }
    } = useStore();

    // lifecycle
    useEffect(() => {
        if (isChannelShopOwner) {
            fetchData();
        }
    }, [isChannelShopOwner]);

    useEffect(() => {
        if (shopInfo && (shopInfo as GetOperationInfoResponse)) {
            const { displayStatus, operatingStatus, pickUpStatus, pickUpPriceType, generalOpenTime, generalCloseTime, operationTimes } = shopInfo as GetOperationInfoResponse;
            form.reset({
                displayStatus,
                operatingStatus,
                pickUpStatus,
                pickUpPriceType: pickUpStatus ? pickUpPriceType.toString() : PICK_UP_PRICE_TYPE.Free.toString(),
                generalOpenTime: generalOpenTime ? generalOpenTime : DEFAULT_TIME_STEP,
                generalCloseTime: generalCloseTime ? generalCloseTime : DEFAULT_TIME_STEP,
                operationTimes: operationTimes ? operationTimes : []
            });
        }
    }, [shopInfo]);

    // function
    const fetchData = async () => {
        await getShopInfo({ typeInfo: INFO_TYPE.OperationInfo });
    };

    const onSubmit = async (data: FormData) => {
        const { displayStatus, operatingStatus, pickUpStatus, pickUpPriceType, generalOpenTime, generalCloseTime, operationTimes } = data;
        const res = await flowResult(updateOperationInfo({
            displayStatus: displayStatus ?? false,
            operatingStatus: operatingStatus ?? false,
            pickUpStatus: pickUpStatus ?? false,
            pickUpPriceType: pickUpStatus && pickUpPriceType ? Number(pickUpPriceType) : null,
            generalOpenTime: generalOpenTime ?
                formatDateTime(generalOpenTime, DISPLAY_MOMENT_TIME_HM, {
                    onlyTime: true,
                    currentformat: DISPLAY_MOMENT_TIME_HMS
                }) ?? '' :
                '',
            generalCloseTime: generalCloseTime ?
                formatDateTime(generalCloseTime, DISPLAY_MOMENT_TIME_HM, {
                    onlyTime: true,
                    currentformat: DISPLAY_MOMENT_TIME_HMS
                }) ?? '' :
                '',
            operationTimes: operationTimes ?
                operationTimes.map((item) => {
                    return {
                        day: item.day,
                        openStatus: item.openStatus,
                        openTime: item.openStatus && item.openTime ?
                            formatDateTime(item.openTime, DISPLAY_MOMENT_TIME_HM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            }) ?? '' :
                            null,
                        closeTime: item.openStatus && item.closeTime ?
                            formatDateTime(item.closeTime, DISPLAY_MOMENT_TIME_HM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            }) ?? '' :
                            null
                    };
                }) :
                []
        }));
        if (res && res.ok) {
            toastify('alert-success', t('messages.update_success'));
            setObservable('isEditMode', false);
            scrollToTop();
        }
    };

    const handleAddEditByOfDate = () => {
        if (watchOperationTimes && watchOperationTimes.length <= 6) {
            form.setValue('operationTimes', [...form.getValues('operationTimes') || [], {
                day: Object.values(DAYS_OF_WEEK_TYPE).find((item) => {
                    if (disableOptions) {
                        return !disableOptions.includes(item);
                    }
                }) ?? 0,
                openStatus: true,
                openTime: form.getValues('generalOpenTime'),
                closeTime: form.getValues('generalCloseTime')
            }]);
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-4 items-start'>
                    <div className='bg-white mt-4 rounded p-4 w-full flex flex-col gap-4'>
                        <FormField
                            control={form.control}
                            name='displayStatus'
                            render={({ field }) => (
                                <FormItem className='flex justify-between items-center'>
                                    <div>
                                        <Label className='label-1'>{t('words_title.shop_display_status')}</Label>
                                    </div>
                                    <FormControl>
                                        <Switch
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='operatingStatus'
                            render={({ field }) => (
                                <FormItem className='flex justify-between items-center'>
                                    <div>
                                        <Label className='label-1'>{t('words_title.shop_operation_status')}</Label>
                                    </div>
                                    <FormControl>
                                        <Switch
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='pickUpStatus'
                            render={({ field }) => (
                                <FormItem className='flex justify-between items-center'>
                                    <div>
                                        <Label className='label-1'>{t('words_title.shop_pickup_status')}</Label>
                                    </div>
                                    <FormControl>
                                        <Switch
                                            checked={field.value}
                                            onCheckedChange={(value) => {
                                                if (!value) {
                                                    form.setValue('pickUpPriceType', PICK_UP_PRICE_TYPE.Free.toString());
                                                }
                                                field.onChange(value);
                                            }}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        {
                            form.getValues('pickUpStatus') && (
                                <FormField
                                    control={form.control}
                                    name='pickUpPriceType'
                                    render={({ field }) => (
                                        <FormItem className='flex flex-col'>
                                            <Label className='label-1'>{t('words_title.shop_pickup_price')}</Label>
                                            <Select
                                                defaultValue={PICK_UP_PRICE_TYPE.Free.toString()}
                                                key={field.value}
                                                onValueChange={field.onChange}
                                                {...field}
                                            >
                                                <FormControl>
                                                    <SelectTrigger className='mt-2'>
                                                        <SelectValue placeholder='Choose'>
                                                        </SelectValue>
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    {Object.keys(PICK_UP_PRICE).map(item => (
                                                        <SelectItem
                                                            className='hover:cursor-pointer'
                                                            key={PICK_UP_PRICE[item].key}
                                                            value={item}
                                                        >
                                                            <span>{t(`select_options.${PICK_UP_PRICE[item].key}`)}</span>
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )
                        }
                    </div>
                    <div className='bg-white rounded p-4 w-full'>
                        <StepTimeSelect title={t('words_title.open_time')} name='generalOpenTime' control={form.control} />
                        <StepTimeSelect className='mt-4' title={t('words_title.close_time')} name='generalCloseTime' control={form.control} />
                        <div className='flex justify-center mt-4'>
                            <Button type='button' variant='add' onClick={handleAddEditByOfDate} disabled={watchOperationTimes?.length === 7}>
                                <img src={CircleAddIcon} alt='' className='mr-1 h-5 w-5' />
                                <span>{t('words_title.edit_day_of_week')}</span>
                            </Button>
                        </div>
                        {
                            watchOperationTimes && watchOperationTimes.length > 0 && watchOperationTimes.map((item, index) => {
                                return (
                                    <EditByDayOfWeekItem key={index} index={index} form={form} data={item} disableOptions={disableOptions} />
                                );
                            })
                        }
                    </div>
                </div>
                <div className='flex md:flex-row flex-col-reverse justify-center items-center gap-4 px-4 pt-4 md:pb-0 pb-4'>
                    <Button
                        type='button'
                        variant='clear'
                        className='px-4 py-2 md:min-w-44 md:w-fit w-full'
                        onClick={() => {
                            setObservable('isEditMode', false);
                            scrollToTop();
                        }}
                    >
                        {t('button.cancel')}
                    </Button>
                    <Button type='submit' variant='submit' size='submit' disabled={form.formState.isSubmitting} className='px-4 py-2 md:min-w-44 md:w-fit w-full'>
                        {t('button.save')}
                    </Button>
                </div>
            </form>
        </Form>
    );
});

interface EditByDayOfWeekItemProps {
    form: UseFormReturn<FormData>,
    index: number,
    data: OperationTime,
    disableOptions: Number[]
}

const EditByDayOfWeekItem = ({ form, index, data, disableOptions }: EditByDayOfWeekItemProps) => {
    // hooks
    const { t } = useTranslation();

    // function
    const handleRemoveOperationItem = () => {
        const operationTimes = form.getValues('operationTimes') ?? [];
        form.setValue('operationTimes', [
            ...operationTimes.slice(0, index),
            ...operationTimes.slice(index + 1)
        ]);
    };

    return (
        <div>
            <div className='mt-4 relative'>
                <div className='border-t border-solid border-[#E8E8E8]'></div>
                <FormField
                    control={form.control}
                    name={`operationTimes.${index}.day`}
                    render={({ field }) => (
                        <FormItem className='flex flex-col mt-4'>
                            <Label className='label-1'>{t('words_title.select_day_of_week')}</Label>
                            <Select onValueChange={field.onChange} value={field.value.toString()}>
                                <FormControl>
                                    <SelectTrigger className='mt-1.5'>
                                        <SelectValue>
                                        </SelectValue>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {disableOptions && Object.values(DAYS_OF_WEEK_TYPE).map(item => (
                                        <SelectItem
                                            className='hover:cursor-pointer'
                                            key={DAYS_OF_WEEK[item].key}
                                            value={item.toString()}
                                            disabled={disableOptions.includes(Number(item))}
                                        >
                                            <span>{t(DAYS_OF_WEEK[item].key)}</span>
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name={`operationTimes.${index}.openStatus`}
                    render={({ field }) => (
                        <FormItem className='flex justify-between items-center mt-4'>
                            <div>
                                <Label className='label-1'>{t('words_title.on_off_status')}</Label>
                            </div>
                            <FormControl>
                                <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
                {
                    form.getValues(`operationTimes.${index}.openStatus`) && (
                        <>
                            <StepTimeSelect className='mt-4' title={t('words_title.open_time')} name={`operationTimes.${index}.openTime`} control={form.control} />
                            <StepTimeSelect className='mt-4' title={t('words_title.close_time')} name={`operationTimes.${index}.closeTime`} control={form.control} />
                        </>
                    )
                }
                <button
                    type='button'
                    onClick={handleRemoveOperationItem}
                    className='flex justify-center items-center absolute top-3 right-0 cursor-pointer bg-placeholder-1 bg-opacity-80 rounded-full p-1 shadow-lg w-4.5 h-4.5'
                >
                    <XIcon className='w-3.5 h-3.5 text-white' />
                </button>
            </div>
        </div>
    );
};

import { useEffect, useRef } from 'react';

export const useOutsideAlerter = (callback: Function) => {
    const ref = useRef<HTMLDivElement>(null);

    /**
         * Alert if clicked on outside of element
         */
    const handleClickOutside = (event) => {
        if (!ref.current?.contains(event.target)) {
            callback?.();
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    return ref;
};

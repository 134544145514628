import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import classNames from 'classnames';
import { CalendarHeartIcon, LockIcon, LucideIcon, PawPrintIcon, StarIcon, StoreIcon, UserIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export default function SidebarMyAccount() {
    // hooks
    const { t } = useTranslation();

    // store
    const { authStore: { isChannelShopOwner } } = useStore();

    return (
        <div className='flex flex-col w-full'>
            <div className='px-4 pb-4 pt-6 title-3'>{t('words_title.my_account')}</div>
            {
                isChannelShopOwner && (
                    <SideBarItem icon={StoreIcon} title={t('words_title.shop_info')} borderBottom={true} url={ROUTES.myShop.href} />
                )
            }
            <SideBarItem icon={UserIcon} title={t('words_title.account_infomation')} borderBottom={true} url={ROUTES.myAccount.href} />
            {
                !isChannelShopOwner && (
                    <>
                        <SideBarItem icon={PawPrintIcon} title={t('words_title.my_pet')} borderBottom={true} url={ROUTES.myPet.href} />
                        <SideBarItem icon={StarIcon} title={t('words_title.my_review')} borderBottom={true} url={ROUTES.myReview.href} />
                    </>
                )
            }
            <SideBarItem icon={LockIcon} title={t('words_title.change_password')} borderBottom={true} url={ROUTES.changePassword.href} />
        </div>
    );
};

interface SideBarItemProps {
    icon: LucideIcon,
    title: string,
    borderBottom: boolean,
    url: string
}

export const SideBarItem = ({ icon: Icon, title, borderBottom, url }: SideBarItemProps) => {
    // hooks
    const { pathname } = useLocation();

    const isActive = useMemo(() => {
        if (url === ROUTES.home.href && pathname === url) {
            return true;
        } else if (url !== ROUTES.home.href && pathname.includes(url)) {
            return true;
        }
        return false;
    }, [pathname]);

    return (
        <Link
            to={url}
            className={classNames(
                'group p-4 flex gap-3 items-center relative',
                isActive ? 'before:absolute before:top-0 before:left-0 before:w-1 before:h-full before:bg-active font-medium' : 'text-text-4',
                borderBottom && 'border-b border-b-background-2'
            )}
        >
            <Icon className={classNames('w-5 h-5 group-hover:text-text-3', isActive && 'text-text-3')} />
            <div className='group-hover:text-text-3'>{title}</div>
        </Link>
    );
};

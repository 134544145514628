import MapPin from '@/assets/images/mapin.svg';
import PetIcon from '@/assets/images/petIcon.svg';
import Phone from '@/assets/images/phone.svg';
import { Button } from '@/components/common/Button';
import { ACTIVITY_PATH, APPOINTMENT_STATUS, DAYS_OF_WEEK, DISPLAY_MOMENT_DATE_DMYHM_AM_PM, DISPLAY_MOMENT_TIME_DMY, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { APPOINTMENT_ROLE_TYPE, APPOINTMENT_STATUS_TYPE, DAYS_OF_WEEK_TYPE, SHOP_STATUS_TYPE } from '@/types/enums';
import { AppointmentInfo } from '@/types/shop';
import { formatDateTime } from '@/utils/datetime';
import { toastify } from '@/utils/toastify';
import { formatNumber } from '@/utils/utils';
import classNames from 'classnames';
import { Calendar, CreditCard, Tag, TriangleAlert } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ReviewFormMyAppointment from './ReviewFormMyAppointment';
import { LocationIcon, ShopPhoneIcon } from '@/components/icons';

interface AppointmentCardProps {
    appointment: AppointmentInfo
}

export default observer(function AppointmentCard({ appointment }: AppointmentCardProps) {
    const { shop, code, appointmentDate, appointmentTime, createdAt, status, pet, selectedServices, cancelledBy, totalAmount, pickUpService } = appointment;

    const isShopInactive = shop?.displayStatus === false || shop?.operatingStatus === false || shop?.isDeleted === true || shop?.status !== SHOP_STATUS_TYPE.Active;

    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const { appointmentStore: { updateUserAppointmentStatus, getAllUserAppointments },
        modalStore: { hideAllModals, showAlertModal, modalWaringAlertOptions, showModal } } = useStore();

    // function
    const handleChatHistory = () => {
        navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.chat}/${shop?.slug}`);
    };

    const handleClickShop = (e) => {
        navigate(`${ROUTES.shopDetail.href}/${shop?.slug}`);
        e.preventDefault();
    };

    const handleReschedule = () => {
        navigate(
            `${ROUTES.bookAppointment.href}/${shop?.slug}?appointment=${encodeURI(code)}`,
            {
                state: {
                    previousUrl: ROUTES.activity.href
                }
            }
        );
    };

    const handleCancelAppointment = () => {
        showAlertModal(modalWaringAlertOptions({
            content: t('sentences.cancel_appointment'),
            onSave: async () => {
                hideAllModals();
                const res = await flowResult(updateUserAppointmentStatus({ code, status: APPOINTMENT_STATUS_TYPE.Cancelled }));
                if (res) {
                    toastify('alert-success', t('sentences.update_success'));
                    getAllUserAppointments();
                }
            }
        }));
    };

    const handleWriteReview = () => {
        showModal({
            id: 'write-review',
            title: t('words_title.write_review'),
            titleClassName: 'title-3 text-center',
            content: <ReviewFormMyAppointment slug={shop?.slug} />,
            footerClassName: 'p-0'
        });
    };

    const date = new Date(appointmentDate);
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' });

    const type = status ? APPOINTMENT_STATUS[status]?.key : undefined;

    const bgColorStatus = {
        not_confirmed: 'bg-icon-18',
        confirmed: 'bg-icon-19',
        completed: 'bg-icon-20',
        cancelled: 'bg-icon-21'
    };

    return (
        <div className='md:py-4 flex flex-col justify-between pb-4 px-4 md:px-4 border-b-4 border-b-background-3 md:rounded-sm bg-white md:border border-solid !border-gray-100'>
            <Link to={`${ROUTES.activity.href}/${ACTIVITY_PATH.appointment}/${code}`}>
                <div className='time mt-2 md:mt-0 flex items-center justify-between pb-3 border-b border-b-border-1'>
                    <div className='flex flex-col'>
                        <p className='text-small text-tab-1'>{t('words_title.created_date')}</p>
                        <p className='text-nowrap text-tab-1 text-small'>{formatDateTime(createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}</p>
                    </div>
                    <div className='w-32 text-end'>
                        <div className='inline-flex items-center justify-center px-2 py-1 gap-1 rounded-2xl bg-background-4 h-fit'>
                            <span className={`size-2 rounded-full ${type ? bgColorStatus[type] : ''}`}></span>
                            <p className='text-text-14 text-nowrap'>{t(`words_title.${type}`)}</p>
                        </div>
                    </div>
                </div>
                <div className='py-3 flex items-start gap-3 lg:gap-6'>
                    <button
                        onClick={handleClickShop}
                        disabled={isShopInactive}
                        className='size-24 overflow-hidden rounded-sm relative'
                    >
                        <img className='size-24 object-cover hover:scale-105 transition-all duration-150' src={shop?.images[0]} alt='shop-image' />
                        {isShopInactive && (
                            <>
                                <div className='absolute top-0 h-full w-full bg-black opacity-60'>
                                </div>
                                <div className='absolute top-0 flex items-center justify-center h-full w-full flex-col '>
                                    <TriangleAlert className='text-white' />
                                    <p className='text-white text-small'>{t('sentences.temporarily_out_of_service_2')}</p>
                                </div>
                            </>
                        )}
                    </button>
                    <div className='flex-1 flex flex-col gap-2 overflow-hidden min-h-[7.375rem]'>
                        <div>
                            <button
                                onClick={handleClickShop}
                                disabled={isShopInactive}
                                className={classNames(
                                    'text-[1.0625rem] font-medium line-clamp-2 pb-[1px] text-start',
                                    { 'hover:underline': !isShopInactive }
                                )}
                            >
                                {shop?.name}
                            </button>
                        </div>
                        <div className='flex space-x-2 item-center justify-start text-small md:text-[0.875rem] text-tab-1'>
                            <div className='pt-1 size-[0.8125rem]'>
                                <LocationIcon className='size-[0.8125rem] fill-icon-2' />
                            </div>
                            <p className='line-clamp-2'>{shop?.address || '--'}</p>
                        </div>
                        <div className='flex space-x-2 item-center justify-start text-small md:text-[0.875rem] text-tab-1'>
                            <div className='pt-1 size-[0.9375rem]'>
                                <ShopPhoneIcon className='size-[0.9375rem] fill-icon-2' />
                            </div>
                            <p>{shop?.phoneNumber || '--'}</p>
                        </div>
                    </div>
                </div>
                <hr className='border-dashed' />
                <div className='flex h-10 gap-2'>
                    <div className='flex space-x-1 items-center text-small md:text-[0.875rem] leading-[1.125rem] text-icon-2'>
                        <Calendar strokeWidth={1.5} />
                        <span className='text-nowrap text-text-14'>{`${t(`${DAYS_OF_WEEK[DAYS_OF_WEEK_TYPE[dayOfWeek]].key}`)}, ${formatDateTime(appointmentDate, DISPLAY_MOMENT_TIME_DMY)}`}</span>
                    </div>
                    <div className='flex space-x-1 items-center text-small md:text-[0.875rem] leading-[1.125rem]'>
                        <span className='text-nowrap text-text-14'>
                            {formatDateTime(appointmentTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            })}
                        </span>
                    </div>
                </div>
                <div className='mb-2.5 gap-2 flex justify-between text-small md:text-[0.875rem]'>
                    <p className='flex space-x-1 items-center text-icon-2 line-clamp-1'>
                        <img src={PetIcon} alt='' className='text-tab-1 size-4.5' />
                        <span className='truncate text-text-14'>{pet?.name}</span>
                    </p>
                    <div className='h-auto w-[0.0625rem] border-r'></div>
                    <p className='flex space-x-1 items-center text-icon-2'>
                        <Tag className='size-4' strokeWidth={1.5} />
                        <span className='text-nowrap text-text-14'>
                            {`${selectedServices.length + (pickUpService ? 1 : 0)} ${(selectedServices.length + (pickUpService ? 1 : 0)) > 1 ? t('words_title.services_2') : t('words_title.service_2')}`}
                        </span>
                    </p>
                    <div className='h-auto w-[0.0625rem] border-r'></div>
                    <p className='flex space-x-1 items-center text-icon-2 text-nowrap'>
                        <CreditCard className='size-4.5' strokeWidth={1.5} />
                        <span className='text-text-14'>{`${formatNumber(totalAmount)} VND`}</span>
                    </p>
                </div>
                <hr className='border-dashed' />
                {cancelledBy &&
                    (
                        <div className=''>
                            {(cancelledBy === APPOINTMENT_ROLE_TYPE.PetOwner) && (
                                <p className='py-1 text-text-21 md:text-[0.875rem] font-medium col-span-2'>{t('sentences.cancelled_by_you_pet')}</p>
                            )}
                            {(cancelledBy === APPOINTMENT_ROLE_TYPE.ShopOwner) && (
                                <p className='py-1 text-text-21 md:text-[0.875rem] font-medium col-span-2'>{t('sentences.cancelled_by_shop_owner')}</p>
                            )}
                            <hr className='border-dashed' />
                        </div>
                    )}
            </Link>
            <div className='mt-4 grid justify-end grid-cols-2 sm:grid-cols-3 md:grid-cols-2 xl:grid-cols-3 gap-2'>
                {(status === APPOINTMENT_STATUS_TYPE.NotConfirmed) && (
                    <Button onClick={handleCancelAppointment} className='h-7 rounded-sm  col-span-1' variant='clear'>{t('button.cancel_appointment')}</Button>
                )}

                <Button onClick={handleChatHistory} className='h-7 rounded-sm  col-span-1' variant='outline'>{t('button.chat_now')}</Button>

                {(status === APPOINTMENT_STATUS_TYPE.Completed || status === APPOINTMENT_STATUS_TYPE.Cancelled) && (
                    <Button disabled={isShopInactive} onClick={handleWriteReview} className='h-7 rounded-sm  col-span-1' variant='outline'>{t('button.write_review')}</Button>
                )}

                <Button disabled={isShopInactive} onClick={handleReschedule} className='h-7 rounded-sm  col-span-1' variant='outline'>{t('button.reschedule')}</Button>
            </div>
        </div>
    );
});

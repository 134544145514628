import { Button } from '@/components/common/Button';
import { APPOINTMENT_STATUS, DISPLAY_MOMENT_DATE_DMYHM_AM_PM, MY_PET_TYPE, ROUTES, SHOP_SERVICE_NAME } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { APPOINTMENT_ROLE_TYPE, APPOINTMENT_STATUS_TYPE, LANGUAGES_SUPPORTED, SHOP_SERVICE_TYPE } from '@/types/enums';
import { SearchAppointmentsParam } from '@/types/http-payload/shop';
import { AppointmentInfo, ShopServiceAppointment } from '@/types/shop';
import { formatDateTime } from '@/utils/datetime';
import { toastify } from '@/utils/toastify';
import { formatNumber } from '@/utils/utils';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

interface AppointmentItemProps {
    appointment: AppointmentInfo,
    params: SearchAppointmentsParam
}

export default observer(function AppointmentItem({ appointment, params }: AppointmentItemProps) {
    const { code, appointmentDate, appointmentTime, selectedServices, totalAmount, status, createdAt, cancelledBy, pet, petOwner } = appointment;
    const type = APPOINTMENT_STATUS[status]?.key;

    // hooks
    const { t, i18n: { language } } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        uiStore: { windowSize },
        modalStore: { hideAllModals, showAlertModal, modalWaringAlertOptions },
        appointmentStore: { updateAppointmentStatus, getAllAppointments }
    } = useStore();

    const { width } = windowSize;

    // state
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [thirdLineIndex, setThirdLineIndex] = useState<number | null>(null);
    const [services, setServices] = useState<ShopServiceAppointment[]>(selectedServices);

    // lifecycle
    useEffect(() => {
        if (containerRef.current) {
            const spans = containerRef.current.querySelectorAll('span');
            if (spans.length > 0) {
                let initialOffsetTop = spans[0].offsetTop;
                let lineCount = 1;

                for (let i = 1; i < spans.length; i++) {
                    if (spans[i].offsetTop > initialOffsetTop) {
                        lineCount++;
                        initialOffsetTop = spans[i].offsetTop;
                    }

                    if (lineCount === 3) {
                        setThirdLineIndex(i);
                        break;
                    }
                }
            }
        }
    }, [width]);

    // function
    const handleUpdateAppointmentStatus = (e, statusType, title, successMessage) => {
        showAlertModal(modalWaringAlertOptions({
            content: title,
            onSave: async () => {
                hideAllModals();
                const res = await flowResult(updateAppointmentStatus({ code, status: statusType }));
                if (res) {
                    toastify('alert-success', successMessage);
                    getAllAppointments(params);
                }
            }
        }));
        e.preventDefault();
    };

    const handleCancelAppointment = (e) => {
        handleUpdateAppointmentStatus(
            e,
            APPOINTMENT_STATUS_TYPE.Cancelled,
            t('sentences.cancel_appointment'),
            t('sentences.update_success')
        );
    };

    const handleConfirmAppointment = (e) => {
        handleUpdateAppointmentStatus(
            e,
            APPOINTMENT_STATUS_TYPE.Confirmed,
            t('sentences.confirm_appointment'),
            t('sentences.update_success')
        );
    };

    const handleCompleteAppointment = (e) => {
        handleUpdateAppointmentStatus(
            e,
            APPOINTMENT_STATUS_TYPE.Completed,
            t('sentences.confirm_complete_status'),
            t('sentences.update_success')
        );
    };

    const handleChat = (e, code?: string) => {
        e.preventDefault();
        if (code) {
            navigate(`${ROUTES.chatForShop.href}/${code}`);
        }
    };

    const bgColorStatus = {
        not_confirmed: 'bg-icon-18',
        confirmed: 'bg-icon-19',
        completed: 'bg-icon-20',
        cancelled: 'bg-icon-21'
    };

    return (
        <Link to={code} className='pb-2 pt-2 md:pt-2 px-4 md:py-4 flex flex-col gap-2.5 bg-white text-[0.8125rem] md:text-[0.875rem] md:border md:rounded-sm'>
            <div className='flex justify-between items-center'>
                <div className='flex flex-col point-920:flex-row justify-start items-start point-920:items-end'>
                    <p className='font-medium mr-1 text-[0.875rem] md:text-[1.0625rem]'>{code}</p>
                    <div className='h-[1.125rem] w-[0.0625rem] bg-border-8 mr-1 hidden point-920:block'></div>
                    <p className='font-normal mr-1 text-tab-1 text-nowrap'>
                        {formatDateTime(createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}
                    </p>
                </div>
                <div className='flex px-2.5 py-3.5 justify-center items-center h-[1.375rem] bg-background-4 rounded-xl gap-1'>
                    <span className={`rounded-full size-2.5 ${type && bgColorStatus[type]}`}></span>
                    <p className='text-text-14 text-nowrap'>{type && t(`words_title.${type}`)}</p>
                </div>
            </div>
            <hr className='text-border-8' />
            <div className='flex flex-col lg:flex-row lg:justify-between gap-y-2.5 gap-x-10'>
                <div className='flex lg:flex-col lg:w-1/3'>
                    <p className='text-text-4 min-w-32 md:min-w-36 lg:min-w-40 xl:min-w-0'>{t('words_title.appointment_time')}</p>
                    <p className='text-nowrap '>
                        {formatDateTime(`${appointmentDate} ${appointmentTime}`, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}
                    </p>
                </div>
                <div className='flex lg:flex-col lg:w-1/3'>
                    <p className='text-text-4 min-w-32 md:min-w-36 lg:min-w-40 xl:min-w-0'>{t('words_title.pet_name')}</p>
                    <p className='line-clamp-1 '>
                        {`${pet?.name}${appointment?.pet?.species ?
                            ' - ' + (typeof appointment?.pet?.species === 'string' ?
                                appointment.pet.species :
                                (language === LANGUAGES_SUPPORTED.Vietnamese ?
                                    appointment.pet.species?.name :
                                    appointment.pet.species?.nameEn)) :
                            ''}`}

                    </p>
                </div>
                <div className='flex lg:flex-col lg:w-1/3'>
                    <p className='text-text-4 min-w-32 md:min-w-36 lg:min-w-40 xl:min-w-0'>{t('words_title.pet_owner_name')}</p>
                    <p className='line-clamp-1'>{petOwner?.fullName}</p>
                </div>
            </div>
            <hr className='text-border-8 border-dashed' />
            <div className='flex flex-col gap-2.5'>
                <div className='flex items-start'>
                    <p className='text-text-4 min-w-32 md:min-w-36 lg:min-w-40'>{t('words_title.services')}</p>
                    <div>
                        <div ref={containerRef} className='flex flex-wrap gap-1'>
                            {services.slice(0, thirdLineIndex ?? services.length).map((service, index) => (
                                <span
                                    key={index}
                                    className='font-normal rounded-xl bg-background-4 py-1 px-2.5 max-w-[20ch] truncate'
                                >
                                    {(service.type === SHOP_SERVICE_TYPE.Cat || service.type === SHOP_SERVICE_TYPE.Dog) ?
                                        t(`select_options.${SHOP_SERVICE_NAME[service.serviceName]?.key}`) :
                                        service?.serviceName}

                                </span>
                            ))}
                        </div>
                        {thirdLineIndex && (
                            <div className='mt-2'>
                                <span
                                    className='font-normal py-1 px-1.5 max-w-[20ch] truncate text-text-4'
                                >
                                    {`+${services.length - thirdLineIndex} ${t('words_title.others_2')}`}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex items-center'>
                    <p className='text-text-4 min-w-32 md:min-w-36 lg:min-w-40'>{t('words_title.total_amount')}</p>
                    <p className='font-semibold'>{`${formatNumber(totalAmount)} VND`}</p>
                </div>
                {(cancelledBy === APPOINTMENT_ROLE_TYPE.PetOwner) && (
                    <p className='text-text-21 font-medium'>{t('sentences.cancelled_by_pet_owner')}</p>
                )}
                {(cancelledBy === APPOINTMENT_ROLE_TYPE.ShopOwner) && (
                    <p className='text-text-21 font-medium'>{t('sentences.cancelled_by_you')}</p>
                )}
            </div>
            <hr className='text-border-8 border-dashed' />
            <div className='flex gap-y-2.5 gap-x-1.5 justify-end'>
                {
                    (status === APPOINTMENT_STATUS_TYPE.NotConfirmed || status === APPOINTMENT_STATUS_TYPE.Confirmed) && (
                        <Button
                            onClick={handleCancelAppointment}
                            className='text-[0.8125rem] md:text-[0.875rem] w-1/3 h-7 rounded-sm'
                            variant='clear'
                        >
                            {t('button.cancel_appointment_mobile')}
                        </Button>
                    )
                }

                <Button
                    onClick={e => handleChat(e, petOwner.code)}
                    className='text-[0.8125rem] md:text-[0.875rem] w-1/3 h-7 rounded-sm'
                >
                    {t('button.chat_with_pet_owner')}
                </Button>

                {
                    status === APPOINTMENT_STATUS_TYPE.Confirmed && (
                        <Button
                            onClick={handleCompleteAppointment}
                            className='text-[0.8125rem] md:text-[0.875rem] w-1/3 h-7 rounded-sm'
                        >
                            {t('button.complete')}
                        </Button>
                    )
                }

                {
                    status === APPOINTMENT_STATUS_TYPE.NotConfirmed && (
                        <Button
                            onClick={handleConfirmAppointment}
                            className='text-[0.8125rem] md:text-[0.875rem] w-1/3 h-7 rounded-sm'
                        >
                            {t('button.confirm')}
                        </Button>
                    )
                }

            </div>
        </Link>
    );
});

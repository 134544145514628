import Avatar from '@/components/common/Avatar';
import { Button } from '@/components/common/Button';
import { Carousel, CarouselContent, CarouselItem } from '@/components/common/Carousel';
import { Form, FormControl, FormField, FormItem } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import ShopImg from '@/assets/images/defaultShopImg.png';
import { ACTIVITY_PATH, DISPLAY_MOMENT_DATE_DMYHM_AM_PM, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { ChatMessage, ShopInforOfChat, UserInforOfChat } from '@/types/chat';
import { ENDUSER_TYPE, EnduserType, LANGUAGES_SUPPORTED, LOAD_MESSAGE_TYPE } from '@/types/enums';
import { GetMoreMessageRequest, NewMessageRequest } from '@/types/http-payload/chat';
import { loadFilePreview, loadURLPreview } from '@/utils/browsers';
import { formatDateTime } from '@/utils/datetime';
import { checkImageSupportFormat, cn, getFileInfo } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { CalendarClock, ChevronLeft, ImagePlus, SendHorizontal, XIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactTextareaAutosize from 'react-textarea-autosize';
import LazyLoad from 'react-lazy-load';

export default observer(function Messages() {
    // hook
    const { t, i18n: { language } } = useTranslation();
    const { state, pathname } = useLocation();
    const { slug, tabPath } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const defaultMessage = searchParams.get('message');

    // store
    const { uiStore: { devicesScreen: { mobile, desktop }, windowSize: { height } },
        authStore: { channel, isChannelShopOwner }, modalStore: { showAlertModal },
        fileStore: { uploadFile },
        chatStore: { chatMessages, getChatMessages, getMoreMessages, chatDetail, getChatList, sendNewMessage, getPreSignedURL, newMessageByYou, setObservable } } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        text: yup.string().optional(),
        images: yup
            .array()
            .of(yup.mixed<File>().required('form_error_validate.required'))
            .checkFileImagesOfArrayExt('')
            .max(1, 'form_error_validate.chat_image_upload_limit')
            .nullable()
    });

    // state
    type FormData = yup.InferType<typeof validateSchema>;
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        defaultValues: {
            text: defaultMessage ?? '',
            images: null
        }
    });
    const watchImages = form.watch('images');
    const watchText = form.watch('text');

    const messageContainerRef = useRef<HTMLDivElement | null>(null);
    const currentMessageRef = useRef<HTMLDivElement | null>(null);
    const messageInputRef = useRef<HTMLDivElement | null>(null);
    const textAreaInputRef = useRef<HTMLTextAreaElement | null>(null);
    const hasMoreRef = useRef<boolean>(false);
    const isLoadingRef = useRef<boolean>(false);
    const isAtBottom = useRef<boolean>(true);
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

    // const [isAtBottom, setIsAtBottom] = useState<boolean>(true);
    const [inputHeight, setInputHeight] = useState(0);
    const [loadedImagesCount, setLoadedImagesCount] = useState(0);
    const [totalImagesCount, setTotalImagesCount] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [viewportHeight, setViewportHeight] = useState(window.visualViewport?.height || window.innerHeight);
    const [fromTop, setFromTop] = useState<number>(0);

    // life cycle
    useEffect(() => {
        if (slug && ((isChannelShopOwner === true && pathname.includes(ROUTES.chatForShop.href)) || tabPath === ACTIVITY_PATH.chat)) {
            getAllMessage();
        }

        return () => {
            setObservable('chatMessages', [], { isMergeObject: false });
            setObservable('chatDetail', {}, { isMergeObject: false });
            isAtBottom.current = true;
            setLoadedImagesCount(0);
            setTotalImagesCount(0);
            setIsInitialLoad(true);
            form.reset({
                text: '',
                images: []
            });
        };
    }, [slug]);

    useEffect(() => {
        if (Array.isArray(watchImages) && watchImages.length > 0) {
            if (watchImages instanceof Array) {
                watchImages.forEach((image, index) => {
                    if (image instanceof File) {
                        loadFilePreview(image, `preview-image-${index}`);
                    } else {
                        loadURLPreview(image, `preview-image-${index}`);
                    }
                });
            }
        }
    }, [watchImages]);

    useEffect(() => {
        chatMessages && chatMessages.length > 0 && handelSetLoadImageCount(chatMessages);
    }, [chatMessages]);

    useEffect(() => {
        // if (loadedImagesCount === totalImagesCount && totalImagesCount > 0) {

        // }
        scrollToBottom();
    }, [loadedImagesCount, totalImagesCount]);

    useEffect(() => {
        if (Object.keys(chatDetail).length > 0) {
            const handleScrollEvent = () => handleScroll();
            if (messageContainerRef.current) {
                // messageContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
                messageContainerRef.current.addEventListener('scroll', handleScrollEvent);
            }

            return () => {
                if (messageContainerRef.current) {
                    messageContainerRef.current.removeEventListener('scroll', handleScrollEvent);
                }
            };
        }
    }, [chatDetail]);

    useEffect(() => {
        if (!messageInputRef.current) return;

        const handleResize = () => {
            if (messageInputRef.current) {
                setInputHeight(messageInputRef.current.offsetHeight);
            }
        };

        // Sử dụng ResizeObserver để theo dõi sự thay đổi chiều cao
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(messageInputRef.current);

        // Xóa observer khi component unmount
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if (currentMessageRef.current) {
            // Khởi tạo một lần MutationObserver để theo dõi sự thay đổi con
            const mutationObserver = new MutationObserver(() => {
                scrollToBottom(); // Cuộn xuống khi có tin nhắn mới
            });

            // Khởi tạo một lần ResizeObserver để theo dõi sự thay đổi kích thước
            const resizeObserver = new ResizeObserver(() => {
                scrollToBottom(); // Cuộn xuống khi có thay đổi kích thước, ví dụ như khi ảnh tải xong
            });

            // Bắt đầu quan sát các thay đổi về con và kích thước của currentMessageRef
            mutationObserver.observe(currentMessageRef.current, { childList: true, subtree: true });
            resizeObserver.observe(currentMessageRef.current);

            // Ngắt quan sát khi component unmount
            return () => {
                mutationObserver.disconnect();
                resizeObserver.disconnect();
            };
        }
    }, []);

    useEffect(() => {
        const updateHeight = () => {
            setViewportHeight(window.visualViewport?.height || window.innerHeight);
        };

        // Lắng nghe sự kiện resize
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    useEffect(() => {
        if (viewportHeight > inputHeight) {
            setFromTop(viewportHeight - inputHeight);
        }
    }, [inputHeight, viewportHeight]);

    useEffect(() => {
        const handleTouchMove = (event) => {
            // Kiểm tra nếu sự kiện xảy ra trong vùng messageContainer hoặc textarea thì cho phép cuộn
            const textarea = textAreaInputRef.current;
            const messageContainer = messageContainerRef.current;

            const isMessageScrollable = messageContainer && messageContainer.scrollHeight > messageContainer.clientHeight;
            const isTextareaScrollable = textarea && textarea.scrollHeight > textarea.clientHeight;
            if (
                (isMessageScrollable && messageContainer?.contains(event.target)) ||
                (isTextareaScrollable && textarea?.contains(event.target))
            ) {
                event.stopPropagation();
            } else {
                // Nếu sự kiện xảy ra ngoài hai vùng trên thì chặn cuộn toàn trang
                event.preventDefault();
            }
        };

        // Thêm sự kiện touchmove khi bàn phím xuất hiện (textarea focus)
        if (isKeyboardVisible) {
            window.addEventListener('touchmove', handleTouchMove, { passive: false });
        } else {
            window.removeEventListener('touchmove', handleTouchMove);
        }

        // Cleanup khi component unmount
        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [isKeyboardVisible]);

    useEffect(() => {
        if (newMessageByYou.byYou === true) {
            isAtBottom.current = true;
            scrollToBottom();
        }
    }, [newMessageByYou]);

    const handleFocus = () => {
        setIsKeyboardVisible(true);
    };
    const handleBlur = () => setIsKeyboardVisible(false);

    // function
    const getAllMessage = async (onlyChatDetail: boolean = false) => {
        const res = await flowResult(getChatMessages({ code: slug, channel }, true));
        if (res) {
            const { messages, ...otherData } = res;
            if (!onlyChatDetail) {
                isAtBottom.current = true;
                hasMoreRef.current = !(messages.length < 10);
                setObservable('chatMessages', messages, { isMergeObject: false });
            }

            setObservable('chatDetail', { ...otherData, messageLength: messages.length }, { isMergeObject: false });
        }
    };

    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const existingImages = watchImages || [];
            const totalImages = existingImages.length + files.length;
            if (totalImages > 1) {
                showAlertModal({
                    type: 'error',
                    content: t('sentences.maximum_review_image')
                });
                return;
            }

            if (checkImageSupportFormat(files)) {
                form.setValue('images', [...(files instanceof FileList ? Array.from(files) : []), ...existingImages], { shouldValidate: true });
            } else {
                showAlertModal({
                    type: 'error',
                    content: t('form_error_validate.shop_image_upload_format')
                });
            }
            e.target.value = '';
        }
    };

    const handleRemoveImg = (index: number) => {
        if (Array.isArray(watchImages) && watchImages.length > 0) {
            form.setValue('images', [
                ...watchImages.slice(0, index),
                ...watchImages.slice(index + 1)
            ], { shouldValidate: true });
        }
    };

    const handleUploadFile = async (fileUploadList, uploadSuccessCallback) => {
        const uploadPromises = fileUploadList.map((fileUpload: any) => {
            const { bucket, ...payload } = fileUpload.presignedUrl?.fields;
            const data = {
                ...(payload || {}),
                file: fileUpload.file
            };

            return new Promise((resolve, reject) => {
                uploadFile(fileUpload.presignedUrl?.url, data, {
                    onSuccess: () => {
                        resolve(true);
                    },
                    onError: () => {
                        fileUpload.onError();
                        reject(false);
                    }
                }, false);
            });
        });

        try {
            const results = await Promise.all(uploadPromises);

            const allSuccessful = results.every(result => result === true);

            if (allSuccessful) {
                uploadSuccessCallback();
            } else {
                showAlertModal({
                    type: 'error',
                    content: t('messages.api_response_no_message')
                });
            }
        } catch (error) {
            console.error('Error during file upload:', error);
        }
    };

    const handleSendNewMessage = async (payload: NewMessageRequest, keepText: boolean = false, text?: string) => {
        const res = await flowResult(sendNewMessage(payload, handleErrorSendMessage));
        if (res) {
            if (keepText) {
                form.setValue('text', text);
            } else {
                form.setValue('text', '');
            }
            form.setValue('images', []);
        }
    };

    const handleErrorSendMessage = (error: any) => {
        const res = error?.data;
        if (res.data.message) {
            const message = language === LANGUAGES_SUPPORTED.Vietnamese ? res.data.message.vi : res.data.message.en;
            showAlertModal({
                id: 'error-message',
                content: message,
                type: 'error',
                onSave: () => {
                    form.reset({
                        text: '',
                        images: []
                    });
                    getAllMessage(true);
                    getChatList({ channel });
                }
            });
        }
    };

    const handleScroll = () => {
        const messageContainer = messageContainerRef.current;
        if (messageContainer) {
            const isBottom = messageContainer.scrollHeight - messageContainer.clientHeight <= (messageContainer.scrollTop + 100);
            isAtBottom.current = isBottom;
            if (messageContainer.scrollTop === 0 && Object.keys(chatDetail).length > 0 && isLoadingRef.current === false && hasMoreRef.current === true) {
                handleLoadMoreMessage();
            }
        }
    };

    const handleLoadMoreMessage = async () => {
        if (Object.keys(chatDetail).length > 0 && hasMoreRef.current === true) {
            let limit = 10;
            const payload: GetMoreMessageRequest = {
                chatCode: chatDetail.chatCode,
                isLast: LOAD_MESSAGE_TYPE.Top,
                limit,
                channel
            };
            const scrollHeightBefore = messageContainerRef?.current?.scrollHeight || 0;
            isLoadingRef.current = true;
            const res = await flowResult(getMoreMessages(payload));
            if (res) {
                hasMoreRef.current = res.length < limit ? false : true;
                const scrollTopBefore = messageContainerRef.current?.scrollTop || 0;
                if (messageContainerRef.current) {
                    messageContainerRef.current.scrollTop = scrollTopBefore + (messageContainerRef.current.scrollHeight - scrollHeightBefore);
                }
                isLoadingRef.current = false;
            };
        }
    };

    const checkShowTypeMessageArea = useMemo(() => {
        let show: boolean = false;
        if (Object.keys(chatDetail).length > 0) {
            if (channel === ENDUSER_TYPE.PetOwner) {
                let shop = chatDetail.shop;

                show = (shop?.isDeleted === false) && (shop?.availableStatus === true);
            } else if (channel === ENDUSER_TYPE.ShopOwner) {
                let user = chatDetail.user;

                show = (user?.isDeleted === false && user?.availableStatus === true);
            }
        }
        return show;
    }, [chatDetail]);

    const scrollToBottom = () => {
        if (messageContainerRef.current && isAtBottom.current === true) {
            // currentMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight - messageContainerRef.current.clientHeight;
        }
    };

    const handleImageLoad = () => {
        setLoadedImagesCount(prevCount => prevCount + 1);
    };

    const handelSetLoadImageCount = (chatMessages: ChatMessage[]) => {
        if (isInitialLoad === true) {
            const totalImageCount = chatMessages.reduce((total, msg) => total + (msg.images ? msg.images.length : 0), 0);

            setLoadedImagesCount(0);
            setTotalImagesCount(totalImageCount);

            // Nếu không có ảnh, cuộn xuống cuối ngay lập tức
            if (totalImageCount === 0) {
                setTimeout(() => {
                    scrollToBottom();
                }, 100);
            }
            setIsInitialLoad(false);
        } else {
            scrollToBottom();
        }
    };

    const onClickGoBack = ({ defaultNavigateUrl }: { defaultNavigateUrl: string }) => {
        if (state?.previousUrl) {
            navigate(state.previousUrl);
        } else if (defaultNavigateUrl) {
            navigate(defaultNavigateUrl);
        } else {
            navigate(ROUTES.home.href, { state: { previousUrl: pathname } });
        }
    };

    const onSubmit = async (data: FormData) => {
        const { text, images } = data;
        let payload: NewMessageRequest = {
            receiverId: 0,
            chatCode: chatDetail?.chatCode,
            typeChannel: channel
        };
        if (channel === ENDUSER_TYPE.PetOwner && chatDetail.shop) {
            payload.receiverId = chatDetail.shop.id;
        } else if (channel === ENDUSER_TYPE.ShopOwner && chatDetail.user) {
            payload.receiverId = chatDetail.user.id;
        }
        let keepText: boolean = false;
        if (images && images.length > 0) {
            const filesUpload: any[] = [];
            let messageImgNameList: string[] = [];
            let fileExtList = images.map(image => getFileInfo(image)[1]);
            const resUpload = await flowResult(getPreSignedURL({ fileExtList }));
            if (resUpload) {
                resUpload.forEach((item, index) => {
                    filesUpload.push({
                        id: `image_${item.preSignedImgUrl.fields.key}`,
                        fileTye: fileExtList[index],
                        file: images[index],
                        presignedUrl: item.preSignedImgUrl
                    });
                    messageImgNameList.push(item.fileName);
                });
            }

            if (filesUpload.length > 0) {
                await handleUploadFile(filesUpload, () => {
                    payload.images = messageImgNameList;
                    keepText = true;
                });
            }
        }

        if (text && (!images || (images && images.length === 0))) payload.text = text;
        if (payload.text || payload.images) {
            handleSendNewMessage(payload, keepText, text);
        }
    };

    return (
        <div
            className='flex flex-col overscroll-contain'
            style={{ height: mobile ? `${viewportHeight}px !important` : '100%' }}
        >
            <header className={classNames('h-14 min-h-14 max-h-14 bg-white flex justify-between items-center px-4 overscroll-contain', mobile && 'fixed top-0 z-40 w-screen')}>
                <div className='flex items-center gap-4'>
                    {mobile && (
                        <div className='h-6 w-6 flex items-center cursor-pointer' onClick={() => onClickGoBack({ defaultNavigateUrl: isChannelShopOwner ? ROUTES.chatForShop.href : `${ROUTES.activity.href}/${ACTIVITY_PATH.chat}` })}>
                            <ChevronLeft className='text-black h-5' />
                        </div>
                    )}
                    <ChatMessageHeader channel={channel} user={chatDetail?.user} shop={chatDetail.shop} />
                </div>
            </header>
            {/* <div className='bg-border-4 p-[0.0625rem]'></div> */}

            {/** *show all message */}
            <section
                className={cn(
                    'overflow-x-hidden overflow-y-scroll scrollbar scrollbar-width-4 relative bg-opacity-50 bg-border-1 border-r-white border-r-4 overscroll-contain',
                    desktop && 'h-full max-h-full',
                    mobile && 'mt-0'
                )}
                style={{
                    height: mobile ? `calc(${viewportHeight}px - 56px - ${inputHeight}px)` : '100%',
                    position: mobile ? 'fixed' : 'static',
                    top: '56px',
                    width: mobile ? '100vw' : '100%'
                }}
                ref={messageContainerRef}
            >
                {/** all message show here */}
                {
                    chatMessages && chatMessages.length > 0 ?
                        (
                            <div className='flex flex-col gap-4 py-2 px-2' ref={currentMessageRef}>
                                {chatMessages.map((msg: ChatMessage, index) => {
                                    return (
                                        <div key={msg.id}>
                                            <div className={classNames('flex', msg.isSendByYou ? 'justify-end' : 'justify-start')}>
                                                <div className='w-fit max-w-[60%] min-w-[150px] flex flex-col'>
                                                    <div className={classNames(
                                                        'border border-border-5 border-solid rounded-tl-md rounded-tr-md p-2 pb-1',
                                                        msg.isSendByYou ? 'text-white bg-active rounded-bl-md' : 'bg-white text-title-1 border-none rounded-br-md shadow'
                                                    )}
                                                    >

                                                        {msg.images && msg.images.length > 0 && (
                                                            <div className='flex flex-col gap-1'>
                                                                {msg.images.map((image, index) => {
                                                                    return (
                                                                        <LazyLoad key={index}>
                                                                            <ImageWithPlaceholder src={image} onLoad={handleImageLoad} alt='chat' />
                                                                        </LazyLoad>
                                                                    );
                                                                })}
                                                            </div>
                                                        )}
                                                        {msg.text && <p className='whitespace-pre-line'>{msg.text}</p>}
                                                        <p className={classNames('ml-auto text-[0.75rem] mt-1 text-right', msg.isSendByYou ? '' : 'text-title-1')}>{formatDateTime(msg.createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>
                        ) :
                        (
                            Object.keys(chatDetail).length > 0 && checkShowTypeMessageArea && <div className='bg-opacity-50 bg-border-1 text-center text-text-26 h-full flex flex-col-reverse px-2 pb-4'>{t('sentences.no_message_history')}</div>
                        )
                }

            </section>
            {/** send message */}
            <section className={cn('bg-white flex flex-col gap-2.5 px-2 py-3 overscroll-contain', mobile && 'fixed w-screen')} style={{ top: mobile ? `${fromTop}px` : '0px' }} ref={messageInputRef}>
                {
                    Object.keys(chatDetail).length > 0 ?
                        (
                            <>
                                {
                                    checkShowTypeMessageArea ?
                                        (
                                            <div>
                                                <Form {...form}>
                                                    <form className='flex flex-col gap-2' onSubmit={form.handleSubmit(onSubmit)}>
                                                        <div className='flex gap-2 items-center'>
                                                            <div>
                                                                <FormField
                                                                    control={form.control}
                                                                    name='images'
                                                                    render={({ field: { onChange, value, ...rest } }) => (
                                                                        <FormItem className={classNames('h-full flex items-center', watchImages && watchImages.length > 0 && 'hidden')}>
                                                                            <Label
                                                                                htmlFor='upload-image'
                                                                                className='flex justify-center items-center w-8 h-8 rounded-md bg-white md:hover:bg-active cursor-pointer md:hover:text-white group border border-label-1'
                                                                            >
                                                                                <ImagePlus className='text-active md:group-hover:text-white' />
                                                                            </Label>
                                                                            <Input
                                                                                type='file'
                                                                                id='upload-image'
                                                                                onChange={handleImageFileChange}
                                                                                className='hidden'
                                                                                accept='image/png, image/jpeg, image/jpg'
                                                                                {...rest}
                                                                            />
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            </div>
                                                            {channel === ENDUSER_TYPE.PetOwner && chatDetail?.shop && chatDetail.shop.availableStatus && chatDetail.shop.operationStatus && (
                                                                <div
                                                                    onClick={() => chatDetail?.shop && navigate(
                                                                        `${ROUTES.bookAppointment.href}/${chatDetail?.shop.slug}`,
                                                                        { state: { previousUrl: pathname } }
                                                                    )}
                                                                    className='h-8 flex gap-2 items-center border-[0.03125rem] border-label-1 text-label-1 rounded-md w-fit leading-4 px-2 py-1 group cursor-pointer hover:bg-active hover:text-white'
                                                                >
                                                                    <CalendarClock className='text-active group-hover:text-white' />
                                                                    <span className='text-small'>{t('button.make_appointment')}</span>
                                                                </div>
                                                            )}
                                                            {/* <div>{viewportHeight}</div> */}
                                                        </div>
                                                        {((channel === ENDUSER_TYPE.PetOwner && chatDetail?.shop && chatDetail.shop.availableStatus && chatDetail.shop.operationStatus) || (!watchImages || (watchImages && watchImages.length === 0))) &&
                                                            (<div className='bg-border-8 h-[0.0625rem]'></div>)}

                                                        <div className={classNames('rounded-md', watchImages && watchImages.length > 0 ? 'bg-white' : 'bg-bg-white px-2')}>

                                                            {/** input box */}
                                                            <div className='flex-grow flex gap-2 items-end relative'>
                                                                <FormField
                                                                    control={form.control}
                                                                    name='text'
                                                                    render={({ field }) => (
                                                                        <FormItem className={classNames('w-full', watchImages && watchImages.length > 0 ? 'hidden' : '')}>
                                                                            <FormControl>
                                                                                <ReactTextareaAutosize
                                                                                    minRows={1}
                                                                                    maxRows={3}
                                                                                    value={field.value}
                                                                                    onChange={field.onChange}
                                                                                    placeholder={t('placeholder.send_message')}
                                                                                    className={cn(
                                                                                        'py-1 bg-white pr-4 outline-none w-full resize-none text-[0.9375rem] scrollbar scrollbar-width-4'
                                                                                    )}
                                                                                    onFocus={handleFocus}
                                                                                    onBlur={handleBlur}
                                                                                    ref={textAreaInputRef}
                                                                                />
                                                                            </FormControl>
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                                {
                                                                    Array.isArray(watchImages) && watchImages.length > 0 && (
                                                                        <Carousel
                                                                            opts={{
                                                                                align: 'start'
                                                                            }}
                                                                            className='w-full my-2'
                                                                        >
                                                                            <CarouselContent className='m-0'>
                                                                                {
                                                                                    Array.isArray(watchImages) && watchImages.length > 0 &&
                                                                                    Array.from(watchImages).map((_, index) => (
                                                                                        <CarouselItem className='relative basis-1/5 pl-0' key={index}>
                                                                                            <img className='size-[70px] object-contain aspect-square border border-label-1 rounded' id={`preview-image-${index}`} alt='' />
                                                                                            <button
                                                                                                title='remove'
                                                                                                type='button'
                                                                                                onClick={() => handleRemoveImg(index)}
                                                                                                className='absolute top-1 left-1 bg-black bg-opacity-80 rounded-full p-1 shadow-lg'
                                                                                            >
                                                                                                <XIcon className='size-2  text-white' />
                                                                                            </button>
                                                                                        </CarouselItem>
                                                                                    ))
                                                                                }
                                                                            </CarouselContent>
                                                                        </Carousel>
                                                                    )
                                                                }

                                                                <Button variant='submit' disabled={form.formState.isSubmitting || !((Array.isArray(watchImages) && watchImages.length > 0) || watchText)} title='Send Message' type='submit' className='border-none rounded-md w-8 h-8 flex items-center justify-center md:hover:bg-white group'>
                                                                    <SendHorizontal className='text-white md:group-hover:text-active' />
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </Form>

                                            </div>
                                        ) :
                                        <p className='text-center'>{channel === ENDUSER_TYPE.PetOwner ? t('sentences.shop_not_available_chat') : t('sentences.user_not_available_chat')}</p>
                                }
                            </>
                        ) :
                        <p></p>
                }
            </section>

        </div>
    );
});

interface ChatMessageHeaderProps {
    channel: EnduserType,
    shop: null | ShopInforOfChat,
    user: null | UserInforOfChat
}

function ChatMessageHeader({ channel, shop, user }: ChatMessageHeaderProps) {
    // hook
    const { t } = useTranslation();

    if (channel === ENDUSER_TYPE.PetOwner) {
        return (
            <>
                <div className={classNames('relative h-8 w-8 rounded-full flex items-center justify-center')}>
                    {
                        shop && (
                            <a href={`${ROUTES.shopDetail.href}/${shop.slug}`} target='_blank' rel='noreferrer' className='size-full'>
                                <img src={shop?.image || ShopImg} alt='shop avartar' className='rounded-full size-full' />
                            </a>
                        )
                    }
                </div>
                <h3 className='font-semibold text-title-1 text-sm my-0 text-ellipsis line-clamp-2 overflow-hidden flex-1'>
                    {
                        shop &&
                        (
                            !shop.isDeleted && shop.availableStatus ?
                                <a href={`${ROUTES.shopDetail.href}/${shop.slug}`} target='_blank' className='size-full' rel='noreferrer'>{shop?.name}</a> :
                                shop.name
                        )
                    }
                </h3>
            </>
        );
    } else if (channel === ENDUSER_TYPE.ShopOwner) {
        return (
            <>
                <div className={classNames('relative h-8 w-8 rounded-full flex items-center justify-center')}>
                    {
                        user && !user.isDeleted && <Avatar className='text-sm bg-slate-600 text-white' fallback={user?.fullName[0]} />
                    }
                </div>
                <div>
                    <h3 className='font-semibold text-title-1 text-sm my-0 text-ellipsis line-clamp-2'>
                        {
                            user && (user.isDeleted === true ?
                                <span className='italic'>{t('sentences.user_deleted_chat')}</span> :
                                (
                                    user.fullName
                                ))
                        }
                    </h3>
                </div>
            </>
        );
    }

    return <></>;
}

function ImageWithPlaceholder({ src, alt, onLoad }: any) {
    const [loaded, setLoaded] = useState(false);
    return (
        <div>
            {!loaded && (
                <div className='aspect-square max-h-[250px] w-full bg-gray-200 animate-pulse' /> // Placeholder
            )}
            <img
                src={src}
                alt={alt}
                onLoad={() => {
                    setLoaded(true);
                    onLoad && onLoad();
                }}
                className={`object-contain aspect-square max-h-[250px] max-w-[100%] ${loaded ? 'block' : 'hidden'}`}
            />
        </div>
    );
};

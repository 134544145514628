import { Button } from '@/components/common/Button';
import { ROUTES } from '@/configs/constants';
import classNames from 'classnames';
import { LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface MyAccountItemProps {
    className?: string,
    icon: LucideIcon,
    title: string,
    desc?: string | null | undefined,
    iconRight?: LucideIcon,
    buttonRight?: string,
    borderBottom?: boolean,
    urlButtonRight?: string,
    url?: string,
    otherStateUrl?: Object,
    showDropDownOption?: boolean,
    onShowDropDownOption?: Dispatch<SetStateAction<boolean>>,
    onClick?: Function
}

export default observer(function MyAccountItem({
    className,
    icon: Icon,
    title,
    desc,
    iconRight: IconRight, borderBottom, buttonRight, urlButtonRight = '/',
    url, otherStateUrl,
    showDropDownOption, onShowDropDownOption,
    onClick
}: MyAccountItemProps) {
    // hooks
    const navigate = useNavigate();
    const location = useLocation();

    // function
    const onHandleClickItem = () => {
        if (onShowDropDownOption) {
            onShowDropDownOption(!showDropDownOption);
        }
        url && navigate(url, { state: { previousUrl: `${location.pathname}${location.search}${location.hash}`, ...otherStateUrl } });
        onClick?.();
    };

    return (
        <div onClick={onHandleClickItem} className={classNames('p-4 flex gap-3 bg-white', className, borderBottom && 'border-b border-background-2')}>
            <Icon className='w-4.5 h-4.5 flex-none' />
            <div className='flex-grow cursor-pointer break-words whitespace-normal w-full'>
                <div className='text-text-4 font-normal'>{title}</div>
                {
                    desc && (
                        <div className='text-text-3 font-medium w-[90%]'>{desc}</div>
                    )
                }
            </div>
            <div className='flex items-center justify-center'>
                {
                    IconRight && <IconRight className='w-4 h-4' />
                }
                {
                    buttonRight && (
                        <Button className='h-7 rounded-sm p-1.5' variant='outline' onClick={() => navigate(urlButtonRight, { state: { previousUrl: `${ROUTES.myAccount.href}#account-infomation` } })}>
                            <span className='font-medium text-small'>{buttonRight}</span>
                        </Button>
                    )
                }
            </div>
        </div>
    );
});

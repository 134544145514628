import Logo from '@/assets/images/logo.svg';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from 'lucide-react';
import { ROUTES } from '@/configs/constants';

export default function FormPanelLayout() {
    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    // function
    const onClickGoBack = () => {
        if (state?.previousUrl) {
            navigate(state.previousUrl);
        } else {
            navigate(ROUTES.home.href, { state: { previousUrl: pathname } });
        }
    };

    return (
        <div className='xsm:flex xsm:justify-center xsm:items-center min-h-screen'>
            <div className='xsm:border xsm:border-border-4 xsm:border-solid xsm:rounded-lg xsm:w-[480px] w-full px-6 py-8'>
                <div className='logo relative w-full'>
                    {
                        (pathname === ROUTES.signIn.href || pathname === ROUTES.signUp.href) && <ChevronLeftIcon className='w-6 h-6 absolute cursor-pointer' onClick={onClickGoBack} />
                    }
                    <img className='mx-auto h-[62px] w-full object-contain' src={Logo} alt='logo-img' />
                </div>
                <Outlet />
            </div>
        </div>
    );
}

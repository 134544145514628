import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { ACTIVITY_PATH, ACTIVITY_TABS, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NotFoundPage from '../not-found';
import Chat from './chat';
import MyAppointments from './my-appointments';
import MyFavoriteShop from './my-favorite-shop';

export default observer(function Activity() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { tabPath, slug } = useParams();

    // store
    const { uiStore: { devicesScreen: { mobile } } } = useStore();

    // function
    const currentTabValue = useMemo(() => {
        if (!tabPath) return 'appointment';
        const findTab = ACTIVITY_TABS.findIndex(item => item.path === tabPath);

        if (findTab > -1) {
            if ((tabPath !== ACTIVITY_PATH.chat && tabPath !== ACTIVITY_PATH.appointment) && slug) {
                return null;
            }
            return tabPath;
        }
        return null;
    }, [tabPath]);

    const handleNavigate = (path: string) => {
        let redirectPath = ROUTES.activity.href + (path ? '/' + path : '');
        navigate(redirectPath);
    };

    const hiddenTabTrigger = mobile && slug && (tabPath === ACTIVITY_PATH.chat || tabPath === ACTIVITY_PATH.appointment);

    return (
        currentTabValue === null ?
            <NotFoundPage /> :
            (
                <div className={classNames('w-full md:pt-2')}>
                    <h3 className={classNames('title-1 my-auto text-center text-active md:text-2xl md:block hidden')}>{t('words_title.activity')}</h3>
                    <div
                        className={classNames('rounded-[0.25rem] md:mx-auto md:min-h-[calc(100vh-13.75rem)] min-h-full w-full md:mt-2 ', tabPath === ACTIVITY_PATH.chat && 'md:h-[calc(100vh-13.75rem)]')}
                    >
                        <Tabs value={currentTabValue} className='w-full h-full flex flex-col min-h-[inherit]'>
                            <TabsList variant='history' data-orientation='horizontal' className={classNames('flex w-full overflow-x-auto bg-white md:static sticky top-13 left-0 z-20', hiddenTabTrigger && 'hidden')}>
                                {ACTIVITY_TABS.map((tab, index) =>
                                    (<TabsTrigger onClick={() => handleNavigate(tab.path)} key={index} variant='history' className='w-full h-12 pt-3' value={tab.path || 'appointment'}>{t(`${tab.key}`)}</TabsTrigger>))}
                            </TabsList>
                            <>
                                {ACTIVITY_TABS.map((tab, index) => (
                                    <TabsContent key={index} value={tab.path || 'appointment'} className='flex-1 md:h-[90%] overflow-hidden md:pt-0 bg-white md:bg-background-2 h-full'>
                                        <TabContent path={tab.path} />
                                    </TabsContent>
                                ))}
                            </>
                        </Tabs>
                    </div>
                </div>
            )
    );
});

const TabContent = ({ path }: { path: string }) => {
    switch (path) {
        case ACTIVITY_PATH.appointment:
            return <MyAppointments />;
        case ACTIVITY_PATH.favoriteShop:
            return <MyFavoriteShop />;
        case ACTIVITY_PATH.chat:
            return <Chat />;
        default:
            return <>My Appointment</>;
    }
};

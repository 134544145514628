import NotFoundPageImg from '@/assets/images/notFoundPageImg.png';
import { cn } from '@/utils/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NotFoundPageProps {
    className?: string,
    message?: React.ReactNode,
    hideTitle?: Boolean
}

export default function NotFoundPage({
    className,
    message,
    hideTitle
}: NotFoundPageProps) {
    const { t } = useTranslation();

    return (
        <div className={cn('flex flex-col gap-4 items-center pt-8', className)}>
            <img src={NotFoundPageImg} alt='' className='w-[352px] h-auto object-contain' />
            {
                !hideTitle &&
                <div className='text-2xl font-semibold leading-[1.875rem] text-[#CF493E]'>{t('words_title.page_not_found')}</div>
            }
            <div className='md:px-0 px-2 max-w-[405px] leading-[1.0625rem] text-[#646464] font-normal text-center'>{message || t('sentences.page_not_found')}</div>
        </div>
    );
}

import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import GeneralHome from './general-home';
import ShopOwnerChannelPage from './shop-owner-channel';

export default observer(function HomePage() {
    // store
    const { authStore: { isChannelShopOwner } } = useStore();
    return (
        isChannelShopOwner ? <ShopOwnerChannelPage /> : <GeneralHome />
    );
});

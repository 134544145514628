import Chat from '@/assets/images/chat.svg';
import { Button } from '@/components/common/Button';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { ACTIVITY_PATH, APPOINTMENT_STATUS, DAYS_OF_WEEK, DISPLAY_MOMENT_DATE_DMYHM_AM_PM, DISPLAY_MOMENT_TIME_DMY, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, MAX_LENGTH_TEXT_DESKTOP, MAX_LENGTH_TEXT_MOBILE, ROUTES, SHOP_SERVICE_NAME } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import NotFoundPage from '@/pages/not-found';
import { APPOINTMENT_ROLE_TYPE, APPOINTMENT_STATUS_TYPE, DAYS_OF_WEEK_TYPE, GENDER, LANGUAGES_SUPPORTED, PET_TYPE, PICK_UP_PRICE_TYPE, SHOP_SERVICE_TYPE, SHOP_STATUS_TYPE } from '@/types/enums';
import { ShopServiceAppointment } from '@/types/shop';
import { calculateAge, formatAge, formatDateTime } from '@/utils/datetime';
import { toastify } from '@/utils/toastify';
import { convertPetWeight, convertServicePrice, formatNumber, shortenParagraph } from '@/utils/utils';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { ChevronDown, ChevronUp, TriangleAlert } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ReviewFormMyAppointment from '../components/ReviewFormMyAppointment';
import { LocationIcon, ShopPhoneIcon, ClockIcon } from '@/components/icons';

export default observer(function AppointmentDetail() {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const { slug } = useParams();
    const navigate = useNavigate();

    // store
    const {
        uiStore: { devicesScreen },
        appointmentStore: { getUserAppointmentDetail, updateUserAppointmentStatus, userAppointment },
        petStore: { getAllPet },
        modalStore: { hideAllModals, showAlertModal, modalWaringAlertOptions, showModal }
    } = useStore();

    const { mobile } = devicesScreen;

    const { shop, note, appointmentDate, appointmentTime, contact, status, cancelledBy, pet, selectedServices, totalAmount, createdAt, pickUpService, pickUpPriceType, pickUpPrice, pickUpAddress, pickUpTime, pickUpNote } = userAppointment || {};

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const [isShow, setIsShow] = useState(false);

    const isShopInactive = shop?.displayStatus === false || shop?.operatingStatus === false || shop?.isDeleted === true || shop?.status !== SHOP_STATUS_TYPE.Active;

    // lifecycle
    useEffect(() => {
        if (slug) {
            getUserAppointmentDetail(slug);
        }
    }, []);

    // functions
    const medicalServices = useMemo<ShopServiceAppointment[]>(() => {
        if (selectedServices && selectedServices.length > 0) {
            return selectedServices
                .filter(service => service.type === 3 || service.isMedicalService)
                .sort((a, b) => a.type - b.type);
        }
        return [];
    }, [selectedServices]);

    const groomingServices = useMemo<ShopServiceAppointment[]>(() => {
        if (selectedServices && selectedServices.length > 0) {
            return selectedServices
                .filter(service => service.type !== 3 && !service.isMedicalService)
                .sort((a, b) => a.type - b.type);
        }
        return [];
    }, [selectedServices]);

    const handleChatHistory = () => {
        navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.chat}/${shop?.slug}`);
    };

    const handleClickShop = () => {
        navigate(`${ROUTES.shopDetail.href}/${shop?.slug}`);
    };

    const handleReschedule = async () => {
        if (slug) {
            const res = await flowResult(getAllPet());
            if (res) {
                if (res.length > 0) {
                    navigate(
                        `${ROUTES.bookAppointment.href}/${shop?.slug}?appointment=${encodeURI(slug)}`,
                        {
                            state: {
                                previousUrl: `${ROUTES.activity.href}/${ACTIVITY_PATH.appointment}/${slug}`
                            }
                        }
                    );
                } else {
                    showAlertModal({
                        id: 'pet-modal',
                        type: 'error',
                        content: t('sentences.not_any_pet')
                    });
                }
            }
        }
    };

    const handleCancelAppointment = () => {
        showAlertModal(modalWaringAlertOptions({
            content: t('sentences.cancel_appointment'),
            onSave: async () => {
                hideAllModals();
                if (slug) {
                    const res = await flowResult(updateUserAppointmentStatus({ code: slug, status: APPOINTMENT_STATUS_TYPE.Cancelled }));
                    if (res) {
                        toastify('alert-success', t('sentences.update_success'));
                        getUserAppointmentDetail(slug);
                    }
                }
            }
        }));
    };

    const handleWriteReview = () => {
        showModal({
            id: 'write-review',
            title: t('words_title.write_review'),
            titleClassName: 'title-3 text-center',
            content: <ReviewFormMyAppointment slug={shop?.slug} />,
            footerClassName: 'p-0'
        });
    };

    const date = appointmentDate ? new Date(appointmentDate) : null;
    const dayOfWeek = date ? date.toLocaleString('en-US', { weekday: 'long' }) : '--';

    const type = status ? APPOINTMENT_STATUS[status]?.key : undefined;

    const bgColorStatus = {
        not_confirmed: 'bg-icon-18',
        confirmed: 'bg-icon-19',
        completed: 'bg-icon-20',
        cancelled: 'bg-icon-21'
    };

    return (
        <>
            <Header
                onlyShowMobile
                headerMobileComponent={(
                    <HeaderMobileFormat3
                        className='fixed top-0 left-0 right-0 z-50 border-b px-4'
                        title={t('words_title.appointment_details')}
                        defaultNavigateUrl={ROUTES.activity.href}
                        rightContent={
                            (
                                <button
                                    title={t('button.chat_with_shop')}
                                    onClick={handleChatHistory}
                                >
                                    <div className='bg-active rounded-full size-8 flex items-center justify-center'>
                                        <img src={Chat} alt='' />
                                    </div>
                                </button>
                            )
                        }
                    />
                )}
            />
            {userAppointment ?
                (
                    <>
                        <div className='bg-background-2 pb-1 mt-13 md:mt-0 md:py-2 grid grid-cols-1 md:grid-cols-2 md:gap-2'>
                            <div className='px-0 md:p-4 bg-white rounded-sm'>
                                <div className='xl:flex gap-4'>
                                    <div className='px-4 py-3 border-b border-b-background-2 xl:w-1/2'>
                                        <p className='text-text-4'>{t('words_title.appointment_time')}</p>
                                        <p className='text-text-3'>
                                            {`${t(`${DAYS_OF_WEEK[DAYS_OF_WEEK_TYPE[dayOfWeek]].key}`)},
                                             ${formatDateTime(appointmentDate, DISPLAY_MOMENT_TIME_DMY)} 
                                             ${formatDateTime(appointmentTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                        onlyTime: true,
                        currentformat: DISPLAY_MOMENT_TIME_HMS
                    })}`}
                                        </p>
                                    </div>
                                    <div className='px-4 py-3 border-b border-b-background-2 xl:w-1/2'>
                                        <p className=' text-text-4'>{t('words_title.created_date')}</p>
                                        <p className=' text-text-3'>{formatDateTime(createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}</p>
                                    </div>
                                </div>
                                <div className='px-4 py-3 space-y-1'>
                                    <p className=' text-text-4'>{t('words_title.appointment_status')}</p>
                                    <div className='inline-flex items-center justify-center px-2 py-0.5 gap-1 rounded-xl bg-background-4 h-fit'>
                                        <span className={`size-2 rounded-full ${type ? bgColorStatus[type] : ''}`}></span>
                                        <p className='text-text-14 text-nowrap'>{t(`words_title.${type}`)}</p>
                                    </div>
                                    {cancelledBy &&
                                        (
                                            <div className='mt-2'>
                                                {(cancelledBy === APPOINTMENT_ROLE_TYPE.PetOwner) && (
                                                    <p className='text-text-21 md:text-[0.875rem] font-medium col-span-2'>{t('sentences.cancelled_by_you_pet')}</p>
                                                )}
                                                {(cancelledBy === APPOINTMENT_ROLE_TYPE.ShopOwner) && (
                                                    <p className='text-text-21 md:text-[0.875rem] font-medium col-span-2'>{t('sentences.cancelled_by_shop_owner')}</p>
                                                )}
                                            </div>
                                        ) }
                                </div>
                                <div className='h-0.5 border-b border-b-background-2'></div>
                                <div className='p-4 flex space-x-4'>
                                    <div>
                                        <button
                                            onClick={handleClickShop}
                                            disabled={isShopInactive}
                                            className='size-24 overflow-hidden rounded-sm relative'
                                        >
                                            <img
                                                className={classNames(
                                                    'size-24 object-cover',
                                                    { 'hover:scale-105 transition-all duration-150': !isShopInactive }
                                                )}
                                                src={shop?.images[0]}
                                                alt='shop-image'
                                            />
                                            {isShopInactive && (
                                                <>
                                                    <div className='absolute top-0 h-full w-full bg-black opacity-60'>
                                                    </div>
                                                    <div className='absolute top-0 flex items-center justify-center h-full w-full flex-col '>
                                                        <TriangleAlert className='text-white' />
                                                        <p className='text-white text-small'>{t('sentences.temporarily_out_of_service_2')}</p>
                                                    </div>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                    <div className='flex-1 overflow-hidden'>
                                        <button
                                            onClick={handleClickShop}
                                            disabled={isShopInactive}
                                            className={classNames(
                                                'text-text-3 font-semibold mb-1 line-clamp-2 text-start',
                                                { 'hover:underline': !isShopInactive }
                                            )}
                                        >
                                            {shop?.name}
                                        </button>
                                        <div className='text-tab-1'>
                                            <div className='mt-1 flex space-x-2 items-start justify-start'>
                                                <div className='pt-1 size-[0.8125rem]'>
                                                    <LocationIcon className='size-[0.8125rem] fill-icon-2' />
                                                </div>
                                                <p className='text-[0.8125rem]'>{shop?.address || '--'}</p>
                                            </div>
                                            <div className='mt-1 flex space-x-2 items-start justify-start'>
                                                <div className='pt-1 size-[0.9375rem]'>
                                                    <ShopPhoneIcon className='size-[0.9375rem] fill-icon-2' />
                                                </div>
                                                <p className='text-[0.8125rem]'>{shop?.phoneNumber || '--'}</p>
                                            </div>
                                            <div className='mt-1 flex space-x-2 items-start justify-start'>
                                                <div className='pt-1 size-[0.8125rem]'>
                                                    <ClockIcon className='size-[0.8125rem] fill-icon-2' />
                                                </div>
                                                <p className='text-[0.8125rem]'>
                                                    {`${formatDateTime(shop?.generalOpenTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                        onlyTime: true,
                                                        currentformat: DISPLAY_MOMENT_TIME_HMS
                                                    })} - ${formatDateTime(shop?.generalCloseTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                        onlyTime: true,
                                                        currentformat: DISPLAY_MOMENT_TIME_HMS
                                                    })}`}
                                                </p>
                                            </div>
                                            <Button
                                                onClick={handleChatHistory}
                                                variant='default'
                                                className='mt-1 md:mt-2.5 rounded-sm px-4 py-1 h-fit hidden md:block'
                                            >
                                                {t('button.chat_with_shop')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='block md:hidden h-1 border-b border-b-background-2'></div>
                            <div className='px-0 pt-4 md:p-4 bg-white rounded-sm'>
                                <div className='px-4 flex space-x-4 overflow-hidden'>
                                    <img className='size-16 rounded-sm' src={pet?.image} alt='pet-image' />
                                    <div className=''>
                                        <h3 className='text-text-3 font-semibold mb-1'>{pet?.name || '--'}</h3>
                                        <div className='text-tab-1'>
                                            <span className='text-[0.8125rem]'>{pet?.typeAnimal === PET_TYPE.Dog ? t('select_options.my_pet_dog') : (pet?.typeAnimal === PET_TYPE.Cat ? t('select_options.my_pet_cat') : t('select_options.my_pet_other'))}</span>
                                            {pet?.species && (
                                                <>
                                                    <span className='text-[0.8125rem] text-border-4 mx-2 lg:mx-1 h-[1.5625rem]'>|</span>
                                                    <span className='text-[0.8125rem]'>{typeof pet?.species === 'string' ? pet?.species : (language === LANGUAGES_SUPPORTED.Vietnamese ? pet?.species?.name : pet?.species?.nameEn)}</span>
                                                </>
                                            )}
                                        </div>
                                        <div className='text-tab-1'>
                                            {pet?.gender && (
                                                <>
                                                    <span className='text-[0.8125rem]'>{pet?.gender === GENDER.Male ? t('words_title.male') : t('words_title.female')}</span>
                                                    <span className=' text-border-4 mx-2 lg:mx-1 h-[1.5625rem]'>|</span>
                                                </>
                                            )}
                                            <span className='text-[0.8125rem]'>
                                                {formatAge(pet?.birthday ?? '', t)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='h-0.5 border-b border-b-background-2 my-2 :my-4'></div>
                                <div className='p-4'>
                                    <span className='text-text-4'>{t('words_title.note')}</span>
                                    <p className='whitespace-pre-wrap'>
                                        {shortenParagraph(note || '--', mobile ? MAX_LENGTH_TEXT_MOBILE : MAX_LENGTH_TEXT_DESKTOP, isExpanded).value}
                                        {
                                            shortenParagraph(note || '--', mobile ? MAX_LENGTH_TEXT_MOBILE : MAX_LENGTH_TEXT_DESKTOP, isExpanded).isEllipsis && (
                                                <button
                                                    onClick={toggleExpand}
                                                    className='text-active text-[0.8125rem] text-blue-500 no-underline'
                                                >
                                                    {isExpanded ? t('button.view_less') : t('button.view_more')}
                                                </button>
                                            )
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-background-2'>
                            <div className='px-0 md:px-4 md:pb-6 rounded-sm bg-white'>
                                <div className='flex justify-between px-4 py-4 border-b'>
                                    <p className='text-active font-semibold text-[1rem]'>{t('words_title.services_list')}</p>
                                    <p className='text-tab-1'>
                                        (
                                        {t('words_title.currency')}
                                        : VND)
                                    </p>
                                </div>
                                {pickUpService && (
                                    <div>
                                        <div className='px-4 pt-4'>
                                            <div className='flex justify-between items-center'>
                                                <p className='text-[0.9375rem] font-semibold'>{t('words_title.pick_up_service')}</p>
                                                <p className='text-label-4'>{pickUpPriceType === PICK_UP_PRICE_TYPE.Free ? t('words_title.free') : (pickUpPrice ? formatNumber(pickUpPrice) : '--')}</p>
                                            </div>
                                            <Button
                                                variant='link'
                                                className=' flex items-center'
                                                onClick={() => setIsShow(!isShow)}
                                            >
                                                {t('button.view_pick_up_info')}
                                                {
                                                    isShow ? <ChevronUp className='text-link-1 size-5' strokeWidth={1.5} /> : <ChevronDown className='text-link-1 size-5' strokeWidth={1.5} />
                                                }
                                            </Button>
                                        </div>
                                        <div className={`px-4 md:mb-3 border-t md:border border-background-2 rounded-sm ${isShow ? 'block' : 'hidden'}`}>
                                            <div className='py-3 md:flex border-b md:border-none border-background-2'>
                                                <p className='text-text-4 md:w-1/5'>{t('words_title.pick_up_time')}</p>
                                                <p className='md:w-4/5'>
                                                    {pickUpTime ?
                                                        formatDateTime(pickUpTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                            onlyTime: true,
                                                            currentformat: DISPLAY_MOMENT_TIME_HMS
                                                        }) :
                                                        '--'}
                                                </p>
                                            </div>
                                            <div className='py-3 md:flex border-b md:border-none border-background-2'>
                                                <p className='text-text-4 md:w-1/5'>{t('words_title.pick_up_address')}</p>
                                                <p className='md:w-4/5 whitespace-pre-wrap'>{pickUpAddress || '--'}</p>
                                            </div>
                                            <div className='py-3 md:flex'>
                                                <p className='text-text-4 md:w-1/5'>{t('words_title.contact')}</p>
                                                <p className='md:w-4/5'>{contact || '--'}</p>
                                            </div>
                                        </div>
                                        <hr className='text-border-8' />
                                    </div>
                                )}
                                <div className='mt-4'>
                                    {groomingServices && groomingServices.length > 0 && (
                                        <div className='px-4'>
                                            <h5 className='font-semibold text-[0.9375rem] pb-4'>{t('words_title.grooming_services')}</h5>
                                            <div className='flex flex-col gap-3'>
                                                {convertServiceList(groomingServices, t).map((service, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={classNames(
                                                                'pb-3 border-b border-dashed border-b-border-8 flex justify-between gap-2',
                                                                { 'border-none': index === groomingServices.length - 1 }
                                                            )}
                                                        >
                                                            <p className='text-tab-1 w-[65%]'>{service.serviceName}</p>
                                                            <p className='text-tab-1 text-right'>
                                                                {service.servicePrice}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                    {medicalServices && medicalServices.length > 0 && groomingServices && groomingServices.length > 0 && (<hr className='text-border-8 mb-2' />)}
                                    {medicalServices && medicalServices.length > 0 && (
                                        <div className='px-4 mt-4'>
                                            <h5 className='font-semibold text-[0.9375rem] pb-4'>{t('words_title.medical_services')}</h5>
                                            <div className='flex flex-col gap-3'>
                                                {convertServiceList(medicalServices, t).map((service, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={classNames(
                                                                'pb-3 border-b border-dashed border-b-border-8 flex justify-between gap-2',
                                                                { 'border-none': index === medicalServices.length - 1 }
                                                            )}
                                                        >
                                                            <p className='text-tab-1 w-[65%]'>{service.serviceName}</p>
                                                            <p className='text-tab-1 text-right'>
                                                                {service.servicePrice}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='h-1 md:h-4 bg-background-2'></div>
                        <div className='bg-white p-4'>
                            <div className='flex justify-between md:justify-end md:gap-52 items-center md:rounded-sm'>
                                <p className='font-semibold'>{t('words_title.expected_total_amount')}</p>
                                <p className='font-semibold text-[1.3125rem]'>{`${formatNumber(totalAmount ?? 0)} VND`}</p>
                            </div>
                            <div className='flex gap-2 rounded-sm bg-border-6 p-2 mt-4'>
                                <div className='size-5'>
                                    <TriangleAlert />
                                </div>
                                <span className='text-small'>
                                    {t('sentences.expected_total_amount_note')}
                                </span>
                            </div>
                        </div>
                        <div className={classNames(
                            'h-1 md:h-4 md:bg-background-2 mb-32 md:mb-0'
                        )}
                        >
                        </div>
                        <div>

                            {
                                (mobile) ?
                                    (
                                        <div className='bg-white flex gap-4 p-4 fixed bottom-0 w-screen'>
                                            {(status === APPOINTMENT_STATUS_TYPE.NotConfirmed) && (
                                                <Button
                                                    className='w-full'
                                                    onClick={handleCancelAppointment}
                                                    variant='outline'
                                                >
                                                    {t('button.cancel_appointment')}
                                                </Button>
                                            )}

                                            {(status === APPOINTMENT_STATUS_TYPE.Completed || status === APPOINTMENT_STATUS_TYPE.Cancelled) && (
                                                <Button
                                                    disabled={isShopInactive}
                                                    className='w-full'
                                                    onClick={handleWriteReview}
                                                    variant='default'
                                                >
                                                    {t('button.write_review')}
                                                </Button>
                                            )}

                                            <Button
                                                disabled={isShopInactive}
                                                className='w-full'
                                                onClick={handleReschedule}
                                                variant='default'
                                            >
                                                {t('button.reschedule')}
                                            </Button>
                                        </div>
                                    ) :
                                    (
                                        <div className='bg-background-2 flex justify-between'>
                                            <Button
                                                variant='clear'
                                                className='min-w-44 px-3'
                                                onClick={() => navigate(ROUTES.activity.href)}
                                            >
                                                {t('button.back')}
                                            </Button>
                                            <div className='flex gap-4'>
                                                {(status === APPOINTMENT_STATUS_TYPE.NotConfirmed) && (
                                                    <div className='w-48'>
                                                        <Button
                                                            className='w-full'
                                                            onClick={handleCancelAppointment}
                                                            variant='outline'
                                                        >
                                                            {t('button.cancel_appointment')}
                                                        </Button>
                                                    </div>
                                                )}

                                                {(status === APPOINTMENT_STATUS_TYPE.Completed || status === APPOINTMENT_STATUS_TYPE.Cancelled) && (
                                                    <div className='w-48'>
                                                        <Button
                                                            disabled={isShopInactive}
                                                            className='w-full'
                                                            onClick={handleWriteReview}
                                                            variant='default'
                                                        >
                                                            {t('button.write_review')}
                                                        </Button>
                                                    </div>
                                                )}

                                                <div className='w-48'>
                                                    <Button
                                                        disabled={isShopInactive}
                                                        className='w-full'
                                                        onClick={handleReschedule}
                                                        variant='default'
                                                    >
                                                        {t('button.reschedule')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </>
                ) :
                (
                    <NotFoundPage />
                )}
        </>

    );
});

const convertServiceList = (serviceList: ShopServiceAppointment[], t: TFunction) => {
    let result: any = [];
    if (serviceList.length > 0) {
        result = serviceList.map((service) => {
            let serviceName = '';
            let servicePrice: number | string = convertServicePrice(service.price, t, false);
            if (service.type === SHOP_SERVICE_TYPE.Dog || service.type === SHOP_SERVICE_TYPE.Cat) {
                serviceName = t(`select_options.${SHOP_SERVICE_NAME[service.serviceName].key}`);
                serviceName += ` / ${convertPetWeight(service.petWeightFrom, service.petWeightTo)}`;
            } else if (service.type === SHOP_SERVICE_TYPE.Additional) {
                serviceName = `(${t('words_title.additional')}) ` + service.serviceName;
            } else {
                serviceName = service.serviceName;
            }
            return {
                serviceName,
                servicePrice
            };
        });
    }
    return result;
};

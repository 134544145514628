import CalendarDatetime from '@/components/calendar/CalendarDatetime';
import { Button } from '@/components/common/Button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { Textarea } from '@/components/common/Textarea';
import { MAX_LENGTH_DEFAULT_INPUT, MY_PET_TYPE, SUBMIT_MOMENT_DATE_YM } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { LANGUAGES_SUPPORTED, PET_TYPE } from '@/types/enums';
import { loadFilePreview, loadURLPreview } from '@/utils/browsers';
import { formatDateTime } from '@/utils/datetime';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImagePlusIcon, XIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toastify } from '@/utils/toastify';
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const FILE_SIZE = 5 * 1024 * 1024; // 5 MB
type Props = {
    code?: string
};
export default observer(function MyPetForm({ code }: Props) {
    // hook
    const { t, i18n: { language } } = useTranslation();

    // store
    const {
        petStore: { petSpecies, editPet, addPet, getAllPet, getAllSpecies, getDetailPet, petDetail, setObservable },
        modalStore: { hideModal, showAlertModal }
    } = useStore();

    // validate Schema
    const validateSchema = yup.object().shape({
        name: yup
            .string()
            .required('form_error_validate.required')
            .max(MAX_LENGTH_DEFAULT_INPUT, 'form_error_validate.name_maxlength'),
        gender: yup
            .string()
            .nullable(),
        birthday: yup
            .string()
            .required('form_error_validate.required'),
        typeAnimal: yup
            .string()
            .required('form_error_validate.required'),
        species: yup
            .string()
            .when('typeAnimal', {
                is: value => PET_TYPE.Other === Number(value),
                then: _ => yup.string().nullable().max(100, 'form_error_validate.species_max_length'),
                otherwise: _ => yup
                    .string()
                    .required('form_error_validate.required')
            }),
        image: yup
            .mixed<string | File>()
            .notOneOf([''], 'form_error_validate.required')
            .required('form_error_validate.required')
            .test('fileType', 'Unsupported file format', (value) => {
                if (value instanceof File) {
                    return SUPPORTED_FORMATS.includes(value.type);
                }
                if (typeof value === 'string') {
                    return true;
                }
                return false;
            })
            .test('fileSize', 'form_error_validate.job_image_upload_format', (value) => {
                if (typeof value === 'string') {
                    return true;
                }
                return value instanceof File && value.size <= FILE_SIZE;
            }),
        note: yup
            .string()
            .max(2000, 'form_error_validate.note_pet_maxlength')
            .nullable()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            name: '',
            gender: '',
            birthday: '',
            typeAnimal: '',
            species: '',
            image: '',
            note: ''
        }
    });

    const watchImageUpload = form.watch('image');
    const watchTypeAnimal = form.watch('typeAnimal');

    // lifecycle
    useEffect(() => {
        const getData = async () => {
            await flowResult(getAllSpecies());
            if (code) {
                await flowResult(getDetailPet(code));
            } else {
                form.setValue('typeAnimal', PET_TYPE.Dog.toString());
            }
        };
        getData();

        return () => {
            setObservable('petDetail', null);
            setObservable('petSpecies', []);
        };
    }, []);

    useEffect(() => {
        if (petDetail) {
            const { name, birthday, gender, typeAnimal, note, species, image } = petDetail;
            form.reset({
                name,
                birthday: birthday,
                gender: gender ? String(gender) : '',
                typeAnimal: typeAnimal?.toString(),
                note,
                species: String(species?.id ?? species ?? ''),
                image
            });
        }
    }, [petDetail]);

    useEffect(() => {
        if (watchImageUpload && watchImageUpload instanceof File) {
            loadFilePreview(watchImageUpload, 'preview-image');
        } else {
            loadURLPreview(watchImageUpload, 'preview-image');
        }
    }, [watchImageUpload]);

    // function
    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newImage = e.target.files;
        if (newImage) {
            if (SUPPORTED_FORMATS.includes(newImage[0].type)) {
                form.setValue('image', newImage[0]);
                form.trigger(['image']);
            } else {
                showAlertModal({
                    type: 'error',
                    content: t('form_error_validate.shop_image_upload_format')
                });
            }
        }
        e.target.value = '';
    };

    const handleRemoveImage = () => {
        form.setValue('image', '');
        form.trigger('image');
    };

    const onSubmit = async (data: any) => {
        const dateFormat = formatDateTime(data.birthday, SUBMIT_MOMENT_DATE_YM);
        const newData = { ...data, birthday: dateFormat };
        if (code) {
            const res = await flowResult(editPet({ code, payload: newData }));
            if (res) {
                toastify('alert-success', t('messages.update_success'));
                hideModal('edit-my-pet');
            }
        } else {
            const res = await flowResult(addPet(newData));
            if (res === true) {
                toastify('alert-success', t('messages.create_success'));
                hideModal('add-my-pet');
            }
        }
        getAllPet();
    };

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div>
                        <FormField
                            control={form.control}
                            name='image'
                            render={({ field: { onChange, value, ...rest } }) => (
                                <>
                                    <FormItem>
                                        <Label className='label-1' required>{t('words_title.pet_image')}</Label>
                                        <FormDescription className='text-[0.8125rem] my-1.5'>{t('sentences.only_support_file_add_pet')}</FormDescription>
                                        <FormDescription className='text-[0.8125rem] my-1.5'>{t('sentences.maximum_one_image')}</FormDescription>
                                        <FormControl>
                                            <Input
                                                id='pet-images'
                                                type='file'
                                                onChange={(event) => {
                                                    handleImageFileChange(event);
                                                }}
                                                className='hidden'
                                                {...rest}
                                            />
                                        </FormControl>
                                        <Label htmlFor='pet-images' className='flex items-center justify-center cursor-pointer border border-input rounded-lg py-3'>
                                            <ImagePlusIcon className='text-active h-6 w-6 mr-2' />
                                            <span className='text-placeholder-1'>{t('button.image_of_pet')}</span>
                                        </Label>
                                        <FormMessage />
                                    </FormItem>
                                    <div className='flex flex-wrap gap-2 mt-4'>
                                        {
                                            watchImageUpload && (
                                                <div className='relative w-[110px] z-1000 mb-2'>
                                                    <img className='w-full h-20 object-cover rounded' id='preview-image' alt=''></img>
                                                    <button
                                                        type='button'
                                                        onClick={handleRemoveImage}
                                                        className='absolute top-1 left-1 bg-black bg-opacity-80 rounded-full p-1 shadow-lg'
                                                    >
                                                        <XIcon className='w-3 h-3 text-white' />
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </>
                            )}
                        />

                    </div>
                    <div className='w-full'>
                        <div className='w-full mb-[1rem] lg:mb-0'>
                            <Label className='label-1' required>{t('words_title.name')}</Label>
                            <FormField
                                control={form.control}
                                name='name'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                type='text'
                                                className='mt-[0.375rem]'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className='lg:flex block gap-[1rem] mt-[1rem]'>
                        <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                            <Label className='label-1' required>{t('words_title.date_of_birth')}</Label>
                            <FormField
                                control={form.control}
                                name='birthday'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <div className='mt-[0.375rem]'>
                                                <CalendarDatetime
                                                    className='mt-[0.375rem]'
                                                    value={field.value ? new Date(field.value) : undefined}
                                                    onChange={field.onChange}
                                                    dateFormat='MM/YYYY'
                                                    timeFormat={false}
                                                    initialViewMode='months'
                                                    updateOnView='months'
                                                    inputProps={{
                                                        placeholder: t('placeholder.choose')
                                                    }}
                                                    closeOnSelect={true}
                                                    isValidDate={currentDate => moment(currentDate).isSameOrBefore(moment(), 'month')}
                                                    useIconRemove={false}
                                                />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                            <Label className='label-1' required>{t('words_title.type_of_animal')}</Label>
                            <FormField
                                control={form.control}
                                name='typeAnimal'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Select
                                                key={field.value}
                                                onValueChange={(value) => {
                                                    field.onChange(value);
                                                    form.setValue('species', '', { shouldValidate: Number(value) === PET_TYPE.Other });
                                                }}
                                                {...field}
                                            >
                                                <SelectTrigger className='mt-[0.375rem]'>
                                                    <SelectValue placeholder={t('placeholder.choose')} />
                                                </SelectTrigger>
                                                <SelectContent className='w-full'>
                                                    {
                                                        Object.values(PET_TYPE).map((item, index) => (
                                                            <SelectItem key={index} value={item.toString()}>{t(`select_options.${MY_PET_TYPE[item].key}`)}</SelectItem>
                                                        ))
                                                    }
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className='lg:flex block gap-[1rem] mt-[1rem]'>
                        <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                            <Label className='label-1' required={!(Number(watchTypeAnimal) === PET_TYPE.Other)}>{t('words_title.breeds')}</Label>
                            <FormField
                                control={form.control}
                                name='species'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>

                                        </FormControl>
                                        {
                                            Number(watchTypeAnimal) === PET_TYPE.Other ?
                                                (
                                                    <Input
                                                        type='text'
                                                        value={field.value || ''}
                                                        onChange={field.onChange}
                                                        className='input-class mt-[0.375rem]'
                                                    />

                                                ) :
                                                (
                                                    <Select
                                                        key={field.value}
                                                        onValueChange={field.onChange}
                                                        {...field}
                                                    >
                                                        <SelectTrigger className='mt-[0.375rem]'>
                                                            <SelectValue placeholder={t('placeholder.choose')} />
                                                        </SelectTrigger>
                                                        <SelectContent className='w-full'>
                                                            {petSpecies.filter(item => Number(item.typeAnimal) === Number(watchTypeAnimal)).map((item, index) => (
                                                                <SelectItem key={index} value={String(item.id)}>
                                                                    {language === LANGUAGES_SUPPORTED.Vietnamese ? item.name : item.nameEn}
                                                                </SelectItem>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                )
                                        }
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                            <Label className='label-1'>{t('words_title.gender')}</Label>
                            <FormField
                                control={form.control}
                                name='gender'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Select
                                                key={field.value}
                                                onValueChange={value => field.onChange(value)}
                                                value={String(field.value)}
                                            >
                                                <SelectTrigger className='mt-[0.375rem]'>
                                                    <SelectValue placeholder={t('placeholder.choose')} />
                                                </SelectTrigger>
                                                <SelectContent className='w-full'>
                                                    <SelectItem value='1'>{t('words_title.male')}</SelectItem>
                                                    <SelectItem value='2'>{t('words_title.female')}</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className='mt-[1rem]'>
                        <Label className='label-1'>{t('words_title.note')}</Label>
                        <FormField
                            control={form.control}
                            name='note'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Textarea
                                            className='mt-[0.375rem]'
                                            value={field.value ?? ''}
                                            onChange={e => field.onChange(e.target.value)}
                                            placeholder={t('placeholder.note_placeholder_2')}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='gap-[1rem] mt-[1rem] lg:mt-[1.5rem] md:flex'>
                        <Button onClick={() => hideModal()} type='button' className='w-full border-border-9 mb-[0.375rem] hidden md:block lg:w-1/2' variant='outline'>{t('button.cancel')}</Button>
                        <Button type='submit' className='w-full block lg:w-1/2'>{t('button.save')}</Button>
                    </div>
                </form>
            </Form>
        </>
    );
});

import { LANGUAGES_MAPPER, MY_ACCOUNT_HASH_MAPPER_ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import AccountInfoDesktop from './components/AccountInfoDesktop';
import AccountInfoMobile from './components/AccountInfoMobile';
import MenuSibarMobileAfterLogin from './components/MenuSibarMobileAfterLogin';
import MenuSibarMobileBeforeLogin from './components/MenuSibarMobileBeforeLogin';
import { useState } from 'react';
import { useOutsideAlerter } from '@/hooks/useOutsideAlerter';
import { useTranslation } from 'react-i18next';

export default observer(function MyAccountPage() {
    // hooks
    const location = useLocation();
    const hash = location.hash;
    const outsideRef = useOutsideAlerter(() => setShowLangOptions(false));
    const { i18n: { language, changeLanguage } } = useTranslation();

    // store
    const { authStore: { token, profile }, uiStore: { devicesScreen: { mobile, desktop } } } = useStore();

    // state
    const [showLangOptions, setShowLangOptions] = useState<boolean>(false);

    // function
    const handleClickChangeLanguage = (item: string) => {
        if (LANGUAGES_MAPPER[item].code !== language) {
            changeLanguage(LANGUAGES_MAPPER[item].code);
            setShowLangOptions(false);
        }
    };

    return (
        <>
            {mobile && (
                hash === MY_ACCOUNT_HASH_MAPPER_ROUTES.accountInfomation.href ?
                    (
                        <AccountInfoMobile profile={profile} />
                    ) :
                    (
                        <div>
                            {token ?
                                (
                                    <MenuSibarMobileAfterLogin
                                        outsideRef={outsideRef}
                                        showLangOptions={showLangOptions}
                                        setShowLangOptions={setShowLangOptions}
                                        handleClickChangeLanguage={handleClickChangeLanguage}
                                    />
                                ) :
                                (
                                    <MenuSibarMobileBeforeLogin
                                        outsideRef={outsideRef}
                                        showLangOptions={showLangOptions}
                                        setShowLangOptions={setShowLangOptions}
                                        handleClickChangeLanguage={handleClickChangeLanguage}
                                    />
                                )}
                        </div>
                    )
            )}

            {desktop && token && profile && (
                <AccountInfoDesktop profile={profile} />
            )}
        </>
    );
});

import { CallIconButton, ClockIcon, LocationIcon, PetHospitalIcon, PetPrintIcon, PickupIcon } from '@/components/icons';
import { useStore } from '@/hooks/useStore';
import classNames from 'classnames';
import { CalendarClock, Heart, MessageSquareText, Star, TriangleAlert } from 'lucide-react';
import { ShopCardInfo } from '@/types/shop';
import { ACTIVITY_PATH, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, ROUTES, SHOP_CATEGORY } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { formatDateTime } from '@/utils/datetime';
import ShopImg from '@/assets/images/defaultShopImg.png';
import { formatVietnamPhoneNumber } from '@/utils/utils';
import { SHOP_CATEGORY_TYPE } from '@/types/enums';

interface StoreCardInfoProps {
    storeCard: ShopCardInfo,
    callBackUpdateFavoriteFc?: Function
}

export default observer(function StoreCard({ storeCard, callBackUpdateFavoriteFc }: StoreCardInfoProps) {
    // props
    const { name, address, rating, isFavorite, generalOpenTime, generalCloseTime,
        topReview, pickUpStatus, category, slug, id, code, operatingStatus, totalReviews, image, generalInfo, shopInfo, operationInfo, appointmentInfo, phoneNumber } = storeCard;
    // hook
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    // store
    const { authStore: { token }, shopStore: { updateFavoriteShops, setAllShop, allShop, topService, getShopDetail, setObservable },
        petStore: { getAllPet }, modalStore: { showAlertModal }, uiStore: { windowSize } } = useStore();
    // function
    const handleAddToFavoriteShops = async (slugShop: string, value: boolean) => {
        const res = await flowResult(updateFavoriteShops({ slug: slugShop, favorite: value }));
        if (res) {
            const findShopInAllShop = allShop.findIndex(item => item.slug === slugShop);
            const findShopInTopService = topService.findIndex(item => item.slug === slugShop);
            if (findShopInAllShop > -1) {
                let cloneAllShop = [...allShop];
                cloneAllShop[findShopInAllShop].isFavorite = value;
                setAllShop(cloneAllShop);
            }
            if (findShopInTopService > -1) {
                let cloneTopShop = [...topService];
                cloneTopShop[findShopInTopService].isFavorite = value;
                setObservable('topService', cloneTopShop, { isMergeObject: false });
            }

            callBackUpdateFavoriteFc && callBackUpdateFavoriteFc(res);
        }
    };

    const handleRedirectBookAppointment = async () => {
        if (!token) navigate(ROUTES.signIn.href);
        if (token && operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo) {
            const res = await flowResult(getAllPet());
            if (res) {
                if (res.length > 0) {
                    navigate(`${ROUTES.bookAppointment.href}/${slug}`, {
                        state: {
                            previousUrl: pathname
                        }
                    });
                } else {
                    showAlertModal({
                        id: 'pet-modal',
                        type: 'error',
                        content: t('sentences.not_any_pet')
                    });
                }
            }
        }
    };

    const handleRedirectChatScreen = () => {
        !token ? navigate(ROUTES.signIn.href) : navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.chat}/${slug}`);
    };

    return (
        <div className='2xl:px- py-4 px-4 w-full min-w-[20rem] md:rounded-sm bg-white md:border border-solid border-border-8 h-full flex flex-col justify-between'>
            <div className='shop-info min-h-[12.5rem] w-full pb-1 flex point-450:flex-row flex-col xsm:items-start items-center gap-4'>
                <div className='relative cursor-pointer point-450:w-[11.625rem] w-full' onClick={() => navigate(`${ROUTES.shopDetail.href}/${slug}`)}>
                    {!operatingStatus && (
                        <div className='absolute flex flex-col gap-1 justify-center items-center top-0 left-0 w-full h-full rounded-[0.25rem] bg-black/60'>
                            <TriangleAlert className='text-white size-[1.1875rem]' />
                            <p className='text-white font-medium text-center'>{t('sentences.temporarily_out_of_service')}</p>
                        </div>
                    )}
                    <img src={image || ShopImg} className='point-450:size-[11.625rem] w-full aspect-video point-450:object-cover rounded-[0.25rem]' alt='shop avartar' />
                </div>
                <div className='py-2 flex flex-col gap-2 flex-1 overflow-hidden w-full'>
                    <div className='min-h-7 flex justify-between items-center gap-2'>
                        <div className='h-full min-h-7 bg-background-3 border-l border-l-icon-4 rounded-[0.125rem] flex items-center justify-start px-2 gap-1'>
                            {category === SHOP_CATEGORY_TYPE.PetHospital ? <PetHospitalIcon className='h-5 w-5 fill-active' /> : <PetPrintIcon className='h-4 w-4 fill-icon-4' />}
                            <p className='text-tab-1'>{category ? t(`select_options.${SHOP_CATEGORY[category].key}`) : ''}</p>
                        </div>
                        {token && <div onClick={() => handleAddToFavoriteShops(slug, !isFavorite)}><Heart className={classNames('w-5 h-5 cursor-pointer', isFavorite ? 'text-active fill-active' : 'text-black fill-white')} strokeWidth={1} /></div>}
                    </div>
                    <div className='line-clamp-2'>
                        <p onClick={() => navigate(`${ROUTES.shopDetail.href}/${slug}`)} className='text-[1.0625rem] font-medium cursor-pointer hover:underline leading-6'>{name}</p>
                    </div>
                    <div className='flex gap-1'>
                        <div className='pt-1 size-[0.8125rem]'>
                            <LocationIcon className='size-[0.8125rem] fill-icon-2' />
                        </div>
                        <p className='text-tab-1 break-words text-small line-clamp-2'>{address}</p>
                    </div>
                    <div className='flex items-center gap-1'>
                        <div className='size-[0.8125rem]'>
                            <ClockIcon className='size-[0.8125rem] fill-icon-2' />
                        </div>
                        <p className='text-tab-1 text-small break-words'>
                            {formatDateTime(generalOpenTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            })}
                            {' '}
                            -
                            {' '}
                            {formatDateTime(generalCloseTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            })}
                        </p>
                    </div>
                    <div className='flex gap-1 items-center'>
                        <p className='text-icon-3 text-small'>{rating.toFixed(1) || '0.0'}</p>
                        <Star className='text-icon-3 w-4 h-4' fill='#FFB72C' />
                        <p className='text-tab-1 text-small'>
                            (
                            {totalReviews || 0}
                            )
                        </p>
                    </div>
                    <div className={classNames('flex gap-1 px-2 items-center bg-background-4 w-fit h-7 rounded-[0.125rem]', !pickUpStatus && 'opacity-0')}>
                        <PickupIcon className='fill-icon-2 w-4 h-3' />
                        <p className='text-small text-text-14'>{t('words_title.mark_pick_up')}</p>
                    </div>
                </div>
            </div>
            <div>
                <div className='comment border-dashed border-t border-border-8 flex gap-3 h-17 pt-2.5'>
                    <div className='flex-1 bg-background-3 flex gap-1 px-2 py-2 rounded-[0.1875rem] border border-border-1 overflow-hidden'>
                        {topReview ?
                            (
                                <>
                                    {/* <img className='rounded-full h-5 w-5' src={AvartatDefault2} alt='avartar' /> */}
                                    <p className='flex-1 text-small text-tab-1 line-clamp-2 italic whitespace-pre-line'>{topReview?.comment !== '' && `${topReview?.comment}`}</p>
                                </>
                            ) :
                            <p className='text-small text-tab-1'>{t('sentences.no_comment')}</p>}
                    </div>
                </div>
                <div className='grid grid-cols-3 border-t border-border-8 justify-center items-center h-fit mt-2.5 pt-4 '>
                    <div
                        title={windowSize.width <= 920 ? t('button.make_a_call') : t('sentences.not_support_pc')}
                        className={classNames('border-r border-border-4 border-dashed flex items-center justify-center', windowSize.width <= 920 ? 'cursor-pointer' : 'cursor-not-allowed')}
                    >
                        {
                            windowSize.width <= 920 ?
                                (
                                    <a href={phoneNumber ? `tel:${formatVietnamPhoneNumber(phoneNumber)}` : ''} target='_blank' rel='noreferrer'>
                                        <CallIconButton className='w-6 h-6 text-active stroke-active fill-none' />
                                    </a>
                                ) :
                                <CallIconButton className='w-6 h-6 text-active stroke-active fill-none opacity-50' />
                        }
                    </div>
                    <div
                        onClick={() => handleRedirectChatScreen()}
                        title={t('button.chat_tooltip')}
                        className='border-r border-border-4 border-dashed flex items-center justify-center cursor-pointer'
                    >
                        <MessageSquareText strokeWidth={1.25} className='w-6 h-6 text-active' />
                    </div>
                    <div
                        title={(!token || (token && operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo)) ? t('button.make_appointment') : t('sentences.function_not_available')}
                        onClick={() => handleRedirectBookAppointment()}
                        aria-disabled={!(token && operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo)}
                        className={classNames(
                            'flex items-center justify-center cursor-pointer',
                            {
                                'disabled:opacity-20 !cursor-not-allowed': token && !(operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo)
                            }
                        )}
                    >
                        <CalendarClock strokeWidth={1.25} className={classNames('w-6 h-6 text-active', token && !(operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo) && 'opacity-50')} />
                    </div>

                </div>
            </div>
        </div>
    );
});

import { useStore } from '@/hooks/useStore';
import { GeneralSettingType } from '@/types/enums';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import '../index.scss';

interface GeneralSettingProps {
    type: GeneralSettingType
}

export default observer(function GeneralSetting({ type }: GeneralSettingProps) {
    // store
    const { generalStore: { content, getGeneralSettingByType } } = useStore();

    // lifecycle
    useEffect(() => {
        getGeneralSettingByType(type);
    }, []);

    return (
        <div className='py-4 md:py-6 px-4'>
            <div className='ql-editor p-0' dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
});

import * as React from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'lucide-react';
import { DayPicker, NavProps } from 'react-day-picker';
import { cn } from '@/utils/utils';
import { format } from 'date-fns';
import { buttonVariants } from '../common/Button';
import { DISPLAY_FNS_DATE_YMD_FOR_LABEL_CALENDAR, LANGUAGES_MAPPER } from '@/configs/constants';
import moment from 'moment';

export type ReactDayPickerProps = React.ComponentProps<typeof DayPicker>;

function ReactDayPicker({
    className,
    classNames,
    showOutsideDays = true,
    captionLayout = 'dropdown',
    ...props
}: ReactDayPickerProps) {
    const navigationButtonStyles = cn(
        'h-4 w-4 bg-transparent p-0'
    );

    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('p-4 rounded-lg border max-w-[300px]', className)}
            classNames={{
                nav: 'h-10 px-4 flex items-center justify-between',
                chevron: 'w-4 h-4',
                button_next: navigationButtonStyles,
                button_previous: navigationButtonStyles,
                caption_label: cn('text-[0.8125rem] font-semibold text-calendar-2 leading-4', captionLayout !== 'label' && 'hidden'),
                day: cn(
                    buttonVariants({ variant: 'ghost' }),
                    'h-8 w-8 font-normal text-center text-[0.8125rem] text-calendar-1 p-0 relative rounded [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 hover:cursor-pointer'
                ),
                day_button: 'h-8 w-8',
                selected: 'bg-active text-primary-foreground hover:bg-active hover:text-primary-foreground focus:bg-active focus:text-primary-foreground',
                disabled: 'text-muted-foreground opacity-50',
                focused: '',
                hidden: 'invisible',
                outside: 'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
                today: 'bg-accent text-accent-foreground',
                dropdown: 'font-normal text-[0.8125rem] border-none outline-none hover:cursor-pointer',
                dropdown_root: '',
                dropdowns: 'flex flex-row-reverse gap-2 w-[100px] items-center justify-center',
                footer: '',
                month: 'w-full border-collapse',
                month_caption: 'absolute top-0 left-1/2 -translate-x-1/2 h-10 flex items-center',
                month_grid: 'w-full',
                months: 'relative flex flex-col',
                months_dropdown: '',
                week: 'flex w-full mt-2 justify-between',
                week_number: '',
                week_number_header: '',
                weekday: 'text-calendar-1 rounded-md w-9 font-normal text-[0.8125rem]',
                weekdays: 'flex h-10 items-center border-y border-border-3 justify-between',
                weeks: '',
                years_dropdown: '',
                ...classNames
            }}
            captionLayout={captionLayout}
            components={{
                Chevron: ({ className, disabled, orientation = 'down', size }) => {
                    const iconChevron = {
                        'down': ChevronDown,
                        'up': ChevronUp,
                        'left': ChevronLeft,
                        'right': ChevronRight
                    };

                    const Comp = iconChevron[orientation];

                    return (
                        <Comp className={className} />
                    );
                },
                Nav: ({ children, className, ...props }: NavProps) => {
                    const [nextButton, previousButton] = React.Children.map(children, child => child) as Array<React.ReactElement<any, string | React.JSXElementConstructor<any>>>;

                    return (
                        <nav className={cn('flex items-center justify-between', className)} {...props}>
                            {nextButton}
                            {previousButton}
                        </nav>
                    );
                }
            }}
            formatters={{
                formatWeekdayName: (weekday, options) => {
                    return format(weekday, 'eee', {
                        locale: options?.locale
                    });
                },
                formatMonthDropdown: (month, locale) => {
                    const formatMonth = locale?.code === LANGUAGES_MAPPER.vietnamese.code ? 'MM' : 'MMM';
                    return format(moment().set('month', month).toDate(), formatMonth, {
                        locale
                    });
                },
                formatCaption: (date) => {
                    return format(date, DISPLAY_FNS_DATE_YMD_FOR_LABEL_CALENDAR);
                }
            }}
            endMonth={new Date((new Date()).getFullYear() + 5, 11, 31)}
            {...props}
        />
    );
}
ReactDayPicker.displayName = 'ReactDayPicker';

export { ReactDayPicker };

import { Comment, Topic } from '@/types/topic';
import { ResponseData } from '@/types/http';
import { CreateCommentRequest, CreateTopicRequest, EditTopicRequest, GetAllTopicRequest, GetAllTopicResponse, GetTopicDetailRequest } from '@/types/http-payload/forum';
import { TableState } from '@tanstack/react-table';
import { flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { TopicApi } from '@/apis';

export default class TopicStore extends BaseStore {
    api: TopicApi;
    topics: Topic[] = [];
    pinTopics: Topic[] = [];
    topic: Topic | null = null;

    // Comment
    comments: Comment[] = [];
    defaultCommentPaging: TableState = {
        pagination: { pageIndex: 0, pageSize: 20 },
        sorting: [{}]
    } as TableState;

    commentPaging: TableState = this.defaultCommentPaging;
    totalCommentRecord: number = 0;
    totalCommentPage: number = 0;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            topics: observable,
            topic: observable,
            pinTopics: observable,
            comments: observable,
            commentPaging: observable,
            totalCommentRecord: observable,
            getAllTopic: flow.bound,
            getTopicDetail: flow.bound,
            likeTopic: flow.bound,
            createTopic: flow.bound,
            editTopic: flow.bound,
            deleteTopic: flow.bound,
            createComment: flow.bound,
            getAllComment: flow.bound
        });
        this.api = new TopicApi();
    }

    *getAllTopic(payload: GetAllTopicRequest, paging?: TableState) {
        try {
            payload = { ...this.convertPagingFromTableToRequest(paging ?? this.paging), ...payload };
            const res: ResponseData<GetAllTopicResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllTopic, payload);
            if (res?.ok) {
                this.pinTopics = res?.data?.pinTopics || [];
                this.topics = res?.data?.elements || [];
                this.paging = {
                    ...this.paging,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalRecord = res?.data?.pagination?.totalRecord;
                this.totalPage = res?.data?.pagination?.totalPage;
            }
        } catch (error) {
        }
    }

    *getTopicDetail(payload: GetTopicDetailRequest, isHidenLoading: boolean = false) {
        try {
            const res: ResponseData<Topic> = yield this.rootStore.apiStore.call(this.api, this.api.getTopicDetail, payload, { hideLoading: isHidenLoading });
            if (res?.ok) {
                this.topic = res?.data || null;
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    *likeTopic(slug: string) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.likeTopic, slug, { hideLoading: true });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
        }
    };

    *createTopic(payload: CreateTopicRequest) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.createTopic, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *editTopic(payload: EditTopicRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.editTopic, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *deleteTopic(slug: string) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.deleteTopic, slug);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    *createComment(payload: CreateCommentRequest) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.createComment, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *getAllComment(slug: string, paging?: TableState) {
        try {
            const payload = { paging: { ...this.convertPagingFromTableToRequest(paging ?? this.commentPaging) }, slug };
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.getAllComment, payload);
            if (res?.ok) {
                this.comments = res?.data?.elements || [];
                this.commentPaging = {
                    ...this.commentPaging,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalCommentPage = res?.data?.pagination?.totalPage;
                this.totalCommentRecord = res?.data?.pagination?.totalRecord;
            }
        } catch (error) {
        }
    }

    clean(): void {
        super.clean();

        this.pinTopics = [];
        this.topics = [];
        this.topic = null;
        this.comments = [];
        this.commentPaging = this.defaultCommentPaging;
        this.totalCommentRecord = 0;
        this.totalCommentPage = 0;
    }
}

import { useStore } from '@/hooks/useStore';
import { INFO_TYPE } from '@/types/enums';
import { GetOperationInfoResponse } from '@/types/http-payload/shop';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import MyShopItem from '../../components/MyShopItem';
import { useTranslation } from 'react-i18next';
import { DAYS_OF_WEEK, DEFAULT_NULL_VALUE, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, PICK_UP_PRICE } from '@/configs/constants';
import { formatDateTime } from '@/utils/datetime';

export default observer(function OperationInfoTab() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const { shopStore: { getShopInfo } } = useStore();

    // state
    const [shopInfo, setShopInfo] = useState<GetOperationInfoResponse>();

    const getPickupPriceStr = useMemo(() => {
        const indexPickup = Object.keys(PICK_UP_PRICE).findIndex(item => item === shopInfo?.pickUpPriceType?.toString());

        if (indexPickup !== -1) {
            return t(`select_options.${PICK_UP_PRICE[indexPickup + 1]?.key}`);
        } else {
            return '--';
        }
    }, [shopInfo, language]);

    // lifecycle
    useEffect(() => {
        fetchData();
    }, []);

    // function
    const fetchData = async () => {
        const res = await flowResult(getShopInfo({ typeInfo: INFO_TYPE.OperationInfo }));
        if (res && res.ok) {
            setShopInfo(res.data as GetOperationInfoResponse);
        }
    };

    return (
        <div className='flex flex-col md:gap-4 gap-1.5'>
            <div className='container-shop-info-destop'>
                <MyShopItem title={t('words_title.shop_display_status')} desc={<div>{(shopInfo?.displayStatus ? t('words_title.on') : t('words_title.off')) ?? ''}</div>} />
                <MyShopItem title={t('words_title.shop_operation_status')} desc={<div>{(shopInfo?.operatingStatus ? t('words_title.on') : t('words_title.off')) ?? ''}</div>} />
                <MyShopItem title={t('words_title.shop_pickup_status')} desc={<div>{(shopInfo?.pickUpStatus ? t('words_title.on') : t('words_title.off')) ?? ''}</div>} />
                {
                    shopInfo?.pickUpStatus && (
                        <MyShopItem title={t('words_title.shop_pickup_price')} desc={<div>{getPickupPriceStr}</div>} />
                    )
                }
            </div>

            <div className='container-shop-info-destop'>
                <MyShopItem
                    title={t('words_title.open_time')}
                    desc={(
                        <div>
                            {formatDateTime(shopInfo?.generalOpenTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            }) ?? DEFAULT_NULL_VALUE}
                        </div>
                    )}
                />
                <MyShopItem
                    title={t('words_title.close_time')}
                    desc={(
                        <div>
                            {formatDateTime(shopInfo?.generalCloseTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            }) ?? DEFAULT_NULL_VALUE}
                        </div>
                    )}
                />
            </div>

            {
                shopInfo?.operationTimes && shopInfo?.operationTimes.length > 0 &&
                shopInfo?.operationTimes.map((item, index) => {
                    let dayWord = '';
                    const indexDay = Object.keys(DAYS_OF_WEEK).findIndex(day => day === item?.day?.toString());

                    if (indexDay !== -1) {
                        dayWord = t(`${DAYS_OF_WEEK[indexDay]?.key}`);
                    }

                    if (index === 0) {
                        return (
                            <div key={index} className='bg-white md:px-4 px-0 md:pt-6 pt-4 md:pb-4 pb-0 rounded'>
                                <div className='px-4 text-text-3 font-semibold'>{t('words_title.operation_title')}</div>
                                <div className='grid md:grid-cols-2 grid-cols-1 gap-2.5'>
                                    <MyShopItem title={t('words_title.day_of_week')} desc={<div>{dayWord}</div>} />
                                    <MyShopItem title={t('words_title.on_off_status')} desc={<div>{item.openStatus ? t('words_title.on') : t('words_title.off')}</div>} />
                                    {
                                        item.openStatus && (
                                            <>
                                                <MyShopItem
                                                    title={t('words_title.open_time')}
                                                    desc={(
                                                        <div>
                                                            {formatDateTime(item.openTime ?? DEFAULT_NULL_VALUE, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                                onlyTime: true,
                                                                currentformat: DISPLAY_MOMENT_TIME_HMS
                                                            })}
                                                        </div>
                                                    )}
                                                />
                                                <MyShopItem
                                                    title={t('words_title.close_time')}
                                                    desc={(
                                                        <div>
                                                            {formatDateTime(item.closeTime ?? DEFAULT_NULL_VALUE, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                                onlyTime: true,
                                                                currentformat: DISPLAY_MOMENT_TIME_HMS
                                                            })}
                                                        </div>
                                                    )}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className='container-shop-info-destop'>
                                <MyShopItem title={t('words_title.day_of_week')} desc={<div>{dayWord}</div>} />
                                <MyShopItem title={t('words_title.on_off_status')} desc={<div>{item.openStatus ? t('words_title.on') : t('words_title.off')}</div>} />
                                {
                                    item.openStatus && (
                                        <>
                                            <MyShopItem
                                                title={t('words_title.open_time')}
                                                desc={(
                                                    <div>
                                                        {formatDateTime(item.openTime ?? DEFAULT_NULL_VALUE, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                            onlyTime: true,
                                                            currentformat: DISPLAY_MOMENT_TIME_HMS
                                                        })}
                                                    </div>
                                                )}
                                            />
                                            <MyShopItem
                                                title={t('words_title.close_time')}
                                                desc={(
                                                    <div>
                                                        {formatDateTime(item.closeTime ?? DEFAULT_NULL_VALUE, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                            onlyTime: true,
                                                            currentformat: DISPLAY_MOMENT_TIME_HMS
                                                        })}
                                                    </div>
                                                )}
                                            />
                                        </>
                                    )
                                }
                            </div>
                        );
                    }
                })
            }

        </div>
    );
});

import classNames from 'classnames';
import { ReactElement } from 'react';

interface MyShopItemProps {
    title: string,
    desc: ReactElement,
    classname?: string
}

export default function MyShopItem({ title, desc, classname }: MyShopItemProps) {
    return (
        <div className={classNames('px-4 py-3 border-b border-background-2', classname)}>
            <div className='text-text-4 font-normal'>{title}</div>
            <div className='text-text-3 font-medium'>{desc}</div>
        </div>
    );
};

import { ROUTES } from '@/configs/constants';
import { Link } from 'react-router-dom';
import Logo from '@/assets/images/logo.svg';
import UserAvatar from '@/components/general/UserAvatar';
import { useTranslation } from 'react-i18next';
import SelectLanguage from '@/components/general/SelectLanguage';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { HEADER_FORMAT } from '@/types/enums';
import { useStore } from '@/hooks/useStore';
import NotificationIcon from '@/components/general/NotificationIcon';

interface HeaderDesktopForShopChannelProps { }

export default observer(function HeaderDesktopForShopChannel(props: HeaderDesktopForShopChannelProps) {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        uiStore: { setShowHeaderFormat }
    } = useStore();

    // lifecycle
    useEffect(() => {
        setShowHeaderFormat(HEADER_FORMAT.desktopShop);

        return () => {
            setShowHeaderFormat(null);
        };
    }, []);

    return (
        <div className='bg-white flex items-center h-auto flex-col point-920:flex-row point-920:justify-between point-920:gap-8'>
            <div className='h-12 flex items-end point-920:h-17 point-920:items-center'>
                <Link to={ROUTES.home.href} className='logo h-10'>
                    <img className='h-full' src={Logo} alt='logo' />
                </Link>
            </div>
            <div className='flex items-center justify-between w-full h-17'>
                <div className='text-text-3 font-medium text-lg'>
                    {t('words_title.shop_channel')}
                </div>
                <div className='flex items-center'>
                    <NotificationIcon withText />
                    <div className='pr-2 text-sm text-nav-1'>
                        <SelectLanguage />
                    </div>
                    <div className='acc-info px-4 border-l border-l-border-6'>
                        <UserAvatar />
                    </div>
                </div>
            </div>
        </div>
    );
});

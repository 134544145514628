import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious
} from '@/components/common/Carousel';
import { observer } from 'mobx-react-lite';
import StoreCard from './StoreCard';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';
import { useEffect } from 'react';
import { TableState } from '@tanstack/react-table';
import { ROUTES, SORT_OPTION } from '@/configs/constants';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import CommingSoonImg from '@/assets/images/commingSoon.png';

interface TopServiceProps {
    isViewAll?: boolean
}

export default observer(function TopService({ isViewAll = false }: TopServiceProps) {
    // hooks
    const { t } = useTranslation();

    // store
    const { uiStore: { devicesScreen: { mobile } }, shopStore: { topService, getTopServiceShop, pagingTopService } } = useStore();

    // lifecycle
    useEffect(() => {
        getDataTopService();
    }, []);

    // function
    const getDataTopService = () => {
        let pagingReq: TableState = { ...pagingTopService };
        pagingReq = {
            ...pagingReq,
            sorting: [
                {
                    id: SORT_OPTION.StarRate.value,
                    desc: true
                }
            ]
        };
        getTopServiceShop(pagingReq);
    };

    const limitData = isViewAll && mobile && topService?.length ? [...topService] : topService?.slice(0, 2) || [];

    return (
        mobile ?
            (
                <div>
                    {
                        isViewAll && <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.top_service')} defaultNavigateUrl={ROUTES.home.href} />} />
                    }
                    <div className='flex flex-col gap-2'>
                        {limitData?.length ?
                            (<>{limitData.map((store, index) => <StoreCard key={index} storeCard={store} />) }</>) :
                            (
                                <div className='w-full p-6 h-52 flex justify-center items-center bg-white'>
                                    <img src={CommingSoonImg} alt='' className='w-[200px] h-auto object-contain' />
                                </div>
                            )}

                    </div>
                </div>
            ) :
            (
                topService?.length ?
                    (
                        <Carousel
                            opts={{
                                align: 'start'
                            }}
                            className='w-full'
                        >
                            <CarouselContent className='-mx-1 flex snap-x snap-mandatory'>
                                {topService.map((store, index) => (
                                    <CarouselItem key={index} className='pl-0 basis-full point-920:basis-1/2 2xl:basis-1/3 snap-center flex-shrink-0'>
                                        <div className='flex justify-center items-center 2xl:px-1 point-920:px-2 px-1 h-full w-full'>
                                            <StoreCard storeCard={store} />
                                        </div>
                                    </CarouselItem>
                                ))}
                            </CarouselContent>
                            {topService && topService.length > 2 && (
                                <CarouselPrevious className='border-none group absolute top-1/2 left-[40px] transform -translate-y-1/2 -translate-x-full shadow-xl' />
                            )}
                            {topService && topService.length > 2 && (
                                <CarouselNext className='border-none group absolute top-1/2 right-[40px] transform -translate-y-1/2 translate-x-full shadow-xl' />
                            )}

                        </Carousel>
                    ) :
                    (
                        <div className='w-full p-6 flex justify-center items-center'>
                            <img src={CommingSoonImg} alt='' className='w-[200px] h-auto object-contain' />

                        </div>
                    )
            )
    );
});

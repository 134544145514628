import { Button } from '@/components/common/Button';
import { Checkbox } from '@/components/common/Checkbox';
import { InputWithIcon } from '@/components/common/Input';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import Paginate from '@/components/pagination/Paginate';
import { useStore } from '@/hooks/useStore';
import { ForumType } from '@/types/enums';
import { scrollToTop } from '@/utils/utils';
import { CirclePlus, Search } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostForm } from '../component/PostForm';
import { TopicItem } from '../component/TopicItem';

interface TopicListProps {
    forumType: ForumType
}

export default observer(function TopicList({ forumType }: TopicListProps) {
    // hooks
    const { t } = useTranslation();
    const [params, setParams] = useState<{ [key: string]: any }>({ 'forum': forumType, 'sorting': 'createdAt' });
    const [keyword, setKeyword] = useState('');

    // store
    const { topicStore: { topics, pinTopics, paging, totalPage, getAllTopic, clean },
        modalStore: { showModal },
        authStore: { token } } = useStore();

    // states
    const [isSearched, setIsSearched] = useState(false);

    // lifecycle
    useEffect(() => {
        getAllTopic(params, { ...paging, pagination: { pageSize: 20, pageIndex: 0 } });
        setIsSearched(!!params.keyword || params.isMyPost);
        return () => {
            clean();
        };
    }, [params]);

    // functions
    function showModalNewPost() {
        showModal({
            id: 'topic-new-post',
            size: '4xl',
            title: t('words_title.new_post'),
            titleClassName: 'title-3 text-center',
            content: <PostForm params={params} forumType={forumType} />,
            footerClassName: 'p-0'
        });
    }

    const handleCheckboxChange = (checked) => {
        setParams({ ...params, isMyPost: checked });
    };

    const handleSearch = () => {
        const trimmedKeyword = keyword.trim();
        setKeyword(trimmedKeyword);
        setParams({ ...params, keyword: trimmedKeyword });
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSelect = (value: string) => {
        setParams({ ...params, sorting: value });
    };

    return (
        <div>
            <div className='py-4 px-2 md:px-4'>
                <div className='flex flex-col justify-between gap-3'>
                    <div className='flex justify-between'>
                        <div className='w-full md:w-[15.25rem]'>
                            <InputWithIcon
                                className='relative placeholder:text-placeholder-1 h-[2.1875rem]'
                                type='text'
                                placeholder={t('placeholder.search_career')}
                                icon={(
                                    <div
                                        className='absolute right-4 top-1/2 -translate-y-1/2'
                                        onClick={handleSearch}
                                    >
                                        <Search strokeWidth={2} className='size-[1.1875rem] text-placeholder-1 cursor-pointer hover:text-active' />
                                    </div>
                                )}
                                value={keyword}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div>
                            <Button
                                className='px-2 h-[2.1875rem] hidden md:flex gap-1 items-center'
                                variant='default'
                                onClick={showModalNewPost}
                            >
                                <CirclePlus className='text-white' />
                                <span className='text-white'>{t('button.create_new_post')}</span>
                            </Button>
                        </div>
                    </div>
                    <div className='flex items-center justify-between'>
                        {token ?
                            (
                                <label className='flex items-start gap-1'>
                                    <Checkbox
                                        className='mt-[1px]'
                                        title='isMyPost'
                                        onCheckedChange={handleCheckboxChange}
                                    />
                                    <span className='cursor-pointer'>{t('words_title.only_my_posts')}</span>
                                </label>
                            ) :
                            (<div></div>)}

                        <Select defaultValue='createdAt' onValueChange={handleSelect}>
                            <SelectTrigger className='h-7.5 w-44'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value='createdAt'>{t('select_options.latest_topics')}</SelectItem>
                                    <SelectItem value='mostViewed'>{t('select_options.the_most_viewed')}</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            </div>
            <div className='flex flex-col border-t-4'>
                {(pinTopics?.length > 0 || topics?.length > 0) ?
                    (
                        <>
                            {pinTopics.map(topic => (
                                <TopicItem key={topic.code} topic={topic} isPinTopics={true} forumType={forumType} />
                            ))}
                            {topics?.length > 0 ?
                                (
                                    topics.map(topic => (
                                        <TopicItem key={topic.code} topic={topic} forumType={forumType} />
                                    ))
                                ) :
                                (
                                    isSearched && (
                                        <div className='min-h-20 flex items-center justify-center'>
                                            <p className='text-tab-1'>{t('sentences.no_topic_found')}</p>
                                        </div>
                                    )
                                )}

                        </>
                    ) :
                    (
                        <div className='min-h-20 flex items-center justify-center'>
                            <p className='text-tab-1'>{t('sentences.no_topic')}</p>
                        </div>
                    )}
            </div>
            {totalPage > 0 && (
                <div className='w-full flex justify-center pt-4 pb-10'>
                    <Paginate
                        forcePage={paging.pagination.pageIndex}
                        pageCount={totalPage}
                        onPageChange={({ selected }) => {
                            paging.pagination.pageIndex = selected;
                            getAllTopic(params, paging);
                            scrollToTop();
                        }}
                    />
                </div>
            )}
            <button
                title={t('button.create_new_post')}
                className='fixed md:hidden size-10 rounded-full bg-active bottom-20 right-5 shadow-lg'
                onClick={showModalNewPost}
            >
                <div className='size-full flex items-center justify-center'><CirclePlus className='size-6 text-white' /></div>
            </button>
        </div>
    );
});

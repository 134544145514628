import * as React from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon, CircleXIcon, MoveRight } from 'lucide-react';
import {
    Popover,
    PopoverContent,
    PopoverTrigger
} from '../common/Popover';
import { ReactDayPicker } from './ReactDayPicker';
import { useTranslation } from 'react-i18next';
import { getLocaleDateFns } from '@/utils/language';
import { cn } from '@/utils/utils';
import { DISPLAY_FNS_DATE_YMD } from '@/configs/constants';

type CalendarProps = {
    selectedFrom: Date | undefined,
    selectedTo: Date | undefined,
    onSelectFrom: (date: Date | undefined) => void,
    onSelectTo: (date: Date | undefined) => void,
    formatDate?: string,
    className?: string,
    useIconRemove?: boolean
};

export default function DualDatePicker({
    selectedFrom,
    selectedTo,
    onSelectFrom,
    onSelectTo,
    formatDate = DISPLAY_FNS_DATE_YMD,
    className = '',
    useIconRemove = true
}: CalendarProps) {
    const { i18n: { language }, t } = useTranslation();
    const localeDateFns = getLocaleDateFns(language);

    const [openCalendarFrom, setOpenCalendarFrom] = React.useState<boolean>(false);
    const [openCalendarTo, setOpenCalendarTo] = React.useState<boolean>(false);

    // Adjust dates to ensure 'To' is not less than 'From'
    const handleSelectFrom = (date: Date | undefined) => {
        if (date && selectedTo && date > selectedTo) {
            onSelectTo(date);
        }
        onSelectFrom(date);
    };

    const handleSelectTo = (date: Date | undefined) => {
        if (date && selectedFrom && date < selectedFrom) {
            onSelectFrom(date);
        }
        onSelectTo(date);
    };

    // Calendar input for 'From' date
    const CalendarInputFrom = () => (
        <Popover open={openCalendarFrom} onOpenChange={setOpenCalendarFrom}>
            <PopoverTrigger asChild>
                <button
                    className={cn('relative justify-between w-20 text-center')}
                >
                    <span className={cn(!selectedFrom && 'text-placeholder-1')}>
                        {selectedFrom ? format(selectedFrom, formatDate) : t('placeholder.from_date')}
                    </span>
                </button>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0 rounded-lg shadow-none border-none'>
                <div className='p-4'>
                    <ReactDayPicker
                        mode='single'
                        locale={localeDateFns}
                        selected={selectedFrom}
                        onSelect={(date) => {
                            handleSelectFrom(date || undefined);
                            setOpenCalendarFrom(false);
                        }}
                    />
                </div>
            </PopoverContent>
        </Popover>
    );

    // Calendar input for 'To' date
    const CalendarInputTo = () => (
        <Popover open={openCalendarTo} onOpenChange={setOpenCalendarTo}>
            <PopoverTrigger asChild>
                <button
                    className={cn('relative justify-between w-20 text-center')}
                >
                    <span className={cn(!selectedTo && 'text-placeholder-1')}>
                        {selectedTo ? format(selectedTo, formatDate) : t('placeholder.to_date')}
                    </span>
                </button>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0 rounded-lg shadow-none border-none'>
                <div className='p-4'>
                    <ReactDayPicker
                        mode='single'
                        locale={localeDateFns}
                        selected={selectedTo}
                        onSelect={(date) => {
                            handleSelectTo(date || undefined);
                            setOpenCalendarTo(false);
                        }}
                    />
                </div>
            </PopoverContent>
        </Popover>
    );

    return (
        <div className={cn('min-w-64 flex items-center gap-2 justify-between bg-white px-4 py-2.5 border border-border-1 rounded-md', className)}>
            <div className='flex gap-2 items-center justify-around w-full'>
                <CalendarInputFrom />
                <MoveRight className='text-placeholder-1 size-5' />
                <CalendarInputTo />
            </div>
            <div className='flex items-center gap-1'>
                <div className='flex items-center gap-2'>
                    {
                        (selectedFrom || selectedTo) && useIconRemove && (
                            <CircleXIcon
                                strokeWidth={1.5}
                                className=' hover:text-red-500'
                                onPointerDown={() => {
                                    onSelectFrom(undefined);
                                    onSelectTo(undefined);
                                }}
                            />
                        )
                    }
                </div>
                <CalendarIcon className='size-6' strokeWidth={1.5} />
            </div>
        </div>
    );
}

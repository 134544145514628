import { ReactDayPicker } from '@/components/calendar/ReactDayPicker';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { Button } from '@/components/common/Button';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AppointmentDate, AppointmentInfo, ShopServiceAppointment } from '@/types/shop';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/common/Form';
import classNames from 'classnames';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { PickupIcon } from '@/components/icons';
import { Switch } from '@/components/common/Switch';
import { BOOK_APPOINTMENT_ERROR_TYPE, PET_TYPE, PetType, SHOP_SERVICE_NAME_TYPE, SHOP_SERVICE_TYPE, ShopServiceNameType, ShopServiceType } from '@/types/enums';
import { DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, PICK_UP_PRICE, ROUTES, SHOP_SERVICE_NAME, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/common/Checkbox';
import ServiceLabel from '../components/ServiceLabel';
import { cn, convertServicePrice, filterServiceListByType, scrollToTop, shortenParagraph } from '@/utils/utils';
import { Textarea } from '@/components/common/Textarea';
import { useStore } from '@/hooks/useStore';
import { flowResult } from 'mobx';
import React from 'react';
import { NavProps } from 'react-day-picker';
import { isSameMonth } from 'date-fns';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatDateTime } from '@/utils/datetime';
import { BooKAppointmentData } from '@/types/http-payload/appointment';
import serviceAppointmentButton from '@/components/general/ServiceAppointmentButton';
import { getLocaleDateFns } from '@/utils/language';
import { TriangleAlert } from 'lucide-react';

interface InputAppointmentProps {
    onComplete?: Function
}

export default observer(function InputAppointment({ onComplete }: InputAppointmentProps) {
    // hook
    const { t, i18n: { language } } = useTranslation();
    const localeDateFns = getLocaleDateFns(language);
    const { shopSlug } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const appointmentCode = searchParams.get('appointment');

    // store
    const { petStore: { pets, getAllPet }, appointmentStore: { isConfirmTab, appointmentFormData, appointmentTimesOfDay, serviceList, getUserAppointmentDetail, confirmOrCompleteAppointment, setObservable },
        shopStore: { getShopDetailForAppointment, shopAppointmentInfor },
        modalStore: { showAlertModal, hideAllModals },
        uiStore: { devicesScreen: { mobile, desktop } } } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        appointmentDate: yup.date().required('form_error_validate.required'),
        appointmentTime: yup.string().required('form_error_validate.required'),
        petCode: yup.string().required('form_error_validate.required'),
        typeAnimal: yup.number().required(),
        note: yup.string().max(2000),
        pickUpStatus: yup.boolean(),
        pickUpAddressSameOwner: yup.boolean(),
        groomingService: yup.array<ShopServiceAppointment>().nullable(),
        medicalService: yup.array<ShopServiceAppointment>().nullable(),
        otherService: yup.array<ShopServiceAppointment>().nullable()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const [month, setMonth] = useState<Date>(new Date());
    const [disbaleConfirm, setDisableConfirm] = useState<boolean>(true);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const currentMonth = new Date();
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            appointmentDate: new Date(),
            appointmentTime: '',
            petCode: '',
            typeAnimal: 0,
            note: '',
            pickUpStatus: false,
            pickUpAddressSameOwner: false,
            groomingService: [],
            medicalService: [],
            otherService: []
        }
    });

    const watchPickup = form.watch('pickUpStatus');
    const watchAppointmentDate = form.watch('appointmentDate');
    const watchApppointmentTime = form.watch('appointmentTime');
    const watchPet = form.watch('petCode');
    const watchPetType = form.watch('typeAnimal');

    // lifecycle

    useEffect(() => {
        if (isConfirmTab === false) {
            if (appointmentCode) {
                getAppointmentInforByCode(appointmentCode);
            } else {
                getDataShopForAppointment();
                getAllPetsOfUser();
            }
        }

        if (isConfirmTab && appointmentFormData && Object.keys(appointmentFormData).length > 0) {
            form.setValue('groomingService', appointmentFormData.groomingSrvList);
            form.setValue('medicalService', appointmentFormData.medicalSrvList);
            form.setValue('otherService', appointmentFormData.otherSrvList);
            form.setValue('appointmentDate', appointmentFormData.appointmentDate);
            form.setValue('appointmentTime', appointmentFormData.appointmentTime);
            form.setValue('pickUpStatus', appointmentFormData.pickUpStatus);
            form.setValue('pickUpAddressSameOwner', !appointmentFormData.pickUpAddressSameOwner);
            form.setValue('typeAnimal', appointmentFormData.typeAnimal);
            form.setValue('petCode', appointmentFormData.petCode);
            form.setValue('note', appointmentFormData.note);
        }
        scrollToTop();
    }, []);

    useEffect(() => {
        if (watchAppointmentDate && watchApppointmentTime) {
            if (form.getValues('groomingService')?.length === 0 && form.getValues('medicalService')?.length === 0 && form.getValues('otherService')?.length === 0) {
                setDisableConfirm(true);
            } else {
                setDisableConfirm(false);
            }
        } else {
            setDisableConfirm(true);
        }
    }, [watchAppointmentDate, watchApppointmentTime, form.watch('groomingService'), form.watch('medicalService'), form.watch('otherService')]);

    // useEffect(() => {

    //     getDataShopForAppointment();
    // }, [watchAppointmentDate]);

    // function
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const getAppointmentInforByCode = async (code: string) => {
        const res = await flowResult(getUserAppointmentDetail(code));
        if (res) {
            handleSetRescheduleDateData(res);
        }
    };

    const getAllPetsOfUser = async () => {
        const res = await flowResult(getAllPet());
        if (res) {
            if (res.length > 0) {
                handleChangePet(res[0].code, res);
            } else {
                showErrorPets();
            }
        }
    };
    const getDataShopForAppointment = async (isConfirmTab: boolean = false) => {
        const params = {
            slug: shopSlug,
            data: {
                isForBookAppointment: true,
                appointmentDate: formatDateTime(form.getValues('appointmentDate'), SUBMIT_MOMENT_DATE_YMD)
            }
        };
        const res = await flowResult(getShopDetailForAppointment(params, error => handleErrorResponseConfirm(error, true)));
        let result = { serviceList: [], appointmentTimesOfDay: [], pickUpStatus: false };
        if (res) {
            if (!isConfirmTab) setObservable('serviceList', res?.services || [], { isMergeObject: false });
            setObservable('appointmentTimesOfDay', res?.appointmentTimesOfDay || [], { isMergeObject: false });

            result = { serviceList: res?.services || [], appointmentTimesOfDay: res?.appointmentTimesOfDay || [], pickUpStatus: res.pickUpStatus };
        }

        return result;
    };
    const calculateTotal = useMemo(() => {
        const groomingSrv = form.getValues('groomingService') || [];
        const medicalSrv = form.getValues('medicalService') || [];
        const otherSrv = form.getValues('otherService') || [];
        const isPickupSrv = form.getValues('pickUpStatus') ? 1 : 0;
        let totalService = groomingSrv.length + medicalSrv.length + otherSrv.length + isPickupSrv;
        let totalServiceAmount = 0;
        totalServiceAmount += groomingSrv.length && groomingSrv.reduce((prev, cur) => {
            return cur.price ? prev + Number(cur.price) : prev;
        }, 0);
        totalServiceAmount += medicalSrv.length && medicalSrv.reduce((prev, cur) => {
            return cur.price ? prev + Number(cur.price) : prev;
        }, 0);
        totalServiceAmount += otherSrv.length && otherSrv.reduce((prev, cur) => {
            return cur.price ? prev + Number(cur.price) : prev;
        }, 0);

        return {
            totalService,
            totalAmount: totalServiceAmount
        };
    }, [form.watch('groomingService'), form.watch('medicalService'), form.watch('otherService'), form.watch('pickUpStatus')]);

    const groomingService: ShopServiceAppointment[] = useMemo(() => {
        let shopServiceType: ShopServiceType | 0;
        switch (watchPetType) {
            case PET_TYPE.Dog:
                shopServiceType = SHOP_SERVICE_TYPE.Dog;
                break;
            case PET_TYPE.Cat:
                shopServiceType = SHOP_SERVICE_TYPE.Cat;
                break;
            default:
                shopServiceType = 0;
                break;
        }
        return filterServiceListByType(serviceList, shopServiceType);
    }, [watchPetType, serviceList]);
    const medicalService = useMemo(() => {
        return filterServiceListByType(serviceList, SHOP_SERVICE_TYPE.Medical);
    }, [serviceList]);
    const otherService = useMemo(() => {
        return filterServiceListByType(serviceList, SHOP_SERVICE_TYPE.Other);
    }, [serviceList]);

    const handleGroomingService = (currentItem: ShopServiceAppointment, currentServiceType: ShopServiceNameType) => {
        const currentGroomingService = form.getValues('groomingService') || [];
        if (currentGroomingService?.length > 0 && currentGroomingService?.some(service => service.code === currentItem.code)) {
            form.setValue('groomingService', currentGroomingService.filter(
                service => service.code !== currentItem.code
            ));
        } else {
            const removePrevValue = currentGroomingService.filter(value => value.serviceName !== currentServiceType.toString()) || [];
            form.setValue('groomingService', currentGroomingService.length > 0 ? [...removePrevValue, currentItem] : [currentItem]);
        }
    };

    const handleChangePet = async (petCode: string, pets: any, isChange: boolean = true) => {
        if (pets?.length > 0) {
            const findPet = pets.find(pet => pet.code === petCode);
            if (findPet) {
                form.setValue('petCode', findPet.code);
                if (findPet.typeAnimal !== watchPetType) {
                    form.setValue('groomingService', []);
                }
                form.setValue('typeAnimal', findPet.typeAnimal);
                form.setValue('note', findPet.note);
            }
        }
    };

    const handleSetRescheduleDateData = (appointmentData: AppointmentInfo) => {
        const { note, appointmentDate, appointmentTime, pet, selectedServices, pickUpService } = appointmentData;
        /**
         * xử lí appointmentDate
         */
        let currentDate = new Date(new Date().setHours(0, 0, 0, 0));
        let scheduleAppointmentDate = new Date(appointmentDate);
        if (scheduleAppointmentDate.getTime() >= currentDate.getTime()) {
            form.setValue('appointmentDate', scheduleAppointmentDate);
        }
        getDataShopForAppointment().then(async ({ serviceList, appointmentTimesOfDay, pickUpStatus }: { serviceList: ShopServiceAppointment[], appointmentTimesOfDay: AppointmentDate[], pickUpStatus: boolean }) => {
            // Xử lí time
            let findTimeIndex = appointmentTimesOfDay.findIndex(item => item.startTime === appointmentTime && item.status === true);
            if (findTimeIndex >= 0) {
                form.setValue('appointmentTime', appointmentTime);
            }
            // Xử lí pet + note
            const pets = await flowResult(getAllPet());
            if (pets.length > 0) {
                let findPet = pets.find(item => item.code === pet.code);
                if (findPet) {
                    form.setValue('petCode', findPet.code);
                    form.setValue('note', note ?? (findPet.note || ''));
                    form.setValue('typeAnimal', findPet.typeAnimal);

                    // Xử lí service
                    if (pickUpStatus === true && pickUpService === true) {
                        form.setValue('pickUpStatus', true);
                    }
                    handleSetSelectedService(serviceList, selectedServices, findPet.typeAnimal);
                }
            } else {
                showErrorPets();
            }
        });
    };

    const handleSetSelectedService = (serviceList: ShopServiceAppointment[], selectedService: ShopServiceAppointment[], typePet: PetType) => {
        let groomingType: ShopServiceType | 0 = 0;
        let acceptServiceType: ShopServiceType[] = ((typePet: PetType) => {
            switch (typePet) {
                case PET_TYPE.Dog:
                    groomingType = SHOP_SERVICE_TYPE.Dog;
                    return [...Object.values(SHOP_SERVICE_TYPE).filter((value: any) => ![SHOP_SERVICE_TYPE.Additional, SHOP_SERVICE_TYPE.Cat].includes(value))];
                case PET_TYPE.Cat:
                    groomingType = SHOP_SERVICE_TYPE.Cat;
                    return [...Object.values(SHOP_SERVICE_TYPE).filter((value: any) => ![SHOP_SERVICE_TYPE.Additional, SHOP_SERVICE_TYPE.Dog].includes(value))];
                case PET_TYPE.Other:
                    return [...Object.values(SHOP_SERVICE_TYPE).filter((value: any) => ![SHOP_SERVICE_TYPE.Additional, SHOP_SERVICE_TYPE.Dog, SHOP_SERVICE_TYPE.Cat].includes(value))];
                default:
                    return [];
            }
        })(typePet);

        let filterServiceList = serviceList.filter(service => acceptServiceType.includes(service.type));
        let filterSelectedServiceList = selectedService.filter(service => acceptServiceType.includes(service.type));

        let fillServices: ShopServiceAppointment[] = filterSelectedServiceList.reduce((prev, cur) => {
            let existService = filterServiceList.find((itemService) => {
                const { code, type, petWeightFrom, petWeightTo, serviceName } = itemService;
                if ([SHOP_SERVICE_TYPE.Dog, SHOP_SERVICE_TYPE.Cat].includes(cur.type)) {
                    return code === cur.code && petWeightFrom === cur.petWeightFrom && petWeightTo === cur.petWeightTo && serviceName === cur.serviceName && type === cur.type;
                }
                if ([SHOP_SERVICE_TYPE.Medical, SHOP_SERVICE_TYPE.Other].includes(cur.type)) {
                    return code === cur.code && serviceName === cur.serviceName && type === cur.type;
                }

                return false;
            });

            if (existService) {
                if (!([SHOP_SERVICE_TYPE.Medical, SHOP_SERVICE_TYPE.Other].includes(existService?.type) &&
                    prev.findIndex(item => item.code === existService?.code && item.serviceName === existService?.serviceName && item.type === existService?.type) > -1)) {
                    prev.push(existService);
                };
            }

            return prev;
        }, [] as ShopServiceAppointment[]);

        if (fillServices.length > 0) {
            form.setValue('groomingService', filterServiceListByType(fillServices, groomingType));

            form.setValue('medicalService', filterServiceListByType(fillServices, SHOP_SERVICE_TYPE.Medical));
            form.setValue('otherService', filterServiceListByType(fillServices, SHOP_SERVICE_TYPE.Other));
        }
    };

    const handleErrorResponseConfirm = (error: any, onLoad: boolean = false) => {
        const res = error?.data;
        if (res?.data?.errorType) {
            hideAllModals();
            const message = language === 'vi' ? res.data.message.vi : res.data.message.en;
            switch (res.data.errorType) {
                case BOOK_APPOINTMENT_ERROR_TYPE.GENERAL:
                    showAlertModal({
                        id: 'not-found-shop',
                        type: 'error',
                        content: t('sentences.appointment_shop_not_exist'),
                        onSave: () => {
                            hideAllModals();
                            navigate(ROUTES.home.href);
                        },
                        saveButton: t('button.back_to_home')
                    });
                    break;
                case BOOK_APPOINTMENT_ERROR_TYPE.DATE_TIME_INVALID:
                    showAlertModal({
                        id: 'date-time-modal',
                        type: 'error',
                        content: message,
                        onSave: () => {
                            form.setValue('appointmentDate', new Date());
                            form.setValue('appointmentTime', '');
                            getDataShopForAppointment(isConfirmTab);
                            hideAllModals();
                        }
                    });
                    break;
                case BOOK_APPOINTMENT_ERROR_TYPE.PET_INVALID:
                    showAlertModal({
                        id: 'pet-modal',
                        type: 'error',
                        content: message,
                        onSave: () => {
                            form.setValue('petCode', '');
                            form.setValue('typeAnimal', 0);
                            form.setValue('note', '');
                            form.setValue('groomingService', []);
                            form.setValue('medicalService', []);
                            form.setValue('otherService', []);
                            getAllPetsOfUser();
                            hideAllModals();
                        }
                    });
                    break;
                case BOOK_APPOINTMENT_ERROR_TYPE.SERVICE_INVALID:
                    showAlertModal({
                        id: 'service-modal',
                        type: 'error',
                        content: message,
                        onSave: () => {
                            form.setValue('pickUpStatus', false);
                            form.setValue('pickUpAddressSameOwner', false);
                            form.setValue('groomingService', []);
                            form.setValue('medicalService', []);
                            form.setValue('otherService', []);
                            getDataShopForAppointment();
                        }
                    });
                    break;
            }
        }
    };

    const showErrorPets = () => {
        showAlertModal({
            id: 'no-pet',
            type: 'error',
            content: t('sentences.not_any_pet'),
            onSave: () => {
                navigate(ROUTES.home.href);
            },
            saveButton: t('button.back_to_home')
        });
    };

    const onSubmit = async (data: FormData) => {
        let groomingSrvList = data.groomingService || [];
        let medicalSrvList = data.medicalService || [];
        let otherSrvList = data.otherService || [];
        if (groomingSrvList.length > 0 || medicalSrvList.length > 0 || otherSrvList.length > 0) {
            let formData: any = {};
            let services = [...groomingSrvList, ...medicalSrvList, ...otherSrvList];
            formData = {
                appointmentDate: data.appointmentDate,
                appointmentTime: data.appointmentTime,
                services,
                pickUpStatus: data.pickUpStatus,
                pickUpAddressSameOwner: !data.pickUpAddressSameOwner,
                petCode: data.petCode,
                typeAnimal: data.typeAnimal,
                note: data.note
            };
            let convertToAPIPayload: BooKAppointmentData = {
                ...formData,
                appointmentDate: formatDateTime(formData.appointmentDate.toString(), SUBMIT_MOMENT_DATE_YMD),
                appointmentTime: formData.appointmentTime.slice(0, 5),
                isConfirm: true
            };
            formData.total = calculateTotal;

            const res = await flowResult(confirmOrCompleteAppointment({ slug: shopSlug || '', data: convertToAPIPayload }, handleErrorResponseConfirm));
            if (res) {
                if (res?.ok) {
                    setObservable('appointmentFormData', { ...formData, groomingSrvList, medicalSrvList, otherSrvList }, { isMergeObject: false });
                    setObservable('isConfirmTab', true, { isMergeObject: false });
                    onComplete && onComplete();
                }
            }
        }
    };

    return (
        <div className=''>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className='flex-col flex md:gap-4 gap-1'>
                    <div className='bg-white pb-4 md:rounded-[0.25rem]'>
                        {appointmentCode && (
                            <div className='flex gap-2 rounded-sm bg-border-6 p-2 mx-2 my-1 w-fit'>
                                <div className='size-5'>
                                    <TriangleAlert />
                                </div>
                                <p>{t('sentences.warning_reschedule')}</p>
                            </div>
                        )}
                        <FormField
                            control={form.control}
                            name='appointmentDate'
                            render={({ field }) => (
                                <FormItem>
                                    <div className='p-4 pt-1 pb-0'>
                                        <FormLabel className='font-semibold'>{t('words_title.choose_date_time')}</FormLabel>
                                    </div>
                                    <FormControl>
                                        <ReactDayPicker
                                            className='max-w-full border-none pt-2'
                                            mode='single'
                                            selected={field.value}
                                            onSelect={(e) => {
                                                e ? field.onChange(e) : field.onChange(field.value);
                                                setMonth(e);
                                                form.setValue('appointmentTime', '');
                                                getDataShopForAppointment(isConfirmTab);
                                            }}
                                            disabled={date =>
                                                date < new Date(new Date().setHours(0, 0, 0, 0))}
                                            locale={localeDateFns}
                                            month={month}
                                            onMonthChange={setMonth}
                                            components={{
                                                Nav: ({ children, className, ...props }: NavProps) => {
                                                    const [previousButton, nextButton] = React.Children.map(children, child => child) as Array<React.ReactElement<any, string | React.JSXElementConstructor<any>>>;

                                                    const isSameMonthAsCurrent = month ? isSameMonth(month, currentMonth) : false;
                                                    return (
                                                        <nav className={cn('flex items-center justify-between', className)} {...props}>
                                                            {!isSameMonthAsCurrent ? previousButton : <div></div>}
                                                            <div>{nextButton}</div>
                                                        </nav>
                                                    );
                                                }
                                            }}
                                            captionLayout='label'
                                            required
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='appointmentTime'
                            render={({ field }) => (
                                <FormItem>
                                    <div className='px-4'>
                                        <div className='h-[0.0625rem] bg-border-12'></div>
                                        <FormControl>
                                            <div className='mt-2 md:px-4 py-2 border-b border-b-border-12 grid grid-cols-4 gap-2'>
                                                {appointmentTimesOfDay.map((value, index) => {
                                                    return (
                                                        <Button
                                                            variant={field.value === value.startTime ? 'active' : 'option'}
                                                            key={index}
                                                            onClick={() => {
                                                                if (field.value === value.startTime) {
                                                                    field.onChange('');
                                                                } else field.onChange(value.startTime);
                                                            }}
                                                            disabled={!value.status}
                                                            type='button'
                                                            className={classNames('basis-1/4 h-9 border-[0.5px]', field.value === value.startTime ? '' : 'md:hover:text-active')}
                                                        >
                                                            {formatDateTime(value.startTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                                onlyTime: true,
                                                                currentformat: DISPLAY_MOMENT_TIME_HMS
                                                            })}
                                                        </Button>
                                                    );
                                                })}
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </div>
                                </FormItem>
                            )}
                        />

                    </div>
                    <div className='bg-white p-4 md:rounded-[0.25rem] flex flex-col gap-4'>
                        <FormField
                            control={form.control}
                            name='petCode'
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className='font-semibold'>{t('words_title.choose_pet')}</FormLabel>
                                    <Select
                                        onValueChange={(value) => {
                                            field.onChange(value);
                                            handleChangePet(value, pets);
                                        }}
                                        value={field.value}
                                        disabled={!(watchAppointmentDate && watchApppointmentTime)}
                                    >
                                        <FormControl>
                                            <SelectTrigger className='h-12 md:w-[26.75rem] w-full mt-1.5'>
                                                <SelectValue />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent className='max-w-[95vw] w-auto break-all whitespace-normal'>
                                            <SelectGroup>
                                                {pets.map((value, index) => {
                                                    return (
                                                        <SelectItem key={index} value={value.code}>
                                                            {value.name}
                                                        </SelectItem>
                                                    );
                                                })}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='note'
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className=''>{t('words_title.note')}</FormLabel>
                                    <div className='mt-[6px]'>
                                        <FormControl>
                                            <Textarea
                                                disabled={!(watchAppointmentDate && watchApppointmentTime)}
                                                rows={4}
                                                value={field.value}
                                                onChange={field.onChange}
                                                placeholder={t('placeholder.note_placeholder_2')}
                                                className='resize-none scrollbar scrollbar-width-3'
                                                maxLength={2000}
                                            >
                                            </Textarea>
                                        </FormControl>
                                    </div>
                                </FormItem>
                            )}
                        />
                    </div>
                    {watchAppointmentDate && watchApppointmentTime && watchPet && (
                        <>
                            {(groomingService.length > 0 || medicalService.length > 0 || otherService.length > 0) ?
                                (
                                    <div className='bg-white p-4 md:rounded-[0.25rem] flex flex-col gap-4'>
                                        <div className='font-semibold'>{t('words_title.choose_service')}</div>
                                        {shopAppointmentInfor?.pickUpStatus && (
                                            <div>
                                                <div className='h-10 flex gap-4 md:justify-start justify-between'>
                                                    <div className='flex gap-2'>
                                                        <div className='w-4 h-full'>
                                                            <PickupIcon className='h-4 w-4 mt-1' />
                                                        </div>
                                                        <div className=''>
                                                            <label className='text-[0.875rem] font-medium'>{t('words_title.pick_up_service')}</label>
                                                            <p className='text-[0.875rem] text-label-4'>
                                                                {t('words_title.appointment_price')}
                                                                :
                                                                {' '}
                                                                {shopAppointmentInfor?.pickUpPriceType && t(`select_options.${PICK_UP_PRICE[shopAppointmentInfor?.pickUpPriceType].key}`)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='md:ml-14'>
                                                        <FormField
                                                            control={form.control}
                                                            name='pickUpStatus'
                                                            render={({ field }) => (
                                                                <FormItem className='flex items-center h-10'>
                                                                    <FormControl>
                                                                        <Switch
                                                                            checked={field.value}
                                                                            onCheckedChange={(checked) => {
                                                                                if (!checked) form.setValue('pickUpAddressSameOwner', false);
                                                                                field.onChange(checked);
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                {watchPickup && (
                                                    <div className='mt-4 md:mb-0 mb-2 h-5'>
                                                        <FormField
                                                            control={form.control}
                                                            name='pickUpAddressSameOwner'
                                                            render={({ field }) => (
                                                                <FormItem>
                                                                    <div className='flex items-start gap-1'>
                                                                        <FormControl>
                                                                            <Checkbox
                                                                                checked={field.value}
                                                                                onCheckedChange={field.onChange}
                                                                                disabled={!watchPickup}
                                                                            />
                                                                        </FormControl>
                                                                        <FormLabel className=''>{t('sentences.tick_pickup_address')}</FormLabel>
                                                                    </div>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className={classNames('h-[0.0625rem] bg-border-12', shopAppointmentInfor?.pickUpStatus ? 'block' : 'hidden')}></div>
                                        {groomingService.length > 0 && (
                                            <div className={classNames((medicalService.length > 0 || otherService.length > 0) && 'md:border-b md:border-border-12 border-b-white pb-3')}>
                                                <FormField
                                                    control={form.control}
                                                    name='groomingService'
                                                    render={() => (
                                                        <FormItem className='grid md:grid-cols-4 grid-cols-1 md:gap-0 gap-2'>
                                                            {[
                                                                SHOP_SERVICE_NAME_TYPE.Cut,
                                                                SHOP_SERVICE_NAME_TYPE.Shower,
                                                                SHOP_SERVICE_NAME_TYPE.Combo,
                                                                SHOP_SERVICE_NAME_TYPE.Hotel
                                                            ].map((serviceType) => {
                                                                const filterService = groomingService.filter(item => item.serviceName === serviceType.toString()) || [];
                                                                return (
                                                                    filterService.length > 0 ?
                                                                        (
                                                                            <div key={serviceType}>
                                                                                <div><ServiceLabel title={t(`select_options.${SHOP_SERVICE_NAME[serviceType].key}`)} /></div>
                                                                                <div className='md:block flex gap-2 overflow-x-scroll scrollbar scrollbar-height-2 pb-2 md:border-none border-b border-b-border-8'>
                                                                                    {filterService.map((itemService, index) => {
                                                                                        return (
                                                                                            <FormField
                                                                                                key={index}
                                                                                                control={form.control}
                                                                                                name='groomingService'
                                                                                                render={({ field }) => {
                                                                                                    return (
                                                                                                        <FormItem className='mt-3'>
                                                                                                            <FormControl>
                                                                                                                <Button
                                                                                                                    variant={field.value?.some(service => service?.code === itemService.code) ? 'active' : 'option'}
                                                                                                                    onClick={() => {
                                                                                                                        handleGroomingService(itemService, serviceType);
                                                                                                                    }}
                                                                                                                    type='button'
                                                                                                                    className='flex flex-col gap-2 p-2 items-start lg:w-[11.875rem] md:w-[10.625rem] w-[11.25rem] h-[3.75rem] group'
                                                                                                                >
                                                                                                                    {serviceAppointmentButton({
                                                                                                                        petWeightFrom: itemService.petWeightFrom,
                                                                                                                        petWeightTo: itemService.petWeightTo,
                                                                                                                        price: itemService.price || 0,
                                                                                                                        active: field.value?.some(service => service?.code === itemService.code),
                                                                                                                        t
                                                                                                                    })}
                                                                                                                </Button>
                                                                                                            </FormControl>
                                                                                                        </FormItem>
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        ) :
                                                                        <></>
                                                                );
                                                            })}

                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {medicalService.length > 0 && (
                                            <>
                                                <div className='border-b border-border-12 pb-3'>
                                                    <FormField
                                                        control={form.control}
                                                        name='medicalService'
                                                        render={() => (
                                                            <FormItem className='w-full'>
                                                                <div>
                                                                    <div><ServiceLabel title={t('select_options.shop_service_medical_appointment')} /></div>
                                                                    <div className='flex flex-col gap-3 mt-2'>
                                                                        {medicalService.map((itemService, index) => {
                                                                            return (
                                                                                <FormField
                                                                                    key={index}
                                                                                    control={form.control}
                                                                                    name='medicalService'
                                                                                    render={({ field }) => {
                                                                                        return (
                                                                                            <FormItem>
                                                                                                <div className={classNames('flex items-start gap-2 pb-3 w-full', index !== medicalService.length - 1 && 'border-b border-dashed border-b-border-8')}>
                                                                                                    <FormControl>
                                                                                                        <Checkbox
                                                                                                            checked={field.value?.some(value => value.code === itemService.code)}
                                                                                                            onCheckedChange={(checked) => {
                                                                                                                return checked ?
                                                                                                                    field.onChange(
                                                                                                                        field.value?.length ? [...field.value, itemService] : [itemService]
                                                                                                                    ) :
                                                                                                                    field.onChange(
                                                                                                                        field.value?.filter(
                                                                                                                            value => value.code !== itemService.code
                                                                                                                        )
                                                                                                                    );
                                                                                                            }}
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                    <FormLabel className='flex flex-col gap-2 flex-1'>
                                                                                                        <p className='text-[0.875rem] leading-4 font-normal'>{itemService.serviceName}</p>
                                                                                                        <p className='text-[0.875rem]'>
                                                                                                            <span className='text-label-4'>
                                                                                                                {t('words_title.appointment_price')}
                                                                                                                :
                                                                                                            </span>
                                                                                                            <span className='ml-1 font-semibold'>
                                                                                                                {convertServicePrice(itemService.price, t)}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </FormLabel>
                                                                                                </div>

                                                                                            </FormItem>
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {otherService.length > 0 && (
                                            <>
                                                <div className=''>
                                                    <FormField
                                                        control={form.control}
                                                        name='otherService'
                                                        render={() => (
                                                            <FormItem className='w-full'>
                                                                <div>
                                                                    <div><ServiceLabel title={t('select_options.shop_service_other_appointment')} /></div>
                                                                    <div className='flex flex-col gap-3 w-full mt-2'>
                                                                        {otherService.map((itemService, index) => {
                                                                            return (
                                                                                <FormField
                                                                                    key={index}
                                                                                    control={form.control}
                                                                                    name='otherService'
                                                                                    render={({ field }) => {
                                                                                        return (
                                                                                            <FormItem>
                                                                                                <div className={classNames('flex gap-2 pb-3 w-full', index !== otherService.length - 1 && 'border-b border-dashed border-b-border-8')}>
                                                                                                    <FormControl>
                                                                                                        <Checkbox
                                                                                                            checked={field.value?.some(value => value.code === itemService.code)}
                                                                                                            onCheckedChange={(checked) => {
                                                                                                                return checked ?
                                                                                                                    field.onChange(
                                                                                                                        field.value && field.value?.length > 0 ? [...field.value, itemService] : [itemService]
                                                                                                                    ) :
                                                                                                                    field.onChange(
                                                                                                                        field.value?.filter(
                                                                                                                            value => value.code !== itemService.code
                                                                                                                        )
                                                                                                                    );
                                                                                                            }}
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                    <FormLabel className='flex flex-col gap-2 flex-1'>
                                                                                                        <p className='text-[0.875rem] leading-4 font-normal leading-0'>
                                                                                                            {itemService.isMedicalService && `(${t('words_title.label_medical')})`}
                                                                                                            {' '}
                                                                                                            {itemService.serviceName}
                                                                                                        </p>
                                                                                                        <p className='text-[0.875rem]'>
                                                                                                            <span className='text-label-4'>
                                                                                                                {t('words_title.appointment_price')}
                                                                                                                :
                                                                                                            </span>
                                                                                                            <span className='ml-1 font-semibold'>
                                                                                                                {convertServicePrice(itemService.price, t)}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </FormLabel>
                                                                                                </div>

                                                                                            </FormItem>
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            );
                                                                        })}
                                                                    </div>

                                                                </div>

                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ) :
                                (
                                    <div className='min-h-16 bg-white p-4 flex justify-center items-center rounded-[0.25rem]'>{handleNoServiceAvailable(language)}</div>
                                )}
                            <div className='w-full flex md:justify-end md:static relative md:z-auto z-[48]'>
                                <div className='md:w-[30rem] w-full bg-white px-4 py-3 md:rounded-[0.25rem]'>
                                    <div className='border-b border-dashed border-b-border-8 py-2 flex flex-col gap-3'>
                                        <div className='flex justify-between text-label-4'>
                                            <label>{t('words_title.total_selected_service')}</label>
                                            <p>{calculateTotal.totalService}</p>
                                        </div>
                                        <div className='flex justify-between items-center font-semibold'>
                                            <label className=''>{t('words_title.expected_total_amount')}</label>
                                            <p className='text-[1.3125rem]'>
                                                {convertServicePrice(calculateTotal.totalAmount, t)}
                                            </p>
                                        </div>
                                        <div className='flex gap-2 rounded-sm bg-border-6 p-2'>
                                            <div className='size-5'>
                                                <TriangleAlert />
                                            </div>
                                            <span className='text-small'>
                                                {shortenParagraph(t('sentences.expected_total_amount_note'), 120, isExpanded).value}
                                                {
                                                    shortenParagraph(t('sentences.expected_total_amount_note'), 120, isExpanded).isEllipsis && (
                                                        <button
                                                            type='button'
                                                            onClick={toggleExpand}
                                                            className='text-active text-[0.8125rem] text-blue-500 no-underline'
                                                        >
                                                            {isExpanded ? t('button.view_less') : t('button.view_more')}
                                                        </button>
                                                    )
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className='mt-4 text-right'>
                                        <Button
                                            type='submit'
                                            disabled={disbaleConfirm}
                                            variant='submit'
                                            className='w-full'
                                        >
                                            {t('words_title.confirm')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                </form>
            </Form>
        </div>
    );
});

const handleNoServiceAvailable = (locale: string) => {
    switch (locale) {
        case 'vi':
            return (
                <p>
                    Chúng tôi không tìm thấy dịch vụ phù hợp với thú cưng của bạn trong cửa hàng này.
                    Vui lòng chọn 1 bé khác hoặc
                    {' '}
                    <span className='text-link-1'><Link to={ROUTES.home.href}>quay về trang chủ</Link></span>
                    {' '}
                    và chọn 1 cửa hàng khác.
                </p>
            );
        case 'en':
            return (
                <p>
                    No available services were found for your pet.
                    Please choose another pet, or you can
                    {' '}
                    <span className='text-link-1'><Link to={ROUTES.home.href}>back to the homepage</Link></span>
                    {' '}
                    and select a different shop .
                </p>
            );
    }
};

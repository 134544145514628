import { Button } from '@/components/common/Button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { useStore } from '@/hooks/useStore';
import { CirclePlus } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HistoryForm from '../components/HistoryForm';
import PetNoteItem from '../components/PetNoteItem';
import { APPOINTMENT_STATUS_TYPE } from '@/types/enums';
import { formatDateTime } from '@/utils/datetime';
import { SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';

interface ServiceHistoryProps {
    type: number
}

export default observer(function PetNoteList({ type }: ServiceHistoryProps) {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        modalStore: { showModal },
        petStore: { pets, getDetailPet },
        appointmentStore: { getAllUserAppointments, myAppointmentPaging },
        petCareStore: { getAllPetCareHistory, petNotePaging, setObservable }
    } = useStore();

    // state
    const [selectValue, setSelectValue] = useState<string>(pets[0].code);
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + 14);

    // lifecycle
    useEffect(() => {
        getAllUserAppointments(
            {
                petCode: selectValue,
                status: APPOINTMENT_STATUS_TYPE.Confirmed,
                fromAppointmentDate: formatDateTime(currentDate, SUBMIT_MOMENT_DATE_YMD),
                toAppointmentDate: formatDateTime(endDate, SUBMIT_MOMENT_DATE_YMD),
                sorting: [
                    { sortKey: 'appointment_date', sortDir: 'ASC' },
                    { sortKey: 'appointment_time', sortDir: 'ASC' }
                ]
            },
            myAppointmentPaging
        );
    }, [selectValue]);

    useEffect(() => {
        getAllPetCareHistory(petNotePaging, { petCode: selectValue, type: type });
        return () => {
            setObservable('petNotePaging', { pagination: { ...petNotePaging.pagination, pageIndex: 0 } }, { isMergeObject: true });
        };
    }, [selectValue, type]);

    useEffect(() => {
        getDetailPet(selectValue);
    }, [selectValue, type]);

    // functions
    const handleSelect = (value: string) => {
        setSelectValue(value);
        setObservable('petNotePaging', { pagination: { ...petNotePaging.pagination, pageIndex: 0 } }, { isMergeObject: true });
    };

    const showModalAddHistory = () => {
        showModal({
            id: 'add-history',
            title: t('words_title.add_history'),
            titleClassName: 'title-3 text-center',
            content: <HistoryForm typeService={type} />,
            footerClassName: 'p-0'
        });
    };

    return (
        <>
            <div className='flex justify-between items-center gap-2 px-4 pb-4 pt-4 md:py-4 bg-white mb-1'>
                <Select className='flex-auto' defaultValue={pets[0].code} onValueChange={handleSelect}>
                    <SelectTrigger className='h-[2.375rem] flex-auto xsm:w-80'>
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent className='max-w-[95vw] xsm:max-w-[320px] overflow-hidden'>
                        {pets.map((value, index) => {
                            return (
                                <SelectItem value={value.code} key={index}>
                                    <span className='line-clamp-1'>{value.name}</span>
                                </SelectItem>
                            );
                        })}
                    </SelectContent>
                </Select>
                <Button
                    className='text-[0.8125rem] md:text-[0.875rem] h-[2.375rem] px-2 md:px-4 md:py-2 md:flex gap-1 items-center'
                    variant='default'
                    onClick={() =>
                        showModalAddHistory()}
                >
                    <CirclePlus className='text-white' />
                    <span className='text-white'>{t('words_title.add_history')}</span>
                </Button>
            </div>
            <div className='flex flex-col gap-1 md:gap-3 bg-background-2'>
                <PetNoteItem type={type} />
            </div>
        </>
    );
});

import { observer } from 'mobx-react-lite';
import ShopChannelBg from '@/assets/images/shopChannelBg.png';
import { Button } from '@/components/common/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';

export default observer(function ShopChannelNewUser() {
    // state
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();

    // function
    const handleClickUpdateShopInfo = () => {
        navigate(ROUTES.myShop.href, { state: { previousUrl: pathname } });
    };

    return (
        <div className='flex flex-col items-center py-12 pb-6 gap-4 text-center'>
            <img src={ShopChannelBg} alt='shop channel background' className='md:h-[354px] h-[163px] w-auto object-contain' />
            <div className='text-center max-w-[360px] flex flex-col gap-4'>
                <h3 className='text-[0.9375rem] font-semibold leading-[1.3125rem]'>{t('words_title.shop_channel_new_user')}</h3>
                <p className='text-text-8 text-small'>{t('sentences.shop_channel_new_user_desc')}</p>
            </div>
            <Button variant='default' className='px-2 py-2.5' onClick={handleClickUpdateShopInfo}>{t('button.edit_shop_info')}</Button>
        </div>
    );
});

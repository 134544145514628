import * as yup from 'yup';
import { FILE_SIZE_IMAGE, FILE_SIZE_MENU } from '@/configs/constants';

declare module 'yup' {
    interface Schema {
        checkFileImagesOfArrayExt(message): this,
        checkFileImagesOfExt(message): this,
        checkSizeFile(message): this
    }
}

yup.addMethod(yup.array, 'checkFileImagesOfArrayExt', function (message) {
    return this.test('checkFileImagesOfArrayExt', message, function (files) {
        if (!files || !(files instanceof Array)) {
            return true;
        }
        let extFiles: string[] = [];
        files.forEach((file) => {
            if (file instanceof File) {
                file = file.name;
            }

            if (file instanceof String || typeof file === 'string') {
                const ext = file.split('.').pop()?.toLowerCase();
                if (ext) {
                    extFiles.push(ext);
                }
            }
        });

        const allowsExtensions = ['jpg', 'png', 'jpeg'];
        return extFiles.every(extFile => allowsExtensions.includes(extFile));
    });
});

yup.addMethod(yup.mixed, 'checkFileImagesOfExt', function (message) {
    return this.test('checkFileImagesOfExt', message, function (file) {
        if (!file) {
            return true;
        }
        let extFiles: string = '';
        if (file instanceof File) {
            file = file.name;
        }

        if (file instanceof String || typeof file === 'string') {
            const ext = file.split('.').pop()?.toLowerCase();
            if (ext) {
                extFiles = ext;
            }
        }

        const allowsExtensions = ['pdf'];
        return allowsExtensions.includes(extFiles);
    });
});

yup.addMethod(yup.mixed, 'checkSizeFile', function (message, type = 'image') {
    return this.test('checkSizeFile', message, function (file) {
        if (!file) return true;
        let size;
        if (file instanceof File) {
            size = file.size;
        }
        return type === 'image' ? size <= FILE_SIZE_IMAGE : size <= FILE_SIZE_MENU;
    });
});

// yup.addMethod(yup.array, 'checkSizeListFile', function (message, type = 'image') {
//     return this.test('checkSizeListFile', message, function (files) {
//         if (!files) return true;
//         return type === 'image' ? size <= FILE_SIZE_IMAGE : size <= FILE_SIZE_MENU;
//     });
// });

export default yup;

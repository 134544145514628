import { Button } from '@/components/common/Button';
import { DISPLAY_MOMENT_DATE_DMYHM_AM_PM, MAX_LENGTH_TEXT_MOBILE, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import ReviewForm from '@/pages/shop-detail/components/components/ReviewForm';
import { Review } from '@/types/review';
import { formatDateTime } from '@/utils/datetime';
import { toastify } from '@/utils/toastify';
import { shortenParagraph } from '@/utils/utils';
import { SquarePen, Star, Trash2 } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
type Prop = {
    data: Review
};
export default observer(function ReviewItem({ data }: Prop) {
    // hook
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        modalStore: { showModal, showAlertModal, modalWaringAlertOptions },
        reviewStore: { deleteReview, getAllMyReview }
    } = useStore();

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    // function
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleEditReview = (id: number) => {
        showModal({
            id: 'edit-review',
            title: t('words_title.write_review'),
            titleClassName: 'title-3 text-center',
            content: <ReviewForm id={id} />,
            footerClassName: 'p-0'
        });
    };

    const handleDeleteMyReview = (id: number) => {
        if (id) {
            showAlertModal(modalWaringAlertOptions({
                content: t('words_title.delete_review'),
                onSave: async () => {
                    const res = await flowResult(deleteReview(id));
                    if (res) {
                        toastify('alert-success', t('messages.delete_success'));
                        getAllMyReview();
                    }
                }
            }));
        }
    };

    return (
        <>
            <div className='p-0 md:px-4 md:mb-6 w-full'>
                <div className='px-4 py-3 bg-white md:border md:border-border-white md:rounded-[4px]'>
                    <div className='flex space-x-2'>
                        <img onClick={() => navigate(`${ROUTES.shopDetail.href}/${data.shop.slug}`)} className='size-10 max-w-10 max-h-10 rounded-full cursor-pointer' src={data?.shop?.images[0]} alt='' />
                        <div className='flex-1'>
                            <div className='flex md:gap-0 justify-between items-start'>
                                <div className='point-375:w-[70%] md:w-[85%] point-920:w-2/3 lg:w-[80%] xl:w-[85%] line-clamp-2 text-text-3 col-span-2 font-medium cursor-pointer' onClick={() => navigate(`${ROUTES.shopDetail.href}/${data.shop.slug}`)}>{data.shop.name}</div>
                                <div className='point-375:w-[30%] md:w-[15%] point-920:w-1/3 lg:w-[20%] xl:w-[15%] flex space-x-2 justify-end text-small'>
                                    <Button onClick={() => handleEditReview(data.id)} className='flex items-center group h-[1.125rem]' variant='link'>
                                        <SquarePen className='group-hover:text-active size-[0.75rem] text-text-3' />
                                        <span className='ml-0.5 text-text-3 group-hover:text-active text-small'>{t('button.edit')}</span>
                                    </Button>
                                    <Button onClick={() => handleDeleteMyReview(data.id)} className='flex items-center group h-[1.125rem]' variant='link'>
                                        <Trash2 className='group-hover:text-active size-[0.75rem] text-text-3' />
                                        <span className='ml-0.5 text-text-3 group-hover:text-active text-small'>{t('button.delete_review')}</span>
                                    </Button>
                                </div>
                            </div>
                            <div className='flex justify-between items-center'>
                                <span className='text-icon-3 flex space-x-1'>
                                    {
                                        Array.from({ length: 5 }, (_, i) => {
                                            const ratingValue = Number(data?.serviceQuality);
                                            if (i < Math.floor(ratingValue)) {
                                                return <Star key={i} className='fill-current text-icon-3 size-4' />;
                                            } else if (i === Math.floor(ratingValue) && ratingValue % 1 !== 0) {
                                                return (
                                                    <div key={i} className='relative inline-block size-4'>
                                                        <Star className='fill-current text-gray-300 size-4' />
                                                        <div
                                                            className='absolute top-0 left-0 h-full overflow-hidden'
                                                            style={{
                                                                width: `${(ratingValue % 1) * 100}%`
                                                            }}
                                                        >
                                                            <Star className='fill-current text-icon-3 size-4' />
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return <Star key={i} className='fill-current text-gray-300 size-4' />;
                                            }
                                        })
                                    }
                                </span>
                                <div className='text-text-4 flex justify-end text-small'>
                                    <span>{formatDateTime(data?.createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`border-b border-dotted w-full mt-1.5 ${data.comment.length > 0 || data.images.length > 0 ? 'block' : 'hidden'}`}></div>
                    <div className='flex overflow-x-auto scrollbar-height-2 scrollbar-width-4 justify-start items-center sm:justify-normal gap-2 mt-2'>
                        {
                            data.images.map((image, i) => (
                                <img key={i} className='w-28 h-20 rounded-md object-cover mb-1' src={image} alt='' />
                            ))
                        }
                    </div>
                    {
                        data.comment.length > 0 && (
                            <div className={`px-2 py-1 bg-border-1 rounded-sm ${data.images.length > 0 ? 'mt-1' : '0'}`}>
                                <div className='flex-1'>
                                    <p className='text-text-4 whitespace-pre-line'>
                                        {shortenParagraph(data?.comment, MAX_LENGTH_TEXT_MOBILE, isExpanded).value}
                                        {
                                            shortenParagraph(data?.comment, MAX_LENGTH_TEXT_MOBILE, isExpanded).isEllipsis && (
                                                <button
                                                    onClick={toggleExpand}
                                                    className='text-active text-[0.8125rem] text-blue-500 no-underline'
                                                >
                                                    {isExpanded ? t('button.view_less') : t('button.view_more')}
                                                </button>
                                            )
                                        }
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
});

import ApiService from './ApiService';

export default class TopicApi extends ApiService {
    constructor() {
        super('topics');
    }

    getAllTopic(payload) {
        return this.get('', payload);
    }

    getTopicDetail({ slug, ...otherPayload }) {
        return this.get(slug, otherPayload);
    }

    likeTopic(slug) {
        return this.put(`likes/${slug}`);
    }

    createComment(payload) {
        const { slug, content } = payload;
        return this.post(`comments/${slug}`, { content });
    }

    getAllComment(payload) {
        const { slug, paging } = payload;
        return this.get(`comments/${slug}`, paging);
    }

    createTopic(payload) {
        return this.post('', payload, { headers: { 'Content-Type': 'multipart/form-data' } });
    }

    editTopic(payload) {
        const { slug, ...rest } = payload;
        return this.put(`${slug}`, rest, { headers: { 'Content-Type': 'multipart/form-data' } });
    }

    deleteTopic(slug) {
        return this.delete(slug);
    }
}

import { ROUTES } from '@/configs/constants';
import { cn } from '@/utils/utils';
import { ChevronLeftIcon } from 'lucide-react';
import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface HeaderMobileFormat3Props {
    title: string,
    className?: string,
    defaultNavigateUrl?: string,
    rightContent?: ReactElement,
    handleOnClickBySelf?: Function,
    showBackIcon?: boolean
}

export default function HeaderMobileFormat3({
    title,
    className,
    defaultNavigateUrl,
    rightContent,
    handleOnClickBySelf,
    showBackIcon = true
}: HeaderMobileFormat3Props) {
    // hooks
    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    // function
    const onClickGoBack = () => {
        if (handleOnClickBySelf) {
            handleOnClickBySelf();
        } else if (state?.previousUrl) {
            navigate(state.previousUrl);
        } else if (defaultNavigateUrl) {
            navigate(defaultNavigateUrl);
        } else {
            navigate(ROUTES.home.href, { state: { previousUrl: pathname } });
        }
    };

    return (
        <div className={cn('w-full h-13 bg-white flex items-center justify-between relative', className)}>
            {
                showBackIcon ?
                    (
                        <ChevronLeftIcon className='w-6 h-6 cursor-pointer' onClick={onClickGoBack} />
                    ) :
                    (
                        <div></div>
                    )
            }
            <div className='title-2 text-center absolute z-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 truncate w-[calc(100%-4rem)]'>{title}</div>
            {rightContent}
        </div>
    );
};

import { ReviewApi } from '@/apis';
import { ResponseData } from '@/types/http';
import { GetAllMyReviewResponse, GetAllReviewForReviewManagementResponse, ReportReviewRequest } from '@/types/http-payload/review';
import { Review, ReviewDetail, ReviewForReviewManagement, ShopInfoForReviewManagement, TotalByServiceQuality } from '@/types/review';
import { TableState } from '@tanstack/react-table';
import { flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';

export default class ReviewStore extends BaseStore {
    reviewDetail: ReviewDetail = {} as ReviewDetail;
    api: ReviewApi;
    totalPage: number = 0;
    allMyReview: Review[] = [];
    allReviewForReviewManagement: ReviewForReviewManagement[] = [];
    shopInfo: ShopInfoForReviewManagement = {} as ShopInfoForReviewManagement;
    totalByServiceQuality: TotalByServiceQuality = {} as TotalByServiceQuality;
    params: { [key: string]: any } = {};

    arrayIdReport: number[] = [];

    myReviewPaging: TableState = {
        pagination: {
            pageIndex: 0,
            pageSize: 20
        },
        sorting: [{}]
    } as TableState;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            totalPage: observable,
            allMyReview: observable,
            reviewDetail: observable,
            myReviewPaging: observable,
            params: observable,
            arrayIdReport: observable,
            allReviewForReviewManagement: observable,
            shopInfo: observable,
            totalByServiceQuality: observable,
            getReviewDetail: flow.bound,
            getAllMyReview: flow.bound,
            editReviewDetail: flow.bound,
            deleteReview: flow.bound,
            getAllReviewForReviewManagement: flow.bound,
            markTopReview: flow.bound,
            reportReview: flow.bound
        });

        this.api = new ReviewApi();
        this.paging = this.myReviewPaging;
    }

    * getAllMyReview(paging?: TableState) {
        try {
            const payload = { ...this.convertPagingFromTableToRequest(paging ?? this.myReviewPaging) };
            const res: ResponseData<GetAllMyReviewResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllMyReview, payload);
            if (res?.ok) {
                this.paging = {
                    ...this.myReviewPaging,
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalRecord = res?.data?.pagination?.totalRecord;
                this.totalPage = res?.data?.pagination?.totalPage;
                this.allMyReview = res?.data?.elements || [];
                return res;
            }
        } catch (error) {
        }
    }

    * getReviewDetail(id: number) {
        try {
            const res: ResponseData<ReviewDetail> = yield this.rootStore.apiStore.call(this.api, this.api.getReviewDetail, id);
            if (res?.ok) {
                this.reviewDetail = res.data;
                return res?.data;
            }
            return false;
        } catch (error) {
        }
    }

    * editReviewDetail(payload) {
        try {
            const res: ResponseData<ReviewDetail> = yield this.rootStore.apiStore.call(this.api, this.api.editReviewDetail, payload, { hideLoading: false });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
        }
    }

    * deleteReview(id: number) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.deleteReview, id, { hideLoading: false });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
        }
    }

    * getAllReviewForReviewManagement(payload?: any, paging?: TableState) {
        try {
            payload = { ...this.convertPagingFromTableToRequest(paging ?? this.myReviewPaging), ...payload };
            const res: ResponseData<GetAllReviewForReviewManagementResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllReviewForReviewManagement, payload);
            if (res?.ok) {
                this.paging = {
                    ...this.myReviewPaging,
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalRecord = res?.data?.pagination?.totalRecord;
                this.totalPage = res?.data?.pagination?.totalPage;
                this.allReviewForReviewManagement = res?.data?.elements || [];
                this.shopInfo = res?.data?.shopInfo;
                this.totalByServiceQuality = res?.data?.totalByServiceQuality;
                return res;
            }
        } catch (error) {
        }
    }

    *markTopReview(id: number) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.markTopReview, id, { hideLoading: true });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
        }
    }

    *reportReview(payload: ReportReviewRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.reportReview, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
        }
    }
}

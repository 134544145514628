import { Button } from '@/components/common/Button';
import { InputWithIcon } from '@/components/common/Input';
import { convertNumber, sumTotalByServiceQuality } from '@/utils/utils';
import classNames from 'classnames';
import { Search, Star } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReviewItem from './ReviewItem';
import { useStore } from '@/hooks/useStore';
import { useTranslation } from 'react-i18next';
import ReportForm from './ReportForm';
import debounce from 'lodash.debounce';

export default observer(function ReviewList() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        modalStore: { showModal },
        reviewStore: { arrayIdReport, allReviewForReviewManagement, totalByServiceQuality, setObservable, params }
    } = useStore();

    // state
    const [keyword, setKeyword] = useState<string>('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const prevKeywordRef = useRef(keyword);

    const debouncedSearch = useCallback(
        debounce((searchTerm) => {
            const trimmedKeyword = searchTerm.trim();
            setObservable(
                'params',
                { ...params, petOwnerName: trimmedKeyword || undefined },
                { isMergeObject: true }
            );
        }, 800),
        [keyword]
    );

    // lifecycle
    useEffect(() => {
        if (prevKeywordRef.current && !keyword) {
            debouncedSearch('');
        } else if (keyword) {
            debouncedSearch(keyword);
        }

        prevKeywordRef.current = keyword;

        return () => {
            debouncedSearch.cancel();
        };
    }, [keyword]);

    // functions
    const handleSelect = (index) => {
        setSelectedIndex(index);
        setObservable('params', { ...params, serviceQuality: index !== -1 ? index : undefined }, { isMergeObject: true });
    };

    const handleReport = () => {
        showModal({
            id: 'report-review',
            title: t('words_title.report_comment'),
            titleClassName: 'title-3 text-center',
            content: <ReportForm arrayId={arrayIdReport} />,
            footerClassName: 'p-0'
        });
    };

    return (
        <div className='bg-white mt-1 md:mt-2 py-4 md:py-6 rounded-sm'>
            <div className='md:flex md:justify-between md:items-center px-4'>
                <p className='text-[1.0625rem] font-semibold text-title-1 text-nowrap transition-transform'>{t('words_title.review_list')}</p>
                <div className='md:hidden block mt-2'>
                    <div>
                        <InputWithIcon
                            className='relative placeholder:text-placeholder-1 h-[2.1875rem] w-full'
                            type='text'
                            placeholder={t('placeholder.search_by_pet_owner_name')}
                            icon={(
                                <div
                                    className='absolute right-4 top-1/2 -translate-y-1/2'
                                >
                                    <Search strokeWidth={2} className='size-[1.1875rem] text-placeholder-1 cursor-pointer hover:text-active' />
                                </div>
                            )}
                            value={keyword}
                            onChange={e => setKeyword(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className='px-0 md:px-4'>
                <hr className='mt-5 border-t-[2px] md:border-t' />
            </div>
            <div className='mt-4 px-4 border-dashed'>
                <div className='flex md:flex space-x-3 overflow-hidden overflow-x-auto scrollbar scrollbar-height-2 pb-1'>
                    <div
                        onClick={() => handleSelect(-1)}
                        className={classNames(
                            'min-w-20 flex gap-1 items-center justify-center p-1.5 rounded-sm cursor-pointer transition-all',
                            selectedIndex === -1 ? 'text-active border border-active' : 'text-text-13 bg-border-1 border border-border-1'
                        )}
                    >
                        <p className='text-nowrap'>{t('words_title.all')}</p>
                        <p>{`(${convertNumber(sumTotalByServiceQuality(totalByServiceQuality).totalReviews)})`}</p>
                    </div>
                    {Array.from({ length: 5 }, (_, i) => {
                        const rating = (5 - i);
                        const total = totalByServiceQuality[rating] || 0;

                        return (
                            <div
                                key={i}
                                onClick={() => handleSelect(rating)}
                                className={classNames(
                                    'min-w-20 flex gap-1 items-center justify-center p-1.5 rounded-sm cursor-pointer transition-all',
                                    selectedIndex === rating ? 'text-active border border-active' : 'text-text-13 bg-border-1 border border-border-1'
                                )}
                            >
                                <p>{rating}</p>
                                <Star className='text-icon-3 fill-current size-4' />
                                <p className='text-text-13'>
                                    {`(${convertNumber(total)})`}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className='hidden md:flex items-center justify-between mt-4 px-4'>
                <div>
                    <InputWithIcon
                        className='relative placeholder:text-placeholder-1 placeholder:text-small h-[2.1875rem] w-[14rem]'
                        type='text'
                        placeholder={t('placeholder.search_by_pet_owner_name')}
                        icon={(
                            <div
                                className='absolute right-3 top-1/2 -translate-y-1/2'
                            >
                                <Search strokeWidth={2} className='text-placeholder-1 cursor-pointer hover:text-active' />
                            </div>
                        )}
                        value={keyword}
                        onChange={e => setKeyword(e.target.value)}
                    />
                </div>
                <Button
                    disabled={arrayIdReport.length === 0}
                    onClick={handleReport}
                    variant='clear'
                    className='font-normal h-[2.1875rem] px-3'
                >
                    {t('button.report_selected_reviews')}
                </Button>
            </div>
            <div className='mt-4 flex flex-col md:gap-4 md:px-4'>
                {
                    allReviewForReviewManagement.length > 0 && allReviewForReviewManagement.map((review, index) => (
                        <ReviewItem
                            key={index}
                            review={review}
                            isChecked={arrayIdReport.includes(review.id)}
                        />
                    ))
                }
                {
                    allReviewForReviewManagement.length === 0 && (
                        <div className='text-center text-text-8 font-medium leading-[21px]'>
                            {t('words_title.no_reviews')}
                        </div>
                    )
                }
            </div>
        </div>
    );
});

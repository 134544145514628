import { NavLink } from 'react-router-dom';
import { ActivityIcon, ForumIcon, MyAccountIcon, PetNoteIcon } from '../icons';
import Nearby from '@/assets/images/nearby.svg';
import { ROUTES } from '@/configs/constants';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';

export default function FooterMenuMobile() {
    // hooks
    const { t } = useTranslation();

    // store
    const { authStore: { onCheckAuthenRedirect } } = useStore();

    return (
        <div className='fixed z-50 bottom-0 left-0 w-full h-15.5 bg-white shadow-[0px_8px_32px_0px_#00000026]'>
            <div className='h-full text-small flex items-center justify-between px-2 point-375:px-4 point-450:px-8 point-600:px-12'>
                <div className='flex justify-center items-center h-full'>
                    <NavLink to={onCheckAuthenRedirect(ROUTES.signIn.href, ROUTES.petNote.href)} className='h-11 flex flex-col justify-between items-center'>
                        {({ isActive }) => (
                            <>
                                <PetNoteIcon isActive={isActive} />
                                <span className={classNames(isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.pet_note')}</span>
                            </>
                        )}
                    </NavLink>
                </div>
                <div className='flex justify-center items-center h-full'>
                    <NavLink to={onCheckAuthenRedirect(ROUTES.signIn.href, ROUTES.activity.href)} className='h-11 flex flex-col justify-between items-center'>
                        {({ isActive }) => (
                            <>
                                <ActivityIcon isActive={isActive} />
                                <span className={classNames(isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.activity')}</span>
                            </>
                        )}
                    </NavLink>
                </div>
                <div className='flex justify-center items-center h-full w-13'>
                    <NavLink to={ROUTES.nearBy.href} className='h-11 flex flex-col justify-between items-center -mt-[0.4rem]'>
                        {({ isActive }) => (
                            <>
                                <img src={Nearby} alt='nearby' />
                                <span className={classNames(isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.nearby')}</span>
                            </>
                        )}
                    </NavLink>
                </div>
                <div className='flex justify-center items-center h-full'>
                    <NavLink to={ROUTES.forum.href} className='h-11 flex flex-col justify-between items-center'>
                        {({ isActive }) => (
                            <>
                                <ForumIcon isActive={isActive} />
                                <span className={classNames(isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.forum')}</span>
                            </>
                        )}
                    </NavLink>
                </div>
                <div className='flex justify-center items-center h-full'>
                    <NavLink to={ROUTES.myAccount.href} className='h-11 min-w-11 flex flex-col justify-between items-center'>
                        {({ isActive }) => (
                            <>
                                <MyAccountIcon isActive={isActive} />
                                <span className={classNames(isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.my_account_1')}</span>
                            </>
                        )}
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import Paginate from '@/components/pagination/Paginate';
import { DISPLAY_MOMENT_DATE_DMYHM_AM_PM } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { formatDateTime } from '@/utils/datetime';
import { getLastNameInitial } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CommentProps {
    slug: string
}

export const Comment = observer(({ slug }: CommentProps) => {
    // hooks
    const { t } = useTranslation();

    // store
    const { topicStore: { comments, totalCommentPage, totalCommentRecord, commentPaging, createComment, getAllComment },
        authStore: { token }
    } = useStore();

    // lifecycle
    useEffect(() => {
        getAllComment(slug);
    }, []);

    // validate Schema
    const validateSchema = yup.object().shape({
        comment: yup
            .string()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            comment: ''
        }
    });

    // functions
    const handlePageChange = (selected: number) => {
        commentPaging.pagination.pageIndex = selected;
        getAllComment(slug, commentPaging);

        // Cuộn đến phần tử comment-section
        const element = document.getElementById('comment-section');
        if (element) {
            const offset = element.offsetTop - 130;
            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    };

    const onSubmit = async (data: FormData) => {
        if (slug && data.comment) {
            const res = await flowResult(createComment({ slug, content: data.comment }));
            if (res) {
                form.reset();
                getAllComment(slug);
            }
        }
    };

    return (
        <>
            <hr className='text-border-8' />
            <p id='comment-section' className='font-medium text-[1.0625rem]'>
                {t('words_title.comments')}
                <span className='pl-2 font-normal'>
                    (
                    {totalCommentRecord}
                    )
                </span>
            </p>
            {/* New Comment */}
            {token && (
                <Form {...form}>
                    <form className='flex flex-col gap-4' onSubmit={form.handleSubmit(onSubmit)}>
                        <div className='flex flex-col gap-4'>
                            <FormField
                                control={form.control}
                                name='comment'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <textarea
                                                id=''
                                                maxLength={1000}
                                                placeholder={t('placeholder.comment_input')}
                                                className='block h-[6.0625rem] w-full border rounded-lg pt-4 pl-3 placeholder:text-placeholder-1 outline-none resize-none'
                                                {...field}
                                            >
                                            </textarea>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='text-end'>
                            <Button variant='default' className='w-[8.625rem]' type='submit'>{t('button.send')}</Button>
                        </div>
                    </form>
                </Form>
            )}

            {(comments && comments.length > 0 && token) && (
                <hr className='text-border-8' />
            )}

            {/* List Comment */}
            {(comments && comments.length > 0) && (
                <>
                    <div className='flex flex-col gap-4'>
                        {comments && comments.map((comment, index) => {
                            return <CommentItem key={index} user={comment.user} createdAt={comment.createdAt} content={comment.content} updatedAt={comment.createdAt} />;
                        })}
                        {totalCommentPage > 0 && (
                            <div className='w-full flex justify-center py-4'>
                                <Paginate
                                    forcePage={commentPaging.pagination.pageIndex}
                                    pageCount={totalCommentPage}
                                    onPageChange={({ selected }) => handlePageChange(selected)}
                                />
                            </div>
                        )}

                    </div>
                </>
            )}
        </>
    );
});

const CommentItem = ({ user, createdAt, updatedAt, content }) => {
    return (
        <div className='py-3 px-4 flex flex-col gap-2 rounded'>
            <div className='flex gap-3'>
                <div className='flex flex-none items-center justify-center size-9.5 rounded-full bg-icon-13'>
                    <span className='text-icon-14 text-lg'>{getLastNameInitial(user?.fullName)}</span>
                </div>
                <div className='flex-auto overflow-hidden'>
                    <p className='font-medium text-title-1 line-clamp-2'>{user?.fullName}</p>
                    <p className='font-normal text-small text-text-4'>{formatDateTime(createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}</p>
                </div>
            </div>
            <div>
                <p className='font-normal text-text-3 whitespace-break-spaces'>{content}</p>
            </div>
        </div>
    );
};

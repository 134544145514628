import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { Button } from './Button';
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from './Command';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import { cn } from '@/utils/utils';
import { FloatingLabel } from './Select';
import { PopoverContentProps } from '@radix-ui/react-popover';
import slugify from 'slugify';
import { t } from 'i18next';

interface ItemCombobox {
    label: string,
    value: string
}

interface ComboboxProps {
    value: string,
    onChange: (item: any) => void,
    items: ItemCombobox[],
    placeholder?: string,
    floatingLabel?: boolean,
    label?: string,
    required?: boolean,
    className?: string,
    side?: PopoverContentProps['side'],
    disabled?: boolean
}

export default function Combobox({
    value,
    onChange,
    items,
    placeholder = 'Choose',
    floatingLabel = false,
    label,
    required,
    className,
    side,
    disabled = false
}: ComboboxProps) {
    const [open, setOpen] = useState(false);
    const selectValue = items?.find(item => item.value === value);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger disabled={disabled} asChild className='disabled:bg-background-2 disabled:cursor-not-allowed disabled:opacity-50'>
                <Button
                    variant='input'
                    size='input'
                    role='combobox'
                    aria-expanded={open}
                    className={cn(
                        'w-full relative justify-start',
                        !floatingLabel && !selectValue && 'text-placeholder-1',
                        floatingLabel && 'px-3 pt-6 pb-1.75',
                        className && className
                    )}
                >
                    {
                        !floatingLabel && (
                            value ?
                                selectValue?.label :
                                placeholder
                        )
                    }
                    {
                        floatingLabel && (
                            <>
                                <span className='peer' {...!selectValue && { 'data-placeholder': true }}>{selectValue?.label}</span>
                                <FloatingLabel required={required}>{label}</FloatingLabel>
                            </>
                        )
                    }
                    <ChevronDownIcon className='h-4 w-4 opacity-50 text-nav-1 icon-right-input' />
                </Button>
            </PopoverTrigger>
            <PopoverContent widthFull className='p-0' side={side}>
                <Command
                    filter={(value, search) => {
                        const slugifyConfig = {
                            replacement: '_',
                            lower: true,
                            strict: true,
                            locale: 'vi'
                        };

                        if (slugify(value, slugifyConfig).includes(slugify(search, slugifyConfig))) return 1;
                        return 0;
                    }}
                >
                    <CommandInput placeholder={t('placeholder.search')} />
                    <CommandList>
                        {/* <CommandEmpty>No found.</CommandEmpty> */}
                        <CommandGroup>
                            {items?.map(item => (
                                <CommandItem
                                    key={item.value}
                                    value={item.value}
                                    onSelect={() => {
                                        onChange(item);
                                        setOpen(false);
                                    }}
                                >
                                    <CheckIcon
                                        className={cn(
                                            'mr-2 h-4 w-4',
                                            value === item.value ? 'opacity-100' : 'opacity-0'
                                        )}
                                    />
                                    {item.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}

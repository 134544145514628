import { flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { ResponseData } from '@/types/http';
import { AdministrativeDataResponse } from '@/types/administrative';
import { getStorage, saveLocalStorage } from '@/utils/browsers';
import { GeneralApi } from '@/apis';
import moment from 'moment';
import { DISPLAY_MOMENT_DATE_YMD } from '@/configs/constants';
import { GeneralSettingType } from '@/types/enums';
import { GeneralSettingResponse } from '@/types/http-payload/forum';

export default class GeneralStore extends BaseStore {
    api: GeneralApi;
    administrativeUnit: AdministrativeDataResponse = getStorage('administrative', true);
    content: string = '';

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            administrativeUnit: observable,
            content: observable,
            getAdministrativeUnit: flow.bound,
            getPlaceAutoComplete: flow.bound,
            getGeneralSettingByType: flow.bound
        });
        this.api = new GeneralApi();
    }

    *getAdministrativeUnit() {
        try {
            const res: ResponseData<AdministrativeDataResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAdministrativeUnit, undefined, { hideLoading: true });
            if (res?.ok) {
                this.administrativeUnit = res?.data;
                saveLocalStorage('getAdministrativeDate', moment().format(DISPLAY_MOMENT_DATE_YMD));
                saveLocalStorage('administrative', JSON.stringify(res?.data));
            }
        } catch (error) {
        }
    }

    *getPlaceAutoComplete(params) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getPlaceAutoComplete, params, { hideLoading: true });
            if (res?.ok) {
                return res.data;
            }
        } catch (error) {
        }
    }

    *getGeneralSettingByType(type: GeneralSettingType) {
        try {
            const res: ResponseData<GeneralSettingResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getGereralSettingByType, type);
            if (res?.ok) {
                this.content = res?.data?.value ?? '';
            }
        } catch (error) {
            console.log(error);
        }
    }
}

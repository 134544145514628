import Paginate from '@/components/pagination/Paginate';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
// import AppointmentItem from './AppointmentItem';
import { useStore } from '@/hooks/useStore';
import { GENDER, LANGUAGES_SUPPORTED } from '@/types/enums';
import { formatAge } from '@/utils/datetime';
import { useRef } from 'react';
import HistoryItem from './HistoryItem';
import UpcomingItem from './UpcomingItem';
type Props = {
    type: number
};
export default observer(function PetNoteItem({ type }: Props) {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const {
        appointmentStore: { userAppointments },
        petStore: { petDetail },
        petCareStore: { getAllPetCareHistory, petNotePaging, allPetCareHistory, totalPage }
    } = useStore();

    // ref
    const headerRef = useRef<HTMLDivElement>(null);

    return (
        <div className='flex flex-col pb-20 md:pb-4 gap-4 py-4 bg-white md:rounded-sm'>
            <div className='flex gap-3 items-start px-4 md:px-6'>
                <img className='size-16 rounded-sm' src={petDetail?.image} alt='image-pet' />
                <div>
                    <p className='font-medium'>{petDetail?.name}</p>
                    <div className='text-small text-tab-1'>
                        <div className='flex items-center'>
                            {
                                typeof petDetail?.species === 'string' && petDetail?.species !== '' ?
                                    petDetail.species :
                                    (petDetail?.species === '' ?
                                        t('words_title.pet_other') :
                                        (language === LANGUAGES_SUPPORTED.Vietnamese ?
                                            petDetail?.species?.name :
                                            petDetail?.species?.nameEn))
                            }
                        </div>
                        <div className='flex items-center gap-2'>
                            {
                                petDetail?.gender && (
                                    <div className='flex items-center'>
                                        {petDetail?.gender === GENDER.Male ? t('words_title.male') : t('words_title.female')}
                                        <div className='w-0.25 h-2.5 border-l border-tab-1 ml-2'></div>
                                    </div>
                                )
                            }
                            <p>
                                {formatAge(petDetail?.birthday ?? '', t)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='flex flex-col gap-4 px-4 md:px-6'>
                <p className='text-[0.9375rem] font-semibold text-link-1'>{t('words_title.upcoming_appointment')}</p>
                {
                    userAppointments.map((appointment, index) => (
                        <UpcomingItem key={index} appointment={appointment} />
                    ))
                }
                {
                    userAppointments.length === 0 && (
                        <div className='text-tab-1'>{t('sentences.no_have_appointment_next_two_week')}</div>
                    )
                }
            </div>
            <div className='flex flex-col gap-4 px-4 md:px-6' ref={headerRef}>
                <div className='flex items-center justify-between'>
                    <p className='text-[0.9375rem] font-semibold text-title-1'>{t('words_title.history')}</p>
                </div>
                {
                    allPetCareHistory?.map((petCareHistory, index) => (
                        <HistoryItem key={index} petCareHistory={petCareHistory} type={type} />
                    ))
                }
                {
                    allPetCareHistory.length === 0 && (
                        <div className='text-tab-1'>{t('sentences.no_have_appointment_history')}</div>
                    )
                }
                <div className='flex justify-center'>
                    {
                        allPetCareHistory.length > 0 && (
                            <Paginate
                                forcePage={petNotePaging.pagination.pageIndex}
                                pageCount={totalPage}
                                onPageChange={({ selected }) => {
                                    getAllPetCareHistory(
                                        { ...petNotePaging, pagination: { ...petNotePaging.pagination, pageIndex: selected } },
                                        { type: type, petCode: petDetail?.code }
                                    );
                                    if (headerRef.current) {
                                        headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                        window.scrollBy(0, -130);
                                    }
                                }}
                            />
                        )
                    }

                </div>
            </div>
        </div>
    );
});

import { Button } from '@/components/common/Button';
import { Sheet, SheetTrigger, SheetContent } from '@/components/common/Sheet';
import { useStore } from '@/hooks/useStore';
import { ChevronLeft, SlidersHorizontal } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FormFilter from './form-sheet/FormFilter';
import FormAllCity from './form-sheet/FormAllCity';
import { FILTER_KEY_LIST } from '@/configs/constants';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FilterShopParams } from '@/types/shop';

export default observer(function SheetFilter() {
    // hook
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    // store
    const {
        uiStore: { setObservable, countFilter, filterData }
    } = useStore();

    // state
    const [open, setOpen] = useState(false);
    const [isFilterForm, setIsFilterForm] = useState(true);

    // lifecycle
    // useEffect(() => {
    //     handleSetFilterData(searchParams);
    // }, []);

    useEffect(() => {
        handleSetFilterData(searchParams);
        countFilterParams();
    }, [searchParams]);

    // function
    const handleSetFilterData = (params: URLSearchParams) => {
        const cityParams = params.get('city');
        const categoryParams = params.get('category');
        const pickupParams = params.get('pickup');
        const ratingParams = params.get('rating');

        let initFilterData: FilterShopParams = {};

        initFilterData.city = cityParams ? cityParams.split(',') || [] : [];

        initFilterData.category = categoryParams ? categoryParams.split(',').map(Number) : [];
        initFilterData.pickUp = pickupParams ? pickupParams : undefined;
        initFilterData.rating = ratingParams ? ratingParams : undefined;

        setObservable('filterData', initFilterData, { isMergeObject: true });
    };
    const handleOpenSheet = () => {
        handleSetFilterData(searchParams);
        // const cityParams = searchParams.get('city');

        // if (cityParams) {
        //     setObservable('listCitySelectedValue', cityParams.split(','), { isMergeObject: false });
        // } else {
        //     setObservable('listCitySelectedValue', [], { isMergeObject: false });
        // }
    };

    const countFilterParams = () => {
        let count = 0;
        searchParams.forEach((value, key) => {
            if (FILTER_KEY_LIST.includes(key)) {
                const data = searchParams.get(key)?.split(',') || [];
                count += data?.length;
            }
        });
        setObservable('countFilter', count, { isMergeObject: false });
    };

    return (
        <>
            <Sheet open={open} onOpenChange={setOpen}>
                <SheetTrigger asChild>
                    <Button onClick={() => handleOpenSheet()} className='md:h-full h-8 md:rounded-[18px] rounded-[13px] flex gap-1 w-fit md:px-2.5 px-2 py-1.5 md:py-0 justify-center items-center bg-button-1 text-black font-medium'>
                        <SlidersHorizontal className={classNames('h-4 w-4', countFilter > 0 ? 'text-label-5' : 'text-text-24')} />
                        {countFilter === 0 && <span className='md:block hidden text-text-24'>{t('button.filter')}</span>}
                        {countFilter > 0 && <div className='h-[1.0625rem] w-[1.0625rem] bg-label-5 rounded-full flex items-center justify-center text-white text-[0.6875rem]'>{countFilter}</div>}
                    </Button>
                </SheetTrigger>
                {isFilterForm ?
                    (
                        <SheetContent
                            side='center'
                            className='p-0 overflow-hidden'
                            closeButtonClassName='left-4 top-6 bg-border-1 h-6 w-6 flex items-center justify-center rounded-full text-black opacity-100 hover:opacity-70'
                            onPointerDownOutside={e => e.preventDefault()}
                        >
                            <FormFilter handleCloseSheet={(close: boolean) => setOpen(close)} handelRedirectForm={(value: boolean) => setIsFilterForm(value)} />
                        </SheetContent>
                    ) :
                    (
                        <SheetContent
                            side='center'
                            className='p-0 overflow-hidden'
                            showCloseButton={false}
                            onPointerDownOutside={e => e.preventDefault()}
                        >
                            <FormAllCity handleBack={(value: boolean) => setIsFilterForm(value)} />
                        </SheetContent>
                    )}
            </Sheet>
        </>
    );
});

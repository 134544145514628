import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import GeneralInformationTab from './tabs/GeneralInformationTab';
import ShopInformationTab from './tabs/ShopInformationTab';
import { useStore } from '@/hooks/useStore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '@/utils/utils';

interface CreateShopPageProps {
    title?: string
}

export default observer(function CreateShopPage({ title }: CreateShopPageProps) {
    // hooks
    const { t } = useTranslation();

    // store
    const { shopStore: { shopInfo }, authStore: { profile, isChannelShopOwner } } = useStore();

    // State
    const [activeTab, setActiveTab] = useState('General Information');
    const [shopCategory, setShopCategory] = useState();

    // function
    const handleTabChange = (tabValue: string) => {
        setActiveTab(tabValue);
    };

    const handleSubmitSuccess = () => {
        setActiveTab('Shop Information');
        scrollToTop();
    };

    return (
        <>
            <div className='md:block hidden text-active font-semibold text-[1.5rem] leading-[1.875rem] py-4 text-center'>{title ? title : t('words_title.create_shop')}</div>
            <Tabs value={activeTab} onValueChange={handleTabChange} className='w-full h-full flex flex-col'>
                <TabsList variant='createShop' data-orientation='horizontal' className={classNames('flex w-full overflow-x-auto bg-white rounded')}>
                    <TabsTrigger
                        variant='createShop'
                        className='md:text-[0.875rem] text-3 w-full'
                        value='General Information'
                        disabled={!isChannelShopOwner && profile?.alreadyOwnShop}
                    >
                        {t('words_title.create_shop_tab_general')}
                    </TabsTrigger>
                    <TabsTrigger
                        variant='createShop'
                        className='md:text-[0.875rem] text-3 w-full'
                        value='Shop Information'
                        disabled={(isChannelShopOwner && shopInfo && !shopInfo.generalInfo) || (!isChannelShopOwner && !profile?.alreadyOwnShop)}
                    >
                        {t('words_title.create_shop_tab_info')}
                    </TabsTrigger>
                </TabsList>
                <TabsContent value='General Information'>
                    <GeneralInformationTab
                        callbackSuccess={handleSubmitSuccess}
                        setShopCategory={setShopCategory}
                    />
                </TabsContent>
                <TabsContent value='Shop Information'>
                    <ShopInformationTab shopCategory={shopCategory} />
                </TabsContent>
            </Tabs>
        </>
    );
});

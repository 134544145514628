import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { DISPLAY_MOMENT_DATE_DMYHMS_AM_PM, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { formatDateTime } from '@/utils/datetime';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

export default observer(function NotificationDetail() {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const { code } = useParams();

    // store
    const {
        authStore: { getProfile },
        notificationStore: { notification, setObservable, getNotificationDetail }
    } = useStore();

    // lifecycle
    useEffect(() => {
        fetchData();
        return () => {
            setObservable('notification', undefined);
        };
    }, []);

    // function
    const fetchData = async () => {
        if (code) {
            const res = await flowResult(getNotificationDetail({
                code
            }));
            if (res) {
                getProfile();
            }
        }
    };

    return (
        <>
            <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.notification_detail')} defaultNavigateUrl={ROUTES.notification.href} />} />
            <div className='md:pt-8'>
                <div className='bg-white p-4'>
                    <p className='text-small text-text-4'>{formatDateTime(notification?.createdAt, DISPLAY_MOMENT_DATE_DMYHMS_AM_PM)}</p>
                    <p
                        className=''
                        dangerouslySetInnerHTML={{ __html: notification?.content?.[language] }}
                    >
                    </p>
                </div>
                <div className='pt-4 hidden md:flex w-full justify-center'><Link to={ROUTES.notification.href} className='text-active py-2.5 px-16 font-medium bg-white rounded-md'>{t('button.back')}</Link></div>
            </div>

        </>
    );
});

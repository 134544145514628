import { observer } from 'mobx-react-lite';
import UserAvatarMobile from './UserAvatarMobile';
import MyAccountItem from './MyAccountItem';
import { CalendarHeartIcon, CheckIcon, ChevronRightIcon, DiamondPlusIcon, GlobeIcon, LockIcon, PawPrintIcon, StarIcon, StoreIcon, UserIcon } from 'lucide-react';
import { Button } from '@/components/common/Button';
import { useStore } from '@/hooks/useStore';
import { ACTIVITY_PATH, LANGUAGES_MAPPER, ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { Dispatch, RefObject, SetStateAction } from 'react';
import classNames from 'classnames';
import MyShopMobileItem from '@/pages/my-shop/components/MyShopMobileItem';

interface MenuSibarMobileAfterLoginProps {
    outsideRef: RefObject<any>,
    showLangOptions: boolean,
    setShowLangOptions: Dispatch<SetStateAction<boolean>>,
    handleClickChangeLanguage: Function
}

export default observer(function MenuSibarMobileAfterLogin({
    outsideRef,
    showLangOptions,
    setShowLangOptions,
    handleClickChangeLanguage
}: MenuSibarMobileAfterLoginProps) {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const { authStore: { signOut, isChannelShopOwner, profile, onSwitchToShopChannel } } = useStore();

    // function

    return (
        <div>
            <UserAvatarMobile />
            {
                !isChannelShopOwner ?
                    (
                        <>
                            <MyAccountItem className='mt-1.5' icon={PawPrintIcon} title={t('words_title.my_pet')} iconRight={ChevronRightIcon} url={ROUTES.myPet.href} />
                            <MyAccountItem className='mt-0.25' icon={CalendarHeartIcon} title={t('words_title.my_appointments')} url={`${ROUTES.activity.href}/${ACTIVITY_PATH.appointment}`} iconRight={ChevronRightIcon} />
                            <MyAccountItem className='mt-0.25' icon={StarIcon} title={t('words_title.my_review')} iconRight={ChevronRightIcon} url={ROUTES.myReview.href} />
                            {
                                profile?.alreadyOwnShop ?
                                    (
                                        <MyAccountItem
                                            className='mt-1.5'
                                            icon={StoreIcon}
                                            title={t('words_title.shop_centre')}
                                            iconRight={ChevronRightIcon}
                                            onClick={onSwitchToShopChannel}
                                        />
                                    ) :
                                    <MyAccountItem className='mt-1.5' icon={DiamondPlusIcon} title={t('words_title.create_shop')} iconRight={ChevronRightIcon} url={ROUTES.createShop.href} />
                            }

                        </>
                    ) :
                    (
                        <MyShopMobileItem className='mt-1.5' icon={StoreIcon} title={t('words_title.shop_info')} iconRight={ChevronRightIcon} url={`${ROUTES.myShop.href}`} />
                    )
            }
            <div ref={outsideRef}>
                <MyAccountItem className='mt-1.5' icon={GlobeIcon} title={t('words_title.language')} desc={t(`words_title.${Object.keys(LANGUAGES_MAPPER).find(item => LANGUAGES_MAPPER[item].code === language)}`)} iconRight={ChevronRightIcon} showDropDownOption={showLangOptions} onShowDropDownOption={setShowLangOptions} />
                {
                    showLangOptions && (
                        <div className='w-full bg-white rounded mt-0.5 px-4 py-2'>
                            {
                                Object.keys(LANGUAGES_MAPPER).map((item, index) => (
                                    <div key={index} className={classNames('relative px-11.5 text-small leading-[1.125rem] text-text-4 font-normal py-2', language === LANGUAGES_MAPPER[item].code && 'bg-accent text-accent-foreground rounded')} onClick={() => handleClickChangeLanguage(item)}>
                                        {t(LANGUAGES_MAPPER[item].fullname)}
                                        <CheckIcon className={classNames('w-4.5 h-4.5 absolute top-2 left-2', language !== LANGUAGES_MAPPER[item].code && 'hidden')} />
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            <MyAccountItem className='mt-1.5' icon={LockIcon} title={t('words_title.change_password')} iconRight={ChevronRightIcon} url={ROUTES.changePassword.href} />
            <div className='mx-4 mt-6'>
                <Button onClick={() => signOut()} className='h-9.5 w-full' variant='outline'>
                    <span className='font-medium'>{t('words_title.sign_out')}</span>
                </Button>
            </div>
        </div>
    );
});

import ContainerBody from '@/components/layout/ContainerBody';
import HeaderMobileFormat2 from '@/components/layout/header-component/HeaderMobileFormat2';
import { ACTIVITY_PATH, FORUM_PATH, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { ENDUSER_TYPE } from '@/types/enums';
import { observer } from 'mobx-react-lite';
import { Outlet, useLocation, useParams } from 'react-router-dom';

export default observer(function ForumPanelLayout() {
    // hooks
    const { pathname } = useLocation();
    const { slug, tabPath } = useParams();
    // store
    const { uiStore: { devicesScreen: { mobile } }, authStore: { isChannelShopOwner } } = useStore();
    // function
    const showHeader = !(mobile && ((slug && isChannelShopOwner && pathname.includes(ROUTES.chatForShop.href)) || ((slug && tabPath && [FORUM_PATH.career, ACTIVITY_PATH.chat, ACTIVITY_PATH.appointment].includes(tabPath)) || pathname.includes(ROUTES.notification.href) || pathname.includes(ROUTES.bookAppointment.href))));
    const showFooterMenu = !(mobile && ((slug && tabPath && [ACTIVITY_PATH.chat, ACTIVITY_PATH.appointment].includes(tabPath)) || pathname.includes(ROUTES.notification.href) || pathname.includes(ROUTES.bookAppointment.href)));
    const showFootter = !(mobile && ((isChannelShopOwner && pathname.includes(ROUTES.chatForShop.href)) || ((slug && tabPath && [FORUM_PATH.career, ACTIVITY_PATH.chat, ACTIVITY_PATH.appointment].includes(tabPath)) || pathname.includes(ROUTES.notification.href) || pathname.includes(ROUTES.forum.href) || pathname.includes(ROUTES.activity.href) || pathname.includes(ROUTES.bookAppointment.href) || pathname.includes(ROUTES.petNote.href))));
    const showDropdown = isChannelShopOwner && (pathname.includes(ROUTES.forum.href) || pathname.includes(ROUTES.chatForShop.href));

    return (
        <div className=''>
            <ContainerBody
                showHeader={showHeader}
                showFooter={showFootter}
                showFooterMenu={showFooterMenu}
                headerMobileComponent={<HeaderMobileFormat2 useMenuDropDown={showDropdown} />}
            >
                <Outlet />
            </ContainerBody>
        </div>

    );
});

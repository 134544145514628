import BookSuccessBg from '@/assets/images/bookSuccess.png';
import { Button } from '@/components/common/Button';
import { ACTIVITY_PATH, ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function BookSuccess() {
    // hook
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { shopSlug } = useParams();

    return (
        <div className='flex flex-col items-center pt-12 pb-6 gap-4'>
            <div>
                <img src={BookSuccessBg} className='md:w-[21.25rem] md:h-[11.875rem] w-[15.125rem] h-[10.1875rem]' alt='shop channel background' />
            </div>
            <div className='text-center flex flex-col gap-3'>
                <h3 className='text-[0.9375rem] font-semibold'>{t('sentences.book_success_one')}</h3>
                <div className='text-center flex flex-col items-center gap-2'>
                    <p className='text-text-8 text-center'>
                        {t('sentences.book_success_two')}
                    </p>
                    <p className='text-text-8 text-center w-[330px]'>
                        {t('sentences.book_success_three')}
                    </p>
                </div>

            </div>
            <div className='flex justify-center gap-4'>
                <Button onClick={() => navigate(ROUTES.home.href)} variant='ghost' className='md:block hidden px-2 py-2.5 w-44  text-active bg-white'>{t('button.go_to_homepage')}</Button>
                <Button onClick={() => navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.chat}/${shopSlug}`)} variant='default' className='px-2 py-2.5 w-44 '>{t('button.chat_with_shop')}</Button>
                <Button onClick={() => navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.appointment}`)} variant='default' className='md:block hidden px-2 py-2.5 w-44 '>{t('button.go_to_appointment')}</Button>
            </div>
            <div className='md:hidden fixed bottom-0 left-0 z-50 w-screen bg-white h-17 p-4 flex justify-center gap-4'>
                <Button onClick={() => navigate(ROUTES.home.href)} variant='clear' className='px-2 py-2.5 w-full  text-active bg-white'>{t('button.go_to_homepage')}</Button>
                <Button onClick={() => navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.appointment}`)} variant='default' className='px-2 py-2.5 w-full '>{t('button.go_to_appointment')}</Button>
            </div>
        </div>
    );
}

import { useStore } from '@/hooks/useStore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import ShopInfomationPage from './components/ShopInfomationPage';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { SquarePenIcon } from 'lucide-react';
import { useEffect } from 'react';
import { INFO_TYPE, SHOP_STATUS_TYPE } from '@/types/enums';
import { observer } from 'mobx-react-lite';

export default observer(function MyShopPage() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const { shopStore: { setObservable, isEditMode, getShopInfo, shopInfo } } = useStore();

    // lifecycle
    useEffect(() => {
        getShopInfo({ typeInfo: INFO_TYPE.GeneralInfo });
        return () => {
            setObservable('isEditMode', false);
        };
    }, []);

    // function
    const handleClickGoBackBySelf = () => {
        setObservable('isEditMode', false);
        navigate(ROUTES.myShop.href);
    };

    const handleClickEdit = () => {
        setObservable('isEditMode', true);
    };

    return (
        <div className='h-full'>
            <Header
                onlyShowMobile
                headerMobileComponent={(
                    <HeaderMobileFormat3
                        title={t('words_title.shop_info')}
                        handleOnClickBySelf={isEditMode && shopInfo?.status === SHOP_STATUS_TYPE.Active ? handleClickGoBackBySelf : undefined}
                        defaultNavigateUrl={`${ROUTES.myAccount.href}`}
                        rightContent={!isEditMode && shopInfo?.status !== SHOP_STATUS_TYPE.WaitApprove ?
                            (
                                <button type='button' onClick={handleClickEdit}>
                                    <SquarePenIcon className='text-text-3' />
                                </button>
                            ) :
                            <div></div>}
                    />
                )}
            />
            <ShopInfomationPage />
        </div>
    );
});

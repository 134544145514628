import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { ButtonProps, buttonVariants } from '../common/Button';
import { cn } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/hooks/useStore';

interface PaginateProps extends ReactPaginateProps {
    size?: ButtonProps['size']
};

export default observer(function Paginate({
    containerClassName,
    pageLinkClassName,
    activeLinkClassName,
    previousLinkClassName,
    previousLabel,
    nextLinkClassName,
    nextLabel,
    breakLabel,
    breakLinkClassName,
    disabledLinkClassName,
    size = 'paginate',
    pageRangeDisplayed,
    marginPagesDisplayed,
    ...props
}: PaginateProps) {
    const { uiStore: { devicesScreen: { desktop } } } = useStore();

    // variables
    const paginateLinkClass = buttonVariants({
        variant: 'paginateOutline',
        size
    });

    return (
        <ReactPaginate
            containerClassName={cn('flex flex-row items-center gap-1', containerClassName)}
            pageLinkClassName={cn(paginateLinkClass, pageLinkClassName)}
            activeLinkClassName={cn(
                buttonVariants({
                    variant: 'paginate',
                    size
                }),
                activeLinkClassName
            )}
            previousLinkClassName={cn(paginateLinkClass, 'flex items-center justify-center group', previousLinkClassName)}
            previousLabel={previousLabel ?? (
                <>
                    <ChevronLeft className='h-4 w-4 group-hover:text-white' />
                </>
            )}
            nextLinkClassName={cn(paginateLinkClass, 'flex items-center justify-center group', nextLinkClassName)}
            nextLabel={nextLabel ?? (
                <>
                    <ChevronRight className='h-4 w-4 group-hover:text-white' />
                </>
            )}
            breakLabel={breakLabel ?? (
                <>
                    <MoreHorizontal className='h-4 w-4' />
                    <span className='sr-only'>More pages</span>
                </>
            )}
            breakLinkClassName={cn('flex h-9 w-9 items-center justify-center', breakLinkClassName)}
            disabledLinkClassName={cn('opacity-50 pointer-events-none', disabledLinkClassName)}
            pageRangeDisplayed={pageRangeDisplayed ?? (desktop ? 2 : 1)}
            marginPagesDisplayed={marginPagesDisplayed ?? (desktop ? 2 : 1)}
            {...props}
        />
    );
});

import { observer } from 'mobx-react-lite';
import ShopChannelDeniedImg from '@/assets/images/ShopChannelDeniedImg.png';
import { Button } from '@/components/common/Button';
import { useStore } from '@/hooks/useStore';
import { flowResult } from 'mobx';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

export default observer(function ShopChannelDenied() {
    // hooks
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();

    // store
    const { shopStore: { requestApprove, getShopInfo } } = useStore();

    // function
    const handleClickReRequestApprove = debounce(async () => {
        const res = await flowResult(requestApprove({}, true));
        if (res) {
            getShopInfo();
        }
    }, 1000, {
        leading: true,
        trailing: false
    });

    const handleClickUpdateInfo = async () => {
        navigate(ROUTES.myShop.href, { state: { previousUrl: pathname } });
    };

    return (
        <div className='flex flex-col items-center py-12'>
            <img src={ShopChannelDeniedImg} alt='' className='md:h-[353px] h-[164px] w-auto object-contain' />
            <div className='flex flex-col items-center gap-4 mt-12 text-center'>
                <div className='font-bold text-[0.9375rem]'>{t('words_title.shop_channel_denied')}</div>
                <div className='max-w-[600px] text-small leading-[1.125rem] text-text-8'>
                    <div>{t('sentences.shop_channel_denied_desc_1')}</div>
                    <div>{t('sentences.shop_channel_denied_desc_2')}</div>
                </div>
                <div className='flex md:flex-row flex-col-reverse items-center gap-4'>
                    <Button variant='outline' size='submit' className='p-4' onClick={handleClickReRequestApprove}>
                        {t('button.re_apporval')}
                    </Button>
                    <Button variant='submit' size='submit' className='p-4' onClick={handleClickUpdateInfo}>
                        {t('button.update_shop_info')}
                    </Button>
                </div>
            </div>
        </div>
    );
});

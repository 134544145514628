import { Search } from 'lucide-react';
import { InputWithIcon } from '../common/Input';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';

interface SearchInputProps {
    className?: string
}

export default observer(function SearchInput({ className = '' }: SearchInputProps) {
    // hooks
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const keyword = searchParams.get('keyword') || '';

    // state
    const [value, setValue] = useState<string>(keyword);

    // function
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (keyword === value) {
            navigate(0);
        } else {
            navigate(`${ROUTES.search.href}?keyword=${encodeURIComponent(value)}`);
        }
    };

    return (
        <div className='relative flex items-center'>
            <InputWithIcon
                type='text'
                placeholder={t('placeholder.search_input_shop')}
                value={value}
                onChange={onChange}
                onKeyDown={handleKeyPress}
                className={className}
                maxLength={70}
                icon={(
                    <div onClick={() => handleSearch()} className='absolute right-[0.25rem] top-0 h-full flex items-center py-[0.125rem] cursor-pointer'>
                        <div className='p-[7px] min-w-11 bg-input-2 flex items-center justify-center text-white rounded-[0.5rem]'>
                            <Search className='text-white' size={16} />
                        </div>
                    </div>
                )}
            />
        </div>
    );
});

import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cn } from '@/utils/utils';
import { cva, VariantProps } from 'class-variance-authority';

const Tabs = TabsPrimitive.Root;

const tabsListVariants = cva(
    'inline-flex justify-center',
    {
        variants: {
            variant: {
                form: 'h-8.5 items-start',
                history: 'h-8 h-12 items-start',
                createShop: 'h-12 items-start',
                appointment: 'h-16 items-center',
                statistic: 'h-10 bg-input rounded-[0.5rem] items-center p-0.5'
            }
        }
    }
);

const tabsTriggerVariants = cva(
    '',
    {
        variants: {
            variant: {
                form: 'h-8.5 inline-flex flex-col justify-between items-center font-medium text-tab-1 leading-5 border-b border-b-border-1 disabled:opacity-5 data-[state=active]:border-none data-[state=active]:text-active data-[state=active]:font-semibold data-[state=active]:after:content-[""] data-[state=active]:after:block data-[state=active]:after:w-full data-[state=active]:after:h-[3px] data-[state=active]:after:bg-active data-[state=active]:after:rounded-t-sm disabled:opacity-50 disabled:text-muted-foreground',
                history: 'h-8 inline-flex flex-col justify-between items-center font-regular text-[0.875rem] text-tab-2 leading-5 border-b border-b-border-1 disabled:opacity-5 data-[state=active]:text-active data-[state=active]:after:content-[""] data-[state=active]:after:block data-[state=active]:after:w-[95%] data-[state=active]:after:h-[2px] data-[state=active]:after:bg-active data-[state=active]:after:rounded-t-sm',
                createShop: 'relative h-12 inline-flex flex-col justify-center items-center font-regular text-[0.875rem] text-text-3 leading-5 border-b border-b-border-1 disabled:opacity-50 disabled:text-muted-foreground data-[state=active]:text-active data-[state=active]:after:content-[""] data-[state=active]:after:absolute data-[state=active]:after:bottom-0 data-[state=active]:after:block data-[state=active]:after:w-[85%] data-[state=active]:after:h-[3px] data-[state=active]:after:bg-active data-[state=active]:after:rounded-t-sm',
                scheduleList: 'h-8 font-medium text-white leading-5 disabled:opacity-5 data-[state=active]:text-white data-[state=active]:bg-active data-[state=active]:rounded',
                appointment: 'h-8 inline-flex flex-col justify-between items-center font-normal text-[0.875rem] text-active leading-3 disabled:opacity-50 after:block after:content-[""] after:h-[2px] after:rounded-md after:bg-active after:w-[95%] data-[state=active]:text-active data-[state=active]:after:content-[""] data-[state=active]:after:block data-[state=active]:after:w-[95%] data-[state=active]:after:h-[2px] data-[state=active]:after:bg-active data-[state=active]:after:rounded-sm',
                statistic: 'h-9 inline-flex justify-center items-center xl:px-6 px-4 py-2.5 bg-input data-[state=active]:bg-white data-[state=active]:rounded-[0.5rem]'
            }
        }
    }
);

const TabsList = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & VariantProps<typeof tabsListVariants>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={cn(
            tabsListVariants({ variant: props.variant }),
            className
        )}
        {...props}
    />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & VariantProps<typeof tabsTriggerVariants>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Trigger
        ref={ref}
        className={cn(
            tabsTriggerVariants({ variant: props.variant }),
            className
        )}
        {...props}
    />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn(
            'outline-transparent',
            className
        )}
        {...props}
    />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };

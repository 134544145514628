import { observer } from 'mobx-react-lite';
import MyShopItem from '../../components/MyShopItem';
import { Carousel, CarouselApi, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/common/Carousel';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';
import { useEffect, useState } from 'react';
import { GetShopInfoResponse } from '@/types/http-payload/shop';
import { flowResult } from 'mobx';
import { INFO_TYPE, SHOP_SERVICE_NAME_TYPE, SHOP_SERVICE_TYPE } from '@/types/enums';
import { loadURLPreview } from '@/utils/browsers';
import { ShopInfoService } from '@/types/shop';
import { DEFAULT_NULL_VALUE, SHOP_SERVICE, SHOP_SERVICE_NAME } from '@/configs/constants';
import FormatNumber from '@/components/react-number-format';
import classNames from 'classnames';

export default observer(function ShopInfoTab() {
    // hooks
    const { t } = useTranslation();

    // store
    const { shopStore: { getShopInfo }, uiStore: { windowSize: { width } } } = useStore();

    // state
    const [shopInfo, setShopInfo] = useState<GetShopInfoResponse>();
    const [api, setApi] = useState<CarouselApi>();
    const [currentImage, setCurrentImage] = useState(0);

    // const filterData = use

    // lifecycle
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (shopInfo?.images && shopInfo?.images.length > 0) {
            shopInfo.images.forEach((image, index) => {
                loadURLPreview(image, `preview-image-${index}`);
            });
        }
    }, [shopInfo]);

    useEffect(() => {
        if (!api) {
            return;
        }

        setCurrentImage(api.selectedScrollSnap() + 1);

        api.on('select', () => {
            setCurrentImage(api.selectedScrollSnap() + 1);
        });
    }, [api]);

    // function
    const fetchData = async () => {
        const res = await flowResult(getShopInfo({ typeInfo: INFO_TYPE.ShopInfo }));
        if (res && res.ok) {
            setShopInfo(res.data as GetShopInfoResponse);
        }
    };

    const handleScrollPrevious = () => {
        const prevIndex = Math.max(currentImage - (width >= 768 ? 8 : (width >= 640 ? 4 : 2)) - 1, 0);
        setCurrentImage(prevIndex);
        api?.scrollTo(prevIndex);
    };

    const handleScrollNext = () => {
        const nextIndex = Math.min(currentImage + (width >= 768 ? 8 : (width >= 640 ? 4 : 2)) - 1, (shopInfo?.images?.length ?? 0) - 1);
        setCurrentImage(nextIndex);
        api?.scrollTo(nextIndex);
    };

    const indexCarousel = (index) => {
        const tempIndex = index + (width >= 768 ? 7 : (width >= 640 ? 3 : 1));
        if (shopInfo && tempIndex > shopInfo?.images?.length) {
            return shopInfo.images.length;
        } else {
            return tempIndex;
        }
    };

    return (
        <div className='flex flex-col md:gap-4 gap-1.5'>
            <div className='bg-white md:px-4 px-0 md:pt-6 pt-0 md:pb-4 rounded flex flex-col gap-2.5'>
                <MyShopItem
                    title={t('words_title.shop_images')}
                    desc={(
                        <Carousel
                            setApi={setApi}
                            opts={{
                                align: 'start'
                            }}
                            className='w-full mt-4'
                        >
                            <CarouselContent>
                                {
                                    shopInfo?.images && shopInfo.images?.length > 0 &&
                                    shopInfo?.images.map((_, index) => (
                                        <CarouselItem className='relative md:w-[12.5%] md:basis-[12.5%] sm:w-1/4 sm:basis-1/4 w-1/2 basis-1/2' key={index}>
                                            <img className='w-full h-24 object-cover rounded' id={`preview-image-${index}`} alt='' />
                                        </CarouselItem>
                                    ))
                                }
                            </CarouselContent>
                            <div className='flex justify-center items-center gap-4 mt-4' role='group'>
                                <CarouselPrevious className='static translate-y-0 w-5 h-5' iconHoverClass='hover:text-white' onClick={handleScrollPrevious} />
                                <span>
                                    {
                                        `${t('words_title.image_upload')} ${shopInfo && shopInfo?.images?.length > 0 ?
                                            (
                                                indexCarousel(currentImage)
                                            ) :
                                            0
                                        }/${shopInfo?.images?.length}`
                                    }
                                </span>
                                <CarouselNext className='static translate-y-0 w-5 h-5' iconHoverClass='hover:text-white' onClick={handleScrollNext} />
                            </div>
                        </Carousel>
                    )}
                />
                <MyShopItem classname='whitespace-pre-line' title={t('words_title.shop_introduction')} desc={<div>{shopInfo?.introduction ?? ''}</div>} />
                <div className='grid md:grid-cols-2 grid-cols-1 gap-2.5'>
                    <MyShopItem title={t('words_title.facebook')} desc={<div>{shopInfo?.facebook ? shopInfo.facebook : DEFAULT_NULL_VALUE}</div>} />
                    <MyShopItem title={t('words_title.youtube')} desc={<div>{shopInfo?.youtube ? shopInfo.youtube : DEFAULT_NULL_VALUE}</div>} />
                    <MyShopItem title={t('words_title.instagram')} desc={<div>{shopInfo?.instagram ? shopInfo.instagram : DEFAULT_NULL_VALUE}</div>} />
                    <MyShopItem title={t('words_title.tiktok')} desc={<div>{shopInfo?.tiktok ? shopInfo.tiktok : DEFAULT_NULL_VALUE}</div>} />
                </div>
            </div>

            <div className='bg-white md:px-4 px-0 md:pt-6 pt-4 md:pb-4 pb-0 rounded'>
                <div className='text-text-3 font-semibold pl-4'>{t('words_title.menu')}</div>
                <MyShopItem
                    title={shopInfo?.menuFile ? t('words_title.upload_menu') : ''}
                    desc={(
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                            href={typeof shopInfo?.menuFile === 'string' ? shopInfo.menuFile : ''}
                            className={classNames('line-clamp-1', typeof shopInfo?.menuFile === 'string' && 'underline')}
                        >
                            {typeof shopInfo?.menuFile === 'string' ? shopInfo.menuFile.substring(shopInfo.menuFile.lastIndexOf('/') + 1) : DEFAULT_NULL_VALUE}
                        </a>
                    )}
                />
            </div>

            {
                Object.values(SHOP_SERVICE_TYPE).filter(item => item !== SHOP_SERVICE_TYPE.Additional).map((shopServiceType, index) => (
                    <ShopServiceItem
                        key={index}
                        title={t(`select_options.${SHOP_SERVICE[shopServiceType].key}`)}
                        type={shopServiceType}
                        data={shopInfo?.services && shopInfo.services.filter(service => service.type === shopServiceType)}
                    />
                ))
            }
        </div>
    );
});

interface ShopServiceItemProps {
    title: string,
    type: number,
    data: ShopInfoService[] | undefined
}

const ShopServiceItem = ({ title, type, data }: ShopServiceItemProps) => {
    // hooks
    const { t } = useTranslation();

    function processPetServices(data) {
        // Khởi tạo một đối tượng để chứa dữ liệu kết quả
        const result: any = [];

        data && data.length > 0 && data.forEach((item) => {
            const weightRange = `${item.petWeightFrom}kg - ${item.petWeightTo === '50+' ? t('words_title.up') : item.petWeightTo + 'kg'}`;
            const serviceName = item.serviceName;

            let existingEntry: any = result.find(entry => Object.keys(entry)[0] === weightRange);

            if (!existingEntry) {
                existingEntry = { [weightRange]: {
                    [SHOP_SERVICE_NAME_TYPE.Cut]: null,
                    [SHOP_SERVICE_NAME_TYPE.Shower]: null,
                    [SHOP_SERVICE_NAME_TYPE.Combo]: null,
                    [SHOP_SERVICE_NAME_TYPE.Hotel]: null } };
                result.push(existingEntry);
            }
            if (!existingEntry[weightRange][serviceName]) {
                existingEntry[weightRange][serviceName] = item.price;
            } else {
                const duplicateService = { [weightRange]: {
                    [SHOP_SERVICE_NAME_TYPE.Cut]: null,
                    [SHOP_SERVICE_NAME_TYPE.Shower]: null,
                    [SHOP_SERVICE_NAME_TYPE.Combo]: null,
                    [SHOP_SERVICE_NAME_TYPE.Hotel]: null } };
                result.push(duplicateService);
                duplicateService[weightRange][serviceName] = item.price;
            }
        });

        return result;
    }

    return (
        <>
            {
                data && data.length > 0 && (
                    <div className='bg-white md:px-4 px-0 md:pt-6 pt-4 md:pb-4 pb-0 rounded flex-shrink-0'>
                        <div className='text-text-3 font-bold pl-4 border-b border-background-2 pb-1.5 w-full'>{title}</div>
                        <div className='px-4 pt-3 overflow-x-auto scrollbar-height-4 scrollbar-width-10'>
                            <div className='md:min-w-0 min-w-[700px]'>
                                {
                                    type === SHOP_SERVICE_TYPE.Cat || type === SHOP_SERVICE_TYPE.Dog ?
                                        (
                                            <div className='grid grid-cols-5 items-center bg-background-3 font-medium min-h-8.5 p-1.5'>
                                                <div>{t('words_title.weight')}</div>
                                                {
                                                    Object.keys(SHOP_SERVICE_NAME).map((item, index) => (
                                                        <div key={index}>{t(`select_options.${SHOP_SERVICE_NAME[item].key}`)}</div>
                                                    ))
                                                }
                                            </div>
                                        ) :
                                        (
                                            <div className='flex items-center bg-background-3 min-h-8.5 px-1.5 font-medium'>
                                                <div className='w-4/5'>{t('words_title.service_name_2')}</div>
                                                <div className='w-1/5'>{t('words_title.price')}</div>
                                            </div>
                                        )
                                }
                            </div>
                            <div className='md:min-w-0 min-w-[700px]'>
                                {
                                    type === SHOP_SERVICE_TYPE.Cat || type === SHOP_SERVICE_TYPE.Dog ?
                                        (
                                            processPetServices(data).map((item, index) => (
                                                <ShopServiceRow
                                                    key={index}
                                                    petWeight={Object.keys(item)[0]}
                                                    data={item[Object.keys(item)[0]]}
                                                    type={type}
                                                />
                                            ))
                                        ) :
                                        (
                                            data?.map((item, index) => (
                                                <ShopServiceRow key={index} data={item} type={type} />
                                            ))
                                        )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

interface ShopServiceRowProps {
    data: any,
    petWeight?: string,
    type: number
}

const ShopServiceRow = ({ petWeight, data, type }: ShopServiceRowProps) => {
    // hooks
    const { t } = useTranslation();

    return (
        <>
            {
                (type === SHOP_SERVICE_TYPE.Cat || type === SHOP_SERVICE_TYPE.Dog) ?
                    (
                        <div className='min-h-8.5 border-b border-background-2 px-1.5 grid grid-cols-5 items-center'>
                            <div>{petWeight}</div>
                            {
                                Object.keys(data).map((item, index) => {
                                    if (!data[item]) {
                                        return <div key={index}>{DEFAULT_NULL_VALUE}</div>;
                                    } else {
                                        return (
                                            <FormatNumber
                                                key={index}
                                                type='text'
                                                decimalScale={0}
                                                statusDisplay='text'
                                                thousandSeparator={true}
                                                value={data[item]}
                                                suffix=' VND'
                                                className='p-0 min-h-8.5 border-none bg-transparent items-center'
                                            />
                                        );
                                    }
                                })
                            }
                        </div>
                    ) :
                    (
                        <div className='border-b border-background-2 px-1.5 flex items-center gap-2'>
                            <div className='w-4/5 py-2'>{`${data.isMedicalService ? `(${t('words_title.medical')}) ` : ''}${data.serviceName}`}</div>
                            {
                                data.price ?
                                    (
                                        <FormatNumber
                                            type='text'
                                            decimalScale={0}
                                            statusDisplay='text'
                                            thousandSeparator={true}
                                            value={data.price}
                                            suffix=' VND'
                                            className='w-1/5 p-0 min-h-8.5 border-none bg-transparent items-center'
                                        />
                                    ) :
                                    (
                                        <div>{t('words_title.by_consul')}</div>
                                    )
                            }
                        </div>
                    )
            }
        </>
    );
};

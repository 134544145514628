import { useTranslation } from 'react-i18next';
import Logo from '@/assets/images/logo.svg';
import Registed from '@/assets/images/registed.png';
import { Link } from 'react-router-dom';
import { FORUM_PATH, ROUTES } from '@/configs/constants';

export default function Footer() {
    // hooks
    const { t } = useTranslation('translation', { keyPrefix: 'footer' });

    return (
        <footer className='w-full bg-white px-4 h-fit md:h-[13.125rem]'>
            <div className='container md:pt-8 md:pb-6 py-4'>
                <div className='w-full flex md:flex-row md:justify-between flex-col items-center md:gap-0 gap-4 md:px-0 px-4'>
                    <Link to={ROUTES.home.href} className='logo md:w-fit w-full md:block md:pb-0 pb-4 md:border-none flex justify-center border-b border-b-border-6'>
                        <img src={Logo} className='md:h-14 h-10' alt='logo' />
                    </Link>
                    <div className='forum md:min-w-80 md:w-auto w-full flex flex-col gap-4'>
                        <div className='h-1/2 flex md:justify-between justify-around'>
                            <Link to={`${ROUTES.forum.href}/${FORUM_PATH.aboutUs}`} className='w-28 text-nav-1 font-medium'>{t('about')}</Link>
                            <div className='w-[1px] bg-border-6 md:block hidden'></div>
                            <Link to={`${ROUTES.forum.href}/${FORUM_PATH.miaPolicy}`} className='w-28 text-nav-1 font-medium'>{t('policy')}</Link>
                        </div>
                        <div className='h-1/2 flex md:justify-between justify-around'>
                            <Link to={`${ROUTES.forum.href}/${FORUM_PATH.career}`} className='w-28 text-nav-1 font-medium'>{t('career')}</Link>
                            <div className='w-[1px] bg-border-6 md:block hidden'></div>
                            <Link to={`${ROUTES.forum.href}/${FORUM_PATH.privacyPolicy}`} className='w-28 text-nav-1 font-medium'>{t('privacy')}</Link>
                        </div>
                    </div>
                    <div className='registed'>
                        <img src={Registed} className='h-12 md:h-15.5' alt='registed' />
                    </div>
                </div>
                <div className='mt-5 flex flex-col justify-center items-center text-tab-1'>
                    <p className='text-center'>{t('company_name')}</p>
                    <p className='text-center'>{t('company_location')}</p>
                </div>
                <div className='bg-white md:h-11 mt-4 flex justify-center items-end md:border-t md:border-t-border-7'>
                    <div className='text-text-6 md:text-base text-[0.75rem] text-center'>Copyright © 2024. All rights reserved.</div>
                </div>
            </div>
        </footer>
    );
}

import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Label } from '@/components/common/Label';
import { Switch } from '@/components/common/Switch';
import { Textarea } from '@/components/common/Textarea';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StarRating from './StarRating';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Carousel, CarouselContent, CarouselItem } from '@/components/common/Carousel';
import { checkImageSupportFormat, hideFullName } from '@/utils/utils';
import { useStore } from '@/hooks/useStore';
import { loadFilePreview, loadURLPreview } from '@/utils/browsers';
import { useEffect } from 'react';
import { Input } from '@/components/common/Input';
import { ImagePlusIcon, XIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { useParams } from 'react-router-dom';
import { toastify } from '@/utils/toastify';
type Props = {
    id?: number,
    code?: string
};
export default observer(function ReviewForm({ id, code }: Props) {
    // hooks
    const { t } = useTranslation();
    const { slug } = useParams();

    // store
    const {
        modalStore: { showAlertModal, hideModal },
        shopStore: { postReview, getAllReviewForShopDetail, getShopDetail },
        authStore: { profile },
        reviewStore: { getReviewDetail, editReviewDetail, getAllMyReview }
    } = useStore();

    // validate Schema
    const validateSchema = yup.object().shape({
        incognito: yup.boolean(),
        serviceQuality: yup
            .number()
            .required('form_error_validate.required')
            .min(1, 'form_error_validate.required'),
        comment: yup
            .string()
            .max(2000)
            .nullable(),
        images: yup
            .array()
            .of(yup.mixed<string | File>().required('form_error_validate.required'))
            .checkFileImagesOfArrayExt('form_error_validate.shop_image_upload_format')
            .max(5, 'form_error_validate.post_review_image_upload_limit')
            .nullable()
    });

    // state
    type FormData = yup.InferType<typeof validateSchema>;
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        defaultValues: {
            incognito: false,
            serviceQuality: 5,
            comment: null,
            images: []
        }
    });
    const watchImages = form.watch('images');

    // lifecycle
    useEffect(() => {
        if (Array.isArray(watchImages) && watchImages.length > 0) {
            if (watchImages instanceof Array) {
                watchImages.forEach((image, index) => {
                    if (image instanceof File) {
                        loadFilePreview(image, `preview-image-${index}`);
                    } else {
                        loadURLPreview(image, `preview-image-${index}`);
                    }
                });
            }
        }
    }, [watchImages]);

    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id]);

    // function
    const fetchData = async (id: number) => {
        if (id) {
            const res = await flowResult(getReviewDetail(id));
            if (res) {
                form.reset({
                    incognito: res.incognito,
                    serviceQuality: res.serviceQuality,
                    comment: res.comment,
                    images: res.images
                });
            }
        }
    };

    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const existingImages = watchImages || [];
            const totalImages = existingImages.length + files.length;
            if (totalImages > 5) {
                showAlertModal({
                    type: 'error',
                    content: t('sentences.maximum_review_image')
                });
                return;
            }

            if (checkImageSupportFormat(files)) {
                form.setValue('images', [...(files instanceof FileList ? Array.from(files) : []), ...existingImages], { shouldValidate: true });
            } else {
                showAlertModal({
                    type: 'error',
                    content: t('form_error_validate.shop_image_upload_format')
                });
            }
            e.target.value = '';
        }
    };

    const handleRomoveImg = (index: number) => {
        if (Array.isArray(watchImages) && watchImages.length > 0) {
            form.setValue('images', [
                ...watchImages.slice(0, index),
                ...watchImages.slice(index + 1)
            ], { shouldValidate: true });
        }
    };

    const onSubmit = async (FormData: FormData) => {
        const { incognito, serviceQuality, comment, images } = FormData;
        // post review
        if (slug) {
            const res = await flowResult(postReview({ slug, incognito, serviceQuality, comment, images }));
            if (res) {
                getShopDetail(slug ?? '');
                getAllReviewForShopDetail(slug ?? '');
                toastify('alert-success', t('messages.update_success'));
                hideModal('write-review');
            }
        }

        // post review pet care history
        if (code) {
            const res = await flowResult(postReview({ slug: code, incognito, serviceQuality, comment, images }));
            if (res) {
                hideModal('add-review');
                toastify('alert-success', t('messages.update_success'));
            }
        }

        // edit review
        const imageFiles: File[] = [];
        const imageStrings: string[] = [];
        if (images) {
            images.forEach((image: string | File) => {
                if (image instanceof File) {
                    imageFiles.push(image);
                } else {
                    imageStrings.push(image);
                }
            });
        }

        if (id) {
            const res = await flowResult(editReviewDetail({
                incognito,
                serviceQuality,
                comment,
                images: imageStrings,
                imageFiles,
                id
            }));
            if (res) {
                getAllMyReview();
                toastify('alert-success', t('messages.update_success'));
                hideModal('edit-review');
            }
        }
    };

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className='flex space-x-3 items-center'>
                        <Label className='label-1 leading-[18px]'>{t('words_title.hide_fullname')}</Label>
                        <FormField
                            control={form.control}
                            name='incognito'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Switch
                                            checked={field.value}
                                            onCheckedChange={(checked) => {
                                                field.onChange(checked);
                                            }}
                                            className='mt-1'
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    {
                        form.getValues('incognito') && (
                            <div className='w-full overflow-hidden break-all'>
                                <p className='text-text-4 text-small'>
                                    {t('sentences.show_hide_fullname')}
                                    {' '}
                                    {hideFullName(profile?.fullName ?? '')}
                                </p>
                            </div>
                        )
                    }
                    <div className='mt-[1rem]'>
                        <Label className='label-1' required>{t('words_title.service_quality')}</Label>
                        <FormField
                            control={form.control}
                            name='serviceQuality'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <StarRating
                                            className='mt-[0.375rem]'
                                            value={field.value}
                                            onChange={newValue => field.onChange(newValue)}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='mt-[1rem]'>
                        <Label className='label-1'>{t('words_title.comments2')}</Label>
                        <FormField
                            control={form.control}
                            name='comment'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Textarea
                                            className='mt-[0.375rem] resize-none h-[133px]'
                                            value={field.value ?? ''}
                                            maxLength={2000}
                                            onChange={e => field.onChange(e.target.value)}
                                            placeholder={t('placeholder.comment_post_review')}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='mt-[1rem]'>
                        <FormField
                            control={form.control}
                            name='images'
                            render={({ field: { onChange, value, ...rest } }) => (
                                <>
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                id='reivew-images'
                                                type='file'
                                                onChange={(event) => {
                                                    handleImageFileChange(event);
                                                }}
                                                className='hidden'
                                                multiple={true}
                                                {...rest}
                                            />
                                        </FormControl>
                                        <Label htmlFor='reivew-images' className='flex items-center justify-center cursor-pointer border border-input rounded-lg py-3'>
                                            <ImagePlusIcon className='text-active h-6 w-6 mr-2' />
                                            <span className='text-placeholder-1 font-medium text-[0.9375rem] leading-4'>{t('button.add_image2')}</span>
                                        </Label>
                                        <FormMessage />
                                    </FormItem>
                                    <Carousel
                                        opts={{
                                            align: 'start'
                                        }}
                                        className='w-full my-4'
                                    >
                                        <CarouselContent>
                                            {
                                                Array.isArray(watchImages) && watchImages.length > 0 &&
                                                Array.from(watchImages).map((_, index) => (
                                                    <CarouselItem className='relative w-[20%] basis-1/5' key={index}>
                                                        <img className='w-full h-full object-cover aspect-square rounded' id={`preview-image-${index}`} alt='' />
                                                        <button
                                                            type='button'
                                                            onClick={() => handleRomoveImg(index)}
                                                            className='absolute top-1 left-5 bg-black bg-opacity-80 rounded-full p-1 shadow-lg'
                                                        >
                                                            <XIcon className='size-3  text-white' />
                                                        </button>
                                                    </CarouselItem>
                                                ))
                                            }
                                        </CarouselContent>
                                    </Carousel>
                                </>
                            )}
                        />

                    </div>
                    <div className=''>
                        <Button disabled={form.formState.isSubmitting} type='submit' className='w-full font-medium text-[0.9375rem] leading-4'>{t('button.submit_review_shop_detail')}</Button>
                    </div>
                </form>
            </Form>
        </>
    );
});

import { DISPLAY_MOMENT_DATE_DMYHM_AM_PM, MAX_LENGTH_TEXT_MOBILE } from '@/configs/constants';
import { AllReviewShopResponse } from '@/types/shop';
import { formatDateTime } from '@/utils/datetime';
import { hideFullName, shortenParagraph } from '@/utils/utils';
import { Star } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
    review: AllReviewShopResponse
};
export default observer(function ReviewItem({ review }: Props) {
    // hooks
    const { t } = useTranslation();

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    // function
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <>
            <div className='px-4 py-3 bg-white'>
                <div className='flex space-x-2'>
                    <div className='flex-none size-[2.375rem] max-w-[2.375rem] h-w-[2.375rem] rounded-full bg-background-avatar flex justify-center items-center'>
                        <div className='text-white text-2xl font-medium'>{review?.user?.fullName[0]}</div>
                    </div>
                    <div className='overflow-hidden flex-1'>
                        <div className='text-text-3 font-medium'>{review?.incognito ? hideFullName(review?.user?.fullName!) : review?.user?.fullName}</div>
                        <div className='flex justify-between items-center'>
                            <p className='text-text-4 text-small line-clamp-1'>{formatDateTime(review?.createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}</p>
                            <div className='flex items-center space-x-1'>
                                <div className='text-text-3'>{review?.serviceQuality?.toFixed(1)}</div>
                                <div className='text-icon-3 flex items-center space-x-0.5 w'>
                                    {
                                        Array.from({ length: 5 }, (_, i) => {
                                            const ratingValue = Number(review?.serviceQuality);
                                            if (i < Math.floor(ratingValue)) {
                                                return <Star key={i} className='fill-current text-icon-3 size-4' />;
                                            } else if (i === Math.floor(ratingValue) && ratingValue % 1 !== 0) {
                                                return (
                                                    <div key={i} className='relative inline-block size-4'>
                                                        <Star className='fill-current text-gray-300 size-4' />
                                                        <div
                                                            className='absolute top-0 left-0 h-full overflow-hidden'
                                                            style={{
                                                                width: `${(ratingValue % 1) * 100}%`
                                                            }}
                                                        >
                                                            <Star className='fill-current text-icon-3 size-4' />
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return <Star key={i} className='fill-current text-gray-300 size-4' />;
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='my-2 text-text-3 whitespace-pre-line'>
                    {shortenParagraph(review?.comment, MAX_LENGTH_TEXT_MOBILE, isExpanded).value}
                    {
                        shortenParagraph(review?.comment, MAX_LENGTH_TEXT_MOBILE, isExpanded).isEllipsis && (
                            <button
                                onClick={toggleExpand}
                                className='text-active text-[0.8125rem] text-blue-500 no-underline'
                            >
                                {isExpanded ? t('button.view_less') : t('button.view_more')}
                            </button>
                        )
                    }
                </p>
                <div className='flex overflow-x-auto scrollbar-height-2 scrollbar-width-4 justify-start items-center sm:justify-normal mt-[6px] gap-2'>
                    {
                        review?.images?.map((image, index) => (
                            <img key={index} className='w-28 h-20 rounded-md object-cover my-0.5' src={image} alt='' />
                        ))
                    }
                </div>
            </div>
        </>
    );
});

import { PetCareApi } from '@/apis';
import { flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { ResponseData } from '@/types/http';
import { GetAllPetCareHistoryResponse, PetCareHistoryInfo, PetCareRequest } from '@/types/http-payload/pet';
import { TableState } from '@tanstack/react-table';

export default class PetCareStore extends BaseStore {
    api: PetCareApi;
    totalPage: number = 0;
    allPetCareHistory: PetCareHistoryInfo[] = [];
    detailPetCareHistory: PetCareHistoryInfo | null = null;

    petNotePaging: TableState = {
        pagination: {
            pageIndex: 0,
            pageSize: 10
        },
        sorting: [{}]
    } as TableState;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            totalPage: observable,
            petNotePaging: observable,
            allPetCareHistory: observable,
            detailPetCareHistory: observable,
            createPetCareHistory: flow.bound,
            getAllPetCareHistory: flow.bound,
            deletePetCareHistory: flow.bound,
            getDetailPetCareHistory: flow.bound,
            updatePetCareHistory: flow.bound
        });
        this.api = new PetCareApi();
        this.paging = this.petNotePaging;
    }

    *createPetCareHistory(payload: PetCareRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.createPetCareHistory, payload);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    }

    * getAllPetCareHistory(paging?: TableState, payload?: any) {
        try {
            payload = { ...this.convertPagingFromTableToRequest(paging ?? this.petNotePaging), ...payload };
            const res: ResponseData<GetAllPetCareHistoryResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllPetCareHistory, payload);
            if (res?.ok) {
                this.petNotePaging = {
                    ...this.petNotePaging,
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalRecord = res?.data?.pagination?.totalRecord;
                this.totalPage = res?.data?.pagination?.totalPage;
                this.allPetCareHistory = res?.data?.elements || [];
                return res;
            }
        } catch (error) {
        }
    }

    *deletePetCareHistory(code: string) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.deletePetCareHistory, code);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    };

    *getDetailPetCareHistory(code: string) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getDetailPetCareHistory, code);
            if (res?.ok) {
                this.detailPetCareHistory = res.data;
                return res?.data;
            }
        } catch (error) {
        }
    };

    *updatePetCareHistory(data) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.updatePetCareHistory, data);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
        }
    };
}

import ApiService from './ApiService';

export default class GeneralApi extends ApiService {
    getAdministrativeUnit() {
        return this.get('administrative-unit');
    }

    getPlaceAutoComplete(payload) {
        return this.get('place-autocomplete', payload);
    }

    getGereralSettingByType(type: string) {
        return this.get(`general-setting/${type}`);
    }
}

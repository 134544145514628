import Tooltip from '@/components/common/Tooltip';
import { ACTIVITY_PATH, DISPLAY_MOMENT_DATE_YMD, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, ROUTES, SHOP_SERVICE_NAME } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { SHOP_SERVICE_TYPE, SHOP_STATUS_TYPE } from '@/types/enums';
import { AppointmentInfo } from '@/types/shop';
import { formatDateTime } from '@/utils/datetime';
import { formatNumber } from '@/utils/utils';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface AppointmentItemProps {
    appointment: AppointmentInfo
}

export default observer(function UpcomingItem({ appointment }: AppointmentItemProps) {
    const { shop } = appointment;
    const isShopInactive = shop.status !== SHOP_STATUS_TYPE.Active || shop.displayStatus === false || shop.operatingStatus === false;

    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        uiStore: { windowSize: { width } }
    } = useStore();

    // state
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [thirdLineIndex, setThirdLineIndex] = useState<number | null>(null);

    // lifecycle
    useEffect(() => {
        if (containerRef.current) {
            const spans = containerRef.current.querySelectorAll('span');
            if (spans.length > 0) {
                let initialOffsetTop = spans[0].offsetTop;
                let lineCount = 1;

                for (let i = 1; i < spans.length; i++) {
                    if (spans[i].offsetTop > initialOffsetTop) {
                        lineCount++;
                        initialOffsetTop = spans[i].offsetTop;
                    }

                    if (lineCount === 3) {
                        setThirdLineIndex(i);
                        break;
                    }
                }
            }
        }
    }, [width, appointment]);

    return (
        <div className='border border-l-2 border-l-icon-10 rounded-sm'>
            <div className='m-2 flex flex-col md:flex-row md:m-4 justify-between'>
                <div className='w-full md:w-3/4 point-920:w-[80%] text-title-1 font-medium line-clamp-1'>
                    <Tooltip
                        content={isShopInactive ? t('words_title.shop_is_not_available') : null}
                        triggerAsChild
                    >
                        <button
                            onClick={(e) => {
                                if (!isShopInactive) {
                                    window.open(`${ROUTES.shopDetail.href}/${shop?.slug}`, '_blank');
                                }
                                e.preventDefault();
                            }}
                            disabled={isShopInactive}
                            className={classNames(
                                'font-medium line-clamp-1 pb-[1px] text-start',
                                { 'hover:underline': !isShopInactive }
                            )}
                        >
                            <div className='line-clamp-1'>
                                {appointment.shop?.name}
                            </div>
                        </button>
                    </Tooltip>

                </div>
                <div className='flex flex-1 justify-start md:justify-end space-x-2 w-1/2 dm:w-1/4 point-920:w-[20%]'>
                    <p className='text-tab-1 font-normal text-[0.8125rem] md:text-[0.875rem] whitespace-nowrap overflow-hidden overflow-ellipsis'>
                        {formatDateTime(appointment?.appointmentDate, DISPLAY_MOMENT_DATE_YMD)}
                    </p>
                    <p className='text-tab-1 font-normal text-[0.8125rem] md:text-[0.875rem]'>
                        {formatDateTime(appointment?.appointmentTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                            onlyTime: true,
                            currentformat: DISPLAY_MOMENT_TIME_HMS
                        })}
                    </p>
                </div>
            </div>
            <hr className='border-t border-border-8 mx-2 md:mx-4' />
            <div onClick={() => navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.appointment}/${appointment?.code}`)} className='m-2 md:my-3 md:mx-4'>
                <div className='flex'>
                    <p className='text-tab-1 font-normal min-w-24 md:w-32 text-[0.8125rem] md:text-[0.875rem]'>{t('words_title.services')}</p>
                    <div>
                        <div ref={containerRef} className='flex flex-wrap gap-1'>
                            {
                                appointment?.selectedServices?.length > 0 && appointment?.selectedServices.slice(0, thirdLineIndex ?? appointment.selectedServices.length).map((service, index) => (
                                    <span
                                        key={index}
                                        className='font-normal rounded-xl bg-background-4 py-1 px-1.5 max-w-[20ch] md:max-w-[30ch] truncate'
                                    >
                                        {
                                            (service.type === SHOP_SERVICE_TYPE.Cat || service.type === SHOP_SERVICE_TYPE.Dog) ?
                                                t(`select_options.${SHOP_SERVICE_NAME[service.serviceName]?.key}`) :
                                                service?.serviceName
                                        }
                                    </span>
                                ))
                            }
                        </div>
                        {thirdLineIndex !== null && thirdLineIndex < appointment?.selectedServices?.length && (
                            <div className='mt-2'>
                                <span
                                    className='font-normal py-1 px-1.5 max-w-[20ch] truncate text-text-4'
                                >
                                    {`+${appointment.selectedServices.length - thirdLineIndex} ${t('words_title.others')}`}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className='mt-3 flex items-center'>
                    <p className='text-tab-1 font-normal min-w-24 md:w-32 text-[0.8125rem] md:text-[0.875rem]'>{t('words_title.total_amount')}</p>
                    {appointment?.totalAmount !== undefined && (
                        <p className='font-semibold text-text-3 pl-1 text-[0.8125rem] md:text-[0.875rem]'>{`${formatNumber(appointment.totalAmount)} VND`}</p>
                    )}
                </div>
            </div>
        </div>
    );
});

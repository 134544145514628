import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../common/Select';
import { Control, useController } from 'react-hook-form';
import { generateMinuteOptions } from '@/utils/utils';
import { DISPLAY_MOMENT_TIME_HM, DISPLAY_MOMENT_TIME_HM_AM_PM_2, DISPLAY_MOMENT_TIME_HMS, TIME_STEP_SELECT } from '@/configs/constants';
import classNames from 'classnames';
import { Label } from '../common/Label';
import { formatDateTime } from '@/utils/datetime';

interface StepTimeSelectProps {
    title?: string,
    name: string,
    control: Control<any>,
    className?: string,
    min?: string,
    max?: string,
    required?: boolean
}

export default function StepTimeSelect({ title, name, control, className, min, max, required = false }: StepTimeSelectProps) {
    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        defaultValue: ''
    });

    const format12hData = formatDateTime(value, DISPLAY_MOMENT_TIME_HM_AM_PM_2, {
        onlyTime: true,
        currentformat: DISPLAY_MOMENT_TIME_HMS
    });

    const [hour, minute, period] = value && format12hData ? format12hData.split(':') : ['', '', 'AM'];
    const hourOptions = [...Array(12).keys()].map(h => String(h + 1).padStart(2, '0'));
    const minuteOptions = generateMinuteOptions(TIME_STEP_SELECT);

    // function

    return (
        <div className={classNames('flex flex-col gap-1.5', className)}>
            {title && (<Label required={required} className='label-1'>{title}</Label>)}
            <div className='flex items-center gap-1.5'>
                <Select
                    className='w-1/3'
                    onValueChange={h => onChange(formatDateTime(`${h}:${minute}:${period}`, DISPLAY_MOMENT_TIME_HM, {
                        onlyTime: true,
                        currentformat: DISPLAY_MOMENT_TIME_HM_AM_PM_2
                    }))}
                    value={hour}
                >
                    <SelectTrigger>
                        <SelectValue>
                        </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                        {hourOptions.map((item, index) => (
                            <SelectItem
                                className='hover:cursor-pointer'
                                key={index}
                                value={item}
                            >
                                {item}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <div className='font-bold'>:</div>
                <Select
                    className='w-1/3'
                    onValueChange={m => onChange(formatDateTime(`${hour}:${m}:${period}`, DISPLAY_MOMENT_TIME_HM, {
                        onlyTime: true,
                        currentformat: DISPLAY_MOMENT_TIME_HM_AM_PM_2
                    }))}
                    value={minute}
                >
                    <SelectTrigger>
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                        {minuteOptions.map(m => (
                            <SelectItem key={m} value={m}>
                                {m}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <Select
                    className='w-1/3'
                    onValueChange={p => onChange(formatDateTime(`${hour}:${minute}:${p}`, DISPLAY_MOMENT_TIME_HM, {
                        onlyTime: true,
                        currentformat: DISPLAY_MOMENT_TIME_HM_AM_PM_2
                    }))}
                    value={period}
                >
                    <SelectTrigger className='text-placeholder-1'>
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                        {['AM', 'PM'].map(p => (
                            <SelectItem key={p} value={p}>
                                {p}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
        </div>
    );
};

import { Button } from '@/components/common/Button';
import { Checkbox } from '@/components/common/Checkbox';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/common/Form';
import { SheetHeader, SheetTitle, SheetDescription, SheetFooter } from '@/components/common/Sheet';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { sortFilterProvinces } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronLeft } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface FormAllCityProps {
    handleBack?: Function
}

export default observer(function FormAllCity({ handleBack }: FormAllCityProps) {
    // hook
    const { t, i18n } = useTranslation();

    // store
    const {
        generalStore: { administrativeUnit },
        uiStore: { listCitySelectedValue, setObservable, filterData }
    } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        city: yup.array().of(yup.string().required())
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            city: []
        }
    });

    // licecycle
    useEffect(() => {
        if (filterData?.city && filterData.city.length > 0) {
            form.setValue('city', filterData.city);
        }
    }, []);
    // function
    const provinces = useMemo(() => {
        const currentProvinces = administrativeUnit?.provinces?.length ? [...administrativeUnit?.provinces] : [];
        const afterSort = sortFilterProvinces(currentProvinces, []);
        return afterSort;
    }, [administrativeUnit?.provinces]);

    const onSubmit = (data: FormData) => {
        let clonefilterData = { ...filterData };
        clonefilterData.city = data?.city || [];
        setObservable('filterData', clonefilterData, { isMergeObject: true });
        handleBack && handleBack(true);
    };

    const handleClearAll = () => {
        form.reset();
    };

    const handleBackFilter = () => {
        setObservable('filterData', { ...filterData, city: form.getValues('city') || [] }, { isMergeObject: false });
        handleBack && handleBack(true);
    };
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='h-full flex flex-col gap-0 overflow-hidden'>
                <SheetHeader className='py-6 px-4 h-[4.5rem] flex items-center justify-center'>
                    <SheetTitle className='text-[0.9375rem]'>{t('words_title.all_city')}</SheetTitle>
                    <SheetDescription></SheetDescription>
                    <div onClick={() => handleBackFilter()} className='absolute left-4 top-[26px] '><ChevronLeft className='h-6 w-6 text-black cursor-pointer' /></div>
                    <div onClick={() => handleClearAll()} className='absolute right-4 top-[26px] text-active text-[0.9375rem] cursor-pointer hover:underline'>{t('button.clear_all')}</div>
                </SheetHeader>
                <div className='bg-white flex-1 overflow-x-hidden overflow-y-scroll scrollbar scrollbar-width-2'>
                    <FormField
                        control={form.control}
                        name='city'
                        render={() => (
                            <FormItem>
                                {provinces.map(item => (
                                    <FormField
                                        key={item.code}
                                        control={form.control}
                                        name='city'
                                        render={({ field }) => {
                                            return (
                                                <FormItem
                                                    key={item.code}
                                                    className='px-4 hover:bg-accent'
                                                >
                                                    <div className='py h-10 grid grid-cols-[1fr,1rem] items-center justify-between border-b border-b-border-6'>
                                                        <FormLabel className='font-medium cursor-pointer'>
                                                            {i18n.language === 'vi' ? item.name : i18n.language === 'en' ? item.nameEn : item.name}
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={field.value?.includes(item.code)}
                                                                onCheckedChange={(checked) => {
                                                                    return checked ?
                                                                        field.onChange(
                                                                            field.value?.length ? [...field.value, item.code] : [item.code]
                                                                        ) :
                                                                        field.onChange(
                                                                            field.value?.filter(
                                                                                value => value !== item.code
                                                                            )
                                                                        );
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </FormItem>
                                            );
                                        }}
                                    />
                                ))}
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <SheetFooter className='w-full block'>
                </SheetFooter>
            </form>
        </Form>
    );
});

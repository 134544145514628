import { useStore } from '@/hooks/useStore';
import { INFO_TYPE, LANGUAGES_SUPPORTED } from '@/types/enums';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { GetGeneralInfoResponse } from '@/types/http-payload/shop';
import { useTranslation } from 'react-i18next';
import MyShopItem from '../../components/MyShopItem';
import { DEFAULT_NULL_VALUE, REPRESENTATIVE_ROLE, SHOP_CATEGORY } from '@/configs/constants';

export default observer(function GeneralInfoTab() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const { shopStore: { getShopInfo } } = useStore();

    // state
    const [shopInfo, setShopInfo] = useState<GetGeneralInfoResponse>();
    const getCategoryStr = useMemo(() => {
        const indexCategory = Object.keys(SHOP_CATEGORY).findIndex(item => item === shopInfo?.category?.toString());
        const indexRole = Object.keys(REPRESENTATIVE_ROLE).findIndex(item => item === shopInfo?.representativeRole?.toString());

        if (indexCategory !== -1 && indexRole !== -1) {
            return [t(`select_options.${SHOP_CATEGORY[indexCategory + 1]?.key}`), t(`select_options.${REPRESENTATIVE_ROLE[indexRole + 1]?.key}`)];
        } else if (indexCategory === -1 && indexRole !== -1) {
            return ['', t(`select_options.${REPRESENTATIVE_ROLE[indexRole + 1]?.key}`)];
        } else if (indexCategory !== -1 && indexRole === -1) {
            return [t(`select_options.${SHOP_CATEGORY[indexCategory + 1]?.key}`), ''];
        } else {
            return ['', ''];
        }
    }, [shopInfo, language]);

    // lifecycle
    useEffect(() => {
        fetchData();
    }, []);

    // function
    const fetchData = async () => {
        const res = await flowResult(getShopInfo({ typeInfo: INFO_TYPE.GeneralInfo }));
        if (res && res.ok) {
            setShopInfo(res.data as GetGeneralInfoResponse);
        }
    };

    return (
        <div className='flex flex-col md:gap-4 gap-1.5'>
            <div className='container-shop-info-destop'>
                <MyShopItem classname='md:col-span-2 col-span-1' title={t('words_title.shop_name')} desc={<div>{shopInfo?.name ?? DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem title={t('words_title.tax_code')} desc={<div>{shopInfo?.taxCode ?? DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem title={t('words_title.category')} desc={<div>{getCategoryStr[0]}</div>} />
                <MyShopItem classname='md:col-span-2 col-span-1' title={t('words_title.shop_address')} desc={<div>{shopInfo?.address ?? DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem title={t('words_title.city')} desc={<div>{(language === LANGUAGES_SUPPORTED.English ? shopInfo?.province.fullNameEn : shopInfo?.province.fullName) ?? DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem title={t('words_title.phone_number')} desc={<div>{shopInfo?.phoneNumber ?? DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem title={t('words_title.zalo_id')} desc={<div>{shopInfo?.zaloId ? shopInfo.zaloId : DEFAULT_NULL_VALUE}</div>} />
            </div>

            <div className='container-shop-info-destop'>
                <MyShopItem title={t('words_title.owner_name')} desc={<div>{shopInfo?.ownerName ?? DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem title={t('words_title.phone_number')} desc={<div>{shopInfo?.ownerPhoneNumber ?? DEFAULT_NULL_VALUE}</div>} />
            </div>

            <div className='container-shop-info-destop'>
                <MyShopItem title={t('words_title.representative_name')} desc={<div>{shopInfo?.representativeName ?? DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem title={t('words_title.phone_number')} desc={<div>{shopInfo?.representativePhoneNumber ?? DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem title={t('words_title.role')} desc={<div>{getCategoryStr[1]}</div>} />
                <MyShopItem title={t('words_title.email')} desc={<div>{shopInfo?.representativeEmail ? shopInfo?.representativeEmail : DEFAULT_NULL_VALUE}</div>} />
            </div>
        </div>
    );
});

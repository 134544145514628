import { observer } from 'mobx-react-lite';

type Props = {
    image: string,
    url: string
};
export default observer(function InformationShopItem({ image, url }: Props) {
    return (
        <>
            {
                url && (
                    <div className='mt-1.5 flex space-x-2 text-small items-start'>
                        <img className='size-[0.8125rem] mt-1' src={image} alt='fb' />
                        <a
                            target='_blank'
                            className='break-words w-full pr-5 underline text-link-1 cursor-pointer'
                            href={url}
                            rel='noreferrer'
                        >
                            {url}
                        </a>
                    </div>
                )
            }
        </>
    );
});

import { convertPetWeight, convertServicePrice } from '@/utils/utils';
import classNames from 'classnames';
import { TFunction } from 'i18next';

interface serviceAppointmentButtonProps {
    petWeightFrom?: string,
    petWeightTo?: string,
    price?: number,
    active?: boolean,
    t?: TFunction,
    isDelete?: boolean
}

export default function serviceAppointmentButton({ petWeightFrom, petWeightTo, price, active, t, isDelete }: serviceAppointmentButtonProps) {
    return (
        <>

            <p className={classNames(isDelete && 'line-through')}>
                <span className={classNames(!active && 'group-hover:text-active')}>{t ? t('words_title.pet_weight') : 'Pet Weight'}</span>
                :
                <span className={classNames('font-normal', active ? 'text-white' : 'text-black group-hover:text-active')}>
                    {' '}
                    {convertPetWeight(petWeightFrom, petWeightTo, t)}
                </span>
            </p>
            <p className={classNames(isDelete && 'line-through')}>
                <span className={classNames(!active && 'group-hover:text-active')}>{t ? t('words_title.appointment_price') : 'Price'}</span>
                :
                <span className={classNames('font-semibold ', active ? 'text-white' : 'text-black group-hover:text-active')}>
                    {' '}
                    {convertServicePrice(price)}
                </span>
            </p>
        </>
    );
};

import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogPortal, DialogTitle } from '../ui/DialogUI';
import { DialogClose } from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { cva } from 'class-variance-authority';
import { cn } from '@/utils/utils';
import ModalFooter from './ModalFooter';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

export const modalVariants = cva(
    '',
    {
        variants: {
            type: {
                default: '',
                success: 'text-[#07bc0c]',
                error: 'text-[#e74c3c]',
                info: 'text-[#3498db]',
                warn: 'text-active'
            },
            size: {
                'default': 'max-w-lg',
                'alert': 'max-w-lg',
                'xs': 'max-w-xs',
                'sm': 'max-w-sm',
                'md': 'max-w-md',
                'lg': 'max-w-lg',
                'xl': 'max-w-xl',
                '2xl': 'max-w-2xl',
                '3xl': 'max-w-3xl',
                '4xl': 'max-w-4xl',
                '5xl': 'max-w-5xl',
                '6xl': 'max-w-6xl',
                '7xl': 'max-w-7xl',
                'auto': ''
            }
        },
        defaultVariants: {
            type: 'default',
            size: 'default'
        }
    }
);

export default observer(function Modals() {
    // store
    const { modalStore: { instances, hideModal } } = useStore();

    const defaultTitleModalClass = '';

    return (
        <>
            {
                instances.map((modal, index) => {
                    return (
                        <Dialog
                            key={modal.id ?? index}
                            open={true}
                            onOpenChange={() => { }}
                        >
                            <DialogPortal>
                                <DialogContent
                                    className={cn('max-w-[90vw]', modalVariants({ size: modal.size }))}
                                    onPointerDownOutside={() => {
                                        modal.closeWhenOutside && hideModal(modal.id);
                                    }}
                                >
                                    <div className='relative'>
                                        {
                                            modal.showHeader ?
                                                (
                                                    <DialogHeader className={modal.headerClassName}>
                                                        <DialogTitle
                                                            className={cn(
                                                                defaultTitleModalClass,
                                                                modalVariants({ type: modal.type, size: 'auto' }),
                                                                modal.titleClassName
                                                            )}
                                                        >
                                                            {modal.title}
                                                        </DialogTitle>
                                                        {
                                                            modal.description && (
                                                                <DialogDescription>
                                                                    {modal.description}
                                                                </DialogDescription>
                                                            )
                                                        }
                                                        {modal.headerRender}
                                                    </DialogHeader>
                                                ) :
                                                (
                                                    <VisuallyHidden>
                                                        <DialogHeader className={modal.headerClassName}>
                                                            <DialogTitle
                                                                className={cn(
                                                                    defaultTitleModalClass,
                                                                    modalVariants({ type: modal.type, size: 'auto' }),
                                                                    modal.titleClassName
                                                                )}
                                                            >
                                                                {modal.title}
                                                            </DialogTitle>
                                                            {
                                                                modal.description && (
                                                                    <DialogDescription>
                                                                        {modal.description}
                                                                    </DialogDescription>
                                                                )
                                                            }
                                                            {modal.headerRender}
                                                        </DialogHeader>
                                                    </VisuallyHidden>
                                                )
                                        }
                                        {
                                            modal.showCloseIcon && (
                                                <DialogClose
                                                    className='absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-input h-6 w-6 flex items-center justify-center transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
                                                    onClick={() => hideModal(modal.id)}
                                                >
                                                    <X className='h-4 w-4' />
                                                    <span className='sr-only'>
                                                        Close
                                                    </span>
                                                </DialogClose>
                                            )
                                        }
                                    </div>
                                    <div className={cn('px-4', modal.contentContainerClassName)}>
                                        {modal.content}
                                    </div>
                                    <DialogFooter className={cn('flex items-center justify-end gap-4 px-4 pb-4', modal.footerClassName)}>
                                        {
                                            modal.footer ?
                                                modal.footer :
                                                (
                                                    <ModalFooter
                                                        cancelButton={modal.cancelButton}
                                                        cancelButtonClassName={modal.cancelButtonClassName}
                                                        onCancel={modal.onCancel}
                                                        saveButton={modal.saveButton}
                                                        saveButtonClassName={modal.saveButtonClassName}
                                                        onSave={modal.onSave}
                                                        variantCancelBtn={modal.variantCancelBtn}
                                                        variantSaveBtn={modal.variantSaveBtn}
                                                    />
                                                )
                                        }
                                    </DialogFooter>
                                </DialogContent>
                            </DialogPortal>
                        </Dialog>
                    );
                })
            }
        </>
    );
});

import { NotificationApi } from '@/apis';
import RootStore from '.';
import BaseStore from './BaseStore';
import { action, flow, makeObservable, observable } from 'mobx';
import { DeleteNotificationRequest, GetAllNotificationRequest, GetAllNotificationResponse, GetNotificationDetailRequest, MarkReadNotificationRequest } from '@/types/http-payload/notification';
import { TableState } from '@tanstack/react-table';
import { ResponseData } from '@/types/http';
import { Notification } from '@/types/notification';

export default class NotificationStore extends BaseStore {
    api: NotificationApi;
    totalNotReadNotification: number = 0;
    notifications: Notification[] = [];
    notification?: Notification;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            totalNotReadNotification: observable,
            notifications: observable,
            notification: observable,
            countNewNotification: action.bound,
            getAllNotification: flow.bound,
            getNotificationDetail: flow.bound,
            markReadNotification: flow.bound,
            deleteNotification: flow.bound
        });
        this.api = new NotificationApi();
        this.paging.pagination.pageSize = 10;
    }

    *getAllNotification(payload: GetAllNotificationRequest, paging?: TableState) {
        try {
            const payloadData = {
                ...this.convertPagingFromTableToRequest(paging ?? this.paging),
                ...payload,
                isChannelShopOwner: this.rootStore.authStore.isChannelShopOwner
            };
            const res: ResponseData<GetAllNotificationResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getAllNotification, payloadData);
            if (res?.ok) {
                this.notifications = res?.data?.elements || [];
                this.paging = {
                    ...this.paging,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res?.data?.pagination)
                };
                this.totalRecord = res?.data?.pagination?.totalRecord;
                this.totalPage = res?.data?.pagination?.totalPage;
            }
        } catch (error) {
        }
    }

    *getNotificationDetail(payload: GetNotificationDetailRequest) {
        try {
            const payloadData = {
                ...payload,
                isChannelShopOwner: this.rootStore.authStore.isChannelShopOwner
            };
            const res: ResponseData<Notification> = yield this.rootStore.apiStore.call(this.api, this.api.getNotificationDetail, payloadData);
            if (res?.ok) {
                this.notification = res?.data || null;
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    *markReadNotification(payload: MarkReadNotificationRequest) {
        try {
            const payloadData = {
                ...payload,
                isChannelShopOwner: this.rootStore.authStore.isChannelShopOwner
            };
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.markReadNotification, payloadData);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    *deleteNotification(payload: DeleteNotificationRequest) {
        try {
            const payloadData = {
                ...payload,
                isChannelShopOwner: this.rootStore.authStore.isChannelShopOwner
            };
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.deleteNotification, payloadData);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    countNewNotification() {
        this.totalNotReadNotification = this.totalNotReadNotification + 1;
    }

    clean(): void {
        super.clean();
        this.notifications = [];
        this.paging = this.defaultPaging;
        this.paging.pagination.pageSize = 10;
    }
}

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import TopicList from './topic-list';
import TopicDetail from './topic-detail';
import { ForumType } from '@/types/enums';

interface TopicProps {
    forumType: ForumType
}

export default observer(function Topic({ forumType }: TopicProps) {
    // hooks
    const { slug } = useParams();

    return (
        <>
            {slug ? <TopicDetail forumType={forumType} /> : <TopicList forumType={forumType} />}
        </>
    );
});

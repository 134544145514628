import ChatButton from '@/components/general/ChatButton';
import ContainerBody from '@/components/layout/ContainerBody';
import HeaderMobileFormat2 from '@/components/layout/header-component/HeaderMobileFormat2';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

export default observer(function HomePanelLayout({ children }: { children?: ReactNode }) {
    // hooks
    const { pathname, hash } = useLocation();
    const { slug } = useParams();

    // store
    const {
        authStore: { token, isChannelShopOwner },
        uiStore: { devicesScreen }
    } = useStore();

    const { mobile } = devicesScreen;

    // function
    // Check is on view-all page mobile of topservice section
    const isTopService = pathname === ROUTES.home.href && hash === '#topservice';
    const showHeader = !(mobile && (isTopService || (pathname.includes(ROUTES.nearBy.href) && slug)));
    const showFooter = !(pathname.includes(ROUTES.nearBy.href) && mobile);

    return (
        <>
            {/* Container Body */}
            <ContainerBody
                showHeader={showHeader}
                showFooter={showFooter}
                showFooterMenu={true}
                headerMobileComponentForShopOwner={<HeaderMobileFormat2 useMenuDropDown />}
            >
                {
                    children ?? <Outlet />
                }
            </ContainerBody>

            {/* Tin nhắn */}
            {token && <ChatButton />}
        </>
    );
});

import Datetime, { DatetimepickerProps } from 'react-datetime';
import { Button } from '../common/Button';
import { cn } from '@/utils/utils';
import { CalendarIcon, CircleXIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES_SUPPORTED } from '@/types/enums';
import { format } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';
import moment from 'moment';

// import 'moment/locale/vi';
// import 'moment/locale/en';

import './style.scss';

type CalendarDatetimeProps = DatetimepickerProps & {
    useIconRemove?: boolean
};

export default function CalendarDatetime({
    dateFormat,
    timeFormat,
    useIconRemove = true,
    onChange,
    locale,
    ...props
}: CalendarDatetimeProps) {
    // hooks
    const { i18n: { language } } = useTranslation();

    // render custom
    const InputCustomRender = (inputProps) => {
        const { value, placeholder, onChange: onChangeInput, onClick, onFocus } = inputProps;

        return (
            <Button
                variant='input'
                size='input'
                type='button'
                onClick={onClick}
                onFocus={onFocus}
                className={cn(
                    'relative justify-between form-control-group-child',
                    !value && 'text-placeholder-1'
                )}
            >
                {
                    value ? value : <span>{placeholder}</span>
                }
                <div className='flex items-center gap-2'>
                    {
                        value && useIconRemove && (
                            <CircleXIcon
                                className=' hover:text-red-500'
                                onPointerDown={(e) => {
                                    e?.stopPropagation();
                                    onChangeInput?.('');
                                }}
                            />
                        )
                    }
                    <CalendarIcon />
                </div>
            </Button>
        );
    };

    const MonthCustomRender = (monthProps, month) => {
        const { key, ...otherMonthProps } = monthProps;
        const formatMonth = language === LANGUAGES_SUPPORTED.Vietnamese ?
            {
                previousLabel: 'Tháng',
                format: 'MMMM',
                locale: vi
            } :
            {
                previousLabel: '',
                format: 'MMM',
                locale: enUS
            };
        return (
            <td key={key} {...otherMonthProps}>
                {
                    format(moment().set('month', month).toDate(), formatMonth.format, {
                        locale: formatMonth.locale
                    })
                }
            </td>
        );
    };

    return (
        <Datetime
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            onChange={onChange}
            renderInput={InputCustomRender}
            renderMonth={MonthCustomRender}
            locale={locale}
            {...props}
        />
    );
}

import CircleAddIcon from '@/assets/images/circleAddIcon.svg';
import Calendar from '@/components/calendar/Calendar';
import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import { RadioGroup, RadioGroupItem } from '@/components/common/RadioGroup';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import FormatNumber from '@/components/react-number-format';
import { MAX_PRICE_SERVICE, PET_CARE_HISTORY_TYPE, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { formatDateTime } from '@/utils/datetime';
import { toastify } from '@/utils/toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { XIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface HistoryFormProps {
    code?: string,
    typeService?: number
}

export default observer(function HistoryForm({ code, typeService }: HistoryFormProps) {
    // hooks
    const { t } = useTranslation();

    const {
        modalStore: { hideModal, showAlertModal, hideAllModals },
        petStore: { pets, petDetail, getAllPet },
        petCareStore: { createPetCareHistory, getDetailPetCareHistory, updatePetCareHistory, getAllPetCareHistory, petNotePaging }
    } = useStore();

    // validate Schema
    const validateSchema = yup.object().shape({
        petCode: yup.string().required('form_error_validate.required'),
        type: yup.string(),
        date: yup.date().required('form_error_validate.required'),
        shopName: yup.string().required('form_error_validate.required'),
        services: yup.array()
            .of(
                yup.object().shape({
                    serviceName: yup.string().required('form_error_validate.required')
                })
            )
            .min(1, 'form_error_validate.required'),
        totalAmount: yup
            .number()
            .min(1, 'form_error_validate.required')
            .transform(value => Number.isNaN(value) ? null : value)
            .required('form_error_validate.required')
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            petCode: pets[0]?.code,
            type: PET_CARE_HISTORY_TYPE.GROOMING_HISTORY.toString(),
            date: undefined,
            shopName: '',
            services: [],
            totalAmount: undefined
        }
    });

    const errors = form.formState.errors;

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'services'
    });

    // lifecycle
    useEffect(() => {
        fetchData();
    }, [code]);

    // functions
    const fetchData = async () => {
        if (code) {
            const res = await flowResult(getDetailPetCareHistory(code));
            form.reset({
                petCode: res?.pet.code,
                type: res?.type.toString(),
                date: new Date(res?.datetime),
                shopName: res?.shop?.name,
                services: res?.services,
                totalAmount: res?.totalAmount
            });
        }
    };

    const handleChangePet = async (petCode: string, pets: any, isChange: boolean = true) => {
        if (pets?.length > 0) {
            const findPet = pets.find(pet => pet.code === petCode);
            if (findPet) {
                form.setValue('petCode', findPet.code);
            }
        }
    };

    const onSubmit = async (data: FormData) => {
        const { petCode, type, date, shopName, services, totalAmount } = data;
        const dateFormat = formatDateTime(date, SUBMIT_MOMENT_DATE_YMD);
        const allPet = await flowResult(getAllPet());
        if (allPet) {
            const foundPet = allPet.find(pet => pet?.code === petCode);
            if (!!foundPet === true) {
                if (code) {
                    const res = await flowResult(updatePetCareHistory({
                        code,
                        petCode,
                        type: Number(type),
                        date: dateFormat ?? '',
                        shopName,
                        services: services ?? [],
                        totalAmount
                    }));
                    if (res) {
                        getAllPetCareHistory(
                            { ...petNotePaging, pagination: { ...petNotePaging.pagination, pageIndex: 0 } },
                            { type: typeService, petCode: petDetail?.code }
                        );
                        toastify('alert-success', t('messages.update_success'));
                    }
                    hideModal('edit-history');
                } else {
                    const res = await flowResult(createPetCareHistory({
                        petCode,
                        type: Number(type),
                        date: dateFormat ?? '',
                        shopName,
                        services: services ?? [],
                        totalAmount
                    }));
                    if (res) {
                        getAllPetCareHistory(
                            { ...petNotePaging, pagination: { ...petNotePaging.pagination, pageIndex: 0 } },
                            { type: typeService, petCode: petDetail?.code }
                        );
                        toastify('alert-success', t('messages.update_success'));
                    }
                    hideModal('add-history');
                }
            } else {
                hideAllModals();
                showAlertModal({
                    id: 'pet-note-modal',
                    type: 'error',
                    content: t('sentences.pet_is_no_available')
                });
            }
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-4'>
                    <FormField
                        control={form.control}
                        name='petCode'
                        render={({ field }) => (
                            <FormItem className='flex flex-col gap-1.5'>
                                <Label required className='font-medium'>{t('words_title.pet_name')}</Label>
                                <FormControl>
                                    <Select
                                        onValueChange={(value) => {
                                            field.onChange(value);
                                            handleChangePet(value, pets);
                                        }}
                                        value={field.value}
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder={t('placeholder.choose')} />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {pets.map((value, index) => {
                                                return (
                                                    <SelectItem key={index} value={value.code} className='w-[85vw] max-w-[470px]'>
                                                        <p className='overflow-ellipsis line-clamp-1'>
                                                            {value.name}
                                                        </p>
                                                    </SelectItem>
                                                );
                                            })}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='type'
                        render={({ field }) => (
                            <FormItem className='flex flex-col gap-1.5'>
                                <Label required className='font-medium'>{t('words_title.history')}</Label>
                                <FormControl>
                                    <RadioGroup
                                        className='flex gap-10 mt-2'
                                        value={field.value}
                                        onValueChange={field.onChange}
                                        defaultValue={PET_CARE_HISTORY_TYPE.GROOMING_HISTORY.toString()}
                                    >
                                        <div className='flex items-center space-x-2'>
                                            <RadioGroupItem value={PET_CARE_HISTORY_TYPE.GROOMING_HISTORY.toString()} id='grooming' />
                                            <Label htmlFor='grooming'>{t('select_options.grooming')}</Label>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <RadioGroupItem value={PET_CARE_HISTORY_TYPE.MEDICAL_HISTORY.toString()} id='medical' />
                                            <Label htmlFor='medical'>{t('select_options.medical')}</Label>
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='date'
                        render={({ field }) => (
                            <FormItem className='flex flex-col gap-1.5'>
                                <Label required className='font-medium'>{t('words_title.date')}</Label>
                                <FormControl>
                                    <Calendar
                                        placeholder={t('placeholder.choose')}
                                        type='datePicker'
                                        mode='single'
                                        selected={field.value}
                                        onSelect={field.onChange}
                                        useIconRemove={true}
                                        required
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='shopName'
                        render={({ field }) => (
                            <FormItem className='flex flex-col gap-1.5'>
                                <Label required className='font-medium'>{t('words_title.shop_name')}</Label>
                                <FormControl>
                                    <Input
                                        className='placeholder:text-placeholder-1'
                                        type='text'
                                        maxLength={255}
                                        placeholder={t('placeholder.enter')}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='services'
                        render={() => (
                            <FormItem className='flex flex-col gap-1.5'>
                                <Label required className='font-medium'>
                                    {t('words_title.services')}
                                </Label>
                                <div className='flex flex-col gap-1.5 max-h-56 overflow-y-auto pr-1 scrollbar scrollbar-width-4'>
                                    {fields.map((field, index) => (
                                        <FormField
                                            key={field.id}
                                            control={form.control}
                                            name={`services.${index}.serviceName`}
                                            render={({ field, fieldState }) => (
                                                <FormItem>
                                                    <div className='flex items-center gap-2'>
                                                        <FormControl className='flex-1'>
                                                            <Input
                                                                type='text'
                                                                placeholder={t('placeholder.enter')}
                                                                className='placeholder:text-placeholder-1'
                                                                {...field}
                                                                maxLength={150}
                                                            />
                                                        </FormControl>
                                                        <Button
                                                            disabled={fields.length === 1}
                                                            type='button'
                                                            variant='clear'
                                                            onClick={() => remove(index)}
                                                            hidden={fields.length === 1}
                                                            className='flex justify-center items-center bg-placeholder-1 bg-opacity-80 rounded-full p-1 shadow-lg w-4.5 h-4.5'
                                                        >
                                                            <XIcon className='w-3.5 h-3.5 text-white' />
                                                        </Button>
                                                    </div>
                                                    {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                                                </FormItem>
                                            )}
                                        />
                                    ))}
                                </div>
                                <div className='flex justify-center'>
                                    <Button
                                        type='button'
                                        variant='add'
                                        onClick={async () => {
                                            append({ serviceName: '' });
                                            form.clearErrors('services');
                                        }}
                                        className='px-2 mt-1 text-center h-8'
                                    >
                                        <img src={CircleAddIcon} alt='' className='mr-1' />
                                        {t('button.add_service')}
                                    </Button>
                                </div>
                                {errors.services?.message && <FormMessage>{errors.services.message}</FormMessage>}
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='totalAmount'
                        render={({ field }) => (
                            <FormItem className='flex flex-col gap-1.5'>
                                <Label required className='font-medium'>{t('words_title.total_amount')}</Label>
                                <FormControl>
                                    <FormatNumber
                                        minValue={1}
                                        maxValue={MAX_PRICE_SERVICE}
                                        decimalScale={0}
                                        statusDisplay='input'
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        value={field.value}
                                        setvalueOfNumericFormat={(values) => {
                                            field.onChange(values === '' ? values : Number(values));
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className='flex justify-between gap-6'>
                        <Button onClick={() => hideModal()} type='button' variant='clear' size='submit' className='mt-6 hidden md:block'>
                            {t('button.cancel')}
                        </Button>
                        <Button type='submit' variant='submit' size='submit' className='mt-6'>
                            {t('button.save')}
                        </Button>
                    </div>
                </div>
            </form>
        </Form>
    );
});

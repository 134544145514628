import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { CircleHelpIcon, Star } from 'lucide-react';
import { useStore } from '@/hooks/useStore';
import DualDatePicker from '@/components/calendar/DualDatePicker';
import { convertNumber, sumTotalByServiceQuality } from '@/utils/utils';
import { formatDateTime } from '@/utils/datetime';
import { SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import Tooltip from '@/components/common/Tooltip';

export default observer(function ShopRate() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        uiStore: { devicesScreen: { mobile, desktop } },
        reviewStore: { totalByServiceQuality, shopInfo, params, setObservable },
        uiStore: { windowSize: { width } }
    } = useStore();

    // state
    const [fromCreatedDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toCreatedDate, setToDate] = useState<Date | undefined>(undefined);
    const [isOpenTooltipHint, setIsOpenTooltipHine] = useState<boolean>(false);
    const [isOpenTooltipHint2, setIsOpenTooltipHine2] = useState<boolean>(false);

    // lifecycle
    useEffect(() => {
        setObservable('params', {
            ...params,
            // Xử lý fromDate
            ...(fromCreatedDate ? { fromCreatedDate: formatDateTime(fromCreatedDate, SUBMIT_MOMENT_DATE_YMD) } : { fromCreatedDate: undefined }),
            // Xử lý toDate
            ...(toCreatedDate ? { toCreatedDate: formatDateTime(toCreatedDate, SUBMIT_MOMENT_DATE_YMD) } : { toCreatedDate: undefined })
        });
    }, [toCreatedDate, fromCreatedDate]);

    const totalReviews = sumTotalByServiceQuality(totalByServiceQuality).totalReviews;
    const totalGoodReviews = sumTotalByServiceQuality(totalByServiceQuality).totalGoodReviews;
    const totalBadReviews = sumTotalByServiceQuality(totalByServiceQuality).totalBadReviews;
    const percentGoodReviews = totalGoodReviews === 0 ? 0 : ((totalGoodReviews / totalReviews) * 100).toFixed(1);
    const percentBadReviews = totalBadReviews === 0 ? 0 : ((totalBadReviews / totalReviews) * 100).toFixed(1);

    // function
    const handleOpenTooltipHintChange = (open) => {
        if (width < 920) {
            setIsOpenTooltipHine(isOpenTooltipHint ? !isOpenTooltipHint : open);
        } else {
            setIsOpenTooltipHine(open);
        }
    };

    const handleOpenTooltipHintChange2 = (open) => {
        if (width < 920) {
            setIsOpenTooltipHine2(isOpenTooltipHint ? !isOpenTooltipHint : open);
        } else {
            setIsOpenTooltipHine2(open);
        }
    };

    return (
        <div className='px-4 pb-3 pt-3 md:pt-0 mt-1 md:mt-0 bg-white rounded-b-sm'>
            <div className='flex flex-col gap-3 md:flex-row md:justify-between'>
                <div className='flex items-center gap-2 md:hidden point-920:flex'>
                    <p className='text-[1.0625rem] font-semibold text-title-1'>{t('words_title.shop_rate')}</p>
                    <div className='flex items-center gap-1'>
                        <p className='text-[1.0625rem] font-medium text-icon-3'>{shopInfo?.rating?.toFixed(1)}</p>
                        <Star className='size-5 fill-current text-icon-3' />
                    </div>
                </div>
                <div className='w-full point-920:w-auto'>
                    <DualDatePicker
                        selectedFrom={fromCreatedDate}
                        selectedTo={toCreatedDate}
                        onSelectFrom={setFromDate}
                        onSelectTo={setToDate}
                        className='h-10'
                    />
                </div>
            </div>
            {mobile && (
                <div className='p-4 border border-border-12 rounded-sm mt-4'>
                    <div className='flex flex-col justify-center items-center'>
                        <p className='text-nav-1'>{t('words_title.total_number_of_reviews')}</p>
                        <p className='mt-2 text-[1.5rem] font-medium '>{convertNumber(totalReviews)}</p>
                    </div>
                    <div className='flex gap-2  md:gap-4 justify-between mt-6'>
                        <div className='flex flex-col justify-center items-start'>
                            <div className='flex items-center space-x-1'>
                                <p className='text-nav-1'>
                                    {t('words_title.total_of_bad_rates')}
                                </p>
                                <span>
                                    <Tooltip
                                        side={width < 920 ? 'bottom' : 'right'}
                                        align={width < 920 ? 'center' : 'start'}
                                        alignOffset={16}
                                        sideOffset={8}
                                        open={isOpenTooltipHint2}
                                        onOpenChange={handleOpenTooltipHintChange2}
                                        content={t('sentences.tooltip_bad_review')}
                                        contentClassName='w-full max-w-[calc(100vw_-_2rem)] mx-4 point-920:mx-0 xl:max-w-[600px] point-920:max-w-[480px] text-text-25 text-[0.8125rem]'
                                        triggerType='button'
                                        triggerAsChild
                                        arrow={width < 920}
                                        arrowHeight={10}
                                        arrowWidth={20}
                                    >
                                        <CircleHelpIcon className='text-tab-1 size-[14px] max-w-[14px] max-h-[14px]' onClick={handleOpenTooltipHintChange2} />
                                    </Tooltip>
                                </span>
                            </div>
                            <p className='mt-2 text-[1.5rem] font-medium '>
                                {convertNumber(totalBadReviews)}
                                <span className='ml-1 text-[0.875rem] font-medium text-tab-1'>
                                    {percentBadReviews}
                                    {' '}
                                    %
                                </span>
                            </p>
                        </div>
                        <div className='h-12 w-0.25 bg-border-12'></div>
                        <div className='flex flex-col justify-center items-start'>
                            <div className='flex items-center space-x-1'>
                                <p className='text-nav-1'>
                                    {t('words_title.total_of_good_rates')}
                                </p>
                                <Tooltip
                                    side={width < 920 ? 'bottom' : 'right'}
                                    align={width < 920 ? 'center' : 'start'}
                                    alignOffset={16}
                                    sideOffset={8}
                                    open={isOpenTooltipHint}
                                    onOpenChange={handleOpenTooltipHintChange}
                                    content={t('sentences.tooltip_good_review')}
                                    contentClassName='w-full max-w-[calc(100vw_-_2rem)] mx-4 point-920:mx-0 xl:max-w-[600px] point-920:max-w-[480px] text-text-25 text-[0.8125rem]'
                                    triggerType='button'
                                    triggerAsChild
                                    arrow={width < 920}
                                    arrowHeight={10}
                                    arrowWidth={20}
                                >
                                    <CircleHelpIcon className='text-tab-1 size-[14px] max-w-[14px] max-h-[14px]' onClick={handleOpenTooltipHintChange} />
                                </Tooltip>
                            </div>
                            <p className='mt-2 text-[1.5rem] font-medium '>
                                {convertNumber(totalGoodReviews)}
                                <span className='ml-1 text-[0.875rem] font-medium text-tab-1'>
                                    {percentGoodReviews}
                                    {' '}
                                    %
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {desktop && (
                <div className='flex items-center justify-between p-4 border border-border-12 rounded-sm mt-4'>
                    <div className='flex flex-1 flex-col justify-center items-start text-nowrap pr-1'>
                        <p className='text-nav-1'>{t('words_title.total_number_of_reviews')}</p>
                        <p className='mt-2 text-[1.5rem] font-medium '>
                            {convertNumber(totalReviews)}
                            <span className='ml-1 text-[0.875rem] font-medium text-tab-1'>{' '}</span>
                        </p>
                    </div>
                    <div className='flex flex-1 flex-col justify-center items-start border-l text-nowrap px-1 lg:pl-4'>
                        <div className='flex items-center space-x-1'>
                            <p className='text-nav-1'>{t('words_title.total_of_good_rates')}</p>
                            <Tooltip
                                side={width < 920 ? 'bottom' : 'right'}
                                align={width < 920 ? 'center' : 'start'}
                                alignOffset={16}
                                sideOffset={8}
                                open={isOpenTooltipHint2}
                                onOpenChange={handleOpenTooltipHintChange2}
                                content={t('sentences.tooltip_good_review')}
                                contentClassName='w-full max-w-[calc(100vw_-_2rem)] mx-4 point-920:mx-0 xl:max-w-[600px] point-920:max-w-[480px] text-text-25 text-[0.8125rem]'
                                triggerType='button'
                                triggerAsChild
                                arrow={width < 920}
                                arrowHeight={10}
                                arrowWidth={20}
                            >
                                <CircleHelpIcon className='text-tab-1 size-[14px] max-w-[14px] max-h-[14px]' onClick={handleOpenTooltipHintChange2} />
                            </Tooltip>
                        </div>
                        <p className='mt-2 text-[1.5rem] font-medium '>
                            {convertNumber(totalGoodReviews)}
                            <span className='ml-1 text-[0.875rem] font-medium text-tab-1'>
                                {percentGoodReviews}
                                {' '}
                                %
                            </span>
                        </p>
                    </div>
                    <div className='flex flex-1 flex-col justify-center items-start border-l text-nowrap pl-2 lg:pl-4 pr-1'>
                        <div className='flex items-center space-x-1'>
                            <p className='text-nav-1'>{t('words_title.total_of_bad_rates')}</p>
                            <Tooltip
                                side={width < 920 ? 'bottom' : 'right'}
                                align={width < 920 ? 'center' : 'start'}
                                alignOffset={16}
                                sideOffset={8}
                                open={isOpenTooltipHint}
                                onOpenChange={handleOpenTooltipHintChange}
                                content={t('sentences.tooltip_bad_review')}
                                contentClassName='w-full max-w-[calc(100vw_-_2rem)] mx-4 point-920:mx-0 xl:max-w-[600px] point-920:max-w-[480px] text-text-25 text-[0.8125rem]'
                                triggerType='button'
                                triggerAsChild
                                arrow={width < 920}
                                arrowHeight={10}
                                arrowWidth={20}
                            >
                                <CircleHelpIcon className='text-tab-1 size-[14px] max-w-[14px] max-h-[14px]' onClick={handleOpenTooltipHintChange} />
                            </Tooltip>
                        </div>
                        <p className='mt-2 text-[1.5rem] font-medium '>
                            {convertNumber(totalBadReviews)}
                            <span className='ml-1 text-[0.875rem] font-medium text-tab-1'>
                                {percentBadReviews}
                                {' '}
                                %
                            </span>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
});

import ApiService from './ApiService';

export default class NotificationApi extends ApiService {
    getAllNotification(payload) {
        const { isChannelShopOwner, ...otherPayload } = payload;
        return this.get(`${isChannelShopOwner ? 'my-shop/' : ''}notifications`, otherPayload);
    }

    getNotificationDetail(payload) {
        const { code, isChannelShopOwner, ...otherPayload } = payload;
        return this.get(`${isChannelShopOwner ? 'my-shop/' : ''}notifications/${code}`, otherPayload);
    }

    markReadNotification(payload) {
        const { isChannelShopOwner, ...otherPayload } = payload;
        return this.put(`${isChannelShopOwner ? 'my-shop/' : ''}notifications/mark-read`, otherPayload);
    }

    deleteNotification(payload) {
        const { code, isChannelShopOwner, ...otherPayload } = payload;
        return this.delete(`${isChannelShopOwner ? 'my-shop/' : ''}notifications/${code}`, otherPayload);
    }
}

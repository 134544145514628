import { useStore } from '@/hooks/useStore';
import { scrollToTop } from '@/utils/utils';
import { ArrowUpToLineIcon } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

export default observer(function ScrollToTop() {
    // store
    const { uiStore: { isShowScrollToTop, setObservable } } = useStore();

    // lifecycle
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setObservable('isShowScrollToTop', true);
            } else {
                setObservable('isShowScrollToTop', false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    if (!isShowScrollToTop) return <></>;

    return (
        <div
            onClick={scrollToTop}
            className='fixed bottom-20 right-5 z-40 w-10 h-10 bg-white text-white rounded-full flex justify-center items-center cursor-pointer shadow-lg'
        >
            <ArrowUpToLineIcon className='w-6 h-6 text-active' />
        </div>
    );
});

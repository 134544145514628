import { Tabs, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent, type ChartConfig } from '@/components/ui/chart';
import { DAYS_OF_WEEK, MONTH_OF_YEAR, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { APPOINTMENT_STATUS_TYPE, DAYS_OF_WEEK_TYPE, LANGUAGES_SUPPORTED, MONTH_OF_YEAR_TYPE, STATISTIC_TYPE, StatisticType } from '@/types/enums';
import { formatDateTime } from '@/utils/datetime';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, TooltipProps, XAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const STATISTIC_APPOINTMENT_BY_STATUS = [
    STATISTIC_TYPE.Weekly,
    STATISTIC_TYPE.Monthly,
    STATISTIC_TYPE.Yearly
];

export default observer(function ShopAppointmentStatisticByStatus() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const { shopStore: { getStatisticsAppointmentByStatus } } = useStore();

    // status
    const [data, setData] = useState<any>();
    const [statisticType, setStatisticType] = useState<StatisticType>(STATISTIC_TYPE.Weekly);

    // lifecycle
    useEffect(() => {
        fetchData();
    }, [statisticType]);

    // function
    const fetchData = async () => {
        const res = await flowResult(getStatisticsAppointmentByStatus({
            statisticType,
            ...(statisticType === STATISTIC_TYPE.Weekly ? { date: formatDateTime(new Date(), SUBMIT_MOMENT_DATE_YMD) ?? '' } : {}),
            ...(statisticType === STATISTIC_TYPE.Monthly ? { year: formatDateTime(new Date(), 'YYYY') ?? '' } : {})
        }));
        if (res?.ok) {
            setData(res.data);
        }
    };

    const chartData = useMemo(() => {
        if (data) {
            if (statisticType === STATISTIC_TYPE.Weekly) {
                return Object.values(DAYS_OF_WEEK_TYPE).map((day, index) => {
                    return {
                        key: index,
                        day: language === LANGUAGES_SUPPORTED.English ? DAYS_OF_WEEK[day].shortCutEn : DAYS_OF_WEEK[day].shortCutVi,
                        completed: data[APPOINTMENT_STATUS_TYPE.Completed][index]?.totalAppointments,
                        cancelled: data[APPOINTMENT_STATUS_TYPE.Cancelled][index]?.totalAppointments
                    };
                });
            } else if (statisticType === STATISTIC_TYPE.Monthly) {
                return Object.values(MONTH_OF_YEAR_TYPE).map((month, index) => {
                    return {
                        key: index,
                        month: language === LANGUAGES_SUPPORTED.English ? MONTH_OF_YEAR[month].shortCutEn : MONTH_OF_YEAR[month].shortCutVi,
                        completed: data[APPOINTMENT_STATUS_TYPE.Completed][month - 1]?.totalAppointments,
                        cancelled: data[APPOINTMENT_STATUS_TYPE.Cancelled][month - 1]?.totalAppointments
                    };
                });
            } else if (statisticType === STATISTIC_TYPE.Yearly) {
                return data[APPOINTMENT_STATUS_TYPE.Completed].map((item, index) => {
                    return {
                        key: index,
                        year: item.year,
                        completed: item?.totalAppointments,
                        cancelled: data[APPOINTMENT_STATUS_TYPE.Cancelled][index]?.totalAppointments
                    };
                });
            }
        }
    }, [data, language]);

    const chartConfig = {
        completed: {
            label: t('words_title.completed'),
            color: '#28C22E'
        },
        cancelled: {
            label: t('words_title.cancelled'),
            color: '#DCDCDC'
        }
    } satisfies ChartConfig;

    const handleClickTab = (value) => {
        setStatisticType(value);
    };

    return (
        <div className='p-4'>
            <div className='text-[0.9375rem] font-semibold pb-4 border-b border-border-12'>{t('words_title.statistic_by_status')}</div>
            <Tabs value={statisticType} onValueChange={handleClickTab} className='mt-4'>
                <TabsList variant='statistic' data-orientation='horizontal' className=''>
                    {STATISTIC_APPOINTMENT_BY_STATUS.map((tab, index) =>
                        (<TabsTrigger key={index} value={tab} variant='statistic'>{t(`words_title.${tab}`)}</TabsTrigger>))}
                </TabsList>
            </Tabs>
            {
                chartData && chartData.length > 0 && !chartData.every(item => !item.completed && !item.cancelled) ?
                    (
                        <ChartContainer className='mt-4 w-full h-[300px] mx-auto aspect-none' config={chartConfig}>
                            <BarChart accessibilityLayer data={chartData}>
                                <CartesianGrid vertical={false} strokeDasharray='4' />
                                <XAxis
                                    dataKey={statisticType === STATISTIC_TYPE.Monthly ? 'month' : (statisticType === STATISTIC_TYPE.Weekly ? 'day' : 'year')}
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={10}
                                />
                                <ChartTooltip
                                    cursor={false}
                                    content={<ChartTooltipContent hideLabel={statisticType === STATISTIC_TYPE.Yearly} />}
                                />
                                <ChartLegend
                                    content={<ChartLegendContent />}
                                    className='px-6 py-2.5 border border-border-9 rounded-[0.5rem] w-fit mt-4'
                                    wrapperStyle={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                />
                                <Bar dataKey='completed' fill='#28C22E' radius={[8, 8, 0, 0]} barSize={8} />
                                <Bar dataKey='cancelled' fill='#DCDCDC' radius={[8, 8, 0, 0]} barSize={8} />
                            </BarChart>
                        </ChartContainer>
                    ) :
                    (
                        <div className='text-icon-2 text-center mt-10 text-small'>
                            {
                                statisticType === STATISTIC_TYPE.Weekly ?
                                    (
                                        t('sentences.week_no_data')
                                    ) :
                                    (
                                        statisticType === STATISTIC_TYPE.Monthly ?
                                            (
                                                t('sentences.month_no_data')
                                            ) :
                                            (
                                                t('sentences.year_no_data')
                                            )
                                    )
                            }
                        </div>
                    )
            }
        </div>
    );
});

import { Button } from '@/components/common/Button';
import { observer } from 'mobx-react-lite';
import Chat from '@/assets/images/chat.svg';
import WriteReview from '@/assets/images/write.svg';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';
import ReviewForm from './components/ReviewForm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ACTIVITY_PATH, ROUTES } from '@/configs/constants';
import { flowResult } from 'mobx';
export default observer(function ShopDetailUtils() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { slug } = useParams();
    const { pathname } = useLocation();

    // store
    const {
        modalStore: { showModal, showAlertModal },
        authStore: { profile, token },
        uiStore: { devicesScreen: { mobile } },
        shopStore: { shopDetail, getShopDetail },
        petStore: { getAllPet }
    } = useStore();

    // function
    const handlePostReview = () => {
        if (!profile) {
            navigate(ROUTES.signIn.href);
            return;
        }
        showModal({
            id: 'write-review',
            title: t('words_title.write_review'),
            titleClassName: 'title-3 text-center',
            content: <ReviewForm />,
            footerClassName: 'p-0'
        });
    };

    const handleRedirectBookAppointment = async () => {
        const resShop = await flowResult(getShopDetail(slug!));
        if (resShop) {
            const { operatingStatus } = resShop;
            if (operatingStatus) {
                const res = await flowResult(getAllPet());
                if (res) {
                    if (res.length > 0) {
                        navigate(`${ROUTES.bookAppointment.href}/${slug}`, {
                            state: {
                                previousUrl: pathname
                            }
                        });
                    } else {
                        showAlertModal({
                            id: 'pet-modal',
                            type: 'error',
                            content: t('sentences.not_any_pet')
                        });
                    }
                }
            } else {
                showAlertModal({
                    id: 'temp-out-modal',
                    type: 'error',
                    content: t('sentences.temp_out_service_msg')
                });
            }
        }
    };

    const handleRedirectChatScreen = () => {
        !token ? navigate(ROUTES.signIn.href) : navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.chat}/${slug}`);
    };

    return (
        <div className={`bg-white rounded h-auto px-0.5 py-2 xsm:px-4 xsm:py-3 ${mobile ? 'fixed bottom-0 w-full z-50 px-3 py-3' : ''}`}>
            <div className='flex space-x-1 items-center justify-center md:flex-col'>
                <div className='w-1/2 flex items-center justify-evenly md:w-full md:flex md:justify-between'>
                    <Button
                        onClick={(() => handlePostReview())}
                        variant='outline'
                        className={`xsm:w-1/2 border-none hover:bg-white space-x-1 hover:text-active flex ${mobile ? 'flex-col' : ''}`}
                    >
                        <div className='bg-active rounded-full size-6 min-w-6 min-h-6 flex items-center justify-center'>
                            <img src={WriteReview} alt='' />
                        </div>
                        <p className='text-small sm:text-[0.875rem]'>{t('button.write_review')}</p>
                    </Button>
                    <div className='w-[0.5px] hidden xsm:block h-[2.375rem] bg-text-5 mx-1'></div>
                    <Button
                        onClick={() => handleRedirectChatScreen()}
                        variant='outline'
                        className={`xsm:w-1/2 border-none hover:bg-white space-x-1 hover:text-active flex ${mobile ? 'flex-col' : ''}`}
                    >
                        <div className='bg-active rounded-full size-6 min-w-6 min-h-6 flex items-center justify-center'>
                            <img src={Chat} alt='' />
                        </div>
                        <p className='text-small sm:text-[0.875rem]'>{t('button.chat_now')}</p>
                    </Button>
                </div>
                <div className='w-1/2 md:w-full md:mt-2'>
                    <Button
                        onClick={(() => !token ? navigate(`${ROUTES.signIn.href}`) : handleRedirectBookAppointment())}
                        disabled={!shopDetail.operatingStatus || !shopDetail.generalInfo || !shopDetail.shopInfo || !shopDetail.operationInfo || !shopDetail.appointmentInfo}
                        className='!text-small sm:text-[0.875rem] w-full py-[1.25rem] md:py-0'
                        variant='default'
                    >
                        {t('button.book_appointment')}
                    </Button>
                </div>
            </div>
        </div>
    );
});

import Logo from '@/assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { useEffect } from 'react';
import { HEADER_FORMAT } from '@/types/enums';
import { useStore } from '@/hooks/useStore';
import ChatIcon from '@/components/general/ChatIcon';
import NotificationIcon from '@/components/general/NotificationIcon';
import MenuDropdownShopMobile from '@/components/general/MenuDropdownShopMobile';

interface HeaderMobileFormat2Props {
    useMenuDropDown?: boolean
}

export default function HeaderMobileFormat2({ useMenuDropDown = false }: HeaderMobileFormat2Props) {
    // hooks
    // store
    const {
        authStore: { token },
        uiStore: { setShowHeaderFormat }
    } = useStore();

    // lifecycle
    useEffect(() => {
        setShowHeaderFormat(HEADER_FORMAT.mobile2);

        return () => {
            setShowHeaderFormat(null);
        };
    }, []);

    // function

    return (
        <div className='bg-white h-13 flex items-center justify-between relative'>
            {
                useMenuDropDown ?
                    (
                        <MenuDropdownShopMobile />
                    ) :
                    (
                        <div className='h-6 w-6'></div>
                    )
            }
            <Link to={ROUTES.home.href} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <img className='h-8' src={Logo} alt='logo' />
            </Link>
            {
                token && (
                    <div className='flex gap-2.5'>
                        <ChatIcon />
                        <NotificationIcon />
                    </div>
                )
            }
        </div>
    );
}

import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from '@/components/common/Form';
import { observer } from 'mobx-react-lite';
import Combobox from '@/components/common/Combobox';
import { Label } from '@/components/common/Label';
import { MAX_LENGTH_DEFAULT_INPUT, ROUTES, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { District, Ward } from '@/types/administrative';
import { flowResult } from 'mobx';
import { formatDateTime } from '@/utils/datetime';
import { Input } from '@/components/common/Input';
import Calendar from '@/components/calendar/Calendar';
import { Button } from '@/components/common/Button';
import { useNavigate } from 'react-router-dom';
import { toastify } from '@/utils/toastify';
import { LANGUAGES_SUPPORTED } from '@/types/enums';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';

export default observer(function MyAccountEditPage() {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        authStore: { token, getProfile, profile, editProfile },
        generalStore: { administrativeUnit },
        uiStore: { devicesScreen: { mobile } }
    } = useStore();

    // validate Shema
    const validateShmena = yup.object().shape({
        fullName: yup
            .string()
            .required('form_error_validate.required')
            .max(MAX_LENGTH_DEFAULT_INPUT, 'form_error_validate.full_name_maxlength'),
        birthday: yup
            .string()
            .nullable(),
        phoneNumber: yup
            .string()
            .required('form_error_validate.required'),
        email: yup
            .string()
            .email('form_error_validate.email')
            .nullable(),
        provinceCode: yup
            .string()
            .required('form_error_validate.required'),
        provinceName: yup
            .string()
            .required('form_error_validate.required'),
        districtCode: yup
            .string()
            .nullable(),
        districtName: yup
            .string(),
        wardCode: yup
            .string()
            .nullable(),
        wardName: yup
            .string(),
        address: yup
            .string()
            .max(MAX_LENGTH_DEFAULT_INPUT, 'form_error_validate.address_maxlength')
            .nullable()
    });

    type FormData = yup.InferType<typeof validateShmena>;

    // state
    const [filteredDistricts, setFilteredDistricts] = useState<District[]>([]);
    const [filteredWards, setFilteredWards] = useState<Ward[]>([]);
    const form = useForm<FormData>({
        resolver: yupResolver(validateShmena),
        mode: 'onChange',
        defaultValues: {
            fullName: '',
            birthday: null,
            phoneNumber: '',
            email: null,
            provinceCode: '',
            districtCode: null,
            wardCode: null,
            address: null
        }
    });

    // lifecycle
    useEffect(() => {
        token && getProfile();
    }, [token]);

    useEffect(() => {
        if (profile) {
            setFilteredDistricts(administrativeUnit.districts.filter(district => district.provinceCode === profile.province?.code));
            setFilteredWards(administrativeUnit.wards.filter(ward => ward.districtCode === profile?.district?.code));
            form.reset({
                fullName: profile?.fullName,
                birthday: profile?.birthday ? formatDateTime(profile.birthday, SUBMIT_MOMENT_DATE_YMD) : '',
                phoneNumber: profile?.phoneNumber,
                email: profile?.email,
                provinceCode: profile?.province?.code,
                provinceName: profile?.province?.name,
                districtCode: profile?.district?.code,
                districtName: profile?.district?.name,
                wardCode: profile?.ward?.code,
                wardName: profile?.ward?.name,
                address: profile?.address
            });
        }
    }, [profile]);

    // function
    // Hàm lọc distric dựa trên provinceID
    const handleDistrictChange = (provinceID: string) => {
        const newDistricts = administrativeUnit.districts.filter(district => district.provinceCode === provinceID);
        setFilteredDistricts(newDistricts);
        // reset khi change va hien thi placeholder = 'Choose'
        form.setValue('districtCode', '');
        form.setValue('districtName', '');
        form.setValue('wardCode', '');
        form.setValue('wardName', '');
        setFilteredWards([]);
    };

    // Hàm lọc wards dựa trên districtID
    const handleWardChange = (districtID: string) => {
        const newWards = administrativeUnit.wards.filter(ward => ward.districtCode === districtID);
        setFilteredWards(newWards);
        // reset khi change va hien thi placeholder = 'Choose'
        form.setValue('wardCode', '');
        form.setValue('wardName', '');
    };

    const onSubmit = async (data: FormData) => {
        const { fullName, birthday, email, provinceCode, districtCode, wardCode, address } = data;
        const newBirthday = formatDateTime((birthday || ''), SUBMIT_MOMENT_DATE_YMD);
        const res = await flowResult(editProfile({
            fullName,
            birthday: newBirthday ?? '',
            email: email ?? '',
            provinceCode,
            districtCode: districtCode ?? '',
            wardCode: wardCode ?? '',
            address: address ?? ''
        }));
        if (res) {
            getProfile();
            toastify('alert-success', t('messages.update_success'));
            if (mobile) {
                navigate(`${ROUTES.myAccount.href}#account-infomation`);
            } else {
                navigate(ROUTES.myAccount.href);
            }
        }
    };

    return (
        <>
            <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.edit_account_information')} defaultNavigateUrl={ROUTES.myAccount.href} />} />
            <div className='pb-[1.5rem] bg-white'>
                <div className='px-4 py-6 title-3 hidden md:block md:px-4 md:py-6'>{t('words_title.account_infomation')}</div>
                <div className='px-4 pt-4 md:pt-0'>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <div className='lg:flex block gap-[1rem]'>
                                <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                                    <Label className='label-1' required>{t('words_title.full_name')}</Label>
                                    <FormField
                                        control={form.control}
                                        name='fullName'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        type='text'
                                                        className='mt-[0.375rem]'
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                                    <Label className='label-1'>{t('words_title.date_of_birth')}</Label>
                                    <FormField
                                        control={form.control}
                                        name='birthday'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <div className='mt-[0.375rem]'>
                                                        <Calendar
                                                            placeholder={t('placeholder.choose')}
                                                            className='mt-[0.375rem]'
                                                            type='datePicker'
                                                            mode='single'
                                                            selected={field.value ? new Date(field.value) : undefined}
                                                            onSelect={field.onChange}
                                                            required={true}
                                                            disabled={[
                                                                {
                                                                    after: new Date()
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='lg:flex block gap-[1rem] mt-[1rem]'>
                                <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                                    <Label className='label-1' required>{t('words_title.phone_number')}</Label>
                                    <FormField
                                        control={form.control}
                                        name='phoneNumber'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        type='text'
                                                        className='mt-[0.375rem]'
                                                        disabled={true}
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                                    <Label className='label-1'>{t('words_title.email')}</Label>
                                    <FormField
                                        control={form.control}
                                        name='email'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        type='email'
                                                        className='mt-[0.375rem]'
                                                        {...field}
                                                        value={field.value ?? ''}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='lg:flex block gap-[1rem] mt-[1rem]'>
                                <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                                    <Label className='label-1' required>{t('words_title.city')}</Label>
                                    <FormField
                                        control={form.control}
                                        name='provinceName'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Combobox
                                                        value={field.value}
                                                        onChange={(item) => {
                                                            field.onChange(item?.value);
                                                            form.setValue('provinceCode', item?.code);
                                                            handleDistrictChange(item?.code);
                                                        }}
                                                        items={administrativeUnit?.provinces?.map((item, index) => (
                                                            {
                                                                label: language === LANGUAGES_SUPPORTED.English ? item.fullNameEn : item.fullName,
                                                                value: item.name,
                                                                code: item.code
                                                            }
                                                        ))}
                                                        label={t('words_title.city')}
                                                        required
                                                        className='mt-[0.375rem]'
                                                        placeholder={t('placeholder.choose')}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                                    <Label className='label-1'>{t('words_title.district')}</Label>
                                    <FormField
                                        control={form.control}
                                        name='districtName'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Combobox
                                                        value={field.value!}
                                                        onChange={(item) => {
                                                            field.onChange(item?.value);
                                                            form.setValue('districtCode', item?.code);
                                                            handleWardChange(item?.code);
                                                        }}
                                                        items={filteredDistricts?.map((item, index) => (
                                                            {
                                                                label: language === LANGUAGES_SUPPORTED.English ? item.fullNameEn : item.fullName,
                                                                value: item.name,
                                                                code: item.code
                                                            }
                                                        ))}
                                                        className='mt-[0.375rem]'
                                                        placeholder={t('placeholder.choose')}
                                                        disabled={(filteredDistricts && filteredDistricts?.length === 0) ? true : false}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='lg:flex block gap-[1rem] mt-[1rem]'>
                                <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                                    <Label className='label-1'>{t('words_title.ward')}</Label>
                                    <FormField
                                        control={form.control}
                                        name='wardName'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Combobox
                                                        value={field.value!}
                                                        onChange={(item) => {
                                                            field.onChange(item?.value);
                                                            form.setValue('wardCode', item?.code);
                                                        }}
                                                        items={filteredWards?.map((item, index) => (
                                                            {
                                                                label: language === LANGUAGES_SUPPORTED.English ? item.fullNameEn : item.fullName,
                                                                value: item.name,
                                                                code: item.code
                                                            }
                                                        ))}
                                                        className='mt-[0.375rem]'
                                                        placeholder={t('placeholder.choose')}
                                                        disabled={(filteredWards && filteredWards?.length === 0) ? true : false}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div className='w-full mb-[1rem] lg:w-1/2 lg:mb-0'>
                                    <Label className='label-1'>{t('words_title.address')}</Label>
                                    <FormField
                                        control={form.control}
                                        name='address'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        type='text'
                                                        className='mt-[0.375rem]'
                                                        {...field}
                                                        value={field.value ?? ''}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='gap-[1rem] mt-[1rem] lg:mt-[1.5rem] md:flex'>
                                <Button type='button' className='w-full border-border-9 mb-[0.375rem] hidden md:block lg:w-1/2' onClick={() => navigate(ROUTES.myAccount.href)} variant='outline'>{t('button.cancel')}</Button>
                                <Button type='submit' className='w-full block lg:w-1/2'>{t('button.save')}</Button>
                            </div>
                        </form>

                    </Form>
                </div>

            </div>
        </>
    );
});

import SelectLanguage from '@/components/general/SelectLanguage';
import UserAvatar from '@/components/general/UserAvatar';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { ENDUSER_TYPE, HEADER_FORMAT } from '@/types/enums';
import { cn } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Logo from '@/assets/images/logo.svg';
import { PetNoteIcon, ActivityIcon, ForumIcon } from '@/components/icons';
import SearchInput from '@/components/general/SearchInput';
import Nearby from '@/assets/images/nearby.svg';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import NotificationIcon from '@/components/general/NotificationIcon';

interface HeaderDesktopForGeneralProps { }

export default observer(function HeaderDesktopForGeneral(props: HeaderDesktopForGeneralProps) {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        uiStore: { setShowHeaderFormat },
        authStore: { token, profile, onSwitchToShopChannel, onCheckAuthenRedirect }
    } = useStore();

    // lifecycle
    useEffect(() => {
        setShowHeaderFormat(HEADER_FORMAT.desktopGeneral);

        return () => {
            setShowHeaderFormat(null);
        };
    }, []);

    // function
    const handleRedirectToCreateShop = async () => {
        navigate(ROUTES.createShop.href);
    };

    return (
        <>
            <div className='py-3 h-11.5 md:flex items-center justify-between hidden'>
                <div className='flex'>
                    {
                        (!token || (token && profile?.alreadyOwnShop)) && (
                            <div
                                onClick={onSwitchToShopChannel}
                                className={cn('pr-4 text-sm text-nav-1 cursor-pointer', !profile?.alreadyOwnShop && 'border-r border-r-border-6')}
                            >
                                {t('words_title.shop_centre')}
                            </div>
                        )
                    }
                    {
                        token && !profile?.alreadyOwnShop && (
                            <div onClick={() => handleRedirectToCreateShop()} className='pl-4 text-sm text-nav-1 cursor-pointer'>
                                {t('words_title.create_shop')}
                            </div>
                        )
                    }
                    {
                        !token && (
                            <Link to={ROUTES.signUp.href} state={{ userType: String(ENDUSER_TYPE.ShopOwner) }} className='pl-4 text-sm text-nav-1'>
                                {t('words_title.register_shop')}
                            </Link>
                        )
                    }
                </div>
                <div className='flex items-center'>
                    {token && (
                        <NotificationIcon withText />

                    )}
                    <div className='pr-2 text-sm text-nav-1'>
                        <SelectLanguage />
                    </div>
                    {token ?
                        (
                            <div className='acc-info px-4 border-l border-l-border-6'>
                                <UserAvatar />
                            </div>
                        ) :
                        (
                            <>
                                <Link to={ROUTES.signUp.href} className='px-4 border-x border-x-border-6 text-sm text-nav-1'>{t('words_title.sign_up')}</Link>
                                <Link to={ROUTES.signIn.href} className='pl-4 text-sm text-nav-1'>{t('words_title.sign_in')}</Link>
                            </>
                        )}
                </div>
            </div>
            <div className='h-17 flex items-center justify-between'>
                <Link to={ROUTES.home.href}>
                    <img className='h-8 point-920:h-10' src={Logo} alt='logo' />
                </Link>
                <div className='nav-bar h-9 flex items-center xl:gap-9 lg:gap-7 gap-4'>
                    <NavLink to={onCheckAuthenRedirect(ROUTES.signIn.href, ROUTES.petNote.href)} className=' flex items-center gap-1'>
                        {({ isActive }) => (
                            <>
                                <PetNoteIcon className='w-6 h-6' isActive={isActive} />
                                <span className={cn('text-sm', isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.pet_note')}</span>
                            </>
                        )}
                    </NavLink>
                    <NavLink to={ROUTES.nearBy.href} className=' flex items-center gap-1'>
                        {({ isActive }) => (
                            <>
                                <img className='lg:h-8 lg:w-7 h-7 w-6' src={Nearby} alt='nearby' />
                                <span className={cn('text-sm', isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.nearby')}</span>
                            </>
                        )}
                    </NavLink>
                    <NavLink to={onCheckAuthenRedirect(ROUTES.signIn.href, ROUTES.activity.href)} className=' flex items-center gap-1'>
                        {({ isActive }) => (
                            <>
                                <ActivityIcon className='w-6 h-6' isActive={isActive} />
                                <span className={cn('text-sm', isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.activity')}</span>
                            </>
                        )}
                    </NavLink>
                    <NavLink to={ROUTES.forum.href} className=' flex items-center gap-1'>
                        {({ isActive }) => (
                            <>
                                <ForumIcon className='w-6 h-6' isActive={isActive} />
                                <span className={cn('text-sm', isActive ? 'text-active font-semibold' : 'text-nav-1 ')}>{t('words_title.forum')}</span>
                            </>
                        )}
                    </NavLink>
                </div>
                <div className='search-shop'>
                    <SearchInput className='rounded-[0.5rem] w-52 h-10 point-920:w-60 lg:w-72 xl:w-96 border-[0.5px] border-input-2 pl-3 pr-10 py-2 text-icon-1' />
                </div>
            </div>
        </>
    );
});

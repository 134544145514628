import { DISPLAY_MOMENT_TIME_HM, DISPLAY_MOMENT_TIME_HM_AM_PM_2, FILE_SIZE_IMAGE, IMPORTANT_CODE, SUPPORTED_FORMATS } from '@/configs/constants';
import classNames from 'classnames';
import { Province } from '@/types/administrative';
import { twMerge } from 'tailwind-merge';
import { SearchShopParams } from '@/types/http-payload/shop';
import { PICK_UP_SERVICE_STATUS, ShopServiceType } from '@/types/enums';
import { Schedule, ShopServiceAppointment } from '@/types/shop';
import { TFunction } from 'i18next';
import { formatDateTime } from './datetime';

export function cn(...inputs: classNames.ArgumentArray) {
    return twMerge(classNames(inputs));
}

export function getAvatarFallbackName(fullname?: string) {
    if (!fullname) return '';
    const names = fullname.trim().split(' ');

    if (names.length === 1) {
        // Trường hợp chỉ có một từ
        return `${names[0].charAt(0).toUpperCase()}${names[0].charAt(1).toUpperCase()}`;
    } else if (names.length > 1) {
        // Lấy ký tự đầu tiên của tên và họ
        const firstInitial = names[0].charAt(0).toUpperCase();
        const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
    }

    return '';
}

export function getLastNameInitial(fullname?: string): string {
    if (!fullname) return '';

    // Tách fullname thành các từ
    const names = fullname.trim().split(' ');

    // Kiểm tra nếu có ít nhất một từ
    if (names.length > 0) {
        // Lấy từ cuối cùng và chữ cái đầu tiên của nó
        const lastName = names[names.length - 1];
        return lastName.charAt(0).toUpperCase();
    }

    return '';
}

export function getFullAddress(userData: any, locale: string) {
    // if (locale === LANGUAGES_SUPPORTED.English) {
    //     return `${userData.address}, ${userData.ward.nameEn}, ${userData.district.nameEn}, ${userData.province.nameEn}`;
    // } else if (locale === LANGUAGES_SUPPORTED.Vietnamese) {
    //     return `${userData.address}, ${userData.ward.name}, ${userData.district.name}, ${userData.province.name}`;
    // }
    if (userData?.address) return userData.address;
    return null;
}

export const checkImageSupportFormat = (files) => {
    if (!files || !(files instanceof FileList)) {
        return false;
    }
    return Array.from(files).every(item => SUPPORTED_FORMATS.includes(item.type) && item.size <= FILE_SIZE_IMAGE);
};

export const generateMinuteOptions = (step) => {
    const options: string[] = [];
    for (let i = 0; i < 60; i += step) {
        options.push(i.toString().padStart(2, '0'));
    }
    return options;
};

export const convertToHourMinuteFormat = (data: number, locale: string) => {
    const hours = Math.floor(data / 60);
    const remainingMinutes = data % 60;
    if (locale === 'vi') {
        if (hours > 0 && remainingMinutes > 0) {
            return `${hours} giờ ${remainingMinutes} phút`;
        } else if (hours > 0) {
            return `${hours} giờ`;
        } else if (remainingMinutes > 0) {
            return `${remainingMinutes} phút`;
        } else {
            return '';
        }
    } else if (locale === 'en') {
        if (hours > 0 && remainingMinutes > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''}`;
        } else if (remainingMinutes > 0) {
            return `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
        } else {
            return '';
        }
    }
};

export const sortFilterProvinces = (provinces: Province[], selectedProvinces: string[]) => {
    return provinces.sort((a, b) => {
        const importantProvinces = [IMPORTANT_CODE.HN, IMPORTANT_CODE.HCM];

        const isAImportant = importantProvinces.includes(a.code);
        const isBImportant = importantProvinces.includes(b.code);

        const isASelected = selectedProvinces.includes(a.code);
        const isBSelected = selectedProvinces.includes(b.code);

        // 1. Nếu "Hà Nội" hoặc "Hồ Chí Minh" được chọn, đẩy lên đầu danh sách chọn
        if (isAImportant && isASelected && (!isBImportant || !isBSelected)) return -1;
        if (isBImportant && isBSelected && (!isAImportant || !isASelected)) return 1;

        // 2. Đẩy các tỉnh khác đã được chọn lên trước, nếu không phải "Hà Nội" hoặc "Hồ Chí Minh"
        if (isASelected && !isBSelected) return -1;
        if (!isASelected && isBSelected) return 1;

        // 3. Nếu không tỉnh nào được chọn, nhưng là "Hà Nội" hoặc "Hồ Chí Minh", đẩy chúng lên đầu trong danh sách không được chọn
        if (isAImportant && !isBImportant) return -1;
        if (!isAImportant && isBImportant) return 1;

        // 4. Sắp xếp các tỉnh còn lại theo tên
        return a.name.localeCompare(b.name);
    });
};

export const convertNumber = (value: number) => {
    const isNegative = value < 0;
    const absValue = Math.abs(value); // Lấy giá trị tuyệt đối để xử lý

    let result = '';

    if (absValue < 1000) {
        result = absValue.toString();
    } else if (absValue < 1000_000) {
        result = addCommas((absValue / 1000)) + 'K';
    } else if (absValue < 1000_000_000) {
        result = addCommas((absValue / 1000_000)) + 'M';
    } else {
        result = addCommas((absValue / 1000_000_000)) + 'B';
    }

    return isNegative ? `-${result}` : result; // Thêm dấu âm nếu ban đầu là số âm
};
const addCommas = (num: number) => {
    let shortString = num.toString();
    let indexDots = shortString.indexOf('.');

    if (indexDots !== -1) {
        if (shortString[indexDots + 1] === '0') {
            shortString = shortString.slice(0, indexDots + 1).replace('.', '');
        } else {
            shortString = shortString.slice(0, indexDots + 2);
        }
    }

    let decimalNumber = shortString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return decimalNumber;
};
export const formatNumber = (num: number) => {
    const numStr = num.toString();

    return numStr.length > 3 ? numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : numStr;
};

export const setJobsCookie = (name: string, value: string) => {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    const expiresStr = `expires=${endOfDay.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expiresStr}; path=/`;
};

export const getCookie = (name: string): string | null => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export const eraseCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export const getFileInfo = (file: File) => {
    const dotIndex = file.name.lastIndexOf('.');
    if (dotIndex === -1 || dotIndex === 0) {
        return '';
    }
    return [file.name.slice(0, dotIndex).toLowerCase(), file.type.split('/')[1].toLowerCase()];
};

export const getFileNameFromUrl = (url: any) => {
    const parts = url?.split('/');
    return parts?.length > 0 ? parts[parts.length - 1] : '';
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

export function convertParamsToApiParams(searchParams?: URLSearchParams) {
    let params: SearchShopParams = {};
    if (searchParams) {
        if (searchParams.get('city')) {
            params = { ...params, provinceCodes: searchParams.get('city')?.split(',') };
        }
        if (searchParams.get('category')) {
            params = { ...params, categories: searchParams.get('category')?.split(',').map(Number) };
        }
        if (searchParams.get('pickup')) {
            params = { ...params, pickupStatus: Number(searchParams.get('pickup')) === PICK_UP_SERVICE_STATUS.Offer };
        }
        if (searchParams.get('rating')) {
            params = { ...params, rating: Number(searchParams.get('rating')) };
        }
    }

    return params;
}

// shop

export const filterServiceListByType = (serviceList: ShopServiceAppointment[] = [], type: ShopServiceType | 0) => {
    let result: ShopServiceAppointment[] = [];
    if (serviceList.length > 0 && type > 0) {
        result = serviceList.filter(service => service.type === type);
    }

    return result;
};

export const convertPetWeight = (weightFrom: string = '', weightTo: string = '', t?: TFunction) => {
    let result = `${weightFrom}kg`;
    if (weightTo.includes('+')) {
        result += ` - ${t ? t('words_title.up') : 'up'}`;
    } else {
        result += ` - ${weightTo}kg`;
    }
    return result;
};

export const convertServicePrice = (price?: number | null, t?: TFunction, isHaveUnit: boolean = true) => {
    if (typeof price === 'number') {
        return `${formatNumber(price)} ${isHaveUnit ? 'VND' : ''}`;
    }
    return t ? t('words_title.by_consul') : '';
};

export const generateTimeSlots = (duration: number, openTime: string, closeTime: string): Schedule[] => {
    if (!duration || duration <= 0) return [];

    const [openHour, openMinute] = openTime.split(':').map(Number);
    const [closeHour, closeMinute] = closeTime.split(':').map(Number);

    if (isNaN(openHour) || isNaN(openMinute) || isNaN(closeHour) || isNaN(closeMinute)) {
        return [];
    }

    const openTotalMinutes = openHour * 60 + openMinute;
    const closeTotalMinutes = closeHour * 60 + closeMinute;

    if (closeTotalMinutes <= openTotalMinutes) {
        return [];
    }

    const result: Schedule[] = [];

    for (let time = openTotalMinutes; time <= closeTotalMinutes; time += duration) {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        const time24h = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

        result.push({
            startTime: formatDateTime(time24h, DISPLAY_MOMENT_TIME_HM_AM_PM_2, {
                onlyTime: true,
                currentformat: DISPLAY_MOMENT_TIME_HM
            }) ?? '',
            status: true
        });
    }

    return result;
};

export const shortenParagraph = (value: string, maxCount: number, isExpand: boolean) => {
    if (!value) return {
        value: '',
        isEllipsis: false
    };

    if (value.length > maxCount && !isExpand) {
        return {
            value: `${value.slice(0, maxCount)}...`,
            isEllipsis: true
        };
    } else if (value.length > maxCount && isExpand) {
        return {
            value: value + ' ',
            isEllipsis: true
        };
    } else {
        return {
            value: value,
            isEllipsis: false
        };
    }
};

// distance
export const convertDistance = (distance?: number | null) => {
    if (!distance) return null;
    const roundedMeters = Math.round(distance);

    if (roundedMeters >= 1000) {
        const kilometers = distance / 1000;
        return `${kilometers.toFixed(1)} km`;
    }

    return `${roundedMeters} m`;
};

export const hideFullName = (name: string) => {
    if (!name) return '';
    const trimmedName = name.replace(/\s+/g, ' ').trim();
    if (trimmedName.length === 1) return trimmedName + '*******';
    return trimmedName.slice(0, 2) + '******';
};

export const sumTotalByServiceQuality = (totalByServiceQuality: Record<string, number>) => {
    const totalReviews = Object.values(totalByServiceQuality ?? {}).reduce((total, count) => total + count, 0);
    const totalGoodReviews = (totalByServiceQuality[4] || 0) + (totalByServiceQuality[5] || 0);
    const totalBadReviews = (totalByServiceQuality[1] || 0) + (totalByServiceQuality[2] || 0) + (totalByServiceQuality[3] || 0);
    return {
        totalReviews,
        totalGoodReviews,
        totalBadReviews
    };
};

export const removeVietnameseAccents = (string) => {
    if (!string) return '';
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/Đ/g, 'D').replace(/đ/g, 'd');
};

export const formatVietnamPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.startsWith('0') && phoneNumber.length === 10) {
        return '+84' + phoneNumber.slice(1);
    } else {
        return phoneNumber;
    }
};

import ChatButton from '@/components/general/ChatButton';
import SidebarMyAccount from '@/components/general/SidebarMyAccount';
import ContainerBody from '@/components/layout/ContainerBody';
import HeaderMobileFormat2 from '@/components/layout/header-component/HeaderMobileFormat2';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { cn } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
interface MyAccountPanelLayoutProps {
    children?: ReactNode
}

export default observer(function MyAccountPanelLayout({
    children
}: MyAccountPanelLayoutProps) {
    // hooks
    const { pathname, hash } = useLocation();

    // store
    const {
        uiStore: { devicesScreen: { mobile, desktop } },
        authStore: { isChannelShopOwner, token }
    } = useStore();

    return (
        <>
            {/* Container Body */}
            <ContainerBody
                showHeader={(pathname === ROUTES.myAccount.href && !hash && mobile) || desktop}
                showFooter={desktop}
                showFooterMenu={pathname === ROUTES.myAccount.href && !hash && mobile}
                headerMobileComponent={<HeaderMobileFormat2 useMenuDropDown={isChannelShopOwner} />}
            >
                {
                    mobile && (
                        children ?? <Outlet />
                    )
                }
                {
                    desktop && (
                        <div className='flex pt-8 gap-4 flex-1 min-h-[inherit]'>
                            <div className='w-1/3 bg-white flex-col min-[920px]:block hidden rounded'>
                                <SidebarMyAccount />
                            </div>
                            <div className='min-[920px]:w-2/3 w-full bg-white flex-col min-h-full rounded'>
                                {
                                    children ?? <Outlet />
                                }
                            </div>
                        </div>
                    )
                }
            </ContainerBody>
            {token && <ChatButton />}
        </>
    );
});

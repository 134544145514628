import ServerErrorPageImg from '@/assets/images/serverErrorImg.png';
import { cn } from '@/utils/utils';
import { useTranslation } from 'react-i18next';

interface ServerErrorModalProps {
    className?: string
}

export default function ServerErrorModal({
    className
}: ServerErrorModalProps) {
    const { t } = useTranslation();

    return (
        <div className={cn('flex flex-col gap-4 items-center pt-8', className)}>
            <img src={ServerErrorPageImg} alt='' className='w-[352px] h-auto object-contain' />
            <div className='text-2xl font-semibold leading-[1.875rem] text-[#CF493E]'>{t('words_title.server_error_page')}</div>
            <div className='md:px-0 px-2 max-w-[405px] leading-[1.0625rem] text-[#646464] font-normal text-center'>{t('sentences.server_error_page')}</div>
        </div>
    );
}

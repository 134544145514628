import Avatar from '@/components/common/Avatar';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { ACTIVITY_PATH, DISPLAY_MOMENT_DATE_YMD, DISPLAY_MOMENT_TIME_DM, ROUTES } from '@/configs/constants';
import ShopImg from '@/assets/images/defaultShopImg.png';
import ChatListBg from '@/assets/images/chatListBg.png';
import { useStore } from '@/hooks/useStore';
import { CHAT_PARTICIPANT_STATUS, ChatParticipantStatus, ENDUSER_TYPE, EnduserType } from '@/types/enums';
import { formatDateTime } from '@/utils/datetime';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { ChatListItem, ShopInforOfChat, UserInforOfChat } from '@/types/chat';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LazyLoad from 'react-lazy-load';
import { TriangleAlert } from 'lucide-react';

export default observer(function ChatList() {
    // hook
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state, pathname } = useLocation();
    const { slug } = useParams();
    // store
    const { chatStore: { chatList, chatDetail, getChatList, seenMessages, setObservable }, authStore: { channel, isChannelShopOwner }, uiStore: { devicesScreen: { mobile }, windowSize: { height } } } = useStore();
    // state
    type filterByReadType = 'all' | 'false' | 'true';
    const [filterByRead, setFilterByRead] = useState<filterByReadType>('all');
    // lifecycle
    useEffect(() => {
        getChatList({ channel });
    }, []);
    // function
    const handleOnSelectChat = (url: string, chatCode: string, unreadMessageCount: number, shop: any, user: any) => {
        navigate(url);
    };

    const activeClass = (slug?: string) => {
        let active = 'border-l-4 border-l-active';
        if (isChannelShopOwner) {
            return chatDetail?.user?.code === slug ? active : '';
        }
        return chatDetail?.shop?.slug === slug ? active : '';
    };

    return (
        <div className='w-full h-full border-r-border-4 border-r-2 border-solid'>
            <div className='h-14 px-4 py-3 flex items-center'>
                <div className='w-1/2'>
                    <Select
                        value={filterByRead}
                        onValueChange={(value: filterByReadType) => {
                            setFilterByRead(value);
                            getChatList({
                                channel,
                                isRead: value === 'all' ? '' : value
                            });
                        }}
                    >
                        <SelectTrigger className='h-8'>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent position='popper'>
                            <SelectItem value='all'>{t('select_options.chat_all')}</SelectItem>
                            <SelectItem value='true'>{t('select_options.chat_read')}</SelectItem>
                            <SelectItem value='false'>{t('select_options.chat_unread')}</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>
            <div className='bg-border-4 h-[0.0625rem]'></div>

            <div
                className={classNames(
                    'overflow-x-hidden overflow-y-auto scrollbar scrollbar-width-2 p-4'

                )}
                style={{
                    height: mobile ? (isChannelShopOwner ? `calc(${height}px - 3.5rem - 3.25rem - 0.0625rem)` : `calc(${height}px - 7.125rem - 3.5rem - 3.125rem)`) : 'calc(100vh - 20.3125rem)'
                }}
            >
                {
                    chatList.length === 0 && (
                        <div className='mt-12 flex flex-col items-center gap-1'>
                            <img src={ChatListBg} alt='chat list background' />
                            <p className='text-center text-text-26'>{filterByRead !== 'all' ? t('sentences.no_conversation_found') : t('sentences.no_conversation_yet')}</p>
                        </div>
                    )
                }
                {chatList.length > 0 && (
                    <div className='flex flex-col gap-4'>
                        {chatList.map((chat: ChatListItem, index) => {
                            const { shop, user, lastMessage, code, unreadMessageCount, updatedAt } = chat;

                            let lstMsg = '';
                            if (chat.isSendByYou === true) {
                                lstMsg += `${t('words_title.chat_by_you')}: `;
                            }
                            if (lastMessage?.images && lastMessage.images.length > 0) {
                                lstMsg += `${t('words_title.chat_image')}`;
                            } else {
                                lstMsg += lastMessage?.text || '';
                            }

                            let linkToChat = isChannelShopOwner ? ROUTES.chatForShop.href : `${ROUTES.activity.href}/${ACTIVITY_PATH.chat}`;
                            if (channel === ENDUSER_TYPE.PetOwner) {
                                linkToChat += `/${shop?.slug}`;
                            } else {
                                linkToChat += `/${user?.code}`;
                            }

                            const currentYear = moment().year();
                            const yearOfDate = moment(updatedAt).year();
                            let fomartLastMessageDate: string = '';
                            if (currentYear === yearOfDate) {
                                fomartLastMessageDate = DISPLAY_MOMENT_TIME_DM;
                            } else {
                                fomartLastMessageDate = DISPLAY_MOMENT_DATE_YMD;
                            }

                            return (
                                <LazyLoad key={chat?.id}>
                                    <div
                                        onClick={() => handleOnSelectChat(linkToChat, code, unreadMessageCount, shop, user)}
                                        className={classNames(
                                            'flex items-center gap-2 py-3 px-2 border border-border-4 md:hover:border-primary rounded-md md:hover:bg-slate-100 cursor-pointer overflow-hidden',
                                            activeClass(isChannelShopOwner ? user?.code : shop?.slug)
                                        )}
                                    >
                                        <ChatAvartar channel={channel} shop={shop} user={user} />
                                        <div className='flex-1 overflow-hidden'>
                                            <div className='w-full flex items-center justify-between'>
                                                <ChatUserORShopName channel={channel} shop={shop} user={user} />
                                                <div className='text-[0.75rem] text-slate-500'>{formatDateTime(updatedAt, fomartLastMessageDate)}</div>
                                            </div>
                                            <div className='text-slate-500 flex items-center w-full justify-between gap-1'>
                                                <ChatListItemContent channel={channel} shop={shop} user={user} lstMsg={lstMsg} unreadMessageCount={unreadMessageCount} />
                                            </div>
                                        </div>
                                    </div>
                                </LazyLoad>

                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
});

interface ChatItemGeneralProps {
    channel: EnduserType,
    shop?: ShopInforOfChat | null,
    user?: UserInforOfChat | null,
    unreadMessageCount?: number,
    lstMsg?: string
}

function ChatAvartar({ channel, shop, user }: ChatItemGeneralProps) {
    if (channel === ENDUSER_TYPE.PetOwner) {
        return (
            <div
                className={classNames('relative h-8 w-8 rounded-full flex items-center justify-center')}
            >
                {
                    shop && <img src={shop?.image || ShopImg} alt='shop avartar' className='size-full rounded-full' />
                }

            </div>

        );
    } else if (channel === ENDUSER_TYPE.ShopOwner) {
        return (
            <div
                className={classNames('relative h-8 w-8 rounded-full flex items-center justify-center')}
            >
                {
                    user && (
                        <Avatar className='bg-slate-600 text-white' fallback={user?.fullName[0]} />
                    )
                }
            </div>
        );
    }

    return <></>;
}

function ChatUserORShopName({ channel, shop, user }: ChatItemGeneralProps) {
    return (
        <h3 className='text-ellipsis line-clamp-1 font-semibold'>
            <>
                {
                    channel === ENDUSER_TYPE.PetOwner && shop?.name
                }
            </>
            <>
                {
                    channel === ENDUSER_TYPE.ShopOwner && user?.fullName
                }
            </>
        </h3>

    ); ;
}

function ChatListItemContent({ channel, shop, user, unreadMessageCount, lstMsg }: ChatItemGeneralProps) {
    // hook
    const { t } = useTranslation();

    let participantStatus: ChatParticipantStatus = CHAT_PARTICIPANT_STATUS.AVAILABLE;

    if (channel === ENDUSER_TYPE.PetOwner) {
        if (shop?.isDeleted === true) {
            participantStatus = CHAT_PARTICIPANT_STATUS.DELETED;
        } else if (shop?.availableStatus === false) {
            participantStatus = CHAT_PARTICIPANT_STATUS.UNAVAILABLE;
        };
    } else if (channel === ENDUSER_TYPE.ShopOwner) {
        if (user?.isDeleted === true) {
            participantStatus = CHAT_PARTICIPANT_STATUS.DELETED;
        } else if (user?.availableStatus === false) {
            participantStatus = CHAT_PARTICIPANT_STATUS.UNAVAILABLE;
        };
    }

    let content = <></>;
    switch (participantStatus) {
        case CHAT_PARTICIPANT_STATUS.DELETED:
            content = (
                <div className='flex gap-1'>
                    <div>
                        <TriangleAlert className='sixe-full' />
                    </div>
                    <p className='text-active text-small'>{t('sentences.chat_participant_deleted')}</p>
                </div>
            );
            break;
        case CHAT_PARTICIPANT_STATUS.UNAVAILABLE:
            content = (
                <div className='flex gap-1'>
                    <div>
                        <TriangleAlert className='sixe-full' />
                    </div>
                    <p className='text-active text-small'>{t('sentences.chat_participant_unavailable')}</p>
                </div>
            );
            break;
        default:
            content = (
                <p className={classNames('text-ellipsis max-w-[90%] line-clamp-1 text-small', unreadMessageCount && unreadMessageCount > 0 && 'text-black font-semibold')}>
                    {lstMsg}
                </p>
            );
    }

    return (
        <>

            {content}
            {
                unreadMessageCount && unreadMessageCount > 0 && (
                    <div className='w-5 h-5 flex justify-center items-center ml-auto bg-background-round text-white text-[0.75rem] font-semibold rounded-[2rem]'>{unreadMessageCount}</div>
                )
            }
        </>
    );
}

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/common/DropdownMenu';
import Tooltip from '@/components/common/Tooltip';
import { DISPLAY_MOMENT_TIME_DMY, ROUTES, SHOP_SERVICE_NAME } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import ReviewForm from '@/pages/shop-detail/components/components/ReviewForm';
import { SHOP_SERVICE_TYPE, SHOP_STATUS_TYPE } from '@/types/enums';
import { PetCareHistoryInfo } from '@/types/http-payload/pet';
import { formatDateTime } from '@/utils/datetime';
import { toastify } from '@/utils/toastify';
import { formatNumber } from '@/utils/utils';
import classNames from 'classnames';
import { Ellipsis, Pencil, RotateCw, SquarePen, X } from 'lucide-react';
import { flowResult } from 'mobx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HistoryForm from './HistoryForm';
import ViewHistory from './ViewHistory';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

interface PetCareHistoryItemProps {
    petCareHistory: PetCareHistoryInfo,
    type: number
}

export default observer(function HistoryItem({ petCareHistory, type }: PetCareHistoryItemProps) {
    const { code, datetime, shop, haveAppointment, totalAmount, appointmentCode } = petCareHistory;
    const isShopInactive = shop.status !== SHOP_STATUS_TYPE.Active || shop.displayStatus === false || shop.isDeleted === true;
    const isRescheduleDisable = isShopInactive || shop.operatingStatus === false;

    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        modalStore: { showModal, showAlertModal, modalWaringAlertOptions },
        uiStore: { windowSize: { width } },
        petCareStore: { deletePetCareHistory, petNotePaging, getAllPetCareHistory },
        petStore: { petDetail, getAllPet }
    } = useStore();

    // state
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [thirdLineIndex, setThirdLineIndex] = useState<number | null>(null);

    // lifecycle
    useEffect(() => {
        if (containerRef.current) {
            const spans = containerRef.current.querySelectorAll('span');
            if (spans.length > 0) {
                let initialOffsetTop = spans[0].offsetTop;
                let lineCount = 1;

                for (let i = 1; i < spans.length; i++) {
                    if (spans[i].offsetTop > initialOffsetTop) {
                        lineCount++;
                        initialOffsetTop = spans[i].offsetTop;
                    }

                    if (lineCount === 3) {
                        setThirdLineIndex(i);
                        break;
                    }
                }
            }
        }
    }, [width, petCareHistory]);

    // functions
    function showModalViewHistory() {
        showModal({
            id: 'view-history',
            size: '4xl',
            title: t('words_title.detail'),
            titleClassName: 'title-3 text-center',
            content: <ViewHistory petDetail={petDetail!} data={petCareHistory} />,
            footerClassName: 'pb-0'
        });
    }

    const showModalEditHistory = (code: string) => {
        showModal({
            id: 'edit-history',
            title: t('words_title.edit_history'),
            titleClassName: 'title-3 text-center',
            content: <HistoryForm code={code} />,
            footerClassName: 'p-0'
        });
    };

    function showModalWriteReview(code: string) {
        showModal({
            id: 'add-review',
            title: t('words_title.write_review'),
            titleClassName: 'title-3 text-center',
            content: <ReviewForm code={code} />,
            footerClassName: 'p-0'
        });
    }

    const handleRemovePetCareHistory = async (code: string) => {
        if (code) {
            showAlertModal(modalWaringAlertOptions({
                content: t('words_title.delete_pet_care_history'),
                onSave: async () => {
                    const res = await flowResult(deletePetCareHistory(code));
                    if (res) {
                        toastify('alert-success', t('messages.delete_success'));
                    }
                    getAllPetCareHistory(petNotePaging, { type: type, petCode: petDetail?.code });
                }
            }));
        }
    };

    const handleReschedule = async () => {
        if (appointmentCode) {
            const res = await flowResult(getAllPet());
            if (res) {
                if (res.length > 0) {
                    navigate(
                        `${ROUTES.bookAppointment.href}/${shop?.slug}?appointment=${encodeURI(appointmentCode)}`,
                        {
                            state: {
                                previousUrl: ROUTES.petNote.href
                            }
                        }
                    );
                } else {
                    showAlertModal({
                        id: 'pet-modal',
                        type: 'error',
                        content: t('sentences.not_any_pet')
                    });
                }
            }
        }
    };

    return (
        <div className='border rounded-sm'>
            <div className='m-2 md:m-4 flex flex-col gap-1 justify-between'>
                <div className='flex justify-between gap-4 md:gap-8 items-center pr-1'>
                    <div className='flex-1 line-clamp-1 text-title-1 font-medium'>
                        {
                            haveAppointment === false && (
                                <Tooltip
                                    content={haveAppointment === false ? t('words_title.shop_is_not_available') : null}
                                    triggerAsChild
                                >
                                    <button
                                        onClick={() => window.open(`${ROUTES.shopDetail.href}/${shop?.slug}`, '_blank')}
                                        disabled={isShopInactive}
                                        className={classNames(
                                            'font-medium line-clamp-2 pb-[1px] text-start',
                                            { 'hover:underline': !isShopInactive }
                                        )}
                                    >
                                        <span className='line-clamp-1'>{shop?.name}</span>
                                    </button>
                                </Tooltip>
                            )
                        }
                        {
                            haveAppointment && (
                                <Tooltip
                                    content={isShopInactive ? t('words_title.shop_is_not_available') : null}
                                    triggerAsChild
                                >
                                    <button
                                        onClick={(e) => {
                                            if (!isShopInactive) {
                                                window.open(`${ROUTES.shopDetail.href}/${shop?.slug}`, '_blank');
                                            }
                                            e.preventDefault();
                                        }}
                                        disabled={isShopInactive}
                                        className={classNames(
                                            'font-medium line-clamp-1 pb-[1px] text-start',
                                            { 'hover:underline': !isShopInactive }
                                        )}
                                    >
                                        <div className='line-clamp-1'>
                                            {shop?.name}
                                        </div>
                                    </button>
                                </Tooltip>
                            )
                        }
                    </div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <button
                                className=''
                                type='button'
                                title={t('words_title.more')}
                            >
                                <Ellipsis className='text-black' />
                            </button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align='end' className='w-[14.5rem]'>
                            {
                                !haveAppointment && (
                                    <>
                                        <DropdownMenuItem onClick={() => showModalEditHistory(code)} className='text-left flex items-center gap-x-3 text-sm py-3 px-4 border-b cursor-pointer hover:bg-background-5 transition-colors'>
                                            <Pencil />
                                            <p>{t('button.edit')}</p>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => handleRemovePetCareHistory(code)} className='text-left flex items-center gap-x-3 text-sm py-3 px-4 cursor-pointer hover:bg-background-5 transition-colors'>
                                            <X />
                                            <p>{t('button.remove')}</p>
                                        </DropdownMenuItem>
                                    </>
                                )
                            }
                            {
                                haveAppointment && (
                                    <>
                                        <DropdownMenuItem disabled={isRescheduleDisable} onSelect={() => handleReschedule()} className='text-left flex items-center gap-x-3 text-sm py-3 px-4 border-b cursor-pointer hover:bg-background-5 transition-colors'>
                                            <RotateCw />
                                            <p>{t('button.re_schedule')}</p>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onSelect={() => showModalWriteReview(shop?.code)} className='text-left flex items-center gap-x-3 text-sm py-3 px-4 cursor-pointer hover:bg-background-5 transition-colors'>
                                            <SquarePen />
                                            <p>{t('button.write_review')}</p>
                                        </DropdownMenuItem>
                                    </>
                                )
                            }
                            {/* <DropdownMenuItem className='text-left flex items-center gap-x-3 text-sm py-3 px-4 cursor-pointer hover:bg-background-5 transition-colors'>
                            <X />
                            <p>{t('button.cancel_appointment')}</p>
                        </DropdownMenuItem> */}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                <div className='flex space-x-2'>
                    <p className='text-tab-1 font-normal text-[0.8125rem] md:text-[0.875rem] whitespace-nowrap overflow-hidden overflow-ellipsis'>
                        {formatDateTime(datetime, DISPLAY_MOMENT_TIME_DMY)}
                    </p>
                </div>

            </div>
            <hr className='border-t border-border-8 mx-2 md:mx-4' />
            <div onClick={showModalViewHistory} className='m-2 md:my-3 md:mx-4'>
                <div className='flex'>
                    <p className='text-tab-1 font-normal min-w-24 md:w-32 text-[0.8125rem] md:text-[0.875rem]'>{t('words_title.services')}</p>
                    <div>
                        {
                            haveAppointment === false && (
                                <div ref={containerRef} className='flex flex-wrap gap-1'>
                                    {
                                        petCareHistory.services.slice(0, thirdLineIndex ?? petCareHistory.services.length).map((service, index) => (
                                            <span
                                                key={index}
                                                className='font-normal rounded-xl bg-background-4 py-1 px-1.5 max-w-[20ch] md:max-w-[30ch] truncate'
                                            >
                                                {
                                                    service.serviceName
                                                }
                                            </span>
                                        ))
                                    }
                                </div>
                            )
                        }

                        {
                            haveAppointment === true && (
                                <div ref={containerRef} className='flex flex-wrap gap-1'>
                                    {
                                        petCareHistory.services?.slice(0, thirdLineIndex ?? petCareHistory.services?.length).map((service, index) => (
                                            <span
                                                key={index}
                                                className='font-normal rounded-xl bg-background-4 py-1 px-1.5 max-w-[20ch] md:max-w-[30ch] truncate'
                                            >
                                                {
                                                    ((service.type === SHOP_SERVICE_TYPE.Cat || service.type === SHOP_SERVICE_TYPE.Dog) ?
                                                        t(`select_options.${SHOP_SERVICE_NAME[service.serviceName]?.key}`) :
                                                        service?.serviceName
                                                    )
                                                }
                                            </span>
                                        ))
                                    }
                                </div>
                            )
                        }
                        {thirdLineIndex && thirdLineIndex < petCareHistory?.services?.length && (
                            <div className='mt-2'>
                                <span
                                    className='font-normal py-1 px-1.5 max-w-[20ch] truncate text-text-4'
                                >
                                    {`+${petCareHistory.services.length - thirdLineIndex} ${t('words_title.others')}`}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className='mt-3 flex items-center'>
                    <p className='text-tab-1 font-normal min-w-24 md:w-32 text-[0.8125rem] md:text-[0.875rem]'>{t('words_title.total_amount')}</p>
                    {totalAmount !== undefined && (
                        <p className='font-semibold text-text-3 pl-1 text-[0.8125rem] md:text-[0.875rem]'>{`${formatNumber(totalAmount)} VND`}</p>
                    )}
                </div>
            </div>
        </div>
    );
});

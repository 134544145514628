import { observer } from 'mobx-react-lite';
import ShopChannelWaitApproveImg from '@/assets/images/ShopChannelWaitApproveImg.png';
import { Button } from '@/components/common/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';

export default observer(function ShopChannelWaitApprove() {
    // hooks
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();

    // function
    const handleClickViewInfo = () => {
        navigate(ROUTES.myShop.href, { state: { previousUrl: pathname } });
    };

    return (
        <div className='flex flex-col gap-4 items-center py-12 text-center'>
            <img src={ShopChannelWaitApproveImg} alt=''className='md:h-[353px] h-[163px] w-auto object-contain' />
            <div className='text-[0.9375rem] font-bold leading-[1.3125rem] text-[#383636]'>{t('words_title.shop_channel_wait_approve')}</div>
            <div className='max-w-[600px] text-small leading-[1.125rem] text-text-8'>
                <div>{t('sentences.shop_channel_wait_approve_desc_1')}</div>
                <div>{t('sentences.shop_channel_wait_approve_desc_2')}</div>
            </div>
            <Button variant='submit' size='submit' className='max-w-60' onClick={handleClickViewInfo}>
                {t('button.view_shop_info')}
            </Button>
        </div>
    );
});

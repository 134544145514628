import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import HeaderDesktopForShopChannel from './HeaderDesktopForShopChannel';
import HeaderDesktopForGeneral from './HeaderDesktopForGeneral';

export default observer(function HeaderDesktop() {
    // store
    const { authStore: { isChannelShopOwner } } = useStore();

    return (
        !isChannelShopOwner ?
            (
                <HeaderDesktopForGeneral />
            ) :
            (
                <HeaderDesktopForShopChannel />
            )
    );
});

import GeneralInformationTab from '@/pages/create-shop/tabs/GeneralInformationTab';
import { observer } from 'mobx-react-lite';

interface GeneralInfoTabEditProps {
    handleSubmitSuccess?: () => void
}

export default observer(function GeneralInfoTabEdit({ handleSubmitSuccess }: GeneralInfoTabEditProps) {
    return (
        <GeneralInformationTab
            callbackSuccess={handleSubmitSuccess}
        />
    );
});

import { CircleCheckIcon } from 'lucide-react';
import { ReactNode } from 'react';
import { Slide, toast, ToastOptions, Zoom } from 'react-toastify';

type ToastifyType = 'success' | 'error' | 'warn' | 'info' | 'custom';

const toastifyOptionsDefault: ToastOptions = {
    position: 'top-right',
    autoClose: 1500,
    delay: 0,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    transition: Slide
};

const toastifyOptionsAlert: ToastOptions = {
    className: 'toastify-position-center-center m-0 bg-alert-success rounded-md min-h-[100px] max-w-[310px]',
    position: 'top-center',
    autoClose: 1500,
    delay: 0,
    pauseOnHover: false,
    pauseOnFocusLoss: true,
    icon: false,
    closeButton: false,
    hideProgressBar: true,
    transition: Zoom
};

interface ToastifyAlertSuccessProps {
    message: string,
    icon?: ReactNode,
    hideIcon?: boolean
}

const ToastifyAlertSuccess = ({
    message,
    icon,
    hideIcon
}: ToastifyAlertSuccessProps) => {
    return (
        <div className='flex flex-col items-center gap-3'>
            {
                !hideIcon && (icon ?? <CircleCheckIcon className='text-success-1 w-12 h-12' />)
            }
            <div className='text-center text-[0.8125rem] font-normal text-success-1 whitespace-pre-line leading-4'>{message}</div>
        </div>
    );
};

export const toastify = (
    toastifyType: ToastifyType | 'alert-success',
    message: string,
    toastifyOptions?: ToastOptions & ToastifyAlertSuccessProps
) => {
    const toastifyOptionsAll = ['alert-success'].includes(toastifyType) ? Object.assign(toastifyOptionsAlert, toastifyOptions) : Object.assign(toastifyOptionsDefault, toastifyOptions);

    switch (toastifyType) {
        case 'success':
            toast.success(message, toastifyOptionsAll);
            break;

        case 'error':
            toast.error(message, toastifyOptionsAll);
            break;

        case 'warn':
            toast.warn(message, toastifyOptionsAll);
            break;

        case 'info':
            toast.info(message, toastifyOptionsAll);
            break;

        case 'alert-success':
            toast.success(<ToastifyAlertSuccess message={message} />, toastifyOptionsAll);
            break;

        default:
            toast(message, toastifyOptionsAll);
            break;
    }
};

import { useTranslation } from 'react-i18next';
import { LANGUAGES_MAPPER } from '@/configs/constants';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/components/common/Select';
import { LanguageInfo } from '@/types/language';
import { Globe } from 'lucide-react';

export default function SelectLanguage() {
    // hooks
    const {
        t,
        i18n: { language, changeLanguage }
    } = useTranslation();

    // variables
    const languages: LanguageInfo[] = [
        LANGUAGES_MAPPER.english,
        LANGUAGES_MAPPER.vietnamese
    ];
    const selectedLanguage = languages.find(lang => lang.code === language);

    // function
    const onChangeSelectLanguage = (locale: string) => {
        changeLanguage(locale);
    };

    return (
        <Select
            onValueChange={onChangeSelectLanguage}
            defaultValue={selectedLanguage?.code}
        >
            <SelectTrigger className='border-none font-normal'>
                <SelectValue>
                    {selectedLanguage && (
                        <div className='flex gap-1 items-center'>
                            <Globe className='text-nav-1' />
                            <div className='text-nav-1 text-sm'>{t(selectedLanguage.fullname)}</div>
                        </div>
                    )}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='w-[180px]'>
                {languages.map(lang => (
                    <SelectItem
                        className='hover:cursor-pointer'
                        key={lang.code}
                        value={lang.code}
                    >
                        <span>{t(lang.fullname)}</span>
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}

import { action, flow, makeAutoObservable, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import { FileApi } from '../apis';
import { toastify } from '@/utils/toastify';
import RootStore from '.';

interface FileUploadInstanceOptions {
    id: string,
    title: string,
    fileType: string,
    file: File,
    presignedUrl: PresignedUrl,
    onSuccess?: () => void,
    onError?: () => void,
    onFinish?: () => void
}

interface PresignedUrl {
    url: string,
    fields: Record<string, string>
}

interface UploadPayload {
    onUploadProgress?: (progressEvent: { progress: number }) => void,
    onSuccess?: () => void,
    onError?: () => void,
    onFinish?: () => void
}

class FileStore extends BaseStore {
    fileUploadList: FileUploadInstance[] = [];
    uploadCallback: () => void = () => {};

    fileApi: FileApi;

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.fileApi = new FileApi();
        makeObservable(this, {
            fileUploadList: observable,
            uploadCallback: observable,
            uploadFile: flow.bound,
            setFilesUpload: action.bound,
            finishUploadFiles: action.bound
        });
    }

    *uploadFile(url: string, payload: FormData, { onUploadProgress, onSuccess, onError, onFinish }: UploadPayload, isShowLoading: boolean = true) {
        try {
            isShowLoading && this.rootStore.apiStore.showLoading();
            yield this.fileApi.uploadFile({ url, payload, onUploadProgress });
            if (onSuccess) {
                onSuccess();
                isShowLoading && this.rootStore.apiStore.hideLoading();
            }
        } catch (error) {
            if (onError) onError();
        } finally {
            if (onFinish) onFinish();
        }
    }

    setFilesUpload(filesOptions: FileUploadInstanceOptions[] = [], uploadCallback: () => void = () => {}) {
        if (this.fileUploadList.length === 0) {
            if (Array.isArray(filesOptions)) {
                this.fileUploadList = filesOptions.map(options => new FileUploadInstance(options));
            }
            this.uploadCallback = uploadCallback;
        }
    }

    finishUploadFiles() {
        this.clean();
    }

    clean() {
        super.clean();
        this.fileUploadList = [];
        this.uploadCallback = () => {};
    }
}

class FileUploadInstance {
    id: string = '';
    title: string = '';
    fileType: string = '';
    file: File | null = null;
    presignedUrl: PresignedUrl = { url: '', fields: {} };
    onSuccess: () => void;
    onError: () => void;
    onFinish: () => void;

    constructor({ id, title, fileType, file, presignedUrl, onSuccess, onError, onFinish }: FileUploadInstanceOptions) {
        makeAutoObservable(this);
        this.id = id;
        this.title = title;
        this.fileType = fileType;
        this.file = file;
        this.presignedUrl = presignedUrl;
        this.onSuccess = onSuccess ?? this.defaultSuccess;
        this.onError = onError ?? this.defaultError;
        this.onFinish = onFinish ?? (() => {});
    }

    defaultSuccess = () => {
        toastify('success', 'Upload file thành công');
    };

    defaultError = () => {
        toastify('error', 'Upload file không thành công');
    };
}

export default FileStore;

import { Button } from '@/components/common/Button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImagePlusIcon, XIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { useStore } from '@/hooks/useStore';
import { useTranslation } from 'react-i18next';
import { flowResult } from 'mobx';
import { toastify } from '@/utils/toastify';
import { ForumType } from '@/types/enums';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const FILE_SIZE = 5 * 1024 * 1024; // 5 MB

interface PostFormProps {
    slug?: string,
    params?: { [key: string]: any },
    forumType: ForumType
}

export const PostForm = ({ slug, params, forumType }: PostFormProps) => {
    // hooks
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    // store
    const { topicStore: { topic, createTopic, editTopic, getAllTopic, getTopicDetail, paging }, modalStore: { hideModal } } = useStore();
    const [imagePreview, setimagePreview] = useState<string | null>(topic?.image || null);

    // lifecycle
    useEffect(() => {
        return () => {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [imagePreview]);

    // validate Schema
    const validateSchema = yup.object().shape({
        title: yup
            .string()
            .required('form_error_validate.required'),
        description: yup
            .string()
            .required('form_error_validate.required'),
        image: yup
            .mixed()
            .nullable()
            .test('fileType', 'form_error_validate.job_image_upload_format', (value) => {
                if (!value) return true;
                if (value instanceof File) {
                    return SUPPORTED_FORMATS.includes(value.type);
                }
                if (typeof value === 'string') {
                    return true;
                }
                return false;
            })
            .test('fileSize', 'form_error_validate.job_image_upload_format', (value) => {
                if (!value) return true;
                if (typeof value === 'string') {
                    return true;
                }
                return value instanceof File && value.size <= FILE_SIZE;
            })
    });

    type FormData = yup.InferType<typeof validateSchema>;

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            title: topic?.title || '',
            description: topic?.description || '',
            image: topic?.image || undefined
        }
    });

    // functions
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const newimagePreview = URL.createObjectURL(file);
            setimagePreview(newimagePreview);

            form.setValue('image', file);
            form.trigger('image');
        }
    };

    const handleRemoveImage = () => {
        setimagePreview(null);
        form.setValue('image', '');
        form.trigger('image');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const onSubmit = async (data: FormData) => {
        if (slug) {
            const res = await flowResult(editTopic({ slug, ...data }));
            if (res) {
                hideModal('topic-edit-post');
                toastify('alert-success', 'Post successfully!');
                getTopicDetail({
                    slug
                });
            }
        } else {
            const res = await flowResult(createTopic({ ...data, forum: forumType }));
            if (res) {
                hideModal('topic-new-post');
                toastify('alert-success', 'Post successfully!');
                getAllTopic(params || {}, paging);
            }
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-4'>
                    <FormField
                        control={form.control}
                        name='title'
                        render={({ field }) => (
                            <FormItem>
                                <Label required className='text-[0.9375rem] font-medium'>{t('words_title.title')}</Label>
                                <FormControl>
                                    <Input type='text' className='placeholder:text-placeholder-1 mt-1.5' {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='description'
                        render={({ field }) => (
                            <FormItem>
                                <Label required className='text-[0.9375rem] font-medium'>{t('words_title.description')}</Label>
                                <FormControl>
                                    <textarea maxLength={5000} className='mt-1.5 block h-[40vh] w-full border rounded-lg pt-4 pl-3 placeholder:text-placeholder-1 outline-none resize-none focus:border-active' {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='image'
                        render={({ field: { onChange, value, ref, ...rest } }) => (
                            <FormItem>
                                <Label className='text-[0.9375rem] font-medium'>{t('words_title.image')}</Label>
                                <FormDescription className='text-small leading-[0.875rem] my-1.5'>{t('sentences.only_support_file_add_job')}</FormDescription>
                                <FormControl>
                                    <Input
                                        id='post-image'
                                        type='file'
                                        accept='image/*'
                                        ref={fileInputRef}
                                        onChange={handleImageChange}
                                        className='hidden'
                                        {...rest}
                                    />
                                </FormControl>
                                <Label htmlFor='post-image' className='flex items-center justify-center cursor-pointer border border-input rounded-lg py-3'>
                                    <ImagePlusIcon className='text-active h-6 w-6 mr-2' />
                                    <span className='text-placeholder-1 font-medium text-[0.9375rem] leading-4'>
                                        {t('button.choose_image')}
                                    </span>
                                </Label>
                                <FormMessage />
                                {imagePreview && (
                                    <div className='flex justify-center w-full'>
                                        <div className='relative mt-4 w-fit'>
                                            <img
                                                src={imagePreview}
                                                alt='Job-image'
                                                className='w-auto min-w-40 h-40 object-cover rounded-lg'
                                            />
                                            <button
                                                type='button'
                                                title='remove'
                                                onClick={handleRemoveImage}
                                                className='absolute top-1 left-1 bg-black bg-opacity-80 rounded-full p-1 shadow-lg'
                                            >
                                                <XIcon className='w-3 h-3 text-white' />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </FormItem>
                        )}
                    />
                </div>
                <Button disabled={form.formState.isSubmitting} type='submit' variant='submit' size='submit' className='mt-6'>
                    {slug ? t('button.save') : t('button.create_post')}
                </Button>
            </form>
        </Form>
    );
};

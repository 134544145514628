import { ChevronDown, LockIcon, LogOutIcon, PawPrintIcon, StarIcon, StoreIcon, UserIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/components/common/DropdownMenu';
import { Button } from '@/components/common/Button';
import { Link } from 'react-router-dom';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { ROUTES } from '@/configs/constants';

export default observer(function UserAvatar() {
    // hooks
    const { t } = useTranslation();

    // store
    const { authStore: { signOut, profile, isChannelShopOwner, onSwitchToPetChannel } } = useStore();

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <div className='flex items-center gap-1 cursor-pointer'>
                    <Button
                        variant='outline'
                        className='relative h-[25px] w-[25px] rounded-full bg-active flex items-center justify-center'
                    >
                        {/* <User className='text-white w-4 h-4' fill='white' /> */}
                        <div className='text-white w-4.5 h-4.5'>{profile?.fullName?.[0]}</div>
                    </Button>
                    <span className='text-nav-1 text-sm truncate max-w-32'>{profile?.fullName}</span>
                    <ChevronDown className='text-nav-1 w-4 h-4' />
                </div>
            </DropdownMenuTrigger>

            <DropdownMenuContent className='w-56 text-nav-1' align='end' forceMount>
                {
                    isChannelShopOwner ?
                        (
                            <>
                                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                                    <Link to={ROUTES.myShop.href} className='flex items-center hover:underline text-text-4'>
                                        <StoreIcon className='w-4 h-4 mr-3 text-text-4' />
                                        {t('menu_dropdown.shop_info')}
                                    </Link>
                                </DropdownMenuItem>

                                <DropdownMenuSeparator />
                                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                                    <Link to={ROUTES.myAccount.href} className='flex items-center hover:underline text-text-4'>
                                        <UserIcon className='w-4 h-4 mr-3 text-text-4' />
                                        {t('menu_dropdown.account_info')}
                                    </Link>
                                </DropdownMenuItem>

                                <DropdownMenuSeparator />
                                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                                    <Link to={ROUTES.changePassword.href} className='flex items-center hover:underline text-text-4'>
                                        <LockIcon className='w-4 h-4 mr-3 text-text-4' />
                                        {t('menu_dropdown.change_password')}
                                    </Link>
                                </DropdownMenuItem>

                                <DropdownMenuSeparator />
                                <DropdownMenuItem className='hover:cursor-pointer text-active hover:underline border border-active border-dashed' onClick={onSwitchToPetChannel}>
                                    <PawPrintIcon className='w-4 h-4 mr-3 text-active stroke-[2px]' />
                                    {t('menu_dropdown.switch_pet_owner')}
                                </DropdownMenuItem>

                                <DropdownMenuSeparator />
                                <DropdownMenuItem
                                    className='hover:cursor-pointer hover:underline text-text-4'
                                    onClick={() => signOut()}
                                >
                                    <LogOutIcon className='w-4 h-4 mr-3 text-text-4' />
                                    {t('words_title.sign_out')}
                                </DropdownMenuItem>
                            </>
                        ) :
                        (
                            <>
                                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                                    <Link to={ROUTES.myAccount.href} className='flex items-center hover:underline text-text-4'>
                                        <UserIcon className='w-4 h-4 mr-3 text-text-4' />
                                        {t('words_title.my_account')}
                                    </Link>
                                </DropdownMenuItem>

                                <DropdownMenuSeparator />
                                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                                    <Link to={ROUTES.myPet.href} className='flex items-center hover:underline text-text-4'>
                                        <PawPrintIcon className='w-4 h-4 mr-3 text-text-4' />
                                        {t('words_title.my_pet')}
                                    </Link>
                                </DropdownMenuItem>

                                <DropdownMenuSeparator />
                                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                                    <Link to={ROUTES.myReview.href} className='flex items-center hover:underline text-text-4'>
                                        <StarIcon className='w-4 h-4 mr-3 text-text-4' />
                                        {t('words_title.my_review')}
                                    </Link>
                                </DropdownMenuItem>

                                <DropdownMenuSeparator />
                                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                                    <Link to={ROUTES.changePassword.href} className='flex items-center hover:underline text-text-4'>
                                        <LockIcon className='w-4 h-4 mr-3 text-text-4' />
                                        {t('words_title.change_password')}
                                    </Link>
                                </DropdownMenuItem>

                                <DropdownMenuSeparator />
                                <DropdownMenuItem
                                    className='hover:underline hover:cursor-pointer text-text-4'
                                    onClick={() => signOut()}
                                >
                                    <LogOutIcon className='w-4 h-4 mr-3 text-text-4' />
                                    {t('words_title.sign_out')}
                                </DropdownMenuItem>
                            </>
                        )
                }
            </DropdownMenuContent>
        </DropdownMenu>
    );
});

import { Button } from '@/components/common/Button';
import { useStore } from '@/hooks/useStore';
import { LANGUAGES_SUPPORTED } from '@/types/enums';
import { PetInfoResponse } from '@/types/http-payload/pet';
import { formatAge } from '@/utils/datetime';
import { toastify } from '@/utils/toastify';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import MyPetForm from './SettingPetForm';
interface Props {
    pet: PetInfoResponse
}
export default observer(function MyPetItem({ pet }: Props) {
    // hook
    const { t, i18n: { language } } = useTranslation();

    // store
    const {
        modalStore: { showModal, showAlertModal, modalWaringAlertOptions },
        petStore: { deletePet, getAllPet }
    } = useStore();

    // function
    const handleEditMyPet = (code: string) => {
        showModal({
            id: 'edit-my-pet',
            size: '2xl',
            footerClassName: 'p-0',
            title: t('words_title.edit_my_pet'),
            titleClassName: 'title-3 text-center',
            content: <MyPetForm code={code} />
        });
    };

    const handleDeleteMyPet = (code: string) => {
        if (code) {
            showAlertModal(modalWaringAlertOptions({
                content: t('words_title.delete_pet'),
                onSave: async () => {
                    const res = await flowResult(deletePet(code));
                    if (res) {
                        toastify('alert-success', t('messages.delete_success'));
                    }
                    getAllPet();
                }
            }));
        }
    };

    return (
        <>
            <div className='w-full px-[0.375rem] pt-2 pb-[0.75rem] border border-border-2 rounded-[0.25rem] bg-white flex flex-col'>
                <div className='relative w-full'>
                    <img className='w-full aspect-[1/1] mx-auto rounded-sm object-cover' src={pet?.image} alt='' />
                    <span className='absolute left-1 bottom-1 text-small leading-[0.75rem] p-1 rounded-[0.25rem] bg-white text-black'>
                        {formatAge(pet.birthday, t)}
                    </span>
                </div>
                <p className='mt-[0.625rem] font-semibold line-clamp-1'>{pet?.name}</p>
                <div className='text-tab-1 mb-1 line-clamp-1'>
                    <span className='text-small'>{pet.typeAnimal === 1 ? t('select_options.my_pet_dog') : (pet.typeAnimal === 2 ? t('select_options.my_pet_cat') : t('select_options.my_pet_other'))}</span>
                    {
                        (pet.typeAnimal && pet?.species !== '') && <span className='text-small text-border-4 mx-2 lg:mx-1 h-[1.5625rem]'>|</span>
                    }
                    <span className='text-small'>{typeof pet?.species === 'string' ? pet?.species : (language === LANGUAGES_SUPPORTED.Vietnamese ? pet?.species?.name : pet?.species?.nameEn)}</span>
                </div>
                <div className='flex gap-[0.625rem] mt-auto'>
                    <Button onClick={() => handleDeleteMyPet(pet.code!)} className='w-1/2 mt-1 h-6 border border-border-4 text-[0.8125rem] text-[#4A4A4A] rounded-sm' variant='outline'>{t('button.delete')}</Button>
                    <Button
                        className='w-1/2 mt-1 h-6 border border-border-4 text-[0.8125rem] text-text-13 rounded-sm'
                        variant='outline'
                        onClick={() => handleEditMyPet(pet.code ?? '')}
                    >
                        {t('button.edit')}
                    </Button>
                </div>
            </div>
        </>
    );
});

import React from 'react';
import { useStore } from './useStore';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { getStorage } from '@/utils/browsers';
import { ENDUSER_TYPE } from '@/types/enums';

// Định nghĩa kiểu cho các props của HOC
interface WithNonAuthenticationProps {
    // Các props khác bạn muốn truyền vào
}

export default function withNonAuthentication<P extends object>(
    WrappedComponent: React.ComponentType<P>
) {
    const WithNonAuthentication = (props: P & WithNonAuthenticationProps): React.ReactElement => {
        // hooks
        const location = useLocation();

        // store
        const {
            authStore: { token }
        } = useStore();

        // state
        const signInUserType = getStorage('signInUserType');

        if (token) {
            if (location.pathname === ROUTES.signIn.href) {
                if (signInUserType === ENDUSER_TYPE.ShopOwner.toString() && location.hash === '#shop-owner') {
                    // to do
                } else {
                    return <Navigate to={ROUTES.home.href} />;
                }
            } else {
                return <Navigate to={ROUTES.home.href} />;
            }
        }

        return <WrappedComponent {...(props as P)} />;
    };

    return WithNonAuthentication;
}

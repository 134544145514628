import { ChatApi } from '@/apis';
import BaseStore from './BaseStore';
import RootStore from '.';
import { flow, makeObservable, observable } from 'mobx';
import { ResponseData } from '@/types/http';
import { ChatInfor, ChatListItem, ChatMessage } from '@/types/chat';
import { GetMoreMessageRequest } from '@/types/http-payload/chat';
import { LOAD_MESSAGE_TYPE } from '@/types/enums';

export default class ChatStore extends BaseStore {
    api: ChatApi;
    chatList: ChatListItem[] = [];
    chatDetail: ChatInfor = {} as ChatInfor;
    chatMessages: ChatMessage[] = [];
    unreadChatsCount: number = 0;
    newMessageByYou: { byYou: boolean } = { byYou: false };

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            chatList: observable,
            chatMessages: observable,
            chatDetail: observable,
            unreadChatsCount: observable,
            newMessageByYou: observable,
            getChatList: flow.bound,
            getChatMessages: flow.bound,
            getMoreMessages: flow.bound,
            sendNewMessage: flow.bound,
            getPreSignedURL: flow.bound,
            seenMessages: flow.bound,
            countUnreadChat: flow.bound

        });
        this.api = new ChatApi();
    }

    *getChatList(payload, isHideLoading: boolean = true) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getChatList, payload, { hideLoading: isHideLoading });
            if (res.ok) {
                this.chatList = res.data;
                return res.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getChatMessages(payload, isHideLoading: boolean = false) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getChatDetail, payload, { hideLoading: isHideLoading });
            if (res?.ok) {
                this.chatMessages = res.data.messages;
                return res.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getMoreMessages(payload: GetMoreMessageRequest) {
        try {
            const { isLast } = payload;
            const length = this.chatMessages?.length || 0;
            if (length > 0) {
                payload.messageId = isLast === LOAD_MESSAGE_TYPE.End ? this.chatMessages[length - 1].id : this.chatMessages[0].id;
            }
            const res: ResponseData<ChatMessage[]> = yield this.rootStore.apiStore.call(this.api, this.api.getMoreMessage, payload, { hideLoading: true });
            if (res?.ok) {
                this.chatMessages = isLast === LOAD_MESSAGE_TYPE.End ? [...this.chatMessages, ...res.data] : [...res.data, ...this.chatMessages];
                return res.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *sendNewMessage(payload, callBackErrorFn?: Function) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.sendNewMessage, payload, { hideLoading: true, disableAlertError: true });
            if (res?.ok) {
                return true;
            }
        } catch (error) {
            console.log(error);
            callBackErrorFn && callBackErrorFn(error);
        }
    }

    *getPreSignedURL(payload: { fileExtList: string[] }) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getPreSignedChatImageURL, payload, { hideLoading: true });
            if (res?.ok) {
                return res.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *seenMessages(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.seenMessages, payload, { hideLoading: true });
            if (res?.ok) {
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *countUnreadChat(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.countUnreadChats, payload, { hideLoading: true });
            if (res?.ok) {
                this.unreadChatsCount = res.data.unreadChatsCount;
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.chatList = [];
        this.chatMessages = [];
        this.chatDetail = {} as ChatInfor;
    }
}

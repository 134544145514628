import ApiService from './ApiService';

export default class PetCareApi extends ApiService {
    constructor() {
        super('pet-care-history');
    }

    createPetCareHistory(payload) {
        return this.post('', payload);
    };

    getAllPetCareHistory(payload) {
        return this.get('', payload);
    };

    deletePetCareHistory(code) {
        return this.delete(code);
    };

    getDetailPetCareHistory(code) {
        return this.get(code);
    };

    updatePetCareHistory(payload) {
        const { code, ...data } = payload;
        return this.put(code, data);
    }
}

import { DISPLAY_MOMENT_DATE_DMYHM_AM_PM, FORUM_PATH, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { FORUM_TYPE, ForumType, ROLE, TOPIC_TYPE, TopicType } from '@/types/enums';
import { Topic } from '@/types/topic';
import { formatDateTime } from '@/utils/datetime';
import { convertNumber } from '@/utils/utils';
import classNames from 'classnames';
import { Calendar, Eye, ThumbsUp, TriangleAlert } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

interface TopicItemProps {
    topic: Topic,
    isPinTopics?: boolean,
    forumType: ForumType
}

export const TopicItem = observer(({ topic, isPinTopics, forumType }: TopicItemProps) => {
    const { slug, title, type, createdAt, totalLikes, totalViews, isRead, creatorBy } = topic;

    // store
    const { authStore: { isChannelShopOwner } } = useStore();

    // functions
    const getIconByType = (type) => {
        const newType: TopicType = (creatorBy && creatorBy === ROLE.Enduser && isPinTopics === true) ? TOPIC_TYPE.Best : type;

        switch (newType) {
            case TOPIC_TYPE.Notice:
                return <span className='inline-block w-9 align-middle'><TriangleAlert className='text-active' /></span>;
            case TOPIC_TYPE.Advertisement:
                return <span className='inline-block w-9 font-medium text-icon-10'>AD</span>;
            case TOPIC_TYPE.Best:
                return <span className='inline-block w-9 font-medium text-icon-11'>Best</span>;
            default:
                return null;
        }
    };

    return (
        <Link
            to={`${isChannelShopOwner ? ROUTES.shopForum.href : ROUTES.forum.href}/${forumType === FORUM_TYPE.Career ? FORUM_PATH.career : FORUM_PATH.freeTopic}/${slug}`}
            className={classNames(
                'flex justify-between border-b py-3 group',
                getIconByType(type) ? 'bg-background-5' : (isRead ? 'bg-white' : 'bg-border-9')
            )}
        >
            <div className='group-hover:underline inline-block text-text-3 px-4 text-nowrap overflow-hidden text-ellipsis'>
                {getIconByType(type)}
                {title}
            </div>
            <div className={classNames('hidden pr-2', isChannelShopOwner ? 'lg:flex lg:gap-2 justify-end items-center' : 'md:flex md:gap-2 justify-end items-center')}>
                <div
                    className='flex items-center justify-center gap-1 min-w-32'
                >
                    <Calendar className='size-[1.0625rem] text-icon-2' />
                    <span className='text-small text-icon-2 text-nowrap'>
                        { formatDateTime(createdAt || '', DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}
                    </span>
                </div>
                <div className='h-[1.125rem] w-[0.0625rem] bg-border-8'></div>
                <div className='flex items-center gap-1 w-[64px] px-1'>
                    <Eye className='size-4 text-icon-2' />
                    <div><span className='text-small text-icon-2'>{totalViews && convertNumber(totalViews)}</span></div>
                </div>
                <div className='h-[1.125rem] w-[0.0625rem] bg-border-8'></div>
                <div className='flex items-center justify-start gap-1 w-[64px] px-1'>
                    <ThumbsUp className='size-4 text-icon-2' />
                    <div><span className='text-small text-icon-8'>{totalLikes && convertNumber(totalLikes)}</span></div>
                </div>
            </div>
        </Link>
    );
});

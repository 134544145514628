import { useState } from 'react';
import { Star } from 'lucide-react';
import classNames from 'classnames';

interface RatingProps {
    maxStars?: number,
    value?: number,
    onChange?: (newValue: number) => void,
    className?: string
}

export default function StarRating({
    maxStars = 5,
    value = 0,
    onChange,
    className = ''
}: RatingProps) {
    // state
    const [hoverValue, setHoverValue] = useState<number | null>(null);

    // function
    const handleMouseEnter = (index: number) => {
        setHoverValue(index);
    };

    const handleMouseLeave = () => {
        setHoverValue(null);
    };

    const handleClick = (index: number) => {
        if (onChange) {
            onChange(index);
        }
    };

    return (
        <div className={classNames('flex space-x-3', className)}>
            {Array.from({ length: maxStars }, (_, index) => {
                const currentIndex = index + 1;
                return (
                    <Star
                        key={index}
                        className={
                            `fill-current size-[30px] cursor-pointer
                             ${(hoverValue || value) >= currentIndex ? 'text-icon-3' : 'text-gray-300'}`
                        }
                        onMouseEnter={() => handleMouseEnter(currentIndex)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleClick(currentIndex)}
                    />
                );
            })}
        </div>
    );
}

import { DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, MAX_LENGTH_TEXT_DESKTOP, MAX_LENGTH_TEXT_MOBILE, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { APPOINTMENT_STATUS_TYPE, PICK_UP_PRICE_TYPE } from '@/types/enums';
import { formatDateTime } from '@/utils/datetime';
import { formatNumber, shortenParagraph } from '@/utils/utils';
import classNames from 'classnames';
import { SquarePen } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default observer(function PickupService() {
    // hooks
    const { t } = useTranslation();

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const {
        appointmentStore: { appointment },
        uiStore: { devicesScreen: { mobile } }
    } = useStore();

    return (
        <div className='flex flex-col gap-4 bg-white p-4 md:rounded-sm'>
            <div className='flex justify-between md:px-4 items-center'>
                <h4 className='font-semibold text-[0.9375rem]'>{t('words_title.pick_up_service')}</h4>
                {(appointment?.status === APPOINTMENT_STATUS_TYPE.NotConfirmed ||
                    appointment?.status === APPOINTMENT_STATUS_TYPE.Confirmed
                ) && (
                    <Link to={`${ROUTES.editPickupService.href}/${appointment.code}`}className='flex gap-1 items-center justify-center'>
                        <SquarePen className='text-black size-6 md:size-4' />
                        <p className='font-medium hidden md:block'>{t('button.edit')}</p>
                    </Link>
                )}
            </div>
            <hr className='text-border-8' />
            <div className='grid grid-cols-2 md:gap-4 gap-y-1.5'>
                <div className={classNames('md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5')}>
                    <p className='text-text-4'>{t('words_title.book_pick_up?')}</p>
                    <p className=''>{appointment?.pickUpService ? t('words_title.yes') : t('words_title.no')}</p>
                </div>
                {appointment?.pickUpService && (
                    <>
                        <div className='md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5'>
                            <p className='text-text-4'>{t('words_title.price')}</p>
                            <p className=''>
                                {appointment.pickUpPriceType === PICK_UP_PRICE_TYPE.Free ? t('words_title.free') : (appointment.pickUpPrice ? `${formatNumber(appointment.pickUpPrice)} VND` : '-- VND')}
                            </p>
                        </div>
                        <div className='md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5'>
                            <p className='text-text-4'>{t('words_title.pick_up_time')}</p>
                            <p className=''>
                                {appointment?.pickUpTime ?
                                    formatDateTime(appointment?.pickUpTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                        onlyTime: true,
                                        currentformat: DISPLAY_MOMENT_TIME_HMS
                                    }) :
                                    '--'}
                            </p>
                        </div>
                        <div className='md:px-4 py-3 col-span-2 md:col-span-1 border-b border-border-5'>
                            <p className='text-text-4'>{t('words_title.contact')}</p>
                            <p className=''>{appointment.contact || '--'}</p>
                        </div>
                        <div className='md:px-4 py-3 col-span-2 border-b border-border-5'>
                            <p className='text-text-4'>{t('words_title.pick_up_address')}</p>
                            <p className='whitespace-pre-wrap'>{appointment.pickUpAddress || '--'}</p>
                        </div>
                    </>
                )}
                <div className='md:px-4 py-3 col-span-2'>
                    <p className='text-text-4'>{t('words_title.note')}</p>
                    <p className='whitespace-pre-wrap'>
                        {shortenParagraph(appointment?.pickUpNote || '--', mobile ? MAX_LENGTH_TEXT_MOBILE : MAX_LENGTH_TEXT_DESKTOP, isExpanded).value}
                        {
                            shortenParagraph(appointment?.pickUpNote || '--', mobile ? MAX_LENGTH_TEXT_MOBILE : MAX_LENGTH_TEXT_DESKTOP, isExpanded).isEllipsis && (
                                <button
                                    onClick={toggleExpand}
                                    className='text-active text-[0.8125rem] text-blue-500 no-underline'
                                >
                                    {isExpanded ? t('button.view_less') : t('button.view_more')}
                                </button>
                            )
                        }
                    </p>
                </div>
            </div>
        </div>
    );
});

import App from '@/App';
import { ROUTES } from '@/configs/constants';
import FormPanelLayout from '@/layouts/FormPanelLayout';
import MyAccountPanelLayout from '@/layouts/MyAccountPanelLayout';
import HomePanelLayout from '@/layouts/HomePanelLayout';
import {
    CreateShopPage, ForgotPasswordPage, HomePage, MyAccountChangePassword, MyAccountEditPage, MyAccountEditPhonePage, MyAccountPage,
    MyPetPage, MyReviewPage, NotFoundPage, ResetPasswordPage, ShopDetailPage, SignInPage, SignUpPage, VerifyOtpPage, Activitypage, ForumPage, NotificationDetailPage, NotificationListPage, SearchResultPage,
    BookAppointmentPage,
    AppointmentManagementPage,
    AppointmentDetailPage,
    EditGeneralInformationPage,
    EditServiceListPage,
    EditPickupServicePage,
    MyShopPage,
    PetNotePage,
    ReviewManagementPage,
    NearByPage,
    ChatPage
} from '@/pages';

import ForumPanelLayout from '@/layouts/ForumPanelLayout';
import { ENDUSER_TYPE, VERIFY_OTP_TYPE } from '@/types/enums';
import { createBrowserRouter } from 'react-router-dom';
import ShopDetailPanelLayout from '@/layouts/ShopDetailPanelLayout';
import CreateShopPanelLayout from '@/layouts/CreateShopPanelLayout';
import ShopManagementPanelLayout from '@/layouts/ShopManagementPanelLayout';
import withNonAuthentication from '@/hooks/withNonAuthentication';
import withAuthentication from '@/hooks/withAuthentication';
import ShopOwnerChannel from '@/pages/home/shop-owner-channel';

const FormPanelLayoutWithNonAuthentication = withNonAuthentication(FormPanelLayout);
const MyAccountPanelLayoutWithAuthentication = withAuthentication(MyAccountPanelLayout);
const CreateShopPanelLayoutWithAuthentication = withAuthentication(CreateShopPanelLayout);
const ShopManagementPanelLayoutWithAuthentication = withAuthentication(ShopManagementPanelLayout);
const ForumPanelLayoutWithAuthentication = withAuthentication(ForumPanelLayout);

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <FormPanelLayoutWithNonAuthentication />,
                children: [
                    {
                        path: ROUTES.signIn.href,
                        element: <SignInPage />
                    },
                    {
                        path: ROUTES.signUp.href,
                        element: <SignUpPage />
                    },
                    {
                        path: ROUTES.forgotPassword.href,
                        element: <ForgotPasswordPage />
                    },
                    {
                        path: ROUTES.verifyOtpSignUp.href,
                        element: <VerifyOtpPage type={VERIFY_OTP_TYPE.Signup} />
                    },
                    {
                        path: ROUTES.verifyOtpForgotPassword.href,
                        element: <VerifyOtpPage type={VERIFY_OTP_TYPE.ForgotPassword} />
                    },
                    {
                        path: ROUTES.resetPassword.href,
                        element: <ResetPasswordPage />
                    }
                ]
            },
            {
                path: '/',
                element: <HomePanelLayout />,
                children: [
                    {
                        index: true,
                        element: <HomePage />
                    },
                    {
                        path: ROUTES.nearBy.href,
                        element: <NearByPage />,
                        children: [
                            {
                                path: ':slug'
                            }
                        ]
                    }

                ]
            },
            {
                path: '/',
                element: <MyAccountPanelLayout />,
                children: [
                    {
                        path: ROUTES.myAccount.href,
                        element: <MyAccountPage />
                    }
                ]
            },
            {
                path: '/',
                element: <MyAccountPanelLayoutWithAuthentication />,
                children: [
                    {
                        path: ROUTES.myAccountEdit.href,
                        element: <MyAccountEditPage />
                    },
                    {
                        path: ROUTES.myAccountEditPhone.href,
                        element: <MyAccountEditPhonePage />
                    },
                    {
                        path: ROUTES.changePassword.href,
                        element: <MyAccountChangePassword />
                    }
                ]
            },
            {
                path: '/',
                element: <MyAccountPanelLayoutWithAuthentication channels={[ENDUSER_TYPE.PetOwner]} />,
                children: [
                    {
                        path: ROUTES.myReview.href,
                        element: <MyReviewPage />
                    },
                    {
                        path: ROUTES.myPet.href,
                        element: <MyPetPage />
                    }
                ]
            },
            {
                path: '/',
                element: <MyAccountPanelLayoutWithAuthentication channels={[ENDUSER_TYPE.ShopOwner]} />,
                children: [
                    {
                        path: ROUTES.myShop.href,
                        element: <MyShopPage />
                    }
                ]
            },
            {
                path: '/',
                element: <ShopDetailPanelLayout />,
                children: [
                    {
                        index: true,
                        path: `${ROUTES.shopDetail.href}/:slug`,
                        element: <ShopDetailPage />
                    }
                ]
            },
            {
                path: '/',
                element: <CreateShopPanelLayoutWithAuthentication channels={[ENDUSER_TYPE.PetOwner]} />,
                children: [
                    {
                        path: ROUTES.createShop.href,
                        element: <CreateShopPage />
                    }
                ]
            },
            {
                path: '/',
                element: <ForumPanelLayout />,
                children: [
                    {
                        path: ROUTES.forum.href,
                        element: <ForumPage />,
                        children: [
                            {
                                path: ':tabPath',
                                element: <></>,
                                children: [
                                    {
                                        path: ':slug',
                                        element: <></>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: ROUTES.search.href,
                        element: <SearchResultPage />
                    }
                ]
            },
            {
                path: '/',
                element: <ForumPanelLayoutWithAuthentication />,
                children: [
                    {
                        path: ROUTES.notification.href,
                        element: <NotificationListPage />
                    },
                    {
                        path: `${ROUTES.notification.href}/:code`,
                        element: <NotificationDetailPage />
                    }
                ]
            },
            {
                path: '/',
                element: <ForumPanelLayoutWithAuthentication channels={[ENDUSER_TYPE.ShopOwner]} />,
                children: [
                    {
                        path: ROUTES.chatForShop.href,
                        element: <ChatPage />,
                        children: [
                            {
                                path: ':slug',
                                element: <></>
                            }
                        ]
                    }
                ]
            },
            {
                path: '/',
                element: <ForumPanelLayoutWithAuthentication channels={[ENDUSER_TYPE.PetOwner]} />,
                children: [
                    {
                        path: ROUTES.activity.href,
                        element: <Activitypage />,
                        children: [
                            {
                                path: ':tabPath',
                                element: <></>,
                                children: [
                                    {
                                        path: ':slug',
                                        element: <></>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: `${ROUTES.bookAppointment.href}/:shopSlug`,
                        element: <BookAppointmentPage />
                    },
                    {
                        path: ROUTES.petNote.href,
                        element: <PetNotePage />
                    }
                ]
            },
            {
                path: '/',
                element: <ShopManagementPanelLayoutWithAuthentication channels={[ENDUSER_TYPE.ShopOwner]} />,
                children: [
                    {
                        path: ROUTES.reviewManagement.href,
                        element: (
                            <ShopOwnerChannel>
                                <ReviewManagementPage />
                            </ShopOwnerChannel>
                        )
                    },
                    {
                        path: ROUTES.appointmentManagement.href,
                        element: (
                            <ShopOwnerChannel>
                                <AppointmentManagementPage />
                            </ShopOwnerChannel>
                        )
                    },
                    {
                        path: ROUTES.shopForum.href,
                        element: (
                            <ShopOwnerChannel>
                                <ForumPage />
                            </ShopOwnerChannel>
                        ),
                        children: [
                            {
                                path: ':tabPath',
                                element: <></>,
                                children: [
                                    {
                                        path: ':slug',
                                        element: <></>
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: `${ROUTES.editGeneralInformation.href}/:slug`,
                        element: <EditGeneralInformationPage />
                    },
                    {
                        path: `${ROUTES.editPickupService.href}/:slug`,
                        element: <EditPickupServicePage />
                    },
                    {
                        path: `${ROUTES.editServiceList.href}/:slug`,
                        element: <EditServiceListPage />
                    },
                    {
                        path: `${ROUTES.appointmentManagement.href}/:slug`,
                        element: <AppointmentDetailPage />
                    }
                ]
            },
            {
                path: '*',
                element: <HomePanelLayout />,
                children: [
                    {
                        path: '*',
                        element: <NotFoundPage />
                    }
                ]
            }
        ]
    }
]);

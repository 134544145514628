import { Link } from 'react-router-dom';
import { DoubleMessageIcon } from '../icons';
import { ACTIVITY_PATH, ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/hooks/useStore';
import { useEffect } from 'react';

export default observer(function ChatButton() {
    // hooks
    const { t } = useTranslation();
    // store
    const { authStore: { token, channel, isChannelShopOwner }, chatStore: { unreadChatsCount, countUnreadChat } } = useStore();

    // lifecycle
    useEffect(() => {
        if (token) {
            countUnreadChat({ channel });
        }
    }, [token]);

    return (
        <Link
            to={isChannelShopOwner ? ROUTES.chatForShop.href : `${ROUTES.activity.href}/${ACTIVITY_PATH.chat}`}
            className='fixed xl:bottom-20 xl:right-20 bottom-32 right-3 z-50 hidden md:flex flex-col gap-1 justify-center items-center h-fit min-h-14 min-w-14 py-2 px-1 rounded-md bg-active text-white cursor-pointer'
        >
            <div className='relative size-[1.875rem'>
                <DoubleMessageIcon className='size-[1.875rem] stroke-white fill-none' />
                {unreadChatsCount > 0 && (
                    <div className='absolute h-4 w-4 -top-1 -right-1 bg-black text-white text-[0.6875rem] rounded-full flex items-center justify-center'>
                        {unreadChatsCount > 99 ? 99 : unreadChatsCount}
                    </div>
                )}
            </div>
            <div className='text-white lg:block hidden'>{t('button.chat')}</div>
        </Link>
    );
});

import { ROUTES } from '@/configs/constants';
import { Star, CircleGauge, CalendarHeart, Newspaper } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { SideBarItem } from './SidebarMyAccount';

export default function SidebarShopManagement() {
    // hooks
    const { t } = useTranslation();

    return (
        <div className='flex flex-col w-full'>
            <div className='px-4 pb-4 pt-6 title-3'>{t('words_title.shop_management')}</div>
            <SideBarItem icon={CircleGauge} title={t('words_title.dashboard')} borderBottom={true} url={ROUTES.home.href} />
            <SideBarItem icon={CalendarHeart} title={t('words_title.appointment_management')} borderBottom={true} url={ROUTES.appointmentManagement.href} />
            <SideBarItem icon={Star} title={t('words_title.review_management')} borderBottom={true} url={ROUTES.reviewManagement.href} />
            <SideBarItem icon={Newspaper} title={t('words_title.forum')} borderBottom={false} url={ROUTES.shopForum.href} />
        </div>
    );
};

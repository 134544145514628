import { observer } from 'mobx-react-lite';
import MyShopItem from '../../components/MyShopItem';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';
import { useEffect, useMemo, useState } from 'react';
import { GetAppointmentInfoResponse } from '@/types/http-payload/shop';
import { flowResult } from 'mobx';
import { DAYS_OF_WEEK_TYPE, INFO_TYPE } from '@/types/enums';
import { cn, convertToHourMinuteFormat } from '@/utils/utils';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { DAYS_OF_WEEK, DEFAULT_NULL_VALUE, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HM_AM_PM_2, DISPLAY_MOMENT_TIME_HMS } from '@/configs/constants';
import { Schedule } from '@/types/shop';
import { formatDateTime } from '@/utils/datetime';

export default observer(function AppointmentInfoTab() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const { shopStore: { getShopInfo } } = useStore();

    // state
    const [shopInfo, setShopInfo] = useState<GetAppointmentInfoResponse>();

    // lifecycle
    useEffect(() => {
        fetchData();
    }, []);

    // function
    const fetchData = async () => {
        const res = await flowResult(getShopInfo({ typeInfo: INFO_TYPE.AppointmentInfo }));
        if (res && res.ok) {
            setShopInfo(res.data as GetAppointmentInfoResponse);
        }
    };

    return (
        <div className='flex flex-col md:gap-4 gap-1.5'>
            <div className='container-shop-info-destop'>
                <MyShopItem title={t('words_title.average_duration')} desc={<div>{shopInfo?.averageDuration ? convertToHourMinuteFormat(shopInfo.averageDuration, language) : DEFAULT_NULL_VALUE}</div>} />
                <MyShopItem
                    title={t('words_title.service_open_time')}
                    desc={(
                        <div>
                            {shopInfo?.serviceOpenTime ?
                                formatDateTime(shopInfo.serviceOpenTime?.toString(), DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                    onlyTime: true,
                                    currentformat: DISPLAY_MOMENT_TIME_HMS
                                }) :
                                DEFAULT_NULL_VALUE}
                        </div>
                    )}
                />
                <MyShopItem
                    title={t('words_title.service_close_time')}
                    desc={(
                        <div>
                            {shopInfo?.serviceCloseTime ?
                                formatDateTime(shopInfo.serviceCloseTime?.toString(), DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                    onlyTime: true,
                                    currentformat: DISPLAY_MOMENT_TIME_HMS
                                }) :
                                DEFAULT_NULL_VALUE}
                        </div>
                    )}
                />
            </div>

            {
                shopInfo?.appointmentTimesOfDays && shopInfo.appointmentTimesOfDays?.length > 0 && shopInfo.appointmentTimesOfDays.some(item => item.appointments?.length > 0) && (
                    <div className='bg-white rounded px-8 py-4'>
                        <Tabs defaultValue={String(DAYS_OF_WEEK_TYPE.Monday)} className='w-full h-full flex flex-col'>
                            <TabsList data-orientation='horizontal'>
                                {
                                    Object.values(DAYS_OF_WEEK_TYPE).map((tab, index) => (
                                        <TabsTrigger
                                            key={index}
                                            variant='scheduleList'
                                            className='md:text-[0.875rem] text-3 w-full'
                                            value={tab.toString()}
                                        >
                                            {language === 'vi' ? DAYS_OF_WEEK[tab].shortCutVi : DAYS_OF_WEEK[tab].shortCutEn}
                                        </TabsTrigger>
                                    ))
                                }
                            </TabsList>
                            {
                                shopInfo.appointmentTimesOfDays.map((item, index) => (
                                    <TabsContent key={index} value={item.day.toString()} className='w-full'>
                                        <div className='mt-4 grid grid-cols-2 gap-x-1.5 gap-y-2 w-full'>
                                            {
                                                item.appointments.map((appointment, appointmentIndex, appointments) => (
                                                    <ScheduleListItem
                                                        key={appointmentIndex}
                                                        data={appointment}
                                                        index={index}
                                                        appointmentIndex={appointmentIndex}
                                                        isDisableAll={
                                                            !(shopInfo as GetAppointmentInfoResponse).operationTimes.find(operationTime => operationTime.day === item.day)?.openStatus
                                                        }
                                                    />
                                                ))
                                            }
                                        </div>
                                    </TabsContent>
                                ))
                            }
                        </Tabs>
                    </div>
                )
            }
        </div>
    );
});

interface ScheduleListItemProps {
    data: Schedule,
    index: number,
    appointmentIndex: number,
    isDisableAll?: boolean
}

const ScheduleListItem = ({ data, index, appointmentIndex, isDisableAll = false }: ScheduleListItemProps) => {
    const [hour, minute, period] = formatDateTime(data?.startTime, DISPLAY_MOMENT_TIME_HM_AM_PM_2, {
        onlyTime: true,
        currentformat: DISPLAY_MOMENT_TIME_HMS
    })?.split(':') ?? ['', '', ''];

    // store
    const { shopStore: { shopInfo } } = useStore();

    const isDuplicate = useMemo(() => {
        if (appointmentIndex > 0) {
            return (shopInfo as GetAppointmentInfoResponse)?.appointmentTimesOfDays[index].appointments[appointmentIndex].startTime === (shopInfo as GetAppointmentInfoResponse)?.appointmentTimesOfDays[index].appointments[appointmentIndex - 1].startTime || false;
        }
    }, [data]);

    return (
        <div className={cn(
            'flex px-3 py-4 rounded-[0.75rem] border border-input',
            (!data.status || isDisableAll) && 'bg-background-3 opacity-50',
            isDuplicate && 'border-l-4 border-l-active'
        )}
        >
            <div className='flex justify-center items-center w-full'>
                <span className='text-[0.9375rem] leading-4 font-medium'>
                    <span className='text-input-1 mr-1'>{`${hour}:${minute}`}</span>
                    <span className='text-placeholder-1'>{period}</span>
                </span>
            </div>
        </div>
    );
};

import { observer } from 'mobx-react-lite';
import StoreCard from './StoreCard';
import { MultiPetPrintIcon } from '@/components/icons';
import classNames from 'classnames';
import { useStore } from '@/hooks/useStore';
import { useRef, useEffect, useCallback, useState } from 'react';
import SheetFilter from './SheetFilter';
import SortShop from './SortShop';
import { useSearchParams } from 'react-router-dom';
import { convertParamsToApiParams } from '@/utils/utils';
import { TableState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { flowResult } from 'mobx';
import { ShopCardInfo } from '@/types/shop';
import LazyLoad from 'react-lazy-load';
import { SORT_OPTION } from '@/configs/constants';
import { Button } from '@/components/common/Button';
import CommingSoonImg from '@/assets/images/commingSoon.png';

export default observer(function AllShop() {
    // hook
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    // state
    const allShopRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // store
    const {
        uiStore: { isLogoVisible, setObservable, countFilter, currentLocation, devicesScreen: { mobile } },
        shopStore: { allShop, getAllShop, setAllShop, paging, setObservable: setVariableObservable, cleanAllShop }
    } = useStore();

    // lifecycle
    useEffect(() => {
        if (mobile) {
            handleHiddenLogo();
            const handleScroll = () => {
                handleHiddenLogo();
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else {
            setObservable('isLogoVisible', true, { isMergeObject: false });
        }
    }, [mobile]);

    useEffect(() => {
        searchParams && getDataAllShop(searchParams, true);
    }, [searchParams, currentLocation]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setObservable('currentLocation', {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }, { isMergeObject: true });
            });
        }

        return () => {
            cleanAllShop();
        };
    }, []);

    // function
    const getDataAllShop = async (searchParams: URLSearchParams, reAssigedValue: boolean = false) => {
        let params = convertParamsToApiParams(searchParams);
        let pagingReq: TableState = { ...paging };
        pagingReq.pagination.pageSize = 18;

        if (currentLocation) {
            params.location = currentLocation.latitude + ',' + currentLocation.longitude;
            pagingReq = {
                ...pagingReq,
                sorting: [
                    {
                        id: SORT_OPTION.Nearby.value,
                        desc: true
                    }
                ]
            };
        } else {
            pagingReq = {
                ...pagingReq,
                sorting: [
                    {
                        id: SORT_OPTION.StarRate.value,
                        desc: true
                    }
                ]
            };
        }
        // pagingReq.pagination.pageSize = 1;
        if (searchParams.get('sort')) {
            pagingReq = {
                ...pagingReq,
                sorting: [
                    {
                        id: searchParams.get('sort') || '',
                        desc: true
                    }
                ]
            };
        }
        params.isGetNearBy = false;
        let currentTotal = 0;
        if (!reAssigedValue) {
            params.shopIds = allShop.map(item => item.id).join(',');
            currentTotal = allShop.length;
        }
        const res = await flowResult(getAllShop(params, pagingReq));
        if (res) {
            let shopData: ShopCardInfo[] = [];
            let responseDataElement = res.elements || [];
            let totalDataRecord = res.pagination.totalRecord;
            if (reAssigedValue) {
                shopData = responseDataElement;
            } else {
                shopData = allShop.length > 0 ? [...allShop, ...responseDataElement] : responseDataElement;
            }

            setIsLoading(shopData.length < (totalDataRecord + currentTotal));
            setAllShop(shopData);
        }
    };

    const getMoreData = () => {
        getDataAllShop(searchParams);
    };
    const handleHiddenLogo = useCallback(() => {
        if (allShopRef.current) {
            const rect = allShopRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const offset = Math.floor(windowHeight / 2);
            const afterDownValue = Math.floor(rect.top);
            setObservable('isLogoVisible', (afterDownValue > offset), { isMergeObject: false });
        }
    }, []);

    return (
        <>
            <div className={classNames('bg-white md:flex md:h-11 h-12 md:pt-0 py-2 md:justify-between md:items-center md:static md:w-full md:px-0 px-4')}>
                <div
                    className='title relative h-10 w-fit py-1 '
                >
                    <h3 className='text-xl font-semibold'>{t('words_title.all_shop')}</h3>
                    <div className='absolute -top-[0.125rem] -right-4'>
                        <MultiPetPrintIcon className='w-6 h-6 fill-black' />
                    </div>
                </div>
                <div
                    className={classNames(
                        'md:h-8 h-12 bg-white flex md:justify-end justify-start gap-2 md:gap-0',
                        !isLogoVisible ? 'fixed container w-screen top-[3.5rem] left-0 px-4 z-50 flex items-center' : 'md:flex hidden'
                    )}
                >
                    <SheetFilter />
                    <div className='w-[1px] h-full bg-border-6 mx-1 md:block hidden'></div>
                    <SortShop />

                </div>
            </div>
            <div className='md:mt-2 overflow-hidden' ref={allShopRef}>
                {allShop.length ?
                    (
                        <div>
                            <div className='grid grid-flow-row 2xl:grid-cols-3 point-920:grid-cols-2 grid-cols-1 2xl:gap-2 md:gap-4 gap-1'>
                                {allShop.map((store, index) => {
                                    return (
                                        <LazyLoad key={index}>
                                            <StoreCard storeCard={store} />
                                        </LazyLoad>
                                    );
                                })}
                            </div>
                            {isLoading && (
                                <div className='md:mt-4 mt-1 bg-white py-3'>
                                    <Button variant='outline' className='mx-auto md:mt-3 md:w-fit h-10 px-3 flex items-center' onClick={() => getMoreData()}>
                                        {t('button.view_more_upper')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) :
                    (
                        <div className='w-full p-6 h-60 flex justify-center items-center bg-white'>
                            {
                                countFilter === 0 ?
                                    <img src={CommingSoonImg} alt='' className='w-[200px] h-auto object-contain' /> :
                                    <>{t('sentences.no_shop_found')}</>
                            }
                        </div>
                    )}
            </div>
        </>

    );
});

import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { BellIcon } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface NotificationIconProps {
    withText?: boolean
}

export default observer(function NotificationIcon({ withText = false }: NotificationIconProps) {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        notificationStore: { totalNotReadNotification }
    } = useStore();

    // render
    if (!withText) {
        return (
            <Link to={ROUTES.notification.href}>
                <div className='relative'>
                    <BellIcon className='h-6 w-6 text-active' />
                    {
                        totalNotReadNotification > 0 && (
                            <div className='absolute -top-[5px] -right-1 rounded-full flex justify-center items-center size-[15px] bg-text-21 text-white text-[9px]'>
                                {
                                    totalNotReadNotification > 99 ? 99 : totalNotReadNotification
                                }
                            </div>
                        )
                    }
                </div>
            </Link>
        );
    }

    return (
        <Link to={ROUTES.notification.href} className='notification flex items-center gap-1 pr-1 cursor-pointer'>
            <div className='relative'>
                <BellIcon className='text-nav-1' />
                {
                    totalNotReadNotification > 0 && (
                        <div className='absolute -top-[5px] -right-1 rounded-full flex justify-center items-center size-[15px] bg-text-21 text-white text-[9px]'>
                            {
                                totalNotReadNotification > 99 ? 99 : totalNotReadNotification
                            }
                        </div>
                    )
                }
            </div>
            <span className='text-sm text-nav-1'>{t('words_title.notification')}</span>
        </Link>
    );
});

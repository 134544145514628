import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import AppointmentDetail from './appointment-detail';
import AppointmentList from './appointment-list';
import { ACTIVITY_PATH } from '@/configs/constants';

export default observer(function MyAppointments() {
    // hooks
    const { slug, tabPath } = useParams();

    // store

    // state

    // lifecycle

    return (
        <>
            {
                (tabPath === ACTIVITY_PATH.appointment || !tabPath) && (
                    slug ? <AppointmentDetail /> : <AppointmentList />
                )
            }
        </>
    );
});

import { Outlet } from 'react-router-dom';
import { useStore } from '@/hooks/useStore';
import { ROUTES } from '@/configs/constants';
import { ReactNode } from 'react';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import ContainerBody from '@/components/layout/ContainerBody';
import Header from '@/components/layout/Header';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

interface CreateShopPanelLayoutProps {
    children?: ReactNode
}

export default observer(function CreateShopPanelLayout({ children }: CreateShopPanelLayoutProps) {
    // hooks
    const { t } = useTranslation();

    // store
    const { uiStore: { devicesScreen: { desktop } } } = useStore();

    return (
        <>
            <ContainerBody
                showHeader={desktop}
                showFooter={desktop}
            >
                <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.create_shop')} defaultNavigateUrl={ROUTES.home.href} />} />
                {
                    children ?? <Outlet />
                }
            </ContainerBody>
        </>
    );
});

import { observer } from 'mobx-react-lite';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '../common/DropdownMenu';
import { AlignJustifyIcon, CalendarHeartIcon, ChevronRightIcon, CircleGaugeIcon, LogOutIcon, NotebookTextIcon, PawPrintIcon, StarIcon } from 'lucide-react';
import { useStore } from '@/hooks/useStore';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import userAvatar from '@/assets/images/userAvatar.svg';

export default observer(function MenuDropdownShopMobile() {
    // hooks
    const { t } = useTranslation();

    // store
    const { authStore: { profile, signOut, onSwitchToPetChannel } } = useStore();

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <AlignJustifyIcon className='h-6 w-6 text-active hover:cursor-pointer' />
            </DropdownMenuTrigger>

            <DropdownMenuContent className='w-56' align='start' sideOffset={14} forceMount>
                <DropdownMenuItem className='font-normal' asChild>
                    <Link to={ROUTES.myAccount.href} className='flex gap-2 items-center justify-between'>
                        <div className='flex items-center gap-2'>
                            <img className='w-4.5 h-4.5' src={userAvatar} alt='logo-img' />
                            <p className='text-sm font-medium leading-none'>
                                {profile?.fullName}
                            </p>
                        </div>
                        <ChevronRightIcon className='text-active' />
                    </Link>
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                    <Link to={ROUTES.home.href} className='flex items-center hover:underline text-text-4'>
                        <CircleGaugeIcon className='w-4 h-4 mr-3 text-text-4' />
                        {t('menu_dropdown.dashboard')}
                    </Link>
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                    <Link to={ROUTES.appointmentManagement.href} className='flex items-center hover:underline text-text-4'>
                        <CalendarHeartIcon className='w-4 h-4 mr-3 text-text-4' />
                        {t('menu_dropdown.appointment_manage')}
                    </Link>
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                    <Link to={ROUTES.reviewManagement.href} className='flex items-center hover:underline text-text-4'>
                        <StarIcon className='w-4 h-4 mr-3 text-text-4' />
                        {t('menu_dropdown.review_manage')}
                    </Link>
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                <DropdownMenuItem className='hover:cursor-pointer' asChild>
                    <Link to={ROUTES.forum.href} className='flex items-center hover:underline text-text-4'>
                        <NotebookTextIcon className='w-4 h-4 mr-3 text-text-4' />
                        {t('menu_dropdown.forum')}
                    </Link>
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                <DropdownMenuItem className='hover:cursor-pointer text-active hover:underline border border-active border-dashed' onClick={onSwitchToPetChannel}>
                    <PawPrintIcon className='w-4 h-4 mr-3 text-active stroke-[2px]' />
                    {t('menu_dropdown.switch_pet_owner')}
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                <DropdownMenuItem
                    className='hover:cursor-pointer hover:underline text-text-4'
                    onClick={() => signOut()}
                >
                    <LogOutIcon className='w-4 h-4 mr-3 text-text-4' />
                    {t('words_title.sign_out')}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
});

import { observer } from 'mobx-react-lite';
import { ChatList, MessageDetail } from './components';
import { useLocation, useParams } from 'react-router-dom';
import { ROUTES, ACTIVITY_PATH } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { io } from 'socket.io-client';
import { URL_CONFIG } from '@/configs/environment';
import { useEffect, useRef, useState } from 'react';
import { ENDUSER_TYPE, LOAD_MESSAGE_TYPE, SOCKET_EVENT_TYPE } from '@/types/enums';
import { flowResult } from 'mobx';
import { GetMoreMessageRequest, SeenMessageRequest } from '@/types/http-payload/chat';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChatInfor, ChatMessage } from '@/types/chat';
import NoMessageBg from '@/assets/images/messageBg.png';

export default observer(function Chat() {
    // hooks
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { tabPath, slug } = useParams();

    // store
    const { authStore: { token, channel, isChannelShopOwner }, uiStore: { devicesScreen: { mobile, desktop } },
        chatStore: { chatDetail, chatMessages, getChatMessages, getChatList, getMoreMessages, seenMessages, newMessageByYou, setObservable } } = useStore();

    // state
    const chatDetailRef = useRef(chatDetail);
    const [isSocketConnected, setIsSocketConnected] = useState(false);

    // lifecycle
    useEffect(() => {
        if (token) {
            const socketConnectionInit = io(`${URL_CONFIG.SOCKET.url}/${URL_CONFIG.SOCKET.chatNamespace}`, {
                reconnection: true,
                reconnectionAttempts: 5,
                reconnectionDelay: 10000,
                reconnectionDelayMax: 30000,
                timeout: 60000
            });

            socketConnectionInit.on('connect', () => {
                setIsSocketConnected(true);
            });

            socketConnectionInit.emit(SOCKET_EVENT_TYPE.Connected, { token, channel });

            socketConnectionInit.on(SOCKET_EVENT_TYPE.NewMessage, (data) => {
                handleRealoadChat(data);
            });

            socketConnectionInit.on(SOCKET_EVENT_TYPE.SeenMessage, (data) => {
                getChatList({ channel });
            });

            return () => {
                socketConnectionInit.disconnect();
            };
        }
    }, [token]);

    useEffect(() => {
        chatDetailRef.current = chatDetail;
    }, [chatDetail]);

    useEffect(() => {
        if (isSocketConnected && ((isChannelShopOwner && slug) || (tabPath === ACTIVITY_PATH.chat && slug)) && Object.keys(chatDetail).length > 0 && chatMessages && chatMessages.length > 0) {
            modifySeenMessage(slug, chatDetail, chatMessages);
        }
    }, [isSocketConnected, slug, chatDetail, chatMessages]);

    // function
    const getAllMessage = async () => {
        const res = await flowResult(getChatMessages({ code: slug, channel }, true));
        if (res) {
            const { messages, ...otherData } = res;
            setObservable('chatMessages', messages, { isMergeObject: false });
            setObservable('chatDetail', { ...otherData, messageLength: messages.length }, { isMergeObject: false });
        }
    };

    const handleRealoadChat = (data) => {
        getChatList({ channel });
        let reloadChatMessages: boolean = false;

        if (channel === ENDUSER_TYPE.PetOwner) {
            reloadChatMessages = chatDetailRef.current?.shop?.id === data.participantId;
        } else if (channel === ENDUSER_TYPE.ShopOwner) {
            reloadChatMessages = chatDetailRef.current?.user?.id === data.participantId;
        }
        if (reloadChatMessages) {
            if (chatDetailRef.current.messageLength && chatDetailRef.current.messageLength > 0) {
                let payload: GetMoreMessageRequest = {
                    isLast: LOAD_MESSAGE_TYPE.End,
                    chatCode: data.chatCode,
                    channel
                };
                getMoreMessages(payload);
            } else {
                getAllMessage();
            }
            if (data.isSendByYou === false) {
                setObservable('newMessageByYou', { byYou: false }, { isMergeObject: false });
                let payload: SeenMessageRequest = {
                    channel,
                    chatCode: data.chatCode
                };
                if (channel === ENDUSER_TYPE.PetOwner) {
                    payload.shopId = chatDetailRef.current?.shop?.id;
                } else if (channel === ENDUSER_TYPE.ShopOwner) {
                    payload.userId = chatDetailRef.current?.user?.id;
                }

                seenMessages(payload);
            } else {
                setObservable('newMessageByYou', { byYou: true }, { isMergeObject: false });
            }
        }
    };

    const modifySeenMessage = (slug: string, chatDetail: ChatInfor, chatMessages: ChatMessage[]) => {
        if (chatMessages.length > 0) {
            let lastMessage = chatMessages[chatMessages.length - 1];
            if (lastMessage.isSendByYou === false && lastMessage.isRead === false) {
                let payload: SeenMessageRequest = {
                    channel,
                    chatCode: chatDetail.chatCode || ''
                };
                if (channel === ENDUSER_TYPE.PetOwner && slug === chatDetail?.shop?.slug) {
                    payload.shopId = chatDetail?.shop?.id;
                    seenMessages(payload);
                } else if (channel === ENDUSER_TYPE.ShopOwner && slug === chatDetail.user?.code) {
                    payload.userId = chatDetail?.user?.id;
                    seenMessages(payload);
                }
            }
        }
    };

    const basePath = [`${ROUTES.activity.href}/${ACTIVITY_PATH.chat}`, ROUTES.chatForShop.href].includes(pathname);

    return (
        <>
            {
                isChannelShopOwner && <h3 className={classNames('title-1 pt-4 my-auto text-center text-active md:text-2xl md:block hidden')}>{t('words_title.chat')}</h3>
            }

            <div className={classNames(
                'grid lg:grid-cols-[23.125rem,1fr] md:grid-cols-[20.625rem,1fr] grid-cols-1 h-full overflow-x-hidden',
                isChannelShopOwner && 'md:pt-2 md:h-[calc(100vh-13.75rem)] h-[calc(100vh-3.5rem)] overflow-hidden'
            )}
            >

                {(desktop || (mobile && basePath)) && (
                    <section className='bg-white'>
                        <ChatList />
                    </section>
                )}

                {/** message component**/}
                <section className='h-full overflow-hidden bg-white'>
                    {!basePath ?
                        (
                            <MessageDetail />
                        ) :
                        (
                            <div className='hidden size-full md:flex flex-col items-center mt-[20%] gap-2'>
                                <img src={NoMessageBg} alt='no message background' />
                                <p className='text-center text-text-26'>{t('sentences.note_select_conversation')}</p>
                            </div>
                        )}
                </section>

            </div>
        </>

    );
});

import { Button } from '@/components/common/Button';
import { Carousel, CarouselContent, CarouselItem } from '@/components/common/Carousel';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import GeneralInfoTab from '../tabs/view/GeneralInfoTab';
import OperationInfoTab from '../tabs/view/OperationInfoTab';
import ShopInfoTab from '../tabs/view/ShopInfoTab';
import AppointmentInfoTab from '../tabs/view/AppointmentInfoTab';
import { useEffect, useMemo, useState } from 'react';
import GeneralInfoTabEdit from '../tabs/edit/GeneralInfoTabEdit';
import OperationInfoTabEdit from '../tabs/edit/OperationInfoTabEdit';
import ShopInfoTabEdit from '../tabs/edit/ShopInfoTabEdit';
import AppointmentInfoTabEdit from '../tabs/edit/AppointmentInfoTabEdit';
import { SHOP_STATUS_TYPE } from '@/types/enums';
import { scrollToTop } from '@/utils/utils';
import { useLocation } from 'react-router-dom';

export default observer(function ShopInfomationPage() {
    // hooks
    const { t } = useTranslation();
    const location = useLocation();

    // state
    const [activeTab, setActiveTab] = useState(location?.state?.activeTab ? location.state.activeTab : 'create_shop_tab_general');

    // store
    const {
        uiStore: { devicesScreen: { mobile, desktop } },
        shopStore: { shopInfo, isEditMode, setObservable }
    } = useStore();

    const handleSubmitSuccess = () => {
        setActiveTab('create_shop_tab_info');
        scrollToTop();
    };

    const SHOP_INFO_TABS = [
        {
            key: 'create_shop_tab_general',
            tab: <GeneralInfoTab />,
            editTab: <GeneralInfoTabEdit handleSubmitSuccess={handleSubmitSuccess} />
        },
        {
            key: 'create_shop_tab_info',
            tab: <ShopInfoTab />,
            editTab: <ShopInfoTabEdit />
        },
        {
            key: 'create_shop_tab_operation',
            tab: <OperationInfoTab />,
            editTab: <OperationInfoTabEdit />
        },
        {
            key: 'create_shop_tab_appointment',
            tab: <AppointmentInfoTab />,
            editTab: <AppointmentInfoTabEdit />
        }
    ];

    const TabList = useMemo(() => {
        if (shopInfo?.status === SHOP_STATUS_TYPE.Active) {
            return SHOP_INFO_TABS;
        } else {
            return SHOP_INFO_TABS.filter(item => item.key !== 'create_shop_tab_operation' && item.key !== 'create_shop_tab_appointment');
        }
    }, [shopInfo]);

    // lifecycle
    useEffect(() => {
        if (shopInfo?.status === SHOP_STATUS_TYPE.WaitApprove) {
            setObservable('isEditMode', false);
        } else if (shopInfo?.status === SHOP_STATUS_TYPE.DeniedApprove || shopInfo?.status === SHOP_STATUS_TYPE.NotCompletedInfo) {
            setObservable('isEditMode', true);
        }
    }, [shopInfo]);

    useEffect(() => {
        if ((activeTab === SHOP_INFO_TABS[2].key || activeTab === SHOP_INFO_TABS[3].key) && shopInfo?.status !== SHOP_STATUS_TYPE.Active) {
            setActiveTab(SHOP_INFO_TABS[0].key);
        }
    }, [isEditMode, shopInfo]);

    // function
    const handleTabChange = (tabValue: string) => {
        setActiveTab(tabValue);
    };

    const handleClickEditShop = () => {
        setObservable('isEditMode', true);
    };

    return (
        <div className='h-full'>
            <div className='md:pt-6 pt-0 bg-white flex flex-col h-full rounded'>
                <div className='md:flex justify-between items-center hidden px-4'>
                    <div className='title-3'>{t('words_title.shop_info')}</div>
                    {
                        !isEditMode && shopInfo?.status !== SHOP_STATUS_TYPE.WaitApprove && (
                            <Button
                                onClick={handleClickEditShop}
                                className='px-2 py-2.5'
                                variant='outline'
                            >
                                {isEditMode ? t('button.view_shop_info') : t('button.edit_shop_info')}
                            </Button>
                        )
                    }
                </div>
                <div className='md:mt-4 mt-0 flex-1'>
                    <Tabs value={activeTab} onValueChange={handleTabChange} className='w-full h-full flex flex-col'>
                        {desktop && (
                            <TabsList variant='createShop' data-orientation='horizontal' className='flex w-full overflow-x-auto px-4'>
                                {TabList.map((tab, index) =>
                                    (
                                        <TabsTrigger
                                            key={index}
                                            variant='createShop'
                                            className='w-full'
                                            value={tab.key}
                                            disabled={tab.key === 'create_shop_tab_info' && shopInfo && !shopInfo.generalInfo}
                                        >
                                            {t(`words_title.${tab.key}`)}
                                        </TabsTrigger>
                                    ))}
                            </TabsList>
                        )}
                        {mobile && (
                            <TabsList variant='createShop' data-orientation='horizontal' className='w-full px-4'>
                                <Carousel

                                    opts={{
                                        align: 'start'
                                    }}
                                    className='w-full'
                                >
                                    <CarouselContent className='flex snap-x snap-mandatory'>
                                        {TabList.map((tab, index) =>
                                            (
                                                <CarouselItem key={index} className='pl-0 basis-1/2 snap-center flex-shrink-0'>
                                                    <TabsTrigger
                                                        key={index}
                                                        variant='createShop'
                                                        className='w-full'
                                                        value={tab.key}
                                                        disabled={tab.key === 'create_shop_tab_info' && shopInfo && !shopInfo.generalInfo}
                                                    >
                                                        {t(`words_title.${tab.key}`)}
                                                    </TabsTrigger>
                                                </CarouselItem>
                                            ))}
                                    </CarouselContent>
                                </Carousel>
                            </TabsList>
                        )}
                        <div className='bg-background-2 flex-grow'>
                            {
                                TabList.map((item, index) => (
                                    <TabsContent key={index} value={item.key}>
                                        {
                                            isEditMode ? (item.editTab) : (item.tab)
                                        }
                                    </TabsContent>
                                ))
                            }
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    );
});

import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { PASSWORD_VALIDATE_REGEX, ROUTES } from '@/configs/constants';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from '@/components/common/Label';
import { Button } from '@/components/common/Button';
import { flowResult } from 'mobx';
import { useStore } from '@/hooks/useStore';
import { PasswordInput } from '@/components/common/Input';
import { toastify } from '@/utils/toastify';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { useNavigate } from 'react-router-dom';

export default observer(function MyAccountChangePassword() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        authStore: { changePassword }
    } = useStore();

    // validate Schema
    const validateSchema = yup.object().shape({
        currentPassword: yup
            .string()
            .required('form_error_validate.required'),
        newPassword: yup
            .string()
            .required('form_error_validate.required')
            .matches(PASSWORD_VALIDATE_REGEX, 'form_error_validate.format_password'),
        confirmNewPassword: yup
            .string()
            .required('form_error_validate.required')
            .oneOf([yup.ref('newPassword')], 'form_error_validate.confirm_new_password_not_match')
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        }
    });

    // function
    const onSubmit = async (data: FormData) => {
        const { currentPassword, newPassword } = data;
        const res = await flowResult(changePassword({ currentPassword, password: newPassword }));
        if (res) {
            form.reset();
            navigate(ROUTES.myAccount.href);
            toastify('alert-success', t('messages.reset_password_success'));
        } else {
            form.setError('currentPassword', {
                type: 'manual',
                message: t('form_error_validate.current_password_not_match')
            });
        }
    };

    return (
        <>
            <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('button.change_password')} defaultNavigateUrl={ROUTES.myAccount.href} />} />
            <div className='pb-[1.5rem] bg-white'>
                <div className='px-4 py-6 title-3 hidden md:block md:px-4 md:py-6'>{t('words_title.change_password')}</div>
                <div className='px-4 pt-4 md:pt-0'>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className='w-full'>
                            <div className='mb-[1rem]'>
                                <Label className='label-1' required>{t('words_title.current_password')}</Label>
                                <FormField
                                    control={form.control}
                                    name='currentPassword'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <PasswordInput
                                                    isPreventCopy={true}
                                                    isPreventCut={true}
                                                    type={!showPassword ? 'password' : 'text'}
                                                    className='mt-[0.375rem]'
                                                    onToggleIcon={() => setShowPassword(!showPassword)}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='mb-[1rem]'>
                                <Label className='label-1' required>{t('words_title.new_password')}</Label>
                                <FormField
                                    control={form.control}
                                    name='newPassword'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <PasswordInput
                                                    isPreventCopy={true}
                                                    isPreventCut={true}
                                                    type={!showNewPassword ? 'password' : 'text'}
                                                    className='mt-[0.375rem]'
                                                    onToggleIcon={() => setShowNewPassword(!showNewPassword)}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        form.getValues('confirmNewPassword') && form.trigger('confirmNewPassword');
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage className='whitespace-pre-line' />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='mb-[1rem]'>
                                <Label className='label-1' required>{t('words_title.confirm_new_password')}</Label>
                                <FormField
                                    control={form.control}
                                    name='confirmNewPassword'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <PasswordInput
                                                    isPreventCopy={true}
                                                    isPreventCut={true}
                                                    type={!showConfirmPassword ? 'password' : 'text'}
                                                    className='mt-[0.375rem]'
                                                    onToggleIcon={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        form.getValues('newPassword') && form.trigger('newPassword');
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='flex justify-center'>
                                <Button type='submit' variant='submit' className='mt-6 min-w-60'>
                                    {t('button.save')}
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
        </>
    );
});

import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { PHONE_VALIDATE_REGEX, ROUTES } from '@/configs/constants';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button } from '@/components/common/Button';
import { useNavigate } from 'react-router-dom';
import VerifyOtpPhoneNumber from './components/VerifyOtpPhoneNumber';
import { flowResult } from 'mobx';
import { useStore } from '@/hooks/useStore';
import { Label } from '@/components/common/Label';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { PhoneIcon } from 'lucide-react';

export default observer(function MyAccountEditPhonePage() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const { authStore: { changePhoneNumber } } = useStore();

    // validate Schema
    const validateSchema = yup.object().shape({
        phoneNumber: yup
            .string()
            .required('form_error_validate.required')
            .matches(PHONE_VALIDATE_REGEX, 'form_error_validate.format_phone')
    });
    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const [showVerifyOtp, setShowVerifyOtp] = useState<boolean>(false);
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        defaultValues: {
            phoneNumber: ''
        }
    });

    // function
    const onSubmit = async (data: FormData) => {
        const res = await flowResult(changePhoneNumber(data));
        if (res) {
            setShowVerifyOtp(true);
        }
    };

    return (
        <div>
            <Header
                onlyShowMobile
                headerMobileComponent={(
                    <HeaderMobileFormat3
                        title={t('words_title.edit_phone_number')}
                        handleOnClickBySelf={() => {
                            if (showVerifyOtp) {
                                setShowVerifyOtp(false);
                            } else {
                                navigate(`${ROUTES.myAccount.href}#account-infomation`);
                            }
                        }}
                    />
                )}
            />
            <div className='pb-[1.5rem] bg-white'>
                <div className='px-4 py-6 title-3 hidden md:block md:px-4 md:py-6'>{t('words_title.edit_phone_number')}</div>
                {
                    !showVerifyOtp ?
                        (
                            <>
                                <div className='px-4 pt-4 md:pt-0'>
                                    <Form {...form}>
                                        <form onSubmit={form.handleSubmit(onSubmit)} className='w-full mb-[0.435rem]'>
                                            <div>
                                                <Label className='text-text-7'>
                                                    {t('words_title.phone_number')}
                                                </Label>
                                                <FormField
                                                    control={form.control}
                                                    name='phoneNumber'
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <div className='relative'>
                                                                <PhoneIcon className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 z-[2]' />
                                                                <FormControl>
                                                                    <Input
                                                                        className='pl-10 mt-[0.375rem]'
                                                                        {...field}
                                                                        isTypingOnlyNumber={true}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                            <span className='text-text-2 text-small'>{t('sentences.verify_otp_signup_title_2')}</span>
                                            <div className='gap-[1rem] mt-[1rem] lg:mt-[1.5rem] md:flex'>
                                                <Button type='button' className='w-full border-border-9 mb-[0.375rem] hidden md:block lg:w-1/2' onClick={() => navigate(ROUTES.myAccount.href)} variant='outline'>{t('button.cancel')}</Button>
                                                <Button disabled={!form.formState.isDirty} type='submit' className='w-full block lg:w-1/2'>{t('button.get_verification_code_2')}</Button>
                                            </div>
                                        </form>
                                    </Form>
                                </div>
                            </>
                        ) :
                        (
                            <VerifyOtpPhoneNumber setShowVerifyOtp={setShowVerifyOtp} phoneNumberChange={form.getValues('phoneNumber')} />
                        )
                }
            </div>
        </div>
    );
});

import { observer } from 'mobx-react-lite';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/components/common/Select';
import { SquarePen } from 'lucide-react';
import NotificationItem from './components/NotificationItem';
import Paginate from '@/components/pagination/Paginate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { MarkReadNotificationRequest } from '@/types/http-payload/notification';
import { flowResult } from 'mobx';
import { scrollToTop } from '@/utils/utils';

export default observer(function NotificationList() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        authStore: { getProfile },
        notificationStore: { getAllNotification, notifications,
            paging, totalPage, totalNotReadNotification, markReadNotification, clean,
            deleteNotification }
    } = useStore();

    // state
    type filterByReadType = 'all' | 'false' | 'true';
    const [filterByRead, setFilterByRead] = useState<filterByReadType>('all');

    // lifecycle
    useEffect(() => {
        getProfile();
        getAllNotification({});

        return () => {
            clean();
        };
    }, []);

    // function
    const onMarkReadNotification = async (data: MarkReadNotificationRequest) => {
        try {
            const result = await flowResult(markReadNotification(data));

            if (result) {
                getProfile();
                getAllNotification({
                    readStatus: filterByRead === 'all' ? '' : filterByRead
                });
            }
        } catch (error) {
        }
    };

    const onDeleteNotification = async (code: string) => {
        try {
            const result = await flowResult(deleteNotification({ code }));

            if (result) {
                getProfile();
                getAllNotification({
                    readStatus: filterByRead === 'all' ? '' : filterByRead
                });
            }
        } catch (error) {
        }
    };

    return (
        <>
            <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.notification')} defaultNavigateUrl={ROUTES.home.href} />} />
            <div className='md:pt-8'>
                <div className='bg-white'>
                    <div className='mx-4 py-4 flex flex-col point-375:flex-row gap-4 point-375:gap-1 items-end point-375:items-center justify-between border-b'>
                        <div className='w-full point-375:w-32'>
                            <Select
                                value={filterByRead}
                                onValueChange={(value: filterByReadType) => {
                                    setFilterByRead(value);
                                    paging.pagination.pageIndex = 0;
                                    getAllNotification({
                                        readStatus: value === 'all' ? '' : value
                                    }, paging);
                                }}
                            >
                                <SelectTrigger className='h-7.5'>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup className=''>
                                        <SelectItem value='all'>{t('select_options.notification_all')}</SelectItem>
                                        <SelectItem value='false'>{t('select_options.notification_unread')}</SelectItem>
                                        <SelectItem value='true'>{t('select_options.notification_read')}</SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        {
                            totalNotReadNotification > 0 && (
                                <div
                                    className='text-active flex items-center gap-1 cursor-pointer flex-none'
                                    onClick={() => onMarkReadNotification({
                                        isReadAll: true
                                    })}
                                >
                                    <div>
                                        <SquarePen className='text-active size-[0.875rem]' />
                                    </div>
                                    <p>
                                        {t('button.mark_all_as_read')}
                                        {' '}
                                        (
                                        {totalNotReadNotification}
                                        )
                                    </p>
                                </div>
                            )
                        }
                    </div>
                    {
                        notifications.length > 0 ?
                            (
                                <div>
                                    {notifications.map((notification, index) => (
                                        <NotificationItem
                                            key={index}
                                            {...notification}
                                            onMarkReadNotification={onMarkReadNotification}
                                            onDeleteNotification={onDeleteNotification}
                                        />
                                    ))}
                                </div>
                            ) :
                            (
                                <div className='py-4 text-center text-tab-1'>
                                    {t('sentences.no_notification')}
                                </div>
                            )
                    }

                    {
                        totalPage > 0 && (
                            <div className='py-4 flex justify-center items-center'>
                                <Paginate
                                    forcePage={paging.pagination.pageIndex}
                                    pageCount={totalPage}
                                    onPageChange={({ selected }) => {
                                        paging.pagination.pageIndex = selected;
                                        getAllNotification({}, paging);
                                        scrollToTop();
                                    }}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
});

import { Checkbox } from '@/components/common/Checkbox';
import { DISPLAY_MOMENT_DATE_DMYHM_AM_PM, MAX_LENGTH_TEXT_MOBILE, SHOP_REVIEW_STATUS } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { ReviewForReviewManagement } from '@/types/review';
import { formatDateTime } from '@/utils/datetime';
import { getLastNameInitial, scrollToTop, shortenParagraph } from '@/utils/utils';
import { OctagonAlert, Star } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportForm from './ReportForm';

type Props = {
    review: ReviewForReviewManagement,
    isChecked?: boolean
};

export default observer(function ReviewItem({ review, isChecked }: Props) {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        reviewStore: { markTopReview, getAllReviewForReviewManagement, params, arrayIdReport, setObservable },
        modalStore: { showModal }
    } = useStore();

    // state
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    // functions
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleMarkTop = async (id: number) => {
        const res = await flowResult(markTopReview(id));
        if (res) {
            scrollToTop();
            getAllReviewForReviewManagement(params);
        }
    };

    const handleReport = (arrayId: Array<number>) => {
        showModal({
            id: 'report-review',
            title: t('words_title.report_comment'),
            titleClassName: 'title-3 text-center',
            content: <ReportForm arrayId={arrayId} />,
            footerClassName: 'p-0'
        });
    };

    return (
        <div className='px-4 py-3 md:px-3 lg:px-4 border-t md:border md:rounded-sm flex space-x-2'>
            <div className='pt-3 hidden md:block'>
                <Checkbox
                    checked={isChecked}
                    disabled={review.status === SHOP_REVIEW_STATUS.REQUEST_APPROVAL}
                    onCheckedChange={(checked) => {
                        if (checked) {
                            setObservable('arrayIdReport', [...arrayIdReport, review?.id], { isMergeObject: false });
                        } else {
                            setObservable('arrayIdReport', arrayIdReport.filter(item => item !== review?.id), { isMergeObject: false });
                        }
                    }}
                />
            </div>
            <div className='flex-1 overflow-hidden'>
                <div className='flex space-x-2 justify-between'>
                    <div className='flex items-center justify-center min-w-9.5 min-h-9.5 size-9.5 rounded-full bg-icon-13'>
                        <span className='text-icon-14 text-lg'>{getLastNameInitial(review?.user?.fullName)}</span>
                    </div>
                    <div className='flex-1 overflow-hidden'>
                        <div className='w-full break-words line-clamp-2'>
                            <p className='text-text-3 font-medium break-words'>{review?.user?.fullName}</p>
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='text-text-4 text-small'>
                                {formatDateTime(review?.createdAt, DISPLAY_MOMENT_DATE_DMYHM_AM_PM)}
                            </p>
                            <div className='flex'>
                                <div className='text-icon-3 flex space-x-0.5'>
                                    {
                                        Array.from({ length: 5 }, (_, i) => {
                                            const ratingValue = Number(review?.serviceQuality);
                                            if (i < Math.floor(ratingValue)) {
                                                return <Star key={i} className='fill-current text-icon-3 size-4' />;
                                            } else if (i === Math.floor(ratingValue) && ratingValue % 1 !== 0) {
                                                return (
                                                    <div key={i} className='relative inline-block size-4'>
                                                        <Star className='fill-current text-gray-300 size-4' />
                                                        <div
                                                            className='absolute top-0 left-0 h-full overflow-hidden'
                                                            style={{
                                                                width: `${(ratingValue % 1) * 100}%`
                                                            }}
                                                        >
                                                            <Star className='fill-current text-icon-3 size-4' />
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return <Star key={i} className='fill-current text-gray-300 size-4' />;
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='text-text-3 mt-1 w-full whitespace-pre-line'>
                    {shortenParagraph(review?.comment, MAX_LENGTH_TEXT_MOBILE, isExpanded).value}
                    {
                        shortenParagraph(review?.comment, MAX_LENGTH_TEXT_MOBILE, isExpanded).isEllipsis && (
                            <button
                                onClick={toggleExpand}
                                className='text-active text-[0.8125rem] text-blue-500 no-underline'
                            >
                                {isExpanded ? t('button.view_less') : t('button.view_more')}
                            </button>
                        )
                    }
                </p>
                <div className='flex justify-start items-center sm:justify-normal gap-2 mt-2 overflow-x-auto scrollbar-height-2 scrollbar-width-4'>
                    {
                        review.images.map((image, i) => (
                            <img key={i} className='w-28 h-20 rounded-md object-cover mb-1' src={image} alt='' />
                        ))
                    }
                </div>
                <div className='md:hidden flex justify-end gap-4 text-small mt-2'>
                    {
                        review.status === SHOP_REVIEW_STATUS.PUBLISHED && (
                            <button onClick={() => handleMarkTop(review?.id)} className='flex gap-1 items-center'>
                                <Star className={`size-4 mb-0.5 ${review.markTop ? 'fill-current text-icon-8' : ''}`} />
                                <p className='text-icon-8'>{review.markTop ? t('button.unmark_top_comment') : t('button.mark_top_comment')}</p>
                            </button>
                        )
                    }
                    <button
                        disabled={review.status === SHOP_REVIEW_STATUS.REQUEST_APPROVAL}
                        className='flex gap-1 items-center disabled:opacity-50'
                        onClick={() => handleReport([review.id])}
                    >
                        <OctagonAlert className='size-4 mb-0.5' />
                        <p>{t('button.report')}</p>
                    </button>
                </div>
                <div className='hidden md:flex justify-end  md:space-x-2 md:justify-end text-small'>
                    {
                        review.status === SHOP_REVIEW_STATUS.PUBLISHED && (
                            <button onClick={() => handleMarkTop(review?.id)} className='flex gap-1 items-center'>
                                <Star className={`size-4 mb-0.5 text-text-1 ${review.markTop ? 'fill-current text-text-1' : ''}`} />
                                <p className='text-text-1'>{review.markTop ? t('button.unmark_top_comment') : t('button.mark_top_comment')}</p>
                            </button>
                        )
                    }
                    <button
                        disabled={review.status === SHOP_REVIEW_STATUS.REQUEST_APPROVAL}
                        className='flex gap-1 items-center disabled:opacity-50'
                        onClick={() => handleReport([review.id])}
                    >
                        <OctagonAlert className='size-4 mb-0.5 text-text-1' />
                        <p className='text-text-1'>{t('button.report')}</p>
                    </button>
                </div>
            </div>
        </div>
    );
});

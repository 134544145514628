import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/utils/utils';

const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default:
                    'bg-active text-white hover:bg-active/90',
                destructive:
                    'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                outline:
                    'border border-active text-active bg-background md:hover:bg-active md:hover:text-white',
                secondary:
                    'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'link-1',
                submit: 'bg-active text-white',
                input: 'px-3 py-4 border border-input rounded-lg text-input-1 font-medium',
                paginate: 'border border-input !bg-button-paginate !text-white',
                paginateOutline: 'border border-border-1 bg-white hover:bg-button-paginate hover:text-white text-button-paginate',
                clear: 'border border-border-6 bg-white text-active',
                add: 'border border-solid border-[#3A3A3A] rounded-[2.1875rem] py-2 px-4 flex items-center text-[#3A3A3A] bg-white',
                active: 'bg-active border border-active text-white',
                option: 'border border-border-service text-label-4 bg-background md:hover:border-active md:hover:border-2'
            },
            size: {
                default: 'h-10.5',
                sm: 'h-9.5',
                icon: 'h-4.5 w-4.5',
                submit: 'h-10.5 w-full',
                input: 'h-12 w-full',
                paginate: 'h-9 w-9'
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default'
        }
    }
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
    asChild?: boolean,
    iconClass?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, iconClass, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}
            />
        );
    }
);
Button.displayName = 'Button';

export { Button, buttonVariants };

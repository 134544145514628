export const ENVIRONMENT_MODE = process.env.REACT_APP_ENVIRONMENT;

export const URL_CONFIG = {
    API: {
        url: process.env.REACT_APP_API_URL
    },
    SOCKET: {
        url: process.env.REACT_APP_SOCKET_URL,
        notificationNamespace: 'notification',
        chatNamespace: 'chat'
    }
};

import { ACTIVITY_PATH, ROUTES } from '@/configs/constants';
import { Link } from 'react-router-dom';
import { DoubleMessageIcon } from '../icons';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/hooks/useStore';
import { useEffect } from 'react';

export default observer(function ChatIcon() {
    // store
    const { authStore: { token, channel, isChannelShopOwner }, chatStore: { unreadChatsCount, countUnreadChat } } = useStore();

    // lifecycle
    useEffect(() => {
        if (token) {
            countUnreadChat({ channel });
        }
    }, [token]);

    return (
        <Link to={isChannelShopOwner ? ROUTES.chatForShop.href : `${ROUTES.activity.href}/${ACTIVITY_PATH.chat}`}>
            <div className='relative'>
                <DoubleMessageIcon className='h-6 w-6 stroke-active fill-none' />
                {
                    unreadChatsCount > 0 && (
                        <div className='absolute -top-[5px] -right-1 rounded-full flex justify-center items-center size-[15px] bg-text-21 text-white text-[9px]'>
                            {
                                unreadChatsCount > 99 ? 99 : unreadChatsCount
                            }
                        </div>
                    )
                }
            </div>

        </Link>
    );
});

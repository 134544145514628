import { Button } from '@/components/common/Button';
import Tooltip from '@/components/common/Tooltip';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface DisabledButtonProps {
    text: string,
    icon?: ReactElement,
    disabled: boolean,
    onClick: () => void,
    buttonClassname?: string,
    tooltipTriggerClassname?: string
}

export default observer(function DisabledButton({ text, icon, disabled, onClick, buttonClassname, tooltipTriggerClassname }: DisabledButtonProps) {
    // hooks
    const { t } = useTranslation();

    return (
        <>
            {
                disabled ?
                    (
                        <Tooltip
                            align='center'
                            content={t('sentences.alert_save_before_submit')}
                            triggerType='button'
                            triggerClassName={classNames('cursor-default', tooltipTriggerClassname)}
                        >
                            <Button
                                type='button'
                                variant='outline'
                                size='submit'
                                disabled
                                className={classNames('min-h-10.5 whitespace-normal md:min-w-52', buttonClassname)}
                            >
                                {icon}
                                {text}
                            </Button>
                        </Tooltip>
                    ) :
                    (
                        <Button
                            type='button'
                            variant='outline'
                            size='submit'
                            onClick={onClick}
                            className={classNames('min-h-10.5 whitespace-normal md:min-w-52', buttonClassname, tooltipTriggerClassname)}
                        >
                            {icon}
                            {text}
                        </Button>
                    )
            }
        </>
    );
});

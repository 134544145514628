import ApiService from './ApiService';

export default class ChatApi extends ApiService {
    constructor() {
        super('chats');
    }

    getChatList(payload) {
        return this.get('', payload);
    }

    getChatDetail(payload) {
        const { code, ...otherPayload } = payload;
        return this.get(code, otherPayload);
    }

    getMoreMessage(payload) {
        const { chatCode, ...otherPayload } = payload;
        return this.get(`${chatCode}/messages`, otherPayload);
    }

    sendNewMessage(payload) {
        return this.post('', payload);
    }

    getPreSignedChatImageURL(payload) {
        return this.post('presigned-url', payload);
    }

    seenMessages(payload) {
        const { chatCode, ...otherPayload } = payload;
        return this.put(`${chatCode}/seen-message`, otherPayload);
    }

    countUnreadChats(payload) {
        return this.get('unread-chat', payload);
    }
}

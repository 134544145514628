import * as React from 'react';
import { compareAsc, format } from 'date-fns';
import { Calendar as CalendarIcon, CircleXIcon } from 'lucide-react';
import {
    Popover,
    PopoverContent,
    PopoverTrigger
} from '../common/Popover';
import { isDateRange } from 'react-day-picker';
import { ReactDayPickerProps, ReactDayPicker } from './ReactDayPicker';
import { useTranslation } from 'react-i18next';
import { getLocaleDateFns } from '@/utils/language';
import { Button } from '../common/Button';
import { cn } from '@/utils/utils';
import { DISPLAY_FNS_DATE_YMD } from '@/configs/constants';

type CalendarProps = ReactDayPickerProps & {
    mode: 'single' | 'multiple' | 'range',
    selected: any,
    onSelect: any,
    type?: 'calendar' | 'datePicker',
    placeholder?: string,
    useIconRemove?: boolean,
    formatDate?: string
};

export default function Calendar({
    type = 'datePicker',
    mode,
    selected,
    onSelect,
    onDayClick,
    placeholder = 'Choose',
    useIconRemove = true,
    formatDate,
    ...props
}: CalendarProps) {
    // hooks
    const { i18n: { language } } = useTranslation();
    const localeDateFns = getLocaleDateFns(language);

    // state
    const [openCalendar, setOpenCalendar] = React.useState<boolean>(false);
    const [countClickDays, setCountClickDays] = React.useState<number>(0);

    // function
    const onCheckCloseCalendarRange = (date: Date) => {
        if (countClickDays > 0 && selected?.from && compareAsc(selected.from, date) !== 0) {
            setOpenCalendar(false);
            setCountClickDays(0);
        } else {
            setCountClickDays(state => state + 1);
        }
    };

    // render
    const CalendarChildren = () => {
        const defaultMonth = mode === 'single' ?
            selected :
            (
                mode === 'range' ?
                    (selected?.from ?? selected?.to) :
                    (
                        mode === 'multiple' ? selected?.[selected?.length - 1] : undefined
                    )
            );

        return (
            <ReactDayPicker
                mode={mode}
                locale={localeDateFns}
                selected={selected}
                onSelect={onSelect}
                onDayClick={(date, modifiers, e) => {
                    onDayClick && onDayClick(date, modifiers, e);
                    mode === 'single' && setOpenCalendar(false);
                    mode === 'range' && onCheckCloseCalendarRange(date);
                }}
                defaultMonth={defaultMonth}
                {...props}
            />
        );
    };

    if (type === 'calendar') {
        return <CalendarChildren />;
    }

    return (
        <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
            <PopoverTrigger asChild>
                <Button
                    variant='input'
                    size='input'
                    className={cn(
                        'relative justify-between',
                        !selected && 'text-placeholder-1'
                    )}
                >
                    {
                        selected && selected instanceof Date && format(selected, formatDate ?? DISPLAY_FNS_DATE_YMD)
                    }
                    {
                        (selected instanceof Array && selected.length > 0) && (
                            selected.length > 3 ?
                                `${selected.length} selected date` :
                                selected.map(selectDate => format(selectDate, formatDate ?? DISPLAY_FNS_DATE_YMD)).join(', '))
                    }
                    {
                        selected && isDateRange(selected) && selected.from &&
                        `${format(selected.from, formatDate ?? DISPLAY_FNS_DATE_YMD)} ~ ${selected.to ? format(selected.to, formatDate ?? DISPLAY_FNS_DATE_YMD) : ''}`
                    }
                    {
                        (!selected || (selected instanceof Array && selected.length === 0)) &&
                        <span>{placeholder}</span>
                    }
                    <div className='flex items-center gap-2'>
                        {
                            selected && useIconRemove && (
                                <CircleXIcon
                                    className=' hover:text-red-500'
                                    onPointerDown={() => {
                                        onSelect && onSelect(undefined);
                                        mode === 'range' && setCountClickDays(0);
                                    }}
                                />
                            )
                        }
                        <CalendarIcon />
                    </div>
                </Button>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0 rounded-lg shadow-none border-none'>
                <CalendarChildren />
            </PopoverContent>
        </Popover>
    );
}

import { ButtonProps } from '@/components/common/Button';
import ServerErrorModal from '@/components/general/ServerErrorModal';
import { modalVariants } from '@/components/modal/Modals';
import { ROUTES } from '@/configs/constants';
import { NotFoundPage } from '@/pages';
import { cn } from '@/utils/utils';
import { VariantProps } from 'class-variance-authority';
import { t } from 'i18next';
import { CircleAlert, CircleCheckIcon, CircleHelpIcon } from 'lucide-react';
import { action, makeAutoObservable, observable } from 'mobx';
import React from 'react';

export default class ModalStore {
    instances: ModalInstance[] = [];
    modalWaringAlertOptions: (options?: AlertModalInstance) => Partial<AlertModalInstance>;

    constructor() {
        makeAutoObservable(this, {
            instances: observable,
            showModal: action.bound,
            hideModal: action.bound,
            hideAllModals: action.bound,
            showAlertModal: action.bound,
            showServerErrorModal: action.bound
        });

        this.modalWaringAlertOptions = options => ({
            id: 'warning-modal',
            type: 'warn',
            cancelButton: t('button.no'),
            saveButton: t('button.yes'),
            title: t('words_title.confirm'),
            saveButtonClassName: 'w-1/2',
            cancelButtonClassName: 'w-1/2 hover:text-active hover:bg-background',
            variantSaveBtn: 'submit',
            ...options
        });
    }

    showModal(options?: ModalInstance) {
        const instance = new ModalInstance(options);
        this.instances.push(instance);
    }

    hideModal(id?: string) {
        if (id) {
            this.instances = this.instances.filter(ins => ins.id !== id);
        } else {
            this.instances.splice(-1, 1);
        }
    }

    hideModals(ids: string[]) {
        this.instances = this.instances.filter(ins => !ins?.id || !ids.includes(ins.id));
    }

    hideAllModals() {
        this.instances = [];
    }

    showAlertModal({
        id,
        type,
        size = 'md',
        content,
        cancelButton = false,
        onCancel,
        saveButton = t('button.close'),
        onSave,
        saveButtonClassName,
        showHeader = false,
        showCloseIcon = false,
        variantSaveBtn = 'outline',
        footerClassName,
        hideIconCenter = false,
        contentClassName = '',
        ...options
    }: AlertModalInstance = {}) {
        this.showModal({
            id,
            type,
            size,
            cancelButton,
            saveButton,
            saveButtonClassName: cn('w-full', saveButtonClassName),
            variantSaveBtn,
            showHeader,
            showCloseIcon,
            footerClassName,
            onSave: () => {
                this.hideModal(id);
                onSave?.();
            },
            onCancel: () => {
                this.hideModal(id);
                onCancel?.();
            },
            content: (
                <div className='flex flex-col items-center pt-4'>
                    {
                        !hideIconCenter && (
                            <div className='mb-4'>
                                {
                                    type === 'error' && <CircleAlert className='text-warning-1 w-12 h-12' />
                                }
                                {
                                    type === 'success' && <CircleCheckIcon className='text-success-1 w-12 h-12' />
                                }
                                {
                                    type === 'warn' && <CircleHelpIcon className='text-icon-3 w-12 h-12' />
                                }
                            </div>
                        )
                    }
                    <div className={cn('text-center text-sm font-medium text-label-1 whitespace-pre-line leading-6', contentClassName)}>
                        {content}
                    </div>
                </div>
            ),
            ...options
        });
    }

    showServerErrorModal({
        id,
        size = 'md',
        content,
        onCancel,
        saveButton,
        onSave,
        showCloseIcon = false,
        ...options
    }: ModalInstance = {}) {
        this.showModal({
            size,
            showHeader: false,
            saveButton: t('button.reload'),
            saveButtonClassName: 'w-full h-10.5 rounded-2 font-medium w-[200px]',
            footerClassName: 'sm:justify-center',
            variantSaveBtn: 'outline',
            onSave: () => {
                window.location.reload();
            },
            content: (
                <ServerErrorModal className='pt-4' />
            ),
            showCloseIcon,
            ...options
        });
    }

    show404ErrorModal({
        id,
        size = 'md',
        content,
        onCancel,
        saveButton,
        onSave,
        showCloseIcon = false,
        ...options
    }: ModalInstance = {}) {
        this.showModal({
            size,
            showHeader: false,
            saveButton: t('button.back_to_home'),
            saveButtonClassName: 'w-full h-10.5 rounded-2 font-medium w-[200px]',
            footerClassName: 'sm:justify-center',
            variantSaveBtn: 'outline',
            onSave: () => {
                window.location.href = ROUTES.home.href;
            },
            content: (
                <NotFoundPage message={content} className='pt-4' hideTitle />
            ),
            showCloseIcon,
            ...options
        });
    }
}

class ModalInstance {
    id?: string;
    type?: VariantProps<typeof modalVariants>['type'];
    size?: VariantProps<typeof modalVariants>['size'];
    // header
    title?: string;
    titleClassName?: string;
    description?: string;
    headerRender?: React.ReactNode;
    showHeader?: boolean;
    headerClassName?: string;
    // close button
    showCloseIcon?: boolean;
    // content
    content?: React.ReactNode;
    contentClassName?: string;
    contentContainerClassName?: string;
    closeWhenOutside?: boolean;
    // footer
    footer?: React.ReactNode;
    footerClassName?: string;
    cancelButton?: React.ReactNode;
    cancelButtonClassName?: string;
    onCancel?: Function;
    saveButton?: React.ReactNode;
    saveButtonClassName?: string;
    onSave?: Function;
    variantCancelBtn?: ButtonProps['variant'];
    variantSaveBtn?: ButtonProps['variant'];

    constructor({
        showCloseIcon = true,
        closeWhenOutside = false,
        showHeader = true,
        variantCancelBtn = 'outline',
        variantSaveBtn = 'default',
        type = 'default',
        size = 'default',
        ...options
    }: ModalInstance = {}) {
        this.id = options.id;
        this.type = type;
        this.size = size;
        this.title = options.title;
        this.titleClassName = options.titleClassName;
        this.description = options.description;
        this.headerRender = options.headerRender;
        this.headerClassName = options.headerClassName;
        this.showHeader = showHeader;
        this.showCloseIcon = showCloseIcon;
        this.content = options.content;
        this.contentClassName = options.contentClassName;
        this.contentContainerClassName = options.contentContainerClassName;
        this.closeWhenOutside = closeWhenOutside;
        this.footer = options.footer;
        this.footerClassName = options.footerClassName;
        this.cancelButton = options.cancelButton;
        this.cancelButtonClassName = options.cancelButtonClassName;
        this.onCancel = options.onCancel;
        this.saveButton = options.saveButton;
        this.saveButtonClassName = options.saveButtonClassName;
        this.onSave = options.onSave;
        this.variantCancelBtn = variantCancelBtn;
        this.variantSaveBtn = variantSaveBtn;
    }
}

interface AlertModalInstance extends ModalInstance {
    hideIconCenter?: boolean,
    contentClassName?: string
}

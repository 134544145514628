import ApiService from './ApiService';

export default class ShopApi extends ApiService {
    // #region my-shop
    getShopInfo(payload) {
        return this.get('my-shop', payload);
    }

    updateGeneralInfo(payload) {
        return this.put('my-shop/general-info', payload);
    }

    updateShopInfo(payload) {
        return this.put('my-shop/shop-info', payload);
    }

    updateOperationInfo(payload) {
        return this.put('my-shop/operation-info', payload);
    }

    updateAppointmentInfo(payload) {
        return this.put('my-shop/appointment-info', payload);
    }

    requestApprove(payload) {
        return this.post('my-shop/request-approve', payload);
    }

    createShop(payload) {
        return this.post('my-shop', payload);
    }

    // #endregion

    // #region all shops
    getAllShop(payload) {
        return this.post('shops', payload);
    }

    updateFavoriteShops(payload) {
        const { slug, favorite } = payload;
        return this.put(`shops/favorite/${slug}`, { favorite });
    }

    getAllFavoriteShop(payload) {
        return this.post('shops', payload);
    }

    getShopDetail(payload) {
        const { slug, data, ...otherPayload } = payload;
        return this.get(`shops/${slug}`, {
            ...otherPayload,
            ...data
        });
    }

    postReview(payload) {
        const { slug, ...data } = payload;
        return this.post(`shops/reviews/${slug}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getAllReviewForShopDetail(payload) {
        const { slug, ...paging } = payload;
        return this.get(`shops/reviews/${slug}`, paging);
    }
    // #endregion

    // #region statistics
    getStatisticsAppointmentByStatus(payload) {
        return this.get('my-shop/statistics/appointment-by-status', payload);
    }

    getStatisticsAppointmentByService(payload) {
        return this.get('my-shop/statistics/appointment-by-service', payload);
    }

    getStatisticsAppointmentByView(payload) {
        return this.get('my-shop/statistics/view', payload);
    }

    // #endregion
}

import { observer } from 'mobx-react-lite';
import MyAccountItem from './MyAccountItem';
import { Calendar, ChevronRightIcon, LockIcon, MailIcon, MapPinHouseIcon, MapPinIcon, PhoneIcon, UserIcon } from 'lucide-react';
import { Button } from '@/components/common/Button';
import { useTranslation } from 'react-i18next';
import { Profile } from '@/types/auth';
import { DISPLAY_MOMENT_DATE_YMD, ROUTES } from '@/configs/constants';
import { useNavigate } from 'react-router-dom';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { formatDateTime } from '@/utils/datetime';
import { LANGUAGES_SUPPORTED } from '@/types/enums';
type Props = {
    profile?: Profile
};
export default observer(function AccountInfoMobile({ profile }: Props) {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.account_infomation')} defaultNavigateUrl={ROUTES.myAccount.href} />} />
            <MyAccountItem icon={UserIcon} title={t('words_title.full_name')} desc={profile?.fullName} />
            <MyAccountItem className='mt-1.5' icon={Calendar} title={t('words_title.date_of_birth')} desc={formatDateTime(profile?.birthday, DISPLAY_MOMENT_DATE_YMD)} />
            <MyAccountItem className='mt-1.5' icon={PhoneIcon} title={t('words_title.phone_number')} desc={profile?.phoneNumber} buttonRight={t('button.change_2')} urlButtonRight={ROUTES.myAccountEditPhone.href} />
            <MyAccountItem className='mt-1.5' icon={MailIcon} title={t('words_title.email')} desc={profile?.email} />
            <MyAccountItem className='mt-1.5' icon={MapPinIcon} title={t('words_title.city')} desc={language === LANGUAGES_SUPPORTED.English ? profile?.province?.fullNameEn : profile?.province?.fullName} />
            <MyAccountItem className='mt-1.5' icon={MapPinHouseIcon} title={t('words_title.district')} desc={language === LANGUAGES_SUPPORTED.English ? profile?.district?.fullNameEn : profile?.district?.fullName} />
            <MyAccountItem className='mt-1.5' icon={MapPinHouseIcon} title={t('words_title.ward')} desc={language === LANGUAGES_SUPPORTED.English ? profile?.ward?.fullNameEn : profile?.ward?.fullName} />
            <MyAccountItem className='mt-1.5' icon={MapPinHouseIcon} title={t('words_title.address')} desc={profile?.address} />
            <MyAccountItem className='mt-1.5' icon={LockIcon} title={t('button.change_password')} iconRight={ChevronRightIcon} url={ROUTES.changePassword.href} />
            <div className='mx-4 my-6'>
                <Button onClick={() => navigate(ROUTES.myAccountEdit.href, { state: { previousUrl: `${ROUTES.myAccount.href}#account-infomation` } })} className='h-9.5 w-full' variant='outline'>
                    <span className='font-medium'>{t('button.edit_account_info')}</span>
                </Button>
            </div>
        </div>
    );
});

import { ROUTES, SHOP_SERVICE_NAME } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { APPOINTMENT_STATUS_TYPE, SHOP_SERVICE_TYPE } from '@/types/enums';
import { ShopServiceAppointment } from '@/types/shop';
import { convertServicePrice, convertPetWeight, formatNumber } from '@/utils/utils';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { SquarePen } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default observer(function ServicesList() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        appointmentStore: { appointment }
    } = useStore();

    // functions
    const medicalServices = useMemo<ShopServiceAppointment[]>(() => {
        if (appointment?.selectedServices && appointment.selectedServices.length > 0) {
            return appointment.selectedServices
                .filter(service => service.type === SHOP_SERVICE_TYPE.Medical || service.isMedicalService)
                .sort((a, b) => a.type - b.type);
        }
        return [];
    }, [appointment?.selectedServices]);

    const groomingServices = useMemo<ShopServiceAppointment[]>(() => {
        if (appointment?.selectedServices && appointment.selectedServices.length > 0) {
            return appointment.selectedServices
                .filter(service => service.type !== SHOP_SERVICE_TYPE.Medical && !service.isMedicalService)
                .sort((a, b) => a.type - b.type);
        }
        return [];
    }, [appointment?.selectedServices]);

    return (
        <>
            <div className='flex flex-col gap-4 bg-white p-4 md:rounded-sm'>
                <div className='flex justify-between md:px-4 items-center'>
                    <h4 className='font-semibold text-[0.9375rem]'>{t('words_title.services_list')}</h4>
                    {(appointment?.status === APPOINTMENT_STATUS_TYPE.NotConfirmed ||
                        appointment?.status === APPOINTMENT_STATUS_TYPE.Confirmed
                    ) && (
                        <Link to={`${ROUTES.editServiceList.href}/${appointment.code}`}className='flex gap-1 items-center justify-center'>
                            <SquarePen className='text-black size-6 md:size-4' />
                            <p className='font-medium hidden md:block'>{t('button.edit')}</p>
                        </Link>
                    )}
                </div>
                <hr className='text-border-8' />
                {groomingServices && groomingServices.length > 0 && (
                    <div className='md:px-4'>
                        <div className='flex justify-between'>
                            <h5 className='font-semibold pb-4'>{t('words_title.grooming_services')}</h5>
                            <p className='text-tab-1'>
                                (
                                {t('words_title.currency')}
                                : VND)
                            </p>
                        </div>
                        <div className='flex flex-col gap-3'>
                            {convertServiceList(groomingServices, t).map((service, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={classNames(
                                            'pb-3 border-b border-dashed border-b-border-8 flex justify-between gap-1',
                                            { 'border-none !pb-0': index === groomingServices.length - 1 }
                                        )}
                                    >
                                        <p className='text-tab-1 w-[65%]'>{service.serviceName}</p>
                                        <p className='text-tab-1 text-right'>
                                            {service.servicePrice}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {medicalServices && medicalServices.length > 0 && groomingServices && groomingServices.length > 0 && (<hr className='text-border-8' />)}
                {medicalServices && medicalServices.length > 0 && (
                    <div className='md:px-4'>
                        <div className='flex justify-between'>
                            <h5 className='font-semibold pb-4'>{t('words_title.medical_services')}</h5>
                            <p className='text-tab-1'>
                                (
                                {t('words_title.currency')}
                                : VND)
                            </p>
                        </div>
                        <div className='flex flex-col gap-3'>
                            {convertServiceList(medicalServices, t).map((service, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={classNames(
                                            'pb-3 border-b border-dashed border-b-border-8 flex justify-between gap-1',
                                            { 'border-none !pb-0': index === medicalServices.length - 1 }
                                        )}
                                    >
                                        <p className='text-tab-1 w-[65%]'>{service.serviceName}</p>
                                        <p className='text-tab-1 text-right'>
                                            {service.servicePrice}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <div className='flex justify-between items-center bg-white p-4 md:rounded-sm pb-20 md:pb-4'>
                <div className='hidden md:block'></div>
                <p className='font-semibold'>{t('words_title.expected_total_amount')}</p>
                <p className='font-semibold text-[1.3125rem]'>{`${formatNumber(appointment?.totalAmount ?? 0)} VND`}</p>
            </div>
        </>
    );
});

const convertServiceList = (serviceList: ShopServiceAppointment[], t: TFunction) => {
    let result: any = [];
    if (serviceList.length > 0) {
        result = serviceList.map((service) => {
            let serviceName = '';
            let servicePrice: number | string = convertServicePrice(service.price, t, false);
            if (service.type === SHOP_SERVICE_TYPE.Dog || service.type === SHOP_SERVICE_TYPE.Cat) {
                serviceName = t(`select_options.${SHOP_SERVICE_NAME[service.serviceName].key}`);
                serviceName += ` / ${convertPetWeight(service.petWeightFrom, service.petWeightTo, t)}`;
            } else if (service.type === SHOP_SERVICE_TYPE.Additional) {
                serviceName = `(${t('words_title.additional')}) ` + service.serviceName;
            } else {
                serviceName = service.serviceName;
            }
            return {
                serviceName,
                servicePrice
            };
        });
    }
    return result;
};

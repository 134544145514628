import { action, computed, flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { getStorage, removeStorage, saveLocalStorage } from '@/utils/browsers';
import { AuthApi } from '@/apis';
import { ROUTES } from '@/configs/constants';
import { Profile } from '@/types/auth';
import { ResponseData } from '@/types/http';
import { ChangePasswordRequest, ChangePhoneNumberRequest, EditProfileRequest, ForgotPasswordPostRequest, ForgotPasswordPostResponse, ResendOtpPostRequest, ResendOtpPostResponse, ResetPasswordPostRequest, ResetPasswordPostResponse, SignInPostRequest, SignInPostResponse, SignUpPetOwnerPostRequest, SignUpPostResponse, SignUpShopOwnerPostRequest, SignUpVerifyOtpPostRequest, VerifyOtpPhoneNumberRequest, VerifyOtpPostRequest, VerifyOtpPostResponse, VerifyUuidPostRequest, VerifyUuidPostResponse } from '@/types/http-payload/auth';
import { ENDUSER_TYPE, EnduserType } from '@/types/enums';

export default class AuthStore extends BaseStore {
    api: AuthApi;
    profile?: Profile = getStorage('profile', true);
    token: string = getStorage('token');
    channel: EnduserType = getStorage('channel') === ENDUSER_TYPE.ShopOwner.toString() ? ENDUSER_TYPE.ShopOwner : ENDUSER_TYPE.PetOwner;
    userType: EnduserType = getStorage('userType') === ENDUSER_TYPE.ShopOwner.toString() ? ENDUSER_TYPE.ShopOwner : ENDUSER_TYPE.PetOwner;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            profile: observable,
            token: observable,
            channel: observable,
            userType: observable,
            isChannelShopOwner: computed,
            signIn: flow.bound,
            signUp: flow.bound,
            forgotPassword: flow.bound,
            resetPassword: flow.bound,
            changePassword: flow.bound,
            changePhoneNumber: flow.bound,
            verifyOtpPhoneNumber: flow.bound,
            verifyOtp: flow.bound,
            verifyUuid: flow.bound,
            signOut: action.bound,
            getProfile: flow.bound,
            editProfile: flow.bound,
            resendOtp: flow.bound,
            clearAuthentication: action.bound,
            onSwitchToPetChannel: action.bound,
            onSwitchToShopChannel: action.bound,
            onCheckAuthenRedirect: action.bound
        });
        this.api = new AuthApi();
    }

    get isShopOwner() {
        saveLocalStorage('userType', this.userType.toString());
        return this.userType === ENDUSER_TYPE.ShopOwner;
    }

    get isChannelShopOwner() {
        saveLocalStorage('channel', this.channel.toString());
        return this.channel === ENDUSER_TYPE.ShopOwner;
    }

    *signIn(payload: SignInPostRequest) {
        try {
            const res: ResponseData<SignInPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.signIn, payload);
            if (res?.ok) {
                this.token = res.data.token;
                this.channel = payload.type as EnduserType;
                this.userType = payload.type as EnduserType;
                saveLocalStorage('token', res.data.token);
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *signUp(payload: SignUpPetOwnerPostRequest | SignUpShopOwnerPostRequest | SignUpVerifyOtpPostRequest) {
        try {
            const res: ResponseData<SignUpPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.signUp, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *forgotPassword(payload: ForgotPasswordPostRequest) {
        try {
            const res: ResponseData<ForgotPasswordPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.forgotPassword, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *resetPassword(payload: ResetPasswordPostRequest) {
        try {
            const res: ResponseData<ResetPasswordPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.resetPassword, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *changePassword(payload: ChangePasswordRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.changePassword, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *changePhoneNumber(payload: ChangePhoneNumberRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.changePhoneNumber, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *verifyOtpPhoneNumber(payload: VerifyOtpPhoneNumberRequest) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.verifyOtpPhoneNumber, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *verifyOtp(payload: VerifyOtpPostRequest) {
        try {
            const res: ResponseData<VerifyOtpPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.verifyOtp, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *verifyUuid(payload: VerifyUuidPostRequest) {
        try {
            const res: ResponseData<VerifyUuidPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.verifyUuid, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *getProfile() {
        try {
            const res: ResponseData<Profile> = yield this.rootStore.apiStore.call(this.api, this.api.getProfile, {
                notificationForShop: this.rootStore.authStore.isChannelShopOwner
            });
            if (res?.ok) {
                this.profile = res.data;
                this.userType = res.data.type as EnduserType;
                this.rootStore.notificationStore.totalNotReadNotification = res.data.totalNotReadNotification ?? 0;
                saveLocalStorage('profile', {
                    fullName: this.profile.fullName,
                    alreadyOwnShop: this.profile.alreadyOwnShop
                }, true);
                return res.data;
            }
        } catch (error) {
        }
    }

    *editProfile(payload: EditProfileRequest) {
        try {
            const res: ResponseData<Profile> = yield this.rootStore.apiStore.call(this.api, this.api.editProfile, payload);
            if (res?.ok) {
                this.profile = res.data;
                return true;
            }
            return false;
        } catch (error) {
        }
    }

    *resendOtp(payload: ResendOtpPostRequest) {
        try {
            const res: ResponseData<ResendOtpPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.resendOtp, payload);
            if (res?.ok) {
                return res;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    onSwitchToPetChannel() {
        saveLocalStorage('channel', ENDUSER_TYPE.PetOwner.toString());
        window.location.href = ROUTES.home.href;
    }

    onSwitchToShopChannel() {
        if (this.isShopOwner) {
            saveLocalStorage('channel', ENDUSER_TYPE.ShopOwner.toString());
            window.location.href = ROUTES.home.href;
        } else {
            saveLocalStorage('signInUserType', ENDUSER_TYPE.ShopOwner.toString());
            window.location.href = `${ROUTES.signIn.href}${!this.token ? '' : '#shop-owner'}`;
        }
    }

    onCheckAuthenRedirect(nonAuthUrl?: string, authUrl?: string) {
        if (!this.token) {
            return nonAuthUrl ? nonAuthUrl : ROUTES.home.href;
        } else {
            return authUrl ? authUrl : ROUTES.home.href;
        }
    }

    signOut(redirectHome: boolean = true) {
        try {
            this.rootStore.apiStore.call(this.api, this.api.signOut);
            this.clearAuthentication();
            if (redirectHome) {
                window.location.href = ROUTES.home.href;
            }
            return true;
        } catch (error) {
        }
    }

    clearAuthentication() {
        removeStorage(['token', 'profile', 'userType', 'channel', 'signInUserType']);
    }
}

import ApiService from './ApiService';

export default class AuthApi extends ApiService {
    signIn(payload) {
        return this.post('sign-in', payload);
    }

    signUp(payload) {
        return this.post('sign-up', payload);
    }

    forgotPassword(payload) {
        return this.post('forgot-password', payload);
    }

    resetPassword(payload) {
        return this.post('set-password', payload);
    }

    changePassword(payload) {
        return this.post('change-password', payload);
    }

    verifyOtp(payload) {
        return this.post('verify-otp', payload);
    }

    verifyUuid(payload) {
        return this.post('verify-uuid', payload);
    }

    signOut() {
        return this.post('sign-out');
    }

    getProfile(payload) {
        return this.get('profile', payload);
    }

    editProfile(payload) {
        return this.put('profile', payload);
    }

    changePhoneNumber(payload) {
        return this.post('change-phone-number', payload);
    }

    verifyOtpPhoneNumber(payload) {
        return this.post('change-phone-number/verify-otp', payload);
    }

    resendOtp(payload) {
        return this.post('resend-otp', payload);
    }
}

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { InputWithIcon } from '@/components/common/Input';
import { Search } from 'lucide-react';
import AppointmentItem from '../components/AppointmentItem';
import { useTranslation } from 'react-i18next';
import DualDatePicker from '@/components/calendar/DualDatePicker';
import { useStore } from '@/hooks/useStore';
import Paginate from '@/components/pagination/Paginate';
import { formatDateTime } from '@/utils/datetime';
import { SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { scrollToTop } from '@/utils/utils';

interface AppointmentListProps {
    type: number
}

export default observer(function AppointmentList({ type }: AppointmentListProps) {
    // hooks
    const { t } = useTranslation();

    // store
    const { appointmentStore: { appointments, paging, totalPageAppointments, getAllAppointments, clean } } = useStore();

    // state
    const [params, setParams] = useState<{ [key: string]: any }>(!!type ? { status: type } : {});
    const [keyWord, setKeyWord] = useState('');
    const [fromAppointmentDate, setFromAppointmentDate] = useState<Date | undefined>(undefined);
    const [toAppointmentDate, setToAppointmentDate] = useState<Date | undefined>(undefined);

    // lifecycle
    useEffect(() => {
        getAllAppointments(params);
    }, [params]);

    useEffect(() => {
        return () => {
            clean();
        };
    }, []);

    useEffect(() => {
        setParams((prevParams) => {
            let updatedParams = { ...prevParams };

            // Xử lý fromAppointmentDate
            if (fromAppointmentDate) {
                updatedParams.fromAppointmentDate = formatDateTime(fromAppointmentDate, SUBMIT_MOMENT_DATE_YMD);
            } else {
                const { fromAppointmentDate, ...rest } = updatedParams;
                updatedParams = rest; // Loại bỏ fromAppointmentDate nếu không có giá trị
            }

            // Xử lý toAppointmentDate
            if (toAppointmentDate) {
                updatedParams.toAppointmentDate = formatDateTime(toAppointmentDate, SUBMIT_MOMENT_DATE_YMD);
            } else {
                const { toAppointmentDate, ...rest } = updatedParams;
                updatedParams = rest; // Loại bỏ toAppointmentDate nếu không có giá trị
            }

            return updatedParams;
        });
    }, [toAppointmentDate, fromAppointmentDate]);

    // functions
    const handleSearch = () => {
        const trimmedKeyword = keyWord.trim();
        setKeyWord(trimmedKeyword);
        setParams({ ...params, keyword: trimmedKeyword });
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <>
            <div className='bg-gray-100 flex flex-col gap-1 md:gap-4 md:bg-white md:px-4 md:mt-6 min-h-56'>
                <div className='flex flex-col px-4 md:px-0 md:0 gap-y-4 gap-4 point-920:flex-row point-920:justify-between bg-white pb-4 md:pb-2'>
                    <div className='w-full lg:w-[18rem]'>
                        <InputWithIcon
                            className='relative placeholder:text-placeholder-1 h-9 md:h-12'
                            type='text'
                            placeholder={t('placeholder.search_by_keyword')}
                            icon={(
                                <div
                                    className='absolute right-4 top-1/2 -translate-y-1/2'
                                    onClick={handleSearch}
                                >
                                    <Search strokeWidth={2} className='size-[1.1875rem] text-placeholder-1 cursor-pointer hover:text-active' />
                                </div>
                            )}
                            value={keyWord}
                            onChange={e => setKeyWord(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className='min-w-64'>
                        <DualDatePicker
                            selectedFrom={fromAppointmentDate}
                            selectedTo={toAppointmentDate}
                            onSelectFrom={setFromAppointmentDate}
                            onSelectTo={setToAppointmentDate}
                            className='h-9 md:h-12'
                        />
                    </div>
                </div>
                {appointments.length > 0 ?
                    (
                        appointments.map(appointment => (
                            <AppointmentItem key={appointment.code} appointment={appointment} params={params} />
                        ))
                    ) :
                    (
                        <div className='flex items-center justify-center bg-white min-h-48'>
                            <p className='text-tab-1'>{t('sentences.no_appointment_found')}</p>
                        </div>
                    )}
            </div>
            {appointments.length > 0 && (
                <div className='flex justify-center mt-4 md:mt-6'>
                    <Paginate
                        forcePage={paging.pagination.pageIndex}
                        pageCount={totalPageAppointments}
                        onPageChange={({ selected }) => {
                            paging.pagination.pageIndex = selected;
                            getAllAppointments(params, paging);
                            scrollToTop();
                        }}
                    />
                </div>
            )}
        </>
    );
});

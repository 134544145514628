import axios from 'axios';

class FileApi {
    uploadFile({ url, payload, onUploadProgress }) {
        return axios({
            url,
            method: 'POST',
            data: payload,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'json',
            onUploadProgress
        });
    }
}

export default FileApi;

import { useStore } from '@/hooks/useStore';
import { SHOP_STATUS_TYPE } from '@/types/enums';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect } from 'react';
import ShopChannelDashboard from './pages/ShopChannelDashboard';
import ShopChannelNewUser from './pages/ShopChannelNewUser';
import ShopChannelWaitApprove from './pages/ShopChannelWaitApprove';
import ShopChannelDenied from './pages/ShopChannelDenied';

interface ShopOwnerChannelPageProps {
    children?: ReactElement
}

export default observer(function ShopOwnerChannelPage({ children }: ShopOwnerChannelPageProps) {
    // store
    const { shopStore: { shopInfo, getShopInfo } } = useStore();

    // life cycle
    useEffect(() => {
        getShopInfo();
    }, []);

    switch (shopInfo?.status) {
        case SHOP_STATUS_TYPE.Active:
            return children ?? <ShopChannelDashboard />;
        case SHOP_STATUS_TYPE.NotCompletedInfo:
            return <ShopChannelNewUser />;
        case SHOP_STATUS_TYPE.WaitApprove:
            return <ShopChannelWaitApprove />;
        case SHOP_STATUS_TYPE.DeniedApprove:
            return <ShopChannelDenied />;
        default: return <></>;
    }
});

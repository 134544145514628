import { observer } from 'mobx-react-lite';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { APPOINTMENT_MANAGEMENT_TABS } from '@/configs/constants';
import { Carousel, CarouselContent, CarouselItem } from '@/components/common/Carousel';
import AppointmentList from './appointment-list';
import { useStore } from '@/hooks/useStore';
import { useTranslation } from 'react-i18next';

export default observer(function AppointmentManagement() {
    // hooks
    const { t } = useTranslation();

    // store
    const { uiStore: { devicesScreen: { mobile, desktop }, windowSize: { width } } } = useStore();

    return (
        <div className='flex flex-col md:gap-6 md:pt-6 pb-2.5'>
            <div className='text-start px-4 hidden md:block'>
                <h3 className='text-[1.3125rem] text-active font-semibold'>{t('words_title.appointment_management')}</h3>
            </div>
            <div className='md:pt-4'>
                <Tabs defaultValue={APPOINTMENT_MANAGEMENT_TABS[0].key}>
                    {width >= 1024 && (
                        <TabsList variant='history' data-orientation='horizontal' className='flex w-full overflow-x-auto mt-4'>
                            {APPOINTMENT_MANAGEMENT_TABS.map((tab, index) =>
                                (<TabsTrigger onClick={() => {}} key={index} variant='history' value={tab.key} className='md:text-[0.875rem] text-small w-full h-11 lg:h-7'>{t(`words_title.${tab.key}`)}</TabsTrigger>))}
                        </TabsList>
                    )}
                    {width < 1024 && (
                        <TabsList variant='appointment' data-orientation='horizontal' className='w-full sticky top-13 z-20 bg-white'>
                            <Carousel
                                opts={{
                                    align: 'start'
                                }}
                                className='w-full'
                            >
                                <CarouselContent className='flex snap-x snap-mandatory ml-0'>
                                    {APPOINTMENT_MANAGEMENT_TABS.map((tab, index) =>
                                        (
                                            <CarouselItem key={index} className='pl-0 point-600:basis-[calc(33.333333%-35px)] point-450:basis-[calc(33.333333%-25px)] point-375:basis-[calc(33.333333%-15px)] basis-[calc(33.333333%-12px)] md:basis-32 snap-center flex-shrink-0'>
                                                <TabsTrigger key={index} variant='history' className='text-small w-full' value={tab.key}>{t(`words_title.${tab.key}`)}</TabsTrigger>
                                            </CarouselItem>
                                        ))}
                                </CarouselContent>
                            </Carousel>
                        </TabsList>
                    )}

                    {/* List */}
                    {APPOINTMENT_MANAGEMENT_TABS.map((tab, index) =>
                        (
                            <TabsContent key={index} value={tab.key}>
                                <AppointmentList type={tab.value} />
                            </TabsContent>
                        ))}
                </Tabs>
            </div>
        </div>

    );
});

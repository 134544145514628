import { Button } from '@/components/common/Button';
import Combobox from '@/components/common/Combobox';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from '@/components/common/Form';
import { FloatingLabelInput } from '@/components/common/Input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import Tooltip from '@/components/common/Tooltip';
import { PASSWORD_VALIDATE_REGEX, PHONE_VALIDATE_REGEX, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { ENDUSER_TYPE, LANGUAGES_SUPPORTED } from '@/types/enums';
import { yupResolver } from '@hookform/resolvers/yup';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default observer(function SignUpPage() {
    // hooks
    const { state } = useLocation();
    const { t } = useTranslation();

    // state
    const [userType, setUserType] = useState<string>(state?.userType ? state.userType : String(ENDUSER_TYPE.PetOwner));

    // function
    const onClickChangeTab = (value) => {
        setUserType(value);
    };

    return (
        <div className='mx-auto flex flex-col justify-center'>
            <Tabs defaultValue={userType} className='w-full mt-6' onValueChange={onClickChangeTab}>
                <h3 className='title-1 text-center'>{t('words_title.sign_up')}</h3>
                <TabsList variant='form' className='grid w-full grid-cols-2 mt-6'>
                    <TabsTrigger variant='form' value={String(ENDUSER_TYPE.PetOwner)}>{t('words_title.pet_owner')}</TabsTrigger>
                    <TabsTrigger variant='form' value={String(ENDUSER_TYPE.ShopOwner)}>{t('words_title.shop_owner')}</TabsTrigger>
                </TabsList>
                <TabsContent value={String(ENDUSER_TYPE.PetOwner)} className='mt-6'>
                    <SignUpForm userType={userType} />
                </TabsContent>
                <TabsContent value={String(ENDUSER_TYPE.ShopOwner)} className='mt-6'>
                    <SignUpForm userType={userType} />
                </TabsContent>
            </Tabs>
        </div>
    );
});

interface SignUpFormProps {
    userType: string
}

const SignUpForm = observer(({ userType }: SignUpFormProps) => {
    // hooks
    const { t, i18n: { language } } = useTranslation();
    const navigate = useNavigate();

    // store
    const { authStore: { signUp }, generalStore: { administrativeUnit } } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        fullName: yup
            .string()
            .required('form_error_validate.required'),
        phoneNumber: yup
            .string()
            .required('form_error_validate.required')
            .matches(PHONE_VALIDATE_REGEX, 'form_error_validate.format_phone'),
        password: yup
            .string()
            .required('form_error_validate.required')
            .matches(PASSWORD_VALIDATE_REGEX, 'form_error_validate.format_password'),
        confirmPassword: yup
            .string()
            .required('form_error_validate.required')
            .oneOf([yup.ref('password')], 'form_error_validate.confirm_password_not_match'),
        provinceName: yup
            .string()
            .required('form_error_validate.required'),
        provinceCode: yup
            .string()
            .required('form_error_validate.required'),
        type: yup.string().required(),
        shopName: yup.string().when('type', {
            is: ENDUSER_TYPE.ShopOwner.toString(),
            then: _ => yup.string()
                .required('form_error_validate.required'),
            otherwise: _ => yup.string().nullable().notRequired()
        }),
        taxCode: yup.string().when('type', {
            is: ENDUSER_TYPE.ShopOwner.toString(),
            then: _ => yup
                .string()
                .required('form_error_validate.required')
                .max(13, 'form_error_validate.tax_code_maxlength'),
            otherwise: _ => yup.string().nullable().notRequired()
        })
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            fullName: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            provinceCode: '',
            type: userType,
            shopName: '',
            taxCode: ''
        }
    });

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [isOpenTooltipHint, setIsOpenTooltipHine] = useState<boolean>(false);

    // lifecycle
    useEffect(() => {
        form.setValue('type', userType);
    }, [userType]);

    // function
    const onSubmit = async (data: FormData) => {
        const { fullName, phoneNumber, password, provinceCode, type, shopName, taxCode } = data;

        const res = await flowResult(signUp({
            fullName,
            phoneNumber,
            password,
            provinceCode,
            type: Number(type),
            ...(type === ENDUSER_TYPE.ShopOwner.toString() && {
                shopName,
                taxCode
            })
        }));

        if (res && res.ok) {
            navigate(`${ROUTES.verifyOtpSignUp.href}?uuid=${res.data.uuid}`);
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='mt-6'>
                <div>
                    {
                        userType === String(ENDUSER_TYPE.PetOwner) ?
                            (
                                <FormField
                                    control={form.control}
                                    name='fullName'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <FloatingLabelInput
                                                    label={t(
                                                        'words_title.full_name'
                                                    )}
                                                    className='mt-4'
                                                    required
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            ) :
                            (
                                <>
                                    <FormField
                                        control={form.control}
                                        name='shopName'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <FloatingLabelInput
                                                        label={t(
                                                            'words_title.shop_name'
                                                        )}
                                                        className='mt-4'
                                                        required
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name='taxCode'
                                        render={({ field }) => (
                                            <FormItem>
                                                <Tooltip
                                                    open={isOpenTooltipHint}
                                                    content={t('sentences.tax_code_note')}
                                                    triggerAsChild
                                                >
                                                    <FormControl>
                                                        <FloatingLabelInput
                                                            maxLength={13}
                                                            type='number'
                                                            label={t(
                                                                'words_title.tax_code'
                                                            )}
                                                            className='mt-4'
                                                            isTypingOnlyNumber={true}
                                                            required
                                                            onFocus={() => {
                                                                setIsOpenTooltipHine(true);
                                                            }}
                                                            {...field}
                                                            onChange={(value) => {
                                                                setIsOpenTooltipHine(false);
                                                                field.onChange(value);
                                                            }}
                                                            onBlur={() => {
                                                                setIsOpenTooltipHine(false);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Tooltip>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name='fullName'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <FloatingLabelInput
                                                        label={t(
                                                            'words_title.owner_name'
                                                        )}
                                                        className='mt-4'
                                                        required
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </>
                            )
                    }
                    <FormField
                        control={form.control}
                        name='phoneNumber'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <FloatingLabelInput
                                        type='number'
                                        label={t(
                                            'words_title.phone_number'
                                        )}
                                        className='mt-4'
                                        isTypingOnlyNumber={true}
                                        required
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='password'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <FloatingLabelInput
                                        type={!showPassword ? 'password' : 'text'}
                                        label={t(
                                            'words_title.password'
                                        )}
                                        className='mt-4'
                                        icon={
                                            !showPassword ?
                                                <EyeOffIcon className='icon-right-input' onClick={() => setShowPassword(!showPassword)} /> :
                                                <EyeIcon className='icon-right-input' onClick={() => setShowPassword(!showPassword)} />
                                        }
                                        isPreventCopy={true}
                                        isPreventCut={true}
                                        required
                                        {...field}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            form.getValues('confirmPassword') && form.trigger('confirmPassword');
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='confirmPassword'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <FloatingLabelInput
                                        type={!showConfirmPassword ? 'password' : 'text'}
                                        label={t(
                                            'words_title.confirm_password'
                                        )}
                                        className='mt-4'
                                        icon={
                                            !showConfirmPassword ?
                                                <EyeOffIcon className='icon-right-input' onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> :
                                                <EyeIcon className='icon-right-input' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                        }
                                        isPreventCopy={true}
                                        isPreventCut={true}
                                        required
                                        {...field}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            form.getValues('password') && form.trigger('password');
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name='provinceName'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Combobox
                                        value={field.value}
                                        onChange={(item) => {
                                            field.onChange(item?.value);
                                            form.setValue('provinceCode', item?.code);
                                        }}
                                        items={administrativeUnit?.provinces?.map((item, index) => (
                                            {
                                                label: language === LANGUAGES_SUPPORTED.English ? item.fullNameEn : item.fullName,
                                                value: item.name,
                                                code: item.code
                                            }
                                        ))}
                                        label={t('words_title.city')}
                                        required
                                        floatingLabel
                                        className='mt-4'
                                        side='top'
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <Button type='submit' variant='submit' size='submit' className='mt-6'>
                    {t('words_title.sign_up')}
                </Button>
                <div className='text-center text-[0.8125rem] mt-6'>
                    <span className='text-text-1'>{t('sentences.have_an_account')}</span>
                    {' '}
                    <Link to='/sign-in' state={{ previousUrl: ROUTES.signUp.href, userType }} className='link-1'>{t('words_title.sign_in_1')}</Link>
                </div>
            </form>
        </Form>
    );
});

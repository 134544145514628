import { useStore } from '@/hooks/useStore';
import ShopInformationTab from '@/pages/create-shop/tabs/ShopInformationTab';
import { INFO_TYPE } from '@/types/enums';
import { GetShopInfoResponse } from '@/types/http-payload/shop';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

export default observer(function ShopInfoTabEdit() {
    // store
    const { shopStore: { getShopInfo, shopInfo } } = useStore();

    // lifecycle
    useEffect(() => {
        getShopInfo({ typeInfo: INFO_TYPE.ShopInfo });
    }, []);

    return (
        <ShopInformationTab shopCategory={(shopInfo as GetShopInfoResponse) && (shopInfo as GetShopInfoResponse).category} />
    );
});

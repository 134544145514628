import ApiService from './ApiService';

export default class AppointmentApi extends ApiService {
    // #region appointment of my-shop
    getAllAppointments(payload) {
        return this.get('my-shop/appointments', payload);
    }

    getAppointmentDetail(code) {
        return this.get(`my-shop/appointments/${code}`);
    }

    updateAppointmentStatus(payload) {
        const { code, status } = payload;
        return this.put(`my-shop/appointments/status/${code}`, { status });
    }

    updateUserAppointmentInfor(payload) {
        const { code, data, type } = payload;
        return this.put(`my-shop/appointments/${type}/${code}`, data);
    }
    // #endregion

    // #region appointment for user
    createAppointment(payload) {
        const { slug, data } = payload;
        return this.post(`appointments/book/${slug}`, data);
    }

    getAllUserAppointments(payload) {
        return this.get('appointments', payload);
    }

    getUserAppointmentDetail(code) {
        return this.get(`appointments/${code}`);
    }

    updateUserAppointmentStatus(payload) {
        const { code, status } = payload;
        return this.put(`appointments/status/${code}`, { status });
    }
    // #endregion
}

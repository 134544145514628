import { Button } from '@/components/common/Button';
import Tooltip from '@/components/common/Tooltip';
import { DISPLAY_MOMENT_TIME_DMY, ROUTES, SHOP_SERVICE_NAME } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { LANGUAGES_SUPPORTED, SHOP_SERVICE_TYPE, SHOP_STATUS_TYPE } from '@/types/enums';
import { PetCareHistoryInfo, PetInfoResponse } from '@/types/http-payload/pet';
import { formatDateTime } from '@/utils/datetime';
import { formatNumber } from '@/utils/utils';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type Props = {
    data: PetCareHistoryInfo,
    petDetail: PetInfoResponse
};
export default observer(function ViewHistory({ data, petDetail }: Props) {
    const { code, datetime, shop, haveAppointment, totalAmount, services } = data;
    const isShopInactive = shop.status !== SHOP_STATUS_TYPE.Active || shop.displayStatus === false;

    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const {
        modalStore: { hideModal }
    } = useStore();

    return (
        <div className='flex flex-col gap-4 pb-2 font-medium'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-2'>
                <div className='flex items-start'>
                    <p className='min-w-28 text-tab-1'>{t('words_title.pet_name')}</p>
                    <p className='flex-1'>
                        {petDetail?.name}
                    </p>
                </div>
                <div className='flex items-start'>
                    <p className='min-w-28 point-600:min-w-0 text-tab-1 point-600:mr-2'>{t('words_title.breeds')}</p>
                    <p className='flex-1 overflow-hidden whitespace-nowrap text-ellipsis'>
                        {
                            typeof petDetail?.species === 'string' && petDetail?.species !== '' ?
                                petDetail?.species :
                                (petDetail?.species === '' ?
                                    t('words_title.pet_other') :
                                    (language === LANGUAGES_SUPPORTED.Vietnamese ?
                                        petDetail?.species?.name :
                                        petDetail?.species?.nameEn))
                        }
                    </p>
                </div>
            </div>
            <hr className='border border-background-2' />
            <div className='flex items-center'>
                <p className='min-w-28 text-tab-1'>{t('words_title.date')}</p>
                <p>
                    {formatDateTime(datetime, DISPLAY_MOMENT_TIME_DMY)}
                </p>
            </div>
            <div className='flex'>
                <p className='min-w-28 text-tab-1'>{t('words_title.shop_name')}</p>
                {
                    !haveAppointment && (
                        <Tooltip
                            content={!haveAppointment ? t('words_title.shop_is_not_available') : null}
                            triggerAsChild
                        >
                            <button
                                onClick={() => window.open(`${ROUTES.shopDetail.href}/${shop?.slug}`, '_blank')}
                                disabled={isShopInactive}
                                className={classNames(
                                    'line-clamp-2 pb-[1px] text-start',
                                    { 'hover:underline': !isShopInactive }
                                )}
                            >
                                {shop?.name}
                            </button>
                        </Tooltip>
                    )
                }
                {
                    haveAppointment && (
                        <Tooltip
                            content={isShopInactive ? t('words_title.shop_is_not_available') : null}
                            triggerAsChild
                        >
                            <button
                                onClick={(e) => {
                                    if (!isShopInactive) {
                                        window.open(`${ROUTES.shopDetail.href}/${shop?.slug}`, '_blank');
                                    }
                                    e.preventDefault();
                                }}
                                disabled={isShopInactive}
                                className={classNames(
                                    'pb-[1px] text-start',
                                    { 'hover:underline': !isShopInactive }
                                )}
                            >

                                {shop?.name}
                            </button>
                        </Tooltip>
                    )
                }
            </div>
            <div className='flex'>
                <p className='min-w-28 text-tab-1'>{t('words_title.services')}</p>
                {
                    !haveAppointment && (
                        <div className='flex flex-wrap gap-2'>
                            {
                                services?.map((service, index) => (
                                    <span
                                        key={index}
                                        className='font-normal rounded-xl bg-background-4 py-1 px-1.5'
                                    >
                                        {service.serviceName}
                                    </span>
                                ))
                            }
                        </div>
                    )
                }
                {
                    haveAppointment && (
                        <div className='flex flex-wrap gap-2'>
                            {
                                services?.map((service, index) => (
                                    <span
                                        key={index}
                                        className='font-normal rounded-xl bg-background-4 py-1 px-1.5'
                                    >
                                        {
                                            (service.type === SHOP_SERVICE_TYPE.Cat || service.type === SHOP_SERVICE_TYPE.Dog) ?
                                                t(`select_options.${SHOP_SERVICE_NAME[service.serviceName]?.key}`) :
                                                service?.serviceName
                                        }

                                    </span>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            <hr className='border border-background-2' />
            <div className='flex items-center'>
                <p className='min-w-28 text-tab-1'>{t('words_title.total_amount')}</p>
                {totalAmount !== undefined && (
                    <p>
                        {`${formatNumber(totalAmount)} VND`}
                    </p>
                )}
            </div>
            <div className='flex justify-center mt-3'>
                <Button onClick={() => hideModal()} type='button' className='w-full border-border-9 lg:w-1/2' variant='outline'>{t('button.close')}</Button>
            </div>
        </div>
    );
});

import './services/i18n';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingApi from './components/loading/LoadingApi';
import { ToastContainer } from 'react-toastify';
import Modals from './components/modal/Modals';
import { useStore } from './hooks/useStore';
import { getStorage } from './utils/browsers';
import { DISPLAY_MOMENT_DATE_YMD, HIDE_SCROLL_TOP_LST_FOR_PO, HIDE_SCROLL_TOP_LST_FOR_SO, HIDE_SCROLL_TOP_SCREEN } from './configs/constants';
import { observer } from 'mobx-react-lite';
import ScrollToTop from './components/general/ScrollToTop';
import useWindowSize from './hooks/useWindowSize';
import { formatDateTime } from './utils/datetime';
import { scrollToTop } from './utils/utils';
import { io } from 'socket.io-client';
import { URL_CONFIG } from './configs/environment';
import { SOCKET_EVENT_TYPE } from './types/enums';

import '/node_modules/flag-icons/css/flag-icons.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/App.scss';

export default observer(function App() {
    // hooks
    useWindowSize();
    const { i18n } = useTranslation();
    const location = useLocation();

    // store
    const { generalStore: { getAdministrativeUnit } } = useStore();

    // store
    const {
        authStore: { token, getProfile, channel, isChannelShopOwner },
        notificationStore: { countNewNotification },
        chatStore: { countUnreadChat }
    } = useStore();

    // lifecycle
    useEffect(() => {
        // get administrative unit
        const getAdministrativeDate = getStorage('getAdministrativeDate');
        if (!getAdministrativeDate || (getAdministrativeDate !== formatDateTime(new Date(), DISPLAY_MOMENT_DATE_YMD))) {
            getAdministrativeUnit();
        }

        // Set initial language
        handleLanguageChange(i18n.language);

        // Listen for language changes
        i18n.on('languageChanged', handleLanguageChange);

        // Clean up listener on unmount
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    useEffect(() => {
        if (token) {
            getProfile();
        }
    }, [token]);

    useEffect(() => {
        scrollToTop();
    }, [location.pathname]);

    useEffect(() => {
        if (token) {
            const socketConnectionInit = io(`${URL_CONFIG.SOCKET.url}/${URL_CONFIG.SOCKET.notificationNamespace}`, {
                reconnection: true,
                reconnectionAttempts: 5,
                reconnectionDelay: 10000,
                reconnectionDelayMax: 30000,
                timeout: 60000
            });

            if (socketConnectionInit) {
                socketConnectionInit.on(SOCKET_EVENT_TYPE.Connect, () => {
                    socketConnectionInit.emit(SOCKET_EVENT_TYPE.Connected, { token, channel });
                });

                socketConnectionInit.on(SOCKET_EVENT_TYPE.NewNotification, () => {
                    countNewNotification();
                });

                socketConnectionInit.on(SOCKET_EVENT_TYPE.NewMessage, () => {
                    countUnreadChat({ channel });
                });

                socketConnectionInit.on(SOCKET_EVENT_TYPE.SeenMessage, () => {
                    countUnreadChat({ channel });
                });
            }

            return () => {
                socketConnectionInit.disconnect();
            };
        }
    }, [token]);

    // function
    const handleLanguageChange = (lng: string) => {
        document.documentElement.lang = lng;
    };

    const isShowScrollToTop = useMemo(() => {
        const pathname = location.pathname;
        if (HIDE_SCROLL_TOP_SCREEN.some(path => pathname.includes(path))) {
            return false;
        }

        if ((isChannelShopOwner && !HIDE_SCROLL_TOP_LST_FOR_SO.includes(pathname)) || (!isChannelShopOwner && !HIDE_SCROLL_TOP_LST_FOR_PO.includes(pathname))) {
            return true;
        }
        return false;
    }, [location.pathname]);

    return (
        <div className='app'>
            <Outlet />
            <LoadingApi />
            <ToastContainer />
            <Modals />
            {
                isShowScrollToTop && <ScrollToTop />
            }
        </div>
    );
});

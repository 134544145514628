import { Button } from '@/components/common/Button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import yup from '@/services/yup';
import { loadFilePreview, loadURLPreview, saveLocalStorage } from '@/utils/browsers';
import { checkImageSupportFormat, cn, getFileInfo, getFileNameFromUrl, scrollToTop } from '@/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircleHelpIcon, ImagePlusIcon, UploadIcon, XIcon } from 'lucide-react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, UseFieldArrayReturn, useForm, UseFormReturn } from 'react-hook-form';
import CircleAddIcon from '@/assets/images/circleAddIcon.svg';
import { Textarea } from '@/components/common/Textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { FILE_SIZE_MENU, LIMIT_IMAGES_UPLOAD, MAX_PRICE_SERVICE, PET_WEIGHT_OPTIONS, ROUTES, SHOP_SERVICE, SHOP_SERVICE_NAME, SUPPORTED_FORMATS, SUPPORTED_FORMATS_MENU_UPLOAD } from '@/configs/constants';
import { ENDUSER_TYPE, INFO_TYPE, SHOP_CATEGORY_TYPE, SHOP_SERVICE_NAME_TYPE, SHOP_SERVICE_TYPE, SHOP_STATUS_TYPE } from '@/types/enums';
import FormatNumber from '@/components/react-number-format';
import { useStore } from '@/hooks/useStore';
import { flowResult } from 'mobx';
import { useNavigate } from 'react-router-dom';
import { GetShopInfoResponse } from '@/types/http-payload/shop';
import { Checkbox } from '@/components/common/Checkbox';
import { Carousel, CarouselApi, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/common/Carousel';
import { FileUpload } from '@/types/general';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { toastify } from '@/utils/toastify';
import debounce from 'lodash.debounce';
import DisabledButton from '@/pages/my-shop/components/DisabledButton';
import Tooltip from '@/components/common/Tooltip';

// validate scheme
const validateSchema = yup.object().shape({
    images: yup
        .array()
        .of(yup.mixed<string | FileUpload>().required('form_error_validate.required'))
        .checkFileImagesOfArrayExt('form_error_validate.shop_image_upload_format')
        .min(1, 'form_error_validate.required')
        .max(LIMIT_IMAGES_UPLOAD, 'form_error_validate.shop_image_upload_number')
        .required('form_error_validate.required'),
    introduction: yup
        .string()
        .required('form_error_validate.required'),
    facebook: yup
        .string(),
    youtube: yup
        .string(),
    instagram: yup
        .string(),
    tiktok: yup
        .string(),
    menuFile: yup
        .mixed<string | File>()
        .checkFileImagesOfExt('form_error_validate.shop_menu_upload_format')
        .nullable(),
    services: yup.array().of(
        yup.object().shape({
            type: yup.number().oneOf(Object.values(SHOP_SERVICE_TYPE)).required(),
            serviceName: yup
                .string()
                .when('type', {
                    is: type => type === SHOP_SERVICE_TYPE.Other || type === SHOP_SERVICE_TYPE.Medical,
                    then: _ => yup.string().max(255).required('form_error_validate.required'),
                    otherwise: _ => yup
                        .string()
                        .oneOf(Object.values(SHOP_SERVICE_NAME_TYPE).map(item => String(item)), 'form_error_validate.required')
                        .required('form_error_validate.required')
                }),
            petWeightFrom: yup
                .string()
                .when('type', {
                    is: type => type === SHOP_SERVICE_TYPE.Other || type === SHOP_SERVICE_TYPE.Medical,
                    then: _ => yup.string().nullable().optional(),
                    otherwise: _ => yup.string().required('form_error_validate.required')
                }),
            petWeightTo: yup
                .string()
                .when('type', {
                    is: type => type === SHOP_SERVICE_TYPE.Other || type === SHOP_SERVICE_TYPE.Medical,
                    then: _ => yup.string().nullable().optional(),
                    otherwise: _ => yup.string().required('form_error_validate.required')
                }),
            isMedicalService: yup
                .boolean()
                .when('type', {
                    is: SHOP_SERVICE_TYPE.Other,
                    then: _ => yup.boolean().required(),
                    otherwise: _ => yup.boolean().nullable().optional()
                }),
            price: yup.lazy((value) => {
                if (value === '') {
                    return yup
                        .string()
                        .when('type', {
                            is: type => type === SHOP_SERVICE_TYPE.Other || type === SHOP_SERVICE_TYPE.Medical,
                            then: _ => yup.string().nullable().optional(),
                            otherwise: _ => yup.string().required('form_error_validate.required')
                        });
                }

                return yup
                    .number()
                    .positive()
                    .max(MAX_PRICE_SERVICE)
                    .when('type', {
                        is: type => type === SHOP_SERVICE_TYPE.Other || type === SHOP_SERVICE_TYPE.Medical,
                        then: _ => yup.string().nullable().optional(),
                        otherwise: _ => yup.string().required('form_error_validate.required')
                    });
            }),
            code: yup.string().optional().nullable()
        })
    ).required('form_error_validate.required')
});

type FormData = yup.InferType<typeof validateSchema>;

interface ShopInformationTabProps {
    shopCategory?: number
}

export default observer(function ShopInformationTab({ shopCategory }: ShopInformationTabProps) {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();

    const initialValues = {
        images: [],
        introduction: '',
        facebook: '',
        youtube: '',
        instagram: '',
        tiktok: '',
        menuFile: '',
        services: []
    };

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: initialValues
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'services'
    });

    const watchImages = form.watch('images');
    const watchMenuFile = form.watch('menuFile');

    // store
    const {
        modalStore: { showAlertModal, hideModal },
        shopStore: { shopInfo, updateShopInfo, requestApprove, getShopInfo, setObservable, shopCode, isEditMode },
        fileStore: { uploadFile },
        authStore: { isChannelShopOwner, setObservable: setObservableAuth },
        uiStore: { windowSize: { width } }
    } = useStore();

    // state
    const [imagesLoaded, setImagesLoaded] = useState<{
        id: string,
        src: string,
        isFile: boolean
    }[]>([]);
    const [api, setApi] = useState<CarouselApi>();
    const [currentImage, setCurrentImage] = useState(0);
    const [isOpenTooltipHint, setIsOpenTooltipHine] = useState<boolean>(false);
    const labelImagesRef = useRef<HTMLLabelElement>(null);
    const imagesLoadedRef = useRef(imagesLoaded);

    const serviceList = useMemo(() => {
        return Object.keys(SHOP_SERVICE).filter(item => item !== SHOP_SERVICE_TYPE.Additional.toString()).filter((shopServiceType) => {
            if (shopCategory && shopCategory === SHOP_CATEGORY_TYPE.PetShop) {
                return shopServiceType !== SHOP_SERVICE_TYPE.Medical.toString();
            }
            if (shopCategory && shopCategory === SHOP_CATEGORY_TYPE.PetHospital) {
                return shopServiceType === SHOP_SERVICE_TYPE.Medical.toString() || shopServiceType === SHOP_SERVICE_TYPE.Other.toString();
            }
            return true;
        });
    }, [shopCategory]);

    // life cycle
    useEffect(() => {
        if (watchImages?.length > 0) {
            if (watchImages instanceof Array) {
                watchImages.forEach((image, index) => {
                    const imageUploaded = imagesLoaded.find((e) => {
                        if (typeof image === 'string') return e.id === image;
                        return e.id === image.id;
                    });
                    if (imageUploaded) {
                        loadURLPreview(imageUploaded.src, imageUploaded.id);
                    } else {
                        if (typeof image === 'string') {
                            loadURLPreview(image, image);
                            setImagesLoaded((prevState) => {
                                if (!prevState.find(e => e.id === image)) {
                                    const newState = [...prevState, {
                                        id: image,
                                        src: image,
                                        isFile: false
                                    }];
                                    imagesLoadedRef.current = newState;
                                    return newState;
                                }
                                return prevState;
                            });
                        } else {
                            loadFilePreview(image.file, image.id, {
                                nonFreeMemory: true,
                                callbackLoadImage: ({ src }) => {
                                    setImagesLoaded((prevState) => {
                                        if (!prevState.find(e => e.id === image.id)) {
                                            const newState = [...prevState, {
                                                id: image.id,
                                                src,
                                                isFile: true
                                            }];
                                            imagesLoadedRef.current = newState;
                                            return newState;
                                        }
                                        return prevState;
                                    });
                                }
                            });
                        }
                    }
                });
            }
        }
    }, [watchImages]);

    useEffect(() => {
        if (isChannelShopOwner) {
            fetchData();
        }
    }, [isChannelShopOwner]);

    useEffect(() => {
        if (!api) {
            return;
        }

        setCurrentImage(api.selectedScrollSnap() + 1);

        api.on('select', () => {
            setCurrentImage(api.selectedScrollSnap() + 1);
        });
    }, [api]);

    useEffect(() => {
        if (form.formState.errors.images && labelImagesRef.current) {
            labelImagesRef.current.focus();
        }
    }, [form.formState.errors]);

    useEffect(() => {
        return () => {
            if (isEditMode) {
                freeAllImagesMemory();
            }
        };
    }, [isEditMode]);

    // function
    const fetchData = async () => {
        const res = await flowResult(getShopInfo({ typeInfo: INFO_TYPE.ShopInfo }));
        if (res?.ok && (res.data as GetShopInfoResponse)) {
            const { images, introduction, facebook, youtube, instagram, tiktok, menuFile, services } = res.data as GetShopInfoResponse;
            form.reset({
                images: images ?? '',
                introduction: introduction ?? '',
                facebook: facebook ?? '',
                youtube: youtube ?? '',
                instagram: instagram ?? '',
                tiktok: tiktok ?? '',
                menuFile: menuFile ?? null,
                services: services ?? ''
            });
        }
    };

    const onSubmit = async (data: Omit<FormData, 'images'> & { images: any[] }) => {
        const { images, introduction, facebook, youtube, instagram, tiktok, menuFile, services } = data;

        if (services.length <= 0) {
            showAlertErrorAtLeastService();
            return;
        }

        const uploadImage: File[] = images.filter(image => typeof image !== 'string').map(image => image.file);
        const oldImage: string[] = images.filter(image => typeof image === 'string').map(item => getFileNameFromUrl(item));
        const getPresignedUrls = {
            images: uploadImage.length > 0 ?
                uploadImage.map(file => getFileInfo(file)[1]) :
                false,
            menuFile: menuFile instanceof File ?
                {
                    extname: getFileInfo(menuFile)[1],
                    filename: getFileInfo(menuFile)[0]
                } :
                false
        };

        let resultGetPresignedUrl;
        if (uploadImage.length > 0 || menuFile instanceof File) {
            resultGetPresignedUrl = await flowResult(updateShopInfo({
                getPresignedUrls, introduction, facebook, youtube, instagram, tiktok, services, shopCode
            }));
        } else {
            const res = await flowResult(updateShopInfo({
                images: images.map(image => getFileNameFromUrl(image)),
                menuFile: menuFile ? getFileNameFromUrl(menuFile) : '',
                introduction, facebook, youtube, instagram, tiktok, services, shopCode
            }));
            if (res) {
                if (shopInfo?.status === SHOP_STATUS_TYPE.NotCompletedInfo || !isChannelShopOwner) {
                    const resApprove = await flowResult(requestApprove({}));
                    if (resApprove) {
                        setObservable('shopInfo', null);
                        navigate(ROUTES.home.href);
                    }
                } else {
                    setObservable('isEditMode', false);
                    toastify('alert-success', t('messages.update_success'));
                    scrollToTop();
                }
            }
        }

        if (resultGetPresignedUrl) {
            const { presignedUrls } = resultGetPresignedUrl.data;
            const filesUpload: any[] = [];

            if (presignedUrls?.images && presignedUrls.images.length > 0) {
                presignedUrls.images.map((item, index) => {
                    filesUpload.push({
                        id: `image_${item.fields.key}`,
                        fileType: getPresignedUrls.images[index],
                        file: uploadImage[index],
                        presignedUrl: item
                    });
                });
            }

            if (presignedUrls?.menuFile) {
                filesUpload.push({
                    id: `image_${presignedUrls?.menuFile.fields.key}`,
                    fileType: getPresignedUrls.menuFile,
                    file: menuFile,
                    presignedUrl: presignedUrls?.menuFile
                });
            }

            if (filesUpload.length > 0) {
                handleUploadFile(filesUpload, () => handleUpdateShopInfo(presignedUrls, oldImage));
            }
        }
    };

    const handleUpdateShopInfo = async (presignedUrls, oldImage) => {
        const { introduction, facebook, youtube, instagram, tiktok, services, menuFile } = form.getValues();
        const newImages = presignedUrls?.images && presignedUrls.images.length > 0 ? presignedUrls?.images?.map(item => item.fileName) : [];

        const res = await flowResult(updateShopInfo({
            images: [...oldImage, ...newImages],
            menuFile: presignedUrls.menuFile.fileName ?? getFileNameFromUrl(menuFile),
            introduction, facebook, youtube, instagram, tiktok, services, shopCode
        }));
        if (res) {
            if (shopInfo?.status === SHOP_STATUS_TYPE.NotCompletedInfo || !isChannelShopOwner) {
                const resApprove = await flowResult(requestApprove(isChannelShopOwner ? {} : { shopCode }));
                if (resApprove) (
                    showAlertModal({
                        type: 'success',
                        size: 'xl',
                        content: modalApprovelSuccessContent(),
                        variantSaveBtn: 'default',
                        saveButtonClassName: 'w-64',
                        cancelButtonClassName: 'w-64',
                        saveButton: !isChannelShopOwner ? t('button.go_to_shop') : t('button.view_shop_info'),
                        cancelButton: !isChannelShopOwner ? t('button.back_to_home') : undefined,
                        footerClassName: 'flex items-center sm:justify-around w-full mt-5',
                        onSave: () => {
                            if (isChannelShopOwner) {
                                setObservable('isEditMode', false);
                                navigate(ROUTES.myShop.href);
                            } else {
                                setObservableAuth('token', null);
                                saveLocalStorage('signInUserType', ENDUSER_TYPE.ShopOwner.toString());
                                navigate(ROUTES.signIn.href);
                            }
                        },
                        onCancel: () => navigate(ROUTES.home.href)
                    })
                );
            } else {
                setObservable('isEditMode', false);
                toastify('alert-success', t('messages.update_success'));
                scrollToTop();
            }
        }
    };

    const handleUploadFile = async (fileUploadList, uploadSuccessCallback) => {
        const uploadPromises = fileUploadList.map((fileUpload: any) => {
            const { bucket, ...payload } = fileUpload.presignedUrl?.fields;
            const data = {
                ...(payload || {}),
                file: fileUpload.file
            };

            return new Promise((resolve, reject) => {
                uploadFile(fileUpload.presignedUrl?.url, data, {
                    onSuccess: () => {
                        resolve(true);
                    },
                    onError: () => {
                        fileUpload.onError();
                        reject(false);
                    }
                });
            });
        });

        try {
            const results = await Promise.all(uploadPromises);

            const allSuccessful = results.every(result => result === true);

            if (allSuccessful) {
                uploadSuccessCallback();
            } else {
                showAlertModal({
                    type: 'error',
                    content: t('messages.api_response_no_message')
                });
            }
        } catch (error) {
            console.error('Error during file upload:', error);
        }
    };

    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            if (!checkImageSupportFormat(files)) {
                showAlertErrorFormatSupport();
            } else if (watchImages && watchImages.length + files.length > LIMIT_IMAGES_UPLOAD) {
                showAlertErrorReachLimitUpload();
            } else {
                form.setValue('images', [...(watchImages || []), ...(files instanceof FileList ?
                    Array.from(files).map((file, index) => ({
                        file,
                        id: `${file.name}_${Date.now()}_${index}`
                    })) :
                    [])], { shouldValidate: true, shouldDirty: true });
            }
        }
        e.target.value = '';
    };

    const handleMenuFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData = e.target.files;
        if (newData) {
            if (SUPPORTED_FORMATS_MENU_UPLOAD.includes(newData[0].type) && newData[0].size <= FILE_SIZE_MENU) {
                form.setValue('menuFile', newData[0], { shouldDirty: true });
                form.trigger(['menuFile']);
            } else {
                showAlertErrorMenuFormatSupport();
            }
        }
        e.target.value = '';
    };

    const handleRomoveImg = (index: number) => {
        if (watchImages?.length > 0) {
            const removeImage = watchImages[index];

            if (typeof removeImage !== 'string') {
                const imageUploaded = imagesLoaded.find(e => e.id === removeImage.id);
                if (imageUploaded) {
                    // free memory
                    URL.revokeObjectURL(imageUploaded.src);
                }
            }

            form.setValue('images', [
                ...watchImages.slice(0, index),
                ...watchImages.slice(index + 1)
            ], { shouldValidate: true, shouldDirty: true });
        }
    };

    const freeAllImagesMemory = () => {
        imagesLoadedRef.current.forEach((image) => {
            if (image.isFile) {
                // free memory
                URL.revokeObjectURL(image.src);
            }
        });
    };

    const handleRemoveMenuFile = () => {
        form.setValue('menuFile', null, { shouldDirty: true });
    };

    const showAlertErrorFormatSupport = () => {
        showAlertModal({
            type: 'error',
            content: t('form_error_validate.shop_image_upload_format'),
            cancelButton: false,
            saveButton: t('button.close'),
            onSave: () => hideModal()
        });
    };

    const showAlertErrorMenuFormatSupport = () => {
        showAlertModal({
            type: 'error',
            content: t('form_error_validate.shop_menu_upload_format'),
            cancelButton: false,
            saveButton: t('button.close'),
            onSave: () => hideModal()
        });
    };

    const showAlertErrorReachLimitUpload = () => {
        showAlertModal({
            type: 'error',
            content: t('form_error_validate.shop_image_upload_limit'),
            cancelButton: false,
            saveButton: t('button.close'),
            onSave: () => hideModal()
        });
    };

    const showAlertErrorAtLeastService = () => {
        showAlertModal({
            type: 'error',
            content: t('form_error_validate.services_at_least'),
            cancelButton: false,
            saveButton: t('button.close'),
            onSave: () => hideModal()
        });
    };

    const handleClickReAppoval = debounce(async () => {
        const resApprove = await flowResult(requestApprove({}, true));
        if (resApprove) {
            setObservable('shopInfo', null);
            navigate(ROUTES.home.href);
        };
    }, 1000, {
        leading: true,
        trailing: false
    });

    const modalApprovelSuccessContent = () => {
        return (
            <div className='text-center'>
                <div className='font-bold'>{t('sentences.send_approval_success')}</div>
                <div className='mt-4'>{t('sentences.send_approval_success_desc')}</div>
            </div>
        );
    };

    const handleScrollPrevious = () => {
        const prevIndex = Math.max(currentImage - (width >= 768 ? 8 : (width >= 640 ? 4 : 2)) - 1, 0);
        setCurrentImage(prevIndex);
        api?.scrollTo(prevIndex);
    };

    const handleScrollNext = () => {
        const nextIndex = Math.min(currentImage + (width >= 768 ? 8 : (width >= 640 ? 4 : 2)) - 1, watchImages.length - 1);
        setCurrentImage(nextIndex);
        api?.scrollTo(nextIndex);
    };

    const indexCarousel = (index) => {
        const tempIndex = index + (width >= 768 ? 7 : (width >= 640 ? 3 : 1));
        if (tempIndex > watchImages.length) {
            return watchImages.length;
        } else {
            return tempIndex;
        }
    };

    const handleOpenTooltipHintChange = (open) => {
        if (width < 920) {
            setIsOpenTooltipHine(isOpenTooltipHint ? !isOpenTooltipHint : open);
        } else {
            setIsOpenTooltipHine(open);
        }
    };

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className={classNames(isEditMode ? 'mt-4' : 'mt-6')}>
                    <div className={classNames('bg-white rounded p-4', !isEditMode && 'mt-4')}>
                        <FormField
                            control={form.control}
                            name='images'
                            render={({ field: { onChange, value, ...rest } }) => (
                                <>
                                    <FormItem>
                                        <Label className='label-1' required>{t('words_title.shop_images')}</Label>
                                        <FormDescription className='text-[0.8125rem] leading-[1.125rem] text-text-4'>{t('sentences.upload_images_shop_desc_1')}</FormDescription>
                                        <FormDescription className='text-[0.8125rem] leading-[1.125rem] text-text-4 pb-1.5'>{t('sentences.upload_images_shop_desc_2')}</FormDescription>
                                        <FormControl>
                                            <Input
                                                id='shop-images'
                                                type='file'
                                                onChange={(event) => {
                                                    handleImageFileChange(event);
                                                }}
                                                className='hidden'
                                                accept={SUPPORTED_FORMATS.join(', ')}
                                                multiple
                                                {...rest}
                                            />
                                        </FormControl>
                                        <Label
                                            ref={labelImagesRef}
                                            htmlFor='shop-images'
                                            tabIndex={0}
                                            className={cn('flex items-center justify-center cursor-pointer border border-input rounded-lg py-6')}
                                        >
                                            <ImagePlusIcon className='text-active h-6 w-6 mr-2' />
                                            <span className='text-placeholder-1 font-medium leading-4'>{t('button.add_shop_image')}</span>
                                        </Label>
                                        <FormMessage />
                                    </FormItem>
                                    <Carousel
                                        setApi={setApi}
                                        opts={{
                                            align: 'start'
                                        }}
                                        className='w-full mt-4'
                                    >
                                        <CarouselContent>
                                            {
                                                watchImages?.length > 0 &&
                                                Array.from(watchImages).map((image, index) => (
                                                    <CarouselItem className='relative md:w-[12.5%] md:basis-[12.5%] sm:w-1/4 sm:basis-1/4 w-1/2 basis-1/2' key={index}>
                                                        <img
                                                            className='w-full h-20 object-cover rounded'
                                                            id={typeof image === 'string' ? image : image.id}
                                                            alt=''
                                                        />
                                                        <button
                                                            type='button'
                                                            onClick={() => handleRomoveImg(index)}
                                                            className='flex justify-center items-center absolute top-1 left-5 bg-black bg-opacity-80 rounded-full p-1 shadow-lg w-4.5 h-4.5'
                                                        >
                                                            <XIcon className='w-3.5 h-3.5 text-white' />
                                                        </button>
                                                    </CarouselItem>
                                                ))
                                            }
                                        </CarouselContent>
                                        <div className='flex justify-center items-center gap-4 mt-4' role='group'>
                                            <CarouselPrevious className='static translate-y-0 w-5 h-5' iconHoverClass='hover:text-white' onClick={handleScrollPrevious} />
                                            <span>
                                                {
                                                    `${t('words_title.image_upload')} ${watchImages.length > 0 ?
                                                        (
                                                            indexCarousel(currentImage)
                                                        ) :
                                                        0
                                                    }/${watchImages.length}`
                                                }
                                            </span>
                                            <CarouselNext className='static translate-y-0 w-5 h-5' iconHoverClass='hover:text-white' onClick={handleScrollNext} />
                                        </div>
                                    </Carousel>
                                </>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='introduction'
                            render={({ field }) => (
                                <FormItem className='flex flex-col mt-4 w-full'>
                                    <div className='flex items-center gap-2'>
                                        <Label className='label-1' required>{t('words_title.shop_introduction')}</Label>
                                        <Tooltip
                                            side={width < 920 ? 'bottom' : 'right'}
                                            align={width < 920 ? 'center' : 'start'}
                                            alignOffset={16}
                                            sideOffset={8}
                                            open={isOpenTooltipHint}
                                            onOpenChange={handleOpenTooltipHintChange}
                                            content={t('sentences.hint_register_shop')}
                                            contentClassName='w-full max-w-[calc(100vw_-_2rem)] mx-4 point-920:mx-0 xl:max-w-[600px] point-920:max-w-[480px] text-text-25 text-[0.8125rem]'
                                            triggerType='button'
                                            triggerAsChild
                                            arrow={width < 920}
                                            arrowHeight={10}
                                            arrowWidth={20}
                                        >
                                            <CircleHelpIcon className='text-active' onClick={handleOpenTooltipHintChange} />
                                        </Tooltip>
                                    </div>
                                    <FormControl className='mt-1.5'>
                                        <Textarea maxLength={1000} placeholder={t('placeholder.st_about_shop')} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className='flex md:flex-row flex-col md:gap-4 gap-0'>
                            <FormField
                                control={form.control}
                                name='facebook'
                                render={({ field }) => (
                                    <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                        <Label className='label-1'>{t('words_title.facebook')}</Label>
                                        <FormControl className='mt-1.5'>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name='youtube'
                                render={({ field }) => (
                                    <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                        <Label className='label-1'>{t('words_title.youtube')}</Label>
                                        <FormControl className='mt-1.5'>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='flex md:flex-row flex-col md:gap-4 gap-0'>
                            <FormField
                                control={form.control}
                                name='instagram'
                                render={({ field }) => (
                                    <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                        <Label className='label-1'>{t('words_title.instagram')}</Label>
                                        <FormControl className='mt-1.5'>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name='tiktok'
                                render={({ field }) => (
                                    <FormItem className='flex flex-col mt-4 md:w-1/2 w-full'>
                                        <Label className='label-1'>{t('words_title.tiktok')}</Label>
                                        <FormControl className='mt-1.5'>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className='bg-white mt-4 rounded p-4'>
                        <FormField
                            control={form.control}
                            name='menuFile'
                            render={({ field: { onChange, value, ...rest } }) => (
                                <FormItem>
                                    <Label className='label-1'>{t('words_title.upload_menu')}</Label>
                                    <FormDescription className='text-[0.8125rem] leading-[1.125rem] text-text-4'>{t('sentences.upload_menu_shop_desc_1')}</FormDescription>
                                    <FormDescription className='text-[0.8125rem] leading-[1.125rem] text-text-4 pb-1.5'>{t('sentences.upload_menu_shop_desc_2')}</FormDescription>
                                    <FormControl>
                                        <Input
                                            id='menu-upload'
                                            type='file'
                                            className='hidden'
                                            onChange={(event) => {
                                                handleMenuFileChange(event);
                                            }}
                                            accept={SUPPORTED_FORMATS_MENU_UPLOAD.join(', ')}
                                            {...rest}
                                        />
                                    </FormControl>
                                    <Label htmlFor='menu-upload' className='flex items-center justify-center cursor-pointer border border-input rounded-lg py-3'>
                                        <UploadIcon className='text-black h-6 w-6 mr-2' />
                                        <span className='text-placeholder-1 font-medium leading-4'>{t('button.upload_shop_menu')}</span>
                                    </Label>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {
                            watchMenuFile && (
                                <div className='border border-input rounded-lg px-2 py-3 flex items-center justify-between mt-2 bg-background-2'>
                                    <span>{watchMenuFile instanceof File ? watchMenuFile.name : watchMenuFile.substring(watchMenuFile.lastIndexOf('/') + 1)}</span>
                                    <button
                                        type='button'
                                        onClick={handleRemoveMenuFile}
                                        className='flex justify-center items-center bg-placeholder-1 bg-opacity-80 rounded-full p-1 shadow-lg w-4.5 h-4.5'
                                    >
                                        <XIcon className='w-3.5 h-3.5 text-white' />
                                    </button>
                                </div>
                            )
                        }
                        {
                            serviceList.map((shopServiceType, index) => (
                                <ShopServiceItem
                                    key={index}
                                    title={t(`select_options.${SHOP_SERVICE[Number(shopServiceType)].key}`)}
                                    form={{
                                        control: form.control,
                                        fields,
                                        append,
                                        remove
                                    }}
                                    t={t}
                                    serviceType={Number(shopServiceType)}
                                    serviceData={fields?.length > 0 ?
                                        fields.map((service) => {
                                            if (service.type.toString() === shopServiceType) return service;
                                            return null;
                                        }) :
                                        []}
                                />
                            ))
                        }
                    </div>
                    <div className={classNames('flex md:flex-row flex-col-reverse items-center md:px-0 px-4 mt-4 md:mb-0 mb-4', shopInfo?.status === SHOP_STATUS_TYPE.Active ? 'justify-center md:gap-6 gap-4' : 'justify-between md:gap-0 gap-4')}>
                        <Button
                            variant='clear'
                            type='button'
                            className='px-4 py-2 md:w-fit w-full md:min-w-44'
                            onClick={() => {
                                if (shopInfo?.status === SHOP_STATUS_TYPE.Active) {
                                    setObservable('isEditMode', false);
                                    scrollToTop();
                                } else {
                                    navigate(ROUTES.home.href);
                                }
                            }}
                        >
                            {shopInfo?.status === SHOP_STATUS_TYPE.Active ? t('button.cancel') : t('button.back_to_home')}
                        </Button>
                        <div className='flex flex-row justify-center items-center gap-6 md:w-fit w-full'>
                            {
                                shopInfo?.status === SHOP_STATUS_TYPE.DeniedApprove && (
                                    <DisabledButton
                                        text={t('button.re_apporval')}
                                        disabled={form.formState.isDirty}
                                        onClick={handleClickReAppoval}
                                        buttonClassname='px-4 py-2'
                                        tooltipTriggerClassname='md:w-fit w-1/2'
                                    />
                                )
                            }
                            <Button type='submit' variant='submit' size='submit' disabled={form.formState.isSubmitting} className={cn('px-4 py-2 md:min-w-44', shopInfo?.status === SHOP_STATUS_TYPE.DeniedApprove ? 'w-1/2' : 'w-full')}>
                                {shopInfo?.status === SHOP_STATUS_TYPE.NotCompletedInfo || !isChannelShopOwner ? t('button.save_and_approval') : t('button.save')}
                            </Button>
                        </div>
                    </div>
                </form>
            </Form>
        </>
    );
});

interface ShopServiceItemProps {
    title: string,
    form: Pick<UseFieldArrayReturn<FormData, 'services'>, 'fields' | 'append' | 'remove'> & Pick<UseFormReturn<FormData>, 'control'>,
    t: TFunction<'translation', undefined>,
    serviceData: Array<any>,
    serviceType: number
}

const ShopServiceItem = memo(({ title, form, t, serviceData, serviceType }: ShopServiceItemProps) => {
    // function
    const handleAddService = () => {
        form.append({
            serviceName: '',
            petWeightFrom: '',
            petWeightTo: '',
            type: Number(serviceType),
            price: '',
            isMedicalService: false
        });
    };

    return (
        <div className='mt-4'>
            <div className='border-t border-solid border-[#E8E8E8]'></div>
            <div className='text-active font-bold leading-[1.125rem] text-[0.9375rem] pt-4'>{title}</div>
            {
                serviceData && serviceData.length > 0 && serviceData.map((item, index) => (
                    item && (
                        <ShopServiceRow
                            key={item.id}
                            form={form}
                            t={t}
                            index={index}
                            serviceType={serviceType}
                        />
                    )
                ))
            }
            <div className='flex justify-center mt-4'>
                <Button type='button' onClick={handleAddService} variant='add'>
                    <img src={CircleAddIcon} alt='' className='mr-1 w-5 h-5' />
                    <span>{t('button.add_shop_service')}</span>
                </Button>
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.serviceData) === JSON.stringify(nextProps.serviceData) &&
        prevProps.serviceType === nextProps.serviceType &&
        prevProps.title === nextProps.title;
});

ShopServiceItem.displayName = 'ShopServiceItem';

interface ShopServiceRowProps {
    form: Pick<UseFieldArrayReturn<FormData, 'services'>, 'fields' | 'append' | 'remove'> & Pick<UseFormReturn<FormData>, 'control'>,
    t: TFunction<'translation', undefined>,
    index: number,
    serviceType: number
}

const ShopServiceRow = ({ form, t, index, serviceType }: ShopServiceRowProps) => {
    // function
    const handleRemoveService = () => {
        form.remove(index);
    };

    return (
        <div className='flex md:flex-row items-start flex-col md:gap-4 gap-0 relative border-t border-dotted border-[#E8E8E8] md:mt-4 mt-6'>
            {
                (serviceType === SHOP_SERVICE_TYPE.Other || serviceType === SHOP_SERVICE_TYPE.Medical) ?
                    (
                        <>
                            <div className='md:w-1/2 w-full'>
                                <FormField
                                    control={form.control}
                                    name={`services.${index}.serviceName`}
                                    render={({ field }) => (
                                        <FormItem className='flex flex-col mt-4 w-full'>
                                            <Label className='label-1' required>{t('words_title.service_name')}</Label>
                                            <FormControl className='mt-1.5'>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className={cn('md:w-1/2 w-full', serviceType === SHOP_SERVICE_TYPE.Other && 'md:w-1/4 w-full')}>
                                <FormField
                                    control={form.control}
                                    name={`services.${index}.price`}
                                    render={({ field }) => (
                                        <FormItem className='flex flex-col mt-4 w-full'>
                                            <Label className='label-1'>{t('words_title.service_price')}</Label>
                                            <FormControl>
                                                <FormatNumber
                                                    minValue={1}
                                                    maxValue={MAX_PRICE_SERVICE}
                                                    className='mt-1.5'
                                                    decimalScale={0}
                                                    statusDisplay='input'
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    value={field.value}
                                                    setvalueOfNumericFormat={(values) => {
                                                        field.onChange(values === '' ? values : Number(values));
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            {
                                serviceType === SHOP_SERVICE_TYPE.Other && (
                                    <div className='md:w-1/4 w-full'>
                                        <div className='flex flex-col mt-4 w-full'>
                                            <div className='h-3.5 md:block hidden'></div>
                                            <div className='mt-1.5 md:h-12 h-fit flex items-center'>
                                                <FormField
                                                    control={form.control}
                                                    name={`services.${index}.isMedicalService`}
                                                    render={({ field }) => (
                                                        <FormItem className='flex items-center gap-2'>
                                                            <FormControl>
                                                                <Checkbox
                                                                    checked={field.value}
                                                                    onCheckedChange={field.onChange}
                                                                />
                                                            </FormControl>
                                                            <Label className='label-1'>{t('words_title.medical_service_checkbox')}</Label>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </>

                    ) :
                    (
                        <>
                            <div className='md:w-1/3 w-full flex gap-4 items-start'>
                                <FormField
                                    control={form.control}
                                    name={`services.${index}.petWeightFrom`}
                                    render={({ field }) => (
                                        <FormItem className='flex flex-col mt-4 w-full'>
                                            <Label className='label-1' required>{t('words_title.pet_weight')}</Label>
                                            <Select
                                                key={field.value}
                                                onValueChange={field.onChange}
                                                {...field}
                                            >
                                                <FormControl>
                                                    <SelectTrigger className='mt-1.5' value={field.value}>
                                                        <SelectValue placeholder={t('placeholder.from')} />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    {PET_WEIGHT_OPTIONS.map(item => (
                                                        <SelectItem
                                                            className='hover:cursor-pointer'
                                                            key={item}
                                                            value={item.toString()}
                                                        >
                                                            <span>{`${item} kg`}</span>
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name={`services.${index}.petWeightTo`}
                                    render={({ field }) => (
                                        <FormItem className='flex flex-col mt-4 w-full'>
                                            <Select
                                                key={field.value}
                                                onValueChange={field.onChange}
                                                {...field}
                                            >
                                                <FormControl>
                                                    <SelectTrigger className='mt-5' value={field.value}>
                                                        <SelectValue placeholder={t('placeholder.to')} />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    {PET_WEIGHT_OPTIONS.map(item => (
                                                        <SelectItem
                                                            className='hover:cursor-pointer'
                                                            key={item}
                                                            value={item.toString()}
                                                        >
                                                            <span>{`${item} kg`}</span>
                                                        </SelectItem>
                                                    ))}
                                                    <SelectItem
                                                        className='hover:cursor-pointer'
                                                        value='50+'
                                                    >
                                                        <span>{`${t('words_title.up')}`}</span>
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='md:w-1/3 w-full'>
                                <FormField
                                    control={form.control}
                                    name={`services.${index}.serviceName`}
                                    render={({ field }) => (
                                        <FormItem className='flex flex-col mt-4 w-full'>
                                            <Label className='label-1' required>{t('words_title.service_name')}</Label>
                                            <Select onValueChange={field.onChange} value={field.value}>
                                                <FormControl>
                                                    <SelectTrigger className='mt-1.5' value={field.value}>
                                                        <SelectValue placeholder={t('placeholder.choose')} />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    {Object.keys(SHOP_SERVICE_NAME).map(item => (
                                                        <SelectItem
                                                            className='hover:cursor-pointer'
                                                            key={SHOP_SERVICE_NAME[item].key}
                                                            value={item}
                                                        >
                                                            <span>{t(`select_options.${SHOP_SERVICE_NAME[item].key}`)}</span>
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='md:w-1/3 w-full'>
                                <FormField
                                    control={form.control}
                                    name={`services.${index}.price`}
                                    render={({ field }) => (
                                        <FormItem className='flex flex-col mt-4 w-full'>
                                            <Label className='label-1' required>{t('words_title.service_price')}</Label>
                                            <FormControl>
                                                <FormatNumber
                                                    minValue={1}
                                                    maxValue={MAX_PRICE_SERVICE}
                                                    className='mt-1.5'
                                                    decimalScale={0}
                                                    statusDisplay='input'
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    value={field.value}
                                                    setvalueOfNumericFormat={(values) => {
                                                        field.onChange(values === '' ? values : Number(values));
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </>
                    )
            }
            <button
                type='button'
                onClick={handleRemoveService}
                className='flex justify-center items-center absolute top-2 right-1 bg-placeholder-1 bg-opacity-80 rounded-full p-1 shadow-lg w-4.5 h-4.5'
            >
                <XIcon className='w-3.5 h-3.5 text-white' />
            </button>
        </div>
    );
};

import { Button } from '@/components/common/Button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage
} from '@/components/common/Form';
import { FloatingLabelInput } from '@/components/common/Input';
import BackToSigninBtn from '@/components/general/BackToSigninBtn';
import { PASSWORD_VALIDATE_REGEX, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NotFoundPage from '../not-found';

export default observer(function ResetPasswordPage() {
    // hooks
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const uuid = searchParams.get('uuid') || '';

    // store
    const { authStore: { verifyUuid, resetPassword }, modalStore: { showAlertModal } } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        newPassword: yup
            .string()
            .required('form_error_validate.required')
            .matches(PASSWORD_VALIDATE_REGEX, 'form_error_validate.format_password'),
        confirmNewPassword: yup
            .string()
            .required('form_error_validate.required')
            .oneOf([yup.ref('newPassword'), ''], 'form_error_validate.confirm_new_password_not_match')
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            newPassword: '',
            confirmNewPassword: ''
        }
    });

    const [existedUuid, setExistedUuid] = useState<boolean>(true);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);

    // lifecycle
    useEffect(() => {
        onVerifyUuid();
    }, []);

    // function
    const onVerifyUuid = async () => {
        const res = await flowResult(verifyUuid({ uuid }));
        if (res && res.ok) {
            setExistedUuid(true);
        }
    };

    const onSubmit = async (data: FormData) => {
        const { newPassword } = data;
        const res = await flowResult(resetPassword({
            uuid,
            password: newPassword
        }));

        if (res && res.ok) {
            showAlertModal({
                type: 'success',
                content: t('messages.reset_password_success'),
                saveButton: t('button.back_to_signin'),
                onSave: () => navigate(ROUTES.signIn.href)
            });
        }
    };

    if (!existedUuid || !uuid) {
        return (
            <div className='text-center mg-t-50'>
                <NotFoundPage />
            </div>
        );
    }

    return (
        <div>
            <h3 className='title-1 text-center mt-6'>{t('words_title.reset_password')}</h3>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className='mt-6'>
                    <div>
                        <FormField
                            control={form.control}
                            name='newPassword'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <FloatingLabelInput
                                            type={!showNewPassword ? 'password' : 'text'}
                                            label={t(
                                                'words_title.new_password'
                                            )}
                                            className='mt-4'
                                            icon={
                                                !showNewPassword ?
                                                    <EyeOffIcon className='icon-right-input' onClick={() => setShowNewPassword(!showNewPassword)} /> :
                                                    <EyeIcon className='icon-right-input' onClick={() => setShowNewPassword(!showNewPassword)} />
                                            }
                                            isPreventCopy={true}
                                            isPreventCut={true}
                                            required
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                form.getValues('confirmNewPassword') && form.trigger('confirmNewPassword');
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage className='whitespace-pre-line' />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='confirmNewPassword'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <FloatingLabelInput
                                            type={!showConfirmNewPassword ? 'password' : 'text'}
                                            label={t(
                                                'words_title.confirm_new_password'
                                            )}
                                            className='mt-4'
                                            icon={
                                                !showConfirmNewPassword ?
                                                    <EyeOffIcon className='icon-right-input' onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} /> :
                                                    <EyeIcon className='icon-right-input' onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} />
                                            }
                                            isPreventCopy={true}
                                            isPreventCut={true}
                                            required
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                form.getValues('newPassword') && form.trigger('newPassword');
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <Button type='submit' variant='submit' size='submit' className='mt-6'>
                        {t('button.reset_password')}
                    </Button>
                    <BackToSigninBtn />
                </form>
            </Form>
        </div>

    );
});

import SidebarShopManagement from '@/components/general/SidebarShopManagement';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { CircleCheckIcon } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ShopTodayAppointmentDashboard from '../components/ShopTodayAppointmentDashboard';
import ShopMonthlyAppointmentDashboard from '../components/ShopMonthlyAppointmentDashboard';
import ShopAppointmentStatisticByStatus from '../components/ShopAppointmentStatisticByStatus';
import ShopAppointmentStatisticByServices from '../components/ShopAppointmentStatisticByServices';
import ShopAppointmentStatisticByView from '../components/ShopAppointmentStatisticByView';

export default observer(function ShopChannelDashboard() {
    // store
    const { uiStore: { devicesScreen: { mobile, desktop } } } = useStore();

    return (
        <>
            {
                mobile && (
                    <div className='bg-white min-h-screen'>
                        <Children />
                    </div>
                )
            }
            {
                desktop && (
                    <div className='grid md:grid-cols-[250px,1fr] lg:grid-cols-[300px,1fr] grid-cols-1 md:gap-4 md:pt-8'>
                        <div className='bg-white rounded-[0.25rem]'>
                            <SidebarShopManagement />
                        </div>
                        <div className='md:rounded-[0.25rem] overflow-hidden'>
                            <Children />
                        </div>
                    </div>
                )
            }
        </>
    );
});

const Children = () => {
    // hooks
    const { t } = useTranslation();

    // store
    const { shopStore: { shopInfo } } = useStore();

    return (
        <>
            {
                shopInfo && ((!shopInfo.generalInfo || !shopInfo.shopInfo || !shopInfo.operationInfo || !shopInfo.appointmentInfo)) && (
                    <div className='bg-white rounded p-4 font-normal mb-4'>
                        <div>{t('sentences.dashboard_desc_1')}</div>
                        <div className='mt-1'>{t('sentences.dashboard_desc_2')}</div>
                        <div className='grid min-[1400px]:grid-cols-4 grid-cols-2 gap-2 mt-4'>
                            <InfoStatusItem text={t('words_title.create_shop_tab_general')} activeTab='create_shop_tab_general' isComplete={shopInfo.generalInfo} />
                            <InfoStatusItem text={t('words_title.create_shop_tab_info')} activeTab='create_shop_tab_info' isComplete={shopInfo.shopInfo} />
                            <InfoStatusItem text={t('words_title.create_shop_tab_operation')} activeTab='create_shop_tab_operation' isComplete={shopInfo.operationInfo} />
                            <InfoStatusItem text={t('words_title.create_shop_tab_appointment')} activeTab='create_shop_tab_appointment' isComplete={shopInfo.appointmentInfo} />
                        </div>
                        <div className='mt-4 italic text-text-4 text-small'>{t('sentences.dashboard_desc_3')}</div>
                    </div>
                )
            }
            <div className=''>
                <ShopTodayAppointmentDashboard />
                <ShopMonthlyAppointmentDashboard />
                <div className='mt-4 flex point-1200:flex-row flex-col gap-4'>
                    <div className='point-1200:w-1/2 w-full bg-white rounded min-h-60'>
                        <ShopAppointmentStatisticByStatus />
                    </div>
                    <div className='point-1200:w-1/2 w-full bg-white rounded min-h-96'>
                        <ShopAppointmentStatisticByServices />
                    </div>
                </div>
                <ShopAppointmentStatisticByView />
            </div>
        </>
    );
};

interface InfoStatusItemProps {
    text: string,
    activeTab: string,
    isComplete: boolean
}

const InfoStatusItem = ({ text, isComplete, activeTab }: InfoStatusItemProps) => {
    // hooks
    const navigate = useNavigate();

    // store
    const { shopStore: { setObservable } } = useStore();

    // function
    const handleClick = () => {
        navigate(ROUTES.myShop.href, { state: { activeTab: activeTab } });
        setObservable('isEditMode', true);
    };

    return (
        <div className='rounded-[1.875rem] border border-border-6 px-2.5 py-2 flex justify-between items-center min-h-9 cursor-pointer decoration-active hover:underline' onClick={handleClick}>
            <div className='text-active font-medium'>{text}</div>
            {
                isComplete && <CircleCheckIcon className='flex-none w-4.5 h-4.5 text-white fill-active' />
            }
        </div>
    );
};

import { Button } from '@/components/common/Button';
import CountDownClock from '@/components/common/CountDownClock';
import { Form, FormControl, FormField, FormItem } from '@/components/common/Form';
import { InputOTP, InputOTPSlot } from '@/components/common/Input';
import { DURATION_RESEND_OTP, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { toastify } from '@/utils/toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
type Props = {
    phoneNumberChange: string,
    setShowVerifyOtp: React.Dispatch<React.SetStateAction<boolean>>
};

export default observer(function VerifyOtpPhoneNumber({ phoneNumberChange, setShowVerifyOtp }: Props) {
    // hook
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const { authStore: { verifyOtpPhoneNumber, changePhoneNumber, getProfile } } = useStore();

    // validate Schema
    const validateSchema = yup.object().shape({
        verifyOtp: yup
            .string()
            .min(6)
            .required()
    });
    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const [runTimmer, setRunTimer] = useState<boolean>(true);
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            verifyOtp: ''
        }
    });

    const watchOtpValue = form.watch('verifyOtp');

    // function
    const onSubmit = async (data: FormData) => {
        const res = await flowResult(verifyOtpPhoneNumber(data));
        if (res) {
            getProfile();
            toastify('alert-success', t('sentences.change_phone_success'));
            navigate(ROUTES.myAccount.href);
        } else {
            form.reset();
        }
    };

    const onClickResendOtp = async () => {
        setRunTimer(true);
        await flowResult(changePhoneNumber({ phoneNumber: phoneNumberChange }));
        toastify('success', t('messages.resend_otp_succes'));
    };

    return (
        <>
            <div className='pb-[1.5rem]'>
                <div className='px-4 pt-4 md:pt-0'>
                    <span className='block mb-2 text-text-2 text-small'>{t('sentences.verify_otp_signup_title_3')}</span>
                    <span className='block mb-2 text-text-2 text-small'>{t('sentences.verify_otp_signup_desc_2')}</span>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className='mt-6'>
                            <div className='flex justify-start'>
                                <FormField
                                    control={form.control}
                                    name='verifyOtp'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <InputOTP maxLength={6} {...field}>
                                                    <InputOTPSlot index={0} />
                                                    <InputOTPSlot index={1} />
                                                    <InputOTPSlot index={2} />
                                                    <InputOTPSlot index={3} />
                                                    <InputOTPSlot index={4} />
                                                    <InputOTPSlot index={5} />
                                                </InputOTP>
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='flex flex-row items-center gap-1 text-small font-medium leading-6 mt-[1rem] md:mt-[1.5rem]'>
                                <span className='text-text-1'>{t('sentences.verify_otp_resend_desc')}</span>
                                <button type='button' className={classNames('text-link-1', runTimmer && 'opacity-50')} disabled={runTimmer} onClick={onClickResendOtp}>{t('sentences.verify_otp_resend_text_2')}</button>
                                {
                                    runTimmer && (
                                        <CountDownClock
                                            duration={DURATION_RESEND_OTP}
                                            callbackWhenZero={() => {
                                                setRunTimer(false);
                                                form.reset();
                                            }}
                                        />
                                    )
                                }
                            </div>
                            <div className='gap-[1rem] mt-[1rem] lg:mt-[1.5rem] md:flex'>
                                <Button type='button' className='w-full border-border-9 mb-[0.375rem] hidden md:block lg:w-1/2' onClick={() => setShowVerifyOtp(false)} variant='outline'>{t('button.cancel')}</Button>
                                <Button type='submit' disabled={watchOtpValue.length < 6} className='w-full block lg:w-1/2'>{t('button.verify_otp_code_2')}</Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
        </>
    );
});

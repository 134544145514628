import React, { useEffect, useState } from 'react';
import { ColorFormat, CountdownCircleTimer, type Props } from 'react-countdown-circle-timer';

type CountDownClockProps = Omit<Props, 'colors' | 'colorsTime'> & {
    colors?: ColorFormat,
    hideWhenZero?: boolean,
    callbackWhenZero?: () => void
};

export default function CountDownClock({
    duration,
    colors = '#0075FF',
    size = 24,
    strokeWidth = 2,
    isPlaying = true,
    hideWhenZero = true,
    callbackWhenZero,
    ...props
}: CountDownClockProps) {
    // state
    const [timer, setTimer] = useState<number>(duration);
    const [showCountDown, setShowCountDown] = useState<boolean>(true);

    // life cycle
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowCountDown(false);
        }, duration * 1000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else {
            callbackWhenZero?.();
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [timer]);

    const renderTime = () => {
        return (
            <div className='text-link-1 text-xs'>
                {timer}
            </div>
        );
    };

    return (
        <>
            {
                showCountDown && (
                    <CountdownCircleTimer duration={duration} isPlaying={isPlaying} colors={colors} size={size} strokeWidth={strokeWidth} {...props}>
                        { renderTime }
                    </CountdownCircleTimer>
                )
            }
        </>
    );
};

import Calendar from '@/components/calendar/Calendar';
import { Button } from '@/components/common/Button';
import { Checkbox } from '@/components/common/Checkbox';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/common/Form';
import { Label } from '@/components/common/Label';
import StepTimeSelect from '@/components/general/StepTimeSelect';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import { DISPLAY_FNS_DATE_YMD, DISPLAY_MOMENT_TIME_HM, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, ROUTES, SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import NotFoundPage from '@/pages/not-found';
import yup from '@/services/yup';
import { APPOINTMENT_INFOR_TYPE, APPOINTMENT_STATUS_TYPE } from '@/types/enums';
import { formatDateTime } from '@/utils/datetime';
import { toastify } from '@/utils/toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default observer(function EditGeneralInformation() {
    const today = new Date().setHours(0, 0, 0, 0);

    // hook
    const { t } = useTranslation();
    const { slug } = useParams();
    const navigate = useNavigate();

    // store
    const {
        shopStore: { getShopDetailForAppointment, shopAppointmentInfor, setObservable },
        appointmentStore: { appointment, getAppointmentDetail, updateAppointmentInfor },
        uiStore: { devicesScreen: { mobile, desktop } }
    } = useStore();

    // state

    // validate schema
    const validateSchema = yup.object().shape({
        appointmentDate: yup.date().required('form_error_validate.required'),
        isSelectTime: yup.boolean(),
        appointmentTime: yup
            .string()
            .when('isSelectTime', {
                is: isSelectTime => isSelectTime === false,
                then: _ => yup.string().required('form_error_validate.required'),
                otherwise: _ => yup.string().nullable()
            }),
        customTime: yup
            .string()
            .when('isSelectTime', {
                is: isSelectTime => isSelectTime === true,
                then: _ => yup.string().required('form_error_validate.required'),
                otherwise: _ => yup.string().nullable()
            })
    });

    type FormData = yup.InferType<typeof validateSchema>;

    // state
    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            appointmentDate: undefined,
            appointmentTime: '',
            isSelectTime: false,
            customTime: ''
        }
    });

    const watchisSelectTime = form.watch('isSelectTime');
    const watchAppointmentDate = form.watch('appointmentDate');

    // lifecycle
    useEffect(() => {
        fetchData();

        return () => {
            setObservable('appointment', null, { isMergeObject: false });
            setObservable('shopAppointmentInfor', {}, { isMergeObject: true });
        };
    }, []);

    // functions
    const fetchShopDetailForAppointment = async (shopCode: string, appointmentDate: string) => {
        const params = {
            slug: shopCode,
            data: {
                isForBookAppointment: true,
                isShopOwner: true,
                appointmentDate: formatDateTime(appointmentDate, SUBMIT_MOMENT_DATE_YMD)
            }
        };
        const res = await flowResult(getShopDetailForAppointment(params));
        return res;
    };

    const fetchData = async () => {
        if (slug) {
            const appointmentDetail = await flowResult(getAppointmentDetail(slug));
            if (appointmentDetail) {
                const appointmentDate = new Date(appointmentDetail?.appointmentDate);

                form.setValue('appointmentDate', appointmentDate);
                if (appointmentDate.getTime() >= today) {
                    form.setValue('appointmentTime', appointmentDetail?.appointmentTime);
                } else {
                    form.setValue('customTime', appointmentDetail?.appointmentTime);
                    form.setValue('isSelectTime', true);
                }

                // Kiểm tra xem thời gian đã chọn có trong danh sách thời gian của cửa hàng không, ko có thì thêm vào
                const shopDetail = await fetchShopDetailForAppointment(appointmentDetail?.shopData?.code, appointmentDetail?.appointmentDate);
                if (appointmentDetail?.appointmentTime && shopDetail?.appointmentTimesOfDay) {
                    const existingTime = shopDetail.appointmentTimesOfDay.find(
                        time => time.startTime === appointmentDetail.appointmentTime
                    );

                    if (!existingTime) {
                        shopDetail.appointmentTimesOfDay.push({
                            startTime: appointmentDetail.appointmentTime,
                            status: false
                        });
                        setObservable('shopAppointmentInfor', { ...shopDetail });
                    }
                }
            }
        }
    };

    const onSubmit = async (data: FormData) => {
        if (slug) {
            const subData = {
                appointmentDate: formatDateTime(data.appointmentDate, SUBMIT_MOMENT_DATE_YMD) ?? '',
                appointmentTime: data.isSelectTime ?
                    formatDateTime(data.customTime, DISPLAY_MOMENT_TIME_HM, {
                        onlyTime: true,
                        currentformat: DISPLAY_MOMENT_TIME_HMS
                    }) :
                    formatDateTime(data.appointmentTime, DISPLAY_MOMENT_TIME_HM, {
                        onlyTime: true,
                        currentformat: DISPLAY_MOMENT_TIME_HMS
                    }),
                isSelectTime: data.isSelectTime
            } as any;

            if (data.appointmentDate.getTime() < today) {
                delete subData.isSelectTime;
            }

            const res = await flowResult(updateAppointmentInfor(
                {
                    code: slug,
                    type: APPOINTMENT_INFOR_TYPE.general,
                    data: subData
                }
            ));

            if (res) {
                navigate(`${ROUTES.appointmentManagement.href}/${slug}`);
                toastify('alert-success', t('sentences.update_success'));
            } else {
                if (appointment?.appointmentTime && appointment?.appointmentDate) {
                    form.setValue('appointmentTime', appointment?.appointmentTime);
                    form.setValue('appointmentDate', new Date(appointment?.appointmentDate));
                }
            }
        }
    };

    return (
        <>
            <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.edit_general_information')} defaultNavigateUrl={`${ROUTES.appointmentManagement.href}/${slug}`} />} />
            {((appointment && (appointment?.status === APPOINTMENT_STATUS_TYPE.Confirmed || appointment?.status === APPOINTMENT_STATUS_TYPE.NotConfirmed))) ?
                (
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <div className='px-4 h-full w-full py-6 flex flex-col gap-4 text-[0.9375rem] pb-40 md:pb-6'>
                                <div className='hidden md:block rounded-sm'>
                                    <h3 className='text-[1.3125rem] text-active font-semibold'>{t('words_title.edit_general_information')}</h3>
                                </div>

                                {/* Appointment ID */}
                                <div className='hidden md:flex md:flex-col md:gap-4'>
                                    <div className='flex gap-8'>
                                        <p className='text-text-4'>{t('words_title.appointment_id')}</p>
                                        <p>{appointment?.code}</p>
                                    </div>
                                </div>
                                <hr className='text-border-8 hidden md:block' />

                                {/* Appointment Date */}
                                <FormField
                                    control={form.control}
                                    name='appointmentDate'
                                    render={({ field }) => (
                                        <FormItem className='flex flex-col gap-2.5'>
                                            <FormLabel>{t('words_title.appointment_date')}</FormLabel>
                                            <FormControl>
                                                <Calendar
                                                    mode='single'
                                                    selected={field.value}
                                                    // disabled={date =>
                                                    //     date < today
                                                    onSelect={(date) => {
                                                        form.setValue('appointmentTime', '');
                                                        form.setValue('customTime', '');
                                                        field.onChange(date);
                                                        if (date) {
                                                            if (date.getTime() < today) {
                                                                form.setValue('isSelectTime', true);
                                                            } else {
                                                                form.setValue('isSelectTime', false);
                                                            }
                                                            fetchShopDetailForAppointment(appointment?.shopData?.code, date);
                                                        }
                                                    }}
                                                    placeholder={t('placeholder.choose')}
                                                    useIconRemove={true}
                                                    required
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Appointment Time */}
                                {(shopAppointmentInfor?.appointmentTimesOfDay && watchAppointmentDate && watchAppointmentDate.getTime() >= today) && (
                                    <>
                                        <FormField
                                            control={form.control}
                                            name='appointmentTime'
                                            render={({ field }) => (
                                                <FormItem className='flex flex-col gap-2.5'>
                                                    <FormLabel className='hidden md:block'>{t('words_title.appointment_time')}</FormLabel>
                                                    <FormControl>
                                                        <div className='grid grid-cols-4 gap-2.5'>
                                                            {shopAppointmentInfor?.appointmentTimesOfDay.map(({ startTime, status }, index) => (
                                                                <Button
                                                                    key={index}
                                                                    type='button'
                                                                    onClick={() => {
                                                                        if (field.value === startTime) {
                                                                            field.onChange('');
                                                                        } else {
                                                                            field.onChange(startTime);
                                                                            form.setValue('isSelectTime', false);
                                                                        };
                                                                    }}
                                                                    variant={field.value === startTime ? 'active' : 'option'}
                                                                    disabled={(!status && (startTime !== appointment?.appointmentTime || formatDateTime(watchAppointmentDate, SUBMIT_MOMENT_DATE_YMD) !== appointment.appointmentDate)) || watchisSelectTime}
                                                                >
                                                                    {formatDateTime(startTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                                                        onlyTime: true,
                                                                        currentformat: DISPLAY_MOMENT_TIME_HMS
                                                                    })}
                                                                </Button>
                                                            ))}
                                                        </div>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <hr className='hidden md:block text-border-8' />
                                    </>
                                )}

                                {/* Checkbox */}
                                {(watchAppointmentDate && watchAppointmentDate.getTime() >= today) && (
                                    <div className=''>
                                        <FormField
                                            control={form.control}
                                            name='isSelectTime'
                                            render={({ field }) => (
                                                <FormItem>
                                                    <div className='flex gap-1'>
                                                        <FormControl>
                                                            <Label className='flex items-start gap-1 cursor-pointer'>
                                                                <Checkbox
                                                                    className='-mt-[1px]'
                                                                    checked={field.value}
                                                                    onCheckedChange={(checked) => {
                                                                        field.onChange(checked);
                                                                        if (checked) {
                                                                            form.setValue('appointmentTime', '');
                                                                            if (watchAppointmentDate.getTime() === new Date(appointment?.appointmentDate).getTime()) {
                                                                                form.setValue('customTime', appointment.appointmentTime);
                                                                            }
                                                                        } else {
                                                                            form.setValue('customTime', '');
                                                                            if (watchAppointmentDate.getTime() === new Date(appointment?.appointmentDate).getTime()) {
                                                                                form.setValue('appointmentTime', appointment.appointmentTime);
                                                                            }
                                                                        }
                                                                        form.trigger('appointmentTime');
                                                                    }}
                                                                />
                                                                <span>{t('sentences.tick_input_specific_time')}</span>
                                                            </Label>
                                                        </FormControl>
                                                    </div>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                )}

                                {/* Custom Time */}
                                {(watchisSelectTime || (watchAppointmentDate && watchAppointmentDate.getTime() < today)) && (
                                    <FormField
                                        control={form.control}
                                        name='customTime'
                                        render={({ field }) => (
                                            <FormItem className='flex flex-col gap-1.5'>
                                                <FormControl>
                                                    <StepTimeSelect name='customTime' control={form.control} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                )}

                                {/* Buttons */}
                                {desktop && (
                                    <div className='flex gap-6'>
                                        <Button
                                            onClick={() => {
                                                navigate(`${ROUTES.appointmentManagement.href}/${slug}`);
                                            }}
                                            type='button'
                                            variant='clear'
                                            className='w-full block'
                                        >
                                            {t('button.cancel')}
                                        </Button>
                                        <Button disabled={!form.formState.isValid || form.formState.isSubmitting} type='submit' className='w-full'>{t('button.save')}</Button>
                                    </div>
                                )}
                            </div>
                            {mobile && (
                                <div className='fixed bottom-0 p-4 w-full bg-white'>
                                    <Button disabled={!form.formState.isValid || form.formState.isSubmitting} type='submit' className='w-full'>{t('button.save')}</Button>
                                </div>
                            )}
                        </form>
                    </Form>
                ) :
                <NotFoundPage />}

        </>
    );
});

import { NumericFormat, NumberFormatValues } from 'react-number-format';

import { useTranslation } from 'react-i18next';
import { cn } from '@/utils/utils';

interface FormatNumberProps {
    value: string | number | null | undefined,
    setvalueOfNumericFormat?: (value: string | number | undefined) => void,
    className?: string,
    statusDisplay?: 'text' | 'input',
    type?: 'text' | 'tel' | 'password' | undefined,
    thousandSeparator?: boolean | string,
    allowNegative?: boolean,
    minValue?: number,
    maxValue?: number,
    decimalScale?: number,
    defaultValue?: string | number,
    prefix?: string,
    suffix?: string,
    getInputRef?: (el: HTMLInputElement) => void,
    renderText?: (formattedValue: string) => JSX.Element | null,
    decimalSeparator?: string,
    fixedDecimalScale?: boolean,
    placeholder?: string,
    disabled?: boolean
}

const FormatNumber: React.FC<FormatNumberProps> = (props) => {
    const { t } = useTranslation();

    const {
        value,
        setvalueOfNumericFormat,
        className,
        statusDisplay = 'text',
        type = 'text',
        thousandSeparator = true,
        allowNegative = false,
        minValue,
        maxValue,
        decimalScale,
        defaultValue,
        prefix = '',
        suffix = '',
        getInputRef,
        renderText,
        decimalSeparator = '.',
        fixedDecimalScale = false,
        placeholder,
        disabled = false
    } = props;

    const onValueChange = (values: NumberFormatValues) => {
        setvalueOfNumericFormat && setvalueOfNumericFormat(values.value);
    };

    /**
     * Kiểm tra giới hạn của giá trị value khi nhập
     */
    const handleAllow = (values: NumberFormatValues) => {
        const { floatValue } = values;
        if (minValue !== undefined && floatValue !== undefined && floatValue + 1 <= minValue) {
            return false;
        }
        if (!floatValue) {
            return true;
        }
        if (minValue !== undefined && maxValue !== undefined) {
            return floatValue >= minValue && floatValue <= maxValue;
        }
        if (minValue !== undefined) {
            return floatValue >= minValue;
        }
        if (maxValue !== undefined) {
            return floatValue <= maxValue;
        }
        return true;
    };

    const checkStatusDisplay = (value: string | number | null | undefined) => {
        if (statusDisplay === 'text') {
            if (value === null || value === undefined || value === '') {
                return '';
            }
            const format = /^-?\d*\.?\d+$/;
            if (format.test(String(value))) {
                return value;
            }
            return NaN;
        }
        return value;
    };

    return (
        <>
            <NumericFormat
                placeholder={placeholder}
                isAllowed={handleAllow}
                decimalScale={decimalScale}
                className={cn('flex h-12 w-full text-input-1 font-medium rounded-lg border border-input bg-background px-3 py-4 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:border-active disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-background-2', className)}
                displayType={statusDisplay}
                thousandSeparator={thousandSeparator}
                allowNegative={allowNegative}
                value={checkStatusDisplay(value)}
                onValueChange={onValueChange}
                defaultValue={defaultValue}
                prefix={prefix}
                suffix={suffix}
                getInputRef={getInputRef}
                renderText={renderText}
                type={type}
                decimalSeparator={decimalSeparator}
                fixedDecimalScale={fixedDecimalScale}
                disabled={disabled}
            />
        </>
    );
};

export default FormatNumber;

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { ChartConfig, ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { SUBMIT_MOMENT_DATE_YMD } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { APPOINTMENT_STATUS_TYPE, LANGUAGES_SUPPORTED, STATISTIC_TYPE, StatisticType } from '@/types/enums';
import { formatDateTime } from '@/utils/datetime';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pie, PieChart } from 'recharts';

const STATISTIC_APPOINTMENT_BY_SERVICE = [
    {
        value: STATISTIC_TYPE.Daily,
        nameEn: 'Today',
        nameVi: 'Hôm nay'
    },
    {
        value: STATISTIC_TYPE.Monthly,
        nameEn: 'This Month',
        nameVi: 'Tháng này'
    },
    {
        value: STATISTIC_TYPE.Yearly,
        nameEn: 'This Year',
        nameVi: 'Năm nay'
    }
];

export default observer(function ShopAppointmentStatisticByServices() {
    // hooks
    const { t, i18n: { language } } = useTranslation();

    // store
    const { shopStore: { getStatisticsAppointmentByService } } = useStore();

    // state
    const [data, setData] = useState<any>();
    const [statisticType, setStatisticType] = useState<StatisticType>(STATISTIC_TYPE.Daily);

    // lifecycle
    useEffect(() => {
        fetchData();
    }, [statisticType]);

    // chart
    const chartConfig = {
        service: {
            label: 'service'
        },
        cut: {
            label: t('select_options.shop_service_cut'),
            color: '#5895FF'
        },
        shower: {
            label: t('select_options.shop_service_shower'),
            color: '#FFB72C'
        },
        comboCutShower: {
            label: t('select_options.shop_service_combo'),
            color: '#FE6A35'
        },
        hotel: {
            label: t('select_options.shop_service_hotel'),
            color: '#28C22E'
        },
        medical: {
            label: t('words_title.label_medical'),
            color: '#8700EE'
        },
        additional: {
            label: t('words_title.additional'),
            color: '#DCDCDC'
        },
        other: {
            label: t('words_title.other'),
            color: '#4991155e'
        }
    } satisfies ChartConfig;

    const chartData = useMemo(() => {
        if (data) {
            const total = Object.values(data as Record<number, number>).reduce((acc, value) => acc + value, 0);
            let tempData: string[] = [];
            tempData = Object.keys(data).filter(item => item !== 'comboCutShower').concat('comboCutShower');
            console.log(tempData);

            return tempData.map((item) => {
                return {
                    service: item,
                    total: ((data[item] / total) * 100),
                    fill: chartConfig[item]?.color
                };
            });
        }
    }, [data]);

    // function
    const fetchData = async () => {
        const res = await flowResult(getStatisticsAppointmentByService({
            statisticType,
            statusCount: [APPOINTMENT_STATUS_TYPE.Completed, APPOINTMENT_STATUS_TYPE.Confirmed],
            ...(statisticType === STATISTIC_TYPE.Daily ? { date: formatDateTime(new Date(), SUBMIT_MOMENT_DATE_YMD) ?? '' } : {}),
            ...(statisticType === STATISTIC_TYPE.Monthly ? { month: formatDateTime(new Date(), 'MM') ?? '' } : {}),
            ...(statisticType === STATISTIC_TYPE.Yearly ? { year: formatDateTime(new Date(), 'YYYY') ?? '' } : {})
        }));
        if (res?.ok) {
            setData(res.data);
        }
    };

    const handleChangeSelect = (value) => {
        setStatisticType(value);
    };

    const isDecimal = (value) => {
        const num = parseFloat(value);
        return !isNaN(num) && num % 1 !== 0;
    };

    return (
        <div className='p-4'>
            <div className='text-[0.9375rem] font-semibold pb-4 border-b border-border-12'>{t('words_title.statistic_by_service')}</div>
            <Select className='w-full mt-4' defaultValue={STATISTIC_TYPE.Daily} value={statisticType} onValueChange={handleChangeSelect}>
                <SelectTrigger className='font-normal h-10'>
                    <SelectValue>
                    </SelectValue>
                </SelectTrigger>
                <SelectContent>
                    {STATISTIC_APPOINTMENT_BY_SERVICE.map((item, index) => (
                        <SelectItem
                            className='hover:cursor-pointer'
                            key={index}
                            value={item.value}
                        >
                            {language === LANGUAGES_SUPPORTED.English ? item.nameEn : item.nameVi}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
            {
                chartData && !Object.values(chartData).every(item => !item.total) ?
                    (
                        <ChartContainer
                            config={chartConfig}
                            className='mx-auto aspect-square w-full h-[350px]'
                        >
                            <PieChart>
                                <ChartTooltip
                                    cursor={false}
                                    content={(
                                        <ChartTooltipContent formatter={(value, name, item, index, payload) => {
                                            return (
                                                <div className='flex items-center'>
                                                    <div className='h-3.5 w-3.5 rounded-[0.125rem] mr-2' style={{ backgroundColor: item.payload.fill }}></div>
                                                    <div className='mr-2'>{t(`chart.${name}`)}</div>
                                                    <div className='font-semibold'>
                                                        {`${
                                                            isDecimal(value) ? (value as number).toFixed(2) : value
                                                        } %`}
                                                    </div>
                                                </div>
                                            );
                                        }}
                                        />
                                    )}
                                />
                                <ChartLegend
                                    content={<ChartLegendContent nameKey='service' />}
                                    className='px-4 py-2.5 border border-border-9 rounded-[0.5rem] grid grid-cols-3 gap-1'
                                />
                                <Pie
                                    data={chartData}
                                    dataKey='total'
                                    nameKey='service'
                                    innerRadius={60}
                                    cornerRadius={6}
                                />
                            </PieChart>
                        </ChartContainer>
                    ) :
                    (
                        <div className='text-icon-2 text-center mt-10 text-small'>
                            {
                                statisticType === STATISTIC_TYPE.Daily ?
                                    (
                                        t('sentences.today_no_data')
                                    ) :
                                    (
                                        statisticType === STATISTIC_TYPE.Monthly ?
                                            (
                                                t('sentences.month_no_data')
                                            ) :
                                            (
                                                t('sentences.year_no_data')
                                            )
                                    )
                            }
                        </div>
                    )
            }
        </div>
    );
});

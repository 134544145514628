import React from 'react';
import { useStore } from './useStore';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { EnduserType } from '@/types/enums';
import { NotFoundPage } from '@/pages';
import HomePanelLayout from '@/layouts/HomePanelLayout';

// Định nghĩa kiểu cho các props của HOC
interface WithAuthenticationProps {
    // Các props khác bạn muốn truyền vào
    channels?: EnduserType[]
}

export default function withAuthentication<P extends object>(
    WrappedComponent: React.ComponentType<P>
) {
    const WithAuthentication = (props: P & WithAuthenticationProps): React.ReactElement => {
        // store
        const {
            authStore: { token, channel }
        } = useStore();

        if (!token) {
            return <Navigate to={ROUTES.home.href} />;
        }

        if (props?.channels && props.channels.length > 0) {
            if (!props?.channels?.includes(channel)) {
                return (
                    <HomePanelLayout>
                        <NotFoundPage />
                    </HomePanelLayout>
                );
            }
        }

        return <WrappedComponent {...(props as P)} />;
    };

    return WithAuthentication;
}

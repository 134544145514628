import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useStore } from './useStore';

interface UseWindowSizeProps {
    mobileWidth?: number
}

export interface WindowSize {
    width: number,
    height: number
}

export interface DevicesScreen {
    mobile: boolean,
    desktop: boolean
}

export default function useWindowSize({
    mobileWidth = 768
}: UseWindowSizeProps = {}): [DevicesScreen, WindowSize] {
    // store
    const { uiStore: { setWindowSize, setDevicesScreen } } = useStore();

    // state
    const [windowSizeState, setWindowSizeState] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [devicesScreenState, setDevicesScreenState] = useState<DevicesScreen>({
        mobile: false,
        desktop: false
    });

    // lifecycle
    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        if (windowSizeState) {
            let mobile = false, desktop = false;
            if (windowSizeState.width < mobileWidth) {
                mobile = true;
            } else {
                desktop = true;
            }
            setDevicesScreenState({ mobile, desktop });
            setDevicesScreen({ mobile, desktop });
        }
    }, [windowSizeState]);

    // function
    const onResize = useCallback(debounce((e: UIEvent) => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
        setWindowSizeState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }, 500), []);

    return [devicesScreenState, windowSizeState];
}

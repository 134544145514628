import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { Switch } from '@/components/common/Switch';
import { Textarea } from '@/components/common/Textarea';
import StepTimeSelect from '@/components/general/StepTimeSelect';
import Header from '@/components/layout/Header';
import HeaderMobileFormat3 from '@/components/layout/header-component/HeaderMobileFormat3';
import FormatNumber from '@/components/react-number-format';
import { MAX_PRICE_SERVICE, PHONE_VALIDATE_REGEX, ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { APPOINTMENT_INFOR_TYPE, PICK_UP_PRICE_TYPE } from '@/types/enums';
import { toastify } from '@/utils/toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NotFoundPage from '@/pages/not-found';

export default observer(function EditPickupService() {
    // hooks
    const { slug } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // state
    const [isShowContent, setIsShowContent] = useState(false);
    const [isChargeByDistance, setIsChargeByDistance] = useState(false);

    // store
    const {
        appointmentStore: { appointment, getAppointmentDetail, updateAppointmentInfor },
        uiStore: { devicesScreen: { mobile } }
    } = useStore();

    // validate schema
    const validateSchema = yup.object().shape({
        pickUpService: yup.boolean(),
        pickUpPriceType: yup
            .number()
            .when('pickUpService', {
                is: pickUpService => pickUpService === true,
                then: _ => yup.number().required('form_error_validate.required'),
                otherwise: _ => yup.number().nullable()
            }),
        pickUpPrice: yup.lazy((value) => {
            if (value === '') {
                return yup
                    .string()
                    .when('pickUpPriceType', {
                        is: PICK_UP_PRICE_TYPE.ChargeByDistance,
                        then: _ => yup.string().nullable().required('form_error_validate.required'),
                        otherwise: _ => yup.string().nullable()
                    });
            }

            return yup
                .number()
                .positive()
                .max(MAX_PRICE_SERVICE)
                .when('pickUpPriceType', {
                    is: PICK_UP_PRICE_TYPE.ChargeByDistance,
                    then: _ => yup.number().nullable().required('form_error_validate.required'),
                    otherwise: _ => yup.number().nullable()
                });
        }),
        pickUpAddress: yup
            .string()
            .when('pickUpService', {
                is: pickUpService => pickUpService === true,
                then: _ => yup.string().required('form_error_validate.required'),
                otherwise: _ => yup.string().nullable()
            }),
        contact: yup
            .string()
            .when('pickUpService', {
                is: pickUpService => pickUpService === true,
                then: _ => yup.string().required('form_error_validate.required').matches(PHONE_VALIDATE_REGEX, 'form_error_validate.format_phone'),
                otherwise: _ => yup.string().nullable()
            }),

        pickUpTime: yup
            .string()
            .when('pickUpService', {
                is: pickUpService => pickUpService === true,
                then: _ => yup.string().required('form_error_validate.required'),
                otherwise: _ => yup.string().nullable()
            }),
        pickUpNote: yup.string()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            pickUpService: appointment?.pickUpService,
            pickUpPriceType: undefined,
            pickUpPrice: undefined,
            pickUpAddress: '',
            contact: '',
            pickUpTime: undefined,
            pickUpNote: ''
        }
    });

    // lifecycle
    useEffect(() => {
        fetchData();
    }, []);

    // functions
    const fetchData = async () => {
        if (slug) {
            const appointmentDetail = await flowResult(getAppointmentDetail(slug));
            if (appointmentDetail) {
                setIsShowContent(appointmentDetail?.pickUpService || false);
                setIsChargeByDistance(appointmentDetail?.pickUpPriceType === PICK_UP_PRICE_TYPE.ChargeByDistance);
                form.reset({
                    pickUpService: appointmentDetail?.pickUpService,
                    pickUpPriceType: appointmentDetail?.pickUpPriceType,
                    pickUpPrice: appointmentDetail?.pickUpPrice,
                    pickUpAddress: appointmentDetail?.pickUpAddress,
                    contact: appointmentDetail?.contact,
                    pickUpTime: appointmentDetail?.pickUpTime ? appointmentDetail?.pickUpTime.split(':').slice(0, 2).join(':') : undefined,
                    pickUpNote: appointmentDetail?.pickUpNote || ''
                });
            }
        }
    };

    const onSubmit = async (data: FormData) => {
        if (slug) {
            const res = await flowResult(updateAppointmentInfor({
                code: slug,
                type: APPOINTMENT_INFOR_TYPE.pickUp,
                data
            }));

            if (res) {
                navigate(`${ROUTES.appointmentManagement.href}/${slug}`);
                toastify('alert-success', t('sentences.update_success'));
            } else {

            }
        }
    };
    return (
        <>
            {appointment ?
                (
                    <>
                        <Header onlyShowMobile headerMobileComponent={<HeaderMobileFormat3 title={t('words_title.edit_pick_up_service')} defaultNavigateUrl={`${ROUTES.appointmentManagement.href}/${slug}`} />} />
                        <div className='px-4 h-full w-full pt-6 pb-24 md:pb-6 flex flex-col gap-4 text-[0.9375rem]'>
                            <div className='hidden md:block rounded-sm'>
                                <h3 className='text-[1.3125rem] text-active font-semibold'>{t('words_title.edit_pick_up_service')}</h3>
                            </div>
                            <Form {...form}>
                                <form onSubmit={form.handleSubmit(onSubmit)}>
                                    <div className='flex flex-col gap-4'>
                                        <FormField
                                            control={form.control}
                                            name='pickUpService'
                                            render={({ field }) => (
                                                <FormItem className='flex items-center gap-3'>
                                                    <Label className='text-[0.9375rem] font-medium'>{t('words_title.book_pick_up')}</Label>
                                                    <FormControl>
                                                        <Switch
                                                            checked={field.value}
                                                            onCheckedChange={(checked) => {
                                                                field.onChange(checked);
                                                                setIsShowContent(checked);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        {isShowContent && (
                                            <>
                                                <div>
                                                    <Label required className='text-[0.9375rem] font-medium'>
                                                        {t('words_title.price')}
                                                        {' '}
                                                        (VND)
                                                    </Label>
                                                    <div className='flex flex-col md:flex-row gap-1.5 pt-1.5'>
                                                        <FormField
                                                            control={form.control}
                                                            name='pickUpPriceType'
                                                            render={({ field }) => (
                                                                <FormItem>
                                                                    <FormControl>
                                                                        <Select
                                                                            onValueChange={(value) => {
                                                                                field.onChange(value);
                                                                                setIsChargeByDistance(value === PICK_UP_PRICE_TYPE.ChargeByDistance.toString());
                                                                            }}
                                                                            value={field.value?.toString()}
                                                                        >
                                                                            <SelectTrigger
                                                                                className='h-12 w-full md:w-48'
                                                                            >
                                                                                <SelectValue placeholder={t('placeholder.choose')} />
                                                                            </SelectTrigger>
                                                                            <SelectContent>
                                                                                <SelectGroup className=''>
                                                                                    <SelectItem value={PICK_UP_PRICE_TYPE.Free.toString()}>{t('select_options.free')}</SelectItem>
                                                                                    <SelectItem value={PICK_UP_PRICE_TYPE.ChargeByDistance.toString()}>{t('select_options.charge_by_distance')}</SelectItem>
                                                                                </SelectGroup>
                                                                            </SelectContent>
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        {isChargeByDistance && (
                                                            <FormField
                                                                control={form.control}
                                                                name='pickUpPrice'
                                                                render={({ field }) => (
                                                                    <FormItem className='w-full'>
                                                                        <FormControl>
                                                                            <FormatNumber
                                                                                minValue={1}
                                                                                maxValue={MAX_PRICE_SERVICE}
                                                                                decimalScale={0}
                                                                                statusDisplay='input'
                                                                                thousandSeparator={true}
                                                                                allowNegative={false}
                                                                                value={field.value}
                                                                                setvalueOfNumericFormat={(values) => {
                                                                                    field.onChange(values === '' ? values : Number(values));
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                        <FormMessage />
                                                                    </FormItem>
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <FormField
                                                    control={form.control}
                                                    name='pickUpAddress'
                                                    render={({ field }) => (
                                                        <FormItem className='flex flex-col gap-1.5'>
                                                            <Label required className='text-[0.9375rem] font-medium'>{t('words_title.pick_up_address')}</Label>
                                                            <FormControl>
                                                                <Textarea className='placeholder:text-placeholder-1 resize-none' rows={3} {...field} />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name='contact'
                                                    render={({ field }) => (
                                                        <FormItem className='flex flex-col gap-1.5'>
                                                            <Label required className='text-[0.9375rem] font-medium'>{t('words_title.contact')}</Label>
                                                            <FormControl>
                                                                <Input type='number' className='placeholder:text-placeholder-1' {...field} />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name='pickUpTime'
                                                    render={({ field }) => (
                                                        <FormItem className='flex flex-col gap-1.5'>
                                                            <FormControl>
                                                                <StepTimeSelect title={t('words_title.pick_up_time_planned')} name='pickUpTime' control={form.control} required={true} />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />

                                            </>
                                        )}
                                        <FormField
                                            control={form.control}
                                            name='pickUpNote'
                                            render={({ field }) => (
                                                <FormItem className='flex flex-col gap-1.5'>
                                                    <Label className='text-[0.9375rem] font-medium'>{t('words_title.note')}</Label>
                                                    <FormControl>
                                                        <Textarea maxLength={1000} className='placeholder:text-placeholder-1' rows={5} {...field} />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <div className={classNames('flex gap-6 pt-4', { 'fixed bottom-0 p-4 w-full bg-white -ml-4': mobile })}>
                                        <Button
                                            onClick={() => {
                                                navigate(`${ROUTES.appointmentManagement.href}/${slug}`);
                                            }}
                                            variant='clear'
                                            type='button'
                                            size='submit'
                                            className='hidden md:block'
                                        >
                                            {t('button.cancel')}
                                        </Button>
                                        <Button disabled={form.formState.isSubmitting} type='submit' variant='submit' size='submit' className=''>
                                            {t('button.save')}
                                        </Button>
                                    </div>
                                </form>
                            </Form>
                        </div>
                    </>
                ) :
                <NotFoundPage />}

        </>
    );
});

import { ROUTES } from '@/configs/constants';
import { MoveLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function BackToSigninBtn() {
    // other
    const { t } = useTranslation();
    return (
        <div className='flex justify-center'>
            <Link to={ROUTES.signIn.href} className='flex mt-4 items-center justify-center gap-0.5'>
                <MoveLeft className='text-link-1' />
                <span className='text-link-1 text-[0.8125rem] font-medium leading-4'>{t('button.back_to_signin')}</span>
            </Link>
        </div>
    );
};

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    type CarouselApi
} from '@/components/common/Carousel';
import SampleBanner1 from '@/assets/images/Banner web-01.jpg';
import SampleBanner2 from '@/assets/images/Banner web-02.jpg';
import SampleBanner3 from '@/assets/images/Banner web-03.jpg';
import SampleBanner4 from '@/assets/images/Banner web-04.jpg';
import SampleBanner5 from '@/assets/images/Banner web-05.jpg';
import { useEffect, useState } from 'react';
import { Button } from '@/components/common/Button';

const sampleBannerImages = [SampleBanner1, SampleBanner2, SampleBanner3, SampleBanner4, SampleBanner5];

export default function Banner() {
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!api) {
            return;
        }

        setCount(api.scrollSnapList().length);
        setCurrent(api.selectedScrollSnap() + 1);

        api.on('select', () => {
            setCurrent(api.selectedScrollSnap() + 1);
        });
    }, [api]);

    return (
        <div className='w-full relative'>
            <Carousel setApi={setApi} className='w-full'>
                <CarouselContent>
                    {sampleBannerImages.map((item, index) => (
                        <CarouselItem key={index}>
                            <div className='xl:h-[28.125rem] md:h-[20rem] h-[12.5rem] w-full overflow-hidden flex justify-center items-center'>
                                <img className='w-fulll h-full object-cover rounded-md' src={item} alt='banner' />
                            </div>
                        </CarouselItem>
                    ))}
                </CarouselContent>
            </Carousel>
            <div className='absolute bottom-2 left-0 right-0 flex justify-center'>
                {Array.from({ length: sampleBannerImages.length }).map((_, index) => (
                    <Button
                        key={index}
                        onClick={() => api?.scrollTo(index)}
                        className={`h-[0.3125rem] mx-1 rounded-md ${
                            current === (index + 1) ? 'bg-active w-8' : 'bg-white w-5'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
}
